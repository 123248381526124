import React, { Component } from 'react';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Modal from '../../../Components/Modal';
import ProductModifiers from '../../../Components/Menu/ProductModifiers';
import { provideIntlService } from '@progress/kendo-react-intl';
import Avatar from '../../../Components/Avatar';
import TextField from '../../../Components/TextField';
import IMenuCategoryItem from '../../../models/IMenuCategoryItem';
import IMenuItemModifier from '../../../models/IMenuItemModifier';
import IOrderItemModifier from '../../../models/IOrderItemModifier';
import IOrderItem from '../../../models/IOrderItem';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({

  formSection: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%', //this allows for 2 columns of checkboxes - which matches mocks. Remove width to use the full width
  },
  container: {
    display: 'flex',
    overflow: 'hidden',
    minHeight: '400px',
  },
  modifierContainer: {
    flex: '1',
    margin: '5px',
    maxHeight: '400px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  imageContainer: {
    flex: '0 0 300px',
    padding: '20px',
    width: '300px',
    borderRight: '1px solid #e6eaee',
  },
  quantitySection: {
    paddingTop: '10px',
  },
  priceLabel: {
    width: '100%',
    color: theme.palette.primary.dark,
    fontSize: 20,
    paddingLeft: 5,
    paddingTop: 10,
  },
  caloriesLabel: {
    width: '100%',
    fontSize: 20,
    paddingLeft: 5,
  },
  controlLabelRoot: {
    marginRight: theme.spacing.unit * 5,
  },
  radioSelectItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 15,
  },

  descriptionSection: {
    padding: 5,
    fontSize: '15px',
    height: 'auto',
  },

  itemQtyContainer: {
    display: 'flex',
    border: '1px solid #2189F8',
    borderRadius: 4,
    padding: 4,
  },
  itemQtyLabel: {
    paddingRight: 2,
    textAlign: 'left',
    fontSize: '15px',
  },
  itemQtyValue: {
    width: '40px',
    textAlign: 'center',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    fontSize: '15px',
  },
  itemDetails: {
    alignItems: 'center',
    marginTop: 4,
  },
  itemButton: {
    padding: 4,
    display: 'flex',
    fontSize: '15px',
    color: '#2189F8',
    textTransform: 'none',
    minWidth: '30px',
  },
  radioSection: {
    width: '514px',
  },
  field: {
    marginTop: '20px',
  },
  notes: {
    width: '260px',
    marginLeft: '0px',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
  },
  checkbox: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  errorMessage: {
      color: '#ff0000',
      transition: '1s',
      opacity: 0,
  },
  errorMessageActive: {
      opacity: 1,
  },
});

interface IProps extends WithStyles {
  onModalClosed: Function;
  title: string;
  onSave: (orderItem: IOrderItem) => void;
  item: IMenuCategoryItem | IOrderItem;
};

interface IState {
  selectedMenuCategoryItem: IMenuCategoryItem;
  allSelectedItemModifiers: {[id: number]: IMenuItemModifier};
  isEdit: boolean;
  orderItemModifiers: { [id: number]: IOrderItemModifier };
  modifiersError: string;
  qtyError: string;
  quantity: number;
  minimumQuantity?: number;
  notes: string;
  noTax?: boolean;
  noServiceCharge?: boolean;
  noGratuity?: boolean;
};

export class ProductDetailsModal extends Component<IProps, IState> {
  state = {
    selectedMenuCategoryItem: {} as IMenuCategoryItem,
    allSelectedItemModifiers: {} as { [id: number]: IMenuItemModifier },
    isEdit: false,
    orderItemModifiers: {} as { [id: number]: IOrderItemModifier },
    modifiersError: '',
    qtyError: '',
    quantity: 1,
    minimumQuantity: null as number,
    notes: '',
    noTax: false,
    noServiceCharge: false,
    noGratuity: false,
  };

  private formatter = provideIntlService(this);

  private init = () => {
    const allSelectedItemModifiers = {} as {[id: number]: IMenuItemModifier};
    const orderItemModifiers = {};
    const { item } = this.props;
    if (!item) {
      return;
    }

    const isEdit = !!(item as IOrderItem).orderId;
    const stateSelectedMenuCategoryItem = isEdit
      ? (item as IOrderItem).menuCategoryItem
      : item as IMenuCategoryItem;

    if (!stateSelectedMenuCategoryItem) {
      return;
    }

    const addModifireOptions = (options: IMenuItemModifier[]) => {
      options.forEach(option => {
          allSelectedItemModifiers[option.id] = option;
          addModifireOptions(option.options);
      });
    }

    if (stateSelectedMenuCategoryItem.menuItem && stateSelectedMenuCategoryItem.menuItem.modifiers) {
      addModifireOptions(stateSelectedMenuCategoryItem.menuItem.modifiers);
    }

    if (isEdit) {
      (item as IOrderItem).orderItemModifiers
        .forEach(modifier => orderItemModifiers[modifier.menuItemModifierId] = { ...modifier });
    }

    const menuItem = stateSelectedMenuCategoryItem.menuItem;
    let configuredQuantity = menuItem.defaultQuantity || 1;
    if (!!menuItem.minimumQuantity && configuredQuantity < menuItem.minimumQuantity) {
      configuredQuantity = menuItem.minimumQuantity;
    }

    this.setState({
      selectedMenuCategoryItem: stateSelectedMenuCategoryItem,
      allSelectedItemModifiers,
      orderItemModifiers,
      isEdit,
      notes: isEdit ? (item as IOrderItem).notes : '',
      quantity: isEdit ? (item as IOrderItem).quantity : configuredQuantity,
      minimumQuantity: menuItem.minimumQuantity || 1,
      noTax: (item as IOrderItem).noTax,
      noServiceCharge: (item as IOrderItem).noServiceCharge,
      noGratuity: (item as IOrderItem).noGratuity,
    });
  }

  private showQtyError = (qtyError: string) => {
    this.setState({
      qtyError,
    }, () => {
        setTimeout(() => {
            this.setState({
              qtyError: '',
            })
        }, 5000);
    })
  }

  private onFieldChange = (fieldName: string) => (event: any) => {
    const { selectedMenuCategoryItem } = this.state;
    const minimumQuantity = selectedMenuCategoryItem.menuItem.minimumQuantity;
    let value = event.target.value;

    if (fieldName === 'quantity' && value < minimumQuantity) {
      this.showQtyError(`Min qty: ${minimumQuantity}`);
      value = minimumQuantity;
    }

    this.setState({ [fieldName]: value } as any);
  }

  private onCheckboxChange = (fieldName: string) => (event: any) => {
    this.setState({ [fieldName]: event.target.checked } as any);
  }

  private onCustomFieldChange = (fieldName: string) => (value: any) => {
    this.setState({ [fieldName]: value } as any);
  }

  private onSave = () => {
    const { onSave, item } = this.props;
    const { notes, selectedMenuCategoryItem, quantity, orderItemModifiers, isEdit, noTax, noServiceCharge, noGratuity } = this.state;

    const selectedMenuItem = selectedMenuCategoryItem.menuItem;

    const orderItem: IOrderItem = isEdit
        ? { ...item as IOrderItem }
        : {
            menuCategoryItemId: selectedMenuCategoryItem.id,
            name: selectedMenuCategoryItem.name,
            notes,
            price: selectedMenuCategoryItem.price ? selectedMenuCategoryItem.price : selectedMenuItem.price,
            taxTypeId: selectedMenuItem.taxTypeId,
            quantity,
            orderItemModifiers: [],
            noTax,
            noServiceCharge,
            noGratuity,
        };

    orderItem.quantity = quantity;
    orderItem.notes = notes;
    orderItem.description = selectedMenuCategoryItem.description;
    orderItem.imageUrl = selectedMenuItem.imageUrl;
    orderItem.unitPrice = selectedMenuCategoryItem.price;

    orderItem.noTax = noTax;
    orderItem.noServiceCharge = noServiceCharge;
    orderItem.noGratuity = noGratuity;
    orderItem.orderItemModifiers = Object.values(orderItemModifiers)
        .map(modifier => {
            const cloneModifier = { ...modifier };
            delete cloneModifier.menuItemModifier;
            return cloneModifier;
        });

    onSave(orderItem);
  }

  private showHTML = (html: string) => {
    if (!html) {
      return '';
    }

    const paragraphTags = /<\/p>/gim;
    const allTagsExceptBreak = /<(?!br|\/br).+?>/gim;

    const sanitizedHtml = html
      .replace(paragraphTags, '<br>')
      .replace(allTagsExceptBreak, '');

    return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml}} />;
  }

  private setModifiersError = (modifiersError: string) => {
    this.setState({ modifiersError });
  }

  private setOrderItemModifiers = (orderItemModifiers: { [id: number]: IOrderItemModifier }) => {
    this.setState({ orderItemModifiers });
  }

  public componentDidMount() {
    this.init();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { item } = this.props;

    if (!!item && prevProps.item !== item) {
      this.init();
    }
  }

  public getCaleries = (item: IMenuCategoryItem)=> {
    return item.menuItem && item.menuItem.calories ? item.menuItem.calories + ' cal.' : ''
  }

  public render() {
    const {
      classes,
      onModalClosed,
      title,
    } = this.props;

    const {
      selectedMenuCategoryItem,
      allSelectedItemModifiers,
      isEdit,
      orderItemModifiers,
      notes,
      quantity,
      modifiersError,
      qtyError,
      noTax,
      noServiceCharge,
      noGratuity,
    } = this.state;
    const modifiersTotalPrice = orderItemModifiers && Object.values(orderItemModifiers)
            .filter(orderItemModifier => orderItemModifier.recordStatus === 'Active')
            .map(orderItemModifier => orderItemModifier.menuItemModifier ? orderItemModifier.menuItemModifier.price : 0)
            .reduce((prev, next) => (prev ? prev : 0) + (next ? next : 0), 0);

    return (
      <Modal
        isOpened={true}
        onCancel={() => onModalClosed()}
        onSave={this.onSave}
        errorMessage={modifiersError}
        title={title || selectedMenuCategoryItem.name || ''}
        addTitleBottomBorder={true}
      >
        <div className={classes.container}>
          <div className={classes.imageContainer}>

            {selectedMenuCategoryItem.imageUrl ?
              <img alt='menu item' src={selectedMenuCategoryItem.imageUrl} />
              :
              <Avatar name={selectedMenuCategoryItem.name} />
            }
            <div className={classes.quantitySection}>
              <div className={classes.formSection}>
                <div className={classes.itemDetails}>
                  <div className={classes.itemQtyContainer}>
                    <label className={classes.itemQtyLabel}>Qty:</label>
                    <input
                      value={quantity}
                      className={classes.itemQtyValue}
                      type="number"
                      onChange={this.onFieldChange('quantity')}
                    />
                  </div>
                  <div className={classNames(classes.errorMessage, {[classes.errorMessageActive]: qtyError})}>
                    <span style={{ display: qtyError ? 'inline' : 'none' }}>{qtyError}</span>
                  </div>
                </div>
                <div className={classes.mainFieldsContainer}>
                  <div className={classes.field}>
                    <TextField
                      label="Special Instructions:"
                      name="notes"
                      value={notes}
                      onFieldChange={this.onCustomFieldChange('notes')}
                      className={classes.notes}
                    />
                  </div>
                  <div className={classes.checkboxes}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={noTax}
                          onChange={this.onCheckboxChange('noTax')}
                          value="noTax"
                          color="primary"
                          className={classes.checkbox}
                          disableRipple={true}
                        />
                      }
                      label="No Tax"
                      classes={{ label: classes.checkboxLabel }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={noServiceCharge}
                          onChange={this.onCheckboxChange('noServiceCharge')}
                          value="noServiceCharge"
                          color="primary"
                          className={classes.checkbox}
                          disableRipple={true}
                        />
                      }
                      label="No Service Charge"
                      classes={{ label: classes.checkboxLabel }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={noGratuity}
                          onChange={this.onCheckboxChange('noGratuity')}
                          value="noGratuity"
                          color="primary"
                          className={classes.checkbox}
                          disableRipple={true}
                        />
                      }
                      label="No Gratuity"
                      classes={{ label: classes.checkboxLabel }}
                    />
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.modifierContainer}>
            <div className={classes.priceLabel}>
              {this.formatter.formatNumber((selectedMenuCategoryItem.price + modifiersTotalPrice) * quantity, 'c2')}
            </div>
            <div className={classes.caloriesLabel}>
              {this.getCaleries(selectedMenuCategoryItem)}
            </div>
            <div className={classes.descriptionSection}>
              {this.showHTML(selectedMenuCategoryItem.description)}
            </div>
            <ProductModifiers
              selectedMenuItem={selectedMenuCategoryItem.menuItem}
              allSelectedItemModifiers={allSelectedItemModifiers}
              orderItemModifiers={orderItemModifiers}
              setOrderItemModifiers={this.setOrderItemModifiers}
              setModifiersError={this.setModifiersError}
              editModifires={isEdit}
            />
          </div>
        </div>
      </Modal>);
  }
}

export default withStyles(styles)(ProductDetailsModal);
