import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  ArrowRight,
} from '@material-ui/icons';
import { fade } from '@material-ui/core/styles/colorManipulator';

const leafHeight = 33;

const styles = theme => ({
  container: {
    display: 'flex',
    paddingLeft: 20,
    color: theme.palette.grey[300],
    cursor: 'pointer',
    alignItems: 'center',
    height: leafHeight,
  },
  rotated: {
    transform: 'rotate(90deg)',
  },
  icon: {
    transitionDuration: '150ms',
    transitionProperty: 'transform',
  },
  label: {
    fontSize: 16,
  },
  pickLeaf: {
    fontSize: 16,
    color: theme.palette.grey[300],
    paddingLeft: 57,
    cursor: 'pointer',
    height: leafHeight,
    display: 'flex',
    alignItems: 'center',
  },
  selected: {
    backgroundColor: fade(theme.palette.secondary.dark, 0.05),
  },
  animateHeight: {
    transitionDuration: '150ms',
    transitionProperty: 'height',
    overflow: 'hidden',
  },
});

class PickBranch extends Component {
  state = {};

  onClick = () => {
    this.props.onToggleExpand();
  }

  leafClicked = pickList => () => {
    this.props.onPickListPicked(pickList);
  }

  render() {
    const {
      classes,
      children,
      pickLists = [],
      selectedListId,
      expanded,
    } = this.props;

    return (<>
      <div
        className={classes.container}
        onClick={this.onClick}>
        <ArrowRight
          className={
            classNames(
              classes.icon,
              expanded && classes.rotated)
          }
        />
        <div className={classes.label}>
          {children}
        </div>
      </div>
      <div
        style={{ height: expanded ? leafHeight * pickLists.length : 0 }}
        className={classes.animateHeight}
      >
        {pickLists.map(pickList => (
          <div
            key={pickList.id}
            onClick={this.leafClicked(pickList)}
            className={classNames(
              classes.pickLeaf,
              pickList.id === selectedListId && classes.selected
            )}>
            {pickList.name}
          </div>
        ))}
      </div>
    </>);
  }
}

export default withStyles(styles)(PickBranch);
