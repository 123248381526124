import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import ISetting from 'models/ISetting';
import HighlightButton from 'Components/Buttons/HighlightButton';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import CoverTimePicker from 'Components/CoverTimePicker';
import moment from 'moment';

const styles = createStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    marginTop: 10,
    marginLeft: 1,
  },
  deleteButon: {
    color: theme.palette.grey[500],
  },
  scheduleWeek: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  timePicker: {
    width: 125,
    paddingRight: 20,
  },
  scheduleDay: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  colorSettingColorButton: {
    width: 32,
    marginTop: 20,
    minWidth: 'initial',
  },
}));

const defaultSchedule = ['', '', false, false, false, false, false, false, false];

interface IProps extends WithStyles {
  setting: ISetting,
  settingAddOption: (settingKey: string, newValue: any) => void,
  settingDeleteOption: (settingKey: string, optionIndex: number) => void,
  settingOnChange: (settingKey: string, settingValue: any, optionIndex?: number) => void,
}

class grazeOpenHours extends Component<IProps> {
  private onTimeFieldChange = (scheduleIndex: number, settingIndex: number) => (value: any) => {
    const { setting, settingOnChange } = this.props;
    const timeValue = value ? moment(value).clone().format('LT') || '' : null;

    const settingValue = [ ...setting.value[scheduleIndex] ];
    settingValue[settingIndex] = timeValue;

    settingOnChange(setting.key, settingValue, scheduleIndex);
  }

  private settingOnChange = (scheduleIndex: number, settingIndex: number) => (event: any) => {
    const { setting, settingOnChange } = this.props;

    const settingValue = [ ...setting.value[scheduleIndex] ];
    settingValue[settingIndex] = event.target.checked;

    settingOnChange(setting.key, settingValue, scheduleIndex);
  }

  private getDateFromTime= (time: string) => {
    if (!time) {
      return null;
    }

    const date = moment(new Date()).format('L'); // d = "12/12/2017"

    return moment(date +' '+ time).format();
  }

  private getSettingName = (settingIndex: number) => {
    switch(settingIndex) {
      case 0:
        return 'Open Time';
      case 1:
        return 'Close Time';
      case 2:
        return 'Su';
      case 3:
        return 'Mo';
      case 4:
        return 'Tu';
      case 5:
        return 'We';
      case 6:
        return 'Th';
      case 7:
        return 'Fr';
      case 8:
        return 'Sa';
      default:
        return '';
    }
  }

  public render() {
    const { setting, settingAddOption, settingDeleteOption, classes } = this.props;

    return (
      <div className={classes.container}>
          {setting.value.map((schedule: any[] , scheduleIndex: number) =>
          <div key={`schedule-week-${scheduleIndex}`} className={classes.scheduleWeek}>
            <Button
              id="delete"
              className={classes.colorSettingColorButton}
              onClick={() => settingDeleteOption(setting.key, scheduleIndex)}
            >
              <DeleteIcon className={classes.deleteButon}/>
            </Button>
            {schedule.map((setting: any, settingIndex: number) =>
              <div key={`schedule-setting-${scheduleIndex}-${settingIndex}`}>
                {settingIndex < 2
                ?
                <CoverTimePicker
                  className={classes.timePicker}
                  name={`${this.getSettingName(settingIndex)}-${scheduleIndex}-${settingIndex}`}
                  label={this.getSettingName(settingIndex)}
                  value={this.getDateFromTime(setting)}
                  onFieldChange={this.onTimeFieldChange(scheduleIndex, settingIndex)}
                />
                :
                <FormControlLabel
                  className={classes.scheduleDay}
                  label={this.getSettingName(settingIndex)}
                  control={
                    <Checkbox
                      name={this.getSettingName(settingIndex)}
                      checked={setting}
                      onChange={this.settingOnChange(scheduleIndex, settingIndex)}
                    />
                  }
                />
                }
              </div>
            )}
          </div>
          )}
          <div className={classes.addButton}>
              <HighlightButton
                onClick={() => settingAddOption(setting.key, defaultSchedule)}
                aria-label="Add A New Schedule"
              >
                <AddIcon/>
              </HighlightButton>
            </div>
        </div>
    );
  }
}

export default withStyles(styles)(grazeOpenHours);
