import React, { Component } from 'react';
import { connect } from 'react-redux';
import OneDimensionalGraphWidget from 'Components/GraphWidgets/OneDimensionalGraphWidget';
import BarGraph from 'Components/GraphWidgets/BarGraph';
import { withStyles } from '@material-ui/core/styles';
import { widgetGraphs } from 'helpers/enums';
import { getOrderTotalSummary, getSelectedOrderTotalSummary } from 'actions/reports';
import { SelectProperty } from 'helpers/select';

const styles = () => ({
  graphContainer: {
    display: 'flex',
    flexGrow: 1,
    paddingBottom: '3.2%',
  },
  lastGraphContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  graphGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const orderCountWidgetSettings = {
  graphType: 'progress',
  isLabelVisible: true,
  label: 'Total Orders Count',
  numeratorUnits: 'none',
  denominatorUnits: 'none',
  differenceUnits: 'percent',
  title: '# of Orders',
  isDifferenceVisible: false,
  graphTypeOptions: {
    donut: 'donut',
    progress: 'progress',
  },
};

const orderTotalSettings = {
  graphTypeOptions: {
    donut: 'donut',
    progress: 'progress',
  },
  graphType: 'donut',
  isDifferenceVisible: false,
  isLabelVisible: true,
  label: 'Total Value',
  numeratorUnits: 'dollars',
  denominatorUnits: 'dollars',
  differenceUnits: 'dollars',
  title: 'Value',
};

const ordersTotalsLast7DaysSettings = {
  id: 'ordersEvents',
  graphTypeOptions: {
    bar: 'bar',
    line: 'line',
  },
  graphType: 'bar',
  isDifferenceVisible: false,
  isLabelVisible: true,
  label: 'Total Last 7 Days',
  numeratorUnits: 'dollars',
  denominatorUnits: 'dollars',
  differenceUnits: 'dollars',
  title: 'Orders Today',
};

class OrderWidgets extends Component {
  state = {
    allOrders: {
      orderCount: 0,
      totalSpend: 0,
      totalEvents: 0,
    },
    selectedOrders: {},
  }

  render() {
    const {
      classes,
      widgetData,
      selectedOrders,
    } = this.props;

    const salesData = widgetData && {
      categories: SelectProperty(widgetData.orderTotalsLast7Days, 'Date'),
      values: SelectProperty(widgetData.orderTotalsLast7Days, 'total'),
    };

    let selectedOrdersTotal = 0.0;

    if (selectedOrders) {
      for (let index = 0; index < selectedOrders.length; index++) {
        selectedOrdersTotal += selectedOrders[index].total;
      }
    }
    if (salesData) {
      return (
        <div className={classes.graphGroupContainer}>
          <div className={classes.graphContainer}>
            <OneDimensionalGraphWidget
              denominator={widgetData.orderCount || 0}
              numerator={selectedOrders ? selectedOrders.length : 0}
              settings={orderCountWidgetSettings}
            />
          </div>
          <div className={classes.graphContainer}>
            <OneDimensionalGraphWidget
              denominator={widgetData.orderTotal}
              numerator={selectedOrdersTotal}
              settings={orderTotalSettings}
            />
          </div>
          <div className={classes.lastGraphContainer}>
            <BarGraph
              denominator={widgetData.totalOrderSalesLast7Days}
              numerator={widgetData.totalOrderSalesToday}
              data={salesData}
              settings={ordersTotalsLast7DaysSettings}
            />
          </div>
        </div>
      );
    } else {
      return (<div>Loading ...</div>);
    }
  }
}

const mapStateToProps = state => {
  const {
    widgets,
    storeReducer: {
      orders:
      {
        selectedOrders,
      },
      widgetData,
    },
  } = state;

  return {
    ordersEventsCostSettings: widgets[widgetGraphs.ordersEvents],
    widgetData,
    selectedOrders,
  };
};

const mapDispatchToProps = {
  getOrderTotalSummary,
  getSelectedOrderTotalSummary,
};

const OrderWidgetsContainer = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OrderWidgets));

export default OrderWidgetsContainer;
