import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    borderRadius: 4,
  },
  close: {
    padding: theme.spacing.unit / 2,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 22,
    marginRight: theme.spacing.unit * 2,
  },
});

class ValidationBanner extends React.Component {
  state = {
    open: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ open: this.props.isOpen });
    }
  }

  open = message => {
    this.setState({ open: true, message });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { open, message } = this.state;
    const bannerMessage = message || 'Please check for invalid fields...';

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        classes={{
          root: classes.root,
        }}
        autoHideDuration={15000}
        onClose={this.handleClose}
        onExited={this.handleExit}
        ContentProps={{
          'aria-describedby': 'message-id',
          headlineMapping: {
            body1: "div",
            body2: "div",
          },
          classes: {
            root: classes.error,
          },
        }}
        message={
          <span id="message-id">
            <ErrorIcon className={classNames(classes.icon)} />{bannerMessage}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

ValidationBanner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ValidationBanner);
