import React, { useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AccountDetails from './AccountDetails';
import TabContainer from 'Components/TabContainer';
import SaveBar from 'Components/SaveBar';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import ProposalSection from 'leads/ProposalSection';
import BookingSection from './BookingSection';
import AccountContext from './AccountContext';
import { PROPOSAL_TYPES } from 'constants/proposalTypes';

const styles = theme => ({
  root: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  tabContainer: {
    height: 40,
    backgroundColor: theme.palette.grey[50],
  },
  tabContent: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});
const tabs = ['Details', 'Bookings', 'Proposals'];

function AccountTabs({ classes }) {
  const {
    account,
    isEditing,
    onSave,
    onCancelEdit,
    salesReps,
    onFieldChange,
    salutations,
    isFieldChanged,
    isAddressFieldChanged,
    validateField,
    isFieldInvalid,
    onLaunchEditMode,
    onAddressFieldChange,
  } = useContext(AccountContext);
  const [ tabIndex, setTabIndex ] = useState(0);

  return (
    <div className={classes.root}>
      <div className={classes.tabContainer}>
        <BlueTextTab
          onTabChange={setTabIndex}
          tabs={tabs}
          tabIndex={tabIndex}  />
      </div>
      <TabContainer>
        {tabIndex === 0 &&
          <AccountDetails
            account={account}
            salesReps={salesReps}
            isEditing={isEditing}
            onFieldChange={onFieldChange}
            salutations={salutations}
            isFieldChanged={isFieldChanged}
            onAddressFieldChange={onAddressFieldChange}
            isAddressFieldChanged={isAddressFieldChanged}
            validateField={validateField}
            isFieldInvalid={isFieldInvalid}
            onLaunchEditMode={onLaunchEditMode}
          />
        }
        {tabIndex === 1 && <BookingSection account={account} />}
        {tabIndex === 2 && <ProposalSection linkedEntityId={account.id} entityType={PROPOSAL_TYPES.account} />}
      </TabContainer>
      {isEditing && <SaveBar onSave={onSave} onCancel={onCancelEdit} isSticky={true} />}
    </div>
  );
}

export default withStyles(styles)(AccountTabs);
