import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import BookingList from './BookingList';
import Booking from './Booking';

class Bookings extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/bookings" component={BookingList} />
        <Route path="/bookings/:id/:eventId(\d+)" component={Booking} />
        <Route path="/bookings/:id" component={Booking} />
      </Switch>
    );
  }
}

export default withRouter(Bookings);
