import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { getWeekAgenda } from 'actions/calendar';
import { connect } from 'react-redux';
import moment from 'moment';
import EventTypeTag from './EventTypeTag';
import { Today } from '@material-ui/icons';
import DataLoader from 'Components/DataLoader';
import _ from 'lodash';

const styles = theme => ({
  detailedAgenda: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    overflowX: 'hidden',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    color: theme.palette.grey[200],
  },
  agendaHeader: {
    flexShrink: 0,
    backgroundColor: theme.palette.background.paper,
    fontSize: 12,
    padding: '16px 0 16px 46px',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  agenda: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  agendaEvent: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 95,
    padding: 16,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  eventDetailsColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '75%',
  },
  flex: {
    display: 'flex',
  },
  calendarIcon: {
    marginRight: 8,
  },
  primaryMainColor: {
    color: theme.palette.primary.main,
  },
  smallText: {
    padding: '4px 0',
    fontSize: 13,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  eventTimeContainer: {
    alignItems: 'flex-end',
  },
  boldText: {
    marginBottom: 8,
    fontSize: 15,
    fontWeight: '800',
  },
  lighterBold: {
    color: theme.palette.grey['A100'],
  },
  noEvents: {
    padding: '24px 48px',
    fontSize: 15,
  },
});

class DetailedAgenda extends Component {
  constructor(props) {
    super(props);
    const selectedDate = moment(this.props.selectedDate).toDate();

    this.state = {
      isLoading: true,
      weekStart: selectedDate,
    };
  }

  componentDidMount() {
    if (this.props.height >= 0) {
      this.refreshAgenda();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedEventTypes !== this.props.selectedEventTypes) {
      this.refreshAgenda();
    }
    if (!moment(prevProps.selectedDate).isSame(moment(this.props.selectedDate))) {
      this.getAgendaByStartDate(moment(this.props.selectedDate));
    }
  }

  refreshAgenda = () => {;
    const { weekStart } = this.state;
    const startDateMoment = moment(weekStart);

    this.getAgendaByStartDate(startDateMoment);
  }

  getAgendaByStartDate = startDateMoment => {
    const { selectedEventTypes } = this.props;
    const weekStart = startDateMoment.clone().toDate();
    const startDate = startDateMoment.clone().format('YYYY-M-D');

    this.setState({ isLoading: true, weekStart });
    this.props.getWeekAgenda({ startDate, eventTypes: selectedEventTypes }).then(agendaEvents => {
      const agendas = this.groupByDay(agendaEvents);

      this.setState({
        agendas,
        isLoading: false,
      });
    });
  }

  groupByDay = agendaEvents => {
    const dayGroups = _.groupBy(agendaEvents, event => {
      return moment(event.startDateTime).startOf('day').format();
    });

    return _.values(dayGroups);
  }

  nextAgenda = () => {
    const { weekStart } = this.state;
    const nextDateMoment = moment(weekStart).clone().add(7, 'days');

    this.getAgendaByStartDate(nextDateMoment);
  }

  previousAgenda = () => {
    const { weekStart } = this.state;
    const previousDateMoment = moment(weekStart).clone().add(-7, 'days');

    this.getAgendaByStartDate(previousDateMoment);
  }

  render() {
    const { classes, height } = this.props;
    const { agendas, isLoading, weekStart } = this.state;
    const weekStartMoment = moment(weekStart);

    return (
      <div className={classes.detailedAgenda} style={{ maxHeight: height }}>
        <div className={classes.agendaHeader}>
          {weekStart && weekStartMoment.format('l')
          + ' - ' + weekStartMoment.add(6, 'days').format('l')}
        </div>
        <div className={classes.agenda}>
          {agendas && !isLoading && agendas.length === 0 && <p className={classes.noEvents}>No Events</p>}
          {agendas && agendas.length > 0 && agendas.map((dayAgenda, index) => {

            return (
              <Fragment key={index}>
                {dayAgenda.map((event, index) => {
                  return (
                    <div className={classes.agendaEvent} key={event.id}>
                      <div className={classes.eventDetailsColumn}>
                        <div className={classes.flex}>
                          <Today className={classes.calendarIcon} />
                          <span className={classNames(classes.boldText, classes.primaryMainColor, classes.ellipsis)}>{event.name}</span>
                        </div>
                        <div className={classes.flex}>
                          <EventTypeTag calendarEventType={event.calendarEventType} />
                          <div className={classNames(classes.smallText, classes.description, classes.ellipsis)}>
                            <span dangerouslySetInnerHTML={{ __html: event.description }} />
                          </div>
                        </div>
                      </div>

                      <div className={classNames(classes.eventDetailsColumn, classes.eventTimeContainer)}>
                        <div className={classNames(classes.boldText, index !== 0 && classes.lighterBold)}>
                          {moment(event.startDateTime).format('dddd')}
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                          {moment(event.startDateTime).format('l')}
                        </div>
                        <div className={classNames(classes.smallText, classes.ellipsis)}>
                          {event.allDay ? 'All Day'
                            :
                            `${moment(event.startDateTime).format('h:mm A')}
                                -
                              ${moment(event.endDateTime).format('h:mm A')}`
                          }
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            );
          })}
          <DataLoader isLoading={isLoading} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    calendar: {
      selectedEventTypes,
    },
  } = state;

  return {
    selectedEventTypes,
  };
};

const mapDispatchToProps = {
  getWeekAgenda,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DetailedAgenda));
