
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  TextField,
} from '@material-ui/core';
import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
} from '@material-ui/core';
import classNames from 'classnames';
import FilterStatusOption from './FilterStatusOption';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => {

  return {
    spendRange: {
      alignItems: 'center',
      display: 'flex',
      padding: '0 1% 1% 1%',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 10,
      },
    },
    statuses: {
      paddingLeft: '1%',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 30,
      },
      display: 'flex',
      flexDirection: 'column',
    },
    filterHeader: {
      fontSize: 13,
      color: theme.palette.grey[500],
      textTransform: 'uppercase',
      padding: '1%',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 10,
        paddingBottom: 10,
      },
      width: '275px',
    },
    minMaxColor: {
      color: theme.palette.grey[700],
    },
    throughDash: {
      paddingTop: 19,
      paddingLeft: 6,
      paddingRight: 6,
      color: theme.palette.grey[700],
    },
    noMargin: {
      margin: 0,
    },
    rightPaper: {
      paddingTop: 17,
    },
    container: {
      display: 'flex',
      height: 40,
      alignItems: 'center',
      paddingLeft: '1%',
      paddingRight: '1%',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 30,
        paddingRight: 30,
      },

      justifyContent: 'space-between',
      borderColor: theme.palette.secondary.dark,
      borderStyle: 'solid',
      borderWidth: 0,

    },
    label: {
      fontSize: 14,
    },
    amount: {
      fontSize: 12,
      color: theme.palette.grey[500],
    },
    checkBoxContainer: {
      marginLeft: 0,
    },
    checkboxWithLabel: {
      color: theme.palette.grey[100],
      paddingLeft: 0,
      paddingTop: 9,
      paddingBottom: 9,
      marginLeft: -3,
    },
    checkbox: {
      paddingTop: 15,
      paddingBottom: 0,
    },
    checkBoxLabel: {
      color: theme.palette.grey[300],
    },
  };
};

class QuickOrderItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        filterId: 1,
        filterDelivery: false,
        filterPickup: false,
        dateRange: 'today',
      },
      combinedStatus: [],
      filterError: '',
      filterStatus: '',
    };
  }

  componentDidMount() {
    const combinedStatus = this.combined(this.props.statusDelivery, this.props.statusPickup);

    this.setState({ combinedStatus });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.statusDelivery !== this.props.statusDelivery || prevProps.statusPickup !== this.props.statusPickup) {
      const combinedStatus = this.combined(this.props.statusDelivery, this.props.statusPickup);

      this.setState({ combinedStatus });
    }

    if (prevState.filter !== this.state.filter) {
      this.updateFilterProp();
    }

  }

  updateFilterProp = () => {
    const { filter: {
      minSpend,
      maxSpend,
      useMinMax,
    },
    } = this.state;
    let checkUseMinMax = false;

    if (useMinMax) {
      if (minSpend && maxSpend && (typeof parseFloat(minSpend) == 'number') && (typeof parseFloat(maxSpend) == 'number') &&
        (parseFloat(minSpend) < parseFloat(maxSpend))) {
        checkUseMinMax = true;
        this.setState({ filterError: '' });
      } else {
        checkUseMinMax = false;
        this.setState({ filterError: 'Please enter valid numbers' });
      }
    }
    const reviseFilter = { ...this.state.filter, useMinMax: checkUseMinMax };

    this.props.onFilterChanged(reviseFilter);
  }

  handleStatusChange = field => event => {
    const filter = {
      ...this.state.filter,
      [field]: event.target.checked,
    };

    if (field === 'filterPickup') {
      filter.filterDelivery = false;
    }
    if (field === 'filterDelivery') {
      filter.filterPickup = false;
    }
    this.setState({ filter });
  }

  handleMinMaxCheck = field => event => {
    const filter = {
      ...this.state.filter,
      [field]: event.target.checked,
    };

    this.setState({ filter });
  }

  handleDateRangeChange = dateRange => {
    const filter = {
      ...this.state.filter,
      dateRange: dateRange,
    };

    this.setState({ filter });
  }

  handleMinMax = field => event => {
    const filter = {
      ...this.state.filter,
      [field]: event.target.value,
    };

    this.setState({ filter });

  }

  combined = (arry1, arry2) => {
    let combined = [];

    arry1.forEach(item1 => {
      const foundItem = combined.find(combinedItem => combinedItem.name === item1.name);

      if (!foundItem) {
        combined.push({ name: item1.name, count: 0 });
      }
    });

    arry2.forEach(item2 => {
      const foundItem = combined.find(combinedItem => combinedItem.name === item2.name);

      if (!foundItem) {
        combined.push({ name: item2.name, count: 0 });
      }
    });

    arry1.forEach(item1 => {
      const indexOfItem = combined.findIndex(combinedItem => combinedItem.name === item1.name);

      if (indexOfItem > -1) {
        combined[indexOfItem].count += item1.count;
      }
    });

    arry2.forEach(item2 => {
      const indexOfItem = combined.findIndex(combinedItem => combinedItem.name === item2.name);

      if (indexOfItem > -1) {
        combined[indexOfItem].count += item2.count;
      }
    });

    return combined;
  }

  filterOptionClicked = filterStatus => {

    const filter = {
      ...this.state.filter,
      Status: filterStatus,
    };

    this.setState({ filter, filterStatus });

  }

  handleClear = () => {
    const filter = {
      filterDelivery: false,
      filterPickup: false,
      dateRange: 'today',
    };

    this.setState({ filter, filterStatus: '' });

  }

  render() {
    const {
      classes,
    } = this.props;
    const minMaxProps = {
      classes: {
        root: classes.minMaxColor,
      },
    };

    const {
      combinedStatus,
      filter,
      filterError,
      filterStatus,
    } = this.state;

    return (
      <Paper className={classes.rightPaper}>
        <div className={classes.spendRange}>
          <Select
            className={classes.noMargin}
            label="Date Range"
            value={filter.dateRange}
            onFieldChange={this.handleDateRangeChange}
          >
            <MenuItem selected='true' value='today'>Today</MenuItem>
            <MenuItem value='yesterday'>Yesterday</MenuItem>
            <MenuItem value='last7days'>Last 7 days</MenuItem>
            <MenuItem value='last30days'>Last 30 days</MenuItem>
          </Select>
        </div>
        <div className={classes.filterHeader}>Order Statuses</div>

        {combinedStatus && combinedStatus.map(orderStatus => (
          <FilterStatusOption
            isActive={filterStatus === orderStatus.name}
            key={orderStatus.name}
            orderStatus={orderStatus}
            onClick={this.filterOptionClicked}
          />))}

        <div className={classes.spendRange}>
          <MaterialCheckbox
            className={classNames(classes.checkbox, classes.checkboxWithLabel)}
            onChange={this.handleMinMaxCheck('useMinMax')}
            disableRipple={true}
          />
          <TextField
            className={classes.noMargin}
            InputLabelProps={minMaxProps}
            id="minSpend"
            label="Min"
            onChange={this.handleMinMax('minSpend')}
            margin="dense"
          />
          <div className={classes.throughDash}>-</div>
          <TextField
            className={classes.noMargin}
            InputLabelProps={minMaxProps}
            id="maxSpend"
            label="Max"
            onChange={this.handleMinMax('maxSpend')}
            margin="dense"
          />
        </div>
        <div className={classes.spendRange}>
          {filterError && filterError}
        </div>
        <div className={classes.statuses}>
          <FormControlLabel
            classes={{
              root: classes.checkBoxContainer,
              label: classes.checkBoxLabel,
            }}
            control={
              <MaterialCheckbox
                className={classes.checkboxWithLabel}
                disableRipple={true}
                checked={filter.filterDelivery}
                onChange={this.handleStatusChange('filterDelivery')}
              />
            }
            label={'Delivery'}
          />
          <FormControlLabel
            classes={{
              root: classes.checkBoxContainer,
              label: classes.checkBoxLabel,
            }}
            control={
              <MaterialCheckbox
                className={classes.checkboxWithLabel}
                disableRipple={true}
                checked={filter.filterPickup}
                onChange={this.handleStatusChange('filterPickup')}
              />
            }
            label={'Pickup'}
          />
          <Button
            onClick={this.handleClear}
          >Clear Filters</Button>
        </div>
      </Paper>

    );
  }
}
export default withStyles(styles)(QuickOrderItems);
