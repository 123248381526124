import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
} from '@material-ui/core';
import ISetting from 'models/ISetting';
import { ChromePicker } from 'react-color';
import HighlightButton from 'Components/Buttons/HighlightButton';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

const styles = createStyles((theme: Theme) => ({
  colorSettingContainer: {
    display: 'flex',
    marginTop: 10,
    marginLeft: -6,
  },
  colorSettingColorButton: {
    margin: '5px 0',
    padding: 0,
    width: 32,
    height: 32,
    minWidth: 'initial',
  },
  colorSettingColorButtonActive: {
    width: 42,
    height: 42,
    margin: '0 -5px',
  },
  colorSettingAddButton: {
    marginTop: 10,
    marginLeft: 1,
  },
  colorSettingPicker: {
    margin: 'auto 20px',
    height: '100%',
  },
  colorSettingDeleteButon: {
    color: theme.palette.grey[500],
  },
}));

interface IProps extends WithStyles {
  setting: ISetting,
  settingAddOption: (settingKey: string, newValue: any) => void,
  settingDeleteOption: (settingKey: string, optionIndex: number) => void,
  settingOnChange: (settingKey: string, settingValue: any, optionIndex?: number) => void,
}

interface IState {
  selectedColorIndex?: number,
}

const defaultState: IState = {
  selectedColorIndex: null,
}

class AccountColorScheme extends Component<IProps, IState> {
  state = defaultState;

  private onColorClick = (selectedColorIndex: number) => {
    this.setState({
      selectedColorIndex: this.state.selectedColorIndex === selectedColorIndex
        ? null
        : selectedColorIndex,
    });
  }

  private addNewColor = (setting: ISetting) => (event: any) => {
    const { settingAddOption } = this.props;
    const selectedColorIndex = setting.value.length;

    settingAddOption(setting.key, '#000');
    this.setState({ selectedColorIndex });
  }

  public componentDidUpdate(prevProps: IProps) {
    const colorDeleted =
      this.state.selectedColorIndex !== null
      && prevProps.setting.value.length > this.props.setting.value.length

    if (colorDeleted) {
        this.setState({ selectedColorIndex: null });
      }
  }

  public render() {
    const { setting, settingOnChange, settingDeleteOption, classes } = this.props;
    const { selectedColorIndex } = this.state;

    return (
      <div className={classes.colorSettingContainer}>
          <div className={classes.colorSettingButtons}>
            {setting.value.map((color: string, index: number) =>
              <div key={`color-setting-button-${index}`}>
                <Button
                  style={{ backgroundColor: color }}
                  className={
                    classNames(
                      classes.colorSettingColorButton,
                      { [classes.colorSettingColorButtonActive]: index === selectedColorIndex },
                    )
                  }
                  onClick={() => this.onColorClick(index)}
                >
                  <span></span>
                </Button>
                <Button
                  id="delete"
                  className={classes.colorSettingColorButton}
                  onClick={() => settingDeleteOption(setting.key, index)}
                >
                  <DeleteIcon className={classes.colorSettingDeleteButon}/>
                </Button>
              </div>
            )}
            <div className={classes.colorSettingAddButton}>
              <HighlightButton
                onClick={this.addNewColor(setting)}
                aria-label="Add A New Color"
              >
                <AddIcon/>
              </HighlightButton>
            </div>
          </div>
          { selectedColorIndex !== null &&
          <ChromePicker
            disableAlpha={true}
            className={classes.colorSettingPicker}
            color={setting.value[selectedColorIndex]}
            onChangeComplete={(event: any) => settingOnChange(setting.key, event.hex, selectedColorIndex)}
          />
          }
        </div>
    );
  }
}

export default withStyles(styles)(AccountColorScheme);
