
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
  buttonDivider: {
    borderRight: `2px solid ${theme.palette.grey['A200']}`,
    alignSelf: 'center',
    height: 15,
  },
  listContainer: {
    overflowX: 'auto',
  },
  selectedTemplate: {
    backgroundColor: theme.palette.action.inputHighlight,
  },
  template: {
    padding: '6px 16px',
  },
  templateButton: {
    padding: 6,
    color: theme.palette.primary.dark,
    cursor: 'pointer',
  },
  templateButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderTop: `1px solid ${theme.palette.grey['A200']}`,
  },
  templates: {
    fontSize: 15,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 200,
    paddingTop: 8,
    borderRight: `2px solid ${theme.palette.grey['A200']}`,
  },
});

class TemplateManager extends Component {

  onQuickPickSelect = option => () => {
    this.props.onQuickPickSelect(option);
  }

  onManageClicked = () => {
    if (!this.props.onManageClicked) {
      console.error('TemplateManager does not have a onManageClicked event');

      return;
    }
    this.props.onManageClicked();
  }

  render() {
    const {
      classes,
      selectedId,
      options,
    } = this.props;

    return (
      <div className={classes.templates}>
        <div className={classes.listContainer}>
          <ul>
            {options.map(option => (
              <li
                className={classNames(classes.template, option.id === selectedId && classes.selectedTemplate)}
                onClick={this.onQuickPickSelect(option)}
                key={option.id}
              >
                <span>{option.name}</span>
              </li>)
            )}

          </ul>
        </div>
        <div className={classes.templateButtons}>
          <div className={classes.templateButton} onClick={this.onManageClicked}>Manage</div>
          <div className={classes.buttonDivider}></div>
          <div className={classes.templateButton}>Update</div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TemplateManager);
