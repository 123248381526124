import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTitle,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import moment from 'moment';

const styles = theme => ({
  graphContainer: {
    height: '100%',
  },
  totalContainer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'space-between',
  },
  totalDescription: {
    color: theme.palette.grey[200],
    fontSize: '12px',
  },
});

class MenuItemsSales extends React.Component {
  constructor(props) {
    super(props);

    this.chartContainerRef = React.createRef();
    this.chartRef = React.createRef();
  }

  render() {
    const {
      classes,
      data,
      graphcolor,
      compareColor,
      height = '50%',
      width = '100%',
    } = this.props;

    const renderQuantityTooltip = ({ point, dataItem }) => {
      const date = dataItem ? dataItem.date : point.dataItem.date;
      const value = dataItem ? dataItem.quantitySold : point.dataItem.quantitySold;

      return `${moment(date).format("l")} : ${value}`;
    };

    const renderSalesTooltip = ({ point, dataItem }) => {
      const date = dataItem ? dataItem.date : point.dataItem.date;
      const value = dataItem ? dataItem.totalSold : point.dataItem.totalSold;

      return `${moment(date).format("l")} : $${value}`;
    };

    return (
      <div ref={this.chartContainerRef} className={classes.graphContainer}>
        <Chart ref={this.chartRef} style={{ height, width }}>
          <ChartTitle text="Menu Item Sales(Last 30 days)" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartValueAxis>
          <ChartSeries>
            <ChartSeriesItem type="area" data={data.totals} field="totalSold" line={{ style: 'normal' }} markers={{ visible: false }} color={graphcolor} opacity={0.1} />
            <ChartSeriesItem type="line" data={data.totals} field="totalSold" line={{ style: 'normal' }} markers={{ visible: false }} color={graphcolor} opacity={1} />
          </ChartSeries>
          <ChartTooltip render={renderSalesTooltip} />
        </Chart>
        <Chart ref={this.chartRef} style={{ height, width }}>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartValueAxis>
          <ChartSeries>

            <ChartSeriesItem type="line" data={data.totals} field="quantitySold" line={{ style: 'normal' }} markers={{ visible: false }} color={compareColor} opacity={1} />
            <ChartSeriesItem type="area" data={data.totals} field="quantitySold" line={{ style: 'normal' }} markers={{ visible: false }} color={graphcolor} opacity={0.1} />

          </ChartSeries>

          <ChartTooltip render={renderQuantityTooltip} />
        </Chart>
      </div>
    );
  }
}

export default withStyles(styles)(MenuItemsSales);
