import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import ISetting from 'models/ISetting';
import HighlightButton from 'Components/Buttons/HighlightButton';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import CoverTimePicker from 'Components/CoverTimePicker';
import moment from 'moment';
import Table from 'Components/Table';

const column = { width: 160 }
const smallColumn = { width: 80 };

const styles = createStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  setting: {
    marginTop: 15,
    border: `solid 1px ${theme.palette.grey[500]}`,
    borderRadius: 5,
  },
  addButton: {
    marginTop: 15,
    marginLeft: 1,
  },
  addButtonGrid: {
    margin: 'auto',
    textAlign: 'center',
  },
  deleteButton: {
    width: 32,
    marginTop: 20,
    minWidth: 'initial',
  },
  editButton: {
    width: 32,
    minWidth: 'initial',
  },
  icon: {
    color: theme.palette.grey[500],
  },
  throttlingSetting: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  throttlingSettingList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '8px 15px',
    width: '92%',
  },
  throttlingValue: {
    margin: 5,
    padding: '0 5px',
    border: `solid 1px ${theme.palette.grey[500]}`,
    borderRadius: 5,
    color: theme.palette.grey[500],
  },
  scheduleWeek: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  timePicker: {
    width: 125,
    paddingRight: 20,
  },
  scheduleDay: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  modalMain: {
    '& .Grid-grid-361 .k-grid .k-grid-norecords': {
      position: 'relative',
      display: 'block',
    },
    '& .k-grid td': {
      textAlign: 'center',
    },
  },
  crudIcons: {
    color: theme.palette.grey[500],
  },
  tableContainer: {
    height: 210,
    overflowY: 'scroll',
    '& th.k-header': {
      lineHeight: '31px',
      textAlign: 'center',
    },
  },
  gridElement: {
    padding: 5,
  },
  gridElemetInput: {
    height: 25,
    padding: '0 10px',
  },
  table: {
    '& thead tr th': {
      textAlign: 'center',
    },
  },
}));

const defaultSchedule = ['', '', true, true, true, true, true, true, true, [] as IThrottlingValue[]];
const newThrottlingValue = { overload: false, quantity: 0, buffer: 0 };
const throttlingSettingIndex = 9;

interface IThrottlingValue {
  overload: boolean,
  quantity: number,
  buffer: number,
}

interface IProps extends WithStyles {
  setting: ISetting,
  settingAddOption: (settingKey: string, newValue: any) => void,
  settingDeleteOption: (settingKey: string, optionIndex: number) => void,
  settingOnChange: (settingKey: string, settingValue: any, optionIndex?: number) => void,
}

interface IState {
  editOrderThrottlingOpen: boolean,
  throttlingScheduleIndex: number,
  selectedThrottlingValues: IThrottlingValue[],
}

const initialState: IState = {
  editOrderThrottlingOpen: false,
  throttlingScheduleIndex: null,
  selectedThrottlingValues: [{ ...newThrottlingValue }],
}

class grazeOrderThrottling extends Component<IProps, IState> {
  public state = initialState;

  private onTimeFieldChange = (scheduleIndex: number, settingIndex: number) => (value: any) => {
    const { setting, settingOnChange } = this.props;
    const timeValue = value ? moment(value).clone().format('LT') || '' : null;

    const settingValue = [ ...setting.value[scheduleIndex] ];
    settingValue[settingIndex] = timeValue;

    settingOnChange(setting.key, settingValue, scheduleIndex);
  }

  private settingOnChange = (scheduleIndex: number, settingIndex: number) => (event: any) => {
    const { setting, settingOnChange } = this.props;

    const settingValue = [ ...setting.value[scheduleIndex] ];
    settingValue[settingIndex] = event.target.checked;

    settingOnChange(setting.key, settingValue, scheduleIndex);
  }

  private settingEditOption = (throttlingScheduleIndex: number) => (event: any) => {
    const { setting } = this.props;
    const selectedThrottlingSchedule = setting.value[throttlingScheduleIndex];
    const selectedThrottlingValues =
      selectedThrottlingSchedule[throttlingSettingIndex] && selectedThrottlingSchedule[throttlingSettingIndex].length !== 0
        ? JSON.parse(JSON.stringify(
            (selectedThrottlingSchedule[throttlingSettingIndex].sort((a: IThrottlingValue, b: IThrottlingValue) => a.quantity - b.quantity)))
        )
        : [{ ...newThrottlingValue }];

    this.setState({ editOrderThrottlingOpen: true, selectedThrottlingValues, throttlingScheduleIndex });
  }

  private throttlingSettingOnChange = (throttlingSettingPropName: string, throttlingSettingIndex: number) => (event: any) => {
    const selectedThrottlingValues = [ ...this.state.selectedThrottlingValues ];

    selectedThrottlingValues[throttlingSettingIndex][throttlingSettingPropName] = throttlingSettingPropName === 'overload' ? event.target.checked : event.target.value;

    this.setState({ selectedThrottlingValues });
  }

  private addThrottling = () => {
    this.setState({
      selectedThrottlingValues: [
        ...this.state.selectedThrottlingValues,
        { ...newThrottlingValue },
      ]
    });
  }

  private deletethrottling = (throttlingValue: IThrottlingValue) => (event: any) => {
    const { selectedThrottlingValues } = this.state;

    this.setState({ selectedThrottlingValues: selectedThrottlingValues.filter(value => value !== throttlingValue) });
  }

  private saveEdit = () => {
    const { setting, settingOnChange } = this.props;
    const { throttlingScheduleIndex, selectedThrottlingValues } = this.state;
    const selectedThrottlingSchedule = [ ...setting.value[throttlingScheduleIndex] ];

    selectedThrottlingSchedule[throttlingSettingIndex] = [ ...selectedThrottlingValues ];

    settingOnChange(setting.key, selectedThrottlingSchedule, throttlingScheduleIndex);
    this.setState({ editOrderThrottlingOpen: false });
  }

  private getDateFromTime= (time: string) => {
    if (!time) {
      return null;
    }

    const date = moment(new Date()).format('L'); // d = "12/12/2017"

    return moment(date +' '+ time).format();
  }

  private getSettingName = (settingIndex: number) => {
    switch(settingIndex) {
      case 0:
        return 'Start Time';
      case 1:
        return 'End Time';
      case 2:
        return 'Su';
      case 3:
        return 'Mo';
      case 4:
        return 'Tu';
      case 5:
        return 'We';
      case 6:
        return 'Th';
      case 7:
        return 'Fr';
      case 8:
        return 'Sa';
      default:
        return '';
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const newThrottlingScheduleAdded = prevProps.setting.value.length < this.props.setting.value.length;

    if (newThrottlingScheduleAdded) {
      const newThrottlingScheduleIndex = this.props.setting.value.length - 1;
      this.settingEditOption(newThrottlingScheduleIndex)(null);
    }
  }

  public render() {
    const { setting, settingAddOption, settingDeleteOption, classes } = this.props;
    const { editOrderThrottlingOpen, selectedThrottlingValues } = this.state;

    return (
      <div className={classes.container}>
          {setting.value.map((schedule: any[] , scheduleIndex: number) =>
          <div key={`schedule-week-${scheduleIndex}`} className={classes.setting}>
            <div className={classes.scheduleWeek}>
              <Button
                id="delete"
                className={classes.deleteButton}
                onClick={() => settingDeleteOption(setting.key, scheduleIndex)}
              >
                <DeleteIcon className={classes.icon}/>
              </Button>
              {schedule.map((setting: any, settingIndex: number) =>
                <div key={`schedule-setting-${scheduleIndex}-${settingIndex}`}>
                  {settingIndex < 2
                  ?
                  <CoverTimePicker
                    className={classes.timePicker}
                    name={`${this.getSettingName(settingIndex)}-${scheduleIndex}-${settingIndex}`}
                    label={this.getSettingName(settingIndex)}
                    value={this.getDateFromTime(setting)}
                    onFieldChange={this.onTimeFieldChange(scheduleIndex, settingIndex)}
                  />
                  : settingIndex < throttlingSettingIndex
                    ? <FormControlLabel
                        className={classes.scheduleDay}
                        label={this.getSettingName(settingIndex)}
                        control={
                          <Checkbox
                            name={this.getSettingName(settingIndex)}
                            checked={setting}
                            onChange={this.settingOnChange(scheduleIndex, settingIndex)}
                          />
                        }
                      />
                    : null
                  }
                </div>
              )}
            </div>
            <div className={classes.throttlingSetting}>
              <Button
                id="edit"
                className={classes.editButton}
                onClick={this.settingEditOption(scheduleIndex)}
              >
                <EditIcon className={classes.icon}/>
              </Button>
              <div className={classes.throttlingSettingList}>
                {!!schedule[throttlingSettingIndex] && schedule[throttlingSettingIndex].length !== 0
                  ? schedule[throttlingSettingIndex]
                      .sort((a: IThrottlingValue, b: IThrottlingValue) => a.quantity - b.quantity)
                      .map((throttlingValue: IThrottlingValue) =>
                        <span className={classes.throttlingValue}>
                          {`From ${throttlingValue.quantity} order${throttlingValue.quantity > 1 ? 's' : ''} - ${throttlingValue.overload ? 'set to overload' : `add ${throttlingValue.buffer} minutes`}`}
                        </span>
                      )
                  : <span>Please add throttling settings</span>
                }
              </div>
            </div>
          </div>
          )}
          <div className={classes.addButton}>
            <HighlightButton
              onClick={() => settingAddOption(setting.key, defaultSchedule)}
              aria-label="Add a New Throttling"
            >
              <AddIcon/>
            </HighlightButton>
          </div>
          <Modal
            isOpened={editOrderThrottlingOpen}
            dimensions={{
              width: 507,
              height: 350,
            }}
            onCancel={() => this.setState({ editOrderThrottlingOpen: false })}
            onSave={this.saveEdit}
            saveText="Ok"
            title="Edit Order Throttling"
            addTitleBottomBorder={true}
          >
            <div>
            <div className={classes.modalMain}>
            <div className={classes.content}>
              <div className={classes.tableContainer}>
                <Table
                  className={classes.table}
                  wrapWithTbody={false}
                  header={
                    <tr className={classes.headerRow}>
                      <th scope="col" style={smallColumn}>Overload</th>
                      <th scope="col" style={column}>From Orders Quantity</th>
                      <th scope="col" style={column}>Add Buffer Minutes</th>
                      <th scope="col" style={smallColumn}>
                        <div className={classes.addButtonGrid}>
                          <HighlightButton
                            onClick={this.addThrottling}
                            aria-label="Add A New Throttling"
                          >
                            <AddIcon/>
                          </HighlightButton>
                        </div>
                      </th>
                    </tr>
                  }
                >
                  <tbody>
                    {selectedThrottlingValues.map((throttlingValue, index) =>
                      <tr key={`table-tr-${index}`}>
                        <td style={{ textAlign: 'center', padding: 0 }}>
                          <Checkbox
                            className={classes.gridElement}
                            checked={throttlingValue.overload}
                            onClick={this.throttlingSettingOnChange('overload', index)}
                          />
                        </td>
                        <td>
                          <TextField
                            id="quantity"
                            type="number"
                            className={classes.gridElement}
                            InputProps={{ className: classes.gridElemetInput, inputProps: { min: 0 } }}
                            variant="outlined"
                            value={throttlingValue.quantity}
                            onChange={this.throttlingSettingOnChange('quantity', index)}
                          />
                        </td>
                        <td className={classes.chargeColumn}>
                          <TextField
                            id="buffer"
                            type="number"
                            className={classes.gridElement}
                            InputProps={{ className: classes.gridElemetInput, inputProps: { min: 0 } }}
                            variant="outlined"
                            disabled={throttlingValue.overload}
                            value={throttlingValue.overload ? 0 : throttlingValue.buffer}
                            onChange={this.throttlingSettingOnChange('buffer', index)}
                          />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Button>
                            <DeleteIcon
                              className={classes.crudIcons}
                              onClick={this.deletethrottling(throttlingValue)}
                              aria-label={'Delete Throttling'}
                            />
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
            </div>
          </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(grazeOrderThrottling);
