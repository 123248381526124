import React from 'react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  container: {
    padding: '22px 25px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  hidden: {
    visibility: 'hidden',
  },
  red: {
    color: theme.palette.error.dark,
    textTransform: 'initial',
  },
});

const nextButtonWidth = { minWidth: 129 };
const StepFooter = ({ isLast, isFirst, isDeleteHidden, handleNext, handleBack, handleDeleteSection, classes, lastStepText, isNextDisabled }) => (
  <div className={classes.container}>
    <PrimaryButton
      onClick={handleBack}
      className={classNames(isFirst && classes.hidden)}
    >
      Back
    </PrimaryButton>

    <Button
      onClick={handleDeleteSection}
      variant="outlined"
      className={classNames(classes.red, isDeleteHidden && classes.hidden)}
    >
      Delete Section
    </Button>

    <PrimaryButton
      onClick={handleNext}
      disabled={isNextDisabled}
      style={nextButtonWidth}
    >
      {isLast ? lastStepText || 'Finish' : 'Next'}
    </PrimaryButton>
  </div>
);

export default withStyles(styles)(StepFooter);
