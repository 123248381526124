import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  container: {
    display: 'flex',
    height: 40,
    alignItems: 'center',
    paddingLeft: '1%',
    paddingRight: '1%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },

    justifyContent: 'space-between',
    borderColor: theme.palette.secondary.dark,
    borderStyle: 'solid',
    borderWidth: 0,
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, .9),
    },
  },
  label: {
    fontSize: 14,
  },
  amount: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  isActive: {
    borderLeftWidth: 3,
    paddingLeft: '1%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 27,
    },
    backgroundColor: lighten(theme.palette.secondary.main, .9),
    color: theme.palette.secondary.dark,
  },
  notActive: {
    color: theme.palette.grey[800],
  },
});

class FilterOptions extends Component {
  clicked = () => {
    this.props.onClick(this.props.option);
  }

  render() {
    const {
      classes,
      option: {
        name,
        amount,
      },
      isActive,
    } = this.props;

    return (
      <div
        className={classNames(classes.container, isActive ? classes.isActive : classes.notActive)}
        onClick={this.clicked}>
        <div className={classes.label}>{name}</div>
        <div className={classes.amount}>
          {amount}
        </div>
      </div>
    );
  }

}

export default withStyles(styles)(FilterOptions);
