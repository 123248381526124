import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from 'Components/Table';
import { FormattedInput } from 'Components/FormattedInput';

const styles = theme => ({
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  table: {
    fontSize: 12,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    '& th': {
      padding: '6px 13px',
    },
    '& tr': {
      borderTop: 0,
      borderBottom: 0,
    },
    '& td': {
      borderLeft: `1px solid ${theme.palette.grey[50]}`,
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
      paddingLeft: 8,
    },
  },
  rowLabel: {
    color: theme.palette.grey[300],
    paddingTop: 11,
    paddingBottom: 11,
  },
});

const TaxRatesTable = ({ classes, revenueConfiguration, rateOverwrites, onRateUpdated }) => {

  const getRate = revenueTypeId => {
    const rate = revenueConfiguration.revenueTypeRates.find(rate => rate.revenueTypeId === revenueTypeId);

    if (rateOverwrites && rateOverwrites.length > 0) {
      const overwrite = rateOverwrites.find(rate => rate.revenueTypeId === revenueTypeId);

      if (overwrite) {
        return overwrite;
      }
    }

    return rate;
  };

  const formatPercentNumber = number => {
    const percentValue = number > 0 && number < 1 ? number.toString().split('.')[1] : 0;

    return `${percentValue}000000`.slice(0, 7);
  };

  const idPattern = [
    { char: /\d/, repeat: 2 },
    { exactly: "." },
    { char: /\d/, repeat: 4 },
    { exactly: "%" },
  ];

  return (
    <Table
      className={classes.table}
      header={
        <tr className={classes.header}>
          <th scope="col">Type</th>
          <th scope="col">Tax</th>
          <th scope="col">Service</th>
          <th scope="col">Gratuity</th>
        </tr>
      }
    >
      {revenueConfiguration && revenueConfiguration.revenueTypeRates && revenueConfiguration.revenueTypeRates.map(revenueTypeRate => {
        const rate = getRate(revenueTypeRate.id);

        return (
          <tr key={revenueTypeRate.revenueTypeId}>
            <th scope="row" className={classes.rowLabel}>
              {revenueTypeRate.revenueType.name}
            </th>
            <td>
              <FormattedInput
                className="formatted-input"
                format={idPattern}
                value={formatPercentNumber(rate.taxPercentage)}
                onChange={value => onRateUpdated({ ...rate, taxPercentage: parseFloat(value) / 100 })}
                placeholder="##.####%"
                style={{ width: 70 }}
              />
            </td>
            <td>
              <FormattedInput
                className="formatted-input"
                format={idPattern}
                value={formatPercentNumber(rate.serviceChargePercentage)}
                onChange={value => onRateUpdated({ ...rate, serviceChargePercentage: parseFloat(value) / 100 })}
                placeholder="##.####%"
                style={{ width: 70 }}
              />
            </td>
            <td>
              <FormattedInput
                className="formatted-input"
                format={idPattern}
                value={formatPercentNumber(rate.gratuityPercentage)}
                onChange={value => onRateUpdated({ ...rate, gratuityPercentage: parseFloat(value) / 100 })}
                placeholder="##.####%"
                style={{ width: 70 }}
              />
            </td>
          </tr>);
      })}
    </Table>
  );
};

export default withStyles(styles)(TaxRatesTable);
