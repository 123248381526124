import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Radio } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Grid from 'Components/Grid/Grid';
import { GridColumn } from '@progress/kendo-react-grid';
import HighlightButton from 'Components/Buttons/HighlightButton';

const styles = theme => ({
  crudIcons: {
    '&:hover $modifyIcon': {
      visibility: 'visible',
    },
  },
  modifyIcon: {
    visibility: 'hidden',
    cursor: 'pointer',
    marginRight: 7,
    marginLeft: 3,
    color: theme.palette.grey[700],
    fontSize: 26,
  },
  gridButton: {
    padding: 0,
  },
  gridEditButton: {
    padding: 0,
    border: 'none',
  },
});

const ProcessorGrid = ({ classes, merchantAccounts, editMerchantAccount, setDefaultMerchantAccount }) => {
  const defaultMerchantAccountId = merchantAccounts.length ? merchantAccounts[0].id : '';

  return (
    <Grid
      items={
        merchantAccounts
          .map(account => { return { ...account, name: `${account.name} - ${account.connectedAccountId}` }; })
          .sort((a, b) => a.name > b.name ? 1 : -1)
      }
    >
      <GridColumn
        field="id"
        title="Default"
        width="66px"
        cell={props => (
          <td style={{ textAlign: 'center', padding: 0 }}>
            <Radio
              className={classes.gridButton}
              checked={props.dataItem.id === defaultMerchantAccountId}
              onClick={() => setDefaultMerchantAccount(props.dataItem.id)}
            />
          </td>
        )}
      />
      <GridColumn field="name" title="Name" />
      <GridColumn field="status" title="Status" width="81px" />
      <GridColumn
        title="Edit"
        width="47px"
        cell={props => (
          <td style={{ textAlign: 'center', padding: 0 }}>
            <HighlightButton
              onClick={() => editMerchantAccount(props.dataItem.id)}
              aria-label={`Edit ${props.dataItem.name} account`}
              className={classes.gridEditButton}
            >
              <Edit/>
            </HighlightButton>
          </td>
        )}
      />
    </Grid>
  );
};

export default withStyles(styles)(ProcessorGrid);
