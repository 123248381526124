import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Tooltip,
  Button,
} from '@material-ui/core';
import {
  GridOn as GridIcon,
} from '@material-ui/icons';
import {
  // getTaxConfig,
  deleteStoreOrderItem,
} from 'actions/order';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import StoreOrderItemModifier from './wizard/ModifierModal/StoreOrderItemModifier';
import {
  Clear as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import {
  Card,
  CardMedia,
} from '@material-ui/core';
import OrderManagerAddItemModal from './OrderManagerAddItemModal';
import StoreMenuItemDescription from 'Components/StoreMenuItemDescription';
import Avatar from 'Components/Avatar';
import AlacarteModal from './alacarte';
import { provideIntlService } from '@progress/kendo-react-intl';
import FinancialsSummary from './FinancialsSummary';
import QuickFacts from './QuickFacts';
import SimpleDialog from 'Components/SimpleDialog';

const thumbnailWidth = 119;

const styles = theme => ({
  menu: {
    display: 'flex',
    backgroundColor: theme.palette.common.paper,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
  },
  toolBarButton: {
    padding: 0,
    minHeight: 32,
    minWidth: 32,
    marginRight: 10,
    borderColor: theme.palette.action.active,
  },
  iconStyle: {
    color: theme.palette.common.black,
    margin: 0,
    height: 18,
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    position: 'absolute',
    padding: 5,
  },
  thumbnail: {
    display: 'flex',
    width: thumbnailWidth,
    height: thumbnailWidth,
  },
  cardRoot: {
    display: 'flex',
    color: theme.palette.text.primary,
    margin: 8,
    '&:hover $cardCrudIcons': {
      visibility: 'visible',
    },
  },
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    padding: 8,
  },
  cardDetail: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardTopRow: {
    display: 'flex',
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardName: {
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 25,
    maxWidth: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cardDescription: {
    whiteSpace: 'normal',
    margin: '6px 0px 0px 25px',
    wordBreak: 'break-word',
    maxWidth: 300,
    overflow: 'hidden',
  },
  cardDescriptionText: {
    overflow: 'hidden',
    position: 'relative',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    marginLeft: 25,
    maxWidth: 200,
  },
  cardThumbnail: {
    display: 'flex',
    width: thumbnailWidth,
    height: thumbnailWidth,
  },
  cardCrudIcons: {
    visibility: 'hidden',
    marginLeft: -56,
    padding: 1,
    backgroundColor: fade(theme.palette.common.white, 0.5),
    height: 27,
    borderRadius: '0 0 0 4px',
  },
  cardModifyIcon: {
    color: theme.palette.grey[900],
    fontSize: 26,
    cursor: 'pointer',
  },
  edit: {
    '&:hover': {
      color: theme.palette.grey[700],
    },
  },
  delete: {
    '&:hover': {
      color: theme.common.red,
    },
  },
  itemList: {
    width: '100%',
    overflow: 'auto',
  },
  quickDetails: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'space-between',
    borderLeft: '2px solid #E6EAEE',
    flexWrap: 'wrap',
  },
  financialDetails: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'space-between',
    borderLeft: '2px solid #E6EAEE',
    flexWrap: 'wrap',
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemCost: {
    minWidth: 50,
  },
  label: {
  },
  subLabel: {
  },
  itemDetails: {
    display: 'flex',
    width: '235px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  itemQtyContainer: {
    display: 'flex',
    border: '1px solid #2189F8',
    borderRadius: 4,
    padding: '0px 2px 0px 4px',
  },
  itemQtyLabel: {
    paddingRight: 2,
    textAlign: 'left',
  },
  itemQtyValue: {
    width: '40px',
    textAlign: 'center',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  itemPrice: {
    fontSize: '11px',
    color: '#2189F8',
    paddingRight: 5,
  },
  itemTotalPrice: {
    fontSize: '11px',
    paddingRight: 5,
  },
  itemButtonContainer: {
    display: 'flex',
    marginTop: '4px',
  },
  itemButton: {
    padding: 4,
    display: 'flex',
    fontSize: '11px',
    color: '#2189F8',
    textTransform: 'none',
    minWidth: '30px',
  },
  imageContainer: {
    margin: 15,
    width: '119px',
    height: '93px',
  },
  img: {
    margin: 'auto',
    minWidth: '100%',
    minHeight: '100%',
    width: '115px',
    height: '75px',
  },
  itemContainer: {
    display: 'flex',
    paddingBottom: 5,
    borderBottom: '1px solid #E6EAEE',
    alignItems: 'center',
    marginTop: 5,
  },
  itemName: {
    width: '100%',
    display: 'flex',
    fontSize: 15,
    fontWeight: 'bold',
  },
  modifierContainer: {
    width: '100%',
    textAlign: 'left',
    fontSize: '11px',
  },
  modifierSelected: {
    fontSize: '11px',
  },
  editDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    padding: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  button: {
    width: '126px',
    height: '32px',
    borderRadius: 4,
  },
  sectionTitle: {
    padding: 5,
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    fontSize: '18px',
    backgroundColor: '#F2F2F2',
    paddingLeft: 22,
    height: '35px',
    position: 'sticky',
    top: 0,
  },
  orderNumber: {
    position: 'absolute',
    top: '33px',
    left: '12px',
    fontSize: '15px',
  },
  addItemButton: {
    display: 'flex',
    alignSelf: 'flex-end',
    textTransform: 'initial',
    minHeight: 25,
    maxHeight: 25,
    maxWidth: 85,
    fontSize: '12px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    marginRight: 25,
  },
  priceContainer: {
    display: 'flex',
    padding: '8px',
    marginLeft: '11px',
  },
  priceQty: {
    padding: 4,
    display: 'flex',
    fontSize: 12,
    color: '#2189F8',
    textTransform: 'none',
    minWidth: '30px',
  },
  priceDivider: {
    borderRight: '1px solid',
    alignSelf: 'center',
    height: 12,
    color: '#2189F8',
    marginRight: 5,
    marginLeft: 5,
  },
  price: {
    padding: 4,
    display: 'flex',
    fontSize: 12,
    textTransform: 'none',
    minWidth: '30px',
  },
});

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxConfig: {},
      modifiersModalVisible: false,
      selectedOrderItem: {},
      addItemModalVisible: false,
      alacarteModalVisible: false,
    };
    this.formatter = provideIntlService(this);
    this.noAssignedOnlineMenusDialog = React.createRef();
  }

  toolBar = () => {
    const classes = this.props.classes;

    return (<>
      <Tooltip title="Edit Modifiers" placement="top">
        <Button variant="outlined" aria-label="Edit Modifiers" mini className={classNames(classes.toolBarButton)} onClick={this.toggleModifiers}>
          M
        </Button>
      </Tooltip>
      <Tooltip title="Edit Grid" placement="top">
        <Button variant="outlined" aria-label="Edit Grid" mini className={classNames(classes.toolBarButton)}>
          <GridIcon className={classes.iconStyle} />
        </Button>
      </Tooltip>
    </>);
  }

  toggleModifiers = () => {
    this.setState({ showModifiers: !this.state.showModifiers });
  }

  toggleAddItems = () => {
    const { isEditing, onEdit, storeMenus } = this.props;

    if (storeMenus.length === 0) {
      this.noAssignedOnlineMenusDialog.current.open('No Quick Order Menu Assigned');

      return;
    }

    if (!isEditing) onEdit();

    this.setState({ addItemModalVisible: true });
  }

  onAddItemModalClose = () => {
    this.setState({ addItemModalVisible: false });
  }

  onModifiersModalSave = data => {
    this.setState({
      modifiersModalVisible: false,
    });
  }

  updateModifersForSelectedItem = returnModifiers => {
    const { selectedMenuItem } = this.state;
    const orderItem = {};

    orderItem.menuCategoryItem = selectedMenuItem;
    orderItem.menuCategoryItemId = selectedMenuItem.id;
    orderItem.orderItemModifiers = returnModifiers.modifiers;
    orderItem.quantity = returnModifiers.quantity;

    this.props.updateStoreManagedOrderItem(orderItem);

    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  getImage = item => {
    if (item.imageUrl) {
      return item.imageUrl;
    }
    if (item.menuCategoryItem && item.menuCategoryItem.imageUrl) {
      return item.menuCategoryItem.imageUrl;
    }
  }

  onItemEdit = itemSelected => {
    const { isEditing, onEdit } = this.props;

    if (!isEditing) onEdit();

    if (itemSelected.orderItem.menuCategoryItemId) {
      this.setState({
        selectedOrderItem: itemSelected,
        modifiersModalVisible: true,
      });
    } else {
      this.setState({
        selectedOrderItem: itemSelected,
        alacarteModalVisible: true,
      });
    }
  }

  onItemDelete = itemSelected => {
    const { isEditing, onEdit } = this.props;

    if (!isEditing) onEdit();

    this.props.deleteStoreOrderItem({ orderItem: itemSelected.orderItem, index: itemSelected.index });
  }

  onAlacarteModalSave = data => {
    this.setState({
      alacarteModalVisible: false,
    });
  }

  onOpenAlacarteModal = data => {
    this.setState({
      alacarteModalVisible: true,
    });
  }

  getModifiedUnitPrice = orderItem => {
    const modifiersPrice = orderItem.orderItemModifiers && Object.values(orderItem.orderItemModifiers)
      .filter(orderItemModifier => orderItemModifier.recordStatus === 'Active')
      .map(orderItemModifier => orderItemModifier.menuItemModifier && orderItemModifier.menuItemModifier.price ? orderItemModifier.menuItemModifier.price : 0)
      .reduce((prev, next) => (prev ? prev : 0) + (next ? next : 0), 0);

    return this.formatter.formatNumber(orderItem.unitPrice + modifiersPrice, 'c');
  }

  render() {
    const { classes, storeOrder, storeAllMenuItems } = this.props;
    const {
      modifiersModalVisible,
      selectedOrderItem,
      addItemModalVisible,
      alacarteModalVisible,
    } = this.state;

    return (
      <div className={classes.menu}>
        <div className={classes.column}>
          <div className={classes.sectionTitle}>Cart</div>

          <div className={classes.form}>
            { storeAllMenuItems ?
              <Button
                variant="outlined"
                className={classes.addItemButton}
                aria-label="Add Item"
                onClick={e => this.toggleAddItems()}>
                Add Item
              </Button>
              :
              <></>
            }
          </div>
          <div className={classes.itemList}>
            {storeOrder.orderItems && storeOrder.orderItems.filter(item => item.recordStatus === 'Active' || !item.recordStatus).map((orderItem, index) => (
              <div key={`orderItem-${index}`}>
                <Card
                  classes={{ root: classes.cardRoot }}
                >
                  <div className={classes.cardContent}>
                    <div className={classes.cardThumbnail}>
                      {!!this.getImage(orderItem) ?
                        <CardMedia
                          style={{ width: thumbnailWidth, height: thumbnailWidth }}
                          image={this.getImage(orderItem)}
                        />
                        :
                        <Avatar name={orderItem.name} />
                      }
                    </div>
                    <div className={classes.cardDetail}>
                      <div className={classes.cardTopRow}>
                        <div className={classes.cardDetails}>
                          <p className={classes.cardName}>{orderItem.name ? orderItem.name : 'orderItem'}</p>
                          <p className={classes.cardDescriptionText} dangerouslySetInnerHTML={{ __html: orderItem.description }}></p>
                        </div>
                      </div>
                      <div className={classes.cardTopRow}>
                        <div className={classes.cardDescription}>
                          <StoreMenuItemDescription item={orderItem} />
                        </div>
                      </div>
                      <div className={classes.priceContainer}>
                        <div className={classes.priceQty}>{this.getModifiedUnitPrice(orderItem)} x {orderItem.quantity}</div>
                        <div className={classes.priceDivider}></div>
                        <div className={classes.price}>{this.formatter.formatNumber(orderItem.price, 'c')}</div>
                      </div>
                    </div>
                  </div>

                  <div className={classes.cardCrudIcons}>
                    <EditIcon onClick={e => this.onItemEdit({ orderItem, index })}
                      className={classNames(classes.cardModifyIcon, classes.edit)} />
                    <DeleteIcon onClick={e => this.onItemDelete({ orderItem, index })}
                      className={classNames(classes.cardModifyIcon, classes.delete)} />
                  </div>
                </Card>
              </div>
            ))}
          </div>
          {modifiersModalVisible &&
            <StoreOrderItemModifier
              onModalClosed={this.onModifiersModalSave}
              updateSetsOfModifiers={this.updateModifersForSelectedItem}
              selectedOrderItem={selectedOrderItem}
            />}
          {addItemModalVisible &&
            <OrderManagerAddItemModal
              onClose={this.onAddItemModalClose}
            />}
          {alacarteModalVisible && <AlacarteModal
            onModalClosed={this.onAlacarteModalSave}
            selectedOrderItem={selectedOrderItem}
          />}
        </div>

        <div className={classes.column}>
          <div className={classes.quickDetails}>
            <QuickFacts storeOrder={storeOrder} />
          </div>
          <div className={classes.financialDetails}>
            <FinancialsSummary storeOrder={storeOrder} />
          </div>
        </div>
        <SimpleDialog
          innerRef={this.noAssignedOnlineMenusDialog}
          onlyOkayButton={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeOrder,
      storeAllMenuItems,
      storeMenus,
    },
  } = state;

  return {
    storeOrder,
    storeAllMenuItems,
    storeMenus,
  };
};

const mapDispatchToProps = {
  deleteStoreOrderItem,
  //  getTaxConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Menu));
