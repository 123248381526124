import {
  RECEIVE_LEADS,
  FETCH_TAX_TYPES_BEGIN,
  FETCH_ALL_TAX_TYPES_SUCCESS,
  FETCH_REVENUE_TYPES_SUCCESS,
  FETCH_INVOICES_SUCCESS,
  RECEIVE_MODIFIER_CATEGORIES,
  RECEIVE_OPERATORS,
  RECEIVE_MODIFIERS,
  MODIFIERS_REQUEST_BEGIN,
  RECEIVE_CATEGORIES,
  RECEIVE_SETS,
  RECEIVE_SALES_REPS,
  FETCH_BOOKINGS_SUCCESS,
  RECEIVE_CALENDAR_EVENTS,
  BOOKINGS_SELECTED,
  FETCH_BOOKINGS_BEGIN,
  FETCH_CHITCHAT_MESSAGES_BEGIN,
  FETCH_CHITCHAT_MESSAGES_SUCCESS,
  FETCH_CHITCHAT_MESSAGES_FAILURE,
  CONFIRMED_BOOKINGS_TOGGLED,
  FETCH_ACCOUNTS_SUCCESS,
  ACCOUNT_RECEIVED,
  RESET_CURRENT_ACCOUNT,
  CLONE_ACCOUNT,
  ON_SCHEDULER_DAY_SELECTED,
  ROOM_RECEIVE,
  SETUP_STYLES_RECEIVED,
  PACKAGES_RECEIVED,
  SITES_RECEIVED,
  REFERENCES_RECEIVED,
  BOOKING_CATEGORIES_RECEIVED,
  BOOKING_THEMES_RECEIVED,
  BOOKING_EVENT_RECEIVED,
  RECEIVE_PROPOSAL_RELATIONSHIPS,
  RECEIVE_ACCOUNT_NAME_AND_IDS,
  CURRENT_LOCATION_SET,
  FETCH_DEFAULT_TAX_TYPES_SUCCESS,
  PARENT_ROOMS_RECEIVED,
  BOOKING_EVENT_TYPES_RECEIVED,
} from 'actions/constants';
import _ from 'lodash';

const initialState = {
  currentLocation: null, //the currently selected location from the top nav.
  bookings: {
    data: [],
    selectedBookingIds: [],
    isLoading: true,
    isConfirmedToggled: true,
  },
  categories: [],
  invoices: [],
  modifierCategories: [],
  modifiers: {
    data: [],
    isLoading: true,
  },
  accounts: {
    isLoading: true,
    data: [],
    selectedIds: [],
    nameAndIds: [],
  },
  contacts: {
    data: [],
    selectedIds: [],
    nameAndIds: [],
  },
  leads: {
    isLoading: true,
    data: [],
    selectedIds: [],
  },
  rooms: [],
  parentRooms: [],
  salesReps: {
    salesRepsData: [],
  },
  setupStyles: [],
  calendarEvents: {
    json: {},
    params: {
      eventTypes: [],
    },
  },
  schedulerDateSelected: new Date(),
  packages: [],
  sites: [],
  references: [],
  bookingCategories: [],
  bookingThemes: [],
  bookingEventTypes: [],
  chitChat: {
    threads: [],
    isLoading: false,
  },
  taxTypes: {
    default: [],
    all: [],
    isLoading: false,
  },
  proposalRelationships: [],
};

export function api(state = initialState, action) {
  switch (action.type) {
    case BOOKING_EVENT_RECEIVED: {
      const { response } = action;

      return {
        ...state,
        bookingEvent: response,
      };
    }
    case SITES_RECEIVED: {
      const { sites } = action;

      return {
        ...state,
        sites: sites,
      };
    }
    case RECEIVE_MODIFIERS: {
      const { response: { data, meta } } = action;

      return {
        ...state,
        modifiers: {
          ...state.modifiers,
          data,
          loading: false,
          meta,
        },
      };
    }
    case MODIFIERS_REQUEST_BEGIN: {
      const { params } = action;

      return {
        ...state,
        modifiers: {
          ...state.modifiers,
          loading: true,
          params,
        },
      };
    }

    case RECEIVE_LEADS: {
      const { data, append } = action;
      const { leads } = state;

      return {
        ...state,
        leads: {
          ...leads,
          isLoading: false,
          data: append ? state.leads.data.concat(data) : data,
        },
      };
    }

    case RECEIVE_SETS:
      const { sets } = action;

      return {
        ...state,
        sets,
      };

    case FETCH_INVOICES_SUCCESS:
      const { invoices } = action;

      return {
        ...state,
        invoices,
      };

    case FETCH_BOOKINGS_BEGIN: {
      const { params } = action;
      const { bookings } = state;

      return {
        ...state,
        bookings: {
          ...bookings,
          isLoading: true,
          params,
        },
      };
    }

    case FETCH_BOOKINGS_SUCCESS:
      const { data, append } = action;
      const { bookings } = state;

      return {
        ...state,
        bookings: {
          ...bookings,
          isLoading: false,
          data: append ? bookings.data.concat(data) : data,
        },
      };

    case BOOKINGS_SELECTED:
      const { bookingIdHash } = action;
      const { bookings: { selectedBookingIds } } = state;
      const bookingKeys = Object.keys(bookingIdHash);
      let newSelectedBookingIds = [];

      bookingKeys.forEach(key => {
        if (bookingIdHash[key] === true) {
          newSelectedBookingIds.push(key);
        }
      });
      newSelectedBookingIds.sort();
      if (_.isEqual(newSelectedBookingIds, selectedBookingIds)) {
        return state;
      }

      return {
        ...state,
        bookings: {
          ...state.bookings,
          selectedBookingIds: newSelectedBookingIds,
        },
      };

    case CONFIRMED_BOOKINGS_TOGGLED:
      const { isConfirmedToggled } = action;

      return {
        ...state,
        bookings: {
          ...state.bookings,
          isConfirmedToggled,
        },
      };
    case FETCH_REVENUE_TYPES_SUCCESS:
      const { revenueTypes } = action;

      return {
        ...state,
        revenueTypes,
      };
    case FETCH_ALL_TAX_TYPES_SUCCESS:
      const { allTaxTypes } = action;

      return {
        ...state,
        taxTypes: {
          all: allTaxTypes,
          isLoading: false,
        },
      };
    case FETCH_DEFAULT_TAX_TYPES_SUCCESS:
      const { defaultTaxTypes } = action;

      return {
        ...state,
        taxTypes: {
          default: defaultTaxTypes,
          isLoading: false,
        },
      };
    case RECEIVE_MODIFIER_CATEGORIES: {
      const { modifierCategories } = action;

      return {
        ...state,
        modifierCategories,
      };
    }
    case RECEIVE_OPERATORS:
      const { operators } = action;

      return {
        ...state,
        operators,
      };
    case RECEIVE_CATEGORIES: {
      const { categories } = action;

      return {
        ...state,
        categories,
      };
    }
    case RECEIVE_SALES_REPS: {
      const { salesRepsData } = action;

      return {
        ...state,
        salesReps: {
          salesRepsData,
        },
      };
    }
    case RECEIVE_CALENDAR_EVENTS: {
      const { json, params } = action;

      return {
        ...state,
        calendarEvents: {
          json,
          params,
        },
      };
    }
    case FETCH_CHITCHAT_MESSAGES_BEGIN: {
      const { chitChat: { threads } } = state;

      return {
        ...state,
        chitChat: {
          threads,
          isLoading: true,
        },
      };
    }
    case FETCH_CHITCHAT_MESSAGES_SUCCESS: {
      const { threads } = action;

      return {
        ...state,
        chitChat: {
          threads,
          isLoading: false,
        },
      };
    }
    case FETCH_CHITCHAT_MESSAGES_FAILURE: {
      return {
        ...state,
        chitChat: {
          isLoading: false,
        },
      };
    }
    case FETCH_ACCOUNTS_SUCCESS: {
      const { data, append } = action;
      const { accounts } = state;

      return {
        ...state,
        accounts: {
          ...accounts,
          isLoading: false,
          data: append ? state.accounts.data.concat(data) : data,

        },
      };
    }
    case ACCOUNT_RECEIVED:
      const { account } = action;

      return {
        ...state,
        currentAccount: account,
      };
    case RESET_CURRENT_ACCOUNT:
      return {
        ...state,
        currentAccount: {},
      };
    case CLONE_ACCOUNT:
      return {
        ...state,
      };
    case RECEIVE_ACCOUNT_NAME_AND_IDS: {
      const { nameAndIds } = action;
      const { accounts } = state;

      return {
        ...state,
        accounts: {
          ...accounts,
          nameAndIds,
        },
      };
    }
    case ON_SCHEDULER_DAY_SELECTED: {
      const { dateSelected } = action;

      return {
        ...state,
        schedulerDateSelected: dateSelected,
      };
    };
    case PARENT_ROOMS_RECEIVED: {
      const { parentRooms } = action;

      return {
        ...state,
        parentRooms,
      };
    };
    case ROOM_RECEIVE: {
      const { rooms } = action;

      return {
        ...state,
        rooms,
      };
    };
    case SETUP_STYLES_RECEIVED: {
      const { setupStyles } = action;

      return {
        ...state,
        setupStyles,
      };
    };

    case PACKAGES_RECEIVED: {
      const { packages } = action;

      return {
        ...state,
        packages,
      };
    };

    case REFERENCES_RECEIVED: {
      const { references } = action;

      return {
        ...state,
        references,
      };
    };
    case BOOKING_CATEGORIES_RECEIVED: {
      const { bookingCategories } = action;

      return {
        ...state,
        bookingCategories,
      };
    };
    case BOOKING_THEMES_RECEIVED: {
      const { bookingThemes } = action;

      return {
        ...state,
        bookingThemes,
      };
    };
    case BOOKING_EVENT_TYPES_RECEIVED: {
      const { bookingEventTypes } = action;

      return {
        ...state,
        bookingEventTypes,
      };
    };
    case RECEIVE_PROPOSAL_RELATIONSHIPS: {
      const { proposalRelationships } = action;

      return {
        ...state,
        proposalRelationships,
      };
    };
    case CURRENT_LOCATION_SET: {
      const { location } = action;

      return {
        ...state,
        currentLocation: location,
      };
    }
    case FETCH_TAX_TYPES_BEGIN:
      return {
        ...state,
        taxTypes: {
          ...state.taxTypes,
          isLoading: true,
        },
      };
    default:
      return state;
  }
}
