import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  DeviceHub as TriForce,
} from '@material-ui/icons';
import Table from 'Components/Table';
import {
  SortableContainer,
  arrayMove,
} from 'react-sortable-hoc';
import PickListOptionRow from './PickListOptionRow';

const SortableList = SortableContainer(({ pickList, onEdit, onDelete }) => {
  return (
    <tbody>
      {pickList.pickListOptions
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((item, index) =>
          <PickListOptionRow
            key={`picklistoption-${item.id}`}
            index={index}
            item={{ ...item, index }}
            onItemEdit={onEdit}
            onItemDelete={onDelete}
          />
        )
      }
    </tbody>
  );
});

const styles = theme => ({
  table: {
    borderColor: theme.palette.grey[50],
    '& th': {
      textAlign: 'center',
    },
    '& td': {
      borderWidth: 1,
      textAlign: 'center',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
    '& tbody th': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
  },
});
const smallColumn = { width: 60 };

class PicklistOptionsTable extends Component {
  state = {};

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onSort(arrayMove(this.props.pickList.pickListOptions, oldIndex, newIndex));
  };

  render() {
    const {
      classes,
      pickList,
      onItemEdit,
      onItemDelete,
    } = this.props;

    return (
      <Table
        className={classes.table}
        wrapWithTbody={false}
        header={
          <tr className={classes.headerRow}>
            <th scope="col" style={smallColumn}>Order</th>
            <th scope="col">Option</th>
            <th scope="col" style={smallColumn}><TriForce /></th>
            <th scope="col" style={smallColumn}>Disabled</th>
          </tr>
        }
      >
        {pickList && <SortableList
          pickList={pickList}
          onEdit={onItemEdit}
          onDelete={onItemDelete}
          onSortEnd={this.onSortEnd}
          lockAxis="y"
          useDragHandle={true}
        />}

      </Table >);
  }
}

export default withStyles(styles)(PicklistOptionsTable);

