import React, { useContext } from 'react';
import NewOrderContext from 'orders/wizard/NewOrderContext';
import PrimaryButtonWithSpinner from 'Components/Buttons/PrimaryButtonWithSpinner';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  container: {
    padding: '22px 25px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  hidden: {
    visibility: 'hidden',
  },
  red: {
    color: theme.palette.error.dark,
    textTransform: 'initial',
    borderColor: theme.palette.error.dark,
    left: '50px',
  },
  blue: {
    textTransform: 'initial',
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
    right: '45px',
  },
  centerButton: {
    fontSize: '13px',
    height: '32px',
    minWidth: '129px',
  },
});

const nextButtonWidth = { minWidth: 129 };
const StepFooter = ({ closeDialog, isFirst, isLast, handleNext, handleBack, classes, lastStepText, isNextDisabled, isSavingOrder, isPlacingOrder }) => {
  const { saveAndClose } = useContext(NewOrderContext);

  return (
    <div className={classes.container}>
      <PrimaryButton className={classNames({ [classes.hidden]: isFirst })}
        onClick={handleBack}
      >
      Back
      </PrimaryButton>

      <Button
        onClick={closeDialog(false)}
        variant="outlined"
        className={classNames(classes.centerButton, classes.red)}
      >
      Cancel
      </Button>

      <Button
        onClick={saveAndClose}
        variant="outlined"
        className={classNames(classes.centerButton, classes.blue)}
        disabled={isSavingOrder}
      >
      Save & Close
      </Button>

      <PrimaryButtonWithSpinner
        onClick={isLast ? saveAndClose : handleNext}
        disabled={isNextDisabled}
        style={nextButtonWidth}
        isLoading={isPlacingOrder}
      >
        {isLast ? lastStepText || 'Finish' : 'Next'}
      </PrimaryButtonWithSpinner>
    </div>
  );
};

export default withStyles(styles)(StepFooter);
