import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    borderRadius: '5px',
    padding: '5px',
    width: '64px',
    height: '22px',
    lineHeight: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '12px',
    color: theme.common.white,
  },
  green: {
    backgroundColor: theme.common.green,
  },
  gray: {
    backgroundColor: theme.palette.grey['200'],
  },
  red: {
    backgroundColor: theme.common.red,
  },
});

export class InvoiceStatusTag extends Component {
  render() {
    const { status, late, classes } = this.props;

    let text;
    let style;

    switch (status) {
      case 0:
        if (late) {
          text = 'Late';
          style = classes.red;
          break;
        }
        text = 'Not Paid';
        style = classes.gray;
        break;
      case 1:
        text = 'Paid';
        style = classes.green;
        break;
      default:
        return null;
    }

    return (
      <div className={`${classes.root} ${style}`}>{text}</div>
    );
  }
}

export default withStyles(styles)(InvoiceStatusTag);
