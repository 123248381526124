import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StoreOrderWizardModal from './StoreOrderWizardModal';
import StepFooter from './StoreOrderWizardModal/ModalFooter';
import StepHeader from './StoreOrderWizardModal/StepHeader';
import NewOrderContext from './NewOrderContext';
import GeneralDetails from './GeneralDetails';
import StoreOrderReview from './StoreOrderReview';
import Place from './Place';
import Menu from './Menu';
import NotesWizard from 'Components/NotesWizard';
import SectionContext from 'Components/Contexts/SectionContext';
import {
  setStoreOrder,
  updateStoreOrderAPI,
  updateStoreOrder,
  createStoreOrder,
  updateContactAddressesAndStoreOrderAPI,
} from 'actions/order';
import {
  getStatuses,
} from 'actions/settings';
import {
  clearWorkingAddresses,
} from 'actions/address';
import {
  currentContactReset,
  updateCurrentContact,
  saveContact,
} from 'actions/contact';
import {
  addStatusHistory,
} from 'actions/statusHistory';
import { getCategories, getTags } from 'actions/item';
import { getStoreMenu } from 'actions/storemenu';
import { ENTITY_TYPES } from 'constants/entityTypes';
import { MenuType } from 'models/MenuType';

const steps = ['General', 'Menu', 'Preview', 'Payment'];

export class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      isNotesOpen: false,
      order: {},
    };
  }

  componentDidMount() {
    this.props.currentContactReset();
    this.props.clearWorkingAddresses();
    this.props.createStoreOrder(this.props.contactId);
    this.props.getStatuses();
    this.getData();
    this.setState({
      isOpened: true,
      activeStep: 0,
    });
  }

  onClose = () => {
    const { history } = this.props;

    this.setState({
      isOpened: false,
      isNotesOpen: false,
    });
    this.props.onClose();
    this.props.currentContactReset();
    this.props.clearWorkingAddresses();
    history.push(`/orders`);
  }

  handleNext = () => {
    //what was checked???
    const {
      activeStep,
      isNotesOpen,
    } = this.state;

    if (activeStep === 0) {
      this.props.updateContactAddressesAndStoreOrderAPI();

      this.setState({
        activeStep: 1,
        sectionContext: {
          relationship: {
            entityId: this.props.storeOrder.id,
            entityType: ENTITY_TYPES.order,
          },
        },
      });

      return;
    }

    if (isNotesOpen) {
      this.setState({ isNotesOpen: false });

      return;
    }

    this.setState({
      activeStep: activeStep + 1,
    });
  };

  handleBack = () => {
    const {
      activeStep,
      isNotesOpen,
    } = this.state;

    if (isNotesOpen) {
      this.setState({ isNotesOpen: false });

      return;
    }

    if (activeStep > 0) {
      this.setState({
        activeStep: activeStep - 1,
      });
    }
  };

  getStepContent = () => {
    const {
      activeStep,
      isNotesOpen,
    } = this.state;

    if (isNotesOpen || activeStep === 'Notes') {
      return <NotesWizard maxHeight={511} />;
    }

    if (activeStep === 0) {
      return <GeneralDetails />;
    }
    const step = steps[activeStep];

    if (step === 'Menu') {
      return <Menu />;
    }
    if (step === 'Preview') {
      return <StoreOrderReview handleStepClick={this.handleStepClick} />;
    }
    if (step === 'Payment') {
      return <Place />;
    }

    return <h3>{step} Setup</h3>;
  }

  onClickNotes = () => {
    this.setState(prevState => ({ isNotesOpen: !prevState.isNotesOpen }));
  }

  handleStepClick = stepIndex => {
    this.setState({ activeStep: stepIndex, isNotesOpen: false });
  }

  setIsNextDisabled = isNextDisabled => {
    this.setState({ isNextDisabled });
  }

  getData = () => {
    this.props.getTags();
    this.props.getStoreMenu(MenuType.QuickOrder);
  }

  saveAndClose = () => {
    const { activeStep, isNotesOpen } = this.state;
    const { storeOrder, history, updateContactAddressesAndStoreOrderAPI, setStoreOrder, statuses, updateStoreOrder, addStatusHistory } = this.props;
    const isLastStep = activeStep === steps.length - 1 && !isNotesOpen;

    if (isLastStep) {
      this.setState({ isPlacingOrder: true });
      const orderStatus = statuses.find(status => status.type === `${storeOrder.type}Order`)
        .statuses.find(status => status.systemStatusCode === 'Placed');

      const finishedStoreOrder = {
        ...storeOrder,
        orderStatusId: orderStatus.id,
        orderStatus: undefined,
      };

      setStoreOrder(finishedStoreOrder);
      if (finishedStoreOrder.orderStatusId) addStatusHistory('Order', finishedStoreOrder.id, finishedStoreOrder.orderStatusId);
    } else { // else storeOrder is ready to save to API
      this.setState({ isSavingOrder: true });
      const orderStatus = statuses.find(status => status.type === `${storeOrder.type}Order`)
        .statuses.find(status => status.systemStatusCode === 'Created');

      const inProgressStoreOrder = {
        ...storeOrder,
        orderStatusId: orderStatus.id,
        orderStatus: undefined,
      };

      updateStoreOrder(inProgressStoreOrder);
      if (inProgressStoreOrder.statusId) addStatusHistory('Order', inProgressStoreOrder.id, orderStatus.id);
    }

    updateContactAddressesAndStoreOrderAPI().then(() => {
      this.onClose();
      history.push(`/orders/${storeOrder.id}`);
      this.setState({ isSavingOrder: false, isPlacingOrder: false });
    }); //TODO: error handling
  };

  render() {
    const {
      activeStep,
      isOpened,
      isNotesOpen,
      sectionContext,
      isNextDisabled,
      isSavingOrder,
      isPlacingOrder,
    } = this.state;

    if (!isOpened) {
      return null;
    }

    const context = {
      setIsNextDisabled: this.setIsNextDisabled,
      saveAndClose: this.saveAndClose,
    };

    const wizardThemeColor = '#0095a4';

    return (
      <SectionContext.Provider value={sectionContext}>
        <NewOrderContext.Provider value={context}>
          <StoreOrderWizardModal
            isOpened={true}
            height={762}
            title={
              <StepHeader
                activeStep={activeStep}
                steps={steps}
                title="Order"
                onClickNotes={this.onClickNotes}
                notesWizard={isNotesOpen}
                onStepClick={this.handleStepClick}
                wizardThemeColor={wizardThemeColor}
              />}
            compFooter={
              <StepFooter
                lastStepText="Place"
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                isLast={activeStep === steps.length - 1 && !isNotesOpen}
                isFirst={activeStep === 0}
                isDeleteHidden={true}
                isNextDisabled={isNextDisabled}
                isSavingOrder={isSavingOrder}
                isPlacingOrder={isPlacingOrder}
              />}
            onClose={this.onClose}
            closeAlert={true}
          >
            {this.getStepContent()}
          </StoreOrderWizardModal>
        </NewOrderContext.Provider>
      </SectionContext.Provider>
    );
  }
}

const mapDispatchToProps = {
  setStoreOrder,
  getCategories,
  getStoreMenu,
  getTags,
  updateStoreOrderAPI,
  updateStoreOrder,
  createStoreOrder,
  getStatuses,
  currentContactReset,
  updateCurrentContact,
  saveContact,
  clearWorkingAddresses,
  updateContactAddressesAndStoreOrderAPI,
};

const mapStateToProps = state => {
  const {
    api: {
      currentLocation,
    },
    storeReducer: {
      storeOrder,
    },
    settings: {
      statuses,
    },
    address: {
      workingAddresses,
      selectedAddressId,
    },
    contact: {
      currentContact,
    },
  } = state;

  return {
    currentLocation,
    storeOrder,
    statuses,
    workingAddresses,
    selectedAddressId,
    currentContact,
    addStatusHistory,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wizard));
