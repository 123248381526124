import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import Header from 'Components/Header';
import Breadcrumb from 'Components/Breadcrumb';
import ItemGrid from './ItemGrid';
import { getRevenueTypes } from 'actions/item';
import { getMenuItemModifierTemplates } from 'actions/menuItemModifier';
import { getMenuItems, getAllTags } from 'actions/menu';
import { widgetGraphs } from 'helpers/enums';
import MenuItemWidgtes from './MenuItemWidgets';

const styles = theme => ({

  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '3%',
  },
  mainContent: {
    display: 'flex',
    flex: '1 0 auto',
  },
  appBarSpacing: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class ItemManagement extends Component {
  allCategories = {
    name: 'ALL',
    id: 0,
  };

  state = {
    selectedOption: this.allCategories,
    summary: {
      totalCount: 0,
      totalPrice: 0,
    },
    isEditingCategories: false,
  };

  componentDidMount() {
    this.props.getRevenueTypes();
    this.props.getMenuItems();
    this.props.getAllTags();
    this.props.getMenuItemModifierTemplates();
  }

  getTranslations() {
    if (!this.translations) {
      const localizationService = provideLocalizationService(this);

      this.translations = {
        itemManagement: localizationService.toLanguageString('itemManagement.label'),
        itemLibrary: localizationService.toLanguageString('itemManagement.library'),
      };
    }

    return this.translations;
  }

  startEditingCategories = () => {
    this.setState({ isEditingCategories: true });
  }

  stopEditingCategories = () => {
    this.setState({ isEditingCategories: false });
  }

  render() {
    const {
      classes,
      company,
    } = this.props;
    const translations = this.getTranslations();
    const {
      selectedOption = {},
    } = this.state;

    return (
      <>
        <Header> {this.translations.itemManagement} </Header>
        <Breadcrumb>
          <Link disabled to="/admin/item-management">{translations.itemManagement}</Link>
          <Link to="/admin/company">{company.name}</Link>
          <Link disabled to="/admin/item-management">{translations.itemLibrary}</Link>
        </Breadcrumb>
        <div className={classes.mainContent}>
          <div className={classes.gridContainer}>
            <ItemGrid selectedCategory={selectedOption} />
          </div>
          <MenuItemWidgtes />
        </div>
      </>
    );
  }
}

ItemManagement.propTypes = {
  items: PropTypes.array.isRequired,
  company: PropTypes.object.isRequired,
};

registerForLocalization(ItemManagement);

const mapStateToProps = state => {
  const {
    menuItem: {
      data,
    },
    admin: {
      company,
    },
    widgets,
  } = state;

  return {
    itemLibraryProductsSettings: widgets[widgetGraphs.itemLibraryProducts],
    itemLibraryPriceSettings: widgets[widgetGraphs.itemLibraryPrice],
    itemLibraryCostSettings: widgets[widgetGraphs.itemLibraryCost],
    company: company || {},
    items: data,
  };
};

const mapDispatchToProps = {
  getMenuItems,
  getMenuItemModifierTemplates,
  getAllTags,
  getRevenueTypes,
};

const ItemManagementContainer = withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ItemManagement)));

export default ItemManagementContainer;
