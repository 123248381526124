import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import SimpleDialog from 'Components/SimpleDialog';
import Modal from 'Components/Modal';
import DataLoader from 'Components/DataLoader';
import Checkbox from 'Components/Checkbox';
import SectionHeader from 'Components/SectionHeader';
import TextField from 'Components/TextField';
import AddressFields from 'Components/AddressFields';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { addLead } from 'actions/lead';
import { addAccount } from 'actions/account';
import { handleComboBoxPick } from 'Components/handleComboBoxChange';
import { addToRecents } from 'actions/recents';
import { getRelationships } from 'actions/proposal';
import AddressSearch from 'Components/AddressSearch';
import { handleAddressSearchDone } from 'shared/GoogleApi';
import { ENTITY_TYPES } from 'constants/entityTypes';

const styles = () => {
  return {
    overflowIfTooBig: {
      overflow: 'auto',
      height: 535,
    },
    fieldsContent: {
      display: 'flex',
      flexGrow: 1,
      margin: '0 16px 16px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '50%',
    },
    row: {
      display: 'flex',
    },
    halfWidth: {
      width: '50%',
    },
  };
};

class NewLeadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newRecord: {
        ...props.newLead,
        isNew: true,
        locationId: props.currentLocation.id,
      },
      contact: {},
      invalidFields: [],
      isNewAccount: false,
      statesMap: {},
      addressParts: {
        streetAddress1Number: null,
        streetAddress1Route: null,
      },
    };
    this.confirmCancel = React.createRef();
    this.errorMessage = React.createRef();
    this.requiredFields = ['name'];
  }

  editField = fieldName => value => {
    const newRecord = {
      ...this.state.newRecord,
      [fieldName]: value,
    };

    this.setState({ newRecord });
  }

  editAddressField = fieldName => value => {
    let newRecord = {
      ...this.state.newRecord,
      address: {
        ...this.state.newRecord.address,
        [fieldName]: value,
      },
    };

    this.setState({ newRecord });
    console.log(newRecord);
  }

  addNewRecord = () => {
    const { isNewAccount } = this.state;

    if (this.isFormValid()) {
      this.setState({ isSaving: true });

      return isNewAccount ? this.addNewAccount() : this.addNewLead();
    }
  }

  addNewLead = () => {
    const { addLead, onClose, getRelationships, addToRecents } = this.props;
    const newRecord = {
      ...this.state.newRecord,
      leadAddress: this.state.newRecord.address,
    };

    delete newRecord.address;

    return addLead(newRecord).then(newLeadId => {
      getRelationships()
        .then(() => {
          addToRecents(newRecord, `/lead/${newLeadId}`, ENTITY_TYPES.lead);
          onClose({ newLeadId });
        })
        .catch(this.handleError);
    });
  }

  addNewAccount = () => {
    const { addAccount, onClose, getRelationships } = this.props;
    const newRecord = {
      ...this.state.newRecord,
      accountAddress: this.state.newRecord.address,
    };

    delete newRecord.address;

    return addAccount(newRecord).then(account => {
      getRelationships()
        .then(() => {
          addToRecents(newRecord, `/accounts/${account.id}`, ENTITY_TYPES.account);
          onClose({ newAccountId: account.id });
        })
        .catch(this.handleError);
    });
  }

  handleError = () => {
    this.errorMessage.current.open('Something went wrong. Please try again.');
    this.setState({ isSaving: false });
  }

  onCancel = () => {
    const { onClose } = this.props;
    const { newRecord } = this.state;

    if (!!newRecord) {
      this.confirmCancel.current.open('Are you sure you want to lose your changes?').then(onClose);
    } else {
      onClose();
    }
  }

  isFieldInvalid = fieldName => {
    return this.state.invalidFields.indexOf(fieldName) !== -1;
  }

  validateField = fieldName => {
    var isValid = true;
    var value = this.state.newRecord[fieldName];

    switch (fieldName) {
      case 'email':
        const emailResult = validate({ email: value }, { email: { email: true } });

        isValid = !emailResult;
        break;
      case 'name':
        const nameResult = validate({ name: value }, { name: { presence: { allowEmpty: false } } });

        isValid = !nameResult;
        break;
      default:
        return;
    }

    let invalidFields = [...this.state.invalidFields];
    const indexOfField = invalidFields.indexOf(fieldName);

    if (!isValid) {
      if (indexOfField === -1) {
        invalidFields.push(fieldName);
      }
    } else {
      if (indexOfField !== -1) {
        invalidFields.splice(indexOfField, 1);
      }
    }
    this.setState({ invalidFields });
  }

  isFieldInvalid = fieldName => {
    return this.state.invalidFields.indexOf(fieldName) !== -1;
  }

  isFormValid = () => {
    return this.state.invalidFields.length === 0;
  }

  handleCheckbox = event => {
    const isNewAccount = event.target.checked;

    this.setState({ isNewAccount });
  }

  handleComboBox = fieldName => pick => {
    handleComboBoxPick(this.editField, fieldName, pick);
  }

  onAddressSearchDone = selection => {
    handleAddressSearchDone(selection, this.editAddressField);
  }

  render() {
    const {
      classes,
      isOpened,
    } = this.props;
    const {
      newRecord,
      isSaving,
      isNewAccount,
    } = this.state;
    const entityType = isNewAccount ? 'Account' : 'Lead';

    return (
      <Modal
        title={`Add New ${entityType}`}
        isOpened={isOpened}
        onCancel={this.onCancel}
        onSave={this.addNewRecord}
        saveText={`Add ${entityType}`}
        isSaveDisabled={isSaving}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '85%' }}
      >
        <Paper className={classes.overflowIfTooBig}>
          <SectionHeader className={classes.sectionHeader}>General</SectionHeader>
          <div className={classes.fieldsContent}>
            <div className={classes.column}>
              <TextField
                label={'Name'}
                value={newRecord.name}
                error={this.isFieldInvalid('name')}
                onBlur={() => this.validateField('name')}
                onFieldChange={this.editField('name')}
              />
              <TextField
                label={'Email'}
                value={newRecord.email}
                error={this.isFieldInvalid('email')}
                onBlur={() => this.validateField('email')}
                onFieldChange={this.editField('email')}
              />
            </div>
            <div className={classes.column}>
              <TextField
                label={'Phone'}
                value={newRecord.phone}
                onFieldChange={this.editField('phone')}
              />
              {!isNewAccount && <TextField
                label={'Title'}
                value={newRecord.title}
                onFieldChange={this.editField('title')}
              />}
            </div>
          </div>
          <SectionHeader className={classes.sectionHeader}>Address Information</SectionHeader>
          <div className={classes.fieldsContent}>
            <div className={classes.column}>
              <AddressSearch
                onSelection={this.onAddressSearchDone}
              />
              <Checkbox
                label="Add as Account"
                checked={isNewAccount}
                onChange={this.handleCheckbox}
              />
            </div>
            <div className={classes.column}>
              <AddressFields
                address={newRecord.address || {}}
                onFieldChange={this.editAddressField}
                isEditing={true}
              />
            </div>
          </div>
        </Paper>
        <DataLoader isLoading={isSaving} inline={true} />
        <SimpleDialog innerRef={this.confirmCancel} />
        <SimpleDialog onlyOkayButton={true} innerRef={this.errorMessage} />
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: { currentLocation },
  } = state;

  return {
    currentLocation,
  };
};

const mapDispatchToProps = {
  getRelationships,
  addLead,
  addAccount,
  addToRecents,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NewLeadModal));
