import { createUserManager } from 'redux-oidc';

const thisSite = window.location.origin; //will be like http://localhost:3000

const userManagerConfig = {
  authority: window.env.REACT_APP_GATEWAY,
  client_id: window.env.REACT_APP_CLIENT_ID,
  redirect_uri: `${thisSite}/signin-oidc/`,
  response_type: 'id_token token',
  scope: 'openid profile cover-api payment-gateway-api',
  post_logout_redirect_uri: thisSite,
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/silent-refresh.html`,
  clockSkew: 600, // 10 min
};

const userManager = createUserManager(userManagerConfig);

const logout = () => {
  return (_dispatch, getState) => {
    const idToken = getState().oidc.user.id_token;

    userManager.signoutRedirect({ id_token_hint: idToken });
    userManager.removeUser();
  };
};

export { logout };

export default userManager;
