import React, { Component } from 'react';
import { withStyles, TableRow, TableCell } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import WarningIcon from '@material-ui/icons/Warning';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import SimpleDialog from 'Components/SimpleDialog';
import AddEditEventModal from 'calendar/AddEditEventModal';
import moment from 'moment';
import { deleteCalendarEvent, markTaskCompleted } from 'actions/calendar';
import { connect } from 'react-redux';

const styles = theme => ({
  row: {
    height: '70px',
  },
  dotCell: {
    paddingRight: 0,
    textAlign: 'center',
  },
  dot: {
    width: '9px',
    height: '9px',
    borderRadius: '4.5px',
    display: 'inline-block',
    textAlign: 'center',
  },
  title: {
    fontSize: '14px',
    fontWeight: '300',
  },
  subtitle: {
    color: theme.palette.grey['200'],
    fontSize: '12',
    fontWeight: '100',
  },
  iconButton: {
    color: theme.palette.grey[200],
    padding: 8,
  },
  ellipsisCell: {
    color: theme.palette.grey['200'],
    width: '16px',
    textAlign: 'right',
    paddingRight: '24px !important',
  },
  green: {
    backgroundColor: theme.common.green,
  },
  blue: {
    backgroundColor: theme.common.blue,
  },
  red: {
    backgroundColor: theme.common.red,
  },
  redText: {
    color: theme.common.red,
  },
  overdue: {
    color: theme.common.yellow,
  },
});

class Task extends Component {

  state = {
    isEditingEvent: false,
    optionsMenu: null,
  }

  confirmDeleteDialog = React.createRef();

  openOptionsMenu = event => {
    this.setState({ optionsMenu: event.currentTarget });
  };

  closeOptionsMenu = () => {
    this.setState({ optionsMenu: null });
  };

  openCalendarEventModal = () => {
    this.closeOptionsMenu();
    this.setState({ isEditingEvent: true });
  }

  closeCalendarEventModal = () => {
    this.setState({ isEditingEvent: false });
  }

  onDeleteTask = () => {
    const { task, deleteCalendarEvent, onRefresh } = this.props;

    this.closeOptionsMenu();
    this.confirmDeleteDialog.current.open()
      .then(() => {
        deleteCalendarEvent && deleteCalendarEvent(task.id).then(onRefresh);
      })
      .catch(() => {/* noop */});
  }

  onCompleteTask = () => {
    const { task, markTaskCompleted, onRefresh } = this.props;

    this.closeOptionsMenu();
    markTaskCompleted && markTaskCompleted(task.id).then(onRefresh);
  }

  getDotColor(classes, dateStr, now) {
    const date = moment(dateStr);
    const diff = date.diff(now, 'days');

    if (diff === 0 || (diff < 1 && diff > -1)) {
      return classes.blue;
    }
    if (diff > 0) {
      return classes.green;
    }
    if (diff < 0) {
      return classes.red;
    }
  }

  getDueText(dateStr, now) {
    const date = moment(dateStr);
    const diff = date.diff(now, 'days');

    if (diff === 0 || (diff < 1 && diff > -1)) {
      return 'Today';
    }
    if (diff > 0) {
      return `${diff} ${diff > 1 ? 'Days' : 'Day'} from Today`;
    }
    if (diff < 0) {
      return `${Math.abs(diff) + 1} ${diff < -1 ? 'Days' : 'Day'} Past Due`;
    }
  }

  isOverdue(dateStr, now) {
    const date = moment(dateStr);
    const diff = date.diff(now, 'days');

    return diff < 0 ? true : false;
  }

  render() {
    const { task, classes } = this.props;
    const { isEditingEvent, optionsMenu } = this.state;
    const now = moment();
    const isOverdue = this.isOverdue(task.startTime, now);
    const titleClass = isOverdue ? classes.redText : '';

    return (
      <TableRow className={classes.row} key={task.id + 'tasks'}>
        <TableCell className={classes.dotCell}>
          {!isOverdue &&
          <div className={`${classes.dot} ${this.getDotColor(classes, task.startTime, now)}`} />}
          {isOverdue &&
          <div className={classes.overdue}>
            <WarningIcon />
          </div>}
        </TableCell>
        <TableCell>
          <div className={`${classes.title} ${titleClass}`}>
            {task.name}
          </div>
          <div className={classes.subtitle}>
            {moment(task.startTime).format('M/D/YYYY')} | {this.getDueText(task.startTime, now)}
          </div>
        </TableCell>
        <TableCell className={classes.ellipsisCell}>
          <IconButton
            className={classes.iconButton}
            onClick={this.openOptionsMenu}
            aria-label="Task options"
            aria-owns="task-widget-menu"
            aria-haspopup="true"
          >
            <MoreHoriz fontSize={'large'} />
          </IconButton>
          {task.eventType === "task" && <Menu
            id="task-widget-menu"
            anchorEl={optionsMenu}
            open={Boolean(optionsMenu)}
            onClose={this.closeOptionsMenu} >
            <MenuItem onClick={this.openCalendarEventModal}>Edit</MenuItem>
            {!task.dateCompleted &&
                <MenuItem onClick={this.onCompleteTask}>Mark As Completed</MenuItem>
            }
            <MenuItem onClick={this.onDeleteTask}>Delete</MenuItem>
          </Menu>}
        </TableCell>
        <SimpleDialog
          message="Are you sure you want to delete this task?"
          innerRef={this.confirmDeleteDialog}
        />
        {isEditingEvent && (
          <AddEditEventModal
            onModalClosed={this.closeCalendarEventModal}
            appointment={task}
            onRefresh={this.props.onRefresh}
            hasRelationship={!!task.bookingId} // only Bookings have linked Tasks right now, update if needed
          />
        )}
      </TableRow>
    );
  }
}

const mapDispatchToProps = {
  deleteCalendarEvent,
  markTaskCompleted,
};

export default withStyles(styles)(connect(undefined, mapDispatchToProps)(Task));
