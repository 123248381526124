import React, { Component } from 'react';
import { Paper, TableRow, TableCell, TableBody, Table, div, withStyles } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { connect } from 'react-redux';
import { getInvoices } from 'actions/invoice';
import InvoiceStatusTag from './InvoiceStatusTag';
import * as moment from 'moment';
import { provideIntlService } from '@progress/kendo-react-intl';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24,
  },
  heading: {
    padding: 24,
    fontSize: '18px',
    fontWeight: '500',
    borderBottom: `1px solid ${theme.palette.grey['50']}`,
  },
  scrollArea: {
    overflowY: 'auto',
    flexGrow: 1,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  more: {
    color: theme.palette.grey['200'],
    fontSize: '14px',
    fontWeight: 'normal',
    float: 'right',
  },
  row: {
    height: '70px',
  },
  title: {
    fontSize: '14px',
    fontWeight: '300',
  },
  subtitle: {
    color: theme.palette.grey['200'],
    fontSize: '12px',
    fontWeight: '100',
  },
  ellipsisCell: {
    color: theme.palette.grey['200'],
    width: '16px',
    textAlign: 'center',
    paddingRight: '40px !important',
  },
  red: {
    color: theme.common.red,
  },
});

export class InvoicesWidget extends Component {
  componentWillMount() {
    this.props.getInvoices();
  }

  getDueText(invoice, now) {
    if (invoice.status === 1) {
      return '';
    }
    const red = this.props.classes.red;
    const date = moment(invoice.date);
    const diff = date.diff(now, 'days');

    if (diff === 0 || (diff < 1 && diff > -1)) {
      return <div>Payment due today</div>;
    }
    if (diff > 0) {
      return <div>Payment due in {diff} {diff > 1 ? 'days' : 'day'}</div>;
    }
    if (diff < 0) {
      return <div className={red}>{Math.abs(diff) + 1} {diff < -1 ? 'Days' : 'Day'} Late</div>;
    }
  }

  render() {
    const { classes, invoices } = this.props;
    const now = moment();

    return (
      <Paper className={classes.root}>
        <h3 className={classes.heading}>Invoices<span className={classes.more}>All Invoices <ArrowRight /></span></h3>
        <div className={classes.scrollArea}>
          <Table>
            <TableBody>
              {invoices && invoices.map(i => <TableRow className={classes.row} key={i.id + 'invoice'}>
                <TableCell>
                  <div className={classes.title}>
                    {i.name}
                  </div>
                  <div className={classes.subtitle}>
                    {i.bookingName}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.title}>
                    {moment(i.date).format('M/D/YYYY')}
                  </div>
                  <div className={classes.subtitle}>
                    {this.getDueText(i, now)}
                  </div>
                </TableCell>
                <TableCell style={{ width: '5em' }}>
                  <InvoiceStatusTag status={i.status} late={moment(i.date).isBefore(now, 'day')} />
                </TableCell>
                <TableCell style={{ width: '5em' }}>
                  {provideIntlService(this).formatNumber(i.price, 'c')}
                </TableCell>
                <TableCell className={classes.ellipsisCell}>
                  <MoreHoriz fontSize={'large'} />
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  const { invoices } = state.api;

  return {
    invoices,
  };
};

const mapDispatchToProps = {
  getInvoices,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InvoicesWidget));
