export enum ValueType {
  Integer = 'Integer',
  Decimal = 'Decimal',
  Boolean = 'Boolean',
  String = 'String',
  Dropdown = 'Dropdown',
  Switch = 'Switch',
  Multiselect = 'Multiselect',
  Custom = 'Custom',
}

export default ValueType;
