import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Tune from '@material-ui/icons/Tune';
import DifferenceIndicator from './DifferenceIndicator';
import { widgetGraphUnits } from 'helpers/enums';
//import CustomizeWidgetGraphModalContainer from './CustomizeWidgetGraphModalContainer';

const styles = theme => ({
  configureButton: {
    background: 'none',
    border: 'none',
    padding: 0,
  },
  configureIcon: {
    color: theme.palette.grey[100],
  },
  container: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '25px 30px',
    width: '100%',
  },
  difference: {
    fontSize: '14px',
  },
  headerRow: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  numeratorRow: {
    alignItems: 'baseline',
    display: 'flex',
    fontSize: '30px',
    justifyContent: 'space-between',
    lineHeight: 'normal',
  },
});

class GraphWidget extends React.Component {
  state = {
    isModalVisible: false,
  };

  render() {
    const {
      children,
      classes,
      minWidth = '250px',
      minHeight = '175px',
      differenceIndicator = 0,
      numerator = 0,
      settings,
    } = this.props;
    //const { isModalVisible } = this.state;
    const {
      differenceUnits = widgetGraphUnits.percent,
      numeratorUnits = widgetGraphUnits.none,
      title,
    } = settings;

    return (
      <>
        <div style={{ minWidth, minHeight }} className={classes.container}>
          <div className={classes.headerRow}>
            <h2>{title}</h2>
            {/* <button className={classes.configureButton} onClick={this.onButtonClick}><Tune classes={{ root: classes.configureIcon }} /></button> */}
          </div>
          <div className={classes.numeratorRow}>
            <DifferenceIndicator differenceIndicator={numerator} units={numeratorUnits} withColor={false} withSign={false} />
            {settings.isDifferenceVisible && (
              <span className={classes.difference}><DifferenceIndicator differenceIndicator={differenceIndicator} units={differenceUnits} /></span>
            )}
          </div>
          {children}
        </div>
        {/* <CustomizeWidgetGraphModalContainer settings={settings} isOpen={isModalVisible} onSave={this.onSave} onCancel={this.onCancel} /> */}
      </>
    );
  }

  onButtonClick = () => {
    this.setState({ isModalVisible: true });
  }

  onSave = () => {
    this.setState({ isModalVisible: false });
  }

  onCancel = () => {
    this.setState({ isModalVisible: false });
  }
}

export default withStyles(styles)(GraphWidget);
