import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Modal as MaterialModal } from '@material-ui/core';
import classNames from 'classnames';
import SaveBar from './SaveBar';

const styles = theme => ({
  fixYScrollbar: {
    height: '100%',
  },
  modalBody: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginTop: '15px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '5%',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  bigHeader: {
    fontSize: 27,
    color: theme.palette.grey[400],
  },
  normalHeader: {
    fontSize: 20,
    color: theme.palette.grey[800],
  },
  titleBottomBorder: {
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: theme.palette.grey[200],
  },
  normalStyle: {
    height: 'auto',
    margin: '2% auto auto auto',
    maxHeight: '750px',
    width: '708px',
  },
  header: {
    height: 60, //mock is height 80 on item edit modal, but it's too big. And it's 60 everywhere else.
    minHeight: 60, //sometimes large contents (MenuItemModal) can push the header up. This prevents that.
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1.8%',
  },

});

class Modal extends Component {
  render() {
    const {
      classes: {
        fixYScrollbar,
        header,
        modalBody,
        normalStyle,
        titleBottomBorder,
        bigHeader,
        normalHeader,
      },
      children,
      title,
      addTitleBottomBorder,
      ariaDecription,
      dimensions: {
        hasMargin = true,
        height,
        maxHeight = 'unset',
        width,
        maxWidth,
      } = {},
      onSave,
      saveBarExtraButtons,
      onCancel,
      onDelete,
      rightItem,
      leftItem,
      isOpened,
      isSaveDisabled,
      errorMessage,
      saveText,
      cancelText,
      showCancel = true,
    } = this.props;

    const customDimensions = height && width ? {
      ...!hasMargin && { margin: 0 },
      height,
      maxHeight,
      width,
      maxWidth,
    } : {};

    //font is only different on the 100% sized modal. It's bigger and a different color
    const headerFont = customDimensions.height === '100%' && customDimensions.width === '100%' ? bigHeader : normalHeader;

    return (
      <MaterialModal
        classes={{ root: fixYScrollbar }}
        aria-labelledby={title}
        aria-describedby={ariaDecription}
        open={isOpened}
        onClose={onCancel}
      >
        <div className={classNames(modalBody, normalStyle)} style={customDimensions}>
          <div className={classNames(header, addTitleBottomBorder && titleBottomBorder)}>
            {leftItem || <div />}
            <div className={headerFont}>
              {title}
            </div>
            {rightItem || <div />}
          </div>
          {children}
          <SaveBar
            onSave={onSave}
            onCancel={onCancel}
            onDelete={onDelete}
            showCancel={showCancel}
            isSaveDisabled={isSaveDisabled}
            errorMessage={errorMessage}
            saveText={saveText}
            cancelText={cancelText}
            extraButtons={saveBarExtraButtons}
          />
        </div>
      </MaterialModal>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  addTitleBottomBorder: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  dimensions: PropTypes.object,
  saveText: PropTypes.string,
};

export default withStyles(styles)(Modal);
