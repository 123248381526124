import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core';
import Avatar from 'Components/Avatar';

const styles = theme => ({
  overflow: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto',
    paddingTop: 12,
    justifyContent: 'center',
  },
  fitContent: {
    width: '100%',
    height: 'fit-content',
  },
  card: {
    width: 140,
    maxHeight: 140,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    boxShadow: `0 2px 2px 0 rgba(0,0,0,0.2)`,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    textAlign: 'center',
    fontSize: '13px',
    padding: 4,
    height: 45,
    alignItems: 'center',
    paddingBottom: '4px !important',
  },
  selectedTriangle: {
    height: 121,
    width: 120,
    position: 'absolute',
    backgroundImage: 'url(/icons/check-corner.svg)',
    backgroundRepeat: 'no-repeat',
    borderRadius: 4,
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.common.bluegrey,
    height: 93,
    fontSize: 42,
    fontWeight: '600',
    color: theme.palette.common.white,
  },
});

export class ImagePreview extends Component {

  onItemSelected = item => () => {
    this.props.onItemSelected(item);
  }

  render() {
    const { classes, menuItems } = this.props;

    return (
      <div className={classes.overflow}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={24}
          classes={{
            container: classes.fitContent,
          }}
        >
          {menuItems && menuItems.map(menuItem => (
            <Grid item key={menuItem.menuItemId}>
              <Card className={classes.card} onClick={this.onItemSelected(menuItem)}>
                {menuItem.selected && <div className={classes.selectedTriangle} /> }
                {!!menuItem.imageUrl ?
                  <CardMedia
                    style={{ width: 140, height: 93 }}
                    image={menuItem.imageUrl}
                  />
                  :
                  <Avatar name={menuItem.name}/>
                }
                <CardContent className={classes.cardTitle}>
                  {menuItem.name}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ImagePreview);
