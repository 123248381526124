export const CALENDAR_MODE = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Agenda: 'Agenda',
};

export const CALENDAR_VIEW = {
  Timeline: 'Timeline',
  Calendar: 'Calendar',
};

export default CALENDAR_MODE;
