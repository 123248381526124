import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Tune from '@material-ui/icons/Tune';
import classNames from 'classnames';

const styles = theme => {
  return {
    arrowOption: {
      borderLeft: `1px solid ${theme.palette.grey[50]} !important`,
      borderRight: `1px solid ${theme.palette.grey[50]} !important`,
      minWidth: 'unset !important',
    },
    arrowLeft: {
      borderRadius: '4px 0 0 4px',
    },
    arrowRight: {
      borderRadius: '0 4px 4px 0',
    },
    container: {
      display: 'flex',
      position: 'relative',
    },
    nav: {
      color: theme.palette.grey[300],
    },
    option: {
      backgroundColor: '#FFFFFF',
      border: `1px solid ${theme.palette.grey[50]}`,
      borderLeft: 'none',
      color: theme.palette.grey[300],
      display: 'inline-block',
      minHeight: '100%',
      minWidth: '7%',
      opacity: .75,
      textAlign: 'center',
      width: 'auto',
    },
    fixedOption: {
      color: 'black',
      fontWeight: 'bold',
      minWidth: '7%',
      flexShrink: 0,
    },
    optionSelected: {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
      opacity: 1,
      paddingTop: '2px',
    },
    appBarSpacing: {
      marginBottom: theme.spacing.unit * 2,
    },
    configureButton: {
      background: 'none',
      border: 'none',
      padding: 0,
      position: 'absolute',
      top: '-36px',
      right: 0,
    },
    configureIcon: {
      color: theme.palette.grey[100],
    },
    appBar: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
      fontSize: 16,
    },
    renderOptions: {
      whiteSpace: 'nowrap',
      flexGrow: 1,
      overflow: 'hidden',
    },
  };
};

class SelectionBar extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.scrollContainerRef = React.createRef();
  }

  render() {
    const { classes, leftFixedOptions, options, rightFixedOptions, onEditCategoriesClicked, showConfig = true, style } = this.props;

    return (
      <AppBar className={classes.appBar} style={ style } position="static" color="default">
        <div ref={this.containerRef} className={classes.container}>
          <button className={classNames(classes.option, classes.arrowOption, classes.arrowLeft)} onClick={this.onLeftArrowClick}>
            <i className="material-icons"><ArrowLeft classes={{ root: classes.nav }} /></i>
          </button>
          {this.renderOptions(leftFixedOptions, true)}
          <div ref={this.scrollContainerRef} className={classes.renderOptions}>
            {this.renderOptions(options)}
          </div>
          {this.renderOptions(rightFixedOptions, true)}
          <button className={classNames(classes.option, classes.arrowOption, classes.arrowRight)} onClick={this.onRightArrowClick}>
            <i className="material-icons"><ArrowRight classes={{ root: classes.nav }} /></i>
          </button>
          { showConfig &&
          <button className={classes.configureButton} onClick={onEditCategoriesClicked}><Tune classes={{ root: classes.configureIcon }} /></button>
          }
        </div>
      </AppBar>
    );
  }

  renderOptions = (options, isFixed = false) => {
    const { classes, selectedOption = {} } = this.props;

    const classNameDefaults = `${classes.option} ${isFixed && classes.fixedOption}`;

    return options && options.map(option => (
      <button
        key={`selection${option.name}${option.id}`}
        onClick={this.onNewSelectionClick(option)}
        className={`${classNameDefaults} ${selectedOption.id === option.id && classes.optionSelected}`}
      >
        {option.name || 'New'}
      </button>
    ));
  }

  onArrowClick = leftArrow => {
    const scroll = (leftArrow ? -1 : 1) * Math.min(Math.max(0, this.scrollContainerRef.current.clientWidth, this.scrollContainerRef.current.clientWidth));

    this.scrollContainerRef.current.scroll({
      top: 0,
      left: this.scrollContainerRef.current.scrollLeft + scroll,
      behavior: 'smooth',
    });
  }

  onLeftArrowClick = () => this.onArrowClick(true);

  onRightArrowClick = () => this.onArrowClick(false);

  onNewSelectionClick = option => event => {
    const selection = event.currentTarget;
    const halfOfContainer = this.containerRef.current.clientWidth / 2;
    const halfOfSelection = selection.clientWidth / 2;
    const scrollLeft = Math.min(Math.max(0, selection.offsetLeft - halfOfContainer + halfOfSelection), this.scrollContainerRef.current.scrollWidth);

    this.scrollContainerRef.current.scroll({
      top: 0,
      left: scrollLeft,
      behavior: 'smooth',
    });

    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(option);
    }
  }
}

export default withStyles(styles)(SelectionBar);
