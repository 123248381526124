import React, { Component } from 'react';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import SortableItem from 'Components/SortableItem';

const listTyle = {
  width: '100%',
};

const SortableList = SortableContainer(({ items, onEdit, onDelete, onClone }) => {
  return (
    //This needs to be a <div> do not replace with <>
    <div style={listTyle}>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          onEdit={onEdit && onEdit}
          additionalClasses={value.additionalClasses}
          onDelete={onDelete && onDelete}
          onClone={onClone && onClone} />
      ))}
    </div>
  );
});

export class ModifierSortableList extends Component {

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onSorted(arrayMove(this.props.items, oldIndex, newIndex));
  };

  onEdit = (modifier, index) => {
    this.props.onEdit(modifier, index);
  }

  onDelete = (modifier, index) => {
    this.props.onDelete(modifier, index);
  }

  onClone = (modifier, index) => {
    this.props.onClone(modifier, index);
  }

  render() {
    const {
      items,
    } = this.props;

    return (
      <SortableList
        items={items.filter(item => item.recordStatus === "Active" || !item.recordStatus)}
        onEdit={this.onEdit}
        onSortEnd={this.onSortEnd}
        lockAxis="y"
        useDragHandle={true}
        onDelete={this.onDelete}
        onClone={this.onClone}
      />);
  }
}

ModifierSortableList.propTypes = {
  items: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
};

export default ModifierSortableList;
