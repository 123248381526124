export enum EntityType
{
    Booking = 'Booking',
    Account = 'Account',
    Contact = 'Contact',
    Order = 'Order',
    Lead = 'Lead'
}

export default EntityType;
