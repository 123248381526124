import React, { Component, useContext } from 'react';
import {
  Add as ExpandIcon,
  Remove as MinimizeIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { GridDetailRow } from '@progress/kendo-react-grid';
import BulkModifiersModal from './BulkModifiersModal';
import MenuCategoryContext from './MenuCategoryContext';

const expandoStyles = theme => ({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  iconError: {
    color: theme.palette.error.main,
  },
  iconNormal: {
    color: theme.palette.primary.main,
  },
  noModifiers: {
    color: theme.palette.grey[50],
  },
});

class ExpandoCell extends Component {

  onClick = e => {
    e.preventDefault();
    const {
      dataItem,
      field,
    } = this.props;

    if (this.props.onChange) {
      //onChange is Grid.expandChange
      //copied from GridHiearchyCell.js in the Kendo Library
      this.props.onChange({
        dataItem: dataItem,
        syntheticEvent: e,
        field: field,
        value: !dataItem[field],
      });
    }
  }

  render() {
    const {
      expanded,
      classes,
      dataItem,
    } = this.props;

    return (
      <td className={!dataItem.hasModifierSets ? classes.noModifiers : dataItem.inViolation ? classes.iconError : classes.iconNormal}>
        <div className={classes.icon}>
          {expanded ? <MinimizeIcon onClick={this.onClick} /> : <ExpandIcon onClick={this.onClick} />}
        </div>
      </td>);
  }
}

const detailsStyles = theme => ({
  text: {
    color: theme.palette.action.active,
  },
});

class ModifiersDetailsComponent extends GridDetailRow {
  state = { sets: [] };

  openModifierEdit = () => {
    const {
      getSetsOfModifiers,
      dataItem,
    } = this.props;

    getSetsOfModifiers(dataItem).then(sets => {
      this.setState({ sets });
    });
  }

  closeModifierEdit = () => {
    this.setState({ sets: null }); //closes the modal
  }

  content = () => {
    const {
      classes,
      dataItem: { allModifiers, hasModifierSets },
    } = this.props;

    if (hasModifierSets) {
      if (allModifiers) {
        return ( //add onClick
          <p className={classes.text} onClick={this.openModifierEdit}>{allModifiers}</p>
        );
      }

      return <p onClick={this.openModifierEdit}>No selected modifiers</p>;
    }

    if (allModifiers) {
      //the sets were removed, but keep the modifiers
      return (<p className={classes.text}>{allModifiers}</p>);
    }

    return <p>Not applicable</p>;
  }

  render() {
    const sets = this.state.sets;

    return (
      <>
        {this.content()}
        {sets && sets.length ? <BulkModifiersModal
          onModalClosed={this.closeModifierEdit}
          title={this.props.dataItem.name + ' Modifiers'}
          sets={sets}
        /> : null}
      </>);
  }
}

const ExpandoCellWithStyles = withStyles(expandoStyles)(ExpandoCell);

const ModifiersDetailsComponentContainer = props => {
  const {
    getSetsOfModifiers,
  } = useContext(MenuCategoryContext);

  return (<ModifiersDetailsComponent
    {...props}
    getSetsOfModifiers={getSetsOfModifiers}
  />);
};

const ModifiersDetails = withStyles(detailsStyles)(ModifiersDetailsComponentContainer);

export { ExpandoCellWithStyles as ExpandoCell, ModifiersDetails };
