import React, { useContext, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContactContext from './ContactContext';
import TextField from 'Components/TextField';
import classNames from 'classnames';
import ComboBox from 'Components/ComboBox';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  cellContents: {
    display: 'flex',
    width: '100%',
    flex: 1,
  },
  contactInput: {
    color: theme.palette.grey[300],
  },
  quarterWidth: {
    display: 'flex',
    width: '25%',
  },
  flex: {
    display: 'flex',
    flex: 1,
  },
  twoColumns: {
    display: 'flex',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 2,
  },
  nameContainer: {
    flexDirection: 'row',
  },
  fieldIsEdited: {
    background: theme.palette.error.light,
  },
});

function PrimaryDetails({ classes }) {
  const {
    contact,
    isEditing,
    onFieldChange,
    salutations,
    isFieldChanged,
    isFieldInvalid,
    validateField,
    userIsSubscriber,
    isNewContact,
  } = useContext(ContactContext);
  const nameInput = useRef(null);

  useEffect(() => {
    focusNameInput(isNewContact);
  }, []);

  const handleSalutationChange = salutationModel => {
    if (!salutationModel) {
      onFieldChange('salutation')(null);//clicked clear button

      return;
    }
    onFieldChange('salutation')(salutationModel.label);
  };

  const focusNameInput = isNew => {
    if (isNew && nameInput && nameInput.current) {
      nameInput.current.focus();
      nameInput.current.select();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <div className={classNames(classes.column, userIsSubscriber && classes.nameContainer)}>
          <div className={classNames(classes.flex, isFieldChanged('firstName') && classes.fieldIsEdited)}>
            <TextField
              label="First Name"
              onFieldChange={onFieldChange('firstName')}
              name="firstName"
              value={contact.firstName || ''}
              disabled={!isEditing}
              error={isFieldInvalid('firstName')}
              onBlur={() => validateField('firstName')}
              inputRef={nameInput}
            />
          </div>
          <div className={classNames(classes.flex, isFieldChanged('lastName') && classes.fieldIsEdited)}>
            <TextField
              label="Last Name"
              onFieldChange={onFieldChange('lastName')}
              name="lastName"
              value={contact.lastName || ''}
              disabled={!isEditing}
              error={isFieldInvalid('lastName')}
              onBlur={() => validateField('lastName')}
            />
          </div>
        </div>
        <div className={classNames(classes.flex, isFieldChanged('title') && classes.fieldIsEdited)}>
          <TextField
            label="Title"
            onFieldChange={onFieldChange('title')}
            name="title"
            value={contact.title || ''}
            disabled={!isEditing}
          />
        </div>
        {userIsSubscriber &&
          <div className={classNames(classes.flex, isFieldChanged('account') && classes.fieldIsEdited)}>
            <TextField
              label="Account"
              onFieldChange={onFieldChange('account')}
              name="account"
              value={contact.account || ''}
              disabled={!isEditing}
            />
          </div>
        }
        <div className={classNames(classes.flex, isFieldChanged('email') && classes.fieldIsEdited)}>
          <TextField
            label="Email"
            onFieldChange={onFieldChange('email')}
            name="email"
            value={contact.email || ''}
            disabled={!isEditing}
            type="email"
            error={isFieldInvalid('email')}
            onBlur={() => validateField('email')}
          />
        </div>
      </div>
      <div className={classes.column}>
        <div className={classNames(classes.flex, isFieldChanged('salutation') && classes.fieldIsEdited)}>
          <ComboBox
            label="Salutation"
            disabled={!isEditing}
            value={contact.salutation}
            suggestions={salutations}
            isClearable={true}
            isCreatable={true}
            handleChange={handleSalutationChange}
            handleQuickAdd={onFieldChange('salutation')}
          />
        </div>
        <div className={classNames(classes.flex, isFieldChanged('phone') && classes.fieldIsEdited)}>
          <TextField
            label="Cell"
            onFieldChange={onFieldChange('phone')}
            name="phone"
            value={contact.phone || ''}
            disabled={!isEditing}
          />
        </div>
        <div className={classNames(classes.flex, isFieldChanged('phone2') && classes.fieldIsEdited)}>
          <TextField
            label="Phone 2"
            onFieldChange={onFieldChange('phone2')}
            name="phone2"
            value={contact.phone2 || ''}
            disabled={!isEditing}
          />
        </div>
        <div className={classNames(classes.flex, isFieldChanged('salesRep') && classes.fieldIsEdited)}>
          <TextField
            label="Sales Rep"
            onFieldChange={onFieldChange('salesRep')}
            name="salesRep"
            value={contact.salesRep || ''}
            disabled={!isEditing}
          />
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(PrimaryDetails);
