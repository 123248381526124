import {
  PAYMENT_ACTION,
} from 'actions/constants';

const initialState = {
  actionUrl: '',
};

export function payment(state = initialState, action) {
  switch (action.type) {

    case PAYMENT_ACTION: {
      const { actionUrl } = action;

      const newState = {
        ...state,
        actionUrl,
      };

      return newState;
    }

    default:
      return state;
  }
}
