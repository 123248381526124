export function getDateString(date: Date): string {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export function removeOldStringDates(dates: string[]): string[] {
  return removeOldDates(dates.map((date: string) => new Date(date)))
    .map((date: Date) => getDateString(date));
}

export function removeOldDates(dates: Date[]): Date[] {
  const twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

  return dates
    .filter((holidayDate: Date) => holidayDate > twoDaysAgo)
    .sort((a: Date, b: Date) => a.getTime() - b.getTime());
}
