import React, { Component } from 'react';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import SmallGrid from 'Components/Grid/SmallGrid';
import { withStyles } from '@material-ui/core';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import { connect } from 'react-redux';
import { provideIntlService } from '@progress/kendo-react-intl';
import {
  updateStoreFocusedEvent,
} from 'actions/booking';
import { ConvertArrayToDictionary } from 'helpers/dictionary';
import Tooltip from '@material-ui/core/Tooltip';
import { ErrorOutline } from '@material-ui/icons';

const styles = theme => ({
  venueFinder: {
    marginTop: 16,
  },
  unavailableRoom: {
    color: 'red',
  },
  expansionHeader: {
    fontSize: 15,
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.common.white} !important`,
  },
  expanded: {
    margin: 0,
  },
  noScrollBar: {
    '& .k-grid-content': {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '& .k-grid-header': {
      paddingRight: '0 !important',
    },
  },
});

class VenueFinder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedEvent: {},
    };
    this.formatter = provideIntlService(this);
  }

  deleteCustomRoom(roomIndex) {
    const { focusedEvent } = this.props;

    focusedEvent.bookingEventRooms = focusedEvent.bookingEventRooms.filter((room, index) => index !== roomIndex);

    this.props.updateStoreFocusedEvent(focusedEvent);
  }

  formatUnavailabilityMessage = unavailabilityReaons => {
    if (unavailabilityReaons) {

      return (<><ul>
        {unavailabilityReaons.map(reason => {
          switch (reason) {
            case 'DirectlyBooked': return <li>This room is booked</li>;
            case 'BreakOutRoomBooked': return <li>Break out room is booked</li>;
            case 'ParentRoomBooked': return <li>Parent room is booked</li>;
            case 'UnavailableSetupStyleOrCapacity': return <li>Selected setup style or capacity is unavailable for this room</li>;
            default: return '';
          }
        })}</ul>
      </>);
    } else {
      return <ul><li>This room is unavailable because its parent or its break out rooms are already selected</li></ul>;
    }
  }

  render() {
    const { classes, rooms, unavailableRooms, focusedEvent, onSelectVenue, setupStyleId } = this.props;

    const selectedRooms = focusedEvent.bookingEventRooms ? ConvertArrayToDictionary(focusedEvent.bookingEventRooms.filter(room => room.roomId), 'roomId') : {};

    const formattedRooms = rooms && rooms
      .map(room => ({
        ...room,
        setupStyle: room.roomSetupStyles.find(style => style.setupStyleId === setupStyleId),
        rateString: `${this.formatter.formatNumber(room.rate, 'c2')}/${room.rateType}`,
        selected: focusedEvent.bookingEventRooms && focusedEvent.bookingEventRooms.some(br => br.roomId === room.id),
      }));

    const roomsUnavailableDueToSelection = rooms.filter(room => selectedRooms && (
      selectedRooms[room.parentRoomId] ||// parent room is selected
      (room.breakOutRooms && room.breakOutRooms.some(br => selectedRooms[br.id])) // some of the child rooms are selected
    )
    );

    const allUnavailableRooms = (unavailableRooms ? unavailableRooms : []).concat(roomsUnavailableDueToSelection);

    const unavailableRoomsDictionary = ConvertArrayToDictionary(allUnavailableRooms, 'id');

    const formattedUnavailableRooms = formattedRooms.filter(room => unavailableRoomsDictionary[room.id])
      .map(formattedRoom => ({
        ...formattedRoom,
        unavailabilityReasons: unavailableRoomsDictionary[formattedRoom.id].unavailabilityReasons,
        unavailabilityMessage: this.formatUnavailabilityMessage(unavailableRoomsDictionary[formattedRoom.id].unavailabilityReasons),
      }));

    const formattedAvailableRooms = formattedRooms.filter(room => !unavailableRoomsDictionary[room.id]);

    const customRooms = focusedEvent.bookingEventRooms ? focusedEvent.bookingEventRooms.map((room, index) => ({ ...room, roomIndex: index })).filter(room => !room.roomId) : null;

    return <div className={classes.venueFinder}>
      {customRooms && customRooms.length > 0 && <CoverExpansionPanel
        customstyles={{ summary: classes.expansionHeader, expanded: classes.expanded }}
        title="Custom Rooms"
        defaultExpanded={true}
        isTitleHiddenOnDetails={true}
      >
        <SmallGrid
          data={customRooms}
          selectedField="selected"
          className={classes.noScrollBar}
        >
          <Column field="name" title="Room" />
          <Column field="roomType" title="Type" />
          <Column field="price" title="Room Price" format="{0:c}" />
          <Column cell={props => (
            <td style={{ textAlign: 'center' }}>
              <button onClick={() => this.deleteCustomRoom(props.dataItem.roomIndex)}>Delete</button>
            </td>
          )} />
        </SmallGrid>
      </CoverExpansionPanel>}
      {formattedAvailableRooms && <CoverExpansionPanel
        customstyles={{ summary: classes.expansionHeader, expanded: classes.expanded }}
        title="Available Rooms"
        defaultExpanded={true}
        isTitleHiddenOnDetails={true}
      >
        <SmallGrid
          data={formattedAvailableRooms}
          selectedField="selected"
          className={classes.noScrollBar}
        >
          <Column
            field=""
            width="50px"
            cell={props => (
              <td style={{ textAlign: 'center' }}>
                <input
                  className="k-checkbox"
                  type="checkbox"
                  id={`venue-${props.dataItem.id}`}
                  onChange={() => onSelectVenue(props.dataItem)}
                  checked={props.dataItem.selected}
                />
                <label htmlFor={`venue-${props.dataItem.id}`} className="k-checkbox-label"></label>
              </td>
            )}
          />
          <Column field="name" title="Room" />
          <Column field="setupStyle.capacity" title="Capacity" />
          <Column field="type" title="Type" />
          <Column field="rateString" title="Room Rate" />
        </SmallGrid>
      </CoverExpansionPanel>}
      {formattedUnavailableRooms && <CoverExpansionPanel
        customstyles={{ summary: classes.expansionHeader, expanded: classes.expanded }}
        title="Unavailable Rooms"
        defaultExpanded={true}
        isTitleHiddenOnDetails={true}
      >
        <SmallGrid
          data={formattedUnavailableRooms}
          className={classes.noScrollBar}
        >
          <Column
            field=""
            width="50px"
            cell={props => (
              <td style={{ textAlign: 'center' }}>
                <input
                  className="k-checkbox"
                  type="checkbox"
                  id={`venue-${props.dataItem.id}`}
                  onChange={() => onSelectVenue(props.dataItem)}
                  checked={props.dataItem.selected}
                />
                <label htmlFor={`venue-${props.dataItem.id}`} className="k-checkbox-label"></label>
              </td>
            )}
          />
          <Column field="name" title="Room" />
          <Column field="setupStyle.capacity" title="Capacity" cell={props => (
            <td>{props.dataItem.setupStyle ? props.dataItem.setupStyle.capacity : 'N/A'}</td>
          )} />
          <Column field="type" title="Type" />
          <Column field="rateString" title="Room Rate" />
          <Column field="unavailabilityReasons" title=" " width="45px" cell={props => (
            <td>
              <Tooltip title={props.dataItem.unavailabilityMessage} placement="top-start">
                <ErrorOutline style={{ float: 'right', fontSize: 15, marginTop: 3 }} />
              </Tooltip>
            </td>
          )} />
        </SmallGrid>
      </CoverExpansionPanel>}

    </div>;

  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    booking: {
      [ownProps.storeType]: {
        focusedEvent,
      },
      unavailableRooms,
    },
    api: {
      rooms,
      parentRooms,
    },
  } = state;

  return {
    unavailableRooms,
    rooms,
    parentRooms,
    focusedEvent,
  };
};

const mapDispatchToProps = {
  updateStoreFocusedEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VenueFinder));
