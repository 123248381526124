import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Popover,
  Paper,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getItemsApi, getItemImage } from 'actions/item';
import { filterStoreMenu } from 'actions/eventMenu';
import QuickAddResults from './QuickAddResults';

const styles = theme => ({
  quickAddButton: {
    height: 34,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: 13,
    cursor: 'pointer',
  },
  inFrontOfModal: {
    zIndex: theme.zIndex.modal + 1,
  },
});

const inputProps = {
  tabIndex: 1,
};
const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};
const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

class QuickAddWithoutStyle extends Component {
  state = {
    searching: false,
    results: [],
    previewIndex: 0,
  };

  nextId = -1;

  startSearching = () => {
    this.setState({ searching: true, searchText: '' });
  }

  onItemSelected = item => {
    this.props.onItemSelected(item);
    this.cancelSearching();
  }

  handleInputChange = event => {
    this.setState({ searchText: event.target.value }, this.performSearch);
  }

  onBlurSearch = () => {
    if (!this.state.searchText) {
      this.cancelSearching();
    }
  }

  cancelSearching = () => {
    this.setState({
      searching: false,
      results: [],
      previewIndex: 0,
    });
  }

  performSearch = () => {
    const { searchText, previewIndex } = this.state;

    this.props.filterStoreMenu(searchText).then(results => {
      if (this.state.searchText === searchText) {//if user it typing fast this could have changed
        const newState = { results };

        if (previewIndex > 0 && previewIndex >= results.length) {
          newState.previewIndex = results.length - 1;
        }
        this.setState(newState);
      }
    });
  }

  onKeyPressed = event => {
    if (event.key === 'Enter') {
      //only do this if zero items!
      if (this.state.results.length === 0) {
        this.onItemSelected({ name: this.state.searchText, id: this.nextId }); //inserts a new item
        this.nextId--;
      } else {
        this.onItemSelected(this.state.results[this.previewIndex()]); //inserts a new item
      }
    }
    else if (event.key === 'Escape') {
      this.cancelSearching();
    }
    else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      const {
        results,
        previewIndex,
      } = this.state;

      if (results && results.length) {

        const newIndex = previewIndex + (event.key === 'ArrowUp' ? -1 : 1);

        if (newIndex >= 0 && newIndex < results.length) {
          event.preventDefault();
          this.setState({ previewIndex: newIndex });
        }
      }
    }
  }

  onPreviewChange = index => {
    this.setState({ previewIndex: index });
  }

  previewIndex = () => {
    const {
      results,
      previewIndex,
    } = this.state;

    if (results && results.length) {
      if (previewIndex < results.length) {
        return previewIndex;
      }
    }

    return 0;
  }

  // Handle closing the popover manually, disable Material popover callback
  handlePopoverCloseEvent = (event, reason) => {
    if (reason === 'backdropClick') {
      event.preventDefault();
      event.stopPropagation();
      this.cancelSearching();
    }
  }

  render() {
    const {
      searching,
      searchText,
      results,
    } = this.state;
    const {
      classes,
      getItemImage,
    } = this.props;
    const previewIndex = this.previewIndex();

    return (
      <>
        <div
          onClick={this.startSearching}
          className={classes.quickAddButton}
          ref={node => { this.anchorEl = node; }}
        >
          + Quick Add
        </div>
        <Popover
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          anchorEl={this.anchorEl}
          className={classes.inFrontOfModal}
          disableRestoreFocus={true} // fix for 2782
          onClose={this.handlePopoverCloseEvent}
          open={searching}>
          <Paper>
            <TextField
              name="quickSearch"
              autoFocus={true}
              onChange={this.handleInputChange}
              onBlur={this.onBlurSearch}
              inputProps={inputProps}
              onKeyDown={this.onKeyPressed}
            />
            <QuickAddResults
              selectedIndex={previewIndex}
              results={results}
              onPreviewChange={this.onPreviewChange}
              onItemSelected={this.onItemSelected}
              searchText={searchText}
              getItemImage={getItemImage}
            />
          </Paper>
        </Popover>
      </>
    );
  }
}

export const QuickAddWithStyle = withStyles(styles)(QuickAddWithoutStyle); //used in storybook.

const mapDispatchToProps = {
  getItemsApi,
  getItemImage,
  filterStoreMenu,
};

export default connect(undefined, mapDispatchToProps)(QuickAddWithStyle);
