import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GraphWidget from './GraphWidget';
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartPlotArea,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts';
import DifferenceIndicator from './DifferenceIndicator';

const styles = theme => ({
  denominator: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: '18px',
  },
  graphContainer: {
    display: 'flex',
    minHeight: '162px',
    margin: '0 -18px', // Net 12 padding
  },
  graphLabelsContainer: {
    marginTop: '24%',
    textAlign: 'end',
    whiteSpace: 'normal',
    width: '29.32%',
  },
  horizontalAdjustment: {
    backgroundColor: theme.palette.grey[50],
    margin: '11px -30px',
  },
  totalDescription: {
    color: theme.palette.grey[200],
    fontSize: '12px',
  },
});

class CircleGraphWidget extends React.Component {
  constructor(props) {
    super(props);

    this.chartContainerRef = React.createRef();
    this.chartRef = React.createRef();

    this.state = {
      adjustChartMargin: 0,
      dimensionInPx: 0,
    };
  }

  render() {
    const {
      classes,
      denominator,
      differenceIndicator,
      numerator,
      settings,
      theme,
    } = this.props;
    const { adjustChartMargin, dimensionInPx } = this.state;

    const data = [
      { key: 'selected', value: numerator, color: theme.palette.secondary.main },
      { key: 'total', value: Math.max(0, denominator - numerator), color: theme.palette.grey[50] },
    ];

    return (
      <GraphWidget
        minHeight="282px"
        minWidth="250px"
        numerator={numerator}
        differenceIndicator={differenceIndicator}
        onButtonClick={this.onButtonClick}
        settings={settings}
      >
        <hr className={classes.horizontalAdjustment} />
        <div ref={this.chartContainerRef} className={classes.graphContainer}>
          <Chart ref={this.chartRef} style={{ height: dimensionInPx, width: dimensionInPx }}>
            <ChartPlotArea margin={0} />
            <ChartSeries>
              <ChartSeriesItem type="donut" data={data} categoryField="key" fievalueld="value" colorField="color" startAngle={0} />
            </ChartSeries>
            <ChartArea margin={adjustChartMargin} />
            <ChartLegend visible={false} />
          </Chart>
          <div className={classes.graphLabelsContainer}>
            <div className={classes.denominator}>
              <DifferenceIndicator differenceIndicator={denominator} units={settings.denominatorUnits} withColor={false} withSign={false} />
            </div>
            <div className={classes.totalDescription}>
              {settings.isLabelVisible ? settings.label : ''}
            </div>
          </div>
        </div>
      </GraphWidget>
    );
  }

  componentDidMount() {
    this.updateDimensionsIfNeeded();
  }

  componentDidUpdate() {
    this.updateDimensionsIfNeeded();
  }

  updateDimensionsIfNeeded() {
    const { dimensionInPx: prevDimensionInPx, adjustChartMargin: prevAdjustChartMargin } = this.state;

    const container = this.chartContainerRef.current;
    const chart = this.chartRef.current;
    const kendoMarginAdjustment = -.21;
    const adjustChartMargin = chart && chart.element ? chart.element.clientWidth * kendoMarginAdjustment : 0;
    let dimensionInPx = 0;

    if (container) {
      if (container.clientWidth === 0) {
        dimensionInPx = container.clientHeight;
      } else if (container.clientHeight === 0) {
        dimensionInPx = container.clientWidth;
      } else {
        dimensionInPx = container.clientWidth > container.clientHeight ? container.clientHeight : container.clientWidth;
      }
    }

    if (prevDimensionInPx !== dimensionInPx || prevAdjustChartMargin !== adjustChartMargin) {
      this.setState({ dimensionInPx, adjustChartMargin });
    }
  }
}

export default withStyles(styles, { withTheme: true })(CircleGraphWidget);
