import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Menu as HamburgerIcon,
  // Clear as DeleteIcon,
  // Edit as EditIcon,
} from '@material-ui/icons';
import {
  TextField,
} from '@material-ui/core';
import {
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

const styles = theme => ({
  pickNameRow: {
    padding: '8px 4px 8px 8px',
  },
  pickNameContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover $crudIcons': {
      visibility: 'visible',
    },
  },
  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px 12px 0px 12px',
  },
  crudIcons: {
    visibility: 'hidden',
  },
  modifyIcon: {
    marginRight: 7,
    color: theme.palette.grey[700],
    fontSize: 26,
    cursor: 'pointer',
  },
  dataRow: {
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
    padding: '8px 4px 8px 8px',
  },
  inputFocused: {
    fontSize: 18,
    color: theme.palette.grey[700],
  },
  tdNormal: {
    backgroundColor: 'white',
    width: '70px',
  },
  tdError: {
    backgroundColor: 'pink',
    width: '70px',
  },
});

var sortable = { cursor: 'row-resize' };
const DragHandle = SortableHandle(() => <HamburgerIcon style={sortable} />);

class TaxTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isAutoFocused,
      taxPercent: 0,
      servicePercent: 0,
      gratuityPercent: 0,
      errors: [],
    };

    this.textInputClasses = {
      classes: {
        focused: props.classes.inputFocused,
      },
    };
  }

  componentDidMount() {
    this.setTaxConfigState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.taxConfigType !== this.props.taxConfigType) {
      this.setTaxConfigState();
      this.setState({ errors: [] });
    }
  }

  setTaxConfigState = () => {
    this.setState({
      taxPercent: toFixed(this.props.taxConfigType.taxPercentage * 100, 6),
      servicePercent: toFixed(this.props.taxConfigType.serviceChargePercentage * 100, 6),
      gratuityPercent: toFixed(this.props.taxConfigType.gratuityPercentage * 100, 6),
    });
  }

  startEditing = () => {
    this.setState({ isEditing: true, taxConfigTypeName: this.props.taxConfigType.name });
  }

  editingDone = () => {
    const {
      onItemEdit,
      taxConfigType,
    } = this.props;
    const {
      taxConfigTypeName,
    } = this.state;

    this.setState({ isEditing: false });

    if (taxConfigTypeName === taxConfigType.name) {
      return;
    }
    //flush the new name back up
    onItemEdit(taxConfigType.revenueTypeId, taxConfigTypeName);
  }

  onNameChange = event => {
    this.setState({ taxConfigTypeName: event.target.value });
  }

  enterPressed = event => {
    if (event.key !== 'Enter') {
      return;
    }
    this.editingDone();
  }

  onDelete = () => {
    const {
      taxConfigType,
      onItemDelete,
    } = this.props;

    onItemDelete(taxConfigType.revenueTypeId);
  }

  updateTaxPercent = event => {
    const taxPercent = event.target.value;

    this.setState({ taxPercent });
  }

  updateServicePercent = event => {
    const servicePercent = event.target.value;

    this.setState({ servicePercent });
  }

  updateGratuityPercent = event => {
    const gratuityPercent = event.target.value;

    this.setState({ gratuityPercent });
  }

  onBlur = rateType => event => {
    const { errors } = this.state;
    const {  onPercentageFieldChange, taxConfigType } = this.props;

    if (!isNaN(event.target.value) && event.target.value <= 100) {
      const percent = toFixed(event.target.value, 6);

      switch (rateType) {
        case 'taxPercentage':
          this.setState({ taxPercent: percent });
          break;
        case 'serviceChargePercentage':
          this.setState({ servicePercent: percent });
          break;
        case 'gratuityPercentage':
          this.setState({ gratuityPercent: percent });
          break;
        default:
      }

      errors[rateType] = false;
      onPercentageFieldChange(taxConfigType.revenueTypeId, rateType)(parseFloat(percent));

      this.props.setErrors(false,rateType + '' + taxConfigType.revenueTypeId);
      this.setState({ errors });
    } else {
      errors[rateType] = true;

      this.setState({ errors });

      this.props.setErrors(true,rateType + '' + taxConfigType.revenueTypeId);

    }
  }

  isErrorTax = error => {
    return error ? true : false;
  }

  render() {
    const {
      taxConfigType,
      classes,
    } = this.props;

    const {
      isEditing,
      taxConfigTypeName,
      taxPercent,
      servicePercent,
      gratuityPercent,
      errors,
    } = this.state;

    return (
      <tr className={classes.dataRow}>
        {!isEditing && <td><DragHandle /></td>}
        <th scope="row" className={classes.pickNameRow} colSpan={isEditing ? 2 : 1}>
          {false ? (
            <TextField
              autoFocus={true}
              fullWidth={true}
              onChange={this.onNameChange}
              name="name"
              value={taxConfigTypeName}
              onBlur={this.editingDone}
              margin="none"
              InputProps={this.textInputClasses}
              onKeyPress={this.enterPressed}
            />
          ) : (
            <div className={classes.pickNameContent}>
              <span className={classes.name}>
                {taxConfigType.revenueType ? taxConfigType.revenueType.name : ''} {taxConfigType.isEditable}
              </span>
              {/* <div className={classes.crudIcons}>
                <EditIcon onClick={this.startEditing} className={classes.modifyIcon} />
                <DeleteIcon onClick={this.onDelete} className={classes.modifyIcon} />
              </div> */}
            </div>
          )}
        </th>
        <td style={{ padding: '4px' }}>
          <input
            className={this.isErrorTax(errors['taxPercentage']) ? classes.tdError : classes.tdNormal}
            value={taxPercent}
            onChange={this.updateTaxPercent}
            onBlur={this.onBlur('taxPercentage')}
            style={{ width: 70 }}
          />
        </td>
        <td style={{ padding: '4px' }}>
          <input
            className={this.isErrorTax(errors['serviceChargePercentage']) ? classes.tdError : classes.tdNormal}
            value={servicePercent}
            onChange={this.updateServicePercent}
            onBlur={this.onBlur('serviceChargePercentage')}
            style={{ width: 70 }}
          />
        </td>
        <td style={{ padding: '4px' }}>
          <input
            className={this.isErrorTax(errors['gratuityPercentage']) ? classes.tdError : classes.tdNormal}
            value={gratuityPercent}
            onChange={this.updateGratuityPercent}
            onBlur={this.onBlur('gratuityPercentage')}
            style={{ width: 70 }}
          />
        </td>
      </tr>
    );
  }
}

export default withStyles(styles)(SortableElement(TaxTableRow));

function toFixed(param, precisionpadding) {
  let val = param;

  if (isNaN(param))
    val = 0;

  var precision = precisionpadding || 0,
    neg = val < 0,
    power = Math.pow(10, precision),
    value = Math.round(val * power),
    integral = String((neg ? Math.ceil : Math.floor)(value / power)),
    fraction = String((neg ? -value : value) % power),
    padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');

  if (integral < 10)
    integral = '0' + integral;

  const ret = precision ? integral + '.' + padding + fraction : integral;

  return ret;
}
