import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { GridColumn } from '@progress/kendo-react-grid';
import ODataGrid from 'Components/Grid/ODataGrid';
import { connect } from 'react-redux';
import { addLead, saveLeads, getLeads, getLeadColumns, saveLeadColumns, reorderLeadColumns } from 'actions/lead';
import { addStatusHistory } from 'actions/statusHistory';
import SelectedColumn from 'Components/Grid/SelectedColumn';
import GridToolBar from 'Components/Grid/GridToolBar';
import { buildGridCellLink } from 'Components/buildGridCellLink';
import { leadsSelected } from 'actions/api';
import dropdownFilterCell from 'Components/Grid/dropdownFilterCell';
import { getLocations } from 'actions/location';
import SimpleDialog from 'Components/SimpleDialog';
import moment from 'moment';

class LeadGrid extends Component {
  state = {
    selectedIds: {},
    isFilterable: false,
    isEditable: false,
    isGridToggled: false,
    leads: [],
    columns: this.props.columns,
  };

  okDialog = React.createRef();

  linkOptions = {
    url: lead => `/leads/${lead.id}`,
    text: lead => lead.name,
  };

  customFilters = [
    {
      id: 2, name: 'Leads Added Today',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'createdUtcDateTime',
            operator: 'gt',
            value: moment().startOf('day').utc().toDate(),
          },
          {
            field: 'createdUtcDateTime',
            operator: 'lt',
            value: moment().endOf('day').utc().toDate(),
          },
        ],
      },
    },
    {
      id: 3, name: 'Leads Added This Week',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'createdUtcDateTime',
            operator: 'gt',
            value: moment().startOf('week').utc().toDate(),
          },
          {
            field: 'createdUtcDateTime',
            operator: 'lt',
            value: moment().endOf('week').utc().toDate(),
          },
        ],
      },
    },
  ];

  componentDidMount() {
    this.props.leadsSelected({});
    this.props.getLeadColumns();
    this.setupLocations(this.props.getLocations());

  }

  componentDidUpdate(prevProps) {
    if (prevProps.leads !== this.props.leads || (this.props.leads.length !== 0 && this.state.leads.length === 0)) {
      this.setState({ leads: this.setSelectedLeads(this.state.selectedIds) });
    }
    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: this.props.columns });
    }
  }

  setSelectedLeads = (selectedIds = {}) => {
    const { leads } = this.props;

    if (leads.length === 0) {
      return leads;//avoid creating a new array each time
    }

    return leads.map(lead => ({
      ...lead,
      selected: selectedIds[lead.id] === true,
    }));
  }

  saveLeads = leads => {
    if (this.state.isQuickAdding) {
      leads = leads.filter(c => c.isEditable);
    }
    this.props.saveLeads(leads)
      .then(() => {
        this.cancelEditMode();
        this.props.getLeads();
      })
      .catch(window.alert); //TODO: handle errors better. Mockups?
  }

  enterEditMode = () => {
    if (!this.state.isQuickAdding) {
      this.setState({ isEditable: true });
    }
  }

  cancelEditMode = () => {
    if (this.state.isQuickAdding) {
      const leads = this.state.leads.filter(c => !c.isEditable);

      this.setState({ leads });
    }
    this.setState({ isEditable: false, isQuickAdding: false });
  }

  createLead = () => {
    const { currentLocation } = this.props;

    if (currentLocation && currentLocation.id) {
      this.createLeadAndGo();
    } else {
      this.okDialog.current.open('Please select a location before creating a lead');
    }
  };

  createLeadAndGo = () => {
    const { addLead, currentLocation, history, addStatusHistory } = this.props;

    addLead({ firstName: 'New', location: currentLocation.id }).then(lead => {
      history.push(`/leads/${lead.id}`);
      if (lead.statusId) addStatusHistory('Lead', lead.id, lead.statusId);
    });
  };

  onQuickAdd = () => {
    this.createLead();
  }

  onFilterClick = () => {
    this.setState({ isFilterable: !this.state.isFilterable });
  }

  onLeadSelected = ({ id, isSelected, isAll }) => {
    const { leads, selectedIds } = this.state;
    let newLeads;

    if (isAll !== undefined) {
      newLeads = leads.map(c => {
        c.selected = isAll;
        selectedIds[c.id] = isAll;

        return c;
      });
    } else {
      newLeads = leads.map(c => {
        if (c.id === id) {
          c.selected = isSelected;
        }
        selectedIds[c.id] = c.selected;

        return c;
      });
    }
    this.setState({ leads: newLeads, selectedIds });
    this.props.leadsSelected(selectedIds);
  };

  getToolbar = () => (
    <GridToolBar
      isFilterToggled={this.state.isFilterable}
      isEditToggled={this.state.isEditable}
      isGridToggled={this.state.isGridToggled}
      onFilterClick={this.onFilterClick}
      onEditClick={this.enterEditMode}
      onAddClick={this.onQuickAdd}
      onGridEditClick={this.onGridEditClick}
      onColumnsSubmit={this.saveGridColumns}
      onCloseMenu={this.onGridEditClose}
      columns={this.state.columns}
      gridContext="Leads"
      customFilters={this.customFilters}
      onApplyCustomFilter={this.applyCustomFilter}
      activeCustomFilter={this.state.globalFilter}
    />
  )

  applyCustomFilter = filterId => {
    const foundFilter = this.customFilters.find(filter => filter.id === filterId);

    this.setState({ globalFilter: foundFilter });
  }

  onSalesRepChange = props => newValue => {
    props.onChange({ dataItem: props.dataItem, field: newValue.name, value: newValue.value });
  }

  saveGridColumns = columns => {
    this.props.saveLeadColumns(columns);
  }

  onGridEditClose = () => {
    this.setState({ isGridToggled: false });
  }

  onGridEditClick = () => {
    this.setState({ isGridToggled: !this.state.isGridToggled });
  }

  reorderColumns = event => {
    const { columns: reOrderColumns } = event;
    // If the data field does not match the column field name, we have to fix it here:
    const fieldNames = {
      'salesRepId': 'salesRep',
    };
    const reorderedColumns = reOrderColumns.sort((a, b) => a.orderIndex - b.orderIndex).map(column => fieldNames[column.field] || column.field);

    this.props.reorderLeadColumns(reorderedColumns);
  }

  render() {
    const {
      leads,
      isEditable,
      isFilterable,
      isQuickAdding,
      columns,
      globalFilter,
    } = this.state;

    const {
      isLoading,
      total,
    } = this.props;

    return (
      columns.length > 0 && <>
      <ODataGrid
        getData={this.props.getLeads}
        items={leads}
        total={total}
        isLoading={isLoading}
        isQuickAdding={isQuickAdding}
        selectionChanged={this.onLeadSelected}
        isSortable={!isEditable}
        filterable={!isEditable && isFilterable}
        isEditable={isEditable}
        onSave={this.saveLeads}
        onCancel={this.cancelEditMode}
        toolBar={this.getToolbar()}
        onColumnReorder={this.reorderColumns}
        filter={(globalFilter && globalFilter.filter)}
      >
        {columns.map(column => this.getColumn(column))}
      </ODataGrid>
      <SimpleDialog onlyOkayButton={true} innerRef={this.okDialog} />
    </>);
  }

  setupLocations = locations => {
    const dropDownOptions = locations.map(s => (
      {
        text: s.name,
        id: s.id,
      }));
    const locationsFilterCell = dropdownFilterCell(dropDownOptions, 'Locations');

    this.setState({ locationsFilterCell });
  };

  getColumn(column) {
    const {
      isEditable,
      isQuickAdding,
      leads,
      locationsFilterCell,
    } = this.state;

    if (column.isVisible) {
      switch (column.field) {
        case 'selected':
          return SelectedColumn(leads, !isEditable && !isQuickAdding);
        case 'name':
          return isEditable || isQuickAdding ?
            <GridColumn key={column.field} field={column.field} title={column.title} /> :
            <GridColumn key={column.field} field={column.field} title={column.title} cell={buildGridCellLink(this.linkOptions)} />;
        case 'location':
          return (
            <GridColumn
              key={column.field}
              field="location"
              title={column.title}
              sortable={false}
              filterCell={locationsFilterCell}
            />);
        default:
          return <GridColumn
            key={column.field}
            field={column.field}
            title={column.title}
            cell={isEditable || isQuickAdding ? null : buildGridCellLink({ url: this.linkOptions.url, text: lead => lead[column.field] })}
          />;
      }
    }
  }
}

const mapStateToProps = state => {
  const {
    api: {
      currentLocation,
      leads: {
        data,
        isLoading,
        total,
      },
    },
    settings: {
      leads: {
        columns,
      },
    },
  } = state;

  return {
    currentLocation,
    leads: data,
    isLoading,
    total,
    columns,
  };
};

const mapDispatchToProps = {
  addLead,
  saveLeads,
  getLeads,
  leadsSelected,
  getLeadColumns,
  saveLeadColumns,
  getLocations,
  reorderLeadColumns,
  addStatusHistory,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadGrid));
