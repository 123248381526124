import { callApi } from 'shared/CallApi';
import {
  fetchProposalsBegin,
  proposalsReceived,
} from './api';
import {
  RECEIVE_PROPOSAL_RELATIONSHIPS,
} from './constants';

export const proposalRelationshipsReceived = proposalRelationships => ({
  type: RECEIVE_PROPOSAL_RELATIONSHIPS,
  proposalRelationships,
});

export const getProposals = (entityType, linkedEntityId) => {
  return dispatch => {
    dispatch(fetchProposalsBegin(linkedEntityId));

    return getProposalsApi(dispatch, entityType, linkedEntityId).then(json => {
      dispatch(proposalsReceived(json));

      return json;
    });
  };
};

const getProposalsApi = (dispatch, entityType, linkedEntityId) => {
  const oDataEntityType = `EntityType eq Cover.Domain.Models.Enums.EntityType'${entityType}'`;
  const oDataEntityId = `EntityId eq ${linkedEntityId}`;
  const oDataProposal = `Proposal eq true`;

  return dispatch(callApi(`booking?$filter=${oDataEntityType} and ${oDataEntityId} and ${oDataProposal}`))
    .then(response => response.json())
    .catch(error => {
      console.error(error);
    });
};

export function getRelationships(entityType = 'lead') {
  return dispatch => dispatch(callApi(`${entityType}/NameAndId`))
    .then(response => response.json())
    .then(relationships => {
      dispatch(proposalRelationshipsReceived(relationships));

      return relationships;
    })
    .catch(error => {
      console.error(error);
    });
}
