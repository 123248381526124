import { callApi } from 'shared/CallApi';
import {
  MENUS_RECEIVED,
  MENU_SAVED,
  MENU_ADDED,
  MENU_DELETED,
  INIT_WORKING_MASTER_MENU,
  RESET_WORKING_MASTER_MENU,
  UPDATE_WORKING_MASTER_MENU,
  UPDATE_WORKING_MASTER_MENU_NAME,
  WORKING_MENU_ADD_MENU_CATEGORY,
  WORKING_MENU_DELETE_MENU_CATEGORY,
  WORKING_MENU_DELETE_MENU_CATEGORY_ITEM,
  WORKING_MENU_ADD_MENU_CATEGORY_ITEM,
  WORKING_MENU_COLLAPSE_ALL_CATEGORIES,
  WORKING_MENU_EXPAND_ALL_CATEGORIES,
  WORKING_MENU_TOGGLE_EXPAND_CATEGORY,
  WORKING_MENU_UPDATE_MENU_CATEGORY_ITEMS,
  WORKING_MENU_NEXT_PLACEHOLDER_ID,
  WORKING_MENU_API_REQUEST_BEGIN,
  MENU_ASSIGNMENTS_RECEIVED,
  MENU_ASSIGNMENTS_UPDATE,
  MENU_ASSIGNMENTS_RESET,
  SET_WORKING_MASTER_MENU_ID,
  UPDATE_WORKING_MASTER_MENU_CATEGORIES,
  INIT_WORKING_MASTER_MENU_CATEGORIES,
} from './constants';
import _ from 'lodash';
import arrayMove from 'array-move';

export const menuAdded = newMenuId => ({ type: MENU_ADDED, newMenuId });
export const menuDeleted = () => ({ type: MENU_DELETED });

// Working Master Menu
export const initializeWorkingMasterMenu = workingMasterMenu => ({ type: INIT_WORKING_MASTER_MENU, workingMasterMenu });
export const initializeMenuCategories = menuCategories => ({ type: INIT_WORKING_MASTER_MENU_CATEGORIES, menuCategories });
export const setWorkingMasterMenuId = menuId => ({ type: SET_WORKING_MASTER_MENU_ID, menuId });
export const resetWorkingMasterMenu = () => ({ type: RESET_WORKING_MASTER_MENU });
export const workingMenuUpdate = workingMasterMenu => ({ type: UPDATE_WORKING_MASTER_MENU, workingMasterMenu });
export const workingMenuUpdateName = menuName => ({ type: UPDATE_WORKING_MASTER_MENU_NAME, menuName });
export const workingMenuCategoriesUpdate = menuCategories => ({ type: UPDATE_WORKING_MASTER_MENU_CATEGORIES, menuCategories });
export const workingMenuAddMenuCategory = menuCategory => ({ type: WORKING_MENU_ADD_MENU_CATEGORY, menuCategory });
export const deleteMenuCategory = menuCategories => ({ type: WORKING_MENU_DELETE_MENU_CATEGORY, menuCategories });
export const deleteMenuCategoryItem = updatedMenuCategories => ({ type: WORKING_MENU_DELETE_MENU_CATEGORY_ITEM, updatedMenuCategories });
export const addMenuCategoryItem = updatedMenuCategories => ({ type: WORKING_MENU_ADD_MENU_CATEGORY_ITEM, updatedMenuCategories });
export const updateMenuCategoryItems = updatedMenuCategories => ({ type: WORKING_MENU_UPDATE_MENU_CATEGORY_ITEMS, updatedMenuCategories });
export const setNextPlaceholderId = () => ({ type: WORKING_MENU_NEXT_PLACEHOLDER_ID });
export const menuApiRequestBegin = () => ({ type: WORKING_MENU_API_REQUEST_BEGIN });
export const menuSaveSuccess = (menu, updatedMasterMenus, updatedMenuCategories) => ({ type: MENU_SAVED, menu, updatedMasterMenus, updatedMenuCategories });

export const menuAssignmentsReceived = menuAssignments => ({ type: MENU_ASSIGNMENTS_RECEIVED, menuAssignments });
export const menuAssignmentsUpdate = menuAssignments => ({ type: MENU_ASSIGNMENTS_UPDATE, menuAssignments });
export const menuAssignmentsReset = () => ({ type: MENU_ASSIGNMENTS_RESET });

export const getMasterMenus = () => (dispatch, getState) => {
  const { masterMenuManagement: { workingMasterMenuId } } = getState();

  dispatch(getMenuApi())
    .then(masterMenus => dispatch(masterMenusReceived(masterMenus)))
    .then(() => {
      if (workingMasterMenuId) dispatch(initWorkingMasterMenu(workingMasterMenuId));
    });
};

export const masterMenusReceived = masterMenus => dispatch => dispatch({ type: MENUS_RECEIVED, masterMenus });

const getMenuApi = () => dispatch =>
  dispatch(callApi(`Menu`))
    .then(response => response.json());

export const addMenu = () => (dispatch, getState) => { //TODO: this feels slow
  const newMenu = {
    name: 'New',
    menuCategories: [
      {
        name: '',
        menuCategoryItems: [],
      },
    ],
  };

  dispatch(menuApiRequestBegin());

  return dispatch(callApi(`Menu`, { body: newMenu, method: 'POST' }))
    .then(response => response.json())
    .then(apiMenu => {
      dispatch(menuSaved(apiMenu));
      dispatch(setWorkingMasterMenuId(apiMenu.id));
      dispatch(menuAdded(apiMenu.id));
      dispatch(getMasterMenus());
    });
};

export const menuSaved = menu => (dispatch, getState) => {
  const { masterMenuManagement: { masterMenus } } = getState();
  const updatedMasterMenus = masterMenus.map(m => m.id === menu.id ? menu : m);
  const updatedMenuCategories = flattenAndSortCategories(menu.menuCategories);

  dispatch(menuSaveSuccess(menu, updatedMasterMenus, updatedMenuCategories));
};

export const updateMenuApi = () => (dispatch, getState) => {
  const { masterMenuManagement: { workingMasterMenuId, menuName, menuCategories } } = getState();

  const apiSafeCategories = denormalizeCategories([...menuCategories], workingMasterMenuId);
  const menu = {
    id: workingMasterMenuId,
    name: menuName,
    menuCategories: apiSafeCategories,
  };
  const newMenu = sanitizeNewIds(menu);

  dispatch(menuApiRequestBegin());

  return dispatch(callApi(`Menu/${menu.id}`, { body: newMenu, method: 'PUT' }))
    .then(response => response.json())
    .then(apiMenu => dispatch(menuSaved(apiMenu)));
};

export const deleteMenu = menuId => (dispatch, getState) => {
  const { masterMenuManagement: { masterMenus, menuCategories } } = getState();
  const menuIndex = masterMenus.findIndex(menu => menu.id === menuId);
  const newMenus = [...masterMenus];

  newMenus.splice(menuIndex, 1);
  dispatch(masterMenusReceived(newMenus));
  dispatch(setWorkingMasterMenuId(null));

  const menu = masterMenus[menuIndex];
  const deletedMenuCategories = softDeleteChildren(menuCategories);
  const apiSafeCategories = denormalizeCategories(deletedMenuCategories, menuId);

  menu.recordStatus = 'Deleted';
  menu.menuCategories = apiSafeCategories;

  return dispatch(callApi(`Menu/${menu.id}`, { body: menu, method: 'PUT' }))
    .then(dispatch(menuDeleted()))
    .finally(dispatch(getMasterMenus()));
};

const softDeleteChildren = menuCategories => {
  return menuCategories.map(menuCategory => {
    const deletedMenuCategoryItems = menuCategory.menuCategoryItems.map(menuCategoryItem => {
      menuCategoryItem.recordStatus = 'Deleted';

      return menuCategoryItem;
    });

    const deletedMenuCategory = {
      ...menuCategory,
      recordStatus: 'Deleted',
      menuCategoryItems: deletedMenuCategoryItems,
    };

    return deletedMenuCategory;
  });
};

const flattenAndSortCategories = menuCategories => {
  const newMCs = [...menuCategories];

  return newMCs.reduce((acc, menuCategory) => {
    acc = acc.concat(menuCategory);
    if (menuCategory.menuCategories && menuCategory.menuCategories.length > 0) {
      acc = acc.concat(flattenAndSortCategories([...menuCategory.menuCategories]));
    }

    return acc;
  }, []).sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  });
};

// reattach all subcategories to it's parent before sending to API
const denormalizeCategories = (menuCategories, menuId) => {
  const categories = [...menuCategories];
  const topLevelCategories = categories.filter(c => c.menuId === menuId);

  const assignChildren = parents => {
    if (parents && parents.length) {
      const assigned = [];

      for (let i = 0; i < parents.length; i++) {
        const parent = parents[i];
        const children = menuCategories.filter(c => c.parentMenuCategoryId === parent.id);
        const assignedChildren = assignChildren(children);

        parent.menuCategories = assignedChildren;
        assigned.push(parent);
      }

      return assigned;
    }
  };

  return assignChildren(topLevelCategories);
};

const sanitizeNewIds = menu => {
  const menuCategories = _.cloneDeep(menu.menuCategories);

  const sanitizeCategories = menuCategories => {

    return menuCategories.map(mc => {
      if (mc.id < 0) delete mc.id;
      if (mc.parentMenuCategoryId < 0) delete mc.parentMenuCategoryId;

      const newMCIs = mc.menuCategoryItems.map(mci => {
        if (mci.id < 0) delete mci.id;
        if (mci.menuCategoryId < 0) delete mci.menuCategoryId;

        return mci;
      });

      mc.menuCategoryItems = newMCIs;

      if (mc.menuCategories && mc.menuCategories.length > 0) {
        mc.menuCategories = sanitizeCategories(mc.menuCategories);
      }

      return mc;
    });
  };

  const newMCs = sanitizeCategories(menuCategories);

  if (menu.id < 0) delete menu.id;

  const newMenu = {
    ...menu,
    menuCategories: newMCs,
  };

  return newMenu;
};

const convertToMenuCategoryItem = (menuCategoryId, menuItem, index, placeholderId) => {
  return {
    menuCategoryId,
    menuItemId: menuItem.id,
    name: menuItem.name,
    description: menuItem.description,
    imageUrl: menuItem.imageUrl,
    cost: menuItem.cost,
    calories: menuItem.calories,
    allergens: menuItem.allergens,
    price: menuItem.price,
    taxTypeId: menuItem.taxTypeId,
    sortOrder: index,
    id: placeholderId,
    recordStatus: 'Active',
    preparationTimeInSeconds: menuItem.preparationTimeInSeconds,
    serviceCharge: menuItem.serviceCharge,
    noTax: menuItem.noTax,
    noServiceCharge: menuItem.noServiceCharge,
    noGratuity: menuItem.noGratuity,
  };
};

const copyMenuCategoryItem = (destinationMenuCategoryId, menuCategoryItem, destinationIndex) => {
  return {
    ...menuCategoryItem,
    id: null,
    menuCategoryId: destinationMenuCategoryId,
    menuItemId: menuCategoryItem.menuItemId,
    sortOrder: destinationIndex,
  };
};

export const dropAddMenuCategoryItem = (draggableId, droppableDestination) => (dispatch, getState) => {
  const { menuItem: { data }, masterMenuManagement: { menuCategories, placeholderId } } = getState();
  const menuItems = Array.from(data);
  const destinationMenuCategoryId = parseInt(droppableDestination.droppableId, 10);
  const menuItemId = parseInt(draggableId.replace('menuItem', ''), 10);
  const menuItem = menuItems.find(mi => mi.id === menuItemId);
  const menuCategoryItem = convertToMenuCategoryItem(destinationMenuCategoryId, menuItem, droppableDestination.index, placeholderId);

  const destinationCategory = getDestinationMenuCategory(destinationMenuCategoryId, menuCategories);

  const sortedItems = insert(menuCategoryItem, destinationCategory.menuCategoryItems, droppableDestination.index);
  const newMenuCategories = workingMenuReorderItems(sortedItems, menuCategories, destinationMenuCategoryId);

  dispatch(setNextPlaceholderId());

  return dispatch(workingMenuCategoriesUpdate(newMenuCategories));
};

const getDestinationMenuCategory = (destinationMenuCategoryId, menuCategories) => {
  const destination = menuCategories.find(mc => mc.id === destinationMenuCategoryId);

  return destination;
};

const insert = (menuCategoryItem, destination, droppableDestinationIndex) => {
  const destClone = Array.from(destination);

  destClone.splice(droppableDestinationIndex, 0, menuCategoryItem);

  return destClone;
};

const workingMenuReorderItems = (sortedItems, newMenuCategories, destinationMenuCategoryId) => {
  const sortOrderedItems = sortedItems.map((mci, index) => {
    mci.sortOrder = index;

    return mci;
  });

  const category = newMenuCategories.find(mc => mc.id === destinationMenuCategoryId);
  const newCategory = {
    ...category,
    menuCategoryItems: sortOrderedItems,
  };

  const updatedMenuCategories = newMenuCategories.map(mc => mc.id === destinationMenuCategoryId ? newCategory : mc);

  return updatedMenuCategories;
};

export const moveMenuCategoryItem = (source, destination, draggableId) => (dispatch, getState) => {
  const { masterMenuManagement: { menuCategories } } = getState();
  const destinationMenuCategoryId = parseInt(destination.droppableId, 10);
  const sourceMenuCategoryId = parseInt(source.droppableId, 10);
  const sourceCategory = menuCategories.find(mc => mc.id === sourceMenuCategoryId);
  const menuCategoryItems = filterAndSort(sourceCategory.menuCategoryItems);
  const sourceMenuCategoryItemId = parseInt(draggableId.replace('menuCategoryItem', ''), 10);

  if (sourceMenuCategoryId === destinationMenuCategoryId) {
    const sortedItems = arrayMove(menuCategoryItems, source.index, destination.index);
    const newMenuCategories = workingMenuReorderItems(sortedItems, menuCategories, destinationMenuCategoryId);

    return dispatch(workingMenuCategoriesUpdate(newMenuCategories));
  } else {
    const menuCategoryItem = menuCategoryItems.find(mci => mci.id === sourceMenuCategoryItemId);
    const menuCategoryItemCopy = copyMenuCategoryItem(destinationMenuCategoryId, menuCategoryItem, destination.index);

    dispatch(workingMenuDeleteMenuCategoryItem(sourceMenuCategoryId, menuCategoryItem.id));

    return dispatch(workingMenuAddMenuCategoryItem(destinationMenuCategoryId, menuCategoryItemCopy));
  }
};

const filterAndSort = menuCategoryItems => {
  return menuCategoryItems.filter(m => m.recordStatus === 'Active')
    .sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
};

export const convertToMultipleMenuCategoryItems = (menuCategoryId, selectedMenuItems) => (dispatch, getState) => {
  const { menuItem: { data }, masterMenuManagement: { placeholderId, menuCategories }  } = getState();
  const libraryItems = data;
  const menuCategoryItems = menuCategories.find(mc => mc.id === menuCategoryId).menuCategoryItems;
  const sortOrder = menuCategoryItems.length;

  selectedMenuItems.forEach(selectedMenuItem => {
    const libraryItem = libraryItems.find(item => item.id === selectedMenuItem.id);
    const menuCategoryItem = convertToMenuCategoryItem(menuCategoryId, libraryItem, sortOrder, placeholderId);

    dispatch(workingMenuAddMenuCategoryItem(menuCategoryId, menuCategoryItem));
  });
};

export const updateMenuCategoryItem = menuCategoryItem => (dispatch, getState) => {
  const { masterMenuManagement: { menuCategories } } = getState();

  const category = menuCategories.find(mc => mc.id === menuCategoryItem.menuCategoryId);
  const indexOfItem = category.menuCategoryItems.findIndex(mci => mci.id === menuCategoryItem.id);

  category.menuCategoryItems.splice(indexOfItem, 1, menuCategoryItem);
  const newMenuCategories = menuCategories.map(mc => mc.id === menuCategoryItem.menuCategoryId ? category : mc);

  return dispatch(workingMenuCategoriesUpdate(newMenuCategories));
};

export const updateMenuCategory = (menuCategoryId, fieldName, value) => (dispatch, getState) => {
  const { masterMenuManagement: { menuCategories } } = getState();
  const editedItem = menuCategories.find(t => t.id === menuCategoryId);

  editedItem[fieldName] = value;

  const newCategories = menuCategories.map(menuCategory => menuCategory.id === editedItem.menuCategoryId ? editedItem : menuCategory);

  return dispatch(workingMenuCategoriesUpdate(newCategories));
};

export const handleMenuFieldChange = (fieldName, value) => (dispatch, getState) => {
  const { masterMenuManagement: { workingMasterMenu } } = getState();

  const newMenu = {
    ...workingMasterMenu,
    [fieldName]: value,
  };

  return dispatch(workingMenuUpdate(newMenu));
};

export const handleAddCategory = () => (dispatch, getState) => {
  const { masterMenuManagement: { workingMasterMenuId, placeholderId, menuCategories } } = getState();
  const categorySiblings = menuCategories.filter(c => c.menuId === workingMasterMenuId);

  const newCategory = {
    id: placeholderId,
    menuId: workingMasterMenuId,
    menuCategoryItems: [],
    menuCategories: [],
    recordStatus: 'Active',
    sortOrder: categorySiblings.length,
  };

  dispatch(workingMenuAddMenuCategory(newCategory));

  return Promise.resolve(); // so UI can scroll to bottom
};

export const getMenuAssignments = () => dispatch => {
  dispatch(getMenuAssignmentsApi())
    .then(menuAssignments => dispatch(menuAssignmentsReceived(menuAssignments)));
};

const getMenuAssignmentsApi = () => dispatch =>
  dispatch(callApi(`MenuAssignment`))
    .then(response => response.json());

export const menuAssignmentsSave = () => (dispatch, getState) => {
  const { masterMenuManagement: { menuAssignments } } = getState();

  const safeMenuAssignments = sanitizeNewAssignmentIds(menuAssignments);

  dispatch(callApi(`MenuAssignment/UpdateAll`, { body: safeMenuAssignments, method: 'PUT' }))
    .then(response => response.json())
    .then(newMenuAssignments => dispatch(menuAssignmentsReceived(newMenuAssignments)));
};

export const menuAssignmentOnSelect = (menu, purpose) => (dispatch, getState) => {
  const { masterMenuManagement: { menuAssignments, placeholderId } } = getState();
  const newMenuAssignments = [...menuAssignments];
  const newAssignment = {
    menuId: menu.id,
    assignment: purpose,
    id: placeholderId,
  };

  newMenuAssignments.push(newAssignment);

  dispatch(setNextPlaceholderId());

  return dispatch(menuAssignmentsUpdate(newMenuAssignments));
};

export const menuAssignmentOnRemove = (menuId, purpose) => (dispatch, getState) => {
  const { masterMenuManagement: { menuAssignments } } = getState();
  const newMenuAssignments = [...menuAssignments];

  const updatedMenuAssignments = newMenuAssignments.map(a => {
    if (a.menuId === menuId && a.assignment.toLowerCase() === purpose.toLowerCase()) {
      a.recordStatus = 'Deleted';
    }

    return a;
  });

  return dispatch(menuAssignmentsUpdate(updatedMenuAssignments));
};

export const menuAssignmentMarkDefault = (menuId, purpose) => (dispatch, getState) => {
  const { masterMenuManagement: { menuAssignments } } = getState();
  const newMenuAssignments = [...menuAssignments];

  const updatedMenuAssignments = newMenuAssignments.map(a => {
    if (a.assignment === purpose) {
      if (a.menuId === menuId) {
        a.default = !a.default;
      } else {
        a.default = false;
      }
    }

    return a;
  });

  return dispatch(menuAssignmentsUpdate(updatedMenuAssignments));
};

const sanitizeNewAssignmentIds = menuAssignments => {
  const assignments = [...menuAssignments];

  const newAssignments = assignments
    .filter(a => a.id > 0 || (a.id < 0 && (a.recordStatus === 'Active' || !a.recordStatus))) // don't send deleted items to API if they only exist in Redux state
    .map(a => {
      if (a.id < 0) delete a.id;

      return a;
    });

  return newAssignments;
};

export const workingMenuDeleteMenuCategoryItem = (menuCategoryId, menuCategoryItemId) => (dispatch, getState) => {
  const { masterMenuManagement: { menuCategories } } = getState();

  const newMenuCategories = _.cloneDeep(menuCategories);
  const categoryWithThisItem = newMenuCategories.find(mc => mc.id === menuCategoryId);
  const indexOfItem = categoryWithThisItem.menuCategoryItems.findIndex(item => item.id === menuCategoryItemId);
  const deletedItem = categoryWithThisItem.menuCategoryItems[indexOfItem];

  deletedItem.recordStatus = 'Deleted';

  categoryWithThisItem.menuCategoryItems.splice(indexOfItem, 1, deletedItem);
  const updatedMenuCategories = newMenuCategories.map(mc => mc.id === menuCategoryId ? categoryWithThisItem : mc);

  dispatch(deleteMenuCategoryItem(updatedMenuCategories));
};

export const workingMenuAddMenuCategoryItem = (menuCategoryId, menuCategoryItem) => (dispatch, getState) => {
  const { masterMenuManagement: { menuCategories, placeholderId } } = getState();

  menuCategoryItem.id = placeholderId;
  const category = menuCategories.find(mc => mc.id === menuCategoryId);

  category.menuCategoryItems.push(menuCategoryItem);
  const updatedMenuCategories = menuCategories.map(mc => mc.id === menuCategoryId ? category : mc);

  dispatch(addMenuCategoryItem(updatedMenuCategories));
};

export const workingMenuUpdateMenuCategoryItems = (menuCategoryId, menuCategoryItems) => (dispatch, getState) => {
  const { masterMenuManagement: { menuCategories } } = getState();

  const category = menuCategories.find(mc => mc.id === menuCategoryId);
  const newCategory = {
    ...category,
    menuCategoryItems,
  };

  const updatedMenuCategories = menuCategories.map(mc => mc.id === menuCategoryId ? newCategory : mc);

  dispatch(updateMenuCategoryItem(updatedMenuCategories));
};

export const workingMenuDeleteMenuCategory = menuCategoryId => (dispatch, getState) => {
  const { masterMenuManagement: { menuCategories } } = getState();
  const clone = [...menuCategories];
  const indexOfItem = clone.findIndex(t => t.id === menuCategoryId);
  const deletedCategory = clone[indexOfItem];

  deletedCategory.recordStatus = 'Deleted';
  clone.splice(indexOfItem, 1, deletedCategory);

  dispatch(deleteMenuCategory(clone));
};

export const initWorkingMasterMenu = menuId => (dispatch, getState) => {
  const { masterMenuManagement: { masterMenus } } = getState();

  const workingMasterMenu = masterMenus.find(menu => menu.id === menuId);
  const flattenedCategories = flattenAndSortCategories([...workingMasterMenu.menuCategories]);

  dispatch(initializeWorkingMasterMenu(workingMasterMenu));
  dispatch(initializeMenuCategories(flattenedCategories));
};

export const toggleCategoryCollapse = menuCategoryId => (dispatch, getState) => {
  const { masterMenuManagement: { collapsedCategoryIds } } = getState();

  const newCollapsedCategoryIds = [...collapsedCategoryIds];
  const indexOfCategory = newCollapsedCategoryIds.indexOf(menuCategoryId);

  if (indexOfCategory < 0) {
    newCollapsedCategoryIds.push(menuCategoryId);
  } else {
    newCollapsedCategoryIds.splice(indexOfCategory, 1);
  }

  dispatch({ type: WORKING_MENU_TOGGLE_EXPAND_CATEGORY, collapsedCategoryIds: newCollapsedCategoryIds });
};

export const collapseCategories = () => (dispatch, getState) => {
  const { masterMenuManagement: { collapsedCategoryIds, menuCategories } } = getState();

  const newCollapsedCategoryIds = [...collapsedCategoryIds];
  const categoryIdsForThisMenu = menuCategories.map(mc => mc.id);

  categoryIdsForThisMenu.forEach(mcid => {
    const indexOfId = newCollapsedCategoryIds.indexOf(mcid);

    if (indexOfId === -1) newCollapsedCategoryIds.push(mcid);
  });

  dispatch({ type: WORKING_MENU_COLLAPSE_ALL_CATEGORIES, collapsedCategoryIds: newCollapsedCategoryIds });
};

export const expandCategories = () => (dispatch, getState) => {
  const { masterMenuManagement: { collapsedCategoryIds, menuCategories } } = getState();

  const newCollapsedCategoryIds = [...collapsedCategoryIds];
  const categoryIdsForThisMenu = menuCategories.map(mc => mc.id);

  categoryIdsForThisMenu.forEach(mcid => {
    const indexOfId = newCollapsedCategoryIds.indexOf(mcid);

    if (indexOfId > -1) newCollapsedCategoryIds.splice(indexOfId, 1);
  });

  dispatch({ type: WORKING_MENU_EXPAND_ALL_CATEGORIES, collapsedCategoryIds: newCollapsedCategoryIds });
};

export const workingMenuAddSubCategory = parentMenuCategoryId => (dispatch, getState) => {
  const { masterMenuManagement: { menuCategories, placeholderId } } = getState();
  const categorySiblings = [...menuCategories].filter(c => c.parentMenuCategoryId === parentMenuCategoryId);

  const subCategory = {
    id: placeholderId,
    parentMenuCategoryId,
    menuCategoryItems: [],
    menuCategories: [],
    recordStatus: 'Active',
    sortOrder: categorySiblings.length,
  };

  const updatedMenuCategories = [...menuCategories, subCategory];

  dispatch(workingMenuCategoriesUpdate(updatedMenuCategories));
  dispatch(setNextPlaceholderId());
};

export const moveMenuCategory = (menuCategoryId, direction) => (dispatch, getState) =>  {
  const { masterMenuManagement: { menuCategories } } = getState();

  const category = [...menuCategories].find(mc => mc.id === menuCategoryId);
  const sourceSortOrder = category.sortOrder;
  const destinationSortOrder = direction === 'up' ? sourceSortOrder - 1 : sourceSortOrder + 1;
  let allCategories = [...menuCategories];

  if (category.menuId) { // Top-Level Category
    const categorySiblings = menuCategories.filter(c => c.menuId === category.menuId);
    const sortedCategories = updateSiblingSort(categorySiblings, sourceSortOrder, destinationSortOrder).sort((a, b) => a.sortOrder - b.sortOrder);

    allCategories = menuCategories.map(c => c.menuId === category.menuId ? { ...sortedCategories.find(u => u.id === c.id) } : c);
  } else if (category.parentMenuCategoryId) { // Sub-Category
    const categorySiblings = menuCategories.filter(c => c.parentMenuCategoryId === category.parentMenuCategoryId);
    const sortedCategories = updateSiblingSort(categorySiblings, sourceSortOrder, destinationSortOrder).sort((a, b) => a.sortOrder - b.sortOrder);

    allCategories = menuCategories.map(c => c.parentMenuCategoryId === category.parentMenuCategoryId ? { ...sortedCategories.find(u => u.id === c.id) } : c);
  }
  const allCategoriesSorted = allCategories.sort((a, b) => a.sortOrder - b.sortOrder);

  return dispatch(workingMenuCategoriesUpdate(allCategoriesSorted));
};

const updateSiblingSort = (menuCategories, sourceIndex, destinationIndex) => {
  const sortedItems = arrayMove([...menuCategories], sourceIndex, destinationIndex);

  return sortedItems.map((mc, index) => {
    mc.sortOrder = index;

    return mc;
  });
};
