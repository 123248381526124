import {
  ACCOUNT_RECEIVED,
  DELETE_ACCOUNT,
  RESET_CURRENT_ACCOUNT,
  CLONE_ACCOUNT,
  RECEIVE_ACCOUNT_NAME_AND_IDS,
} from './constants';
import { removeRecent } from 'actions/recents';
import { callApi } from 'shared/CallApi';
import {
  fetchAccountsSuccess,
  accountColumnsReceived,
} from './api';
import { toODataString } from '@progress/kendo-data-query';

export const accountReceived = account => ({
  type: ACCOUNT_RECEIVED,
  account,
});

export const accountDeleted = response => ({
  type: DELETE_ACCOUNT,
  response,
});

export const currentAccountReset = () => ({
  type: RESET_CURRENT_ACCOUNT,
});

export const accountCloned = response => ({
  type: CLONE_ACCOUNT,
  response,
});

export const accountNameAndIdsReceived = nameAndIds => ({
  type: RECEIVE_ACCOUNT_NAME_AND_IDS,
  nameAndIds,
});

export const getAccounts = (params, append = false) => {
  return dispatch => {
    return getAccountsApi(dispatch, params).then(result => {
      dispatch(fetchAccountsSuccess({ data: result, append }));

      return result;
    });
  };
};

const getAccountsApi = (dispatch, params) => {
  return dispatch(callApi(`account?${params}`))
    .then(result => result.json())
    .catch(console.error);
};

export const saveAccounts = accounts => {
  let okay = true;
  let status = 0;
  let statusText;

  return dispatch =>
    dispatch(callApi(`account/BatchSave`, {
      body: accounts,
      method: 'POST',
    }))
      .then(result => {
        if (!result.ok) {
          status = result.status;
          statusText = result.statusText;
          okay = false;
        }

        return result;
      })
      .then(json => {
        if (okay) {
          return json;
        }
        if (json.message) {
          throw Error(json.message);
        }
        if (statusText || status) {
          throw Error(`${status} - ${statusText}`);
        }
        throw Error('unknown');
      });
};

export const getAccount = accountId =>
  dispatch =>
    dispatch(callApi(`account/${accountId}`))
      .then(result => result.json())
      .then(json => {
        dispatch(accountReceived(json));

        return json;
      });

export const getAccountContacts = accountId =>
  dispatch =>
    dispatch(callApi(`account/${accountId}/contact`))
      .then(result => result.json());

export const deleteAccountContact = (accountId, contactId) =>
  dispatch =>
    dispatch(callApi(`account/${accountId}/contact/${contactId}`, { method: 'DELETE' }));

export const addAccountContact = (accountId, contactId) =>
  dispatch =>
    dispatch(callApi(`account/${accountId}/contact/${contactId}`, { method: 'POST' }));

export const setContactAsPrimary = (accountId, contactId) =>
  dispatch =>
    dispatch(callApi(`account/${accountId}/contact/${contactId}/SetAsPrimary`, { method: 'PUT' }));

export const saveAccount = account =>
  dispatch =>
    dispatch(callApi(`account/${account.id}`, { method: 'PUT', body: account }))
      .then(response => response.json());

export const addAccount = account =>
  dispatch =>
    dispatch(callApi(`account`, { body: account }))
      .then(response => response.json());

export const deleteAccount = accountId =>
  dispatch =>
    dispatch(callApi(`account/${accountId}`, { method: 'DELETE' }))
      .then(response => {
        dispatch(accountDeleted(response));
        dispatch(removeRecent(accountId, 'account'));
      });

export const clearCurrentAccount = () =>
  dispatch =>
    dispatch(currentAccountReset());

export const cloneAccount = account =>
  dispatch =>
    dispatch(callApi(`account/${account.id}/clone`, { method: 'POST' }))
      .then(result => result.json())
      .then(json => {
        dispatch(accountReceived(json));

        return json;
      });

export const getAccountColumns = () =>
  dispatch =>
    dispatch(callApi('userSetting/account_columns'))
      .then(response => response.json())
      .then(columnString => {
        const columnList = columnString ? columnString.split(',') : [];

        dispatch(accountColumnsReceived(columnList));

        return columnList;
      })
      .catch(error => {
        console.error(error);
      });

export const saveAccountColumns = columns =>
  dispatch =>
    dispatch(
      callApi('userSetting/account_columns', { method: 'PUT', body: columns.join() }))
      .then(response => response.text())
      .then(dispatch(accountColumnsReceived(columns)));

export const reorderAccountColumns = columns => {
  return dispatch => dispatch(
    callApi('userSetting/account_columns', { method: 'PUT', body: columns.join() }))
    .then(response => response.json());
};

export const getAccountNameAndIds = () => {
  return dispatch => {
    return dispatch(callApi(`Account/NameAndId`))
      .then(response => response.json())
      .then(nameAndIds => {
        dispatch(accountNameAndIdsReceived(nameAndIds));
      });
  };
};

export const searchAccounts = searchTerm => dispatch => {
  const odataParams = toODataString({
    take: 5,
    filter: {
      filters: [
        { field: 'name', operator: 'contains', value: searchTerm },
      ],
    },
  });

  return dispatch(callApi(`account/search?${odataParams}`))
    .then(result => result.json())
    .catch(console.error);
};
