import React, { Component } from 'react';
import {
  TextField as MaterialTextField,
  InputAdornment,
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  flexGrowWithPad: {
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
  },
  label: {
    color: theme.palette.grey[200],
  },
  input: {
    color: theme.palette.grey[700],
  },
  hhmm: {
    '& p': {
      fontSize: 11,
    },
  },
});

class TimeField extends Component {
  onInput = event => {
    if (!this.props.onFieldChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    let input = event.target.value.replace(/\D/, "");

    if (input.length < 4) {
      input = "0000" + input;
    }

    let time = input.substr(input.length - 4).replace(/(\d{1,2})(\d{2})/, "$1:$2");

    this.props.onFieldChange(time);
  }

  onBlur = () => {
    this.props.onBlur && this.props.onBlur();
  }

  render() {
    const {
      classes,
      disabled,
      value,
      fieldName,
      label,
      placeholder,
      error,
      inputAdornment,
      className,
      inputComponent,
      margin,
    } = this.props;

    return (
      <MaterialTextField
        onInput={this.onInput}
        label={label}
        placeholder={placeholder}
        error={error}
        className={classNames(classes.flexGrowWithPad, className)}
        name={fieldName}
        margin={margin || "normal"}
        value={value || '00:00'}
        disabled={disabled}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        InputProps={{
          classes: {
            root: classes.input,
            disabled: classes.input,
          },
          inputComponent,
          onBlur: this.onBlur,
          startAdornment: inputAdornment,
          endAdornment: <InputAdornment className={classes.hhmm} position="end">(hh:mm)</InputAdornment>,
        }}
      />
    );
  }
}

export default withStyles(styles)(TimeField);
