import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress }  from '@material-ui/core';
import PrimaryButton  from 'Components/Buttons/PrimaryButton';

const styles = theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.grey[50],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class PrimaryButtonWithSpinner extends Component {
  render() {
    const { classes, children, isLoading, disabled, ...rest } = this.props;

    return (
      <div className={classes.wrapper}>
        <PrimaryButton {...rest} isLoading={isLoading} disabled={disabled} >
          {children}
        </PrimaryButton>
        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  }
}

export default withStyles(styles)(PrimaryButtonWithSpinner);
