import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function MessagesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M23.922395,15.45 C23.872395,15.25 23.872395,15.05 23.972395,14.85 C24.622395,13.7 25.022395,12.4 25.022395,11 C25.022395,6.6 21.222395,3 16.522395,3 C14.322395,3 12.322395,3.8 10.822395,5.1 C15.972395,5.7 20.022395,9.9 20.022395,15 C20.022395,16.25 19.772395,17.45 19.322395,18.55 C19.872395,18.35 20.422395,18.1 20.922395,17.85 C21.122395,17.75 21.322395,17.7 21.522395,17.8 L24.572395,19 C24.872395,19.1 25.122395,18.85 25.022395,18.55 L23.922395,15.45 L23.922395,15.45 Z M9.52239504,7 C4.82239504,7 1.02239504,10.6 1.02239504,15 C1.02239504,16.4 1.42239504,17.7 2.07239504,18.85 C2.17239504,19.05 2.22239504,19.25 2.12239504,19.45 L1.02239504,22.5 C0.922395038,22.8 1.17239504,23.05 1.47239504,22.95 L4.52239504,21.75 C4.72239504,21.7 4.92239504,21.7 5.12239504,21.8 C6.42239504,22.55 7.92239504,22.95 9.52239504,22.95 C14.222395,22.95 18.022395,19.35 18.022395,14.95 C18.022395,10.6 14.222395,7 9.52239504,7 L9.52239504,7 Z" />
    </SvgIcon>
  );
}

export default MessagesIcon;
