import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';

import EmailManagement from './EmailManagement';
import { Route } from 'react-router-dom';

class EmailAdmin extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/admin/email" component={EmailManagement} />
      </Switch>
    );
  }
}

export default withRouter(EmailAdmin);
