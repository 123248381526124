import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import EventCard from './EventCard';
import QuickAddEventCard from './QuickAddEventCard';
import {
  setFocusedEventIndex,
  deleteStoreBookingEvent,
  cloneStoreBookingEvent,
  addStoreBookingEvent,
  storeBookingSetIsEditing,
  toggleIncludeBookingEvent,
} from 'actions/booking';

const styles = () => ({
  eventsPanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    overflowX: 'auto',
  },
});

const BookingEventSelector = props => {
  const {
    classes,
    events,
    setFocusedEventIndex,
    addStoreBookingEvent,
    deleteStoreBookingEvent,
    cloneStoreBookingEvent,
    storeBookingSetIsEditing,
    toggleIncludeBookingEvent,
    focusedEventIndex,
  } = props;

  const handleDelete = eventIndex => {
    deleteStoreBookingEvent(eventIndex);
    storeBookingSetIsEditing(true);
  };

  const handleClone = eventIndex => {
    cloneStoreBookingEvent(eventIndex);
    storeBookingSetIsEditing(true);
  };

  const handleInclude = eventIndex => {
    toggleIncludeBookingEvent(eventIndex);
  };

  const addEvent = () => {
    addStoreBookingEvent();
    storeBookingSetIsEditing(true);
  };

  return (
    <div className={classes.eventsPanel}>
      {events && events.filter(event => event.recordStatus === 'Active').map((event, index) => {
        return (
          <EventCard
            event={event}
            key={event.id}
            isFocused={focusedEventIndex === index}
            onSelectEvent={() => setFocusedEventIndex(index)}
            onDelete={() => handleDelete(index)}
            onClone={() => handleClone(index)}
            onSetIsIncludedInBookingTotal={() => handleInclude(index)}
          />);
      })}
      <QuickAddEventCard onClick={addEvent} />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    booking: {
      manager: {
        storeBooking,
        focusedEventIndex,
      },
    },
  } = state;

  return {
    events: storeBooking.events,
    focusedEventIndex,
  };
};

const mapDispatchToProps = {
  setFocusedEventIndex,
  deleteStoreBookingEvent,
  cloneStoreBookingEvent,
  addStoreBookingEvent,
  storeBookingSetIsEditing,
  toggleIncludeBookingEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookingEventSelector));
