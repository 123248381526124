import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import ActivityPanel from 'Components/ActivityPanel';
import ProposalSection from './ProposalSection';
import { PROPOSAL_TYPES } from 'constants/proposalTypes';

const styles = theme => ({
  root: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
  },
  tabContainer: {
    height: 40,
    backgroundColor: theme.palette.grey[50],
  },
  tabContent: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

const tabs = ['Activity', 'Proposals'];

class LeadTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }

  handleChange = tabIndex => {
    this.setState({ tabIndex });
  };

  render() {
    const { classes, lead } = this.props;
    const { tabIndex } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.tabContainer}>
          <BlueTextTab
            onTabChange={this.handleChange}
            tabs={tabs}
            tabIndex={tabIndex} />
        </div>
        <div className={classes.tabContent}>
          {tabIndex === 0 && <ActivityPanel mainContent />}
          {tabIndex === 1 && <ProposalSection linkedEntityId={lead.id} entityType={PROPOSAL_TYPES.lead} />}
        </div>

      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LeadTabs);
