import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import Done from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  step: {
    position: 'relative',
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'space-between',
    height: 32,
    borderRight: '3px solid',
    '&:first-child': {
      borderTopLeftRadius: 18,
      borderBottomLeftRadius: 18,
      '& $content': {
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
      },
    },
    '&:last-child': {
      borderTopRightRadius: 18,
      borderBottomRightRadius: 18,
      borderRightWidth: 0,
      '& $content': {
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeStep: {
    '& $content,': {
      background: theme.palette.primary.dark, // if no type given
      color: theme.common.white,
    },
  },
  disabledStep: {
    '& $content,': {
      background: '#d1dbe1',
      color: '#677d7d',
    },
  },
  disabledCompletedStep: {
    '& $content,': {
      background: '#d1dbe1',
      color: '#677d7d',
    },

  },
  completeStep: {
    borderRightColor: theme.common.emerald,
    '& $content,': {
      background: lighten(theme.common.emerald, 0.75),
      color: theme.common.green,
    },
  },
  doneIcon: {
    visibility: 'visible',
    marginTop: -4,
  },
  doneLabel: {
    visibility: 'hidden',
    marginTop: -20,
  },
  content: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    background: theme.palette.background.paper,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },

  BookingStep: {
    borderRightColor: theme.palette.bookingEvent,
    '&:hover': {
      '& $content': {
        background: lighten(theme.palette.bookingEvent, 0.7),
        color: theme.palette.bookingEvent,
      },
    },
  },
  BookingActiveStep: {
    '& $content,': {
      background: theme.palette.bookingEvent,
    },
  },
  BookingCompleteStep: {
    '& $content,': {
      background: lighten(theme.palette.bookingEvent, 0.8),
      color: theme.palette.bookingEvent,
    },
  },
  LeadStep: {
    borderRightColor: theme.palette.lead,
    '&:hover': {
      '& $content': {
        background: lighten(theme.palette.lead, 0.7),
        color: theme.palette.lead,
      },
    },
  },
  LeadActiveStep: {
    '& $content,': {
      background: theme.palette.lead,
    },
  },
  LeadCompleteStep: {
    '& $content,': {
      background: lighten(theme.palette.lead, 0.8),
      color: theme.palette.lead,
    },
  },
  PickupOrderStep: {
    borderRightColor: theme.palette.order,
    '&:hover': {
      '& $content': {
        background: lighten(theme.palette.order, 0.7),
        color: theme.palette.order,
      },
    },
  },
  DeliveryOrderStep: {
    borderRightColor: theme.palette.order,
    '&:hover': {
      '& $content': {
        background: lighten(theme.palette.order, 0.7),
        color: theme.palette.order,
      },
    },
  },
  PickupOrderActiveStep: {
    '& $content,': {
      background: theme.palette.order,
    },
  },
  DeliveryOrderActiveStep: {
    '& $content,': {
      background: theme.palette.order,
    },
  },
  PickupOrderCompleteStep: {
    '& $content,': {
      background: "#d0e5e8",
      color: "#0095a4",
    },
  },
  DeliveryOrderCompleteStep: {
    '& $content,': {
      background: "#d0e5e8",
      color: "#0095a4",
    },
  },
});

function StatusStepperStep(props) {
  const { classes, label, isActive, isComplete, isDisabled, isSkipped, onClick, type, tooltip, index, numOfSteps } = props;
  const stepClass = classes[type + 'Step'];
  const activeStepClass = classes[type + 'ActiveStep'];
  const completedStepClass = classes[type + 'CompleteStep'];
  const stepSize = numOfSteps / 100;

  const renderStep = () => {
    return (
      <div style={{ width: `${stepSize}%` }}
        className={classNames(classes.step,
          stepClass,
          isActive && classes.activeStep,
          isActive && activeStepClass,
          isDisabled && classes.disabledStep,
          (isComplete || isSkipped) && index !== 0 && !isDisabled && !isActive && completedStepClass,
          (isComplete || isSkipped) && index !== 0 && isDisabled && classes.disabledCompletedStep,
        )}
        onClick={onClick}
      >
        <div className={classes.content}>
          {isComplete && !isActive && index !== 0 && <span>&nbsp;</span>}
          {label}
          {isComplete && !isActive && index !== 0 && <Done className={classes.doneIcon} />}
        </div>
      </div>
    );
  };

  return (
    tooltip
      ?
      <Tooltip title={tooltip}>
        {renderStep()}
      </Tooltip>
      :
      isSkipped && index !== 0
        ?
        <Tooltip title={"Skipped"}>
          {renderStep()}
        </Tooltip>
        :
        renderStep()
  );
}

export default withStyles(styles)(StatusStepperStep);
