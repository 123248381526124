import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { fetchChitChat, saveNewChitChatThread, fetchMainChitChat } from 'actions/chitchat';
import { CHIT_CHAT_TYPES } from 'constants/entityTypes';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';
import * as moment from 'moment';
import Thread from 'Components/ChitChat/Thread';
import NewPostBox from 'Components/ChitChat/NewPostBox';
import { ENTITY_TYPES } from 'constants/entityTypes';
import DataLoader from 'Components/DataLoader';
import SimpleDialog from 'Components/SimpleDialog';
import classNames from 'classnames';

const styles = theme => ({
  chitChat: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '75%',
    minHeight: 0,
  },
  fullWidth: {
    width: '100%',
  },
  postsContainer: {
    flexGrow: 1,
  },
  positionLoadingBar: {
    position: 'relative',
    minHeight: 150,
  },
  timelineHeader: {
    flexGrow: 1,
    width: '100%',
    height: 33,
    lineHeight: '33px',
    verticalAlign: 'middle',
    paddingLeft: 33,
    backgroundColor: theme.palette.grey[50],
    fontSize: 17,
  },
  mainViewHeader: {
    backgroundColor: 'transparent',
    fontSize: 14,
    marginBottom: 8,
  },
  filterTimeline: {
    width: '100%',
    fontSize: 16,
    verticalAlign: 'middle',
    padding: '6px 8px 8px 33px',
  },
  messagesSection: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0,
    // width: '100%',
    height: '100%',
    padding: '0 33px 8px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

class ChitChat extends Component {
  state = {
    newPost: {},
    threads: [],
  }

  validationDialog = React.createRef();

  errorDialog = React.createRef();

  componentDidMount() {
    this.refreshChitChat();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.relationshipId !== this.props.relationshipId) {
      this.refreshChitChat();
    }
    if (prevProps.threads !== this.props.threads) {
      this.setState({ threads: this.props.threads });
    }
  }

  refreshChitChat = () => {
    const { fetchChitChat, fetchMainChitChat, relationshipId, chitChatRelationship } = this.props;

    if (chitChatRelationship === ENTITY_TYPES.main) {
      return fetchMainChitChat();
    }

    fetchChitChat(chitChatRelationship, relationshipId);
  }

  saveNewPost = () => {
    const { newPost } = this.state;
    const { relationshipId, chitChatRelationship, saveNewChitChatThread, recordName } = this.props;

    const post = {
      baseMessage: {
        subject: 'Post',
        content: newPost.message,
        time: moment().format(),
        recordName,
      },
      relationshipId,
      chitChatRelationship,
    };

    return saveNewChitChatThread(CHIT_CHAT_TYPES.post, post);
  }

  handleChange = fieldName => value => {
    const newPost = {
      ...this.state.newPost,
      [fieldName]: value,
    };

    this.setState({ newPost });
  }

  onAdd = () => {
    const { newPost } = this.state;

    if (!!newPost.message) {

      this.saveNewPost().then(() => {
        this.setState({ newPost: {} });
        this.refreshChitChat();
      }).catch(this.handleError);
    } else {
      this.validationDialog.current.open("Please include a message");
    }
  }

  handleError = () => {
    this.errorDialog.current.open();
  }

  // TODO: DELETE
  hardCodedFilter = threads => {
    const re = RegExp('hyper|shire|friends','gi');

    return threads.filter(t => {
      return re.test(t.recordName);
    });
  }

  render() {
    const { classes, isLoading, chitChatRelationship, isFullWidth, recordName, filter } = this.props;
    const {
      newPost,
      // threads,
    } = this.state;

    // // // // // // // // // // // // // // // // // // // // // // // // // // //
    // TODO: delete! Super hardcoded for Main chitchat demo
    let { threads } = this.state;

    if (filter) {
      if (filter === 'following') {
        threads = this.hardCodedFilter(threads);
      } else if (filter !== 'all') {
        threads = [];
      }
    }
    // // // // // // // // // // // // // // // // // // // // // // // // // // //

    return (
      <div className={classNames(classes.chitChat, isFullWidth && classes.fullWidth)}>
        <div className={classes.postsContainer}>
          <NewPostBox
            newPost={newPost}
            handleChange={this.handleChange}
            onAdd={this.onAdd}
          />
          {recordName === 'Main' ?
            <div className={classNames(classes.timelineHeader, classes.mainViewHeader)}>
              New Activity
            </div>
            :
            <>
            <div className={classes.timelineHeader}>
              Timeline
            </div>
            <div className={classes.filterTimeline}>
              <div>
                  Filter Timeline <ArrowDownButton isBorderless={true} />
              </div>
            </div>
            </>
          }

        </div>

        <div className={classes.messagesSection}>
          {isLoading &&
            <div className={classes.positionLoadingBar}>
              <DataLoader isLoading={true} inline={true} />
            </div>
          }
          {threads && threads.map(thread =>
            <Thread
              thread={thread}
              key={thread.id}
              onCommentSent={this.refreshChitChat}
              chitChatRelationship={chitChatRelationship}
            />
          )}
        </div>
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.validationDialog}
        />
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.errorDialog}
          message="Something went wrong. Please try again."
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      chitChat: {
        threads,
        isLoading,
      },
    },
  } = state;

  return {
    threads,
    isLoading,
  };
};

const mapDispatchToProps = {
  fetchChitChat,
  fetchMainChitChat,
  saveNewChitChatThread,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChitChat));
