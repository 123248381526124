import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
} from '@material-ui/core';
import Avatar from 'Components/Avatar';

const thumbnailHeight = 100;
const styles = theme => ({
  card: {
    width: '100%',
  },
  content: {
    display: 'flex',
    marginTop: -31,
    height: 74,
  },
  text: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    padding: 8,
    zIndex: 1,
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 'bold',
  },
  descriptionText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  thumbnail: {
    display: 'flex',
    height: thumbnailHeight,
    position: 'relative',
    minWidth: '100%',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '40%',
      bottom: 0,
      zIndex: 1,
      background: `linear-gradient(to top, ${theme.palette.common.white}, rgba(0,0,0,0))`,
    },
  },
  avatar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.common.bluegrey,
    fontSize: 32,
    fontWeight: '600',
    color: theme.palette.grey['50'],
  },
});

const MenuItemCard = ({
  classes,
  menuItem,
  onMenuItemClick,
  formatter,
}) => {
  const formattedPrice = formatter ? formatter.formatNumber(menuItem.price, 'c') : menuItem.price;

  return (
    <Card
      onClick={onMenuItemClick}
      className={classes.card}
    >
      <div className={classes.thumbnail}>
        {!!menuItem.imageUrl ?
          <CardMedia
            style={{
              height: thumbnailHeight,
              display: 'flex',
              flexGrow: 1,
            }}
            image={menuItem.imageUrl}
          />
          :
          <Avatar name={menuItem.name} />
        }
      </div>
      <div className={classes.content}>
        <div className={classes.text}>
          <div className={classes.topRow}>
            <p className={classes.name}>{menuItem.name}</p>
          </div>
          <div className={classes.middleRow}>
            <p className={classes.price}>{formattedPrice}</p>
          </div>
          <div className={classes.bottomRow}>
            <p className={classes.descriptionText} dangerouslySetInnerHTML={{ __html: menuItem.description }}></p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default withStyles(styles)(MenuItemCard);
