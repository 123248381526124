import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import Modal from 'Components/Modal';
import TextField from 'Components/TextField';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SectionHeader from 'Components/SectionHeader';
import CheckBox from 'Components/Checkbox';
import CoverDatePicker from 'Components/CoverDatePicker';
import { CurrencyField } from 'Components/currencyInput';
import ValidationBanner from 'Components/ValidationBanner';
import BookingContext from 'bookings/BookingContext';
import { getPaymentMethodKeys, getPaymentCategoryKeys } from 'constants/paymentMethods';
import moment from 'moment';

const styles = theme => {
  return {
    overflowIfTooBig: {
      overflow: 'auto',
      height: 520,
    },
    container: {
      display: 'flex',
      width: '100%',
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 12,
    },
    flex: {
      display: 'flex',
    },
    sectionHeader: {
      paddingLeft: 12,
      marginTop: 16,
    },
    section: {
      display: 'flex',
      flex: '1 1 auto',
    },
    fourtyWidth: {
      display: 'flex',
      width: '40%',
    },
    sixtyWidth: {
      display: 'flex',
      width: '60%',
    },
    oneThirdWidth: {
      display: 'flex',
      width: '33%',
    },
    twoThirdWidth: {
      display: 'flex',
      width: '66%',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
};

const comma = ", ";
const and = " and ";

class AddEditPaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment: props.payment,
      errors: undefined,
    };

    this.confirmDeleteFileDialog = React.createRef();
    this.validationBanner = React.createRef();
  }

  handleFieldChange = (fieldName, parser) => value => {
    const payment = {
      ...this.state.payment,
      [fieldName]: parser ? parser(value) : value,
    };

    this.setState({ payment });
  }

  handleCheckbox = fieldName => event => {
    const value = event.target.checked;

    const payment = {
      ...this.state.payment,
      [fieldName]: value,
    };

    this.setState({ payment });
  }

  onSave = () => {
    let errors = this.getErrors();

    if (errors) {
      errors = this.formatErrorWithAnd(errors);
      this.validationBanner.current.open(errors);
    } else {
      this.props.onSave && this.props.onSave(this.state.payment);
    }
  }

  isFieldProvided(field) {
    if (field === undefined || field == null || field.length < 1) {
      return false;
    }

    return true;
  }

  isCurrencyFieldProvided(field) {
    if (field === undefined || field == null || isNaN(field) || field < 1) {
      return false;
    }

    return true;
  }

  addError(errors, newError) {
    if (errors === undefined || errors === null || errors.length === 0) {
      return newError;
    } else {
      return errors + comma + newError;
    }
  }

  formatErrorWithAnd(errors) {
    const lastComma = errors.lastIndexOf(comma);

    if (lastComma >= 0) {
      const endOfLastComma = lastComma + comma.length;
      let newErrors = errors.substring(0, lastComma) + and + errors.substring(endOfLastComma);

      return newErrors;
    } else {
      return errors;
    }
  }

  getErrors = () => {
    const { payment } = this.state;
    let validationErrors = '';

    if (!this.isFieldProvided(payment.paymentMethod)) {
      validationErrors = this.addError(validationErrors, 'Payment Method');
    }
    if (!this.isCurrencyFieldProvided(payment.amount)) {
      validationErrors = this.addError(validationErrors, 'Amount');
    }
    if (!this.isFieldProvided(payment.dateApplied)) {
      validationErrors = this.addError(validationErrors, 'Date Applied');
    }

    validationErrors = validationErrors ? validationErrors + ' must be provided.' : undefined;

    return validationErrors;
  }

  render() {
    const {
      classes,
      onCancel,
    } = this.props;

    const {
      payment,
      errors,
    } = this.state;

    return (
      <Modal
        isOpened={true}
        onCancel={onCancel}
        title={payment && payment.id === undefined ? "Add New Payment" : "Edit Payment"}
        onSave={this.onSave}
        addTitleBottomBorder={false}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '865px' }}
      >
        <Paper className={classes.overflowIfTooBig}>
          <div className={classNames(classes.container, classes.flexColumn)}>
            <div className={classNames(classes.section, classes.flexColumn)}>
              <SectionHeader className={classes.sectionHeader}>Payment Details</SectionHeader>
              <div className={classes.flex}>
                <div className={classNames(classes.fourtyWidth, classes.flexColumn)}>
                  <div className={classNames(classes.flex)}>
                    <div className={classNames(classes.sixtyWidth)}>
                      <Select
                        label="Payment Method"
                        value={getPaymentMethodKeys(payment.paymentMethod)}
                        onFieldChange={this.handleFieldChange('paymentMethod')}
                        error={errors && errors.paymentMethod}
                      >
                        <MenuItem value="cash">Cash</MenuItem>
                        <MenuItem value="check">Check</MenuItem>
                        <MenuItem value="houseAccount">House Account</MenuItem>
                      </Select>
                    </div>
                    <div className={classNames(classes.fourtyWidth)}>
                      <CurrencyField
                        label="Amount"
                        fieldName="amount"
                        value={payment.amount}
                        onFieldChange={this.handleFieldChange('amount', parseFloat)}
                      />
                    </div>
                  </div>
                  <TextField
                    label="Received By"
                    value={payment.receivedBy}
                    onFieldChange={this.handleFieldChange('receivedBy')}
                  />
                  <TextField
                    label="Made By"
                    value={payment.madeBy}
                    onFieldChange={this.handleFieldChange('madeBy')}
                  />
                </div>
                <div className={classNames(classes.sixtyWidth, classes.flexColumn)}>
                  <div className={classNames(classes.flex)}>
                    <div className={classNames(classes.oneThirdWidth)}>
                      <CoverDatePicker
                        label="Date Applied"
                        fieldName="dateApplied"
                        error={errors && errors.dateApplied}
                        value={payment.dateApplied}
                        onFieldChange={this.handleFieldChange('dateApplied')}
                        minDate={moment().subtract(10, 'years').toDate()}
                      />
                    </div>
                    <div className={classNames(classes.oneThirdWidth)}>
                      <CheckBox
                        label="Deposit"
                        checked={payment.isDeposit}
                        onChange={this.handleCheckbox('isDeposit')}
                        disabled={payment.isDepositDisabled}
                      />
                    </div>
                  </div>
                  <div className={classNames(classes.flex)}>
                    <div className={classNames(classes.oneThirdWidth)}>
                      <TextField
                        label="Check Number"
                        value={payment.checkNumber}
                        onFieldChange={this.handleFieldChange('checkNumber')}
                      />
                    </div>
                    <div className={classNames(classes.oneThirdWidth)}>
                      <TextField
                        label="Account Code"
                        value={payment.accountCode}
                        onFieldChange={this.handleFieldChange('accountCode')}
                      />
                    </div>
                    <div className={classNames(classes.oneThirdWidth)}>
                      <TextField
                        label="Revenue Code"
                        value={payment.revenueCode}
                        onFieldChange={this.handleFieldChange('revenueCode')}
                      />
                    </div>
                  </div>
                  <div className={classNames(classes.flex)}>
                    <div className={classNames(classes.twoThirdWidth)}>
                      <TextField
                        label="Comment"
                        value={payment.comment}
                        onFieldChange={this.handleFieldChange('comment')}
                      />
                    </div>
                    <div className={classNames(classes.oneThirdWidth)}>
                      <Select
                        label="Category"
                        value={getPaymentCategoryKeys(payment.category)}
                        onFieldChange={this.handleFieldChange('category')}
                      >
                        <MenuItem value="initialDeposit">Initial Deposit</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
        <ValidationBanner
          innerRef={this.validationBanner}
        />
      </Modal>
    );
  }
}

const AddEditPaymentModalContainer = props => {
  const {
    onBookingChanged,
  } = useContext(BookingContext);

  return (<AddEditPaymentModal
    {...props}
    onBookingChanged={onBookingChanged}
  />);
};

export default withStyles(styles)(AddEditPaymentModalContainer);
