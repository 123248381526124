import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CardMedia } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import * as imagePlaceholder from 'portal/admin/itemManagement/default.png';
import { matcher as match } from 'Components/QuickAdd/match';
import parse from 'autosuggest-highlight/parse';

const styles = theme => ({
  container: {
    height: 167,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderTop: `3px solid ${theme.palette.grey[500]}`,
    boxShadow: `0 2px 3px 0 ${fade(theme.palette.grey[300], 0.5)}`,
    marginTop: 3,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
  },
  resultsList: {
    width: 123,
    borderRight: `1px solid ${theme.palette.grey[50]}`,
  },
  imageSection: {
    width: 245,
    paddingLeft: 10,
  },
  descriptionSection: {
    width: 268,
    paddingLeft: 10,
    paddingRight: 10,
  },
  resultItem: {
    height: 31,
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  activeResult: {
    backgroundColor: fade(theme.palette.secondary.dark, 0.05),
    cursor: 'pointer',
  },
  textContainer: {
    flexGrow: 1,
    paddingTop: 3,
    paddingLeft: 12,
    paddingBottom: 3,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sectionHeaderText: {
    color: theme.palette.primary.main,
    height: 30,
    display: 'flex',
    alignItems: 'center',
  },
  imagePreview: {
    height: 124,
    width: 235,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  matchedText: {
    fontWeight: 1000,
  },
});

class QuickAddResults extends Component {
  state = {};

  renderHiglightedName(result) {
    const matches = this.matcher(result.name);
    const parts = parse(result.name, matches);

    return parts.map((part, index) =>
      part.highlight ? (
        <span key={String(index)} className={this.props.classes.matchedText}>
          {part.text}
        </span>
      ) : (
        <span key={String(index)}>
          {part.text}
        </span>
      ),
    );
  }

  onPreviewChange = index => () => {
    this.props.onPreviewChange(index);
  }

  onEnterPressed = event => {
    if (event.key === 'Enter') {
      this.sendSelectedItem(this.getActiveResult());
    }
  }

  sendSelectedItem = item => {
    this.props.onItemSelected(item);
  }

  menuItemClicked = item => () => {
    this.sendSelectedItem(item);
  }

  getActiveResult = () => {
    const {
      results,
      selectedIndex,
    } = this.props;

    if (results && results.length) {
      return results[selectedIndex];
    }

    return null;
  }

  render() {
    const {
      classes,
      results,
    } = this.props;

    if (!results || !results.length) {
      return null;
    }
    const activeResult = this.getActiveResult();

    if (this.searchText !== this.props.searchText) {
      this.matcher = match(this.props.searchText); //rebuild the matcher only when searchText changes
      this.searchText = this.props.searchText;
    }
    const tabindex = 2;

    return (
      <div className={classes.container} onKeyPress={this.onEnterPressed}>
        <div className={classes.resultsList}>
          {results.map((result, index) => (
            <div
              tabIndex={index + tabindex}
              key={result.id}
              className={classNames(classes.resultItem)}
              onMouseOver={this.onPreviewChange(index)}
              onFocus={this.onPreviewChange(index)}
              onClick={this.menuItemClicked(result)}
            >
              <div className={classNames(classes.textContainer, activeResult === result && classes.activeResult)}>
                {this.renderHiglightedName(result)}
              </div>
            </div>
          ))}
        </div>
        {activeResult && <>
          <div className={classes.imageSection}>
            <div className={classes.sectionHeaderText}>Image</div>
            <div className={classes.imagePreview}>
              <CardMedia
                component="img"
                image={activeResult.imageUrl || imagePlaceholder}
                title={activeResult.name}
                alt={activeResult.name}
                height="124"
              />
            </div>
          </div>
          <div className={classes.descriptionSection}>
            <div className={classes.sectionHeaderText}>Description</div>
            <div dangerouslySetInnerHTML={{ __html: activeResult.description }} />
          </div>
        </>}
      </div>
    );

  }
}

export default withStyles(styles)(QuickAddResults);
