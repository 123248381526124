import {
  WORKING_ADDRESS_SET_INIT,
  WORKING_ADDRESS_SET_UPDATE,
  WORKING_ADDRESS_SET_ADD_ADDRESS,
  WORKING_ADDRESS_SET_SELECT_ADDRESS,
  WORKING_ADDRESS_SET_RESET,
} from 'actions/constants';

const initialState = {
  workingAddresses: [{ id: -1 }],
  workingAddressesRef: [{ id: -1 }],
  nextIdPlaceholder: -2,
  selectedAddressId: null,
};

export function address(state = initialState, action) {
  switch (action.type) {

    case WORKING_ADDRESS_SET_INIT: {
      const { workingAddresses, workingAddressesRef } = action;

      const newState = {
        ...state,
        workingAddresses,
        workingAddressesRef,
        nextIdPlaceholder: -1,
      };

      return newState;
    }

    case WORKING_ADDRESS_SET_RESET: {
      return initialState;
    }

    case WORKING_ADDRESS_SET_UPDATE: {
      const { workingAddresses } = action;

      const newState = {
        ...state,
        workingAddresses,
      };

      return newState;
    }

    case WORKING_ADDRESS_SET_ADD_ADDRESS: {
      const { nextIdPlaceholder } = state;

      const newState = {
        ...state,
        workingAddresses: [
          ...state.workingAddresses,
          { id: nextIdPlaceholder },
        ],
        selectedAddressId: nextIdPlaceholder,
        nextIdPlaceholder: nextIdPlaceholder - 1,
      };

      return newState;
    }

    case WORKING_ADDRESS_SET_SELECT_ADDRESS: {
      const { selectedAddressId } = action;

      const newState = {
        ...state,
        selectedAddressId,
      };

      return newState;
    }

    default:
      return state;
  }
}
