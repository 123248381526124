import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from 'Components/Buttons/IconButton';
import { connect } from 'react-redux';
import { Add as AddIcon } from '@material-ui/icons';
import { getProposals } from 'actions/proposal';
import ProposalGrid from './ProposalGrid';
import BookingWizard from 'bookings/BookingWizard/BookingWizard';
import PropTypes from 'prop-types';
import moment from 'moment';

const styles = theme => ({
  proposalSection: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  sectionLabel: {
    fontSize: 17,
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[50],
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionContainer: {
    paddingBottom: 18,
  },
  gridWrapper: {
    paddingTop: 16,
  },
});

class ProposalSection extends Component {
  state = {
    proposals: [],
    isWizardOpen: false,
    selectedProposalId: null,
  };

  componentDidMount() {
    this.getProposals();
  }

  getProposals = () => {
    const { entityType, linkedEntityId } = this.props;

    this.props.getProposals(entityType, linkedEntityId).then(proposals => {
      this.setState({ proposals });
    });
  }

  openProposalWizard = selectedProposalId => {
    let proposalId = null;

    // block synthetic event from hijacking selectedProposalId
    if (!isNaN(selectedProposalId)) proposalId = selectedProposalId;

    this.setState({ isWizardOpen: true, selectedProposalId: proposalId });
  }

  closeProposalWizard = () => {
    this.setState({ isWizardOpen: false, selectedProposalId: null });
    this.getProposals();
  }

  render() {
    const { classes, linkedEntityId, entityType } = this.props;
    const now = moment(new Date());

    const { proposals, isWizardOpen, selectedProposalId } = this.state;
    const expiredProposals = proposals.filter(proposal => moment(proposal.startDateTime) < now);
    const activeProposals = proposals.filter(proposal => moment(proposal.startDateTime) >= now);

    return (
      <div className={classes.proposalSection}>
        <div className={classes.sectionContainer}>
          <div className={classes.sectionLabel}>
            <div>Proposals</div>
            <div>
              <IconButton onClick={this.openProposalWizard} height={31} icon={AddIcon} />
            </div>
          </div>
          <div className={classes.gridWrapper}>
            <ProposalGrid proposals={activeProposals} onNameClick={this.openProposalWizard} />
          </div>
        </div>

        <div className={classes.sectionContainer}>
          <div className={classes.sectionLabel}>
            <div>Expired Proposals</div>
          </div>
          <div className={classes.gridWrapper}>
            <ProposalGrid proposals={expiredProposals} onNameClick={this.openProposalWizard} />
          </div>
        </div>
        {isWizardOpen &&
          <BookingWizard
            proposal={true}
            entityType={entityType}
            entityId={linkedEntityId}
            onClose={this.closeProposalWizard}
            selectedProposalId={selectedProposalId}
          />
        }
      </div>
    );
  }
}

ProposalSection.propTypes = {
  entityType: PropTypes.string.isRequired,
  linkedEntityId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  getProposals,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(ProposalSection));
