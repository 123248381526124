import React, { Component } from 'react';
import { Paper, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Table from 'Components/Table';
import LineGraphWidget from 'Components/GraphWidgets/LineGraphWidget';

const styles = theme => ({
  card: {
    flexGrow: 1,
    marginTop: 30,
  },
  cardheading: {
    fontSize: 20,
    fontWeight: 'normal',
    padding: 15,
    paddingBottom: 30,
  },
  padLeft: {
    paddingLeft: 30,
  },
  headingRow: {
    height: 44,
    color: theme.palette.grey['200'],
    fontSize: 12,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  dataRow: {
    height: 40,
  },

});

const lineData = [
  11, 5, 7, 13, 10, 3, 6, 0, 5, 7, 6, 11,
];

class TopSellingItemWidget extends Component {

  render() {
    const {
      classes,
      theme,
      className,
      itemsOrdered,
    } = this.props;

    return (
      <Paper className={classNames(classes.card, className)}>
        <div className={classes.cardheading}>Top Selling Items</div>

        <Table
          header={<tr className={classes.headingRow}>
            <th className={classes.padLeft}>Item Name</th>
            <th># of Sales </th>
            <th>Value </th>
            <th>trend </th>
            <th></th>
          </tr>
          }>
          {  itemsOrdered.slice(0,5).map(item =>
            <tr className={classes.dataRow}>
              <td className={classes.padLeft}>{item.name}</td>
              <td>{item.count}</td>
              <td>${item.price.toFixed(2)}</td>
              <td style={{ color: theme.common.green }}>+25%</td>
              <td>
                <div style={{ maxHeight: 70 }}>
                  <LineGraphWidget
                    height={30}
                    width={98}
                    data={lineData}
                    graphcolor={theme.common.purple}
                  />
                </div>
              </td>
            </tr>
          )}
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles,{ withTheme: true })(TopSellingItemWidget);
