import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  getMenuSettings,
  menuSettingsSave,
} from 'actions/settings';
import SaveBar from 'Components/SaveBar';
import SectionHeader from 'Components/SectionHeader';
import TextField from 'Components/TextField';

const styles = () => ({
  header: {
    marginTop: 16,
  },
  menuSettings: {
    display: 'flex',
    padding: 16,
    flex: 1,
  },
  column: {
    width: 258,
  },
  row: {
    flexDirection: 'column',
    display: 'flex',
  },
});

const MenuSettings = ({
  classes,
  getMenuSettings,
  menuSettingsSave,
  menuSettings,
}) => {
  const [settingsEdit, setSettingsEdit] = useState(menuSettings);
  const [menuSettingsChanged, setIsChanged] = useState(false);

  useEffect(() => {
    getMenuSettingsApi();
  }, []);

  useEffect(() => {
    setSettingsEdit(menuSettings);
  }, [menuSettings]);

  useEffect(() => {
    updateIsChanged();
  });

  async function getMenuSettingsApi() {
    await getMenuSettings();
  }

  const updateIsChanged = () => {
    const isChanged = menuSettings && Object.keys(menuSettings).some(key => settingsEdit[key] !== menuSettings[key]);

    setIsChanged(isChanged);
  };

  const handleDurationChange = fieldName => value => {
    if (value) {
      value = parseInt(value);
    }
    const updatedSettings = {
      ...settingsEdit,
      [fieldName]: value < 0 ? 0 : value * 60,
    };

    setSettingsEdit(updatedSettings);
  };

  const menuSettingsReset = () => {
    setSettingsEdit(menuSettings);
  };

  const save = () => {
    menuSettingsSave(settingsEdit).then(() => {
      setSettingsEdit(menuSettings);
      updateIsChanged();
    });
  };

  return (<>
      <SectionHeader className={classes.header}>
        Menu Settings
      </SectionHeader>
      <div className={classes.menuSettings}>
        <div className={classes.column}>
          <div className={classes.row}>
            <TextField
              label="Box Buffer Time"
              value={settingsEdit.boxBufferDurationInSeconds / 60}
              minValue={0}
              onFieldChange={handleDurationChange('boxBufferDurationInSeconds')}
              type="number"
              endAdornment={<InputAdornment position="end">minutes</InputAdornment>}
            />
            <TextField
              label="Queue Buffer Time"
              value={settingsEdit.queueBufferDurationInSeconds / 60}
              minValue={0}
              onFieldChange={handleDurationChange('queueBufferDurationInSeconds')}
              type="number"
              endAdornment={<InputAdornment position="end">minutes</InputAdornment>}
            />
          </div>
        </div>
      </div>
        <div className={classes.saveBar}>
          {(menuSettingsChanged || !menuSettings.id) &&
            <SaveBar
              onSave={save}
              onCancel={menuSettingsReset}
              isSticky={true}
            />
          }
        </div>
    </>);
};

const mapStateToProps = state => {
  const {
    settings: {
      menuSettings,
    },
  } = state;

  return {
    menuSettings,
  };
};

const mapDispatchToProps = {
  getMenuSettings,
  menuSettingsSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuSettings));

