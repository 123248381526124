import React from 'react';

const WizardContext = React.createContext(
  {
    generalInfo: {}, //used when going back to the generalSettingsTab
    generalInfoErrors: undefined,
    focusedEventId: 0,
    events: [],
    eventsChanged: ({ _events }) => { },
    setFocusedEventId: _event => { },
    quickAddEvent: () => { },
    onDeleteEvent: _eventId => { },
    onUpdateEvent: _event => { },
    onCloneEvent: _event => { },
  }
);

export default WizardContext;
