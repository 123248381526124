import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// import classNames from 'classnames';
import _ from 'lodash';
import { arrayMove } from 'react-sortable-hoc';
import AdminTaxRatesTable from './AdminTaxRatesTable';
import {
  getTaxSchedule,
  updateTaxSchedule,
  createTaxSchedule,
} from 'actions/taxSchedules';
// import { Add } from '@material-ui/icons';
// import HighlightButton from 'Components/Buttons/HighlightButton';
// import RedBorderButton from 'Components/Buttons/RedBorderButton';
import SimpleDialog from 'Components/SimpleDialog';
import CheckBox from 'Components/Checkbox';
import TextField from 'Components/TextField';
import SaveBar from 'Components/SaveBar';
import {
  Radio,
  FormControlLabel,
} from '@material-ui/core';

const styles = () => ({
  taxSchedule: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flex: 1,
  },
  name: {
    display: 'flex',
    width: '65%',
  },
  lower: {
    display: 'flex',
    padding: '24px 16px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  topButtons: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    margin: 8,
  },
  addButton: {
    marginTop: 32,
  },
  primary: {
    marginLeft: -10,
  },
});

const TaxSchedule = ({ classes, getTaxSchedule, updateTaxSchedule, createTaxSchedule, financialConfigId }) => {
  const [ financialConfig, setFinancialConfig ] = useState({});
  const [ financialConfigRef, setFinancialConfigRef ] = useState({});
  const [ idPlaceholder ] = useState(-1);
  // const [ idPlaceholder, setIdPlaceholder ] = useState(-1);
  const [ hasErrors, setHasErrors ] = useState([]);
  const [ activeId ] = useState(idPlaceholder);
  // const [ activeId, setAutoFocus ] = useState(idPlaceholder);
  const confirmDelete = React.createRef();

  useEffect(() => {
    getSchedule();
  }, [financialConfigId]);

  async function getSchedule() {
    const financialConfig = await getTaxSchedule(financialConfigId);

    setFinancialConfig(financialConfig);
    setFinancialConfigRef(_.cloneDeep({ ...financialConfig }));
  }

  async function getupdatedSchedule(id) {
    const financialConfig = await getTaxSchedule(id);

    setFinancialConfig(financialConfig);
    setFinancialConfigRef(_.cloneDeep({ ...financialConfig }));
  }

  const handleChange = fieldName => value =>  {
    const newFinancialConfig = {
      ...financialConfig,
      [fieldName]: value,
    };

    setFinancialConfig(newFinancialConfig);
  };

  const handleTaxNameChange = (revenueTypeId, value) =>  {
    const newTaxConfigTypes = [ ...financialConfig.revenueTypeRates ];
    const editedItem = newTaxConfigTypes.find(t => t.revenueTypeId === revenueTypeId);

    editedItem['revenueType'].name = value;

    const newFinancialConfig = {
      ...financialConfig,
      revenueTypeRates: newTaxConfigTypes,
    };

    setFinancialConfig(newFinancialConfig);
  };

  const handleLineItemChange = (revenueTypeId, fieldName) => value =>  {
    const copyRevenueTypeRates = [ ...financialConfig.revenueTypeRates ];
    const editedItem = copyRevenueTypeRates.find(t => t.revenueTypeId === revenueTypeId);

    editedItem[fieldName] = parseFloat(value) / 100;
    const newFinancialConfig = {
      ...financialConfig,
      revenueTypeRates: copyRevenueTypeRates,
    };

    setFinancialConfig(newFinancialConfig);
    //  const newRevenueTypeRates = [];

    //     copyRevenueTypeRates.forEach(config  => {
    //       const  { id, revenueType, ...revenueTypeRate } = config;

    //       newRevenueTypeRates.push(revenueTypeRate);
    //     });
    //     const { id, ...newFinancialConfig } = financialConfig;

    //     newFinancialConfig.revenueTypeRates = newRevenueTypeRates;
    // debugger;
    //     createTaxSchedule(newFinancialConfig).then(config => {
    //       financialConfig.default = false;
    //       updateTaxSchedule(financialConfig).then(getupdatedSchedule(config.id));
    //     });
  };

  const copyRevenueRateConfig = revenueRateConfig => {
    {
      const { id , ...restRevenvueType } = revenueRateConfig.revenueType;

      revenueRateConfig.revenueType = restRevenvueType;
    }
  };

  // const addRevenueType = () => {
  //   const newFinancialConfig = {
  //     ...financialConfig,
  //     revenueTypeRates: [
  //       ...financialConfig.revenueTypeRates,
  //       {
  //         revenueConfigurationId: 0,
  //         taxPercentage: 0,
  //         serviceChargePercentage: 0,
  //         gratuityPercentage: 0,
  //         revenueType: {
  //           createdBy: "warren@puffnstuff.com",
  //           name: "",
  //           recordStatus: "Active",
  //         },
  //         recordStatus: "Active",
  //         sortOrder: 0,
  //       },
  //     ],
  //   };

  //   setFinancialConfig(newFinancialConfig);
  //   setIdPlaceholder(idPlaceholder - 1);
  //   setAutoFocus(idPlaceholder);
  // };

  const setErrors = (isError, rateId) => {

    if (isError) {
      const newErrors = [ ...hasErrors ];

      newErrors.push(rateId);
      setHasErrors(newErrors);

    } else {
      const newErrors = hasErrors.filter(item => item !== rateId);

      setHasErrors(newErrors);
    }
    console.log(hasErrors);
  };

  const handleRadioButton = fieldName => event =>  {
    const isManual = event.target.value === 'manual';

    handleChange(fieldName)(isManual);
  };

  const handleSave = () =>  {
    updateTaxSchedule(financialConfig).then(getSchedule);
  };

  const handleCancel = () =>  {
    getSchedule();
  };

  const handleItemDelete = revenueTypeId =>  {
    confirmDelete.current.open('Are you sure you want to delete this revenue type?').then(() => {
      const indexOfItem = financialConfig.revenueTypeRates.findIndex(t => t.revenueTypeId === revenueTypeId);
      const deletedRevenueConfigTypes = [...financialConfig.revenueTypeRates];

      deletedRevenueConfigTypes.splice(indexOfItem, 1);

      const newRevenueRates = [];

      deletedRevenueConfigTypes.forEach(config  => {
        const  { id, revenueTypeId,  ...rest } = config;

        copyRevenueRateConfig(rest);

        newRevenueRates.push(rest);
      });

      const { id, ...newConfig } = financialConfig;

      newConfig.revenueTypeRates = newRevenueRates;

      let newId = 0;

      createTaxSchedule(newConfig).then(config => {
        newId = config.id;
        financialConfig.default = false;
        updateTaxSchedule(financialConfig).then(getupdatedSchedule(newId));
      });
    });
  };

  const onSwapOrder = (oldIndex, newIndex) => {
    const originals = [...financialConfig.revenueTypeRates];
    const resortedTaxConfigTypes = arrayMove(originals, oldIndex, newIndex);

    const resortedFinancialConfig = {
      ...financialConfig,
      revenueTypeRates: resortedTaxConfigTypes,
    };

    setFinancialConfig(resortedFinancialConfig);
  };

  const displayErrors = () => {
    if (hasErrors.length) {
      return 'There are errors in your percentages';
    } else {
      return '';
    }
  };

  const isFormChanged = JSON.stringify(financialConfig) !== JSON.stringify(financialConfigRef);

  return financialConfig && (
    <div className={classes.financialConfig}>
      <div className={classes.main}>
        <div className={classes.content}>
          <div className={classes.name}>
            <TextField
              label="Schedule Name"
              value={financialConfig.name}
              onFieldChange={handleChange('name')}
            />
          </div>
          <div className={classes.lower}>
            <div className={classes.radios}>
              <div className={classes.radioWrap}>
                <FormControlLabel
                  label="Use Manual Taxes"
                  checked={true}
                  // checked={financialConfig.isManual} TODO: not yet implemented
                  // onChange={handleRadioButton('isManual')}
                  value="manual"
                  control={<Radio disableRipple />}
                />
              </div>
              <div className={classes.radioWrap}>
                <FormControlLabel
                  label="Use Automatic Taxes"
                  checked={!financialConfig.isManual}
                  onChange={handleRadioButton('isManual')}
                  value="automatic"
                  control={<Radio disableRipple />}
                  disabled={true} // TODO: not yet implemented
                />
              </div>
              <div className={classes.radioWrap}>
                <CheckBox
                  label="Primary"
                  checked={financialConfig.isPrimary}
                  onFieldChange={handleChange('isPrimary')}
                  className={classes.primary}
                />
              </div>
            </div>
            <div className={classes.tableContainer}>
              <AdminTaxRatesTable
                onNameChange={handleTaxNameChange}
                onPercentageFieldChange={handleLineItemChange}
                revenueTypeRates={financialConfig.revenueTypeRates}
                activeId={activeId}
                onItemDelete={handleItemDelete}
                onSwapOrder={onSwapOrder}
                setErrors={setErrors}
              />
            </div>
          </div>
        </div>

        {/* <div className={classes.buttons}>
          <div className={classes.topButtons}>
            <div className={classes.button}>
              <HighlightButton
              // onClick={this.openApplyToLocation}
              >
                Add New Schedule
              </HighlightButton>
            </div>
            <div className={classes.button}>
              <RedBorderButton
                // onClick={this.deleteSchedule} TODO
              >
                Delete Schedule
              </RedBorderButton>
            </div>
          </div>
          <div className={classNames(classes.button, classes.addButton)}>
            <HighlightButton
              onClick={addRevenueType}
              disabled={!financialConfig}
              aria-label="Add New Revenue Type"
            >
              <Add />
            </HighlightButton>
          </div>
        </div> */}
      </div>
      {displayErrors()}
      {isFormChanged && <SaveBar isSaveDisabled={hasErrors.length} onSave={handleSave} onCancel={handleCancel} />}
      <SimpleDialog innerRef={confirmDelete} />
    </div>
  );
};

const mapDispatchToProps = {
  getTaxSchedule,
  updateTaxSchedule,
  createTaxSchedule,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(TaxSchedule));
