import React, { Component } from 'react';
import { Card, withStyles } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom';
import Scheduler from 'calendar/Scheduler';
import CALENDAR_MODE from 'constants/calendarMode';

const styles = theme => ({
  root: {
    width: '390px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: 74,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    paddingLeft: 30,
  },
  linkToCalendar: {
    paddingRight: 25,
    fontSize: 14,
    '& a': {
      color: theme.palette.grey['200'],
    },
  },
});

class DayAtAGlanceWidget extends Component {

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root}>
        <div className={classes.header}>
          <div className={classes.title}>
            Day At A Glance
          </div>
          <div className={classes.linkToCalendar}>
            <Link to="/calendar">
              Calendar
              <ArrowRight />
            </Link>
          </div>
        </div>
        <Scheduler
          theme={this.props.theme}
          currentMode={CALENDAR_MODE.Day}
          dayAtGlance={true}
        />
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DayAtAGlanceWidget);
