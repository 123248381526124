import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from 'Components/Table';
import {
  SortableContainer,
  arrayMove,
} from 'react-sortable-hoc';
import StatusRow from './StatusRow';

const SortableList = SortableContainer(({ statusGroup, onEdit, onDelete }) => {
  return (
    <tbody>
      {statusGroup.statuses.map((item, index) =>
        <StatusRow
          key={`item-${item.id}`}
          index={index}
          type={statusGroup.type}
          item={{ ...item, index }}
          onItemEdit={onEdit}
          onItemDelete={onDelete}
        />
      )}
    </tbody>
  );
});

const styles = theme => ({
  table: {
    borderColor: theme.palette.grey[50],
    '& th': {
      textAlign: 'center',
    },
    '& td': {
      borderWidth: 1,
      textAlign: 'center',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
    '& tbody th': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
  },
  headerRow: {
    height: '30px',
  },
});
const smallColumn = { width: 60 };

class StatusTable extends Component {
  state = {};

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onSort(arrayMove(this.props.statusGroup.statuses, oldIndex, newIndex));
  };

  render() {
    const {
      classes,
      statusGroup,
      onItemEdit,
      onItemDelete,
    } = this.props;

    return (
      <Table
        className={classes.table}
        wrapWithTbody={false}
        header={
          <tr className={classes.headerRow}>
            <th scope="col" style={smallColumn}>Order</th>
            <th scope="col">Name</th>
            <th scope="col">System Status</th>
          </tr>
        }
      >
        {statusGroup && <SortableList
          statusGroup={statusGroup}
          onEdit={onItemEdit}
          onDelete={onItemDelete}
          onSortEnd={this.onSortEnd}
          lockAxis="y"
          useDragHandle={true}
        />}

      </Table >);
  }
}

export default withStyles(styles)(StatusTable);

