import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import copy from 'clipboard-copy';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import TextField from 'Components/TextField';
import {
  share,
} from 'actions/booking';
import {
  FileCopy,
  CheckCircleOutline,
} from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';

const styles = theme => ({
  container: {
    padding: '4px 26px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  table: {
    fontSize: 12,
    '& th': {
      padding: '6px 13px',
    },
    '& tr': {
      borderTop: 0,
      borderBottom: 0,
    },
    '& td': {
      border: `1px solid ${theme.palette.grey[50]}`,
      paddingLeft: 8,
    },
  },
  noTopBorder: {
    borderTopWidth: '0px !important',
  },
  rowLabel: {
    color: theme.palette.grey[300],
    paddingTop: 11,
    paddingBottom: 11,
  },
  sectionLabel: {
    height: 30,
    display: 'flex',
    paddingLeft: 13,
    marginTop: 13,
    alignItems: 'center',
  },
  moveDown: {
    marginTop: 13,
    marginRight: 0,
  },
  exemptContents: {
    display: 'flex',
  },
  leftColumn: {
    minWidth: 126,
  },
  rightColumn: {
    flexGrow: 1,
  },
  linkContainer: {
    display: 'flex',
    height: 152,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  success: {
    backgroundColor: theme.palette.success,
    color: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.success,
    },
  },
  accent: {
    color: theme.palette.action.active,
  },
  link: {
    maxWidth: 373,
    wordBreak: 'break-all',
    overflowX: 'auto',
    color: theme.palette.action.active,
  },
  emailMessage: {
    width: 323,
    textAlign: 'center',
  },
});

const dimensions = {
  width: 425,
  height: 285,
};

class Share extends Component {
  state = {
    share: {},
    error: {},
    copied: false,
  };

  componentDidUpdate(prevProps) {
    const {
      isOpened,
    } = this.props;

    if (isOpened && !prevProps.isOpened) {
      this.setState({ share: {}, error: {} });
    }
  }

  onSave = () => {
    const {
      share,
      bookingId,
    } = this.props;

    const shareModel = this.state.share;

    if (shareModel.name && shareModel.email) {
      share(bookingId, shareModel).then(link => {
        this.setState({ link });
      });

      return;
    }

    const error = {
      email: !shareModel.email,
      name: !shareModel.name,
    };

    this.setState({ error });

  }

  copyToClickBoard = () => {
    copy(this.state.link);
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  };

  onCancel = () => {
    this.setState({ link: null });
    this.props.onClose();
  }

  onFieldChange = fieldName => value => {
    const share = {
      ...this.state.share,
      [fieldName]: value,
    };

    this.setState({ share });
  }

  inputs = () => {
    const {
      share,
      error,
    } = this.state;

    return (<>
      <TextField
        label="Customer Name"
        value={share.name}
        error={error.name}
        onFieldChange={this.onFieldChange('name')}
      />
      <TextField
        label="Customer Email"
        value={share.email}
        error={error.email}
        onFieldChange={this.onFieldChange('email')}
      />
    </>);
  }

  copyLink = () => {
    const {
      link,
      copied,
      share,
    } = this.state;

    const {
      classes,
    } = this.props;

    const Icon = copied ? CheckCircleOutline : FileCopy;

    return (
      <div className={classes.linkContainer}>
        <div className={classes.emailMessage}>
          Email has been sent to <span className={classes.accent}>{share.email}</span>
        </div>
        <div className={classes.link}>
          {link}
        </div>
        <Tooltip title="Copied" open={copied} placement="top">
          <HighlightButton
            className={copied && classes.success}
            onClick={this.copyToClickBoard}
          >
            <Icon />
            Copy to Clipboard
          </HighlightButton>
        </Tooltip>
      </div>
    );
  }

  render() {
    const {
      isOpened,
      classes,
    } = this.props;

    const {
      link,
    } = this.state;

    return (<Modal
      isOpened={isOpened}
      dimensions={dimensions}
      onCancel={this.onCancel}
      onSave={link ? this.onCancel : this.onSave}
      title="Share Booking with Customer"
      addTitleBottomBorder={true}
      saveText={link ? 'Okay' : 'Share'}
    >
      <div className={classes.container}>
        {link ? this.copyLink() : this.inputs()}
      </div>
    </Modal >
    );
  }
}
const mapDispatchToProps = {
  share,
};

const styledShare = withStyles(styles)(Share);

export { styledShare as Share };

export default connect(undefined, mapDispatchToProps)(styledShare);
