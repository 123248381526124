import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
  highlight: {
    background: theme.palette.error.light,
  },
});

class HighlightIfEdited extends Component {
  render() {
    const { children, classes, className, isChanged } = this.props;

    return (
      <div className={classNames(className, isChanged && classes.highlight)}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(HighlightIfEdited);
