import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  Modal as MaterialModal,
  Paper,
} from '@material-ui/core';
import GridIcon from '@material-ui/icons/GridOn';
import { List as DisplayListViewIcon } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { getCategories } from 'actions/item';
import {
  setStoreMenuItemsFromACategory,
  filterMenu,
} from 'actions/storemenu';

import CategoryNavigation from './wizard/StoreMenuSelection/CategoryNavigation';
import { addItemToStoreOrder } from 'actions/order';
import { connect } from 'react-redux';
import { defaultItemLibraryGrid } from 'constants/apiParamDefaults';
import StoreMenuImagePreview from './wizard/StoreMenuSelection/StoreMenuImagePreview';
import StoreMenuItemModifier from './wizard/ModifierModal/StoreMenuItemModifier';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import AlacarteModal from './alacarte';
import MenuItemFilterField from 'Components/Menu/MenuItemFilterField';
import MenuSelectionDropDown from './wizard/StoreMenuSelection/MenuSelectionDropDown';
import { updateStoreOrderItem } from 'actions/order';

const styles = theme => ({
  checkboxWidth: {
    width: '246px',
  },
  relative: {
    position: 'relative',
  },
  formSection: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%', //this allows for 2 columns of checkboxes - which matches mocks. Remove width to use the full width
  },
  formItemSection: {
    display: 'flex',
    flexFlow: 'wrap',
    marginLeft: 9,
    padding: '5px',
  },
  container: {
    display: 'flex',
    overflow: 'hidden',
    minHeight: '400px',
  },
  modifierContainer: {
    flex: '1',
    margin: '5px',
    maxHeight: '400px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  imageContainer: {
    flex: '0 0 300px',
    padding: '20px',
    width: '300px',
    borderRight: '1px solid #e6eaee',
  },
  quantitySection: {
    paddingTop: '10px',
  },
  priceLabel: {
    width: '100%',
    color: theme.palette.primary.dark,
    fontSize: 20,
    paddingLeft: 5,
    paddingTop: 10,
  },
  caloriesLabel: {
    width: '100%',
    fontSize: 20,
    paddingLeft: 5,
  },
  controlLabelRoot: {
    marginRight: theme.spacing.unit * 5,
  },
  multiSelectItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: 20,
  },
  radioSelectItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 15,
  },
  formControl: {
    minWidth: '65px',
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
  },
  descriptionSection: {
    padding: 5,
    fontSize: '15px',
    height: 'auto',
  },
  outlineInput: {
    padding: '5px',
    border: '1px solid #2189F8',
    borderRadius: 4,
    fontSize: '13px',
    '&:focus': {
      outline: 'none',
      border: '1px solid #2189F8',
    },
    width: '420px',
  },
  itemQtyContainer: {
    display: 'flex',
    border: '1px solid #2189F8',
    borderRadius: 4,
    padding: 4,
  },
  itemQtyLabel: {
    paddingRight: 2,
    textAlign: 'left',
    fontSize: '15px',
  },
  itemQtyValue: {
    width: '40px',
    textAlign: 'center',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    fontSize: '15px',
  },
  itemDetails: {
    display: 'flex',
    width: '260px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  itemButton: {
    padding: 4,
    display: 'flex',
    fontSize: '15px',
    color: '#2189F8',
    textTransform: 'none',
    minWidth: '30px',
  },
  radioSection: {
    width: '514px',
  },
  selectSection: {
    padding: 8,
  },
  selectedOptionButton: {
    border: '1px solid',
    borderRadius: '4px',
    fontSize: '13px',
    minHeight: '30px',
    padding: '0 13px',
    minWidth: 'unset',
    textTransform: 'initial',
    background: theme.palette.secondary.contrastText,
    borderColor: '#2189F8',
    height: '25px',
  },
  optionButton: {
    border: '1px solid',
    borderRadius: '4px',
    fontSize: '13px',
    minHeight: '32px',
    padding: '0 13px',
    minWidth: 'unset',
    textTransform: 'initial',
    background: theme.palette.secondary.dark,
    borderColor: theme.palette.grey[100],
    color: theme.palette.secondary.contrastText,
  },
  container2: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexGrow: 1,
    height: '480px',
    borderTop: '1px solid #D1DBE2',
    borderBottom: '1px solid #D1DBE2',
  },
  modal: {
    margin: '5% auto auto auto',
    width: 900,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  footer: {
    padding: '22px 25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  blue: {
    textTransform: 'initial',
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
  },
  centerButton: {
    fontSize: '13px',
    minHeight: '32px',
    minWidth: '129px',
  },
  titleHeader: {
    fontSize: 20,
    height: 60,
    color: theme.palette.grey[800],
    fontWeight: '400',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.grey[200],
  },
  alacarte: {
    background: 'white',
    position: 'absolute',
    textTransform: 'initial',
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
    right: '12px',
    margin: '4px',
  },
  tagsContainer: {
    '& tags': {
      fontSize: '16px',
      border: '0',
      margin: '0',
      flex: 1,
      width: '100%',
      height: '100%',
    },
    '& div': {
      width: '100%',
    },
    '&:hover': {
      paddingBottom: 0,
    },
    '& .searchIcon': {
      marginLeft: '15px',
      fill: '#bcbcbc',
      fontSize: '25px',
      width: 36,
      alignSelf: 'center',
    },
    '& .tagify__tag__removeBtn::after ': {
      width: 9,
      margin: 3,
    },
    display: 'flex',
    width: '100%',
    border: '0',
    flexDirection: 'row',
    marginTop: '3px',
    paddingBottom: 1,
  },
  categoryContainer: {
    minWidth: 290,
    maxWidth: 290,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 12,
    height: '480px',
    backgroundColor: 'white',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
    backgroundColor: theme.palette.grey[50],
    minHeight: 44,
    width: '100%',
  },
  menuSelection: {
    position: 'absolute',
    top: '-10px',
    left: '0px',
    fontSize: '15px',
  },
  gridContainer: {
    display: 'flex',
    flexGrow: 1,
  },
});

export class OrderManagerAddItemModal extends Component {
  constructor(props) {
    super(props);
    const initialSort = [{ field: 'name', dir: 'asc' }];

    this.state = {
      menuItems: props.storeMenuSelectedCategoryItems,
      selectedMenuItem: {},
      selectedCategory: {},
      isImagesOpened: props.isImagesOpenedFirst ? true : false,
      sort: initialSort,
      modifiersModalVisible: false,
      alacarteModalVisible: false,
      activeFilters: {
        ...defaultItemLibraryGrid,
        ...this.getFieldAndDirection(initialSort),
        includeImages: true,
        includeTagSummary: true,
        pageSize: null,
      },
      isDrawerOpen: false,
      modalOpen: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeMenuSelectedCategoryItems !== this.props.storeMenuSelectedCategoryItems) {
      this.setState({ menuItems: this.props.storeMenuSelectedCategoryItems });
    }
    if (prevProps.storeMenuCategories !== this.props.storeMenuCategories && this.props.storeMenuCategories && this.props.storeMenuCategories.length > 0 && !this.props.storeMenuCategories.some(category => category.id === this.state.selectedCategory.id)) {
      this.setState({ selectedCategory: this.props.storeMenuCategories[0] });
    }
  }

  componentDidMount() {
    this.setFilter([]);
    if ( !this.props.storeMenuSelectedCategoryItems ) {
      console.log("if this was not here, this blew up");
    }
  }

  toggleViews = () => { //TODO: temp
    this.setState(prevState => ({ isImagesOpened: !prevState.isImagesOpened }));
  }

  rightButtons = () => {
    const { isImagesOpened } = this.state;

    return (
      <div>
        <HighlightButton
          variant="left"
          minWidth={34}
          pressed={isImagesOpened}
          onClick={this.toggleViews}
        >
          <GridIcon />
        </HighlightButton>
        <HighlightButton
          variant="right"
          minWidth={34}
          pressed={!isImagesOpened}
          onClick={this.toggleViews}
        >
          <DisplayListViewIcon />
        </HighlightButton>
      </div>
    );
  }

  onItemSelected = itemSelected => {
    this.setState({
      selectedMenuItem: itemSelected,
      modifiersModalVisible: true,
    });
  }

  onCartButton = () => {
    const { isDrawerOpen } = this.state;

    this.setState({
      isDrawerOpen: !isDrawerOpen,
    });
  }

  // Need to keep track of selected items on front end, so filtered API results can persist selections
  updateSelectedItems = menuItemId => {
    const { selectedItemIds } = this.state;
    const indexOfId = selectedItemIds.indexOf(menuItemId);

    this.setState({ modifiersModalVisible: true });

    return indexOfId > -1 ?
      selectedItemIds.filter(id => id !== menuItemId) :
      [...selectedItemIds, menuItemId];
  }

  massageData = apiItems => {
    const { selectedItemIds } = this.state;

    return apiItems.map(menuItem => {
      const selected = selectedItemIds.indexOf(menuItem.id) !== -1;

      return {
        ...menuItem,
        quantity: this.props.defaultQuantity,
        selected,
      };
    });
  }

  categoryPicked = selectedCategory => {
    this.props.setStoreMenuItemsFromACategory(selectedCategory);
    this.setState({ selectedCategory });
  }

  setFilter = filter => {
    const { selectedCategory } = this.state;

    this.props.filterMenu(filter, selectedCategory);
  };

  onSave = () => {
    const { menuItems } = this.state;

    this.props.onSave(menuItems);
  }

  getFieldAndDirection = sort => {
    if (sort.length === 0) {
      return { sortField: '', sortDirection: '' };
    }

    const { field: sortField, dir: sortDirection } = sort[0];

    return { sortField, sortDirection };
  }

  onSortChange = sort => {
    this.setState({ sort });
  }

  onModifiersModalSave = data => {
    this.setState({
      modifiersModalVisible: false,
    });
  }

  getCartItemCount = () => {
    return this.props.storeOrder.orderItems.length;
  }

  getOrderId = () => {
    const orderId = this.props.storeOrder.id;

    return orderId ? orderId : "";
  }

  handleModalClose = () => {
    const { modalOpen } = this.state;

    this.props.onClose();
    this.setState({ modalOpen: !modalOpen });
  }

  updateSearchTerm = searchTerm => {
    const { selectedCategory } = this.state;

    this.props.updateMenuItemSearchTerm(searchTerm);
    const updateCategoryItems = this.props.setStoreMenuItemsFromACategory(selectedCategory);

    _.debounce(function () { updateCategoryItems(); }, 400);
    this.setState({ searchTerm });
  }

  onAlacarteModalSave = data => {
    this.setState({
      alacarteModalVisible: false,
    });
  }

  onOpenAlacarteModal = data => {
    this.setState({
      alacarteModalVisible: true,
    });
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      menuItems,
      selectedMenuItem,
      modifiersModalVisible,
      modalOpen,
      alacarteModalVisible,
    } = this.state;

    return (
      <MaterialModal
        open={modalOpen}
        onClose={this.handleModalClose}
      >
        <Paper className={classes.modal}>
          <div className={classes.relative}>
            <div className={classes.menuSelection}><MenuSelectionDropDown /></div>
            <div className={classes.titleHeader}>
              Select Order Items
            </div>
            <div className={classes.filterContainer}>
              <MenuItemFilterField
                className={classes.tagsContainer}
                onChange={this.setFilter}
              />
              <Button
                variant="outlined"
                aria-label="Add to Cart"
                onClick={this.onOpenAlacarteModal}
                className={classes.alacarte}
              >
                Open Item
              </Button>
            </div>
            <div className={classes.container2}>
              <div className={classes.categoryContainer}>
                <CategoryNavigation
                  setSelectedCategory={this.categoryPicked}
                />
              </div>
              <div className={classes.gridContainer}>
                <StoreMenuImagePreview
                  menuItems={menuItems}
                  onItemSelected={this.onItemSelected}
                />
              </div>
            </div>
            {modifiersModalVisible &&
              <StoreMenuItemModifier
                onModalClosed={this.onModifiersModalSave}
                updateStoreOrderItem={this.props.updateStoreOrderItem}
                menuItem={selectedMenuItem}
              />}
            {alacarteModalVisible &&
              <AlacarteModal
                onModalClosed={this.onAlacarteModalSave}
              />}
          </div>
          <div className={classes.footer}>
            <Button
              onClick={e => this.handleModalClose()}
              variant="outlined"
              className={classNames(classes.centerButton, classes.blue)}
            >
              Close
            </Button>
          </div>
        </Paper>
      </MaterialModal>
    );
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeOrder,
      storeMenuCategories,
      storeMenuSelectedCategoryItems,
      storeMenuRepresentedItemTags,
      storeMenuSelectedTags,
      searchTerm,
    },
  } = state;

  return {
    storeOrder,
    storeMenuCategories,
    storeMenuSelectedCategoryItems,
    storeMenuRepresentedItemTags,
    storeMenuSelectedTags,
    searchTerm,
  };
};

const mapDispatchToProps = {
  getCategories,
  addItemToStoreOrder,
  setStoreMenuItemsFromACategory,
  filterMenu,
  updateStoreOrderItem,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OrderManagerAddItemModal));

