import React, { Component } from 'react';
import { provideIntlService } from '@progress/kendo-react-intl';
import { Paper, withStyles } from '@material-ui/core';
import { TrendingUp, TrendingDown } from '@material-ui/icons';
import LineGraphWidget from './LineGraphWidget';

const styles = theme => ({
  card: {
    padding: 16,
    flexGrow: 1,
  },
  heading: {
    color: theme.palette.grey['200'],
    fontSize: 12,
    height: 18,
    fontWeight: 'normal',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
  number: {
    height: 50,
    width: '100%',
    fontSize: 36,
    textAlign: 'center',
  },
  percentUp: {
    width: '100%',
    textAlign: 'center',
    color: theme.common.green,
    height: 22,
  },
  percentDown: {
    width: '100%',
    textAlign: 'center',
    color: theme.common.red,
    height: 22,
  },
  graph: {
    height: 50,
    color: theme.common.green,
  },
});

class CardStyledGraphWidget extends Component {

  render() {
    const { dashboardEventsSettings, classes, heading, number, percent, graphdata, graphcolor, compare, compareColor, isCurrency } = this.props;
    const numberFormat = isCurrency ? 'c0' : 'n0';
    const formatter = provideIntlService(this);

    const amountValue = formatter.formatNumber(number, numberFormat);
    const percentValue = formatter.formatNumber(percent, 'p1');

    return (
      <Paper className={classes.card}>
        <div className={classes.heading}>{heading}</div>
        <div className={classes.number}>{amountValue}</div>
        {percent > 0 ?
          <div className={classes.percentUp}>{percentValue} <TrendingUp /></div>
          :
          <div className={classes.percentDown}>{percentValue}  <TrendingDown /> </div>
        }
        <div className={classes.graph}>
          <LineGraphWidget
            data={graphdata}
            settings={dashboardEventsSettings}
            graphcolor={graphcolor}
            compare={compare}
            compareColor={compareColor}
          />
        </div>

      </Paper>
    );
  }
}

export default withStyles(styles)(CardStyledGraphWidget);
