import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core';
import TextField from 'Components/TextField';
import ComboBoxAsync from 'Components/ComboBoxAsync';
import CoverDatePicker from 'Components/CoverDatePicker';
import QuickAccountModal from 'Components/QuickAccountModal';
import WizardContext from './WizardContext';
import TemplatePanel from './TemplatePanel';
import { searchAccounts } from 'actions/account';
import { updateStoreBooking } from 'actions/booking';
import { searchContacts } from 'actions/contact';
import { connect } from 'react-redux';

const styles = theme => {
  return {
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      alignContent: 'stretch',
      paddingTop: 18,
    },
    rows: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      alignContent: 'stretch',
    },
    row2Header: {
      paddingTop: 16,
    },
    row2: {
      borderTop: `2px solid ${theme.palette.grey['A200']}`,
      marginTop: 8,
    },
    mainPadding: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    form: {
      display: 'flex',
    },
    fieldRow: {
      display: 'flex',
    },
    leftFields: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '40%',
      marginLeft: -16,
    },
    rightFields: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '50%',
      marginRight: -16,
    },
    managerInput: {
      minWidth: 107,
    },
  };
};

class BookingWizardGeneral extends Component {
  state = {
    isNewAccountModalOpen: false,
  };

  componentDidMount() {
    this.restoreDetails();
  }

  // To handle a return to first step, we need to repopulate the local state
  restoreDetails = () => {
    const { storeBooking } = this.props;
    let { account, contact, siteContact } = this.state;

    if (storeBooking.account && !account) account = storeBooking.account;
    if (storeBooking.contact && !contact) contact = storeBooking.contact;
    if (storeBooking.siteContact && !siteContact) siteContact = storeBooking.siteContact;

    this.setState({ account, contact, siteContact });
  }

  onFieldChange = fieldName => value => {
    const { storeBooking } = this.props;

    const updatedStoreBooking = {
      ...storeBooking,
      [fieldName]: value,
    };

    this.props.updateStoreBooking(updatedStoreBooking);
  }

  onQuickAdd = fieldName => value => {
    if (fieldName === 'account') {
      this.setState({ isNewAccountModalOpen: true, newAccount: { name: value } });
    }
  }

  onNewAccountModalClose = selectedEntities => {
    if (selectedEntities) {
      this.updateFromQuickAdds(selectedEntities.account, selectedEntities.contact);
    }
    this.setState({ isNewAccountModalOpen: false });
  }

  updateFromQuickAdds = (account, contact) => {
    const updatedStoreBooking = {
      ...this.props.storeBooking,
    };

    if (account && account.id) {
      updatedStoreBooking.accountId = account.id;
      this.setState({ account });
    }
    if (contact && contact.id) {
      updatedStoreBooking.contactId = contact.id;
      updatedStoreBooking.siteContactId = contact.id;
      this.setState({
        contact: contact,
        siteContact: contact,
      });
    }

    this.props.updateStoreBooking(updatedStoreBooking);
  }

  onSelect = fieldName => selection => {
    return this.onFieldChange(fieldName)(selection && selection.id);
  }

  onAccountSelect = selectedAccount => {
    const { storeBooking } = this.props;

    if (!selectedAccount) { // clear account
      const updatedStoreBooking = {
        ...storeBooking,
        accountId: null,
      };

      this.props.updateStoreBooking(updatedStoreBooking);
      this.setState({ account: null });

      return;
    }

    if (!selectedAccount.__isNew__) {
      const updatedStoreBooking = {
        ...storeBooking,
        accountId: selectedAccount.id,
      };

      if (selectedAccount && selectedAccount.primaryContactId) {
        updatedStoreBooking.contactId = selectedAccount.primaryContactId;
        updatedStoreBooking.siteContactId = selectedAccount.primaryContactId;
      } else {
        updatedStoreBooking.contactId = null;
        updatedStoreBooking.siteContactId = null;
      }

      this.props.updateStoreBooking(updatedStoreBooking);
      this.setState({ account: selectedAccount });
    }
  }

  pickContact = fieldName => selectedContact => {
    const { storeBooking } = this.props;

    const whichContact = {
      'contactId': 'contact',
      'siteContactId': 'siteContact',
    }[fieldName];

    if (!selectedContact) { // clear contact
      const updatedStoreBooking = {
        ...storeBooking,
        accountId: null,
      };

      this.props.updateStoreBooking(updatedStoreBooking);
      this.setState({ [whichContact]: null });

      return;
    }

    if (!selectedContact.__isNew__) {
      const updatedStoreBooking = {
        ...storeBooking,
        [fieldName]: selectedContact.id,
      };

      this.props.updateStoreBooking(updatedStoreBooking);
      this.setState({ [whichContact]: selectedContact });
    }
  }

  render() {
    const {
      classes,
      generalInfoErrors = {},
      searchContacts,
      searchAccounts,
      storeBooking,
    } = this.props;
    const {
      isNewAccountModalOpen,
      newAccount,
      account,
      contact,
      siteContact,
    } = this.state;

    return (
      <div className={classes.mainPadding}>
        <div className={classes.form}>
          <div className={classes.leftFields}>
            <TextField
              label="Name of Booking"
              fieldName="name"
              error={generalInfoErrors.name}
              value={storeBooking.name}
              onFieldChange={this.onFieldChange('name')}
            />
            <div className={classes.fieldRow}>
              <TextField
                label="Event Manager"
                fieldName="eventManager"
                className={classes.managerInput}
                value={storeBooking.eventManager}
                onFieldChange={this.onFieldChange('eventManager')}
              />
              <CoverDatePicker
                label="Date Booked"
                fieldName="dateBooked"
                error={generalInfoErrors.dateBooked}
                value={storeBooking.dateBooked}
                onFieldChange={this.onFieldChange('dateBooked')}
              />
            </div>
          </div>
          <div className={classes.rightFields}>
            <ComboBoxAsync
              label="Account"
              value={account}
              getData={searchAccounts}
              autoLoadOptions={true}
              resultCount={5}
              onSelect={this.onAccountSelect}
              onCreateOption={this.onQuickAdd('account')}
              error={generalInfoErrors.account}
            />
            <div className={classes.fieldRow}>
              <ComboBoxAsync
                label="Contact"
                value={contact}
                getData={searchContacts}
                autoLoadOptions={true}
                resultCount={5}
                onSelect={this.pickContact('contactId')}
                // onCreateOption={this.onQuickAddContact}TODO
              />
              <ComboBoxAsync
                label="Site Contact"
                value={siteContact}
                getData={searchContacts}
                autoLoadOptions={true}
                resultCount={5}
                onSelect={this.pickContact('siteContactId')}
                // onCreateOption={this.onQuickAddContact}TODO
              />
            </div>
          </div>
        </div>
        <TemplatePanel />
        {isNewAccountModalOpen &&
          <QuickAccountModal
            onClose={this.onNewAccountModalClose}
            account={newAccount}
            isOpened={true}
          />}
      </div>
    );
  }
}

//Container to move context into props
const BookingWizardGeneralContainer = props => {
  const {
    generalInfoErrors,
    booking,
  } = useContext(WizardContext);

  return (<BookingWizardGeneral
    {...props}
    generalInfoErrors={generalInfoErrors}
    booking={booking}
  />);
};

const mapStateToProps = state => {
  const {
    booking: {
      wizard: {
        storeBooking,
      },
    },
  } = state;

  return {
    storeBooking,
  };
};

const mapDispatchToProps = {
  searchContacts,
  searchAccounts,
  updateStoreBooking,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BookingWizardGeneralContainer));
