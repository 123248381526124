import React, { Component } from 'react';
import OrderGrid from './OrderGrid';
import OrderWidgets from './OrderWidgets';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '3%',
    width: '100%',
  },
  mainContent: {
    display: 'flex',
    flex: '1 0 auto',
    padding: '24px 24px 0',
  },
});

class OrderList extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainContent}>
        <div className={classes.gridContainer}>
          <OrderGrid wizardOpen={false}/>
        </div>
        <OrderWidgets />
      </div>
    );
  }
}

export default withStyles(styles)(OrderList);
