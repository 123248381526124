import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  AppBar,
  Paper,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TaxAndServices from './TaxAndServices';
import Discounts from './Discounts';
import Share from './Share';
import PropTypes from 'prop-types';
import {
  ListAlt as BookingIcon,
  Forum as ChitChatIcon,
  Email as EmailIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as CloneIcon,
} from '@material-ui/icons';
import { provideIntlService } from '@progress/kendo-react-intl';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import HighlightButton from 'Components/Buttons/HighlightButton';
import HeaderButton from 'Components/Buttons/HeaderButton';
import MiniButtonSet from 'Components/Buttons/MiniButtonSet';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';
import SimpleDialog from 'Components/SimpleDialog';
import PaymentsModal from 'Components/PaymentsModal';
import DepositsModal from './DepositsModal';
import FinancialDetailsModal from './FinancialDetailsModal';
import BookingDetailsModal from './BookingDetailsModal';
import { ENTITY_TYPES } from 'constants/entityTypes';
import ReportPreviewModal from 'Components/ReportPreviewModal';
import Prints from './Prints';
import {
  getPayments,
  addPayment,
  updatePayment,
  deletePayment,
} from 'actions/booking';
import { getReportDetails } from 'actions/reports';
import { saveReportToFiles } from 'actions/file';

const styles = theme => ({
  appBarOverrides: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    fontSize: 16,
    height: 'unset',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 14px',
    backgroundColor: theme.palette.bookingEvent,
    color: theme.palette.common.white,
  },
  topLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  topRight: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 32,
    width: 32,
    marginRight: 16,
    borderRadius: 4,
  },
  bookingIcon: {
    color: theme.palette.common.white,
    fontSize: '48px',
  },
  rightIcon: {
    fontSize: '20px',
  },
  smallerFont: {
    fontSize: 17,
  },
  verticalAlign: {
    verticalAlign: 'middle',
  },
  bookingName: {
    fontSize: 22,
    lineHeight: 1,
  },
  widerButton: {
    padding: '0 12px',
  },
  bottomBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 32px 12px 16px',
    backgroundColor: theme.common.blueGrey,
  },
  glanceSection: {
    display: 'flex',
  },
  marginRight: {
    marginRight: 16,
  },
  glanceLabel: {
    color: theme.palette.grey[200],
    fontSize: 10,
  },
  glanceData: {
    color: theme.palette.grey[300],
    fontSize: 12,
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
  },
  link: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bookingValue: {
    cursor: 'pointer',
    margin: '-12px 16px',
    padding: '9px 40px 9px 16px',
    backgroundColor: theme.palette.secondary.main,
    '& $glanceLabel': {
      color: theme.palette.common.white,
      fontSize: 12,
    },
    '& $glanceData': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: '500',
    },
  },
});

class BookingHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIsPrintsModalOpen: false,
      areAllEventsOnSameDay: false,
      isFinancialDetailsModalOpen: false,
      isBookingDetailsModalOpen: false,
      taxesOpen: false,
      shareOpen: false,
      discountsOpen: false,
      isPaymentsModalOpen: false,
      isDepositsModalOpen: false,
      isReportPreviewOpen: false,
    };
    this.okDialog = React.createRef();
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeBooking !== this.props.storeBooking) {
      this.updateData();
    }
  }

  updateData = () => {
    this.setDisplayDates();

    this.setState({
      financialModal: false,
    });
  }

  openToolsMenu = event => {
    this.setState({ toolsOpenedBy: event.currentTarget });
  }

  closeToolsMenu = () => {
    this.setState({ toolsOpenedBy: null });
  }

  openTaxAndServices = () => {
    this.setState({ toolsOpenedBy: null, taxesOpen: true });
  }

  closeTaxAndServices = () => {
    this.setState({ taxesOpen: false });
  }

  openDiscounts = () => {
    this.setState({ toolsOpenedBy: null, discountsOpen: true });
  }

  closeDiscounts = () => {
    this.setState({ discountsOpen: false });
  }

  openShare = () => {
    this.setState({ toolsOpenedBy: null, shareOpen: true });
  }

  closeShare = () => {
    this.setState({ shareOpen: false });
  }

  goToContact = () => {
    this.props.history.push(`/contacts/${this.props.storeBooking.contactId}`);
  }

  goToAccount = () => {
    this.props.history.push(`/accounts/${this.props.storeBooking.accountId}`);
  }

  openFinancialsModal = () => {
    this.setState({ isFinancialDetailsModalOpen: true, toolsOpenedBy: null });
  }

  closeFinancialModal = () => {
    this.setState({ isFinancialDetailsModalOpen: false });
  }

  openPaymentsModal = () => {
    this.setState({ isPaymentsModalOpen: true, toolsOpenedBy: null });
  }

  openPrintsModal = () => {
    this.setState({ isIsPrintsModalOpen: true, toolsOpenedBy: null });
  }

  onGeneratePrint = print => {
    this.setState({ isIsPrintsModalOpen: false });
    if (print) {
      this.props.getReportDetails(print).then(reportPreview => {
        this.setState({ isReportPreviewOpen: true, reportPreview });
      });

    }
  }

  onSavedAndEmailed = (isSaved, andEmail) => {
    this.setState({ isReportPreviewOpen: false });
    if (isSaved) {
      this.props.saveReportToFiles(ENTITY_TYPES.booking, this.props.storeBooking.id, this.state.reportPreview)
        .then(file => {
          if (andEmail) {
            this.props.openEmailWithFile(file);
          }
          this.props.filesUpdated();
        }).catch(error => this.okDialog.current.open(error.toString()));
    }
  }

  closePaymentsModal = () => {
    this.setState({ isPaymentsModalOpen: false });
  }

  openDepositsModal = () => {
    this.setState({ isDepositsModalOpen: true, toolsOpenedBy: null });
  }

  closeDepositsModal = () => {
    this.setState({ isDepositsModalOpen: false });
  }

  openBookingDetailsModal = () => {
    this.setState({ isBookingDetailsModalOpen: true });
  }

  closeBookingDetailsModal = () => {
    this.setState({ isBookingDetailsModalOpen: false });
  }

  setDisplayDates = () => {
    const { storeBooking: { events } } = this.props;
    let areAllEventsOnSameDay = false;
    let firstEvent = null;
    let lastEvent = null;
    let displayDate = null;
    let displayDateLong = null;
    let displayTime = null;

    if (events && events.length > 1) {
      const eventsClone = events.filter(e => e.recordStatus === 'Active');

      firstEvent = eventsClone[0];
      lastEvent = eventsClone.slice(-1)[0];

      if (firstEvent && lastEvent) {
        areAllEventsOnSameDay = moment(firstEvent.startDateTime).clone().isSame(lastEvent.endDateTime, 'date');
        displayDate = `${moment(firstEvent.startDateTime).clone().format('l')}${areAllEventsOnSameDay ? '' : ' - ' + moment(lastEvent.endDateTime).clone().format('l')}`;
        displayDateLong = `${moment(firstEvent.startDateTime).clone().format('dddd, MMMM D, YYYY')}${areAllEventsOnSameDay ? '' : ' - ' + moment(lastEvent.endDateTime).clone().format('dddd, MMMM D, YYYY')}`;
        displayTime = `${moment(firstEvent.startDateTime).clone().format('LT')} - ${moment(lastEvent.endDateTime).clone().format('LT')}`;
      }
    }

    this.setState({ displayDate, displayDateLong, displayTime });
  }

  getPayments = () => {
    const {
      getPayments,
      storeBooking: { id },
    } = this.props;

    return getPayments(id);
  }

  addPayment = payment => {
    const {
      addPayment,
      storeBooking: { id },
    } = this.props;

    return addPayment(id, payment);
  }

  editPayment = payment => {
    const {
      updatePayment,
      storeBooking: { id },
    } = this.props;

    return updatePayment(id, payment);
  }

  deletePayment = paymentId => {
    const {
      deletePayment,
      storeBooking: { id },
    } = this.props;

    return deletePayment(id, paymentId);
  }

  render() {
    const {
      storeBooking,
      classes,
      label,
      onEdit,
      onDelete,
      isEditing,
      onClone,
      onCloseBooking,
      viewState,
    } = this.props;
    const {
      isFinancialDetailsModalOpen,
      isPaymentsModalOpen,
      isDepositsModalOpen,
      isBookingDetailsModalOpen,
      toolsOpenedBy,
      taxesOpen,
      shareOpen,
      discountsOpen,
      isIsPrintsModalOpen,
      isReportPreviewOpen,
      reportPreview,
      displayDate,
      displayDateLong,
      displayTime,
    } = this.state;

    return (
      <AppBar position="static" classes={{ root: classes.appBarOverrides }}>
        <div className={classes.topBar}>
          <div className={classes.topLeft}>
            <div className={classes.iconContainer}>
              <BookingIcon classes={{ root: classes.bookingIcon }} />
            </div>
            <div className={classNames(classes.verticalAlign)}>
              <div className={classes.smallerFont}>{label}</div>
              <p className={classes.bookingName}>
                {storeBooking.name}
              </p>
            </div>
          </div>
          <div className={classes.topRight}>
            <MiniButtonSet>
              <HeaderButton
                component={Link}
                to={`/bookings/${storeBooking.id}`}
                isActive={viewState === 'Event Details'}
                name="Event"
                icon={<BookingIcon className={classes.rightIcon} />}>
              </HeaderButton>
              <HeaderButton
                component={Link}
                to={`/bookings/${storeBooking.id}/chitchat`}
                isActive={viewState === 'Chit Chat'}
                name="Chit Chat"
                icon={<ChitChatIcon className={classes.rightIcon} />}>
              </HeaderButton>
              <HeaderButton
                component={Link}
                to={`/bookings/${storeBooking.id}/email`}
                isActive={viewState === 'Email'}
                name="Email"
                icon={<EmailIcon className={classes.rightIcon} />}>
              </HeaderButton>
            </MiniButtonSet>
            <MiniButtonSet>
              <HeaderButton onClick={onEdit} isActive={isEditing} name="Edit" icon={<EditIcon className={classes.rightIcon} />}></HeaderButton>
              <HeaderButton onClick={onDelete} name="Delete" icon={<DeleteIcon className={classes.rightIcon} />}></HeaderButton>
              <HeaderButton onClick={onClone} name="Clone" icon={<CloneIcon className={classes.rightIcon} />}></HeaderButton>
              <ArrowDownButton onClick={this.openToolsMenu} />
              <Menu
                id="simple-menu"
                anchorEl={toolsOpenedBy}
                open={Boolean(toolsOpenedBy)}
                onClose={this.closeToolsMenu}
              >
                <MenuItem onClick={this.openPrintsModal}>Prints</MenuItem>
                <MenuItem onClick={this.openPaymentsModal}>Payments</MenuItem>
                <MenuItem onClick={this.openDepositsModal}>Scheduled Deposits</MenuItem>
                <MenuItem onClick={this.openDiscounts}>Discounts</MenuItem>
                <MenuItem onClick={this.openTaxAndServices}>Tax & Service Charge</MenuItem>
                <MenuItem onClick={this.openFinancialsModal}>Financial Details</MenuItem>
                <MenuItem onClick={this.openShare}>Share Booking</MenuItem>
              </Menu>
            </MiniButtonSet>

            <TaxAndServices
              isOpened={taxesOpen}
              bookingId={storeBooking.id}
              onClose={this.closeTaxAndServices}
            />
            <Discounts
              isOpened={discountsOpen}
              bookingId={storeBooking.id}
              onClose={this.closeDiscounts}
            />
            <Share
              isOpened={shareOpen}
              bookingId={storeBooking.id}
              onClose={this.closeShare}
            />
          </div>
        </div>
        {storeBooking &&
          <Paper elevation={1} square className={classes.bottomBar}>
            <div className={classes.glanceSection}>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Contact</p>
                <p onClick={this.goToContact} className={classNames(classes.glanceData, classes.link)}>{storeBooking.contact && storeBooking.contact.name}</p>
              </div>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Account</p>
                <p onClick={this.goToAccount} className={classNames(classes.glanceData, classes.link)}>{storeBooking.account && storeBooking.account.name}</p>
              </div>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Date</p>
                <p className={classes.glanceData}>{displayDate}</p>
              </div>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Time</p>
                <p className={classes.glanceData}>{displayTime}</p>
              </div>
            </div>

            <div className={classes.glanceSection}>
              <div className={classNames(classes.marginRight, classes.divider)}></div>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Event Manager</p>
                <p className={classes.glanceData}>{storeBooking.eventManager}</p>
              </div>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Date Booked</p>
                <p className={classes.glanceData}>{moment(storeBooking.dateBooked).format('l')}</p>
              </div>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}># of Events</p>
                <p className={classes.glanceData}>{storeBooking.events && storeBooking.events.length}</p>
              </div>
            </div>

            <div className={classes.glanceSection}>
              <div className={classes.bookingValue} onClick={this.openFinancialsModal}>
                <p className={classes.glanceLabel}>Booking Value</p>
                <p className={classes.glanceData}>{storeBooking.total && provideIntlService(this).formatNumber(storeBooking.total, 'c')}</p>
              </div>
            </div>
            <div className={classNames(classes.glanceSection, classes.marginRight)}>
              <HighlightButton
                className={classNames(classes.widerButton, classes.marginRight)}
                onClick={this.openBookingDetailsModal}>
                Booking Details
              </HighlightButton>
              <HighlightButton
                className={classes.widerButton}
                onClick={onCloseBooking}
                disabled={storeBooking.isComplete}>
                Close Booking
              </HighlightButton>
            </div>
          </Paper>
        }
        <FinancialDetailsModal onClose={this.closeFinancialModal} bookingId={storeBooking.id} isOpened={isFinancialDetailsModalOpen} />
        {isPaymentsModalOpen &&
          <PaymentsModal
            onClose={this.closePaymentsModal}
            id={storeBooking.id}
            name={storeBooking.name}
            date={displayDateLong}
            isOpened={isPaymentsModalOpen}
            getPayments={this.getPayments}
            addPayment={this.addPayment}
            editPayment={this.editPayment}
            deletePayment={this.deletePayment}
          />}
        {isDepositsModalOpen &&
          <DepositsModal
            onClose={this.closeDepositsModal}
            id={storeBooking.id}
            isOpened={isDepositsModalOpen}
          />}
        {isBookingDetailsModalOpen &&
          <BookingDetailsModal
            onClose={this.closeBookingDetailsModal}
            isOpened={true}
            displayDate={displayDate}
            displayTime={displayTime}
          />}
        <Prints
          isOpened={isIsPrintsModalOpen}
          onGenerate={this.onGeneratePrint}
        />
        <ReportPreviewModal
          onSaveAndEmail={this.onSavedAndEmailed}
          isOpened={isReportPreviewOpen}
          params={{ bookingId: storeBooking.id }}
          report={reportPreview}
        />
        <SimpleDialog onlyOkayButton={true} innerRef={this.okDialog} />
      </AppBar>
    );
  }
}

BookingHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    booking: {
      manager: {
        storeBooking,
        isEditing,
      },
    },
  } = state;

  return {
    storeBooking,
    isEditing,
  };
};

const mapDispatchToProps = {
  getPayments,
  addPayment,
  updatePayment,
  deletePayment,
  saveReportToFiles,
  getReportDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(BookingHeader)));
