import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import ODataGrid from 'Components/Grid/ODataGrid';
import { connect } from 'react-redux';

import {
  getMenuItemModifierTemplates,
  startModifierTemplateStack,
  pushModifierTemplateToStack,
} from 'actions/menuItemModifier';
import { getRevenueTypes } from 'actions/item';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { SelectProperty } from '../../../helpers/select';
import {
  Paper,
} from '@material-ui/core';
import {
  Add as AddIcon,
} from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';

const styles = theme => ({
  cellContents: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  gridWidth: {
    display: 'flex',
    minWidth: 300,
    maxWidth: 300,
  },
  toolbarButton: {
    width: 20,
  },
  buttonsBar: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    flexGrow: 1,
    padding: 10,
  },
});

class ModifierTemplateGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columns: [{
        field: 'name',
        title: 'Name',
        isVisible: true,
      }, {
        field: 'options',
        title: 'Options',
        isVisible: true,
      },
      ],
    };
  }

  getToolbar = () => {
    const { classes } = this.props;

    return (<div className={classes.buttonsBar}><HighlightButton
      onClick={this.addNewMenuItemModifierTemplate}
      className={classes.toolbarButton}
    >
      <AddIcon />
    </HighlightButton></div>);
  }

  componentDidMount() {
    this.props.getMenuItemModifierTemplates();
    this.props.getRevenueTypes();
  }

  onModifierTemplateSelected = event => {
    this.props.startModifierTemplateStack(event.dataItem);

    this.setState({
      ...this.state,
      selectedModifierTemplateId: event.dataItem.id,
    });
  };

  addNewMenuItemModifierTemplate = () => {
    this.props.pushModifierTemplateToStack({
      rule: 'PickOne',
      style: 'Button',
    });
  }

  getColumn(column) {
    if (column.isVisible) {
      switch (column.field) {
        case 'name':
          return <GridColumn
            key={column.field}
            field={column.field}
            title={column.title}
            filterable={false}
          />;
        case 'options':
          return <GridColumn filterable={false} key={column.field} field={column.field} title="Options" cell={props => (
            <td>{SelectProperty(props.dataItem.options, 'name').join(', ')}</td>
          )} />;
        default:
          return <GridColumn key={column.field} field={column.field} title={column.title} />;
      }
    }
  }

  render() {
    const { modifierTemplates, isLoading, classes } = this.props;
    const { columns, selectedModifierTemplateId } = this.state;

    return (

      <Paper elevation={0} className={classNames(classes.cellContents, classes.gridWidth)}>

        <ODataGrid
          getData={this.props.getMenuItemModifierTemplates}
          toolBar={this.getToolbar()}
          items={modifierTemplates && modifierTemplates.map(
            modifierTemplate => ({ ...modifierTemplate, selected: modifierTemplate.id === selectedModifierTemplateId }))}
          isLoading={isLoading}
          onRowClick={this.onModifierTemplateSelected}
        >
          {columns.map(column => this.getColumn(column))}
        </ODataGrid>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  const {
    menuItemModifier: {
      modifierTemplates: {
        data,
        isLoading,
        modifierTemplatesStack,
      },
    },
  } = state;

  return {
    modifierTemplates: data,
    modifierTemplatesStack,
    isLoading,
  };
};

const mapDispatchToProps = {
  getMenuItemModifierTemplates,
  startModifierTemplateStack,
  pushModifierTemplateToStack,
  getRevenueTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModifierTemplateGrid));
