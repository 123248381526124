import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  return {
    container: {
      width: 300,
      marginBottom: 16,
    },
  };
};

class Prints extends Component {
  render() {

    return (
      <h3> Display Prints </h3>
    );
  }
}

export default withStyles(styles)(Prints);
