import React, { Component } from 'react';
import { withTheme } from '@material-ui/core/styles';
import {
  IconButton as MaterialIconButton,
} from '@material-ui/core';

class IconButton extends Component {

  getIconStyle = () => {
    const {
      fontSize,
      theme: { palette },
      className,
    } = this.props;

    if (className) {
      return {};
    }

    return {
      fontSize: fontSize || 24,
      color: palette.grey[400],
    };
  }

  getButtonStyle = iconStyle => {
    return iconStyle.fontSize ?
      {
        padding: ((this.props.height || 64) - iconStyle.fontSize) / 2, //padding lets the hit area of the icon be a little bigger. Calculate is via the desired height.
      } : {};
  }

  //currently if you pass in className, it will ignore fontSize and height props
  render() {
    const {
      className,
      'aria-label': ariaLabel,
      icon: Icon,
      onClick,
    } = this.props;
    const iconStyle = this.getIconStyle();
    const buttonStyle = this.getButtonStyle(iconStyle);

    return (
      <MaterialIconButton
        style={buttonStyle}
        className={className}
        onClick={onClick}
        aria-label={ariaLabel}>
        <Icon style={iconStyle} />
      </MaterialIconButton>
    );
  }
}

export default withTheme()(IconButton);
