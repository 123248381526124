import React from 'react';

//this is a generic wrapper around a Contact, Lead, Booking, Propostal, or Order
//which will facilitate making API calls for each of those entities
const SectionContext = React.createContext(
  {
    relationship: {
      entityType: 'notset',
      entityId: 0,
    },
  }
);

export default SectionContext;
