import React, { Component } from 'react';
import Publish from '@material-ui/icons/Publish';
import { Button, Fade, CardMedia, Card } from '@material-ui/core';
import DataLoader from 'Components/DataLoader';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Clear';
import * as imagePlaceholder from './default.png';

const styles = theme => ({
  uploader: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: 223,
  },
  imageParent: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    height: 160,
  },
  image: {
    height: 160,
    flexGrow: 1,
  },
  icon: {
    color: theme.palette.grey[800],
    width: 16,
    margin: '3px 7px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-around',
    padding: 16,
  },
  button: {
    height: 32,
    width: 32,
    minWidth: 32,
    minHeight: 32,
    padding: 0,
  },
  fileInput: {
    display: 'none',
  },
  loader: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const onUploadInputRef = React.createRef();

class ImageUpload extends Component {

  onUpload = async event => {
    const { newItem, onNewItemUpload } = this.props;

    if (newItem) {
      event.preventDefault();
      event.stopPropagation();

      await onNewItemUpload(onUploadInputRef);

    } else {
      // To enable re-upload of same file
      event.target.value = null;
    }
  }

  render() {
    const { classes, imageSrc, onFileChanged, onRemoveUpload, isLoading } = this.props;

    return (
      <div className={classes.uploader}>
        <Card square={true} elevation={0} className={classes.imageParent}>
          {isLoading ?
            <div className={classes.loader}>
              <Fade
                in={isLoading}
                style={{ transitionDelay: isLoading ? '300ms' : '500ms' }}
                unmountOnExit
              >
                <DataLoader />
              </Fade>
            </div>
            :
            <CardMedia
              className={classes.image}
              image={imageSrc || imagePlaceholder}
              title="Item image"
            />
          }
        </Card>
        <div className={classes.buttons}>
          <input
            ref={onUploadInputRef}
            accept="image/*"
            className={classes.fileInput}
            id="button-file"
            multiple
            type="file"
            onChange={onFileChanged}
            onClick={this.resetValue}
            hidden
          />
          <label htmlFor="button-file">
            <Button component="span" variant="outlined" aria-label="Upload File" mini className={classes.button} onClick={this.onUpload}>
              <Publish className={classes.icon} />
            </Button>
          </label>
          <Button variant="outlined" aria-label="Remove Upload" mini className={classes.button} onClick={onRemoveUpload}>
            <DeleteIcon className={classes.icon} />
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ImageUpload);
