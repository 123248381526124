import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { Clear as DeleteIcon } from '@material-ui/icons';
import SmallGrid from 'Components/Grid/SmallGrid';
import { buildGridCellModalLink } from 'Components/buildGridCellLink';
import { provideIntlService } from '@progress/kendo-react-intl';
import classNames from 'classnames';

const styles = theme => ({
  siteGrid: {
    height: 'inherit',
  },
  hoverPointer: {
    '& .k-checkbox, & .k-checkbox-label': {
      cursor: 'pointer',
    },
  },
  deleteIcon: {
    '&:hover': {
      color: theme.common.softPink,
      cursor: 'pointer',
    },
  },
});

class SiteGrid extends Component {
  constructor(props) {
    super(props);
    if (!this.props.onEdit) {
      return;
    }
    this.nameCell = buildGridCellModalLink({
      onOpenModal: item => this.props.onEdit(item),
      text: item => item.name || 'Unnamed',
    });
    this.formatter = provideIntlService(this);
  }

  onCheckboxClick = siteIndex => {
    console.log('onCheckboxClick TODO', siteIndex);
  }

  render() {
    const {
      classes,
      bookingEventSites,
      isEditing,
      onDeleteSite,
    } = this.props;

    return (
      <div className={classNames(classes.siteGrid, isEditing && classes.hoverPointer)}>
        <SmallGrid data={bookingEventSites ? bookingEventSites.filter(site => site.recordStatus === 'Active') : []}>
          <Column
            field=""
            width="50px"
            cell={props => (
              <td style={{ textAlign: 'center' }}>
                <input
                  className="k-checkbox"
                  type="checkbox"
                  id={`location-${props.dataItem.siteId}`}
                  checked={props.dataItem.selected}
                  onChange={() => this.onCheckboxClick(props.dataIndex)}
                  disabled={!isEditing}
                />
                <label htmlFor={`location-${props.dataItem.siteId}`} className="k-checkbox-label"></label>
              </td>
            )}
          />
          <Column field="name" title="Site" cell={this.nameCell} />
          <Column field="address1" title="Address" />
          <Column field="city" title="City" width="100px" />
          <Column field="stateProvince" title="State" width="75px" />
          <Column field="postalCode" title="Zip" width="75px" />
          <Column field="rateString" title="Rate" cell={props => props.dataItem.site ?
            <td>{`${this.formatter.formatNumber(props.dataItem.site.rate, 'c2')}/${props.dataItem.site.rateType}`}</td>
            :
            <td></td>
          }/>
          {isEditing && <Column
            field=""
            width="45px"
            cell={props => (
              <td style={{ display: 'flex', justifyContent: 'center' }}>
                <DeleteIcon className={classes.deleteIcon} onClick={() => onDeleteSite(props.dataIndex)} />
              </td>
            )}
          />
          }
        </SmallGrid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    booking: {
      focusedEvent,
    },
  } = state;

  return {
    focusedEvent,
  };
};

export default connect(mapStateToProps, undefined)(withStyles(styles)(SiteGrid));
