import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import RichTextField from 'Components/RichTextField';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import noop from 'lodash';

const styles = theme => ({
  addPostContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
  },
  addPostContent: {
    padding: '0 20px',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .fr-view': {
      whiteSpace: 'normal', // wraps text in froala editor
    },
  },
  post: {
    padding: '20px 0 16px 2px',
    overflow: 'auto',
  },
  postContentColumn1: {
    display: 'flex',
    flexDirection: 'column',
    width: '92%',
    flexGrow: 0,
    marginRight: 16,
  },
  postContentColumn2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0',
    justifyContent: 'flex-end',
  },
  addButton: {
    padding: '0 20px',
  },
  tabContainer: {
    height: 40,
    minHeight: 40,
    backgroundColor: theme.palette.grey[50],
    borderRadius: '4px 4px 0 0',
    '& button': {
      cursor: 'default',
    },
  },
});

class NewPostBox extends Component {
  render() {
    const { classes, newPost, handleChange, onAdd } = this.props;

    return (
      <div className={classes.addPostContainer}>
        <div className={classes.tabContainer}>
          <BlueTextTab
            tabs={['Post']}
            tabIndex={0}
            onTabChange={() => noop}
          />
        </div>
        <div className={classes.addPostContent}>
          <div className={classes.postContentColumn1}>
            <div className={classes.post}>
              <RichTextField
                value={newPost.message}
                onFieldChange={handleChange('message')}
                config={{
                  placeholderText: 'New Post',
                  heightMax: 300,
                }}
              />
            </div>
          </div>
          <div className={classes.postContentColumn2}>
            <PrimaryButton onClick={onAdd} className={classes.addButton}>Add</PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(NewPostBox);
