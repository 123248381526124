import React, { Component } from 'react';

class Display extends Component {

  render() {
    return (
      <h3> Display general </h3>
    );
  }
}

export default Display;
