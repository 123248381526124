import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  TimelineViews,
  Month,
  Inject,
  Resize,
  DragAndDrop,
  ResourcesDirective,
  ResourceDirective,
} from '@syncfusion/ej2-react-schedule';
import { getCalendarAndBookingEventsByDates, saveCalendarEvent, onSchedulerDaySelected, deleteCalendarEvent } from 'actions/calendar';
import { connect } from 'react-redux';
import HeaderWeek from './HeaderWeek';
import HeaderDayAtGlance from './HeaderDayAtGlance';
import HeaderTimeline from './HeaderTimeline';
import AddEditEventModal from './AddEditEventModal';
import BookingWizard from 'bookings/BookingWizard/BookingWizard';
import { eventTypeToColor } from './Agenda';
import { CALENDAR_MODE, CALENDAR_VIEW } from 'constants/calendarMode';
import DayEvent from './DayEvent';
import TimelineEvent from './TimelineEvent';
import Event from './Event';
import MonthEvent from './MonthEvent';
import RoomTemplate from './RoomTemplate';
import { REQUEST_TYPES } from 'constants/requestTypes';
import {
  QueryBuilder as QueryBuilderIcon,
} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import './Scheduler.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getBorderColor } from 'shared/themes';
import ValidationBanner from 'Components/ValidationBanner';
import DataLoader from 'Components/DataLoader';
import { getRooms } from 'actions/rooms';
import Prints from 'bookings/Prints';
import ReportPreviewModal from 'Components/ReportPreviewModal';
import { getReportDetails } from 'actions/reports';

//for list of fields and how they map checkout
//Schedule.prototype.initializeDataModule in node_modules\@syncfusion\ej2-schedule\src\schedule\base\schedule.js in
//                                           node_modules\@syncfusion\ej2-react-base\src\template.js
//                                           node_modules\@syncfusion\ej2-schedule\src\schedule\renderer\vertical-view.js
const eventSettings = {
  fields: {
    startTime: { name: 'syncFusionStart' },
    endTime: { name: 'syncFusionEnd' },
    id: 'id',
    subject: { name: 'name' },
    isAllDay: { name: 'allDay' },
    location: { name: 'location' },
    eventType: { name: 'calendarEventType' },
    description: { name: 'description' },
  },
};
const timeScale = {
  interval: 60,
  slotCount: 2,
};
const calendarContainer = {
  flexGrow: 1,
  position: 'relative',
};
const groupByRoom = { enableCompactView: true, resources: ['MeetingRoom'] };
const noGrouping = { enableCompactView: true, resources: [] };
const workDays = [0, 1, 2, 3, 4, 5, 6]; //just a hack to get the month view to not grey out the weekends

class Scheduler extends Component {
  constructor(props) {
    super(props);
    this.selectedDate = new Date(); //use this to select a range

    this.state = {
      selectedAppointment: null,
      isLoading: true,
      wizardType: null,
      selectedProposalId: null,
      printsModalOpen: false,
      reportPreviewOpen: false,
    };

    this.mode = props.currentMode;
    this.view = props.currentView || CALENDAR_VIEW.Calendar;
    this.validationBanner = React.createRef();
    this.schedule = React.createRef();
    this.loadingRef = React.createRef();
  }

  componentDidMount() {
    const getEvents = this.getCalendarEventsApi(this.getCalendarParams());

    const getRooms = this.props.getRooms()
      .then(rooms => {
        this.rooms = rooms;
      });

    Promise.all([getEvents, getRooms])
      .then(() => this.setState({ isLoading: false, containerHeight: this.loadingRef.current && this.loadingRef.current.clientHeight }));
  }

  componentDidUpdate(prevProps) {
    /*if (prevProps.currentMode !== this.props.currentMode) {
      // does not work because syncfusion assumes rendering the templates are synchronous and will do an element.clone on an emply element.
      const schedule = this.schedule.current;
      schedule.currentView = this.props.currentMode;
      schedule.dataBind();
    }*/
    if (prevProps.selectedEventTypes !== this.props.selectedEventTypes) {
      //this.state.mainCalendarMode !== CALENDAR_MODE.Agenda
      this.getCalendarEvents(REQUEST_TYPES.forceCacheUpdate);
    }
  }

  quickSaveEvent = newAppointment => {
    newAppointment.endDateTime = moment(newAppointment.startDateTime).add(1, 'day').add(-1, 'minute');
    this.props.saveCalendarEvent(newAppointment).catch(error => {
      if (error && error.message) {
        this.validationBanner.current.open(error.message);
      } else {
        this.validationBanner.current.open('Error occured');
      }
    }).then(() => this.getCalendarEvents(REQUEST_TYPES.forceCacheUpdate));
  }

  deleteCalendarEvent = id => {
    this.props.deleteCalendarEvent(id);
  }

  getCalendarEventsApi = params => {

    return this.props.getCalendarAndBookingEventsByDates(params)
      .then(calendarEvents => {

        if (this.schedule.current) {
          this.schedule.current.eventSettings.dataSource = calendarEvents;
        } else {
          eventSettings.dataSource = calendarEvents; //should only happen the first time!
        }
        this.calendarMeta = {
          firstDate: moment(params.firstDate),
          lastDate: moment(params.lastDate),
          calendarView: params.calendarView,
        };
      })
      .catch(error => console.log(`error getting calendar events ${error}`));
  }

  //called when
  // * selectedEventTypes changes
  // * switching modes
  // * after adding via quick add
  getCalendarEvents = requestType => {
    const params = this.getCalendarParams();

    if (
      requestType !== REQUEST_TYPES.forceCacheUpdate
      && params.firstDate.isBetween(this.calendarMeta.firstDate, this.calendarMeta.lastDate, null, '[)')
      && params.lastDate.isBetween(this.calendarMeta.firstDate, this.calendarMeta.lastDate, null, '(]')
      && params.calendarView === this.calendarMeta.calendarView) {
      this.reBindCalendar();

      return; //bail! no need to call API
    }

    return this.getCalendarEventsApi(params)
      .then(() => {
        this.reBindCalendar();
        this.selectCell(this.selectedDate);
      });
  }

  reBindCalendar = () => {
    const schedule = this.schedule.current;

    if (!schedule) {
      return;
    }

    if (this.view === CALENDAR_VIEW.Timeline) {
      schedule.group.resources = ['MeetingRoom'];
    } else {
      schedule.group.resources = [];
    }
    schedule.selectedDate = this.selectedDate;
    schedule.currentView = this.syncFusionView();
    this.schedule.current.dataBind();
  }

  popupOpen = args => {
    switch (args.type) {
      case 'QuickInfo':
        this.renderQuickInfo(args);
        break;
      case 'Editor':
        args.cancel = true; // prevent default editor template
        this.startNewEvent(args.data);
        break;
      default:
        break;
    }
  }

  styleQuickAdd = args => {
    // QuickAdd
    const popupContent = args.element.children[0].children[1];

    if (popupContent) {
      const input = args.element.querySelector('.e-popup-content .e-input');
      const dateContainer = args.element.querySelector('.e-date-time');
      const dateText = args.element.querySelector('.e-date-time-details').innerText;
      const quickAddIconStyles = { fontSize: 21, alignSelf: 'center' };
      const quickAddDateTextStyles = { marginLeft: 20, alignSelf: 'center' };

      input.setAttribute('placeholder', 'New Task');
      ReactDOM.render(
        <>
          <QueryBuilderIcon style={quickAddIconStyles} />
          <Typography variant="body1" style={quickAddDateTextStyles}>{dateText}</Typography>
        </>,
        dateContainer
      );
    }
  }

  styleQuickView = args => {
    const { theme } = this.props;
    const eventData = args.data;
    const backgroundColor = eventTypeToColor(theme, eventData);
    const ePopupHeader = args.element.querySelector('.e-popup-header');
    const eSubjectWrap = args.element.querySelector('.e-subject-wrap');
    const eSubject = args.element.querySelector('.e-subject');
    const ePopupHeaderIconWrapper = args.element.querySelector('.e-header-icon-wrapper');
    const popupButtons = document.createElement('div');
    const ePopupContent = args.element.querySelector('.e-popup-content');
    const miniButtonStyle = {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[100]}`,
      color: theme.palette.common.black,
      minHeight: 32,
      padding: '0 15px',
      textTransform: 'initial',
      fontWeight: '400',
      borderRadius: '0',
      borderRight: 'none',
      '&:firstChild': {
        borderRadius: '4px 0 0 4px',
      },
      '&:lastChild': {
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: '0 4px 4px 0',
      },
    };

    ePopupHeader.style.cssText = `background-color: ${backgroundColor};`;
    eSubjectWrap.style.cssText = 'padding: 8px 24px 8px 16px;';
    eSubject.style.cssText = 'font-size: 18px; font-weight: normal;';
    ePopupHeaderIconWrapper.style.cssText = 'padding: 8px;';
    ePopupHeaderIconWrapper.insertBefore(popupButtons, ePopupHeaderIconWrapper.childNodes[0]);
    popupButtons.className = 'e-popup-buttons';
    popupButtons.style.cssText = "margin-right: auto; border-radius: 4px; overflow: hidden;";
    ePopupContent.style.cssText = 'padding: 8px 16px 24px 16px';

    ReactDOM.render(
      <>
        <Button style={miniButtonStyle} onClick={() => this.printCalendarEvent(eventData)}>Print</Button>
        <Button style={miniButtonStyle} onClick={() => this.startNewEvent(eventData)}>View</Button>
      </>, popupButtons);

    if (eventData.sharedContacts && eventData.sharedContacts.length) {
      const eSharedContacts = document.createElement('div');

      eSharedContacts.className = 'e-shared-contacts';
      eSharedContacts.style.cssText = "display: flex; padding-top: 16px;";
      ePopupContent.appendChild(eSharedContacts);
      ReactDOM.render(
        <>
          <div
            className="e-shared-contacts-icon e-icons"
            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '18px', paddingRight: '13px', width: '31px' }}
          >
          </div>
          <div className="e-shared-contacts-wrapper e-text-ellipsis">
            <div className="e-shared-contacts-details e-resource-details e-text-ellipsis">
              {eventData.sharedContacts.map(c => c.name).join(', ')}
            </div>
          </div>
        </>, eSharedContacts);
    }
  }

  renderQuickInfo = args => {
    if (args.data.id) {
      this.styleQuickView(args);
    } else {
      this.styleQuickAdd(args);
    }
  }

  buildSelectedAppointment = data => {
    if (!data) { // New Event
      const { selection } = this.state;

      if (selection) {
        return {
          startDateTime: selection.startTime,
          endDateTime: selection.endTime,
        };
      }

      return null;
    }

    if (data.id && data.startDateTime) { // Existing Event
      return data;
    }
    if (data.syncFusionStart && (!data.id || !data.startDateTime)) { // Quick Add -> More Details
      return {
        name: data.name,
        calendarEventType: 'Task',
        startDateTime: data.syncFusionStart,
        // for some reason, syncFusionEnd is same as syncFusionStart, so we must add 24 hours manually (Syncfusion bug?).
        endDateTime: moment(data.syncFusionStart).add(24, 'hours').toDate(),
      };
    }

    return null;
  }

  isCalendarEvent = () => {
    const { selection } = this.state;

    return selection.calendarEventType === 'Task' || selection.calendarEventType === 'Personal';
  }

  printCalendarEvent = eventData => {
    if (this.isCalendarEvent()) {
      this.onGeneratePrint('Calendar-Print-PersonalToDo.repx');
    } else {
      this.setState({ printsModalOpen: true });
    }
  }

  onGeneratePrint = print => {
    this.setState({ printsModalOpen: false });
    if (print) {
      const { selection } = this.state;
      const reportParams = this.isCalendarEvent()
        ? { eventId: selection ? selection.eventId : 0 }
        : { bookingId: selection ? selection.bookingId : 0 };

      this.props.getReportDetails(print).then(reportPreview => {
        this.setState({ reportPreviewOpen: true, reportPreview, reportParams });
      });
    }
  }

  onSavedAndEmailed = (isSaved, andEmail) => {
    this.setState({ reportPreviewOpen: false });
  }

  startNewEvent = eventData => {
    if (eventData && eventData.bookingId && eventData.calendarEventType === 'Booking') {
      //could also check eventData.eventType===0
      this.props.history.push(`bookings/${eventData.bookingId}/${eventData.baseId}`);

      return; //not using the AddEditEventModal
    }
    if (eventData && eventData.calendarEventType === 'Proposal') {
      this.openBookingWizard({ wizardType: 'proposal', selectedProposalId: eventData.bookingId });

      return;
    }
    const selectedAppointment = this.buildSelectedAppointment(eventData);

    this.setState({ isAddingEditingEvent: true, selectedAppointment });
  }

  //once bug gets fixed in syncfusion we can do this in the componentDidUpdate() and get rid of this function
  //if (prevProps.currentMode !== this.props.currentMode) {
  //this.schedule.current.currentView = this.props.currentMode;
  //this.schedule.current.dataBind();
  //}

  gotoMode = ({ mode, view }) => {
    const previousMode = this.mode;

    this.mode = mode;
    this.view = view;
    if (mode === CALENDAR_MODE.Agenda) {
      return;
    }
    if (previousMode === CALENDAR_MODE.Agenda) {
      //switch from Month to Agenda, then back to Month messed up the calendar height for some reason
      //adding force is a quick fix for this
      this.getCalendarEvents(REQUEST_TYPES.forceCacheUpdate);
    } else {
      this.getCalendarEvents();
    }
    this.props.onSchedulerDaySelected(this.selectedDate);
  }

  syncFusionView = () => {
    if (this.view === CALENDAR_VIEW.Timeline) {
      if (this.mode === CALENDAR_MODE.Week) {
        return 'TimelineWeek';
      }

      return 'TimelineDay';
    }

    return this.mode;
  }

  getCalendarParams = () => {
    const momentDate = moment(this.selectedDate);
    let firstDate;
    let unitsToAdd;

    if (this.mode === CALENDAR_MODE.Month) {
      firstDate = momentDate.startOf('month');
      unitsToAdd = 'months';
    } else if (this.mode === CALENDAR_MODE.Week) {
      firstDate = momentDate.startOf('week');
      unitsToAdd = 'weeks';
    } else {
      firstDate = momentDate.startOf('day');
      unitsToAdd = 'days';
    }
    const lastDate = firstDate.clone().add(1, unitsToAdd);

    return {
      eventTypes: this.props.selectedEventTypes,
      firstDate,
      lastDate,
      calendarView: this.view,
    };
  }

  gotoDate = date => {
    const calendarState = this.getCalendarState();

    this.selectedDate = date;

    if (date >= calendarState.firstDate && date <= calendarState.lastDate) {
      return;
    }

    this.getCalendarEvents();
  }

  selectCell = date => {
    if (!this.schedule.current) {
      return;
    }

    const schedule = this.schedule.current;
    const selectedCell = schedule.getWorkCellElements().find(cell => cell.data === date.getDate());

    if (selectedCell) {
      schedule.selectCell(selectedCell);
    }
  }

  gotoNext = () => {
    const calendarState = this.getCalendarState();
    const newDate = new Date(calendarState.lastDate.getTime());

    newDate.setDate(newDate.getDate() + 1);
    this.gotoDate(newDate);
  }

  gotoPrevious = () => {
    const calendarState = this.getCalendarState();
    const newDate = new Date(calendarState.firstDate.getTime());

    newDate.setDate(newDate.getDate() - 1);
    this.gotoDate(newDate);
  }

  dateHeaderTemplate = props => {
    if (this.props.dayAtGlance) {
      return (<HeaderDayAtGlance date={props.date} palette={this.props.theme.palette} />);
    }

    return (<HeaderWeek date={props.date} palette={this.props.theme.palette} />);
  }

  onScheduleLoad = () => {
    this.sendCalendarRange();
    this.props.onScheduleLoad({ height: this.state.containerHeight });
  }

  onScheduleNavigated = args => {
    if (['viewNavigate', 'dateNavigate'].includes(args.requestType)) {
      this.sendCalendarRange();
    }
  }

  getCalendarState = () => {
    const schedule = this.schedule.current;

    if (!schedule) {
      console.error('this.schedule.current not set');

      return;
    }
    const view = schedule.activeView;

    if (!view) {
      console.error('schedule.activeView not set');

      return;
    }

    if (schedule.currentView === CALENDAR_MODE.Day && this.mode !== CALENDAR_MODE.Day) {
      //the user clicked a date in the calendar circumventing normal mode switching
      this.mode = CALENDAR_MODE.Day;
      this.selectedDate = view.renderDates[0];
    }

    if (view.renderDates.length > 7 && this.mode === CALENDAR_MODE.Month) {
      const startOfWeek2 = moment(view.renderDates[7]);

      return {
        currentMode: this.mode,
        firstDate: startOfWeek2.startOf('month').toDate(),
        lastDate: startOfWeek2.endOf('month').toDate(),
      };
    }

    return {
      currentMode: this.mode,
      firstDate: new Date(view.renderDates[0]),
      lastDate: new Date(view.renderDates[view.renderDates.length - 1]),
    };
  }

  sendCalendarRange = () => {
    const calendarState = this.getCalendarState();

    if (!calendarState) {
      return;
    }

    this.props.onCalendarRangeChanged(calendarState.firstDate, calendarState.lastDate, calendarState.currentMode);
  }

  actionFailure = e => {
    console.error('onactionFailure', e);
  }

  //this is only to customize the Events under the more Menu. For instance if there are 10 events on 1 day, a little link with more will be available
  eventRendered = args => {
    const element = args.element;

    if (element.className !== 'e-appointment') {
      return;
    }
    if (element.children.length !== 1) {
      return;
    }
    if (element.children[0].className !== 'e-subject') {
      return;
    }

    const {
      data: event,
    } = args;
    const theme = this.props.theme;
    const color = eventTypeToColor(theme, event);

    ReactDOM.render(<Event event={event} color={color} timeColor={theme.palette.grey.A200} tiny={true} />, element);
  }

  monthTemplate = event => {
    return (<MonthEvent event={event} color={eventTypeToColor(this.props.theme, event)} />);
  }

  timelineTemplate = event => {
    const theme = this.props.theme;
    const color = eventTypeToColor(theme, event);

    return (<TimelineEvent event={event} color={color} disabledColor={theme.palette.grey.A200} />);
  }

  dayTemplate = event => {
    const color = eventTypeToColor(this.props.theme, event);

    return (<DayEvent event={event} color={color} />);
  }

  renderCell = args => {
    if (!this.loaded) {
      this.onScheduleLoad(); //calling here because if you call when the schedule is created, activeView is not set
      //So just call it when the very first cell renders
      this.loaded = true;
    }

    if (args.elementType === 'monthCells') {
      const content = args.element.firstChild.innerHTML;

      if (content) {
        args.element.firstChild.innerHTML = content.replace(/[a-z ]/ig, ''); //remove the text, (Feb, Mar, ect ) in the date field
      }

      args.element.data = args.date.getDate();
    }
    if (args.elementType === 'majorSlot' && this.syncFusionView() === 'TimelineDay') {
      this.renderTimelineHeader(args);
    }
  }

  renderTimelineHeader = args => {
    const element = args.element;
    let content = element.firstChild.innerHTML;

    if (content) {
      content = '';

      ReactDOM.render(
        <HeaderTimeline time={args.date} palette={this.props.theme.palette} />,
        element
      );
    }
  }

  closeCalendarEventModal = (shouldRefresh = false) => {
    this.setState({ isAddingEditingEvent: false });
    if (shouldRefresh) {
      this.getCalendarEvents(REQUEST_TYPES.forceCacheUpdate);
    }
  }

  isSlotAvailable(event) {
    if (!event.roomId) {
      return true;
    }
    if (this.view === CALENDAR_VIEW.Calendar) {
      return true; //getGroupIndexFromEvent only works if the calendar is in timeline mode with rooms visible.
    }
    const schedule = this.schedule.current;
    const groupIndex = schedule.eventBase.getGroupIndexFromEvent(event);

    return schedule.isSlotAvailable(event.syncFusionStart, event.syncFusionEnd, groupIndex);
  }

  actionBegin = args => {
    switch (args.requestType) {
      case 'eventCreate':
        // This is a Quick-Add
        const data = args.data[0];

        args.cancel = true;
        this.quickSaveEvent(this.buildDto(data, { calendarEventType: 'Task' }), true);
        break;
      case 'eventChange':
        this.quickSaveEvent(this.buildDto(args.data), true);
        break;
      case 'eventRemove':
        this.deleteCalendarEvent(this.buildDto(args.data[0]).id);
        break;
      default:
        break;
    }
  }

  buildDto = (data, overrides) => {
    if (overrides) {
      const updatedData = {
        ...data,
        ...overrides,
        startDateTime: data.syncFusionStart,
        endDateTime: data.syncFusionEnd,
      };

      updatedData.id = undefined; //sync fusion internally will try and calculate the next Id for inserts, Our API need a blank ID or else it considers it an update (if the ID already exists)
      if (this.view !== CALENDAR_VIEW.Timeline) {
        updatedData.roomId = undefined; //sync fusion assigns all events a room id. bug? clean it up before sending to the API
      }

      return updatedData;
    }

    return {
      ...data,
      startDateTime: data.syncFusionStart,
      endDateTime: data.syncFusionEnd,
    };
  }

  resourceHeaderTemplate = props => {
    const borderColor = getBorderColor(this.props.theme);

    return (
      <RoomTemplate {...props} borderColor={borderColor} />
    );
  }

  onSelect = args => {
    this.props.onSchedulerDaySelected(args.data.syncFusionStart);
    const data = args.data;
    const selection = {
      startTime: data.syncFusionStart,
      endTime: data.syncFusionEnd,
      roomId: data.roomId ? data.roomId[0] : null,
      bookingId: data.booking ? data.booking.id : 0,
      eventId: data.id,
      calendarEventType: data.calendarEventType,
    };

    this.setState({ selection }); //TODO: clear this when not used for wizard, so it doesn't prepopulate unexpectedly
  }

  openBookingWizard = ({ wizardType, selectedProposalId }) => {
    this.setState({ wizardType, selectedProposalId });
  }

  closeBookingWizard = () => {
    this.setState({ wizardType: null, selectedProposalId: null });
    this.getCalendarEvents(REQUEST_TYPES.forceCacheUpdate);
  }

  getCachedScheduleComponent = () => {
    if (this.cachedScheduleComponent) {
      //syncfusion's performance gets bad when modifying in a traditional react props lifecycle.
      //So cache the react component and do any changes through the ref with databind will give us better results.
      //and is also how many of their example do it.
      //Also some SyncFusion APIs, ex. dateHeaderTemplate, assumes rendering the templates are synchronous
      //then will do an element.clone on an emply element (React is lazy and decides when it wants to render a component)
      //So we get empty elements when sunc fusion runs during the render(). Instead rendering inside the onClick() with dataBind() is pretty consistent
      //though there will be a chance the dateHeaderTemplate will not render if React is in the right place.

      return this.cachedScheduleComponent;
    }
    const currentView = this.syncFusionView();
    const height = `${this.state.containerHeight}px`;
    const group = this.view === CALENDAR_VIEW.Calendar ? noGrouping : groupByRoom;

    this.cachedScheduleComponent = (
      <ScheduleComponent
        className='calendar'
        width='100%'
        height={height}
        timeScale={timeScale}
        ref={this.schedule}
        resourceHeaderTemplate={this.resourceHeaderTemplate}
        actionComplete={this.onScheduleNavigated}
        workDays={workDays}
        currentView={currentView}
        eventSettings={eventSettings}
        showHeaderBar={false}
        showTimeIndicator={false}
        actionFailure={this.actionFailure}
        eventRendered={this.eventRendered}
        renderCell={this.renderCell}
        dateHeaderTemplate={this.dateHeaderTemplate}
        popupOpen={this.popupOpen}
        actionBegin={this.actionBegin}
        group={group}
        select={this.onSelect}
      >
        <ResourcesDirective>
          <ResourceDirective
            field='roomId'
            title='Room Type'
            name='MeetingRoom'
            allowMultiple={true}
            dataSource={this.rooms}
            textField='name'
            idField='id'
          >
          </ResourceDirective>
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective option={CALENDAR_MODE.Day} eventTemplate={this.dayTemplate} />
          <ViewDirective option={CALENDAR_MODE.Week} eventTemplate={this.dayTemplate} />
          <ViewDirective option={CALENDAR_MODE.Month} eventTemplate={this.monthTemplate} />
          <ViewDirective option='TimelineDay' eventTemplate={this.timelineTemplate} />
          <ViewDirective option='TimelineWeek' eventTemplate={this.timelineTemplate} />
        </ViewsDirective>
        <Inject services={[Day, Week, Month, Resize, DragAndDrop, TimelineViews]} />
      </ScheduleComponent>
    );

    return this.cachedScheduleComponent;
  }

  render() {
    const {
      isAddingEditingEvent,
      selectedAppointment,
      isLoading,
      wizardType,
      selection,
      selectedProposalId,
      printsModalOpen,
      reportPreviewOpen,
      reportPreview,
      reportParams,
    } = this.state;

    if (isLoading) {
      //this is kind of awful. We need to set the height of the scheduler with pixels or else the timeline view will render without the needed scrollbars.
      //So temporarily put this div tag on. Get the height, then set it on the schedule

      return (
        <div ref={this.loadingRef} style={calendarContainer} >
          <DataLoader isLoading={true} />
        </div>);
    }

    return (
      <>
        {this.getCachedScheduleComponent()}
        <ValidationBanner
          innerRef={this.validationBanner}
        />
        {isAddingEditingEvent && (
          <AddEditEventModal
            onModalClosed={this.closeCalendarEventModal}
            appointment={selectedAppointment}
            hasRelationship={selectedAppointment && !!selectedAppointment.bookingId} //TODO: change to relationshipId if/when Tasks can be linked to other entities
          />
        )}
        {Boolean(wizardType) &&
          <BookingWizard
            onClose={this.closeBookingWizard}
            selection={selection}
            proposal={wizardType === 'proposal'}
            selectedProposalId={selectedProposalId}
          />
        }
        <Prints
          isOpened={printsModalOpen}
          onGenerate={this.onGeneratePrint}
        />
        <ReportPreviewModal
          onSaveAndEmail={this.onSavedAndEmailed}
          isOpened={reportPreviewOpen}
          params={reportParams}
          report={reportPreview}
        />
      </>
    );
  }
}

Scheduler.propTypes = {
  onCalendarRangeChanged: PropTypes.func,
  onScheduleLoad: PropTypes.func,
  theme: PropTypes.object,
  dayAtGlance: PropTypes.bool,
};

Scheduler.defaultProps = {
  onCalendarRangeChanged: () => { },
  onScheduleLoad: () => { },
  dayAtGlance: false,
};

const mapDispatchToProps = {
  getCalendarAndBookingEventsByDates,
  saveCalendarEvent,
  deleteCalendarEvent,
  getRooms,
  onSchedulerDaySelected,
  getReportDetails,
};

const mapStateToProps = state => {
  const {
    calendar: {
      selectedEventTypes,
    },
  } = state;

  return {
    selectedEventTypes,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Scheduler));
