import React, { Component } from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';

export default function dropdownFilterCell(data, defaultItem) {
  const defaulItemWithId = {
    text: defaultItem,
  };

  return class extends Component {
    hasValue(value) {
      return value && value.id;
    }

    handleChange = event => {
      const hasValue = this.hasValue(event.target.value);

      this.props.onChange({
        value: hasValue ? event.target.value.id : '',
        operator: hasValue ? 'eq' : '',
        syntheticEvent: event.syntheticEvent,
      });
    }

    render() {
      const thisValue = this.props.value ? data.find(d => d.id === this.props.value) : defaulItemWithId;

      return (
        <div className="k-filtercell">

          <DropDownList
            data={data}
            textField="text"
            onChange={this.handleChange}
            value={thisValue}
            defaultValue={defaulItemWithId}
          />
          <button
            className="k-button k-button-icon k-clear-button-visible"
            title="Clear"
            disabled={!this.hasValue(thisValue)}
            onClick={event => {
              event.preventDefault();
              this.props.onChange({
                value: '',
                operator: '',
                syntheticEvent: event,
              });
            }}
          >
            <span className="k-icon k-i-filter-clear" />
          </button>
        </div>
      );
    }
  };
}
