import React, { Component } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import Modal from 'Components/Modal';
import TextField from 'Components/TextField';
import Select from 'Components/Select';
import SectionHeader from 'Components/SectionHeader';
import SimpleDialog from 'Components/SimpleDialog';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { CurrencyField } from 'Components/currencyInput';

const styles = theme => {
  return {
    overflowIfTooBig: {
      overflow: 'auto',
      height: 350,
    },
    container: {
      display: 'flex',
      padding: '0 16px',
    },
    sectionHeader: {
      marginTop: 16,
    },
    section: {
      display: 'flex',
      flex: '1 1 auto',
    },
    halfWidth: {
      display: 'flex',
      width: '50%',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
};

class CustomRoomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      room: {},
      //TODO: get roomTypes from API (will probably be an Admin QuickPick)
      roomTypes: [
        'Banquet Room',
        'Classroom',
        'Outdoor Space',
        'Pub',
        'Theater',
      ],
    };
    this.errorDialog = React.createRef();
  }

  handleFieldChange = (fieldName, parser) => value => {
    const room = {
      ...this.state.room,
      [fieldName]: parser ? parser(value) : value,
    };

    this.setState({ room });
  }

  saveCustomRoom = () => {
    const { room } = this.state;
    const { saveCustomRoom } = this.props;

    if (!!room.name) {
      saveCustomRoom(room);
    } else {
      this.handleError('Please enter a room name');
    }
  }

  handleError = errorMessage => {
    this.errorDialog.current.open(errorMessage);
    this.setState({ isSaving: false });
  }

  render() {
    const {
      classes,
      onClose,
      isOpened,
    } = this.props;
    const {
      room,
      roomTypes,
      isSaving,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        onCancel={onClose}
        title="Add Custom Room"
        onSave={this.saveCustomRoom}
        isSaveDisabled={isSaving}
        addTitleBottomBorder={false}
      >
        <Paper className={classes.overflowIfTooBig}>
          <div className={classNames(classes.section, classes.flexColumn)}>
            <SectionHeader className={classes.sectionHeader}>Room Details</SectionHeader>
            <div className={classes.container}>
              <div className={classNames(classes.halfWidth, classes.flexColumn)}>
                <TextField
                  label="Room Name"
                  value={room.name}
                  onFieldChange={this.handleFieldChange('name')}
                />
                <CurrencyField
                  label="Room Price"
                  fieldName="price"
                  value={room.price}
                  onFieldChange={this.handleFieldChange('price', parseFloat)}
                />
              </div>
              <div className={classNames(classes.halfWidth, classes.flexColumn)}>
                <Select
                  label="Room Type"
                  value={room.roomType}
                  onFieldChange={this.handleFieldChange('roomType')}
                >
                  {roomTypes && roomTypes.map((type, index) =>
                    <MenuItem key={index} value={type} className={classes.input}>{type}</MenuItem>
                  )}
                </Select>
              </div>
            </div>
          </div>
        </Paper>
        <SimpleDialog
          innerRef={this.errorDialog}
          onlyOkayButton={true}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {

};

export default withStyles(styles)(connect(undefined, mapDispatchToProps)(CustomRoomModal));
