import React from 'react';
import Details from './Details';
import AuditTrail from '../contacts/AuditTrail';
import AddressFormWithMap from 'Components/AddressFormWithMap';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';

function AccountDetails({
  account,
  salesReps,
  isEditing,
  onFieldChange,
  onAddressFieldChange,
  salutations,
  isFieldChanged,
  isAddressFieldChanged,
  validateField,
  isFieldInvalid,
  onLaunchEditMode,
  isQuickAdd,
}) {

  return (
    <div onDoubleClick={onLaunchEditMode}>
      <Details
        account={account}
        salesReps={salesReps}
        isEditing={isEditing}
        onFieldChange={onFieldChange}
        salutations={salutations}
        isFieldChanged={isFieldChanged}
        validateField={validateField}
        isFieldInvalid={isFieldInvalid}
        isQuickAdd={isQuickAdd}
      />
      <CoverExpansionPanel title="Address Information" defaultExpanded={true}>
        <AddressFormWithMap
          entityId={account.id}
          address={account.accountAddress}
          isEditing={isEditing}
          onFieldChange={onAddressFieldChange}
          isFieldChanged={isAddressFieldChanged}
          isQuickAdd={isQuickAdd}
        />
      </CoverExpansionPanel>
      {!isQuickAdd &&
          <AuditTrail contact={account} />
      }
    </div>
  );
}

export default AccountDetails;
