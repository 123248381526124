import React, { Component } from 'react';
import DayAtAGlanceWidget from './DayAtAGlanceWidget';
import GreetingWidget from './GreetingWidget';
import UnlimitedWidget from './UnlimitedWidget';
import InvoicesWidget from './InvoicesWidget';
import TasksWidget from './TasksWidget';
import EventsCostGraphWidget from './EventsCostGraphWidget';
import EventsGraphDashboard from './EventsGraphWidget';
import { withStyles } from '@material-ui/core/styles';
import { getTasks } from 'actions/calendar';
import { connect } from 'react-redux';
import moment from 'moment';

const styles = () => ({
  homeContent: {
    padding: 24,
    overflowY: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  topContent: {
    display: 'flex',
    flex: '0 1 auto',
    height: 175,
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  mainContent: {
    display: 'flex',
    flex: '1 1 auto',
    height: 'calc(100% - 199px)', // subtract height of topContent (199 = height + margin)
  },
});

export class HomeMain extends Component {
  state = { tasks: [] };

  componentDidMount() {
    this.onRefresh();
  }

  onRefresh = () => {
    const today = moment().startOf('day').format();

    this.props.getTasks(today).then(({ data, meta }) => {
      this.setState({ tasks: data, taskCount: meta.total });
    });
  }

  render() {
    const { classes } = this.props;
    const { tasks, taskCount } = this.state;

    return (
      <div className={classes.homeContent}>
        <div className={classes.topContent}>
          <GreetingWidget taskCount={taskCount} />
          <EventsGraphDashboard />
          <EventsCostGraphWidget />
          <UnlimitedWidget />
        </div>
        <div className={classes.mainContent}>
          <InvoicesWidget />
          <TasksWidget tasks={tasks} onRefresh={this.onRefresh} />
          <DayAtAGlanceWidget />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getTasks,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(HomeMain));
