import { callApi } from 'shared/CallApi';
import {
  EMAIL_TEMPLATES_REQUEST_BEGIN,
  EMAIL_TEMPLATES_REQUEST_END,
  RECEIVE_EMAIL_TEMPLATES,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  SAVE_EMAIL_TEMPLATE,
  RECEIVE_EMAIL_TEMPLATES_MERGE_TAGS,
  EMAIL_SENT_SUCCESSFULLY,
} from './constants';

export const sendEmail = emailRequest =>
  dispatch => {
    return dispatch(callApi(`Email`, { method: 'POST', body: emailRequest }))
      .then(result => {
        if (!result.ok || result.status !== 200) {
          throw Error('Email not sent', result);
        }

        dispatch(emailSentSuccess());

        return result;
      });
  };

export const getEmailTemplateMergeTags = entityType =>
  (dispatch, getState) => {
    return dispatch(getEmailMergeTagsApi(entityType)).then(result => {
      dispatch(fetchMergeTagsSuccess(result));

      return result;
    });
  };

export const getEmailTemplates = (params = '') =>
  (dispatch, getState) => {
    dispatch(fetchEmailTemplatesBegin());

    return getEmailTemplatesApi(dispatch, params).then(result => {
      dispatch(fetchEmailTemplatesSuccess(result));

      return result;
    }).finally(() => dispatch(fetchEmailTemplatesEnded()));
  };

export const getEmailTemplate = emailTemplateId =>
  dispatch => {
    return dispatch(getEmailTemplateApi(emailTemplateId)).then(result => {
      return result;
    }).finally(() => dispatch(fetchEmailTemplatesEnded()));
  };

export const createEmailTemplate = emailTemplate => dispatch => {
  return dispatch(createEmailTemplateApi(emailTemplate))
    .then(result => {
      dispatch(saveEmailTemplateSuccess(result));

      return result;
    });
};

export const saveEmailTemplate = emailTemplate =>
  dispatch => {
    return dispatch(updateEmailTemplateApi(emailTemplate)).then(result => {
      dispatch(saveEmailTemplateSuccess(result));
    });
  };

export const deleteEmailTemplate = emailTemplate =>
  dispatch => {
    return dispatch(deleteEmailTemplateApi(emailTemplate)).then(result => {
      dispatch(deleteEmailTemplateSuccess(result));
      dispatch(getEmailTemplates());
    });
  };

const getEmailMergeTagsApi = entityType => dispatch => {
  return dispatch(
    callApi(`${entityType}/MergeTags`))
    .then(response => response.json())
    .catch(console.error);
};

const getEmailTemplatesApi = dispatch => {
  return dispatch(
    callApi(`Email/Template`))
    .then(response => response.json())
    .catch(console.error);
};

const getEmailTemplateApi = emailTemplateId =>
  dispatch => {
    return dispatch(callApi(`Email/Template/${emailTemplateId}`))
      .then(response => response.json())
      .catch(console.error);
  };

const deleteEmailTemplateApi = emailTemplate =>
  dispatch => {
    return dispatch(callApi(`Email/Template/${emailTemplate.id}`, { method: 'DELETE' }))
      .then(emailTemplate)
      .catch(console.error);
  };

const updateEmailTemplateApi = emailTemplate =>
  dispatch => {
    return dispatch(callApi(`Email/Template/${emailTemplate.id}`, { method: 'PUT', body: emailTemplate }))
      .then(response => response.json())
      .catch(console.error);
  };

const createEmailTemplateApi = emailTemplate => dispatch => {
  return dispatch(callApi(`Email/Template`, { method: 'POST', body: emailTemplate }))
    .then(response => response.json())
    .catch(console.error);
};

export const mergeTemplateWithTags = (emailTemplateId, entityRelationship) => dispatch => {
  return dispatch(callApi(`${entityRelationship.entityType}/${entityRelationship.entityId}/EmailTemplate/${emailTemplateId}`))
    .then(response => response.text())
    .catch(console.error);
};

export const fetchEmailTemplatesBegin = params => ({
  type: EMAIL_TEMPLATES_REQUEST_BEGIN,
});

export const fetchEmailTemplatesSuccess = response => ({
  type: RECEIVE_EMAIL_TEMPLATES,
  response,
});

export const fetchMergeTagsSuccess = response => ({
  type: RECEIVE_EMAIL_TEMPLATES_MERGE_TAGS,
  response,
});

export const fetchEmailTemplatesEnded = () => ({
  type: EMAIL_TEMPLATES_REQUEST_END,
});

export const deleteEmailTemplateSuccess = emailTemplate => ({
  type: DELETE_EMAIL_TEMPLATE_SUCCESS,
  emailTemplate,
});

export const saveEmailTemplateSuccess = emailTemplate => ({
  type: SAVE_EMAIL_TEMPLATE,
  emailTemplate,
});

export const emailSentSuccess = () => ({
  type: EMAIL_SENT_SUCCESSFULLY,
});

