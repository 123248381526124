import { callApi } from 'shared/CallApi';
import moment from 'moment';
import { fetchChitChatSuccess, fetchChitChatBegin, fetchChitChatFail } from './api';
import { toODataString } from '@progress/kendo-data-query';
import { CHIT_CHAT_TYPES } from 'constants/entityTypes';

export const fetchChitChat = (chitChatRelationship, relationshipId) => dispatch => {
  dispatch(fetchChitChatBegin(relationshipId));

  return dispatch(callApi(`chitChat/${chitChatRelationship}/${relationshipId}`))
    .then(result => result.json())
    .then(threads => dispatch(fetchChitChatSuccess(threads)))
    .catch(error => dispatch(fetchChitChatFail(error)));
};

export const fetchMainChitChat = () => dispatch => {
  // TODO: implement other filters, and virtual scroll.
  const odataFilter = toODataString({
    sort: [{ field: 'lastUpdated', dir: 'desc' }],
    // skip: 0,
    // take: 50,
  });

  dispatch(fetchChitChatBegin('Main'));

  return dispatch(callApi(`chitChat/main?${odataFilter}`))
    .then(result => result.json())
    .then(threads => dispatch(fetchChitChatSuccess(threads)))
    .catch(error => dispatch(fetchChitChatFail(error)));
};

export const saveNewChitChatThread = (chitChatType, chitChatThread) => dispatch => {
  return dispatch(callApi(`chitChat/${chitChatThread.chitChatRelationship}/${chitChatThread.relationshipId}/${chitChatType}`, { body: chitChatThread.baseMessage }));
};

export const saveChitChatComment = (chitChatRelationship, relationshipId, chitChatMessage) => dispatch => {
  return dispatch(callApi(`chitChat/${chitChatRelationship}/${relationshipId}/thread/${chitChatMessage.chitChatThreadId}`, { body: chitChatMessage }));
};

export const setThreadIsShared = (chitChatRelationship, relationshipId, chitChatThreadId) => dispatch => {
  return dispatch(callApi(`chitChat/${chitChatRelationship}/${relationshipId}/thread/${chitChatThreadId}/share`, { method: 'PUT' }));
};

export const deleteThread = (chitChatRelationship, relationshipId, chitChatThreadId) => dispatch => {
  return dispatch(callApi(`chitChat/${chitChatRelationship}/${relationshipId}/thread/${chitChatThreadId}`, { method: 'DELETE' }));
};

export const logChitChatSystemMessage = (message, relationship, recordName) => dispatch => {
  const chitChatThread = {
    baseMessage: {
      subject: 'Action',
      content: `${message}<br>${recordName}`,
      time: moment().format(),
      recordName,
      from: 'System Generated',
    },
    chitChatRelationship: relationship.entityType,
    relationshipId: relationship.id,
  };

  return dispatch(saveNewChitChatThread(CHIT_CHAT_TYPES.systemGeneratedAction, chitChatThread));
};
