import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  setStoreMenuItemsFromACategory,
} from 'actions/storemenu';
import { Collapse } from '@material-ui/core';
import MenuCategoryCard from 'Components/Menu/MenuCategoryCard';

const styles = theme => {
  return {
    container: {
      overflowY: 'auto',
    },
  };
};

export class CategoryNavigationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelOpen: ['','','','',''],
      position: 0,
    };
  }

  refs = {};

  myRef = React.createRef();

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    this.refs = this.props.storeMenuCategories.reduce((acc, value) => {
      acc[value.id] = React.createRef();

      return acc;
    }, {});

    this.findCategoryWithItems(this.props.storeMenuCategories,1);

  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeMenuCategories !== this.props.storeMenuCategories) {
      // TODO: Async issues with storeMenuCategories....these should always be loaded before this component is created
      this.findCategoryWithItems(this.props.storeMenuCategories,1);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  findCategoryWithItems = (categories,level) => {

    let foundId = 0;

    categories.forEach(item => {
      if (!foundId)
        if (item.menuCategoryItems && item.menuCategoryItems.length) {
          foundId = item.id;
          this.props.setStoreMenuItemsFromACategory(item);

        } else {
          let foundSomthing = false;

          if (item.menuCategories)
            foundSomthing = this.findCategoryWithItems(item.menuCategories, level + 1);

          if (foundSomthing)
            foundId = item.id;
        }
    });
    if (foundId) {
      const { panelOpen } = this.state;

      panelOpen[level] = `subcat-${foundId}`;
      this.setState({ panelOpen });
    }

    return foundId;
  }

  onScroll = () => {
    const scrollTop = this.myRef.current.scrollTop;

    this.setState({
      position: scrollTop,
    });
  }

  updateScroll = lastPosition => {
    this.myRef.current.scrollTop = lastPosition + 70;
  }

  categoryPicked = (level, index) => selectedCategory => {
    const { panelOpen } = this.state;
    const selectedCategoryStr = `subcat-${selectedCategory.id}`;

    if (this.refs && this.refs[selectedCategory.id] && this.refs[selectedCategory.id].current) {
      this.refs[selectedCategory.id].current.scrollIntoView({ block: 'start', behavior: 'auto' });
    }

    this.props.setStoreMenuItemsFromACategory(selectedCategory);

    if (panelOpen[level] !==  selectedCategoryStr) {
      panelOpen[level] = selectedCategoryStr;
      if (selectedCategory.menuCategories && selectedCategory.menuCategories.length)  {
        // setTimeout(this.updateScroll.bind(null,position), 500);
      }
    }

    for (let x = level + 1; x < panelOpen.length; x++) {
      panelOpen[x] = '';
    }

    this.setState({ panelOpen: panelOpen });
    this.props.setSelectedCategory(selectedCategory);
  }

  subCategories = (category, level) => {
    const { panelOpen } = this.state;
    const subCategories = category.menuCategories;
    const padding = level * 30;

    if (subCategories) {
      return  (
        <Collapse in={panelOpen[level] === `subcat-${category.id}`} timeout="auto" unmountOnExit>
          { subCategories.map((category, index ) =>
            <div key={`categoryCard-${category.id}`}>
              <MenuCategoryCard
                style={{ marginLeft: `${padding}px ` }}
                selected={`subcat-${category.id}` === panelOpen[level + 1] && this.multiCategoryBackgroundColor(level + 1)}
                onSelect={this.categoryPicked(level + 1, index)}
                category={category} />
              { this.subCategories(category, level + 1) }
            </div>
          )}
        </Collapse>
      );
    } else {
      return '';
    }
  }

  multiCategoryBackgroundColor = level => {
    const { panelOpen } = this.state;

    if (level !== panelOpen.length && panelOpen[level + 1]) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const {
      storeMenuCategories,
      classes,
    } = this.props;
    const {
      panelOpen,
    } = this.state;

    return (
      <div className={classes.container} ref={this.myRef} onScroll={this.onScroll}
      >
        {storeMenuCategories && storeMenuCategories.map((category, index) =>
          <div ref={this.refs[category.id]} key={`categoryCard-${category.id}`}>
            <MenuCategoryCard
              selected={`subcat-${category.id}` === panelOpen[1] && this.multiCategoryBackgroundColor(1)}
              onSelect={this.categoryPicked(1, index)}
              category={category} />
            { this.subCategories(category,1)}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeMenuCategories,
    },
  } = state;

  return {
    storeMenuCategories,
  };
};

const mapDispatchToProps = {
  setStoreMenuItemsFromACategory,
};

const CategoryNavigation = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CategoryNavigationComponent));

export default CategoryNavigation;
