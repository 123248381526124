import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';

const styles = theme => ({
  expandIconLeft: {
    right: 'unset',
    left: -8,
    color: theme.palette.common.black,
  },
  contentHeader: {
    backgroundColor: `${theme.palette.grey[50]} !important`, //if you "focus" on the tab you get a dark grey background. You can do this by tabbing to it.
    fontSize: 17,
    paddingLeft: 32,
    paddingTop: 4,
    paddingBottom: 4,
    width: '100%',
    minHeight: 'unset',
  },
  summaryContent: {
    margin: 'unset',
    minHeight: 'unset !important',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.grey[800],
  },
  centerContent: {
    flexGrow: 1,
    padding: 0,
  },
  rightContent: {
    padding: 0,
    '&:last-child': {
      paddingRight: 0,
    },
  },
  shiftRight: {
    paddingRight: 12,
  },
  expandedSummary: {
    minHeight: 'unset !important',
    margin: 'unset !important',
  },
  noPadding: {
    padding: '0 0',
  },
  noBoxShadow: {
    boxShadow: 'none',
  },
});

class CoverExpansionPanel extends Component {
  render() {
    const {
      classes,
      title,
      hoverTitle,
      children,
      centerContent,
      rightcontent,
      customstyles,
      isTitleHiddenOnDetails,
      ...rest
    } = this.props;

    const useTitle = hoverTitle || title;

    return (
      <ExpansionPanel
        {...rest}
        title={useTitle}
        classes={{
          root: classNames(classes.noBoxShadow, classes.root),
          expanded: classNames(classes.noBoxShadow, customstyles && customstyles.expanded),
        }}
      >
        <ExpansionPanelSummary
          classes={{
            root: classNames(classes.contentHeader, customstyles && customstyles.summary, rightcontent && classes.shiftRight),
            content: classes.summaryContent,
            expanded: classes.expandedSummary,
          }}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            classes: {
              root: classNames(classes.expandIconLeft, customstyles && customstyles.iconButton),
            },
          }}
        >
          {React.isValidElement(title) ? title : <span>{title}</span>}
          {centerContent && <ExpansionPanelActions classes={{ root: classes.centerContent }}>{centerContent}</ExpansionPanelActions>}
          {rightcontent && <ExpansionPanelActions classes={{ root: classes.rightContent }}>
            {rightcontent}
          </ExpansionPanelActions>}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.noPadding }} title={isTitleHiddenOnDetails ? '' : useTitle}>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

CoverExpansionPanel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired ,PropTypes.object]),
};

export default withStyles(styles)(CoverExpansionPanel);
