import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import Details from './Details';
import PaymentTab from './PaymentTab';
import OrderContext from './OrderContext';
import Menu from './Menu';
import SaveBar from 'Components/SaveBar';

const styles = theme => ({
  root: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
  },
  tabContainer: {
    height: 40,
    backgroundColor: theme.palette.grey[50],
  },
  tabContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    minHeight: 0, // flexbox scrolling fix
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

// Note: Payment capability temporarily commented out for tradeshow
// const tabs = ['Details', 'Menu', 'Driver', 'Payment', 'Chit Chat'];
const tabs = ['Order', 'Details', 'Payment'];

const MainTab = ({
  classes,
}) => {
  const {
    isEditing,
    onCancelEdit,
    onSave,
    onLaunchEditMode,
    onEdit,
    storeOrder,
    isSaving,
  } = useContext(OrderContext);
  const [tabIndex, setTabIndex] = useState(0);

  const getTabContents = () => {
    switch (tabIndex) {
      case 0:
        return (
          <Menu
            isEditing={isEditing}
            onEdit={onEdit}
            // disabled={disabled}
          />);
      case 1:
        return <Details />;
      case 2:
        return <PaymentTab storeOrder={storeOrder} />;

      default:
        return `${tabs[tabIndex]} area`;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.tabContainer}>
        <BlueTextTab
          onTabChange={setTabIndex}
          tabs={tabs}
          tabIndex={tabIndex} />
      </div>
      <div className={classes.tabContent} onDoubleClick={onLaunchEditMode}>
        {getTabContents()}
      </div>
      {isEditing && <SaveBar onSave={onSave} onCancel={onCancelEdit} isSticky={true} isSaving={isSaving} />}
    </div>
  );
};

export default withStyles(styles)(MainTab);
