import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from 'Components/Buttons/IconButton';
import { Add as AddIcon } from '@material-ui/icons';
import SmallBookingGrid from 'bookings/SmallBookingGrid';
import BookingWizard from 'bookings/BookingWizard/BookingWizard';
import { getBookingsByContact } from 'actions/booking';

const styles = theme => ({
  bookingSection: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  sectionLabel: {
    fontSize: 17,
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[50],
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionContainer: {
    paddingBottom: 18,
  },
  gridWrapper: {
    paddingTop: 16,
  },
});

class BookingSection extends Component {
  state = { bookings: [] };

  componentDidMount() {
    this.getBookings();
  }

  getBookings = () => {
    this.props.getBookingsByContact(this.props.contact.id).then(bookings => this.setState({ bookings }));
  }

  openProposalWizard = () => {
    this.setState({ isWizardOpen: true });
  }

  closeProposalWizard = () => {
    this.setState({ isWizardOpen: false });
    this.getBookings();
  }

  render() {
    const {
      classes,
      contact,
    } = this.props;
    const {
      bookings,
      isWizardOpen,
    } = this.state;

    return (
      <div className={classes.bookingSection}>
        <div className={classes.sectionContainer}>
          <div className={classes.sectionLabel}>
            <div>Bookings</div>
            <div>
              <IconButton height={31} icon={AddIcon} onClick={this.openProposalWizard} />
            </div>
          </div>
          <div className={classes.gridWrapper}>
            <SmallBookingGrid bookings={bookings} />
          </div>
        </div>
        {isWizardOpen &&
          <BookingWizard
            contactId={contact.id}
            accountId={contact.accountId}
            onClose={this.closeProposalWizard}
          />
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  getBookingsByContact,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(BookingSection));
