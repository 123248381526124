import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from 'Components/Table';

const styles = theme => ({
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  table: {
    fontSize: 12,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    '& th': {
      padding: '6px 13px',
    },
    '& tr': {
      borderTop: 0,
      borderBottom: 0,
    },
    '& td': {
      borderLeft: `1px solid ${theme.palette.grey[50]}`,
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
      paddingLeft: 8,
    },
  },
  rowLabel: {
    color: theme.palette.grey[300],
    paddingTop: 11,
    paddingBottom: 11,
  },
});

class TaxRatesTableComponent extends Component {

  state = {
    rates: [],
    errors: [],
  }

  getRate = revenueTypeId => {
    const { revenueConfiguration, rateOverwrites } = this.props;
    const rate = revenueConfiguration.revenueTypeRates.find(rate => rate.revenueTypeId === revenueTypeId);

    if (rateOverwrites && rateOverwrites.length > 0) {
      const overwrite = rateOverwrites.find(rate => rate.revenueTypeId === revenueTypeId);

      if (overwrite) {
        return overwrite;
      }
    }

    return rate;
  };

  onChange = index => event => {
    const { rates } = this.state;

    rates[index] = event.target.value;
    this.setState({ rates });
  }

  onBlur = (index,rateType,revenueType) => event => {
    const { rates, errors } = this.state;
    const { onRateUpdated } = this.props;

    if (rates[index]) {
      if (!isNaN(event.target.value) && event.target.value <= 100) {
        const rate = this.getRate(revenueType.id);
        const percent = toFixed(event.target.value, 6);

        rates[index] = percent;
        errors[index] = false;
        onRateUpdated({ ...rate, [rateType]: parseFloat(percent) / 100 });
        this.setState({ rates, errors });
      } else {
        errors[index] = true;
        this.setState({ errors });
      }
    }
  }

  isErrorTax = error => {
    return error ? true : false;
  }

  render() {
    const { classes, revenueConfiguration } = this.props;
    const { rates, errors } = this.state;

    return (

      <Table
        className={classes.table}
        header={
          <tr className={classes.header}>
            <th scope="col">Type</th>
            <th scope="col">Tax</th>
            <th scope="col">Service</th>
            <th scope="col">Gratuity</th>
          </tr>
        }
      >
        {revenueConfiguration && revenueConfiguration.revenueTypeRates && revenueConfiguration.revenueTypeRates.map((revenueTypeRate, index) =>

          <tr key={revenueTypeRate.revenueTypeId}>
            <th scope="row" className={classes.rowLabel}>
              {revenueTypeRate.revenueType.name}
            </th>
            <td style={{ backgroundColor: this.isErrorTax(errors[index * 3]) ? 'red' : 'white' }}>
              <input
                className="formatted-input"
                value={rates[index * 3] ? rates[index * 3] : toFixed(revenueTypeRate.taxPercentage * 100,6)}
                onChange={this.onChange(index * 3)}
                onBlur={this.onBlur(index * 3, 'taxPercentage',revenueTypeRate)}
                style={{ width: 70 }}
              />
            </td>
            <td style={{ backgroundColor: this.isErrorTax(errors[index * 3 + 1]) ? 'red' : 'white' }}>
              <input
                className="formatted-input"
                value={rates[index * 3 + 1] ? rates[index * 3 + 1] : toFixed(revenueTypeRate.serviceChargePercentage * 100,6)}
                onChange={this.onChange(index * 3 + 1)}
                onBlur={this.onBlur(index * 3 + 1, 'serviceChargePercentage',revenueTypeRate)}
                style={{ width: 70 }}
              />
            </td>
            <td style={{ backgroundColor: this.isErrorTax(errors[index * 3 + 2]) ? 'red' : 'white' }}>
              <input
                className="formatted-input"
                value={rates[index * 3 + 2] ? rates[index * 3 + 2] : toFixed(revenueTypeRate.gratuityPercentage * 100,6)}
                onChange={this.onChange(index * 3 + 2)}
                onBlur={this.onBlur(index * 3 + 2, 'gratuityPercentage',revenueTypeRate)}
                style={{ width: 70 }}
              />
            </td>
          </tr>
        )}
      </Table>
    );

  }
}

export default withStyles(styles)(TaxRatesTableComponent);

function toFixed(param, precisionpadding) {
  let val = param;

  if (isNaN(param))
    val = 0;

  var precision = precisionpadding || 0,
    neg = val < 0,
    power = Math.pow(10, precision),
    value = Math.round(val * power),
    integral = String((neg ? Math.ceil : Math.floor)(value / power)),
    fraction = String((neg ? -value : value) % power),
    padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');

  if (integral < 10)
    integral = '0' + integral;

  return precision ? integral + '.' + padding + fraction : integral;
}
