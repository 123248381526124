import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Today as Calendar,
  Settings,
} from '@material-ui/icons';
import moment from 'moment';

import HighlightButton from 'Components/Buttons/HighlightButton';

const styles = theme => {
  return {
    spaceOut: {
      marginLeft: 10,
      marginRight: 50,
    },
    spaceAfter: {
      marginRight: 200,
    },
  };
};

const thisMonthAndYear = moment().format('MMMM YYYY');

class TopFilter extends Component {
  render() {
    const { classes } = this.props;

    return (
      <>
        <HighlightButton minWidth={130}>
          {thisMonthAndYear}
        </HighlightButton>
        <HighlightButton
          className={classes.spaceOut}
        >
          <Calendar />
        </HighlightButton>
        <span className={classes.spaceAfter}>Filters</span>
        <HighlightButton>
          <Settings />
        </HighlightButton>

      </>
    );
  }
}

export default withStyles(styles)(TopFilter);
