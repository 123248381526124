import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = () => ({
  containerStyle: {
    position: 'absolute',
    margin: '-20px 36px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  time: {
    fontSize: 13,
    marginBottom: 8,
  },
  amPm: {
    fontSize: 11,
  },
});

class HeaderTimeline extends Component {
  render() {
    const {
      time,
      classes,
      palette,
    } = this.props;
    const momentForTime = moment(time);
    const shortTime = momentForTime.format('h');
    const amPm = momentForTime.format('A');

    return (
      <div className={classes.containerStyle}>
        <div className={classes.time} style={{ color: palette.primary.main }}>{shortTime}</div>
        <div className={classes.amPm} style={{ color: palette.grey.A200 }}>{amPm}</div>
      </div>
    );
  }
}
export default withStyles(styles)(HeaderTimeline);
