import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getReportsList } from 'actions/reports';
import { connect } from 'react-redux';
import { reportAboluteUrl } from 'actions/reports';
import _ from 'lodash';
import ReportFilter from './ReportFilter';
import ReportCard from './ReportCard';
import {
  Person as ContactIcon,
  RestaurantMenu as OrderIcon,
  Business as AccountIcon,
  ListAlt as BookingIcon,
} from '@material-ui/icons';
import { userIsInRole, adminRole } from 'helpers/userHelpers';

const styles = theme => ({
  reportsHeader: {
    position: 'static',
    alignItems: 'center',
    background: theme.palette.grey[50],
    fontSize: 18,
    padding: '12px 24px',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: theme.common.white,
  },
  bookingColor: {
    color: theme.palette.bookingEvent,
  },
  orderColor: {
    color: theme.common.softPink,
  },
  leadColor: {
    color: theme.common.magenta,
  },
  accountColor: {
    color: theme.common.yellow,
  },
  contactColor: {
    color: theme.common.teal,
  },
  filters: {
    display: 'flex',
    fontSize: 24,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  reports: {
    display: 'flex',
    flex: 1,
    padding: 16,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: '0px 32px 0 8px',
    '&:last-child': {
      paddingRight: 8,
    },
  },
  columnTitle: {
    fontSize: 15,
    color: theme.palette.grey[200],
    margin: '4px 0 12px',
  },
  reportColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  filterPlaceholder: {
    display: 'flex',
    height: 120,
    width: '51%',
  },
});

class ReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      filter: 'orderreport',
    };
    this.userIsSubscriber = userIsInRole(this.props.user, adminRole); // TODO: will be subscriptionOwnerRole
  }

  componentDidMount = () => {
    this.props.getReportsList().then(apiReports => {
      apiReports.forEach(r => r.name = _.startCase(r.name));
      this.setState({ reports: apiReports });
    });
  }

  openReport = url => {
    const fullUrl = reportAboluteUrl(url);

    return window.open(fullUrl, "_blank");
  }

  onFilterClick = filterName => {
    this.setState({ filter: filterName });
  }

  getFilteredReports = category => {
    const {
      reports,
      filter,
    } = this.state;

    return reports.filter(r => r.entityType.toLowerCase() === filter && r.category === category);
  }

  render() {
    const { classes } = this.props;
    const {
      filter,
    } = this.state;

    return (<>
      <div className={classes.mainContent}>
        <div className={classes.reportsHeader}>
        Reports
        </div>
        <div className={classes.paper}>
          <div className={classes.filters}>
            <ReportFilter
              name="Orders"
              onFilterClick={() => this.onFilterClick('orderreport')}
              isActive={filter === 'orderreport'}
              icon={<OrderIcon className={classes.orderColor}/>}
            />
            <ReportFilter
              name="Contacts"
              onFilterClick={() => this.onFilterClick('contactreport')}
              isActive={filter === 'contactreport'}
              icon={<ContactIcon className={classes.contactColor} />}
            />
            {this.userIsSubscriber ? <>
              <ReportFilter
                name="Bookings"
                onFilterClick={() => this.onFilterClick('booking')}
                isActive={filter === 'booking'}
                icon={<BookingIcon className={classes.bookingColor}/>}
              />
              <ReportFilter
                name="Accounts"
                onFilterClick={() => this.onFilterClick('account')}
                isActive={filter === 'account'}
                icon={<AccountIcon className={classes.accountColor}/>}
              />
            </>
              :
              <div className={classes.filterPlaceholder}></div>
            }
          </div>
          <div className={classes.reports}>
            <div className={classes.column}>
              <div className={classes.columnTitle}>Financial</div>
              <div className={classes.reportColumn}>
                {this.getFilteredReports('financial').map(report =>
                  <ReportCard report={report} onOpen={() => this.openReport(report.url)} />
                )}
              </div>
            </div>
            <div className={classes.column}>
              <div className={classes.columnTitle}>Detail</div>
              <div className={classes.reportColumn}>
                {this.getFilteredReports('detail').map(report =>
                  <ReportCard report={report} onOpen={() => this.openReport(report.url)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
  }
}

const mapDispatchToProps = {
  getReportsList,
};

const mapStateToProps = state => {
  const {
    oidc: { user },
  } = state;

  return {
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReportList));
