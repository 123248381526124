import {
  BOOKING_CREATE,
  BOOKING_UPDATE,
  SET_FOCUSED_EVENT_INDEX,
  UPDATE_FOCUSED_EVENT,
  UNAVAILABLE_ROOMS_RECEIVED,
  SET_SHIFT_TYPES,
  SET_NEXT_TEMP_ID,
  STORE_BOOKING_SET_IS_EDITING,
  STORE_BOOKING_REVERT_CHANGES,
  STORE_BOOKING_SET_STORE_TYPE,
  CLEAR_STORE_BOOKING,
} from 'actions/constants';
import _ from 'lodash';

const initialBookingState = {
  storeBooking: {
    events: [],
  },
  storeBookingRef: {
    events: [],
  },
  focusedEvent: {},
  focusedEventIndex: 0,
  nextTempId: 1,
};

const initialState = {
  wizard: {
    ...initialBookingState,
  },
  manager: {
    ...initialBookingState,
    isEditing: false,
  },
  shiftTypes: [],
  storeDefaultShiftTypes: {},
  currentStoreType: null, // 'wizard' or 'manager'
};

export function booking(state = initialState, action) {
  switch (action.type) {
    case BOOKING_CREATE: {
      const { currentStoreType, storeBooking } = action;
      const focusedEvent = storeBooking.events[0];

      return {
        ...state,
        currentStoreType,
        [currentStoreType]: {
          ...state[currentStoreType],
          storeBooking,
          focusedEvent,
          focusedEventIndex: 0,
        },
      };
    }
    case CLEAR_STORE_BOOKING: {
      const { currentStoreType } = state;

      return {
        ...state,
        [currentStoreType]: {
          ...initialBookingState,
        },
      };
    }
    case STORE_BOOKING_SET_STORE_TYPE: {
      const { storeType } = action;

      return {
        ...state,
        currentStoreType: storeType,
      };
    }
    case BOOKING_UPDATE: {
      const { updatedBooking } = action;
      const { currentStoreType } = state;
      const { focusedEventIndex } = state[currentStoreType];
      const focusedEvent = updatedBooking.events ? updatedBooking.events[focusedEventIndex] : null;

      return {
        ...state,
        [currentStoreType]: {
          ...state[currentStoreType],
          storeBooking: updatedBooking,
          focusedEvent,
        },
      };
    }
    case SET_NEXT_TEMP_ID: {
      const { nextTempId } = action;
      const { currentStoreType } = state;

      return {
        ...state,
        [currentStoreType]: {
          ...state[currentStoreType],
          nextTempId,
        },
      };
    }
    case SET_FOCUSED_EVENT_INDEX: {
      const { focusedEventIndex } = action;
      const { currentStoreType } = state;
      const { storeBooking } = state[currentStoreType];
      const focusedEvent = storeBooking.events[focusedEventIndex];

      return {
        ...state,
        [currentStoreType]: {
          ...state[currentStoreType],
          focusedEventIndex,
          focusedEvent,
        },
      };
    }
    case UPDATE_FOCUSED_EVENT: {
      const { focusedEvent } = action;
      const { currentStoreType } = state;
      const { storeBooking, focusedEventIndex } = state[currentStoreType];

      const events = storeBooking.events.filter(event => event.recordStatus === 'Active');

      events[focusedEventIndex] = focusedEvent;

      return {
        ...state,
        [currentStoreType]: {
          ...state[currentStoreType],
          focusedEvent: { ...focusedEvent },
          storeBooking: {
            ...storeBooking,
            events: events,
          },
        },
      };
    }
    case UNAVAILABLE_ROOMS_RECEIVED: {
      const { unavailableRooms } = action;

      return {
        ...state,
        unavailableRooms: unavailableRooms,
      };
    }
    case SET_SHIFT_TYPES: {
      const { shiftTypes, storeDefaultShiftTypes } = action;

      return {
        ...state,
        shiftTypes,
        storeDefaultShiftTypes,
      };
    }
    case STORE_BOOKING_SET_IS_EDITING: {
      const { isEditing } = action;
      const { manager } = state;
      const { storeBooking, storeBookingRef } = manager;

      return {
        ...state,
        manager: {
          ...state.manager,
          isEditing,
          storeBookingRef: isEditing ? _.cloneDeep(storeBooking) : storeBookingRef,
        },
      };
    }
    case STORE_BOOKING_REVERT_CHANGES: {
      const { currentStoreType } = state;
      const { storeBookingRef } = state[currentStoreType];

      return {
        ...state,
        [currentStoreType]: {
          ...state[currentStoreType],
          storeBooking: storeBookingRef,
        },
      };
    }
    default:
      return state;
  };
}
