import React from 'react';
import Modal from 'Components/Modal';
import VenuesSelection from './VenuesSelection';

const SelectVenueModal = ({ onModalClosed, venueConfigId }) => (
  <Modal
    isOpened={true}
    onCancel={onModalClosed}
    title="Select Venues"
    saveText="Select"
    onSave={onModalClosed}
    addTitleBottomBorder={true}
    showCancel={false}
  >
    <VenuesSelection venueConfigId={venueConfigId} storeType="manager" />
  </Modal>);

export default SelectVenueModal;
