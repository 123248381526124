import React from 'react';
import { withStyles, FormControlLabel, Checkbox, Button, ClickAwayListener } from '@material-ui/core';
import classNames from 'classnames';
import {
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
} from '@progress/kendo-react-grid';

const styles = theme => ({
  gridColumnEditMenu: {
    position: 'absolute',
    top: 35,
    right: -56,
    borderRadius: 4,
    '& .k-columnmenu-item': {
      color: theme.palette.grey[400],
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '4px 4px 0 0',
      textAlign: 'center',
      fontSize: 14,
      cursor: 'default',
    },
  },
  columnList: {
    paddingTop: 4,
    backgroundColor: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
  },
  columnListItem: {
    paddingRight: 0,
  },
  columnMenuActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: 4,
    backgroundColor: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '0 0 4px 4px',
  },
  checkBox: {
    padding: '0 6px 0 0',
    marginTop: -2,
  },
  controlLabelRoot: {
    alignItems: 'flex-start',
    width: '100%',
  },
  controlLabel: {
    color: theme.palette.grey[400],
  },
  miniButton: {
    textTransform: 'initial',
  },
});

class GridColumnEditMenu extends React.Component {
  state = {
    columns: this.props.columns || [],
  }

  componentDidUpdate(prevProps) {
    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: this.props.columns });
    }
  }

  onToggleColumn = field => {
    let columns = [...this.state.columns];
    const thisColumnIndex = columns.findIndex(c => c.field === field);

    columns[thisColumnIndex].isVisible = !columns[thisColumnIndex].isVisible;
    this.setState({ columns });
  };

  onReset = () => {
    const { columns } =  this.state;

    const defaultColumns = columns.map(column => {
      return {
        ...column,
        isVisible: column.isDefault,
      };
    });

    this.setState({ columns: defaultColumns }, () => this.onSubmit());
  }

  onSubmit = () => {
    const { columns } = this.state;
    let visibleColumns = [];

    columns.forEach(column => {
      if (column.isVisible) {
        visibleColumns.push(column.field);
      }
    });

    this.props.onColumnsSubmit(visibleColumns);
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  }

  onCancel = () => {
    this.props.onCloseMenu();
  }

  render() {
    const { classes, isMenuVisible, classMods } = this.props;
    const { columns } = this.state;
    const oneVisibleColumn = columns && columns.filter(c => c.isVisible).length === 1;

    return (
      <div className={classNames(classes.gridColumnEditMenu, classMods && classMods.root)}>
        <GridColumnMenuItemGroup>
          <GridColumnMenuItemContent show={isMenuVisible}>
            <GridColumnMenuItem title={'Choose Columns'} />
            <ClickAwayListener onClickAway={this.onCancel}>
              <div className={'k-column-list-wrapper'}>
                <div className={classNames('k-column-list', classes.columnList)}>
                  {columns && columns.map((column, index) =>
                    column.field !== 'selected' &&
                    <div key={index} className={classNames('k-column-list-item', classes.columnListItem)}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={column.isVisible}
                            onChange={() => { this.onToggleColumn(column.field); }}
                            disabled={column.isVisible && oneVisibleColumn}
                            classes={{ root: classes.checkBox }}
                          />
                        }
                        label={column.title}
                        classes={{
                          root: classes.controlLabelRoot,
                          label: classes.controlLabel,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.columnMenuActions}>
                  <Button className={classes.miniButton} mini onClick={this.onReset}>Reset</Button>
                  <Button className={classes.miniButton} mini color="primary" onClick={this.onSubmit}>Save</Button>
                </div>
              </div>
            </ClickAwayListener>
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
      </div>
    );
  }
}

export default withStyles(styles)(GridColumnEditMenu);

