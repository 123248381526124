export const PAYMENT_METHODS = {
  cash: 0,
  check: 1,
  houseAccount: 2,
};

export const PAYMENT_CATEGORIES = {
  initialDeposit: 0,
};

const paymentMethodKeys = Object.keys(PAYMENT_METHODS).reduce(
  (acc, key) => {
    acc[PAYMENT_METHODS[key]] = key;

    return acc;
  },
  {}
);

const paymentCategoryKeys = Object.keys(PAYMENT_CATEGORIES).reduce(
  (acc, key) => {
    acc[PAYMENT_CATEGORIES[key]] = key;

    return acc;
  },
  {}
);

export const getPaymentMethodKeys = paymentMethod => {
  return paymentMethodKeys[paymentMethod] || paymentMethod;
};

export const getPaymentCategoryKeys = paymentCategory => {
  return paymentCategoryKeys[paymentCategory] || paymentCategory;
};
