import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Clear as DeleteIcon } from '@material-ui/icons';
import classNames from 'classnames';
import moment from 'moment';

const styles = theme => ({
  fileContainer: {
    display: 'flex',
    padding: 8,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    minHeight: 56,
  },
  image: {
    width: 30,
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  imageContainer: {
    width: 40,
    textAlign: 'center',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    padding: 2,
    marginTop: 2,
    height: 24,
    width: 24,
  },
  contentColumn: {
    flexGrow: 1,
    padding: '0 8px',
    whiteSpace: 'normal',
  },
  uploadedOn: {
    display: 'flex',
    marginBottom: 2,
    color: theme.palette.grey[200],
  },
  title: {
    color: theme.palette.primary.dark,
  },
  deleteIcon: {
    color: theme.common.softPink,
    verticalAlign: 'top',
    marginLeft: 2,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  shared: {
    borderRadius: '5px',
    padding: '5px',
    minWidth: '78px',
    maxWidth: '78px',
    height: '22px',
    lineHeight: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: theme.common.white,
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'inline-block',
  },
  green: {
    backgroundColor: theme.common.emerald,
  },
  gray: {
    backgroundColor: theme.palette.grey['200'],
  },
});

class File extends Component {

  buildDateLine = () => {
    const { file } = this.props;

    return file.dateAdded ? 'Uploaded on ' + moment(file.dateAdded).format('l') : null;
  }

  onItemDeleted = item => () => {
    this.props.onDelete(item);
  }

  onItemToggleShare = item => () => {
    this.props.onToggleShare(item);
  }

  onItemClicked = file => () => {
    fetch(file.url)
      .then(r => r.blob())
      .then(blob => {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      });
  }

  getFileTypeSvg = fileName => {
    if (fileName && fileName.length) {
      const lowerFileName = fileName.toLowerCase();
      const ext = lowerFileName.split('.')[lowerFileName.split('.').length - 1];

      if (ext === 'docx' || ext === 'doc') {
        return '/icons/doctype/word.svg';
      } else if (ext === 'xlsx' || ext === 'xls') {
        return '/icons/doctype/excel.svg';
      } else if (ext === 'pptx' || ext === 'ppt') {
        return '/icons/doctype/ppt.svg';
      } else if (ext === 'pdf') {
        return '/icons/doctype/pdf.svg';
      } else if (ext === 'txt') {
        return '/icons/doctype/txt.svg';
      } else if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'tiff' || ext === 'bmp') {
        return '/icons/doctype/image.svg';
      }
    }

    return '/icons/doctype/unknown.svg';
  }

  render() {
    const { classes, file } = this.props;
    const dateLine = file.id !== -1 ? this.buildDateLine() : 'Edit to upload with new image';

    const text = file.shared ? 'Shared' : 'Not Shared';
    const style = file.shared ? classes.green : classes.gray;

    const fileTypeSvg = this.getFileTypeSvg(file.name);

    return (
      <div className={classes.fileContainer}>
        <div className={classes.iconBox}>
          <div className={classes.imageContainer}>
            <img src={fileTypeSvg} alt="logo" className={classes.image} onClick={this.onItemClicked(file)} />
          </div>
        </div>
        <div className={classes.contentColumn}>
          <div className={classNames(classes.title)}><div className={classes.pointer} onClick={this.onItemClicked(file)}>{file.name}</div></div>
          {dateLine && <p className={classes.uploadedOn}>
            {dateLine}
          </p>}
        </div>
        {this.props.onToggleShare &&
          <div className={classNames(classes.shared, classes.pointer, style)} onClick={this.onItemToggleShare(file)}>{text}</div>
        }
        {file.id !== -1 &&
          <div>
            <DeleteIcon classes={{ root: classes.deleteIcon }} onClick={this.onItemDeleted(file)} />
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(File);
