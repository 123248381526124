import {
  PICKLISTS_RECEIVED,
  PICKLISTS_GROUPS_RECEIVED,
} from 'actions/constants';

import { ConvertArrayToDictionary } from 'helpers/dictionary';

const initialState = {
  picklists: [],
  pickListDictionary: {},
  picklistGroups: [],
};

export function pickList(state = initialState, action) {
  switch (action.type) {
    case PICKLISTS_RECEIVED: {
      const { picklists } = action;
      const pickListDictionary = ConvertArrayToDictionary(picklists, 'name');

      return {
        ...state,
        picklists,
        pickListDictionary,
      };
    }
    case PICKLISTS_GROUPS_RECEIVED: {
      const { picklistGroups } = action;

      return {
        ...state,
        picklistGroups,
      };
    }
    default:
      return state;
  }
}
