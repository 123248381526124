import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  linkCell: {
    '&:hover': {
      color: theme.palette.primary.dark,
      textDecoration: 'underline !important',
      cursor: 'pointer',
    },
  },
});

export const buildGridCellLink = ({ url, text }) => {
  const NameLinkCell = ({ classes, dataItem }) => (
    <td>
      <Link className={classes.linkCell} to={url(dataItem)}>
        {text(dataItem)}
      </Link>
    </td>
  );

  return withStyles(styles)(NameLinkCell);
};

export const buildGridCellModalLink = ({ text, onOpenModal }) => {
  const NameModalCell = ({ classes, dataItem, dataIndex }) => {
    const onClick = () => onOpenModal(dataItem, dataIndex);

    return (
      <td>
        <span className={classes.linkCell} onClick={onClick}>
          {text(dataItem)}
        </span>
      </td>
    );
  };

  return withStyles(styles)(NameModalCell);
};

export const buildGridCellModalLinkByIndex = ({ text, onOpenModal }) => {
  const NameModalCell = ({ classes, dataItem, dataIndex }) => {
    const onClick = () => onOpenModal(dataItem, dataIndex);

    return (
      <td>
        <span className={classes.linkCell} onClick={onClick}>
          {text(dataItem)}
        </span>
      </td>
    );
  };

  return withStyles(styles)(NameModalCell);
};
