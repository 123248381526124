import React, { Component } from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
  TextField,
  Paper,
} from '@material-ui/core';
import {
  Dehaze,
  Clear as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import CheckBoxOnSortable from './CheckBoxOnSortable';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const styles = theme => ({
  pickList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 'unset',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing.unit,
    boxShadow: 'none',
    '&:first-child': {
      borderRadius: '4px 4px 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 4px 4px',
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '&:hover $crudIcons': {
      visibility: 'visible',
    },
  },
  crudIcons: {
    visibility: 'hidden',
  },
  inputFocused: {
    fontSize: '18px',
    color: theme.palette.grey[700],
  },
  textHolder: {
    userSelect: 'none',
    width: '230px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'middle',
    fontSize: '18px',
    color: theme.palette.grey[700],
  },
  modifyIcon: {
    marginRight: '7px',
    marginLeft: '3px',
    color: theme.palette.grey[700],
    fontSize: '26px',
    cursor: 'pointer',
  },
});

const dragHandleStyle = theme => ({
  dragHandle: {
    marginTop: '2.5px',
    color: theme.palette.grey[700],
    cursor: 'grab',
  },
});

const DragHandle = SortableHandle(withStyles(dragHandleStyle)(({ classes }) => <Dehaze className={classes.dragHandle} />));

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      value: {
        ...props.value,
      },
    };

    this.inFrontOfModal = {
      zIndex: props.theme.zIndex.modal + 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.value, prevProps.value)) {
      this.setState({
        value: {
          ...this.props.value,
        },
      });
    }
  }

  startEditing = () => {
    this.setState({ isEditing: true });
  }

  onChange = event => {
    const value = {
      ...this.state.value,
      name: event.target.value,
    };

    this.setState({ value });
  }

  editingDone = () => {
    this.setState({ isEditing: false });

    if (_.isEqual(this.state.value, this.props.value)) {
      return;
    }

    //flush the new name back up
    this.props.onEdit(this.state.value);
  }

  delete = () => {
    const {
      value: { id },
      onDelete,
    } = this.props;

    onDelete(id);
  }

  handleCheckbox = event => {
    const value = {
      ...this.state.value,
      checked: event.target.checked,
    };

    this.setState({ value });
    this.props.onEdit(value);
  }

  enterPressed = event => {
    if (event.key !== 'Enter') {
      return;
    }
    this.editingDone();
  }

  render() {
    const {
      isEditing,
      value,
    } = this.state;

    const {
      classes,
      useCheckBox,
    } = this.props;

    return (
      <Paper className={classes.pickList} style={this.inFrontOfModal}>
        {isEditing ? (
          <TextField
            autoFocus
            fullWidth
            onChange={this.onChange}
            name="name"
            value={value.name}
            onBlur={this.editingDone}
            margin="none"
            InputProps={{
              classes: {
                focused: classes.inputFocused,
              },
            }}
            onKeyPress={this.enterPressed}
          />) : (<>
            <DragHandle />
            {useCheckBox ?
              <CheckBoxOnSortable
                value={value}
                onChange={this.handleCheckbox}
              /> :
              <span className={classes.textHolder}>
                {value.name}
              </span>
            }
            <div className={classes.crudIcons}>
              <EditIcon onClick={this.startEditing} className={classes.modifyIcon} />
              <DeleteIcon onClick={this.delete} className={classes.modifyIcon} />
            </div>
          </>)}
      </Paper>
    );
  }
}

export default SortableElement(withStyles(styles, { withTheme: true })(Item));
