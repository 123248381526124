import React from 'react';
import ReactDOM from 'react-dom';
import loadingdots from 'shared/icons/loadingdots.svg';

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(242, 242, 242, 0.5)' }}>
      <div style={{ width: '100px', display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <div style={{ color: '#002257', fontSize: '18px', padding: '5px' }}>Loading</div>
        <div><img src={loadingdots} alt="loading..." /></div>
      </div>
    </div>
  </div>
);

export default function DataLoader({ isLoading, inline }) {
  if (!isLoading) {
    return null;
  }
  if (inline) {
    return loadingPanel;
  }
  const gridContent = document && document.querySelector('.k-grid-content');

  if (gridContent) {
    return ReactDOM.createPortal(loadingPanel, gridContent);
  }

  return loadingPanel;
}
