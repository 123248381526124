import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from 'Components/Table';
import { SortableContainer } from 'react-sortable-hoc';
import TaxTableRow from './TaxTableRow';

const SortableList = SortableContainer(({ revenueTypeRates, onNameChange, onPercentageFieldChange, onItemDelete, activeId, setErrors }) => {
  return (
    <tbody>
      {revenueTypeRates.filter(rate => rate.recordStatus !== "Deleted").map((taxConfigType, index) =>
        <TaxTableRow
          key={`revenue-type-${taxConfigType.id}`}
          index={index}
          taxConfigType={taxConfigType}
          onItemEdit={onNameChange}
          onPercentageFieldChange={onPercentageFieldChange}
          onItemDelete={onItemDelete}
          isAutoFocused={activeId === taxConfigType.taxTypeId}
          setErrors={setErrors}
        />
      )}
    </tbody>
  );
});

const styles = theme => ({
  table: {
    borderColor: theme.palette.grey[50],
    '& tr': {
      borderTop: 'none',
    },
    '& th': {
      textAlign: 'center',
      maxWidth: 200,
    },
    '& td': {
      borderWidth: 1,
      textAlign: 'center',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
    '& tbody th': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
  },
});
const smallColumn = { width: 60 };

const AdminTaxRatesTable = ({ classes, revenueTypeRates, onNameChange, onPercentageFieldChange, onItemDelete, onSwapOrder, activeId, setErrors }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    onSwapOrder(oldIndex, newIndex);
  };

  return (
    <Table
      className={classes.table}
      wrapWithTbody={false}
      header={
        <tr className={classes.headerRow}>
          <th scope="col" style={smallColumn}>Order</th>
          <th scope="col">Type</th>
          <th scope="col" style={smallColumn}>Tax</th>
          <th scope="col" style={smallColumn}>Service</th>
          <th scope="col" style={smallColumn}>Gratuity</th>
        </tr>
      }
    >
      {revenueTypeRates && (
        <SortableList
          revenueTypeRates={revenueTypeRates}
          onNameChange={onNameChange}
          onPercentageFieldChange={onPercentageFieldChange}
          onItemDelete={onItemDelete}
          onSortEnd={onSortEnd}
          lockAxis="y"
          useDragHandle={true}
          activeId={activeId}
          setErrors={setErrors}
        />
      )}
    </Table>
  );
};

export default withStyles(styles)(AdminTaxRatesTable);
