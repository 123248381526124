export const guestRole = 'guest';
export const userRole = 'user';
export const subscriptionOwnerRole = 'subscription owner';
export const adminRole = 'admin';
export const trainerRole = 'trainer';

export function userIsInRole(user, roleName) {
  const normalizedRole = roleName.toLowerCase();

  if (user.profile && user.profile.role) {
    if (Array.isArray(user.profile.role)) {
      return user.profile.role.findIndex(userRole => normalizedRole === userRole.toLowerCase()) > -1;
    }

    return user.profile.role.toLowerCase() === normalizedRole;
  }

  return false;
}

export function ableToResetDatabase(user) {
  return (userIsInRole(user, adminRole) || userIsInRole(user, trainerRole))
    && user.profile.organization_resettable && user.profile.organization_resettable.toLowerCase() === 'true'
    && user.profile.organization_internal && user.profile.organization_internal.toLowerCase() === 'true';
}

export function userLocations(user) {
  if (user.profile && user.profile.locations) {
    const locations = JSON.parse(user.profile.locations);

    return locations;
  }

  const locations = {};

  locations[user.profile["organization_id"]] = user.profile["organization_name"];

  return locations;
}
