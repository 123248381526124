import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PickList from 'Components/PickList';
import { connect } from 'react-redux';
import PicklistOptionsTable from './PicklistOptionsTable';
import { Add } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import ApplyToLocation from 'Components/ApplyToLocation';
import { getLocations } from 'actions/location';
import { savePicklist, deletePicklistOption } from 'actions/pickList';

const styles = theme => ({ //TODO fix scroll
  manager: {
    display: 'flex',
  },
  quickListCategories: {
    width: 222,
    display: 'inline-block',
    verticalAlign: 'top',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  quickPicksContainer: {
    width: 460,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  buttons: {
    verticalAlign: 'top',
    width: 250,
    height: 32,
    paddingLeft: 50,
    paddingTop: 35,
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  programArea: {
    fontSize: 16,
    padding: 3,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[50],
  },
});

class PicklistManager extends Component {
  state = {
    applyLocationOpened: false,
  };

  selectPickList = pickList => {
    this.setState({
      selectedPicklist: pickList,
    });
  }

  addItem = async () => {
    const {
      selectedPicklist,
    } = this.state;

    const newPickListOptions = [
      ...selectedPicklist.pickListOptions,
      { name: 'New Option', sortOrder: selectedPicklist.pickListOptions.length * 100 },
    ];

    const savedPickList = await this.props.savePicklist({ ...selectedPicklist, pickListOptions: newPickListOptions });

    this.setState({ selectedPicklist: savedPickList });
  }

  onSort = async sortedItemsArray => {
    const {
      selectedPicklist,
    } = this.state;

    const updatedPickListOptions = sortedItemsArray.map((option, index) => ({ ...option, sortOrder: index * 100 }));

    const savedPickList = await this.props.savePicklist({ ...selectedPicklist, pickListOptions: updatedPickListOptions });

    this.setState({ selectedPicklist: savedPickList });
  }

  onItemDelete = async item => {
    const {
      selectedPicklist,
    } = this.state;

    const updatedPicklistOptions = selectedPicklist.pickListOptions.filter((status, index) => index !== item.index);

    this.setState({ selectedPicklist: { ...selectedPicklist, pickListOptions: updatedPicklistOptions } });

    if (item.id) {
      await this.props.deletePicklistOption(item);
    }

    this.onSort(updatedPicklistOptions);
  }

  onItemEdit = async editedItem => {
    const {
      selectedPicklist,
    } = this.state;

    const updatedPickListOptions = [ ...selectedPicklist.pickListOptions ];

    updatedPickListOptions[editedItem.index] = editedItem;

    const savedPicklist = await this.props.savePicklist({ ...selectedPicklist, pickListOptions: updatedPickListOptions });

    this.setState({ selectedPicklist: savedPicklist });
  }

  closeApplyToLocation = payLoad => {
    if (!payLoad) {
      this.setState({ applyLocationOpened: false });

      return;
    }

    this.state.quickPickService.applyToLocations(payLoad).then(() => {
      this.setState({ applyLocationOpened: false });
    });
  }

  openApplyToLocation = () => {
    this.setState({ applyLocationOpened: true });
  }

  render() {
    const {
      classes,
      locationId,
    } = this.props;

    const {
      selectedPicklist,
      selectedListId,
      applyLocationOpened,
    } = this.state;

    return (
      <div className={classes.manager}>
        <div className={classes.quickListCategories}>
          <div className={classes.programArea}>Program Area</div>
          <PickList
            selectedListId={selectedListId}
            onPickListPicked={this.selectPickList}
          />
        </div>
        <div className={classes.quickPicksContainer}>
          <PicklistOptionsTable
            pickList={selectedPicklist}
            onItemDelete={this.onItemDelete}
            onItemEdit={this.onItemEdit}
            onSort={this.onSort}
          />
        </div>
        <div className={classes.buttons}>
          <HighlightButton
            disabled={!selectedPicklist}
            onClick={this.addItem}
          >
            <Add />
          </HighlightButton>
          {!locationId && <HighlightButton
            onClick={this.openApplyToLocation}
            disabled={!selectedPicklist}
          >
            Apply to Location
          </HighlightButton>}

        </div>
        <ApplyToLocation
          isOpened={applyLocationOpened}
          onClose={this.closeApplyToLocation}
          getLocations={this.props.getLocations}
        />
      </div>);
  }
}

const mapDispatchToProps =
{
  getLocations,
  savePicklist,
  deletePicklistOption,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(PicklistManager));
