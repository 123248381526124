import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import {
  Card,
  CardMedia,
} from '@material-ui/core';
import {
  Clear as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import Avatar from 'Components/Avatar';

const thumbnailWidth = 85;
const styles = theme => ({
  root: {
    display: 'flex',
    color: theme.palette.text.primary,
    margin: 8,
    '&:hover $crudIcons': {
      visibility: 'visible',
    },
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 8,
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 54px)', // subtract width of crudIcons container
  },
  name: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  description: {
    whiteSpace: 'normal',
    marginTop: 8,
  },
  descriptionText: {
    overflow: 'hidden',
    position: 'relative',
    textOverflow: 'ellipsis',
  },
  thumbnail: {
    display: 'flex',
    width: thumbnailWidth,
    height: thumbnailWidth,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  crudIcons: {
    visibility: 'hidden',
    marginLeft: -56,
    padding: 1,
    backgroundColor: fade(theme.palette.common.white, 0.5),
    height: 27,
    borderRadius: '0 0 0 4px',
  },
  modifyIcon: {
    color: theme.palette.grey[900],
    fontSize: 26,
    cursor: 'pointer',
  },
  edit: {
    '&:hover': {
      color: theme.palette.grey[700],
    },
  },
  delete: {
    '&:hover': {
      color: theme.common.red,
    },
  },
});

const MenuCategoryItemCard = ({
  classes,
  menuCategoryItem,
  onMenuItemClick,
  onEdit,
  onDelete,
  index,
  provided,
  formatter,
}) => {
  const draggableMenuCategoryItemId = `menuCategoryItem${menuCategoryItem.id.toString()}`;
  const formattedPrice = formatter ? formatter.formatNumber(menuCategoryItem.price, 'c') : menuCategoryItem.price;

  return (
    <Draggable
      draggableId={draggableMenuCategoryItemId}
      index={index}
      key={menuCategoryItem.id}
    >
      {provided => (

        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...provided.droppableProps}
        >
          <Card
            className={classes.root}
            onClick={onMenuItemClick}
          >
            <div className={classes.thumbnail}>
              {!!menuCategoryItem.imageUrl ?
                <CardMedia
                  style={{ width: thumbnailWidth, height: thumbnailWidth }}
                  image={menuCategoryItem.imageUrl}
                />
                :
                <Avatar name={menuCategoryItem.name}/>
              }
            </div>
            <div className={classes.content}>
              <div className={classes.topRow}>
                <p className={classes.name}>{menuCategoryItem.name}</p>
                <p className={classes.price}>{formattedPrice}</p>
              </div>
              <div className={classes.description}>
                <p className={classes.descriptionText} dangerouslySetInnerHTML={{ __html: menuCategoryItem.description }}></p>
              </div>
            </div>

            <div className={classes.crudIcons}>
              <EditIcon onClick={onEdit} className={classNames(classes.modifyIcon, classes.edit)} />
              <DeleteIcon onClick={onDelete} className={classNames(classes.modifyIcon, classes.delete)} />
            </div>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default withStyles(styles)(MenuCategoryItemCard);
