import { callApi } from 'shared/CallApi';
import { setupStylesReceived, roomsReceived, parentRoomsReceived, unavailableRoomsReceived } from './api';
import moment from 'moment';

export const getRooms = () => dispatch =>
  dispatch(callApi('Room'))
    .then(results => results.json())
    .then(rooms => {
      dispatch(roomsReceived(rooms));

      return rooms;
    })
    .catch(console.error);

export const getParentRooms = () => dispatch =>
  dispatch(callApi('Room?$filter=ParentRoomId%20eq%20null'))
    .then(results => results.json())
    .then(rooms => {
      dispatch(parentRoomsReceived(rooms));

      return rooms;
    })
    .catch(console.error);

export const getAllSetupStyles = () =>
  dispatch =>
    dispatch(callApi('SetupStyle'))
      .then(results => results.json())
      .then(results => {
        dispatch(setupStylesReceived(results));

        return results;
      })
      .catch(console.error);

/*.estimatedGuestCount, event.venueSetupStyleId,
      moment(event.startDateTime).add(-event.venueSetupTimeInMinutes, 'minutes').format(),
      moment(event.endDateTime).add(event.venueTearDownTimeInMinutes, 'minutes').format() */

export const getUnavailableRooms = event =>
  dispatch =>
    dispatch(callApi(`Room/Availability?GuestCount=${event.estimatedGuestCount}&SetupStyleId=${event.venueSetupStyleId}&StartDateTime=${moment(event.startDateTime).add(-event.venueSetupTimeInMinutes, 'minutes').format()}&EndDateTime=${moment(event.endDateTime).add(event.venueTearDownTimeInMinutes, 'minutes').format()}`))
      .then(results => {
        if (results.ok) { return results.json(); }

        return null;
      })
      .then(results => {
        dispatch(unavailableRoomsReceived(results));

        return results;
      })
      .catch(console.error);
