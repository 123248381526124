import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import GeneralFields from './GeneralFields';
import BlueUnderlineTab from 'Components/Tabs/BlueUnderlineTab';
import TabContainer from 'Components/TabContainer';
import NotesPanel from 'Components/NotesPanel';
import FilesPanel from 'Components/FilesPanel';

const styles = theme => ({
  leadNotesTabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  contentArea: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabsWrapper: {
    height: 40,
  },
});

const tabs = ['Details', 'Notes', 'Files'];

class LeadSideTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }

  handleChange = tabIndex => {
    this.setState({ tabIndex });
  };

  render() {
    const { classes } = this.props;
    const { tabIndex } = this.state;

    return (
      <Paper className={classes.leadNotesTabsRoot}>
        <div className={classes.tabsWrapper}>
          <BlueUnderlineTab
            onTabChange={this.handleChange}
            tabs={tabs}
            tabIndex={tabIndex}
          />
        </div>
        <div className={classes.contentArea}>
          {tabIndex === 0 && <GeneralFields />}
          {tabIndex === 1 && <TabContainer><NotesPanel displayAddButton={true} /></TabContainer>}
          {tabIndex === 2 && <TabContainer><FilesPanel /></TabContainer>}
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(LeadSideTabs);
