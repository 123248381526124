import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid as KendoGrid, GridColumn as Column } from '@progress/kendo-react-grid';
import SelectedColumn from 'Components/Grid/SelectedColumn';
import SmallGrid from 'Components/Grid/SmallGrid';

const styles = () => ({
  kendoGrid: {
    height: '100%',
    overflow: 'hidden',
  },
});
// const sortable = {
//   mode: 'single',
// };

export class List extends Component {
  onItemSelected = item => () => {
    this.props.onItemSelected(item);
  }

  onSelectionChange = event => {
    this.props.onItemSelected(event.dataItem);
  }

  onHeaderSelectionChange = event => {
    const checked = event.syntheticEvent.target.checked;

    this.props.onSelectAll(checked);
  }

  onSortChange = event => {
    this.props.onSortChange(event.sort);
  }

  render() {
    const {
      classes,
      menuItems,
      // sort, //TODO sorting
    } = this.props;

    return (
      <SmallGrid wrappedGrid={
        <KendoGrid
          data={menuItems}
          className={classes.kendoGrid}
          selectedField="selected"
          onSelectionChange={this.onSelectionChange}
          onHeaderSelectionChange={this.onHeaderSelectionChange}
          // sortable={sortable}
          // sort={sort}
          // onSortChange={this.onSortChange}
        >
          {SelectedColumn(menuItems)}
          <Column field="name" title="Item" />
          <Column field="cost" title="Cost" format="{0:c}" />
          <Column field="price" title="Price" format="{0:c}" />
          <Column field="calories" title="Calories" editor="numeric" />
          <Column field="allergens" title="Allergens" />
        </KendoGrid>

      } />
    );
  }
}

export default withStyles(styles)(List);
