import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SiteSelection from './SiteSelection';

const styles = () => ({
  overflowIfTooBig: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    paddingTop: 16,
  },
  grid: {
    height: 325,
  },
  mapContainerOverride: {
    minHeight: 300,
  },
});

export class SiteLocationModal extends Component {
  render() {
    const { classes, onModalClosed, onCancel } = this.props;

    return (
      <Modal
        isOpened={true}
        onCancel={onCancel}
        title="Select New Site Location"
        saveText="Confirm"
        onSave={onModalClosed}
        addTitleBottomBorder={true}
        dimensions={{ width: '85%', height: '80%', maxWidth: '1287px', maxHeight: '832px' }}
      >
        <div className={classes.overflowIfTooBig}>
          <SiteSelection
            classes={{ mapContainer: classes.mapContainerOverride }}
            showWebsite={true}
            storeType="manager"
          />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(SiteLocationModal);
