import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { bindActionCreators } from 'redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';

import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import * as adminActions from 'actions';
import CompanyBullet from './companyBullet';
import Hero from './Hero';
import BottomSubContent from './BottomSubContent';
import Grid from '@material-ui/core/Grid';

const styles = theme => (
  {
    companyWhiteBar: {
      backgroundColor: 'white',
      padding: '25px 25px 0px 25px',
      whiteSpace: 'normal', //white-space:'nowrap' is coming from some where,
      display: 'flex',
    },
    whiteButton: {
      padding: '1px 14px',
      margin: '5px',
      marginRight: '20px',
    },
    dropbutton: {
      padding: 0,
      minWidth: '45px',
      marginRight: '10px',
      borderLeft: 'none',
      borderRadius: '0px 4px 4px 0px',
    },
    editbutton: {
      minWidth: '45px',
      marginRight: '0px',
      borderRadius: '4px 0px 0px 4px',
      borderRight: 'none',
    },
    buttons: {
      borderRight: 'none',
      borderLeft: 'none',
      borderRadius: '0px 0px 0px 0px',
    },
    allButtons: {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      textTransform: 'none',
      height: '36px',
    },
    marginTop: {
      marginTop: 20,
    },
  });

export class Franchise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translations: {},
    };
  }

  componentDidMount() {
    const translations = this.getTranslations();

    this.setState({ translations });
  }

  getTranslations() {
    const localizationService = provideLocalizationService(this);

    return {
      details: localizationService.toLanguageString('franchise.details'),
      label: localizationService.toLanguageString('franchise.label'),
    };
  }

  render() {
    const { translations } = this.state;
    const { franchise, theme, classes } = this.props;

    return <div style={{ widht: '100%' }}>
      <Hero
        iconColor={theme.palette.error.main}
        label={translations.details}
        text={franchise.name}
        backgroundColor={theme.palette.grey[100]}
        color={theme.palette.secondary.main}
      >
        <Button variant="outlined" mini className={classNames(classes.whiteButton,classes.allButtons)} component={Link} to="/admin/company/locations">
            Locations
        </Button>
        <Button variant="outlined" mini className={classNames(classes.editbutton,classes.allButtons)}>
            Edit
        </Button>
        <Button variant="outlined" mini className={classNames(classes.buttons,classes.allButtons)}>
            Delete
        </Button>
        <Button variant="outlined" mini className={classNames(classes.buttons,classes.allButtons)}>
            Clone
        </Button>
        <Button variant="outlined" mini className={classNames(classes.dropbutton,classes.allButtons)}>
          <ArrowDownIcon style={{ margin: 0 }}/>
        </Button>
      </Hero>
      <div className={classes.companyWhiteBar}>
        <Grid container spacing={12}>
          <Grid item xs={3}>
            <CompanyBullet
              label={"Address"}
              line1={franchise.name}
              line2={franchise.address}
            />
          </Grid>
          <Grid item xs={3}>
            <CompanyBullet
              label="Region"
              line1="Mountain"
              line2="UTC -07:00"
            />
          </Grid>
          <Grid item xs={3}>
            <CompanyBullet
              label="Primary Contact"
              line1="Jeff Foxworthy"
              line2="General Manager"
            />
          </Grid>
          <Grid item xs={3}>
            <CompanyBullet
              label="Contact Information"
              line1="555-555-5555"
              line2="denver@ruthchris.com"
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.companyWhiteBar} style={{ paddingTop: 0 }}>
        <Grid container spacing={12}>
          <Grid item xs={3}>
            <CompanyBullet
              label="Services Available"
              line1="Delivery, Catering, On Premise, Off Premise, Internal Orders, Corporate, Birthday Parties, Private Functions"
            />
          </Grid>
          <Grid item xs={3}>
            <CompanyBullet
              label="Private Space Size"
              line1="1000 sqft"
              line2="22 ppl"
            />
          </Grid>
          <Grid item xs={3}>
            <CompanyBullet
              label="Secondary Contact"
              line1="Bill Nye"
              line2="Assistant Manager"
            />
          </Grid>
        </Grid>
      </div>

      {/* <ButtonBar tab1={translations.label} tab2="Enterprise" />  TODO: Hidden for BETA */}
      <div className={classes.marginTop}>
        <BottomSubContent />
      </div>
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    franchise: {
      name: 'Greater Denver Franchise',
      address: '123 Main St.',
    },
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(adminActions, dispatch);
};

Franchise.propTypes = {
  classes: PropTypes.object.isRequired,
};

registerForLocalization(Franchise);
export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(Franchise)));
