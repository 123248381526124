import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  Collapse,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import SaveBar from 'Components/SaveBar';
import CheckBox from 'Components/Checkbox';
import TextField from 'Components/TextField';
import {
  saveEmailAccount,
  createEmailAccount,
  deleteEmailAccount,
} from 'actions/emailAccounts';

const styles = theme => ({
  emailAccount: {
    flex: 1,
    margin: '16px 0',
  },
  saved: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
  },
  tools: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 60,
  },
  text: {
    display: 'flex',
    flex: 1,
    fontSize: 14,
    fontWeight: 500,
    width: 180,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  field: {
    width: '25%',
  },
  checkBox: {
    width: '50%',
    marginLeft: 16,
    '& > span': {
      paddingLeft: 0,
    },
  },
  checkboxes: {
    display: 'flex',
    width: '50%',
    alignItems: 'flex-end',
  },
  halfWidth: {
    display: 'flex',
    width: '50%',
  },
  icon: {
    cursor: 'pointer',
  },
  property: {
    flex: 2,
    marginRight: 16,
  },
  hr: {
    margin: '8px 0',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
  },
  saveBar: {
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    height: 50,
  },
});

const EmailAccount = ({
  classes,
  emailAccount,
  saveEmailAccount,
  createEmailAccount,
  deleteEmailAccount,
  onCancelUnsaved,
}) => {
  const [isExpanded, setIsExpanded] = useState(emailAccount.isNew);
  const [emailAccountEdit, setEmailAccountEdit] = useState(emailAccount);
  const [emailAccountSaved, setEmailAccountSaved] = useState(emailAccount);
  const [isEmailValid, setEmailValid] = useState(emailAccount.isNew || !!emailAccount.senderEmailAddress);

  useEffect(() => {
    setEmailAccountEdit(emailAccount);
  }, [emailAccount]);

  useEffect(() => {
    setEmailAccountSaved(emailAccount);
  }, [emailAccount]);

  const onSave = () => {
    if (emailAccountEdit.id > 0) {
      saveEmailAccount(emailAccountEdit).then(emailAccount => {
        if (emailAccount.id) setEmailAccountSaved(emailAccount);
      // else show error dialog TODO
      });
    } else {
      emailAccount.id = 0;
      createEmailAccount(emailAccountEdit).then(emailAccount => {
        if (emailAccount.id) setEmailAccountSaved(emailAccount);
      // else show error dialog TODO
      });
    }
    setIsExpanded(false);
  };

  const onCancelEdit = () => {
    setEmailAccountEdit(emailAccount);
    setIsExpanded(false);
    if (emailAccountEdit.id < 0) onCancelUnsaved(emailAccount.id);
  };

  const handleFieldChange = fieldName => value => {
    const updatedEmailAccountEdit = {
      ...emailAccountEdit,
      [fieldName]: value,
    };

    setEmailAccountEdit(updatedEmailAccountEdit);
  };

  const isSaveDisabled = () => {
    return !emailAccountEdit.smtpHost || !emailAccountEdit.smtpPort || !emailAccountEdit.senderEmailAddress;
  };

  const handleDelete = () => {
    return deleteEmailAccount(emailAccountSaved);
  };

  const validateEmail = value => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isValid = !!value && re.test(String(value).toLowerCase());

    setEmailValid(isValid);
  };

  return (
    <Card className={classes.emailAccount}>
      <div className={classes.saved}>
        <div className={classes.text}>
          <span className={classes.property}>{emailAccountSaved.name}</span>
          <span className={classes.property}>{emailAccountSaved.senderEmailAddress}</span>
        </div>
        <div className={classes.tools}>
          <EditIcon className={classes.icon} onClick={() => setIsExpanded(!isExpanded)} />
          <DeleteIcon className={classes.icon} onClick={() => handleDelete()} />
        </div>
      </div>

      <Collapse in={isExpanded} unmountOnExit>
        <hr className={classes.hr} />
        <div className={classes.form}>
          <div className={classes.row}>
            <TextField
              className={classes.field}
              label="Name"
              value={emailAccountEdit.name}
              onFieldChange={handleFieldChange('name')}
            />
            <TextField
              className={classes.field}
              label="Sender Email Address"
              value={emailAccountEdit.senderEmailAddress}
              onFieldChange={handleFieldChange('senderEmailAddress')}
              onBlur={() => validateEmail(emailAccountEdit.senderEmailAddress)}
              error={!isEmailValid}
            />
            <TextField
              className={classes.field}
              label="SMTP Host"
              value={emailAccountEdit.smtpHost}
              onFieldChange={handleFieldChange('smtpHost')}
            />
            <TextField
              className={classes.field}
              label="SMTP Port"
              value={emailAccountEdit.smtpPort}
              onFieldChange={handleFieldChange('smtpPort')}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.halfWidth}>
              <TextField
                className={classes.halfWidth}
                label="Username"
                value={emailAccountEdit.username}
                onFieldChange={handleFieldChange('username')}
              />
              <TextField
                className={classes.halfWidth}
                label="Password"
                value={emailAccountEdit.password}
                onFieldChange={handleFieldChange('password')}
              />
            </div>
            <div className={classes.checkboxes}>
              <CheckBox
                className={classes.checkBox}
                label="Use SSL"
                checked={emailAccountEdit.useSsl}
                onFieldChange={handleFieldChange('useSsl')}
              />
              <CheckBox
                className={classes.checkBox}
                label="Default"
                checked={emailAccountEdit.default}
                onFieldChange={handleFieldChange('default')}
              />
            </div>
          </div>
        </div>
        <SaveBar className={classes.saveBar} onSave={onSave} onCancel={onCancelEdit} isSticky={true} isSaveDisabled={isSaveDisabled()} />
      </Collapse>
    </Card>
  );
};

const mapDispatchToProps = {
  saveEmailAccount,
  createEmailAccount,
  deleteEmailAccount,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(EmailAccount));
