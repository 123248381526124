import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SectionHeader from 'Components/SectionHeader';
import TextField from 'Components/TextField';
import CoverDateTimePicker from 'Components/CoverDateTimePicker';
import RichTextField from 'Components/RichTextField';
import SimpleDialog from 'Components/SimpleDialog';
import { saveEventTime } from 'actions/booking';
import { connect } from 'react-redux';
import moment from 'moment';

const styles = () => ({

  formSection: {
    flexGrow: 1,
    padding: '16px 33px 0px 33px',
  },
  topSectionContainer: {
    padding: '0 24px 20px 24px',
  },
  columnLeft: {
    paddingRight: 24,
  },
  fieldsRowContainer: {
    display: 'flex',
  },
  timesContainer: {
    padding: '0 16px 0px 16px',
    display: 'flex',
  },
});

export class AddEditTimeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: { ...props.time },
      hasErrors: {},
    };
    this.froalaConfig = {
      zIndex: 1301,
      heightMax: 250,
      toolbarButtons: ['bold', 'italic', 'underline', 'textColor', 'align'],
    };
  }

  errorModal = React.createRef();

  componentDidMount() {
    this.setState({ time: { ...this.props.time } });
  }

  componentDidUpdate(prevProps) {
    if (this.props.time !== prevProps.time) {
      this.setState({ time: { ...this.props.time } });
    }
  }

  formatUpdatedTime = time => {
    time.startTime = moment(time.startTime);
    time.endTime = moment(time.endTime);

    return time;
  }

  save = time => {
    const updatedTime = this.formatUpdatedTime(time);

    this.props.saveEventTime(this.props.bookingId, this.props.bookingEvent.id, updatedTime)
      .then(this.props.onCancel);
  }

  onFieldChange = fieldName => value => {
    this.setState({
      time: {
        ...this.state.time,
        [fieldName]: value,
      },
    });
  }

  handleDateTimeChange = fieldName => changedEventArgs => {
    if (!changedEventArgs) {
      return;
    }

    let newTime =  {
      ...this.state.time,
      [fieldName]: changedEventArgs.value,
    };

    this.setState({ time: newTime });
  }

  onCancel = () => this.props.onModalClosed;

  onSave = () => {
    const errors = this.checkErrors();

    if (!errors.length) {
      this.save(this.state.time);

      return;
    }
    let hasErrors = {};

    errors.forEach(error => {
      hasErrors[error.field] = true;
    });
    this.setState({ hasErrors });

    if (errors.length === 1) {
      this.errorModal.current.open(errors[0].error);

      return;
    }

    this.errorModal.current.open('Please fix the errors before continuing.');
  }

  checkErrors = () => {
    const errors = [];

    const { time:
      {
        title,
        startTime,
        endTime,
      },
    } = this.state;

    if (!title) {
      errors.push({ field: 'title', error: 'Additional Time is required!' });
    }
    if (!startTime) {
      errors.push({ field: 'startTime', error: 'A valid Start Time is required!' });
    }

    if (startTime && endTime) {
      const momentStart = moment(startTime);
      const momentEnd = moment(endTime);

      if (momentEnd.isBefore(momentStart)) {
        errors.push({ field: 'endTime', error: 'End Time must be after Start Time!' });
      }
    }

    return errors;
  }

  render() {
    const { classes, onCancel } = this.props;
    const {
      time: {
        title,
        startTime,
        endTime,
        note,
      },
      hasErrors,
    } = this.state;

    return (
      <Modal
        isOpened={true}
        onCancel={onCancel}
        onSave={this.onSave}
        title={"Time Detail"}
        addTitleBottomBorder={true}
        dimensions={{ width: 'unset', height: '525px', maxWidth: '823px', maxHeight: '750px' }}
      >
        <SectionHeader>
          Time Detail Information
        </SectionHeader>
        <div className={classes.topSectionContainer}>
          <div className={classes.fieldsRowContainer}>
            <TextField
              label="Additional Time"
              error={hasErrors.title}
              value={title}
              onFieldChange={this.onFieldChange('title')}
            />
          </div>
          <div className={classes.timesContainer}>
            <CoverDateTimePicker
              label="Start Time"
              error={hasErrors.startTime}
              value={startTime}
              className={classes.columnLeft}
              onChange={this.handleDateTimeChange('startTime')}
            />
            <CoverDateTimePicker
              label="End Time"
              error={hasErrors.endTime}
              value={endTime}
              onChange={this.handleDateTimeChange('endTime')}
            />
          </div>
        </div>
        <SectionHeader>
          Notes
        </SectionHeader>
        <div className={classes.formSection}>
          <RichTextField
            value={note}
            onFieldChange={this.onFieldChange('note')}
            config={this.froalaConfig}
          />
        </div>
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.errorModal}
        />
      </Modal >);
  }
}

const mapDispatchToProps = {
  saveEventTime,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(AddEditTimeModal));
