import React, { Component, useContext } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { connect } from 'react-redux';
import SectionContext from 'Components/Contexts/SectionContext';
import FilesService from 'actions/FilesService';

//note: toolbarButtons, formatOL and paragraphFormat are broken becuase of the lignning design css
//to test need to import 'froala-editor/js/plugins/lists.min.js' and 'froala-editor//js/plugins/paragraph_format.min.js'. Then remove salesforce-lightning-design-system.min.css

const defaultConfig = {
  charCounterCount: false,
  height: '100%',
  toolbarButtons: ['bold', 'italic', 'underline', 'textColor', 'align', 'insertImage'],
  toolbarInline: true,
  width: '100%',
  key: 'zEG4iH4B11B6D7F4A3g1JWSDBCQG1ZGDf1C1d2JXDAAOZWJhE5B4E4C3F2H3C11A4C4D4==',
};

//documenting keys here if we need to re-use elsewhere or upgrade/downgrade
//Version 3 zEG4iH4B11B6D7F4A3g1JWSDBCQG1ZGDf1C1d2JXDAAOZWJhE5B4E4C3F2H3C11A4C4D4==
//Versions >= 2.8.0 zA4B4B3B2A10D6eF5C4C3E3E2C2B3D6D4C3xJCJWECHICe1JOZWJ==
//Versions < 2.8.0 zc1QXIa2QZe1UOXATEX==

//Heads Up: if RichTextField is used in a modal, you currently must set the zIndex to 1301 to see the popups
// TODO: delete above if better solution found
class RichTextField extends Component {
  constructor(props) {
    super(props);
    this.config = {
      ...defaultConfig,
      ...props.config,
      imageUploadURL: props.uploadUrl,
      requestHeaders: { Authorization: props.authorization },
    };
  }

  render() {
    const {
      onFieldChange,
      value,
    } = this.props;

    return (
      <FroalaEditor
        config={this.config}
        model={value || ''}
        onModelChange={onFieldChange}
      />
    );
  }
}

const mapStateToProps = state => {
  if (!state.oidc.user) {
    return {};
  }
  const token = state.oidc.user.access_token;

  return { authorization: `Bearer ${token}` };
};
const RichTextFieldConnected = connect(mapStateToProps)(RichTextField);

export default props => {
  const {
    relationship,
  } = useContext(SectionContext);
  const uploadUrl = new FilesService(relationship).fileUploadUrl();

  return (<RichTextFieldConnected
    {...props}
    uploadUrl={uploadUrl}
  />);
};
