import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  tabContainer: {
    display: 'flex',
    flexGrow: 1,
    borderRadius: '0 0 4px 4px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  children: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.common.paper,
  },
});

class TabContainer extends Component {
  render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.tabContainer}>
        <div className={classes.children}>
          {children}
        </div>
      </div>
    );
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(TabContainer);
