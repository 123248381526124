import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Table from 'Components/Table';
import { provideIntlService } from '@progress/kendo-react-intl';
import classNames from 'classnames';
import SimpleModal from 'Components/SimpleModal';
import { getBorderColor } from 'shared/themes';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import AddIcon from '@material-ui/icons/Add';
import HighlightButton from 'Components/Buttons/HighlightButton';
import AddEditPaymentModal from 'Components/AddEditPaymentModal';
import Payment from 'Components/Payment';

const styles = theme => {
  const tableBorderColor = getBorderColor(theme);

  return {
    paymentsContainer: {
      display: 'flex',
      width: '100%',
      padding: 15,
      flexDirection: 'column',
    },
    top: {
      display: 'flex',
      padding: 15,
      whiteSpace: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row-reverse',
    },
    paymentsTable: {
      lineHeight: '33px',
      '& td, & th': {
        paddingLeft: 6,
      },
      '& th': {
        color: theme.palette.grey[300],
      },
      '& td': {
        borderLeft: `1px solid ${tableBorderColor}`,
      },
    },
    tableRight: {
      display: 'flex',
      flexFlow: 'row-reverse',
      lineHeight: '33px',
      '& th': {
        border: `1px solid ${tableBorderColor}`,
        color: theme.palette.grey[300],
        paddingLeft: 6,
        paddingRight: 30,
      },
      '& td': {
        border: `1px solid ${tableBorderColor}`,
        paddingLeft: 30,
        paddingRight: 6,
        textAlign: 'right',
      },
    },
    titleContainer: {
      width: '100%',
    },
    name: {
      fontSize: 24,
      whiteSpace: 'normal',
    },
    date: {
      fontSize: 18,
      color: theme.palette.grey[200],
      whiteSpace: 'nowrap',
    },
    iconButton: {
      display: 'flex',
      flexFlow: 'row-reverse',
      paddingRight: 15,
    },
    table: {
      color: theme.palette.primary.main,
      '& thead': {
        backgroundColor: theme.palette.grey[50],
        border: `1px solid ${tableBorderColor}`,
      },
      '& td': {
        border: `1px solid ${tableBorderColor}`,
      },
    },
    green: {
      backgroundColor: lighten(theme.common.green, 0.9),
    },
    pink: {
      backgroundColor: lighten(theme.common.red, 0.9),
    },
    pinkLighter: {
      backgroundColor: lighten(theme.common.red, 0.95),
    },
    greyText: {
      color: theme.palette.grey[300],
    },
    greyLighter: {
      backgroundColor: lighten(theme.palette.grey[50], 0.5),
    },
  };
};

class PaymentsModal extends Component {
  state = {
    payments: [],
    financialDetails: undefined,
    isEditModalOpen: false,
    payment: undefined,
  };

  componentDidMount() {
    this.formatter = provideIntlService(this);
    this.refreshPayments();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.refreshPayments();
    }
    if (prevProps.payments !== this.props.payments) {
      this.refreshPayments();
    }
  }

  refreshPayments = () => {
    const { getPayments } = this.props;

    getPayments && getPayments().then(payments => {
      this.setState({ payments });
    });
  }

  onAddPaymentButtonClicked = () => {
    const { user: { profile } } = this.props;
    const now = new Date();
    const newPayment = {
      dateApplied: now,
      receivedBy: profile.name,
    };

    this.setState({ isEditModalOpen: true, payment: newPayment });
  }

  onModalCancel = () => {
    this.setState({ isEditModalOpen: false, payment: undefined });
  }

  onModalSave = payment => {
    this.onModalCancel();
    payment.id === undefined ?
      this.props.addPayment && this.props.addPayment(payment).then(this.refreshPayments) :
      this.props.editPayment && this.props.editPayment(payment).then(this.refreshPayments);
  }

  onEdit = payment => {
    this.setState({ isEditModalOpen: true, payment });
  }

  onDelete = paymentId => {
    this.props.deletePayment && this.props.deletePayment(paymentId).then(this.refreshPayments);
  }

  render() {
    const { classes, onClose, isOpened, name, date } = this.props;
    const { payments, isEditModalOpen, payment, financialDetails } = this.state;

    return (<SimpleModal
      isOpened={isOpened}
      onClose={onClose}
      title="Payments"
      dimensions={{ width: 'unset', height: '600px', maxHeight: '775px' }}
    >
      <>
        <div className={classes.top}>
          {financialDetails &&
          <Table className={classes.tableRight}>
            <tr className={classes.pink}>
              <th scope="row">Total Due</th>
              <td className={classes.greyText}>{provideIntlService(this).formatNumber(financialDetails.total, 'c')}</td>
            </tr>
            <tr className={classes.green}>
              <th scope="row">Total Payments Paid</th>
              <td className={classes.greyText}>{provideIntlService(this).formatNumber(financialDetails.paid, 'c')}</td>
            </tr>
            <tr className={financialDetails.balance > 0 ? classes.pinkLighter : classes.greyLighter}>
              <th scope="row">Balance</th>
              <td className={classes.greyText}>{provideIntlService(this).formatNumber(financialDetails.balance, 'c')}</td>
            </tr>
          </Table>}
          <div className={classes.titleContainer}>
            <div className={classes.name}>{name}</div>
            <div className={classes.date}>{date}</div>
          </div>
        </div>
        <div className={classes.iconButton}>
          <label htmlFor="add-payment">
            <HighlightButton
              component="span"
              aria-label={`Add Payment`}
              onClick={this.onAddPaymentButtonClicked}
            >
              <AddIcon />
            </HighlightButton>
          </label>
        </div>

        <div className={classes.paymentsContainer}>
          <Table className={classNames(classes.paymentsTable, classes.table)} header={
            <tr>
              <th scope="col">Date</th>
              <th scope="col" style={{ textAlign: 'right', paddingRight: 6 }}>Amount</th>
              <th scope="col">Method</th>
              <th scope="col">Received By</th>
              <th scope="col">Made By</th>
              <th scope="col" style={{ textAlign: 'center' }}>Action</th>
            </tr>
          }>
            {payments && payments.map(payment =>
              <Payment payment={payment} key={payment.id} onDelete={this.onDelete} onEdit={this.onEdit} formatter={this.formatter} />
            )}
            {payments && !payments.length &&
            <tr height="100px"><td colSpan="6" text-align="center">No Payments</td></tr>}
          </Table>
          <div> &nbsp;</div>
          <div>&nbsp;</div>
        </div>
        {isEditModalOpen && <AddEditPaymentModal onSave={this.onModalSave} onCancel={this.onModalCancel} payment={payment} />}
      </>
    </SimpleModal>);
  }
}

const mapStateToProps = state => {
  return {
    user: state.oidc.user,
  };
};

export default withStyles(styles)(connect(mapStateToProps, undefined)(PaymentsModal));
