import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BusinessIcon from '@material-ui/icons/Business';
import { withStyles } from '@material-ui/core/styles';

const classes = {
  container: {
    padding: 25,
  },
  businessIcon: {
    fontSize: 36,
  },
  iconContainer: {
    display: 'inline-block',
    marginRight: 10,
    backgroundColor: 'white',
    borderWidth: 2,
    borderStyle: 'solid',
  },
  biggerFont: {
    fontSize: 18,
  },
  verticalAlign: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
};

export class Hero extends Component {
  render() {
    const {
      children,
      label,
      text,
      backgroundColor,
      color,
      iconColor,
      classes,
    } = this.props;

    return (
      <div
        className={classes.container}
        style={{ backgroundColor, color }}>
        <div
          className={classes.iconContainer}
          style={{
            borderColor: iconColor,
          }}>
          <BusinessIcon
            classes={{ root: classes.businessIcon }}
            style={{ color: iconColor }}
          />
        </div>
        <div className={classes.verticalAlign}>
          <div>{label}</div>
          <div className={classes.biggerFont}>
            {text}
          </div>
        </div>
        <div style={{ float: 'right' }}>
          {children}
        </div>
      </div>
    );
  }
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  iconColor: PropTypes.string,
};

export default withStyles(classes)(Hero);
