import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SelectionBar from 'Components/SelectionBar';
import GridIcon from '@material-ui/icons/GridOn';
import { List as DisplayListViewIcon } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { getCategories, getItemsIfNeeded, getTags } from 'actions/item';
import { connect } from 'react-redux';
import { defaultItemLibraryGrid } from 'constants/apiParamDefaults';
import ImagePreview from './ImagePreview';
import TagSelector from './TagSelector';
import List from './List';
import { REQUEST_TYPES } from 'constants/requestTypes';

const styles = theme => {
  return {
    container: {
      overflow: 'auto',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexGrow: 1,
    },
    tagContainer: {
      minWidth: 222,
    },
    categoryContainer: {
      flexGrow: 1,
      backgroundColor: theme.palette.grey[50],
      padding: 12,
      minHeight: 55,
      maxHeight: 55,
      marginTop: 12,
    },
    gridContainer: {
      display: 'flex',
      flexGrow: 1,
    },
  };
};

export class MenuItemModalNoStyle extends Component {

  allCategories = {
    name: 'ALL CATEGORIES',
    id: 0,
  };

  constructor(props) {
    super(props);
    const initialSort = [{ field: 'name', dir: 'asc' }];

    this.state = {
      menuItems: [],
      selectedItemIds: [],
      selectedOption: this.allCategories,
      representedTagIds: [],
      selectedTagIds: [],
      isImagesOpened: props.isImagesOpenedFirst ? true : false,
      tags: [],
      sort: initialSort,
      activeFilters: {
        ...defaultItemLibraryGrid,
        ...this.getFieldAndDirection(initialSort),
        includeImages: true,
        includeTagSummary: true,
        pageSize: null,
      },
    };
  }

  componentDidMount() {
    this.props.getCategories();
    this.props.getTags();
    this.getItemsIfNeeded(this.state.activeFilters, REQUEST_TYPES.forceCacheUpdate);
  }

  toggleViews = () => { //TODO: temp
    this.setState(prevState => ({ isImagesOpened: !prevState.isImagesOpened }));
  }

  rightButtons = () => {
    const { isImagesOpened } = this.state;

    return (
      <div>
        <HighlightButton
          variant="left"
          minWidth={34}
          pressed={isImagesOpened}
          onClick={this.toggleViews}
        >
          <GridIcon />
        </HighlightButton>
        <HighlightButton
          variant="right"
          minWidth={34}
          pressed={!isImagesOpened}
          onClick={this.toggleViews}
        >
          <DisplayListViewIcon />
        </HighlightButton>
      </div>
    );
  }

  onSelectAll = newSelected => {
    const selectedItemIds = this.state.selectedItemIds.filter(selectedItemId => {
      if (this.state.menuItems.find(menuItem => menuItem.id === selectedItemId)) {
        return false; //just remove items from this menu
      }

      return true;//keep other ids
    });

    const menuItems = this.state.menuItems.map(menuItem => {
      if (newSelected) {
        selectedItemIds.push(menuItem.id);
      }

      return {
        ...menuItem,
        selected: newSelected,
      };
    });

    this.setState({ menuItems, selectedItemIds });
  }

  onItemSelected = itemSelected => {
    const menuItems = this.state.menuItems.map(menuItem => {
      if (menuItem === itemSelected) {
        return {
          ...menuItem,
          selected: !menuItem.selected,
        };
      }

      return menuItem;
    });

    const selectedItemIds = this.updateSelectedItems(itemSelected.id);

    this.setState({ menuItems, selectedItemIds });
  }

  // Need to keep track of selected items on front end, so filtered API results can persist selections
  updateSelectedItems = menuItemId => {
    const { selectedItemIds } = this.state;
    const indexOfId = selectedItemIds.indexOf(menuItemId);

    return indexOfId > -1 ?
      selectedItemIds.filter(id => id !== menuItemId) :
      [...selectedItemIds, menuItemId];
  }

  getItemsIfNeeded = params => {
    this.props.getItemsIfNeeded(params, REQUEST_TYPES.forceCacheUpdate).then(response => {
      const { data, meta } = response;

      if (data) {
        const mappedResponseMenuItems = this.massageData(data);

        const representedTagIds = meta.includedItemTagIds;

        this.setState({ menuItems: mappedResponseMenuItems, representedTagIds });
      }
    });
  }

  massageData = apiItems => {
    const { selectedItemIds } = this.state;

    return apiItems.map(menuItem => {
      const selected = selectedItemIds.indexOf(menuItem.id) !== -1;

      return {
        ...menuItem,
        quantity: this.props.defaultQuantity,
        selected,
      };
    });
  }

  categoryPicked = selectedOption => {
    this.updateFilters({ categoryId: selectedOption.id });
    this.setState({ selectedOption });
  }

  selectTag = newId => {
    const { selectedTagIds } = this.state;
    const indexOfId = selectedTagIds.indexOf(newId);

    const newSelectedTagIds = indexOfId > -1 ?
      selectedTagIds.filter(id => id !== newId) :
      [...selectedTagIds, newId];

    this.setState({ selectedTagIds: newSelectedTagIds });
    this.updateFilters({ tags: newSelectedTagIds });
  }

  updateFilters = filter => {
    const { activeFilters } = this.state;
    const newActiveFilters = {
      ...activeFilters,
      ...filter,
    };

    this.setState({ activeFilters: newActiveFilters });
    this.getItemsIfNeeded(newActiveFilters);
  }

  onSave = () => {
    const menuItems = this.state.selectedItemIds.map(id => ({
      id,
      quantity: this.props.defaultQuantity,
    }));

    this.props.onSave(menuItems);
  }

  getFieldAndDirection = sort => {
    if (sort.length === 0) {
      return { sortField: '', sortDirection: '' };
    }

    const { field: sortField, dir: sortDirection } = sort[0];

    return { sortField, sortDirection };
  }

  onSortChange = sort => {
    const apiSort = this.getFieldAndDirection(sort);

    this.setState({ sort });
    this.getItemsIfNeeded(apiSort);
  }

  render() {
    const {
      classes,
      onModalClosed,
      categories,
      tags,
    } = this.props;
    const {
      menuItems,
      selectedOption,
      representedTagIds,
      selectedTagIds,
      isImagesOpened,
      sort,
    } = this.state;

    return (

      <Modal
        isOpened={true}
        onCancel={onModalClosed}
        onSave={this.onSave}
        title="Select Menu Items"
        addTitleBottomBorder={true}
        dimensions={{ width: '85%', height: '85%', maxWidth: 1287, maxHeight: 832 }}
        saveText="Select"
        rightItem={this.rightButtons()}
      >
        <div className={classes.categoryContainer}>
          <SelectionBar
            onSelectionChange={this.categoryPicked}
            leftFixedOptions={[this.allCategories]}
            options={categories}
            selectedOption={selectedOption}
            showConfig={false}
          />
        </div>
        <div className={classes.container}>
          <div className={classes.tagContainer}>
            <TagSelector
              tags={tags}
              onSelectTag={this.selectTag}
              representedTagIds={representedTagIds}
              selectedTagIds={selectedTagIds}
            />
          </div>
          <div className={classes.gridContainer}>
            {isImagesOpened ?
              <ImagePreview
                menuItems={menuItems}
                onItemSelected={this.onItemSelected}
              />
              :
              <List
                menuItems={menuItems}
                onItemSelected={this.onItemSelected}
                onSelectAll={this.onSelectAll}
                onSortChange={this.onSortChange}
                sort={sort}
              />
            }
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      categories,
    },
    admin: {
      itemTags,
    },
  } = state;

  return {
    categories,
    tags: itemTags,
  };
};

const mapDispatchToProps = {
  getCategories,
  getItemsIfNeeded,
  getTags,
};

export const MenuItemModal = withStyles(styles)(MenuItemModalNoStyle);

const MenuItemModalContainer = connect(mapStateToProps, mapDispatchToProps)(MenuItemModal);

export default MenuItemModalContainer;
