import React, { Component } from 'react';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import SortableItem from './SortableItem';

const SortableList = SortableContainer(({ items, onEdit, onDelete, useCheckBox }) => {
  return (
    //This needs to be a <div> do not replace with <>
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          value={value}
          onEdit={onEdit}
          onDelete={onDelete}
          useCheckBox={useCheckBox} />
      ))}
    </div>
  );
});

export class PickListEdit extends Component {

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.updateItems(arrayMove(this.props.items, oldIndex, newIndex));
  };

  onEdit = newItem => {
    const newItems = this.props.items.map(item => item.id === newItem.id ? newItem : item);

    this.props.updateItems(newItems);
  }

  onDelete = id => {
    const newItems = this.props.items.filter(item => item.id !== id);

    this.props.updateItems(newItems);
  }

  render() {
    const {
      items,
      useCheckBox,
    } = this.props;

    return (
      <SortableList
        items={items}
        onEdit={this.onEdit}
        onSortEnd={this.onSortEnd}
        lockAxis="y"
        useDragHandle={true}
        onDelete={this.onDelete}
        useCheckBox={useCheckBox}
      />);
  }
}

PickListEdit.propTypes = {
  items: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
  useCheckBox: PropTypes.bool,
};

export default PickListEdit;
