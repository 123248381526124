import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CheckBox from 'Components/Checkbox';

const styles = () => ({
  container: {
    flexGrow: 1,
  },
});

const CheckBoxOnSortable = ({
  classes,
  value,
  onChange,
}) => (
  <CheckBox
    className={classes.container}
    label={value.name}
    checked={value.checked}
    onChange={onChange}
  />);

export default withStyles(styles)(CheckBoxOnSortable);
