import React, { Component, useContext } from 'react';
import { withStyles, Avatar } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { Book, Home, Map, People, LibraryBooks } from '@material-ui/icons';
import classNames from 'classnames';
import WizardContext from './WizardContext';
import ManageTemplatesModal from './ManageTemplatesModal';
import _ from 'lodash';
import TemplateManager from 'Components/TemplateManager';

const styles = theme => {
  return {
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      alignContent: 'stretch',
      paddingTop: 18,
    },
    rows: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      alignContent: 'stretch',
    },
    row2Header: {
      paddingTop: 16,
    },
    row2: {
      borderTop: `2px solid ${theme.palette.grey['A200']}`,
      marginTop: 8,
    },
    templatesHeader: {
      width: 200,
      textAlign: 'center',
      fontSize: 13,
    },
    sectionsHeader: {
      flex: '1 0 auto',
      textAlign: 'center',
      fontSize: 13,
      color: theme.palette.primary.dark,
    },
    rightBorder: {
      borderRight: `2px solid ${theme.palette.grey['A200']}`,
    },
    sectionCards: {
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column',
      padding: '16px 40px 0',
    },
    sectionCard: {
      height: 121,
      width: 120,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 4,
      boxShadow: `0 2px 2px 0 rgba(0,0,0,0.2)`,
      cursor: 'pointer',
    },
    selectedCard: {
      boxShadow: `5px 5px 2px 0 rgba(0,0,0,0.2)`,
    },
    selectedTriangle: {
      height: 121,
      width: 120,
      position: 'absolute',
      backgroundImage: 'url(/icons/check-corner.svg)',
      backgroundRepeat: 'no-repeat',
      borderRadius: 4,
    },
    cardRow: {
      display: 'flex',
      flexGrow: 1,
      paddingTop: 40,
    },
    topCardRow: {
      justifyContent: 'space-between',
    },
    bottomCardRow: {
      justifyContent: 'space-around',
      paddingRight: 60,
      paddingLeft: 60,
    },
    sectionCardName: {
      margin: '8px 0 -16px',
    },
  };
};

const options = [
  {
    id: 1,
    name: 'On-Site',
    sections: {
      menus: true,
      venues: true,
      sites: false,
      shifts: true,
      notes: true,
    },
  },
  {
    id: 2,
    name: 'Off-Site',
    sections: {
      menus: true,
      venues: false,
      sites: true,
      shifts: false,
      notes: true,
    },
  },
  {
    id: 3,
    name: 'Dinner Reservation',
    sections: {
      menus: true,
      venues: true,
      sites: true,
      shifts: true,
      notes: true,
    },
  },
  {
    id: 4,
    name: 'Wedding',
    sections: {
      menus: true,
      venues: true,
      sites: false,
      shifts: true,
      notes: true,
    },
  },
];

class TemplatePanel extends Component {
  constructor(props) {
    super(props);

    const selectedCards = props.selectedCards || options[0].sections;
    const selectedTemplateId = options[0].id;

    this.state = {
      selectedCards,
      selectedTemplateId,
      isManageTemplatesModalOpen: false,
    };
  }

  componentDidMount() {
    this.props.selectedCardsChanged(this.state.selectedCards);
  }

  selectCard(id) {
    const selectedCards = {
      ...this.state.selectedCards,
      [id]: !this.state.selectedCards[id],
    };

    const template = options.find(option => _.isEqual(option.sections, selectedCards)) || {};
    const selectedTemplateId = template.id;

    this.setState({ selectedCards, selectedTemplateId });
    this.props.selectedCardsChanged(selectedCards);
  }

  quickSelect = template => {
    this.setState({
      selectedCards: template.sections,
      selectedTemplateId: template.id,
    });

    this.props.selectedCardsChanged(template.sections);
  }

  openManageTemplatesModal = () => {
    this.setState({ isManageTemplatesModalOpen: true });
  }

  closeManageTemplatesModal = () => {
    this.setState({ isManageTemplatesModalOpen: false });
  }

  render() {
    const {
      classes,
      theme,
    } = this.props;
    const {
      selectedTemplateId,
      selectedCards,
      isManageTemplatesModalOpen,
    } = this.state;

    return (
      <div className={classes.wrap}>
        <div className={classNames(classes.rows, classes.row2Header)}>
          <div className={classes.templatesHeader}>Templates</div>
          <div className={classes.sectionsHeader}>Required Sections</div>
        </div>
        <div className={classNames(classes.rows, classes.row2)}>
          <TemplateManager
            options={options}
            selectedId={selectedTemplateId}
            onQuickPickSelect={this.quickSelect}
            onManageClicked={this.openManageTemplatesModal}
          />

          <div className={classes.sectionCards}>
            <div className={classNames(classes.cardRow, classes.topCardRow)}>
              <div className={classNames(classes.sectionCard, selectedCards.menus && classes.selectedCard)} onClick={() => this.selectCard('menus')}>
                {this.state.selectedCards.menus ? <div className={classes.selectedTriangle} /> : null}
                <Avatar style={{ backgroundColor: lighten(theme.common.purple2, 0.7), width: 56, height: 57 }} >
                  <Book style={{ color: theme.common.purple2, opacity: 1, fontSize: 39 }} />
                </Avatar>
                <span className={classes.sectionCardName}>Menus</span>
              </div>
              <div className={classNames(classes.sectionCard, selectedCards.venues && classes.selectedCard)} onClick={() => this.selectCard('venues')}>
                {selectedCards.venues ? <div className={classes.selectedTriangle} /> : null}
                <Avatar style={{ backgroundColor: lighten(theme.palette.grey[800], 0.7), width: 56, height: 57 }} >
                  <Home style={{ color: theme.palette.grey[800], fontSize: 42 }} />
                </Avatar>
                <span className={classes.sectionCardName}>Venues</span>
              </div>
              <div className={classNames(classes.sectionCard, selectedCards.sites && classes.selectedCard)} onClick={() => this.selectCard('sites')}>
                {selectedCards.sites ? <div className={classes.selectedTriangle} /> : null}
                <Avatar style={{ backgroundColor: lighten(theme.common.darkGreen, 0.7), width: 56, height: 57 }} >
                  <Map style={{ color: theme.common.darkGreen, fontSize: 39 }} />
                </Avatar>
                <span className={classes.sectionCardName}>Sites</span>
              </div>
            </div>
            <div className={classNames(classes.cardRow, classes.bottomCardRow)}>
              <div className={classNames(classes.sectionCard, selectedCards.shifts && classes.selectedCard)} onClick={() => this.selectCard('shifts')}>
                {selectedCards.shifts ? <div className={classes.selectedTriangle} /> : null}
                <Avatar style={{ backgroundColor: lighten(theme.common.yellow, 0.7), width: 56, height: 57 }} >
                  <People style={{ color: theme.common.yellow, fontSize: 39 }} />
                </Avatar>
                <span className={classes.sectionCardName}>Shifts</span>
              </div>
              <div className={classNames(classes.sectionCard, selectedCards.notes && classes.selectedCard)} onClick={() => this.selectCard('notes')}>
                {selectedCards.notes ? <div className={classes.selectedTriangle} /> : null}
                <Avatar style={{ backgroundColor: lighten(theme.common.red, 0.7), width: 56, height: 57 }} >
                  <LibraryBooks style={{ color: theme.common.red, fontSize: 39 }} />
                </Avatar>
                <span className={classes.sectionCardName}>Notes</span>
              </div>
            </div>
          </div>
        </div>
        {isManageTemplatesModalOpen && <ManageTemplatesModal onModalClosed={this.closeManageTemplatesModal} options={options} />}
      </div>
    );
  }
}

//Container to move context into props
const TemplatePanelContainer = props => {
  const {
    selectedCardsChanged,
    selectedCards,
    generalInfoErrors,
  } = useContext(WizardContext);

  return (<TemplatePanel
    {...props}
    generalInfoErrors={generalInfoErrors}
    selectedCards={selectedCards}
    selectedCardsChanged={selectedCardsChanged}
  />);
};

export default withStyles(styles, { withTheme: true })(TemplatePanelContainer);
