import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import ReactCrop, { Crop } from 'react-image-crop';
import ISetting from 'models/ISetting';

const styles = createStyles((theme: Theme) => ({
  originImage: {
    maxHeight: '300px',
  },
  companyLogo: {
    display: 'block',
    maxHeight: 40,
  },
  inputFile: {
    marginTop: 15,
  },
}));

interface IProps extends WithStyles {
  setting: ISetting,
  logoSettingOnChange: (logoBlob: any, logoFileName: string) => void,
  logoBlob: any,
}

interface IState {
  src: string,
  fileType: string,
  fileName: string,
  crop: Crop,
  zoom: number,
  croppedImageUrl: string,
}

const defaultState: IState = {
  src: null,
  fileType: '',
  fileName: '',
  crop: {
    unit: 'px',
    height: 40,
    aspect: 16 / 6,
  },
  zoom: 1,
  croppedImageUrl: null,
}

class GrazeCompanyLogo extends Component<IProps, IState> {
  state = defaultState;
  imageRef: any;
  fileUrl: string;
  croppedBlob: any;

  onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result as string, fileType: file.type, fileName: file.name })
      );
      reader.readAsDataURL(file);
    }
  };

  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: Crop, percentCrop: Crop) => {
    this.setState({ crop });
  };

  onZoomChange = (zoom: number) => {
    this.setState({ zoom });
  }

  async makeClientCrop(crop: Crop) {
    const { logoSettingOnChange } = this.props;
    const { fileName } = this.state;

    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        fileName,
      ) as string;

      this.setState({ croppedImageUrl });
      logoSettingOnChange(this.croppedBlob, fileName);
    }
  }

  getCroppedImg(image: any, crop: Crop, fileName: string) {
    const { fileType } = this.state;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        this.croppedBlob = blob;
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        (blob as any).name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, fileType);
    });
  }

  public componentDidMount() {
    const { setting } = this.props;

    this.setState({ croppedImageUrl: setting ? setting.value : '' });
  }

  public componentDidUpdate(prevProps: IProps) {
    const { setting, logoBlob } = this.props;
    const settingSavedOrCanceled = !!prevProps.logoBlob && !logoBlob;

    if (prevProps.setting !== setting || settingSavedOrCanceled) {
      this.setState({
        ...defaultState,
        croppedImageUrl: setting ? setting.value : '',
      });
    }
  }

  public render() {
    const { classes } = this.props;
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div>
        <div className={classes.inputFile}>
          <input type="file" accept="image/*" onChange={this.onSelectFile} onClick={(e: any) => (e.target.value = null)} />
        </div>
        <br/>
        {src && (<>
          <p><b>Origin Image</b></p>
          <ReactCrop
            className={classes.originImage}
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        </>)}
        <p><b>Company Logo Preview</b></p>
        {croppedImageUrl
          ? (
            <img
              alt="Company Logo"
              className={classes.companyLogo}
              src={croppedImageUrl} />
          )
          : (
            <div>No file loaded</div>
          )
        }
      </div>
    );
  }
}

export default withStyles(styles)(GrazeCompanyLogo);
