import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ItemTagModal from 'portal/admin/menumanagement/MenuItemSelectionModal/ItemTagModal';
import { Add } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { Button } from '@material-ui/core';

const styles = theme => ({
  tagsText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    height: 36,
    fontWeight: 'bold',
    padding: 8,
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    borderTop: `1px solid ${theme.palette.grey[50]}`,
  },
  tags: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
  },
  buttonContainer: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  buttonItemTag: {
    fontSize: 13,
    fontWeight: 'normal',
    color: theme.palette.grey[200],
    minWidth: 46,
    textTransform: 'none',
    borderRadius: 2,
    borderColor: theme.palette.grey[200],
    padding: '0 4px',
  },
  representedTag: {
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  },
  selectedTag: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.dark,
  },

});

class TagSelector extends Component {
  state = {
    itemTagModalOpened: false,
    tags: [],
  };

  componentDidMount() {
    const { tags } = this.props;

    if (tags) {
      this.setState({ tags });
    }
  }

  componentDidUpdate(prevProps) {
    const { tags } = this.props;

    if (tags !== prevProps.tags) {
      this.setState({ tags });
    }
  }

  isSelected = tagId => {
    const { selectedTagIds } = this.props;

    return selectedTagIds.some(id => id === tagId);
  }

  // This is a tag that is represented in the visible menu items
  isRepresented = tagId => {
    const { representedTagIds } = this.props;

    return representedTagIds.some(id => id === tagId);
  }

  onSelectTag = id => () => {
    //the parent component will toggle the button on and off
    this.props.onSelectTag(id);
  }

  closeItemTags = () => {
    this.setState({ itemTagModalOpened: false });
  }

  saveItemTags = tags => {

    this.props.selectedTagIds.forEach(selctedTagId => {
      if (!tags.find(tag => tag.id === selctedTagId)) {
        //anything in this.props.selectedTagIds, but not in tags[] will need to call onSelect
        //remove because the button is hidden so there is no way for the user to unselect the item tag
        this.props.onSelectTag(selctedTagId);
      }
    });
    this.setState({ tags });
  }

  openItemTagModal = () => {
    const allDisplayedTagIds = this.state.tags.map(tag => tag.id);

    this.setState({ itemTagModalOpened: true, allDisplayedTagIds });
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      allDisplayedTagIds,
      itemTagModalOpened,
      tags,
    } = this.state;

    return (
      <>
        <div className={classes.tagsText}>
          Item Tags
        </div>
        <div className={classNames(classes.tags)}>
          {tags.map(tag => (
            <div className={classes.buttonContainer} key={tag.id}>
              <Button
                variant="outlined"
                className={classNames(
                  classes.buttonItemTag,
                  this.isRepresented(tag.id) && classes.representedTag,
                  this.isSelected(tag.id) && classes.selectedTag)
                }
                onClick={this.onSelectTag(tag.id)}
              >
                {tag.name}
              </Button>
            </div>
          ))}
          <div className={classes.buttonContainer}>
            <HighlightButton
              onClick={this.openItemTagModal}>
              <Add />
            </HighlightButton>
          </div>
        </div>
        <ItemTagModal
          isOpened={itemTagModalOpened}
          checkedIds={allDisplayedTagIds}
          onModalClosed={this.closeItemTags}
          onSave={this.saveItemTags}
          title={`Modify Item Tags`}
        />
      </>
    );
  }
}

export default withStyles(styles)(TagSelector);
