import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { saveBookingMenuColumns } from 'actions/eventMenu';
import { storeBookingSetIsEditing } from 'actions/booking';
import {
  GridOn as GridIcon,
} from '@material-ui/icons';
import GridColumnEditMenu from 'Components/Grid/GridColumnEditMenu';
import HighlightButton from 'Components/Buttons/HighlightButton';
import SelectPackagesModal from 'Components/SelectPackagesModal';
import NewButton from 'menu/NewButton';
import SortableMenuSelection from 'menu/SortableMenuSelection';

const styles = theme => ({
  gridColumnEditMenu: {
    right: -40,
  },
  iconStyle: {
    color: theme.palette.common.black,
    margin: 0,
    height: 18,
  },
  setPositionGridMenu: {
    position: 'relative',
  },
  toolBarButton: {
    padding: 0,
    minHeight: 32,
    minWidth: 32,
    marginRight: 10,
    borderColor: theme.palette.action.active,
  },
});

class MenuTab extends Component {
  state = {
    showModifiers: false,
    isGridToggled: false,
  };

  toggleModifiers = () => {
    this.setState({ showModifiers: !this.state.showModifiers });
  }

  onGridEditClick = () => {
    this.setState({ isGridToggled: !this.state.isGridToggled });
  }

  saveGridColumns = columns => {
    this.props.saveBookingMenuColumns(columns);
  }

  onGridEditClose = () => {
    this.setState({ isGridToggled: false });
  }

  openSelectPackagesModal = () => {
    this.setState({ isSelectPackagesModalOpen: true });
  }

  closeSelectPackagesModal = () => {
    this.setState({ isSelectPackagesModalOpen: false });
  }

  toolBar = () => {
    const {
      classes,
      storeBookingSetIsEditing,
      columns,
    } = this.props;
    const {
      isGridToggled,
    } = this.state;

    return (<>
      <HighlightButton
        onClick={this.toggleModifiers}
        aria-label="Show Modifiers"
        className={classes.toolBarButton}
      >
        M
      </HighlightButton>

      <div className={classes.setPositionGridMenu}>
        <HighlightButton
          onClick={this.onGridEditClick}
          aria-label="Edit Grid Columns"
          className={classes.toolBarButton}
        >
          <GridIcon className={classes.iconStyle} />
        </HighlightButton>
        <GridColumnEditMenu
          columns={columns}
          isMenuVisible={isGridToggled}
          onColumnsSubmit={this.saveGridColumns}
          onCloseMenu={this.onGridEditClose}
          classMods={{ root: classes.gridColumnEditMenu }}
        />
      </div>

      <div className={classes.toolBarButton}>
        <NewButton
          openSelectPackagesModal={this.openSelectPackagesModal}
          enterEditMode={() => storeBookingSetIsEditing(true)}
          newSectionName="Unnamed Menu"
          isToolbarStyle={true}
        />
      </div>
    </>);
  }

  render() {
    const {
      // taxConfig,
      isEditing,
      focusedEvent,
    } = this.props;

    const {
      showModifiers,
      isSelectPackagesModalOpen,
    } = this.state;

    return (
      <>
        {isSelectPackagesModalOpen ?
          <SelectPackagesModal
            isOpened={true}
            onModalClosed={this.closeSelectPackagesModal}
            defaultQuantity={focusedEvent.estimatedGuestCount}
          />
          :
          <SortableMenuSelection
            isEditing={isEditing}
            showModifiers={showModifiers}
            toolBar={this.toolBar()}
            showWhenMenuNameChanged={true}
            storeType="manager"
          />
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    booking: {
      manager: {
        focusedEvent,
        isEditing,
      },
    },
    settings: {
      menu,
    },
  } = state;

  return {
    focusedEvent,
    isEditing,
    columns: menu.bookingMenuColumns,
  };
};

const mapDispatchToProps = {
  saveBookingMenuColumns,
  storeBookingSetIsEditing,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuTab));
