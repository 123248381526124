import {
  MENUS_RECEIVED,
  INIT_WORKING_MASTER_MENU,
  UPDATE_WORKING_MASTER_MENU,
  UPDATE_WORKING_MASTER_MENU_NAME,
  WORKING_MENU_ADD_MENU_CATEGORY,
  WORKING_MENU_DELETE_MENU_CATEGORY,
  WORKING_MENU_DELETE_MENU_CATEGORY_ITEM,
  WORKING_MENU_ADD_MENU_CATEGORY_ITEM,
  WORKING_MENU_UPDATE_MENU_CATEGORY_ITEMS,
  RESET_WORKING_MASTER_MENU,
  MENU_SAVED,
  MENU_ADDED,
  MENU_DELETED,
  WORKING_MENU_COLLAPSE_ALL_CATEGORIES,
  WORKING_MENU_EXPAND_ALL_CATEGORIES,
  WORKING_MENU_TOGGLE_EXPAND_CATEGORY,
  WORKING_MENU_NEXT_PLACEHOLDER_ID,
  WORKING_MENU_API_REQUEST_BEGIN,
  MENU_ASSIGNMENTS_RECEIVED,
  MENU_ASSIGNMENTS_UPDATE,
  MENU_ASSIGNMENTS_RESET,
  SET_WORKING_MASTER_MENU_ID,
  UPDATE_WORKING_MASTER_MENU_CATEGORIES,
  INIT_WORKING_MASTER_MENU_CATEGORIES,
} from 'actions/constants';
import _ from 'lodash';

const initialState = {
  masterMenus: [],
  workingMasterMenuId: null,
  workingMasterMenu: {},
  workingMasterMenuRef: {},
  isWorkingMasterMenuChanged: false,
  menuCategories: [],
  collapsedCategoryIds: [],
  menuAssignments: [],
  menuAssignmentsRef: [],
  menuAssignmentsChanged: false,
  placeholderId: -1,
  newMenuId: null,
  menuSettings: {},
  isLoading: false,
};

export function masterMenuManagement(state = initialState, action) {
  switch (action.type) {

    case MENUS_RECEIVED: {
      const { masterMenus } = action;

      return {
        ...state,
        masterMenus,
        isLoading: false,
      };
    }

    case WORKING_MENU_API_REQUEST_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case INIT_WORKING_MASTER_MENU: {
      const { workingMasterMenu } = action;

      return {
        ...state,
        workingMasterMenu,
        workingMasterMenuRef: _.cloneDeep(workingMasterMenu),
        workingMasterMenuId: workingMasterMenu ? workingMasterMenu.id : null,
        menuName: workingMasterMenu ? workingMasterMenu.name : '',
        isWorkingMasterMenuChanged: false,
        placeholderId: -1,
      };
    }

    case INIT_WORKING_MASTER_MENU_CATEGORIES: {
      const { menuCategories } = action;

      return {
        ...state,
        menuCategories,
        menuCategoriesRef: _.cloneDeep(menuCategories),
      };
    }

    case SET_WORKING_MASTER_MENU_ID: {
      const { menuId } = action;

      return {
        ...state,
        workingMasterMenuId: menuId,
      };
    }

    case RESET_WORKING_MASTER_MENU: {
      const { workingMasterMenuRef, menuCategoriesRef } = state;

      return {
        ...state,
        workingMasterMenu: _.cloneDeep(workingMasterMenuRef),
        menuCategories: _.cloneDeep(menuCategoriesRef),
        menuName: workingMasterMenuRef.name,
        isWorkingMasterMenuChanged: false,
      };
    }

    case UPDATE_WORKING_MASTER_MENU: {
      const { workingMasterMenu } = action;

      return {
        ...state,
        workingMasterMenu,
        isWorkingMasterMenuChanged: true,
      };
    }

    case UPDATE_WORKING_MASTER_MENU_NAME: {
      const { menuName } = action;

      return {
        ...state,
        menuName,
        isWorkingMasterMenuChanged: true,
      };
    }

    case MENU_SAVED: {
      const { menu, updatedMasterMenus, updatedMenuCategories } = action;

      return {
        ...state,
        masterMenus: updatedMasterMenus,
        workingMasterMenu: menu,
        workingMasterMenuRef: _.cloneDeep(menu),
        menuName: menu.name,
        menuCategories: updatedMenuCategories,
        isWorkingMasterMenuChanged: false,
        newMenuId: null,
        isLoading: false,
      };
    }

    case MENU_ADDED: {
      const { newMenuId } = action;

      return {
        ...state,
        newMenuId,
      };
    }

    case MENU_DELETED: {

      return {
        ...state,
        workingMasterMenu: {},
        workingMasterMenuRef: {},
        isLoading: false,
      };
    }

    case WORKING_MENU_ADD_MENU_CATEGORY: {
      const { placeholderId, menuCategories } = state;
      const { menuCategory } = action;

      return {
        ...state,
        menuCategories: [
          ...menuCategories,
          menuCategory,
        ],
        isWorkingMasterMenuChanged: true,
        placeholderId: placeholderId - 1,
      };
    }

    case WORKING_MENU_DELETE_MENU_CATEGORY: {
      const { menuCategories } = action;

      return {
        ...state,
        menuCategories,
        isWorkingMasterMenuChanged: true,
      };
    }

    case WORKING_MENU_DELETE_MENU_CATEGORY_ITEM: {
      const { updatedMenuCategories } = action;

      return {
        ...state,
        menuCategories: updatedMenuCategories,
        isWorkingMasterMenuChanged: true,
      };
    }

    case WORKING_MENU_ADD_MENU_CATEGORY_ITEM: {
      const { placeholderId } = state;
      const { updatedMenuCategories } = action;

      return {
        ...state,
        menuCategories: updatedMenuCategories,
        isWorkingMasterMenuChanged: true,
        placeholderId: placeholderId - 1,
      };
    }

    case WORKING_MENU_UPDATE_MENU_CATEGORY_ITEMS: {
      const { updatedMenuCategories } = action;

      return {
        ...state,
        menuCategories: updatedMenuCategories,
        isWorkingMasterMenuChanged: true,
      };
    }

    case WORKING_MENU_TOGGLE_EXPAND_CATEGORY: {
      const { collapsedCategoryIds } = action;

      return {
        ...state,
        collapsedCategoryIds,
      };
    }

    case WORKING_MENU_COLLAPSE_ALL_CATEGORIES: {
      const { collapsedCategoryIds } = action;

      return {
        ...state,
        collapsedCategoryIds,
      };
    }

    case WORKING_MENU_EXPAND_ALL_CATEGORIES: {
      const { collapsedCategoryIds } = action;

      return {
        ...state,
        collapsedCategoryIds,
      };
    }

    case WORKING_MENU_NEXT_PLACEHOLDER_ID: {
      const { placeholderId } = state;

      return {
        ...state,
        placeholderId: placeholderId - 1,
      };
    }

    case MENU_ASSIGNMENTS_RECEIVED: {
      const { menuAssignments } = action;

      return {
        ...state,
        menuAssignments,
        menuAssignmentsRef: [...menuAssignments],
        menuAssignmentsChanged: false,
      };
    }

    case MENU_ASSIGNMENTS_UPDATE: {
      const { menuAssignments } = action;

      return {
        ...state,
        menuAssignments,
        menuAssignmentsChanged: true,
      };
    }

    case MENU_ASSIGNMENTS_RESET: {
      const { menuAssignmentsRef } = state;

      return {
        ...state,
        menuAssignments: [...menuAssignmentsRef],
        menuAssignmentsChanged: false,
      };
    }

    case UPDATE_WORKING_MASTER_MENU_CATEGORIES: {
      const { menuCategories } = action;

      return {
        ...state,
        menuCategories,
        isWorkingMasterMenuChanged: true,
      };
    }

    default:
      return state;
  }
}
