import {
  SET_WIDGET_GRAPH_TITLE,
  SET_WIDGET_GRAPH_DATA_SET,
  SET_WIDGET_GRAPH_TYPE,
  SET_WIDGET_GRAPH_COLOR,
  SET_WIDGET_GRAPH_COMPARISON,
  SET_WIDGET_GRAPH_LABEL,
  SET_WIDGET_GRAPH_SETTINGS,
} from './constants';

export const setWidgetGraphSettings = settings => ({ type: SET_WIDGET_GRAPH_SETTINGS, settings });

export const setWidgetGraphTitle = (id, title) => ({ type: SET_WIDGET_GRAPH_TITLE, payload: { id, title } });

export const setWidgetGraphDataSet = (id, dataSet) => ({ type: SET_WIDGET_GRAPH_DATA_SET, payload: { id, dataSet } });

export const setWidgetGraphType = (id, graphType) => ({ type: SET_WIDGET_GRAPH_TYPE, payload: { id, graphType } });

export const setWidgetGraphColor = (id, color) => ({ type: SET_WIDGET_GRAPH_COLOR, payload: { id, color } });

export const setWidgetGraphIsComparisonVisible = (id, isComparisonVisible) => ({ type: SET_WIDGET_GRAPH_COMPARISON, payload: { id, isComparisonVisible } });

export const setWidgetGraphLabel = (id, label) => ({ type: SET_WIDGET_GRAPH_LABEL, payload: { id, label } });
