import React from 'react';

const LeadContext = React.createContext(
  {
    lead: {},
    isEditing: false,
    cancelEditMode: () => { },
    stashLead: _lead => { },
    saveLead: () => { },
    validateField: _fieldName => { },
    errors: {},
    steps: [],
  }
);

export default LeadContext;
