import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import classNames from 'classnames';
import TextField from 'Components/TextField';
import moment from 'moment';

const styles = theme => ({
  coverDateTime: {
    '& input.e-control.e-timepicker': {
      color: theme.palette.grey[700],
      paddingBottom: 6,
      paddingTop: 6,
      lineHeight: '19px', //line height has to be `Xpx`
      fontSize: 16,
      '&.e-disabled': {
        cursor: 'default',
      },
      '&::selection': {
        background: theme.palette.action.inputHighlight,
        textShadow: 'none',
        color: theme.palette.common.black,
      },
    },
    '&.e-float-input': {
      marginBottom: 0,
      '& input:valid ~ label.e-float-text': {
        top: -11,
      },
    },
    '& label.e-float-text': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    '& label.e-label-bottom': {
      fontSize: `16px !important`,
    },
    '& td.e-selected span.e-day': {
      background: `${theme.palette.primary.dark} !important`, //currently selected day
    },
    '& .e-btn': {
      color: `${theme.palette.primary.dark} !important`, //today button
    },
  },
  noErrors: {
    '& label.e-float-text': {
      color: `${theme.palette.grey[200]} !important`,
    },
    '& .e-input-focus label.e-float-text': {
      color: theme.palette.primary.dark + '!important',
    },
    '& .e-float-line::before, & .e-float-line::after': {
      background: `${theme.palette.primary.dark} !important`,
    },
    '& .e-icons': {
      color: `${theme.palette.grey[300]} !important`, //clickable calendar and clear icon
    },
    '& .e-active': {
      color: theme.palette.primary.dark + '!important',
    },
  },
  errors: {
    '& $coverDateTime': {
      borderBottomColor: `${theme.palette.error.main} !important`,
    },
    '& label.e-float-text': {
      color: `${theme.palette.error.main} !important`,
    },
    '& .e-float-line::before, & .e-float-line::after': {
      background: `${theme.palette.error.main} !important`, //the
    },
    '& .e-time-icon': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  pickContainer: {
    flexGrow: 1,
    margin: '16px 16px 8px',
  },
});

class CoverTimePicker extends Component {

  onChange = changedEventArgs => {
    if (!this.props.onFieldChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }
    if (changedEventArgs && changedEventArgs.isInteracted) {
      this.props.onFieldChange(changedEventArgs.value);
    }
  }

  render() {
    const {
      value,
      className,
      label,
      name,
      classes,
      minTime,
      maxTime,
      minWidth,
      disabled,
      error,
      ...rest
    } = this.props;
    const style = minWidth ? { minWidth } : undefined;
    const timeOnly = moment(value).clone().format('LT') || '';

    if (disabled) {
      return (
        <TextField
          disabled={true}
          label={label}
          className={className}
          error={error}
          value={timeOnly}
        />
      );
    }

    return (
      <div className={classNames(classes.pickContainer, className, error ? classes.errors : classes.noErrors)} style={style}>
        <TimePickerComponent
          {...rest}
          id={name}
          value={value}
          change={this.onChange}
          min={minTime}
          max={maxTime}
          strictMode="true"
          cssClass={classes.coverDateTime}
          floatLabelType="Auto"
          placeholder={label}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CoverTimePicker);
