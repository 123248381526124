import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  Checkbox as MaterialCheckbox,
} from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
  checkboxStyle: {
    marginLeft: 5, //text boxes use margin 16, but checkboxes already have a 11px padding around the checkbox, so to align vertically we need a margin of 5
  },
  label: {
    color: theme.palette.grey[200],
    fontSize: 12,
  },
  checkbox: {
    padding: 8,
  },
});

class Checkbox extends Component {
  fieldChanged = event => {

    if (!this.props.onFieldChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    this.props.onFieldChange(event.target.checked);
  }

  render() {
    const { classes, labelClass, label, fieldName, checkboxColor, className, styleMods, theme, checked, disabled } = this.props;

    return (
      <FormControlLabel
        classes={{
          root: classNames(classes.checkboxStyle, className),
          label: labelClass || classes.label,
        }}

        control={<MaterialCheckbox
          name={fieldName}
          style={{ color: checkboxColor || theme.palette.primary.main }}
          classes={{
            root: classNames(classes.checkbox, styleMods && styleMods.root),
            checked: classes.checkbox,
          }}
          disableRipple={true}
          checked={!!checked} //not falsey gets rid of a React 'uncontrolled' error message when null
          onChange={this.fieldChanged}
          disabled={disabled}
        />}
        label={label}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(Checkbox);
