import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SectionHeader from 'Components/SectionHeader';
import TextField from 'Components/TextField';
import { CurrencyField } from 'Components/currencyInput';
import RichTextField from 'Components/RichTextField';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getRevenueTypes } from 'actions/item';
import TimeField from 'Components/TimeField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { convertTimeStringToSeconds } from 'helpers/convertTimeStringToSeconds';

const styles = () => ({
  topSection: {
    padding: '0 14px',
  },
  formSection: {
    padding: '8px 30px',
  },
  formRow: {
    display: 'flex',
    flexGrow: 1,
  },
  richTextSection: {
    padding: '12px 30px',
  },
  halfWidth: {
    width: '50%',
    display: 'flex',
  },
  thirdWidth: {
    width: '33%',
    display: 'flex',
  },
  main: {
    overflow: 'auto',
  },
  checkboxLabel: {
    color: '#9B9B9B',
    marginLeft: -5,
  },
});

//whole list except insertImage. Change to an input prop if this is too chattly/brittle
const toolbarButtons = ['bold', 'italic', 'underline', 'textColor', 'align'];

export class MenuCategoryItemEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      sets: [],
      taxTypes: [],
      preparationTimeString: null,
    };
    this.froalaConfig = {
      zIndex: props.theme.zIndex.modal + 1,
      toolbarButtons,
    };
  }

  componentDidMount() {
    this.props.getRevenueTypes();
    this.updatePrepTimeString();

    if (this.props.item) {
      this.copyItemPropsToStateAndGetModifiers();
    }
  }

  componentDidUpdate(prevProps) {
    const { item, taxTypes } = this.props;

    if (prevProps.item !== item) {
      this.copyItemPropsToStateAndGetModifiers();
    }
    if (prevProps.taxTypes !== taxTypes) {
      this.setState({ taxTypes });
    }
  }

  copyItemPropsToStateAndGetModifiers = () => {
    const { item } = this.props;

    this.setState({
      item: {
        ...item,
      },
      sets: [],
    }, this.updatePrepTimeString);
  }

  handleFieldChange = fieldName => value => {
    const { item } = this.state;

    this.setState({
      item: {
        ...item,
        [fieldName]: value,
      },
    });
  }

  handleIntChange = fieldName => value => {
    if (value) {
      value = parseInt(value);
    }
    this.setState({
      item: {
        ...this.state.item,
        [fieldName]: value,
      },
    });
  }

  handleFloatChange = fieldName => value => {
    // decimal validation
    const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');

    if (!floatRegExp.test(value)) {
      return '';
    }

    this.setState({
      item: {
        ...this.state.item,
        [fieldName]: value,
      },
    });
  }

  checkChanged = modifier => event => {
    modifier.selected = event.target.checked;
    this.forceUpdate(); //okay to force update because modifers are loaded into this.state
  }

  fieldChecked = fieldName => event => {
    const checked = event.target.checked;

    this.setState({
      item: {
        ...this.state.item,
        [fieldName]: checked,
      },
    });
  }

  onSave = () => {
    const {
      onModalClosed,
    } = this.props;

    const item = this.state.item;

    item.sets = this.state.sets;
    onModalClosed(item);
  }

  onCancel = () => {
    this.props.onModalClosed();
  }

  updatePrepTimeString = () => {
    const { item } = this.state;

    if (item.preparationTimeInSeconds) {
      const preparationTimeStringDateObj = new Date(item.preparationTimeInSeconds * 1000);
      const preparationTimeString = ('0' + preparationTimeStringDateObj.getUTCHours()).slice(-2) + ':' + ('0' + preparationTimeStringDateObj.getUTCMinutes()).slice(-2);

      this.setState({ preparationTimeString });
    }
  }

  handlePrepTimeChange = value => {
    const { item } = this.state;
    const seconds = convertTimeStringToSeconds(value);

    item.preparationTimeInSeconds = seconds;
    this.setState({ item }, this.updatePrepTimeString);
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      item,
      sets,
      taxTypes,
      preparationTimeString,
    } = this.state;

    return (
      <Modal
        isOpened={true}
        onCancel={this.onCancel}
        onSave={this.onSave}
        title="Edit Menu Item"
        saveText="Done"
        addTitleBottomBorder={true}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '750px' }}
      >

        <div className={classes.main}>
          <SectionHeader>
            Item Information
          </SectionHeader>
          <div className={classes.topSection}>
            <div className={classes.formRow}>
              <TextField
                label="Item"
                fieldName="name"
                value={item.name}
                className={classes.halfWidth}
                onFieldChange={this.handleFieldChange('name')}
              />
              <div className={classes.halfWidth}>
                <CurrencyField
                  label="Price"
                  fieldName="price"
                  value={item.price}
                  onFieldChange={this.handleFloatChange('price')}
                />
                <CurrencyField
                  label="Cost"
                  fieldName="cost"
                  value={item.cost}
                  onFieldChange={this.handleFloatChange('cost')}
                />
                <CurrencyField
                  label="Svc Charge"
                  fieldName="serviceCharge"
                  value={item.serviceCharge}
                  onFieldChange={this.handleFloatChange('serviceCharge')}
                />
              </div>
            </div>
            <div className={classes.formRow}>
              <TextField
                label="Allergens"
                fieldName="allergens"
                value={item.allergens}
                className={classes.halfWidth}
                onFieldChange={this.handleFieldChange('allergens')}
              />
              <div className={classes.halfWidth}>
                <TextField
                  label="Calories"
                  fieldName="calories"
                  value={item.calories}
                  onFieldChange={this.handleIntChange('calories')}
                  className={classes.thirdWidth}
                  type="number"
                />
                <TimeField
                  label="Prep Time"
                  name="preparationTimeString"
                  value={preparationTimeString || '00:00'}
                  onFieldChange={this.handlePrepTimeChange}
                  className={classes.thirdWidth}
                />
                <Select
                  label="Tax Type"
                  value={item.taxTypeId || ''}
                  onFieldChange={this.handleFieldChange('taxTypeId')}
                  name="taxTypeId"
                  className={classes.thirdWidth}
                >
                  {taxTypes.map(taxType =>
                    <MenuItem key={taxType.id} value={taxType.id}>{taxType.name}</MenuItem>
                  )}
                </Select>
              </div>
            </div>
          </div>
          <SectionHeader>
            Description
          </SectionHeader>
          <div className={classes.richTextSection}>
            <RichTextField
              value={item.description}
              config={this.froalaConfig}
              onFieldChange={this.handleFieldChange('description')}
            />
          </div>
          {sets.map(set =>
            <Fragment key={set.id}>
              <SectionHeader>
                {set.label}
              </SectionHeader>
              <div className={classes.formSection}>
                <div className={classes.formRow}>
                  {set.modifiers.map(modifier => (
                    <Checkbox
                      label={modifier.name}
                      checked={modifier.selected}
                      onChange={this.checkChanged(modifier)}
                      className={classes.checkboxWidth}
                      key={modifier.id}
                    />
                  ))}
                </div>
              </div>
            </Fragment>
          )}
          <SectionHeader>
            Additional Information
          </SectionHeader>
          <div className={classes.formSection}>
            <div className={classes.formRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!item.linked}
                    onChange={this.fieldChecked('linked')}
                    value="linked"
                    color="primary"
                    className={classes.checkboxWidth}
                    disableRipple={true}
                  />
                }
                label="Linked"
                classes={{ label: classes.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.noTax}
                    onChange={this.fieldChecked('noTax')}
                    value="noTax"
                    color="primary"
                    className={classes.checkboxWidth}
                    disableRipple={true}
                  />
                }
                label="No Tax"
                classes={{ label: classes.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.noServiceCharge}
                    onChange={this.fieldChecked('noServiceCharge')}
                    value="noServiceCharge"
                    color="primary"
                    className={classes.checkboxWidth}
                    disableRipple={true}
                  />
                }
                label="No Service Charge"
                classes={{ label: classes.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.noGratuity}
                    onChange={this.fieldChecked('noGratuity')}
                    value="noGratuity"
                    color="primary"
                    className={classes.checkboxWidth}
                    disableRipple={true}
                  />
                }
                label="No Gratuity"
                classes={{ label: classes.checkboxLabel }}
              />
            </div>
          </div>
        </div>
      </Modal >);
  }
}

const mapStateToProps = state => {
  const {
    api: {
      revenueTypes,
    },
  } = state;

  return {
    taxTypes: revenueTypes,
  };
};

const mapDispatchToProps = {
  getRevenueTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(MenuCategoryItemEditModal));
