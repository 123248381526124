import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Modal as MaterialModal,
  Paper,
  Dialog,
  Slide,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  deleteWorkingStoreOrder,
} from 'actions/order';

const styles = theme => ({
  relative: {
    position: 'relative',
  },
  modal: {
    margin: '5% auto auto auto',
    width: 900,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  alert: {
    margin: 30,
  },
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 1,
    right: 5,
    top: 17,
    padding: 8,
  },
  titleHeader: {
    fontSize: 20,
    height: 60,
    color: theme.palette.grey[800],
    fontWeight: '400',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.grey[200],
  },
  border: {
    border: '1px solid orange',
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class StoreOrderWizardModal extends Component {
  constructor(props) {
    super(props);
    //need to set the body height so that the previous next buttons are always at the bottom
    this.style = {
      height: props.height,
    };
    if (props.borderColor) {
      this.style.border = `1px solid ${props.borderColor}`;
    }
    if (props.outlineColor) {
      this.style.outlineColor = `${props.outlineColor}`;
    }
    this.state = {
      alert: false,
    };
  }

  //unless closeAlert={true}, this will simply close
  closeModal = force => () => {
    const { onClose, closeAlert } = this.props;

    if (closeAlert && !force) {
      this.setState({ alert: true });
    } else {
      onClose();
    }
  }

  cancelOrder = () => {
    const { onClose } = this.props;

    this.props.deleteWorkingStoreOrder();
    this.setState({ alert: false });
    onClose();
  }

  closeDialog = () => {
    this.setState({ alert: false });
  }

  render() {
    const { classes, children, title, compFooter, isOpened } = this.props;
    const header = title ? React.isValidElement(title) ? title : <div className={classes.titleHeader} id="modal-title">{title}</div> : null;

    return (
      <>
        <MaterialModal
          aria-labelledby="modal-title"
          open={isOpened}
          onClose={this.closeModal(false)}
          classes={{
            root: classes.border,
          }}
        >
          <Paper className={classes.modal} style={this.style}>
            <div className={classes.relative}>
              {header}

              {React.cloneElement(children, { closeDialog: this.closeModal })}

            </div>
            {React.cloneElement(compFooter, { closeDialog: this.closeModal })}
          </Paper>
        </MaterialModal>
        <Dialog
          open={this.state.alert}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <div className={classes.alert}>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to cancel and lose all changes?
            </DialogContentText>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                No
              </Button>
              <Button onClick={e => this.cancelOrder()} color="primary">
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </>
    );
  }
}

StoreOrderWizardModal.propTypes = {
  children: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  compFooter: PropTypes.object,
  isOpened: PropTypes.bool.isRequired,
  closeAlert: PropTypes.bool,
};

const mapDispatchToProps = {
  deleteWorkingStoreOrder,
};

// We need an intermediary variable for handling the recursive nesting.

export default connect(null, mapDispatchToProps)(withStyles(styles)(StoreOrderWizardModal));
