import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import ReportList from './ReportList';
import { Route } from 'react-router-dom';

class Reports extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/reports" component={ReportList} />
      </Switch>
    );
  }
}

export default withRouter(Reports);
