import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Grid from 'Components/Grid/Grid';
import { GridColumn } from '@progress/kendo-react-grid';
import {
  Edit as EditIcon,
  Clear as DeleteIcon,
} from '@material-ui/icons';
import DiscountType from 'models/DiscountType';
import { provideIntlService } from '@progress/kendo-react-intl';

const styles = theme => ({
  modifyIcon: {
    cursor: 'pointer',
    marginRight: 7,
    marginLeft: 3,
    color: theme.palette.grey[700],
    fontSize: 26,
  },
});

const CouponGrid = ({ classes, coupons, onEditCoupon, onDeleteCoupon, revenueTypes }) => {
  const formatter = provideIntlService(CouponGrid);

  return (
    <Grid
      items={coupons}
    >
      <GridColumn
        field="active"
        title={"Active"}
        width="60px"
        cell={props => (
          <td style={{ textAlign: 'center' }}>
            <input
              className="k-checkbox"
              type="checkbox"
              checked={props.dataItem.active}
              onChange={() => {}}
              disabled={true}
            />
            <label htmlFor={`coupon-${props.dataItem.active}`} className="k-checkbox-label"></label>
          </td>
        )}
      />
      <GridColumn field="code" title="Coupon Code" />
      <GridColumn field="discountAmount" title="Percentage Off" cell={props => {
        if (props.dataItem.discountType === DiscountType.Flat) {
          return <td></td>;
        }

        const percentageValue =
          !!props.dataItem.couponDiscounts.length &&
          props.dataItem.couponDiscounts.length === revenueTypes.length &&
          !!props.dataItem.couponDiscounts.reduce((a, b) => a.discountAmount === b.discountAmount ? a : NaN)
            ? props.dataItem.couponDiscounts[0].discountAmount
            : -1;

        return (
          <td>{percentageValue === -1 ? 'Mixed' : formatter.formatNumber(percentageValue / 100, '##.0000 %')}</td>
        );
      }}
      />
      <GridColumn field="discountAmount" title="Dollar Value Off" cell={props => (
        <td>{props.dataItem.discountType === DiscountType.Flat ? formatter.formatNumber(props.dataItem.discountAmount, 'c') : null}</td>
      )}
      />
      <GridColumn field="expirationDate" title="Expiration Date" cell={props => (
        <td>{props.dataItem.expirationDate === null ? '' : moment(props.dataItem.expirationDate).clone().format('l')}</td>
      )}
      />
      <GridColumn field="" title="" width="100px" cell={props => (
        <td className={classes.crudIcons}>
          <EditIcon
            className={classes.modifyIcon}
            onClick={() => onEditCoupon(props.dataItem)}
            aria-label={`Edit Coupon`}
          />
          <DeleteIcon
            className={classes.modifyIcon}
            onClick={() => onDeleteCoupon(props.dataItem)}
            aria-label={`Delete Coupon`}
          />
        </td>
      )}
      />
    </Grid>
  );
};

export default withStyles(styles)(CouponGrid);
