import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    height: 75,
    lineHeight: '75px',
    fontSize: 13,
    paddingLeft: 35,
    textTransform: 'uppercase',
    borderStyle: 'solid',
    borderWidth: '0 0 1px',
  },
};

class RoomTemplate extends Component {
  render() {
    const {
      classes,
      resourceData,
      borderColor,
    } = this.props;

    if (!resourceData) return null;

    return (
      <div className={classes.container} style={{ borderColor }}>
        {resourceData.name}
      </div>
    );
  }
}

export default withStyles(styles)(RoomTemplate);
