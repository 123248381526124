import React, { Component } from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
  Paper,
} from '@material-ui/core';
import {
  Dehaze,
  Clear as DeleteIcon,
  Edit as EditIcon,
  FileCopy as CloneIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import classNames from 'classnames';

const styles = theme => ({
  pickList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 'unset',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing.unit,
    boxShadow: 'none',
    '&:first-child': {
      borderRadius: '4px 4px 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 4px 4px',
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '&:hover $crudIcons': {
      visibility: 'visible',
    },
  },
  crudIcons: {
    visibility: 'hidden',
  },
  inputFocused: {
    fontSize: '18px',
    color: theme.palette.grey[700],
  },
  textHolder: {
    userSelect: 'none',
    width: '175px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'middle',
    fontSize: '17px',
    color: theme.palette.grey[700],
  },
  modifyIcon: {
    marginRight: '7px',
    marginLeft: '3px',
    color: theme.palette.grey[700],
    fontSize: '26px',
    cursor: 'pointer',
  },
});

const dragHandleStyle = theme => ({
  dragHandle: {
    marginTop: '2.5px',
    color: theme.palette.grey[700],
    cursor: 'grab',
  },
});

const DragHandle = SortableHandle(withStyles(dragHandleStyle)(({ classes }) => <Dehaze className={classes.dragHandle} />));

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      value: {
        ...props.value,
      },
    };

    this.inFrontOfModal = {
      zIndex: props.theme.zIndex.modal + 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.value, prevProps.value)) {
      this.setState({
        value: {
          ...this.props.value,
        },
      });
    }
  }

  edit = () => {
    const {
      value,
      index,
      onEdit,
    } = this.props;

    onEdit(value, index);
  }

  delete = () => {
    const {
      value,
      index,
      onDelete,

    } = this.props;

    onDelete(value, index);
  }

  clone = () => {
    const {
      value,
      index,
      onClone,
    } = this.props;

    onClone(value, index);
  }

  render() {
    const {
      value,
    } = this.state;

    const {
      classes,
      additionalClasses,
    } = this.props;

    return (
      <Paper className={classNames(classes.pickList, additionalClasses)} style={this.inFrontOfModal}>
        <DragHandle />
        <span className={classes.textHolder}>
          {value.name}
        </span>
        <div className={classes.crudIcons}>
          <EditIcon onClick={this.edit} className={classes.modifyIcon} />
          <CloneIcon onClick={this.clone} className={classes.modifyIcon} />
          <DeleteIcon onClick={this.delete} className={classes.modifyIcon} />
        </div>
      </Paper>
    );
  }
}

export default SortableElement(withStyles(styles, { withTheme: true })(Item));
