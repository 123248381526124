import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GraphWidget from './GraphWidget';
import DifferenceIndicator from './DifferenceIndicator';

const styles = theme => ({
  numeratorRow: {
    fontSize: '30px',
    lineHeight: 'normal',
  },
  progressBarContainer: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '2px',
    display: 'inline-block',
    height: '4px',
    marginBottom: '4px',
    position: 'relative',
  },
  progressBarGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  progressBar: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '2px',
    height: '100%',
    position: 'absolute',
  },
  totalContainer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'space-between',
  },
  totalDescription: {
    color: theme.palette.grey[200],
    fontSize: '12px',
  },
});

class ProgressBarWidget extends React.Component {
  render() {
    const {
      classes,
      denominator,
      differenceIndicator,
      numerator,
      settings,
    } = this.props;

    const progressBarPercent = { width: `${(numerator * 100 / denominator)}%` };

    return (
      <GraphWidget
        minHeight="175px"
        minWidth="250px"
        numerator={numerator}
        differenceIndicator={differenceIndicator}
        onButtonClick={this.onButtonClick}
        settings={settings}
      >
        <div className={classes.progressBarGroup}>
          <div className={classes.progressBarContainer}>
            <div style={progressBarPercent} className={classes.progressBar}></div>
          </div>
          <div className={classes.totalContainer}>
            <div className={classes.totalDescription}>
              {settings.isLabelVisible ? settings.label : ''}
            </div>
            <DifferenceIndicator differenceIndicator={denominator} units={settings.denominatorUnits} withColor={false} withSign={false} />
          </div>
        </div>
      </GraphWidget>
    );
  }
}

export default withStyles(styles)(ProgressBarWidget);
