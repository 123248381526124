import {
  RECEIVED_STATUSES,
  RECEIVED_SYSTEM_STATUSES,
  SET_STATUS_GROUP,
  MENU_SETTINGS_RECEIVED,
  SETTING_AREAS_RECEIVED,
} from './constants';

import { callApi } from 'shared/CallApi';

export const receivedStatuses = statuses => ({
  type: RECEIVED_STATUSES,
  statuses,
});
export const receivedSystemStatuses = statuses => ({
  type: RECEIVED_SYSTEM_STATUSES,
  statuses,
});
export const setStatusGroup = statusGroup => ({
  type: SET_STATUS_GROUP,
  statusGroup,
});
export const menuSettingsReceived = menuSettings => ({
  type: MENU_SETTINGS_RECEIVED,
  menuSettings,
});
export const settingAreasReceived = settingAreas => ({
  type: SETTING_AREAS_RECEIVED,
  settingAreas,
});

export const getStatuses = () => dispatch => {
  return getStatusesApi(dispatch).then(result => {
    dispatch(receivedStatuses(result));
  });
};

export const getSystemStatuses = () => dispatch => {
  return getSystemStatusesApi(dispatch).then(result => {
    dispatch(receivedSystemStatuses(result));

    return result;
  });
};

export function saveStatus(status) {
  return dispatch => saveStatusApi(dispatch, status).then(response => response);
}

export const saveStatuses = statuses => dispatch => {
  return saveStatusesApi(dispatch, statuses).then(result => {
    dispatch(receivedStatuses(result));

    return result;
  });
};

export const deleteStatus = status => dispatch => {
  return deleteStatusApi(dispatch, status);
};

const deleteStatusApi = (dispatch, status) => {
  return dispatch(
    callApi(`Status/${status.id}`, { method: 'delete' }))
    .catch(console.error);
};

const saveStatusesApi = (dispatch, statuses) => {
  return dispatch(
    callApi(`Status/Bulk`, { method: 'post', body: statuses }))
    .then(response => response.json())
    .catch(console.error);
};

const saveStatusApi = (dispatch, status) => {
  if (status.id) {
    return dispatch(
      callApi(`Status/${status.id}`, { method: 'put', body: status }))
      .then(response => response.json())
      .catch(console.error);
  } else {
    return dispatch(
      callApi(`Status`, { method: 'post', body: status }))
      .then(response => response.json())
      .catch(console.error);
  }
};

const getStatusesApi = dispatch => {
  return dispatch(
    callApi(`Status/Grouped`))
    .then(result => {
      if (result.ok) {
        return result.json();
      }

      throw new Error('Something went wrong. - getStatusesApi');
    })
    .catch(console.error);
};

const getSystemStatusesApi = dispatch => {
  return dispatch(
    callApi(`Status/System`))
    .then(response => response.json())
    .catch(console.error);
};

const getMenuSettingsApi = () => dispatch => {
  return dispatch(callApi('MenuSetting'))
    .then(result => {
      if (result && result.ok) {
        return result.json();
      }
      throw new Error('Something went wrong. - getMenuSettingApi');
    }).then(settings => settings && settings.length ? settings[0] : {});
};

export const menuSettingsSave = menuSettings => dispatch => {
  if (menuSettings.id) {
    return dispatch(menuSettingsUpdate(menuSettings));
  } else {
    return dispatch(menuSettingsCreate(menuSettings));
  }
};

export const menuSettingsCreate = menuSettings => dispatch => {
  return dispatch(callApi(`MenuSetting`, { body: menuSettings, method: 'POST' }))
    .then(response => response.json())
    .then(updated => dispatch(menuSettingsReceived(updated)));
};

export const menuSettingsUpdate = menuSettings => dispatch => {
  return dispatch(callApi(`MenuSetting/${menuSettings.id}`, { body: menuSettings, method: 'PUT' }))
    .then(response => response.json())
    .then(updated => dispatch(menuSettingsReceived(updated)));
};

export const getMenuSettings = () => dispatch => {
  return dispatch(getMenuSettingsApi()).then(menuSettings => {
    dispatch(menuSettingsReceived(menuSettings));
  });
};

export const getStatusesByType = (statuses, type) => {
  const groupedLeadStatusesByType = !!statuses && !!statuses.length
    ? statuses.filter(groupedStatuses => groupedStatuses.type === type)
    : [];

  return groupedLeadStatusesByType.length ? groupedLeadStatusesByType[0].statuses : [];
};

export const getStateODataSettings = () => {

  return (dispatch, getState) => {
    const { settings: { odata } } = getState();

    return odata.take;
  };
};

export const getSettingAreas = () => dispatch => {
  return dispatch(callApi('settingArea'))
    .then(response => response.json())
    .then(settingAreas => dispatch(settingAreasReceived(settingAreas)))
    .catch(console.error);
};

export const saveSettingValues = settingsKeyValues => dispatch => {
  return dispatch(callApi('setting', { body: settingsKeyValues, method: 'PUT' }))
    .catch(console.error);
};
