export const time_zone = {
  'Atlantic Standard Time': 'Atlantic Standard Time (AST)',
  'Eastern Standard Time': 'Eastern Standard Time (EST)',
  'Central Standard Time': 'Central Standard Time (CST)',
  'Mountain Standard Time': "Mountain Standard Time (MST)",
  'Pacific Standard Time': "Pacific Standard Time (PST)",
  'Alaskan Standard Time': 'Alaskan Standard Time (AKST)',
  'Hawaiian Standard Time': 'Hawaiian Standard Time (HST)',
  'UTC-11': 'American Samoa (UTC-11)',
};
