import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getTaxSchedules } from 'actions/taxSchedules';
import { getMerchantAccounts } from 'actions/merchantAccount';
import PickBranch from 'Components/PickBranch';
import FinancialsContext from './FinancialsContext';
import TaxSchedule from './TaxSchedule';
import DeliveryZones from './DeliveryZones';
import Coupons from './Coupons';
import PaymentProcessors from './PaymentProcessors';

const styles = theme => ({
  financials: {
    display: 'flex',
    flex: 1,
  },
  flex: {
    flexDirection: 'column',
    width: 230,
    display: 'flex',
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  financialSetting: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  financialSetup: {
    fontSize: 16,
    padding: 3,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[50],
  },
  placeholderMessage: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
  },
});

class Financials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedNodes: {},
      selectedNode: null,
      selectedSettingId: null,
      taxSchedules: [],
      financialMenus: [
        { name: 'Payment Options', id: 1, pickLists: [
          { name: 'Merchant Accounts', id: 1 },
        ] },
        { name: 'Revenue Types', id: 2, pickLists: [] },
        { name: 'Delivery Zones', id: 3, pickLists: [
          { name: 'Postal Code', id: 1 },
          { name: 'Distance', id: 2 },
          { name: 'Travel Time', id: 3 },
        ] },
        // { name: 'Gateway', id: 3, pickLists: [{ name: 'three', id: 3 }] }, TODO: Hidden for Beta
        { name: 'Coupon Codes', id: 4, pickLists: [
          { name: 'Coupon Code Manager', id: 1 },
        ] },
      ],
    };
  }

  componentDidMount() {
    const { getMerchantAccounts } = this.props;

    this.getTaxScheduleList();
    getMerchantAccounts();
  }

  getTaxScheduleList = () => {
    this.props.getTaxSchedules().then(taxSchedules => {
      const menus = [ ...this.state.financialMenus ];
      const indexOfTaxNode = menus.findIndex(n => n.name === 'Revenue Types');

      menus.splice(indexOfTaxNode, 1, { name: 'Revenue Types', id: 2, pickLists: taxSchedules });

      this.setState({ financialMenus: menus });
    });
  }

  onSettingPicked = (selectedNode, selectedSettingId) => {
    this.setState({ selectedSettingId, selectedNode });
  }

  onToggleExpand = category => () => {
    const newExpanded = !this.state.expandedNodes[category.name];
    const expandedNodes = {
      ...this.state.expandedNodes,
      [category.name]: newExpanded,
    };

    this.setState({ expandedNodes });
  }

  getSetting = () => {
    const { selectedSettingId, selectedNode } = this.state;

    switch (selectedNode) {
      case 'Payment Options':
        return <PaymentProcessors />;
      case 'Revenue Types':
        return <TaxSchedule financialConfigId={selectedSettingId} />;
      case 'Delivery Zones':
        return <DeliveryZones selectedSettingId={selectedSettingId} />;
      case 'Coupon Codes':
        return <Coupons />;

      default:
        return `${selectedNode}`;
    }
  }

  render() {
    const {
      classes,
      locationId,
    } = this.props;

    const {
      selectedSettingId,
      expandedNodes,
      financialMenus,
    } = this.state;

    const financialsContext = {
      locationId,
    };

    return (
      <FinancialsContext.Provider value={financialsContext}>
        <div className={classes.financials}>
          <div className={classes.flex}>
            <div className={classes.financialSetup}>Financial Setup</div>
            <div className={classes.options}>
              {financialMenus.map(category => (
                <PickBranch
                  key={category.name}
                  expanded={expandedNodes[category.name]}
                  onToggleExpand={this.onToggleExpand(category)}
                  onPickListPicked={setting => this.onSettingPicked(category.name, setting.id)}
                  pickLists={category.pickLists}
                  selectedListId={selectedSettingId}
                >
                  {category.name}
                </PickBranch>
              ))}
            </div>
          </div>

          <div className={classes.financialSetting}>
            {selectedSettingId ? this.getSetting() : <div className={classes.placeholderMessage}>Select an option to manage your settings.</div>}
          </div>

        </div>
      </FinancialsContext.Provider>
    );
  }
}

const mapDispatchToProps =  {
  getTaxSchedules,
  getMerchantAccounts,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Financials));
