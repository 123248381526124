import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Fade,
} from '@material-ui/core';
import {
  Mail,
  Comment as PostIcon,
  Event as TaskIcon,
  Beenhere,
  Reply,
  Close,
} from '@material-ui/icons/';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import RichTextField from 'Components/RichTextField';
import SimpleDialog from 'Components/SimpleDialog';
import classNames from 'classnames';
import moment from 'moment';
import {
  saveChitChatComment,
  fetchChitChat,
  setThreadIsShared,
  deleteThread,
} from 'actions/chitchat';
import { connect } from 'react-redux';

const styles = theme => ({
  thread: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    '& > div': {
      flex: 1,
    },
  },
  top: {
    display: 'flex',
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 24,
  },
  messageIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 2,
    marginRight: 16,
    width: 42,
  },
  icon: {
    borderRadius: 5,
    width: 32,
    height: 32,
    fontSize: 17,
  },
  messageMainSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    margin: '0 8px 8px 0',
    fontSize: 13,
    maxWidth: '92%',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  messageSubject: {
    color: theme.palette.action.active,
  },
  messageContent: {
    whiteSpace: 'initial',
  },
  messageFromTo: {
    color: theme.palette.grey[200],
  },
  fromTo: {
    paddingRight: 8,
  },
  messageTime: {
    color: theme.palette.grey[200],
  },
  messageDropDown: {
    paddingLeft: 16,
  },
  name: {
    color: theme.palette.action.active,
  },
  magenta: {
    backgroundColor: theme.common.magenta,
  },
  bluegrey: {
    backgroundColor: theme.common.bluegrey,
  },
  red: {
    backgroundColor: theme.common.red,
  },
  blue: {
    backgroundColor: theme.palette.action.active,
  },
  hr: {
    margin: '4px 0',
    borderTop: `1px solid ${theme.palette.grey[50]}`,
  },
  commentSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 60px',
  },
  comment: {
    display: 'flex',
    padding: 8,
  },
  inlineReplyWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '8px 60px',
  },
  inlineReply: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
  },
  replyField: {
    flex: 1,
    padding: 12,
    maxWidth: '91%',
    '& .fr-view': {
      whiteSpace: 'normal',
    },
  },
  buttonContainer: {
    paddingRight: 8,
  },
  button: {
    padding: '0 20px',
  },
  isShared: {
    borderRadius: 4,
    padding: '0 3px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: theme.common.white,
    height: 16,
    fontSize: 11,
    backgroundColor: theme.common.emerald,
    marginTop: 5,
  },
  replyPrompt: {
    display: 'flex',
    flex: 1,
    cursor: 'pointer',
    alignItems: 'center',
    margin: '-8px 0 16px 40px',
    color: theme.palette.grey[500],
    width: 90,
  },
  iconButton: {
    padding: 4,
    margin: '0 4px',
    color: theme.palette.grey[500],
  },
});

class Thread extends Component {

  state = {
    optionsMenu: null,
    isReplying: false,
    reply: {},
  };

  confirmDialog = React.createRef();

  errorDialog = React.createRef();

  validationDialog = React.createRef();

  openOptionsMenu = event => {
    this.setState({ optionsMenu: event.currentTarget });
  };

  closeOptionsMenu = () => {
    this.setState({ optionsMenu: null });
  };

  getAvatar = () => {
    const { classes, thread: { chitChatType, shared } } = this.props;

    const icons = {
      'Post': <PostIcon/>,
      'Email': <Mail/>,
      'SystemGeneratedAction': <Beenhere/>,
      'UserCreatedAction': <Beenhere/>,
      'Task': <TaskIcon/>,
    };

    const colors = {
      'Post': 'magenta',
      'Email': 'bluegrey',
      'SystemGeneratedAction': 'red',
      'UserCreatedAction': 'red',
      'Task': 'blue',
    };

    const icon = icons[chitChatType] || null;
    const color = colors[chitChatType] || 'magenta';

    return (
      <div className={classes.messageIcon}>
        <Avatar className={classNames(classes.icon, classes[color])}>
          {icon}
        </Avatar>
        {shared && <span className={classes.isShared}>Shared</span>}
      </div>
    );
  }

  onReply = () => {
    this.setState({ isReplying: true, optionsMenu: null });
  }

  onSendComment = () => {
    const { reply } = this.state;
    const { thread, saveChitChatComment, chitChatRelationship } = this.props;

    const comment = {
      subject: 'Reply',
      content: reply.message,
      chitChatThreadId: thread.id,
      time: moment().format(),
    };

    if (comment.content) {
      saveChitChatComment(chitChatRelationship, thread.relationshipId, comment).then(() => {
        this.setState({ isReplying: false });
        this.props.onCommentSent(); //probably refresh chitchat timeline
      }).catch(this.handleError);
    } else {
      this.validationDialog.current.open('Please include a message');
    }
  }

  onSetIsShared = () => {
    const { thread, setThreadIsShared, chitChatRelationship } = this.props;

    this.closeOptionsMenu();
    setThreadIsShared(chitChatRelationship, thread.relationshipId, thread.id).then(() => {
      this.props.onCommentSent();
    }).catch(this.handleError);
  }

  handleChange = fieldName => value => {
    const reply = {
      ...this.state.reply,
      [fieldName]: value,
    };

    this.setState({ reply });
  }

  onRemove = () => {
    const { thread, deleteThread, chitChatRelationship } = this.props;

    this.confirmDialog.current.open('Are you sure you want to remove this thread?').then(() => {
      deleteThread(chitChatRelationship, thread.relationshipId, thread.id).then(() => {
        this.props.onCommentSent();
      }).catch(this.handleError);
    });
  }

  onCancelReply = () => {
    this.setState({ isReplying: false, reply: {} });
  }

  handleError = () => {
    this.errorDialog.current.open();
  }

  render() {
    const { classes, thread } = this.props;
    const { optionsMenu, isReplying, reply } = this.state;
    const message = thread.baseMessage;
    const isAction = thread.chitChatType === 3 || thread.chitChatType === 4;

    moment.locale('en', {
      calendar: {
        lastDay: 'LT, [Yesterday]',
        sameDay: 'LT, [Today]',
        lastWeek: 'llll',
        sameElse: 'llll',
      },
    });

    return (
      <div className={classes.thread}>
        <div className={classes.top}>
          {this.getAvatar()}
          <div className={classes.messageMainSection}>
            {message && <>
              <div className={classes.messageSubject}>{message.subject}</div>
              <div className={classes.messageContent}>
                <p dangerouslySetInnerHTML={{ __html: message.content }}></p>
              </div>
              <div className={classes.messageFromTo}>
                <span className={classes.fromTo}>{isAction ? 'User ' : 'From '}</span><span className={classes.name}>{message.from}</span>
                {message.to && <span className={classes.fromTo}>  |  To <span className={classes.name}>{message.to}</span></span>}
              </div></>
            }
          </div>

          <div className={classes.messageTime}>
            {message && moment(message.time).calendar()}
          </div>

          <div className={classes.messageDropDown}>
            <ArrowDownButton
              onClick={this.openOptionsMenu}
              aria-label="Thread options"
              aria-owns="thread-menu"
              aria-haspopup="true"
            >
            </ArrowDownButton>
            <Menu
              id="thread-menu"
              anchorEl={optionsMenu}
              open={Boolean(optionsMenu)}
              onClose={this.closeOptionsMenu}
            >
              <MenuItem onClick={this.onReply}>Reply</MenuItem>
              <MenuItem onClick={this.onSetIsShared}>{thread.shared && 'Un-'}Share With Client</MenuItem>
              <MenuItem onClick={this.onFollow}>Follow</MenuItem>
              <MenuItem onClick={this.onSave}>Save</MenuItem>
              <MenuItem onClick={this.onRemove}>Remove</MenuItem>
            </Menu>
          </div>
        </div>

        <div className={classes.commentSection}>
          {thread.comments.length > 0 && thread.comments.map(comment =>
            <div key={comment.id} className={classes.hr}>
              <div className={classes.comment}>

                <div className={classes.messageMainSection}>
                  <div className={classes.messageSubject}>{comment.subject || 'Reply'}</div>
                  <div className={classes.messageContent}>
                    <p dangerouslySetInnerHTML={{ __html: comment.content }}></p>
                  </div>
                  <div className={classes.messageFromTo}>
                    <span className={classes.fromTo}>From </span><span className={classes.name}>{comment.from}</span>
                    {comment.to && <span className={classes.fromTo}>  |  To  <span className={classes.name}>{comment.to}</span></span>}
                  </div>
                </div>

                <div className={classes.messageTime}>
                  {moment(comment.time).calendar()}
                </div>
              </div>
            </div>
          )}
        </div>

        {isReplying ?
          <Fade in={isReplying}>
            <div className={classes.inlineReplyWrapper}>
              <div className={classes.inlineReply}>
                <div className={classes.replyField}>
                  <RichTextField
                    value={reply.message}
                    onFieldChange={this.handleChange('message')}
                    config={{
                      placeholderText: 'New Reply',
                      heightMax: 200,
                    }}
                  />
                </div>
                <div className={classes.buttonContainer}>
                  <PrimaryButton onClick={this.onSendComment} className={classes.button}>Send</PrimaryButton>
                </div>
              </div>
              <IconButton
                aria-label="Cancel"
                onClick={this.onCancelReply}
                className={classes.iconButton}
              >
                <Close />
              </IconButton>
            </div>
          </Fade>
          :
          <div className={classes.replyPrompt} onClick={this.onReply}>
            <IconButton
              aria-label="Reply"
              className={classes.iconButton}
            >
              <Reply />
            </IconButton>
              Reply
          </div>
        }

        <div className={classes.hr}></div>
        <SimpleDialog innerRef={this.confirmDialog} />
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.validationDialog}
        />
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.errorDialog}
          message="Something went wrong. Please try again."
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  saveChitChatComment,
  fetchChitChat,
  setThreadIsShared,
  deleteThread,
};

export default withStyles(styles)(connect(undefined, mapDispatchToProps)(Thread));

