import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import ComboBoxAsync from 'Components/ComboBoxAsync';
import SimpleDialog from 'Components/SimpleDialog';
import Modal from 'Components/Modal';
import DataLoader from 'Components/DataLoader';
import { connect } from 'react-redux';
import AccountDetails from '../accounts/AccountDetails';
import ContactQuickAddDetails from 'accounts/ContactQuickAddDetails';
import validate from 'validate.js';
import {
  getAccountNameAndIds,
  addAccount,
  saveAccount,
} from 'actions/account';
import { getSalesRepsIfNeeded } from 'actions/contact';
import {
  addContact,
  searchContacts,
} from 'actions/contact';

const styles = {
  overflowIfTooBig: {
    overflow: 'auto',
    maxHeight: 650,
  },
  fullWidth: {
    width: '100%',
  },
};

class QuickAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      contact: null,
      invalidFields: [],
    };
    this.confirmCancel = React.createRef();
    this.errorMessage = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {
      isOpened,
    } = this.props;

    if (!prevProps.isOpened && isOpened) { //on open
      this.setState({ account: { ...this.props.account } });
    }
    if (prevProps.isOpened && !isOpened) { //on close
      this.setState({ isSaving: false });
    }
  }

  handleAccountFieldChange = fieldName => value => {
    const account = {
      ...this.state.account,
      [fieldName]: value,
    };

    this.setState({ account });
  }

  handleAddressFieldChange = fieldName => value => {
    let account = {
      ...this.state.account,
      accountAddress: {
        ...this.state.account.accountAddress,
        [fieldName]: value,
      },
    };

    this.setState({ account });
  }

  handleContactSelect = selectedContact => {
    if (!selectedContact) { // clear contact
      this.setState({ contact: null });

      return;
    }

    this.setState({ contact: selectedContact });
  }

  handleContactFieldChange = fieldName => value => {
    const contact = {
      ...this.state.contact,
      [fieldName]: value,
    };

    this.setState({ contact });
  }

  onQuickAddContact = value => {
    const contact = { firstName: value };

    this.setState({ isNewContact: true, contact });
  }

  addNewAccountAndContact = () => {
    const { contact, isNewContact } = this.state;
    const { addContact, onClose, currentLocation } = this.props;

    if (!this.isFormValid()) {
      return;
    }
    if (isNewContact) {
      this.setState({ isSaving: true });
      if (currentLocation) contact.locationId = currentLocation.id;

      addContact(contact).then(contact => {
        this.addOrSaveAccountWithContact(contact.id).then(account => {
          const selectedEntities = { account, contact };

          onClose(selectedEntities);
        });
      }).catch(() => this.handleError());
    } else {
      this.addNewAccount();
    }
  }

  addNewAccount = () => {
    const { onClose } = this.props;
    const { contact } = this.state;
    const selectedContactId = contact && contact.id ? contact.id : null;

    this.setState({ isSaving: true });

    return this.addOrSaveAccountWithContact(selectedContactId)
      .then(account => {
        onClose({ account, contact });
      }).catch(() => this.handleError());
  }

  addOrSaveAccountWithContact = contactId => {
    const { account } = this.state;
    const { currentLocation, addAccount, saveAccount } = this.props;

    if (contactId) {
      const newAccountContact = { contactId: contactId, primaryForAccount: true };

      account.accountContacts = [newAccountContact];
    }
    if (currentLocation) {
      account.locationId = currentLocation.id;
    }

    return account.id ? saveAccount(account) : addAccount(account);
  }

  handleError = () => {
    this.errorMessage.current.open('Something went wrong. Please try again.');
    this.setState({ isSaving: false });
  }

  onCancel = () => {
    const { onClose } = this.props;
    const { account, contact } = this.state;

    if (!!account || !!contact) {
      this.confirmCancel.current.open('Are you sure you want to lose your changes?').then(onClose);
    } else {
      onClose();
    }
  }

  isFieldInvalid = fieldName => {
    return this.state.invalidFields.indexOf(fieldName) !== -1;
  }

  validateField = fieldName => {
    var isValid = true;
    var value = this.state.account[fieldName];

    switch (fieldName) {
      case 'email':
        const emailResult = validate({ email: value }, { email: { email: true } });

        isValid = !emailResult;
        break;
      case 'name':
        const nameResult = validate({ name: value }, { name: { presence: { allowEmpty: false } } });

        isValid = !nameResult;
        break;
      default:
        return;
    }

    let invalidFields = [...this.state.invalidFields];
    const indexOfField = invalidFields.indexOf(fieldName);

    if (!isValid) {
      if (indexOfField === -1) {
        invalidFields.push(fieldName);
      }
    } else {
      if (indexOfField !== -1) {
        invalidFields.splice(indexOfField, 1);
      }
    }

    this.setState({ invalidFields });
  }

  isFormValid = () => this.state.invalidFields.length === 0;

  render() {
    const {
      classes,
      isOpened,
      salesRepsData,
      searchContacts,
    } = this.props;
    const {
      account,
      contact,
      isSaving,
      isNewContact,
    } = this.state;

    return (
      <Modal
        title={account.id ? 'Edit Account' : 'Add New Account'}
        isOpened={isOpened}
        onCancel={this.onCancel}
        onSave={this.addNewAccountAndContact}
        isSaveDisabled={isSaving}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '85%' }}
      >
        <Paper className={classes.overflowIfTooBig}>
          <AccountDetails
            account={account}
            salesReps={salesRepsData}
            isEditing={true}
            onFieldChange={this.handleAccountFieldChange}
            isFieldChanged={() => {}}
            validateField={this.validateField}
            isFieldInvalid={this.isFieldInvalid}
            onAddressFieldChange={this.handleAddressFieldChange}
            isQuickAdd={true}
          />

          {!account.id && <CoverExpansionPanel title="Add Primary Contact" defaultExpanded={true}>
            {isNewContact ?
              <ContactQuickAddDetails
                contact={contact}
                isEditing={true}
                onFieldChange={this.handleContactFieldChange}
                isFieldChanged={() => { }}
                validateField={this.validateField}
                isFieldInvalid={this.isFieldInvalid}
              />
              :
              <ComboBoxAsync
                className={classes.fullWidth}
                label="Primary Contact"
                value={contact}
                getData={searchContacts}
                autoLoadOptions={true}
                resultCount={5}
                onSelect={this.handleContactSelect}
                onCreateOption={this.onQuickAddContact}
              />
            }
          </CoverExpansionPanel>}
        </Paper>
        <DataLoader isLoading={isSaving} inline={true} />
        <SimpleDialog innerRef={this.confirmCancel} />
        <SimpleDialog onlyOkayButton={true} innerRef={this.errorMessage} />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  getAccountNameAndIds,
  addAccount,
  getSalesRepsIfNeeded,
  addContact,
  saveAccount,
  searchContacts,
};

//QuickAccountModalWithStyles for storybook
export const QuickAccountModalWithStyles = withStyles(styles)(QuickAccountModal);

const mapStateToProps = state => {
  const {
    api: {
      salesReps: {
        salesRepsData,
      },
      currentLocation,
    },
  } = state;

  return {
    salesRepsData,
    currentLocation,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickAccountModalWithStyles);
