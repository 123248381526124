import React from 'react';
import * as localeActions from 'actions/locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const locales = ['en-US', 'bg-BG', 'en-GB', 'es-US', 'fr-CA'];

const Chooser = ({ locale, localeChanged }) => (
  <label>
    Pick language:{locale}
    <select
      style={{ width: 250, marginLeft: 10 }}
      value={locale}
      onChange={localeChanged}
    >
      {
        locales.map((option, key) =>
          <option key={key} value={option}>{option}</option>)
      }
    </select>
  </label>
);

const mapStateToProps = state => {
  const { locale: { locale } } = state;

  return { locale };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(localeActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Chooser);
