import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import Table from 'Components/Table';

function SquareExample({ color, exampleLink }) {
  const id = encodeURI(color).replace('#', '');

  return (
    <>
      <div style={{ margin: 1 }} id={id}>
        {exampleLink ? <a href={exampleLink} rel='noopener noreferrer' target='_blank'>{color}</a> : color}
      </div>
      <div style={{ height: 100, width: 100, margin: 10, backgroundColor: color }}>
      </div>
    </>
  );
}

const replaceCount = 6;

// If you find a new color that isn't in the palette or hasn't previously been replaced Do this.
// 1)you can add the comment in invision
// 2)get the url (in invision) to the comment
// Add a new column next to the color you're replacing with like this:
// <td>
//   <SquareExample
//     color={'rgba(0,112,210,0.05)'}
//      exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336620343/comments/111618593'} />
//  </td>
// send a link to a color by building a URL like this http://coverui.coverdev.net/colors#323C47

class DebugColor extends React.Component {

  replaceTableHeaders = () => {
    const headers = [];

    for (let i = 1; i <= replaceCount; i++) {
      headers.push(<th key={i}>Replace {i}</th>);
    }

    return headers;
  }

  header() {
    return (
      <tr>
        <th>path</th>
        <th>RGB</th>
        <th>Example</th>
        {this.replaceTableHeaders()}
      </tr>
    );
  }

  componentDidMount() {
    if (!window.location.hash) {
      return;
    }
    const color = window.location.hash.replace('#', '');

    const element = document.getElementById(color);

    if (element) {
      element.scrollIntoView();
    }
  }

  render() {
    const {
      theme: {
        palette,
        common,
        typography,
      },
    } = this.props;

    return (
      <div style={{ height: '100%', overflow: 'auto' }}>
        <Table header={this.header()}>
          <tr>
            <th>palette.primary.main</th>
            <td>{palette.primary.main}</td>
            <td><SquareExample color={palette.primary.main} /></td>
            <td>
              <SquareExample
                color={'#00215B'}
                exampleLink={'Label on quick pick'} />
            </td>
            <td>
              <SquareExample
                color={'#252631'}
                exampleLink={'Credit card number on saved card modal'} />
            </td>
            <td>
              <SquareExample
                color={'#386ca7'}
                exampleLink="https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/328991159/comments/127341573'" />
            </td>
          </tr>
          <tr>
            <th>palette.primary.dark</th>
            <td>{palette.primary.dark}</td>
            <td><SquareExample color={palette.primary.dark} /></td>
            <td>
              <SquareExample
                color={'#4D7CFE'}
                exampleLink={'Color of credit card'} />
            </td>

          </tr>
          <tr>
            <th>palette.primary.light</th>
            <td>{palette.primary.light}</td>
            <td><SquareExample color={palette.primary.light} /></td>
          </tr>
          <tr>
            <th>palette.secondary.main</th>
            <td>{palette.secondary.main}</td>
            <td><SquareExample color={palette.secondary.main} /></td>
            <td>
              <SquareExample
                color={'#1F6FE5'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336620340/comments/111687701'} />
            </td>
          </tr>
          <tr>
            <th>palette.secondary.dark</th>
            <td>{palette.secondary.dark}</td>
            <td><SquareExample color={palette.secondary.dark} /></td>
            <td>
              <SquareExample
                color={'#1665D8'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336620342/comments/111686121'} />
            </td>
          </tr>
          <tr>
            <th>palette.secondary.light</th>
            <td>{palette.secondary.light}</td>
            <td><SquareExample color={palette.secondary.light} /></td>
          </tr>
          <tr>
            <th>palette.error.main</th>
            <td>{palette.error.main}</td>
            <td><SquareExample color={palette.error.main} /></td>
            <td>
              <SquareExample
                color={'#FF0202'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/355368259/comments/118046273'} />
            </td>
          </tr>
          <tr>
            <th>common.red</th>
            <td>{common.red}</td>
            <td><SquareExample color={common.red} /></td>
          </tr>
          <tr>
            <th>palette.error.dark</th>
            <td>{palette.error.dark}</td>
            <td><SquareExample color={palette.error.dark} /></td>
          </tr>
          <tr>
            <th>palette.error.light</th>
            <td>{palette.error.light}</td>
            <td><SquareExample color={palette.error.light} /></td>
          </tr>
          <tr>
            <th>palette.action.active</th>
            <td>{palette.action.active}</td>
            <td><SquareExample color={palette.action.active} /></td>
            <td>
              <SquareExample
                color={'#1570D2'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/#/console/15841595/336873101/comments/113574142'} />
            </td>
          </tr>
          <tr>
            <th>palette.action.inputHighlight</th>
            <td>{palette.action.inputHighlight}</td>
            <td><SquareExample color={palette.action.inputHighlight} /></td>
          </tr>
          <tr>
            <th>palette.grey[50]</th>
            <td>{palette.grey[50]}</td>
            <td><SquareExample color={palette.grey[50]} /></td>
            <td>
              <SquareExample
                color={'rgba(0,112,210,0.05)'} //is is actually fade(theme.palette.secondary.dark,.05)
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336620343/comments/111618593'} />
            </td>
            <td>
              <SquareExample
                color={'#E0E5EE'}
                exampleLink={'BUTTON Step dropdown border'} />
            </td>
            <td>
              <SquareExample
                color={'#E6EAEE'}
                exampleLink={'Divider in menu quick add'} />
            </td>
            <td>
              <SquareExample
                color={'#e2e2e2'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/355368239/comments/125153923'} />
            </td>
            <td>
              <SquareExample
                color={'#ECEFF2'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/#/console/15841595/335354820/comments/126212754'} />
            </td>
            <td>
              <SquareExample
                color={'#F0F3F4'}
                exampleLink="https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/328991159/comments/127342015" />
            </td>
            <td>
              <SquareExample
                color="#F8F8F8"
                exampleLink="https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/405487931/comments"
              />
            </td>
          </tr>
          <tr>
            <th>palette.grey[100]</th>
            <td>{palette.grey[100]}</td>
            <td><SquareExample color={palette.grey[100]} /></td>
            <td>
              <SquareExample
                color={'#D8DCE6'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336620343/comments/111709721'} />
            </td>
            <td>
              <SquareExample
                color={'#DDDBDA'}
                exampleLink={'Boarder on sub tab'} />
            </td>
            <td>
              <SquareExample
                color="#D8DFE5"
                exampleLink="https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/328991159/comments/127348149" />
            </td>
          </tr>
          <tr>
            <th>palette.grey[200]</th>
            <td>{palette.grey[200]}</td>
            <td><SquareExample color={palette.grey[200]} /></td>
            <td>
              <SquareExample
                color={'#8b9399'}
                exampleLink={'Text field label'} />
            </td>
            <td>
              <SquareExample
                color={'#89939A'}
                exampleLink={'Available checkbox label on driver details modal'} />
            </td>
            <td>
              <SquareExample
                color={'#83949c'}
                exampleLink={'color on the labels in clone booking'} />
            </td>

          </tr>
          <tr>
            <th>palette.grey[300]</th>
            <td>{palette.grey[300]}</td>
            <td><SquareExample color={palette.grey[300]} /></td>
            <td>
              <SquareExample
                color={'#6B6C6F'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336620343/comments/111713777'} />
            </td>
            <td>
              <SquareExample
                color={'#706E6B'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/#/console/15841595/336873101/comments/113574328'} />
            </td>
          </tr>
          <tr>
            <th>palette.grey[400]</th>
            <td>{palette.grey[400]}</td>
            <td><SquareExample color={palette.grey[400]} /></td>
            <td>
              <SquareExample
                color={'#37394C'}
                exampleLink="https://horizonbusinessservices.invisionapp.com/d/#/console/15841595/328991146/comments"
              />
            </td>
          </tr>
          <tr>
            <th>palette.grey[500]</th>
            <td>{palette.grey[500]}</td>
            <td><SquareExample color={palette.grey[500]} /></td>
            <td>
              <SquareExample
                color={'#9EA0A5'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336620343/comments/111616908'} />
            </td>
            <td>
              <SquareExample
                color={'#97a3b4'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/335644979/comments/112975168'} />
            </td>
          </tr>
          <tr>
            <th>palette.grey[600]</th>
            <td>{palette.grey[600]}</td>
            <td><SquareExample color={palette.grey[600]} /></td>
          </tr>
          <tr>
            <th>palette.grey[700]</th>
            <td>{palette.grey[700]}</td>
            <td><SquareExample color={palette.grey[700]} /></td>
            <td>
              <SquareExample
                color={'#565D61'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336620343/comments/111698256'} />
            </td>
          </tr>
          <tr>
            <th>palette.grey[800]</th>
            <td>{palette.grey[800]}</td>
            <td><SquareExample color={palette.grey[800]} /></td>
            <td>
              <SquareExample
                color={'#3e3f42'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336620343/comments/111680587'} />
            </td>
            <td>
              <SquareExample
                color={'#323C47'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/main#/console/15841595/336873101/comments/113522862'} />
            </td>
            <td>
              <SquareExample
                color={'#2F3C48'}
                exampleLink={'General details label on booking wizard'} />
            </td>

          </tr>
          <tr>
            <th>palette.grey[900]</th>
            <td>{palette.grey[900]}</td>
            <td><SquareExample color={palette.grey[900]} /></td>
            <td>
              <SquareExample
                color={'#2B2826'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/#/console/15841595/336873101/comments/113574502'} />
            </td>
          </tr>
          <tr>
            <th>palette.grey.A100</th>
            <td>{palette.grey.A100}</td>
            <td><SquareExample color={palette.grey.A100} /></td>
          </tr>
          <tr>
            <th>palette.grey.A200</th>
            <td>{palette.grey.A200}</td>
            <td><SquareExample color={palette.grey.A200} /></td>
            <td>
              <SquareExample
                color={'#BDBEC0'}
                exampleLink={'Border between location information and map on new order wizard'} />
            </td>
            <td>
              <SquareExample
                color={'#98a9bc'}
                exampleLink={'card type label on select cards modal'} />
            </td>
          </tr>
          <tr>
            <th>palette.grey.A400</th>
            <td>{palette.grey.A400}</td>
            <td><SquareExample color={palette.grey.A400} /></td>
          </tr>
          <tr>
            <th>palette.grey.A700</th>
            <td>{palette.grey.A700}</td>
            <td><SquareExample color={palette.grey.A700} /></td>
          </tr>
          <tr>
            <th>palette.action.active</th>
            <td>{palette.action.active}</td>
            <td><SquareExample color={palette.action.active} /></td>
            <td>
              <SquareExample
                color={'#1589EE'}
                exampleLink={'https://horizonbusinessservices.invisionapp.com/d/#/console/15841595/335354818/comments/126918535'} />
            </td>
          </tr>
          <tr>
            <th>common.teal</th>
            <td>{common.teal}</td>
            <td><SquareExample color={common.teal} /></td>
          </tr>
          <tr>
            <th>common.green</th>
            <td>{common.green}</td>
            <td><SquareExample color={common.green} /></td>
          </tr>
          <tr>
            <th>common.emerald</th>
            <td>{common.emerald}</td>
            <td><SquareExample color={common.emerald} /></td>
          </tr>
          <tr>
            <th>common.darkGreen</th>
            <td>{common.darkGreen}</td>
            <td><SquareExample color={common.darkGreen} /></td>
          </tr>
          <tr>
            <th>common.uberGreen</th>
            <td>{common.uberGreen}</td>
            <td><SquareExample color={common.uberGreen} /></td>
          </tr>
        </Table>
        <pre>theme.palette:{JSON.stringify(palette, null, 2)}</pre>
        <pre>theme.common:{JSON.stringify(common, null, 2)}</pre>
        <pre>theme.typography:{JSON.stringify(typography, null, 2)}</pre>
      </div>
    );
  }
}

export default withTheme()(DebugColor);
