import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import ContactList from './ContactList';
import Contact from './Contact';
import { Route } from 'react-router-dom';

class Contacts extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/contacts" component={ContactList} />
        <Route path="/contacts/:id" component={Contact} />
      </Switch>
    );
  }
}

export default withRouter(Contacts);
