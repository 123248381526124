import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import classNames from 'classnames';
import CheckBox from 'Components/Checkbox';
import TextField from 'Components/TextField';
import CoverDatePicker from 'Components/CoverDatePicker';
import { updateStoreOrderAPI, setStoreOrder } from 'actions/order';
import OrderContext from './OrderContext';
import TaxRatesTableComponent from 'Components/TaxRatesTableComponent';
import { CurrencyField } from 'Components/currencyInput';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '13px 26px',
  },
  tableWrapper: {
    display: 'flex',
    flex: 1,
  },
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  sectionLabel: {
    height: 30,
    display: 'flex',
    paddingLeft: 13,
    marginTop: 13,
    alignItems: 'center',
  },
  moveDown: {
    marginTop: 13,
    marginRight: 0,
  },
  exemptContents: {
    display: 'flex',
    paddingTop: 8,
  },
  leftColumn: {
    minWidth: 115,
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  adjustmentsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  adjustmentsLabel: {
    color: theme.palette.grey[300],
    padding: '11px 13px',
  },
});

const dimensions = {
  width: 500,
  height: 585,
};

class TaxAndServices extends Component {
  state = {
    taxConfig: {
    },
  }

  componentDidMount() {
    this.setState({ order: this.props.order });
  }

  onSave = () => {
    const {
      updateStoreOrderAPI,
      onClose,
    } = this.props;

    updateStoreOrderAPI();
    onClose();
  }

  onCancel = () => {
    this.props.onClose();
  }

  onFieldChange = fieldName => value => {
    const order = {
      ...this.props.order,
      [fieldName]: value,
    };

    this.props.setStoreOrder(order);
  }

  onServiceChargeChange = value => {
    const order = {
      ...this.props.order,
      perItemServiceChargeOverride: value, //TODO: how to reset?
    };

    this.props.setStoreOrder(order);
  }

  setOrderRevenueTypeRate = rate => {
    const order = {
      ...this.props.order,
    };

    if (rate.orderId && order.orderRevenueTypeRates) {
      order.orderRevenueTypeRates = order.orderRevenueTypeRates.map(revenueTypeRate => revenueTypeRate.revenueTypeId === rate.revenueTypeId ? rate : revenueTypeRate);
    } else {
      rate.id = 0;

      if (order.orderRevenueTypeRates && order.orderRevenueTypeRates.length > 0) {
        order.orderRevenueTypeRates = order.orderRevenueTypeRates.filter(revenueTypeRate => revenueTypeRate.revenueTypeId !== rate.revenueTypeId);
        order.orderRevenueTypeRates.push(rate);
      }
      else {
        order.orderRevenueTypeRates = [rate];
      }
    }
    this.props.setStoreOrder(order);
  }

  handlePercentageChange = (taxTypeName, fieldName) => value => {
    const originalTaxSchedule = { ...this.state.taxConfig };

    const editedItem = originalTaxSchedule.taxConfigTypes.find(t => t.taxType.name === taxTypeName);

    editedItem[fieldName] = value;

    const newRevenueDetails = originalTaxSchedule.taxConfigTypes.map(item => item.taxTypeId === editedItem.taxTypeId ? editedItem : item);

    const newTaxSchedule = {
      ...originalTaxSchedule,
      taxConfigTypes: newRevenueDetails,
    };

    this.setState({ taxConfig: newTaxSchedule });
  };

  render() {
    const {
      isOpened,
      classes,
      order,
    } = this.props;

    return (<Modal
      isOpened={isOpened}
      dimensions={dimensions}
      onCancel={this.onCancel}
      onSave={this.onSave}
      title="Tax & Service"
      addTitleBottomBorder={true}
    >
      {order && <div className={classes.container}>
        <div className={classes.tableWrapper}>
          <TaxRatesTableComponent
            onRateUpdated={this.setOrderRevenueTypeRate}
            revenueConfiguration={order.revenueConfiguration}
            rateOverwrites={order.orderRevenueTypeRates}
          />
        </div>
        <div className={classNames(classes.header, classes.sectionLabel)}>
          Additional Adjustments
        </div>
        <div className={classes.adjustmentsContainer}>
          <div className={classes.adjustmentsLabel}>Service Charge</div>
          <CurrencyField
            name="perItemServiceCharge"
            value={order.perItemServiceChargeOverride || order.perItemServiceCharge}
            onFieldChange={this.onServiceChargeChange}
          />
        </div>
        <div className={classNames(classes.header, classes.sectionLabel)}>
          Exempt Status
        </div>
        <div className={classes.exemptContents}>
          <div className={classes.leftColumn}>
            <CheckBox
              className={classes.moveDown}
              label="Tax Exempt"
              checked={order.taxExempt}
              onFieldChange={this.onFieldChange('taxExempt')}
            />
          </div>
          <div className={classes.rightColumn}>
            <TextField
              margin="none"
              label="License #"
              value={order.taxExemptionLicenseNumber}
              onFieldChange={this.onFieldChange('taxExemptionLicenseNumber')}
              className={classes.license}
            />
            <CoverDatePicker
              label="Expiration"
              showClearButton={false}
              value={order.taxExemptionExpirationDate}
              onFieldChange={this.onFieldChange('taxExemptionExpirationDate')}
            />
          </div>
        </div>
      </div>}
    </Modal>
    );
  }
}
const mapDispatchToProps = {
  updateStoreOrderAPI,
  setStoreOrder,
};
const taxAndServicesWithContext = props => {
  const {
    onFinancialDetailsChanged,
    onChitChatUpdated,
    storeOrder,
  } = useContext(OrderContext);

  return (<TaxAndServices
    {...props}
    order={storeOrder}
    onFinancialDetailsChanged={onFinancialDetailsChanged}
    onChitChatUpdated={onChitChatUpdated}
  />);
};
const styledTaxAndServicesWithContext = withStyles(styles)(taxAndServicesWithContext);

export { styledTaxAndServicesWithContext as TaxAndServices };
export default connect(undefined, mapDispatchToProps)(styledTaxAndServicesWithContext);
