import { LocalizationService } from '@progress/kendo-react-intl';

class CustomLocalizationService extends LocalizationService {
  toLanguageString(key, default_value) {
    const languageString = super.toLanguageString(key, default_value);

    if (languageString) {
      return languageString;
    }

    //the Kendo LocalizationService returns null if no default_value provided
    //but I'd rather return the key
    return key;
  }
}

export default CustomLocalizationService;
