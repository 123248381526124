import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  uploadFile,
} from 'actions/file';
import {
  Popover,
  TextField,
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  IconButton,
  Collapse,
  Menu,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandIcon,
  Publish as UploadImageIcon,
  ArrowUpward as CategoryUpIcon,
  ArrowDownward as CategoryDownIcon,
  ColorLens as ColorLensIcon,
  Home,
} from '@material-ui/icons';
import SimpleDialog from 'Components/SimpleDialog';
import MenuCategoryItemCard from './MenuCategoryItemCard';
import {
  updateMenuCategory,
  updateMenuCategoryItem,
  workingMenuDeleteMenuCategory,
  workingMenuDeleteMenuCategoryItem,
  convertToMultipleMenuCategoryItems,
  toggleCategoryCollapse,
  workingMenuAddSubCategory,
  moveMenuCategory,
} from 'actions/masterMenuManagement';
import MenuItemSelectionModal from './MenuItemSelectionModal/MenuItemSelectionModal';
import MenuCategoryItemEditModal from './MenuCategoryItemEditModal';
import Avatar from 'Components/Avatar';
import Modal from 'Components/Modal';
import { ChromePicker } from 'react-color';

const styles = theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    paddingBottom: 32,
  },
  glances: {
    display: 'flex',
    flexGrow: 1,
    width: 0,
  },
  menuCategoryItems: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignContent: 'baseline',
    paddingBottom: 32,
  },
  title: {
    fontSize: 18,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  halfWidth: {
    width: '50%',
  },
  categoryImage: {
    height: '95px',
    position: 'relative',
    '&:hover $imageButtonsContainer': {
      visibility: 'visible',
    },
  },
  expandable: {
    transitionProperty: 'height',
    transitionDuration: '1s',
    '&:hover': {
      height: '300px',
    },
  },
  imageButtonsContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    backgroundColor: 'rgba(107, 105, 102, 0.40);',
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
  },
  imageButton: {
    color: 'white',
    width: 60,
    height: 60,
  },
  fileInput: {
    display: 'none',
  },
  subCategoryIndent: {
    marginLeft: 32,
  },
  colorSchemeCheckbox: {
    padding: '5px 17px 8px',
  },
});

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};
const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const MenuCategoryCard = ({
  classes,
  menuCategory,
  updateMenuCategory,
  updateMenuCategoryItem,
  workingMenuDeleteMenuCategory,
  workingMenuDeleteMenuCategoryItem,
  workingMenuAddSubCategory,
  convertToMultipleMenuCategoryItems,
  toggleCategoryCollapse,
  collapsedCategoryIds,
  formatter,
  uploadFile,
  uploadedFile,
  uploading,
  menuCategories,
  categoryLevel = 1,
  moveMenuCategory,
}) => {
  let [nameAnchorEl, setNameAnchorEl] = useState(null);
  const dialog = useRef();
  const [isMenuItemSelectionModalOpen, setIsMenuItemSelectionModalOpen] = useState(false);
  const [isRenamingMenuCategory, setIsRenamingMenuCategory] = useState(false);
  const [categoryName, setCategoryName] = useState(menuCategory.name);
  const [categoryImageUrl, setCategoryImageUrl] = useState(menuCategory.imageUrl);
  const [openedItem, setOpenedItem] = useState(null);
  const [optionsOpenedBy, setOptionsOpenedBy] = useState(null);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [menuCategoryColor, setMenuCategoryColor] = useState(menuCategory.color ? menuCategory.color : '');
  const [menuCategoryMainMenu, setMenuCategoryMainMenu] = useState(menuCategory.description ? menuCategory.description : false);

  useEffect(() => {
    setCategoryName(menuCategory.name);
    setCategoryImageUrl(menuCategory.imageUrl);
    setMenuCategoryColor(menuCategory.color ? menuCategory.color : '');
    setMenuCategoryMainMenu(menuCategory.description);
  }, [menuCategory]);

  const openMenuItemSelectionModal = () => { //todo, just need one modal
    setIsMenuItemSelectionModalOpen(true);
    setOptionsOpenedBy(null);
  };

  const closeMenuItemSelectionModal = () => {
    setIsMenuItemSelectionModalOpen(false);
  };

  const openItemModal = menuCategoryItem => {
    setOpenedItem(menuCategoryItem);
  };

  //updatedItem will be falsy if canceled
  const closeItemModal = updatedItem => {
    setOpenedItem(null);
    if (updatedItem) {
      updateMenuCategoryItem(updatedItem);
      //update the items
    }
  };

  const openRenamePopover = event => {
    event.stopPropagation();
    setNameAnchorEl(event.currentTarget);
    setIsRenamingMenuCategory(true);
  };

  const noPropagate = event => {
    event.stopPropagation();
  };

  const saveMenuCategoryName = () => {
    updateMenuCategory(menuCategory.id, 'name', categoryName);
    setNameAnchorEl(null);
    setIsRenamingMenuCategory(false);
  };

  const onEnterPressed = event => {
    if (event.key === 'Enter') {
      saveMenuCategoryName();
    }
  };

  const menuNameUpdated = event => {
    setCategoryName(event.target.value);
  };

  const onColorRendomChange = event => {
    if (menuCategoryColor) {
      setMenuCategoryColor('');
    }
  };

  const onColorChange = colorEvent => {
    setMenuCategoryColor(colorEvent.hex);
  };

  const onColorOk = event => {
    setColorModalOpen(false);
    const color = menuCategoryColor ? menuCategoryColor : null;

    if (color !== menuCategory.color) {
      updateMenuCategory(menuCategory.id, 'color', color);
    }
  };

  const onMenuCategoryMainMenu = event => {
    let addToMainMenu = 'false';

    console.log(menuCategoryMainMenu);

    if (menuCategoryMainMenu === 'false') {
      addToMainMenu = 'true';
    }

    setMenuCategoryMainMenu(addToMainMenu);
    updateMenuCategory(menuCategory.id, 'description', addToMainMenu);
  };

  const renameTitleComponent = categoryName => {
    return (
      <>
        <div
          onClick={openRenamePopover}
          ref={node => { nameAnchorEl = node; }}
        >
          {categoryName || 'New Category'}
        </div>
        <Popover
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          anchorEl={nameAnchorEl}
          className={classes.inFrontOfModal}
          onClose={saveMenuCategoryName}
          open={isRenamingMenuCategory}>
          <TextField
            autoFocus={true}
            defaultValue={categoryName}
            onClick={noPropagate}
            onBlur={saveMenuCategoryName}
            onChange={menuNameUpdated}
            onKeyPress={onEnterPressed}
          />
        </Popover>
      </>
    );
  };

  const onMultipleMenuItemsAdded = menuItems => {
    convertToMultipleMenuCategoryItems(menuCategory.id, menuItems);
    closeMenuItemSelectionModal();
  };

  const deleteMenuCategory = () => {
    dialog.current.open('Are you sure you want to delete this menu category?').then(() => {
      workingMenuDeleteMenuCategory(menuCategory.id);
    });
  };

  const deleteMenuCategoryItem = menuCategoryItem => {
    dialog.current.open('Are you sure you want to remove this item?').then(() => {
      workingMenuDeleteMenuCategoryItem(menuCategoryItem.menuCategoryId, menuCategoryItem.id);
    });
  };

  const filterAndSort = menuCategoryItems => {
    return menuCategoryItems.filter(m => m.recordStatus === 'Active')
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });
  };

  const isExpanded = () => {
    return collapsedCategoryIds.indexOf(menuCategory.id) < 0;
  };

  const toggleCollapse = () => {
    toggleCategoryCollapse(menuCategory.id);
  };

  const MenuItemList = React.memo(function MenuItemList({ menuCategoryItems, provided }) {
    return (
      <div className={classes.menuCategoryItems}>
        {filterAndSort(menuCategoryItems).map((menuCategoryItem, index) =>
          <MenuCategoryItemCard
            menuCategoryItem={menuCategoryItem}
            index={index}
            provided={provided}
            key={menuCategoryItem.id}
            formatter={formatter}
            onDelete={() => deleteMenuCategoryItem(menuCategoryItem)}
            onEdit={() => openItemModal(menuCategoryItem)}
          />
        )}
      </div>
    );
  });

  const uploadCategoryImage = event => {
    if (event.target.files.length) {
      const formData = new FormData();

      formData.append('file', event.target.files[0]);
      uploadFile('menuCategory', menuCategory.id, 'public', formData).then(
        response => {
          setCategoryImageUrl(response.url);
          updateMenuCategory(menuCategory.id, 'imageUrl', response.url);
        }
      );
    }
  };

  const deleteCategoryImage = () => {
    setCategoryImageUrl(null);
    updateMenuCategory(menuCategory.id, 'imageUrl', null);
  };

  const resetValue = event => {
    // To enable re-upload of same file
    event.target.value = null;
  };

  const addSubCategory = () => {
    if (categoryLevel && categoryLevel < 3) {
      workingMenuAddSubCategory(menuCategory.id);
    }
    setOptionsOpenedBy(null);
  };

  const openOptionsMenu = event => {
    setOptionsOpenedBy(event.currentTarget);
  };

  const renderSubCategories = level => {
    const nextLevel = level + 1;

    return (
      <div className={classes.subCategoryIndent}>
        {subCategories.map(subCategory =>
          <MenuCategoryCardStyledWithConnect
            menuCategory={subCategory}
            key={subCategory.id}
            formatter={formatter}
            categoryLevel={nextLevel}
          />
        )
        }
      </div>
    );
  };

  const getSubCategories = () => {
    return menuCategories.filter(category => {
      return category.parentMenuCategoryId === menuCategory.id && category.recordStatus === 'Active';
    });
  };

  const getSubtitle = () => {
    let subtitle = '';
    const itemCount = menuCategory.menuCategoryItems ? menuCategory.menuCategoryItems.length : 0;
    const subCategoryCount = getSubCategories().length;

    if (itemCount > 0 || (itemCount === 0 && subCategoryCount === 0)) {
      subtitle = `${menuCategory.menuCategoryItems.filter(i => i.recordStatus === 'Active').length} Item${itemCount === 1 ? '' : 's'}`;
    }

    if (subCategoryCount > 0) {
      if (itemCount > 0) subtitle += ' | ';

      subtitle += `${subCategoryCount} Sub-categor${subCategoryCount === 1 ? 'y' : 'ies'}`;
    }

    return subtitle;
  };

  const moveCategory = direction => event => {
    event.stopPropagation();

    return moveMenuCategory(menuCategory.id, direction);
  };

  const subCategories = getSubCategories();

  return (
    <>
      <Card className={classes.card}>
        <Droppable
          droppableId={menuCategory.id.toString()}
          type="menuItem"
        >
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className={classNames(classes.categoryImage, categoryImageUrl ? classes.expandable : '' )}>
                {!!categoryImageUrl ?
                  <CardMedia
                    className={classNames(classes.categoryImage, categoryImageUrl ? classes.expandable : '' )}
                    image={categoryImageUrl}
                    title={categoryName}
                  >
                    <div className={classes.imageButtonsContainer}>
                      <IconButton component="label" onClick={resetValue}>
                        <UploadImageIcon className={classes.imageButton} />
                        <input
                          accept="image/*"
                          className={classes.fileInput}
                          type="file"
                          onChange={uploadCategoryImage}
                          hidden
                        />
                      </IconButton>
                      <IconButton onClick={deleteCategoryImage}>
                        <DeleteIcon className={classes.imageButton} />
                      </IconButton>
                    </div>
                  </CardMedia>
                  :
                  <Avatar className={classes.avatar} name={categoryName} color={menuCategoryColor}>
                    <div className={classes.imageButtonsContainer}>
                      <IconButton component="label" onClick={resetValue}>
                        <UploadImageIcon className={classes.imageButton} />
                        <input
                          accept="image/*"
                          className={classes.fileInput}
                          type="file"
                          onChange={uploadCategoryImage}
                          hidden
                        />
                      </IconButton>
                    </div>

                  </Avatar>
                }
                <input
                  accept="image/*"
                  className={classes.fileInput}
                  id={`button-file-menuCategory-${menuCategory.id}`}
                  multiple
                  type="file"
                  onChange={uploadCategoryImage}
                  onClick={resetValue}
                  hidden
                />
              </div>
              <CardHeader
                title={renameTitleComponent(categoryName)}
                classes={{ title: classes.title }}
                subheader={getSubtitle()}
                action={
                <>
                  <IconButton
                    style={menuCategoryMainMenu === 'true' ? { color: 'green' } : { color: 'gray' }}
                    onClick={() => onMenuCategoryMainMenu()}
                  >
                    <Home />
                  </IconButton>
                  <IconButton
                    style={menuCategoryColor ? { color: menuCategoryColor } : {}}
                    onClick={() => setColorModalOpen(true)}
                  >
                    <ColorLensIcon />
                  </IconButton>
                  <IconButton onClick={moveCategory('up')}>
                    <CategoryUpIcon />
                  </IconButton>
                  <IconButton onClick={moveCategory('down')}>
                    <CategoryDownIcon />
                  </IconButton>
                  <IconButton onClick={deleteMenuCategory}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={openOptionsMenu}>
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    className={classNames(classes.expand, isExpanded() && classes.expandOpen)}
                    onClick={toggleCollapse}
                    aria-expanded={isExpanded()}
                    aria-label="show more"
                  >
                    <ExpandIcon />
                  </IconButton>
                </>
                }
              />
              <Collapse in={isExpanded()} timeout="auto" unmountOnExit>
                <CardContent className={classes.content}>
                  <MenuItemList
                    menuCategoryItems={menuCategory.menuCategoryItems}
                    provided={provided}
                  />
                </CardContent>
              </Collapse>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {isMenuItemSelectionModalOpen &&
        <MenuItemSelectionModal
          isImagesOpenedFirst={true}
          onModalClosed={closeMenuItemSelectionModal}
          onSave={onMultipleMenuItemsAdded}
        />}
        {openedItem && <MenuCategoryItemEditModal item={openedItem} onModalClosed={closeItemModal} />}
        <SimpleDialog innerRef={dialog} />
      </Card>
      <Collapse in={isExpanded()} timeout="auto" unmountOnExit>
        {subCategories.length > 0 && renderSubCategories(categoryLevel)}
      </Collapse>
      <Menu
        id="options-menu"
        anchorEl={optionsOpenedBy}
        open={Boolean(optionsOpenedBy)}
        onClose={() => setOptionsOpenedBy(null)}
      >
        <MenuItem onClick={openMenuItemSelectionModal}>Add Multiple Items</MenuItem>
        {categoryLevel && categoryLevel < 3 && <MenuItem onClick={addSubCategory}>Add Sub-category</MenuItem>}
      </Menu>
      <Modal
        isOpened={colorModalOpen}
        onSave={onColorOk}
        saveText="Ok"
        showCancel={false}
        title={"Menu color"}
        addTitleBottomBorder={true}
        dimensions={{ width: 225, height: 396, maxWidth: 'unset', maxHeight: 'unset' }}
      >
        <ChromePicker
          disableAlpha={true}
          color={menuCategoryColor}
          onChangeComplete={onColorChange}
        />
        <div>
          <Checkbox
            className={classes.colorSchemeCheckbox}
            checked={!menuCategoryColor}
            onChange={onColorRendomChange}
          />
          <Link to="/admin/settings">
            Random Scheme Color
          </Link>
        </div>
      </Modal >
    </>
  );
};

const mapStateToProps = state => {
  const {
    masterMenuManagement: {
      collapsedCategoryIds,
      menuCategories,
    },
    file: {
      uploadedFile,
      uploading,
    },
  } = state;

  return {
    collapsedCategoryIds,
    uploading,
    uploadedFile,
    menuCategories,
  };
};

const mapDispatchToProps = {
  updateMenuCategory,
  uploadFile,
  updateMenuCategoryItem,
  workingMenuDeleteMenuCategory,
  workingMenuDeleteMenuCategoryItem,
  convertToMultipleMenuCategoryItems,
  toggleCategoryCollapse,
  workingMenuAddSubCategory,
  moveMenuCategory,
};

const MenuCategoryCardStyledWithConnect = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuCategoryCard));

export default MenuCategoryCardStyledWithConnect;

