import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import classNames from 'classnames';

const styles = theme => ({
  coverDateTime: {
    '& input.e-control.e-datetimepicker': {
      color: theme.palette.grey[300],
      fontSize: '15px',
      fontFamily: `${theme.typography.fontFamily} !important`,
      '&.e-disabled': {
        cursor: 'default',
      },
      '&::selection': {
        background: theme.palette.action.inputHighlight,
        textShadow: 'none',
        color: theme.palette.grey[900],
      },
    },
    '& td.e-selected span.e-day': {
      background: `${theme.palette.primary.dark} !important`, //currently selected day
    },
    '& .e-btn': {
      color: `${theme.palette.primary.dark} !important`, //today button
    },
    '& input::placeholder': {
      fontSize: '15px',
      color: `${theme.palette.grey[200]} !important`,
    },
  },
  noErrors: {
    '& $coverDateTime': {
      borderBottomColor: `transparent !important`,
    },
  },
  errors: {
    '& $coverDateTime': {
      borderBottomColor: `${theme.palette.error.main} !important`,
    },
    '& .e-icons': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  pickContainer: {
    flexGrow: 1,
  },
});

class ActivityDateTimePicker extends Component {

  render() {
    const { onChange, value, label, name, classes, minDate, maxDate, error, className, floatLabelType, ...rest } = this.props;

    return (
      <div className={classNames(classes.pickContainer, className, error ? classes.errors : classes.noErrors)} >
        <DateTimePickerComponent
          {...rest}
          id={name}
          value={value || ''}
          change={onChange}
          min={minDate}
          max={maxDate}
          strictMode="true"
          cssClass={className || classes.coverDateTime}
          floatLabelType={floatLabelType || "Auto"}
          placeholder={label}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ActivityDateTimePicker);
