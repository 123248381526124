import React from 'react';
import TextField from 'Components/TextField';
import StateProvinceSelect from 'Components/StateProvinceSelect';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  fullWidth: {
    display: 'flex',
    width: '100%',
  },
  halfWidth: {
    display: 'flex',
    width: '50%',
  },
  fieldIsEdited: {
    background: theme.palette.error.light,
  },
});

const AddressFields = ({ classes, address, onFieldChange, isFieldChanged, isEditing }) => {

  return <>
    <div className={classNames(classes.fullWidth, isFieldChanged && isFieldChanged('address1') && classes.fieldIsEdited)}>
      <TextField
        label="Street 1"
        onFieldChange={onFieldChange('address1')}
        name="address1"
        value={address.address1}
        disabled={!isEditing}
      />
    </div>
    <div className={classNames(classes.fullWidth, isFieldChanged && isFieldChanged('address2') && classes.fieldIsEdited)}>
      <TextField
        label="Street 2"
        onFieldChange={onFieldChange('address2')}
        name="address2"
        value={address.address2}
        disabled={!isEditing}
      />
    </div>

    <div className={classNames(classes.fullWidth, isFieldChanged && isFieldChanged('city') && classes.fieldIsEdited)}>
      <TextField
        label="City"
        onFieldChange={onFieldChange('city')}
        name="city"
        value={address.city}
        disabled={!isEditing}
      />
    </div>
    <div className={classes.fullWidth}>
      <div className={classNames(classes.halfWidth, isFieldChanged && isFieldChanged('stateProvince') && classes.fieldIsEdited)}>
        <StateProvinceSelect
          value={address.stateProvince}
          onFieldChange={onFieldChange('stateProvince')}
          disabled={!isEditing}
        />
      </div>
      <div className={classNames(classes.halfWidth, isFieldChanged && isFieldChanged('postalCode') && classes.fieldIsEdited)}>
        <TextField
          label="Postal Code"
          onFieldChange={onFieldChange('postalCode')}
          name="postalCode"
          value={address.postalCode}
          disabled={!isEditing}
        />
      </div>
    </div>
  </>;
};

export default withStyles(styles)(AddressFields);
