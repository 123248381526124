import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  main: {
    height: '100%',
    display: 'flex',
  },
  logo: {
    paddingTop: '48px',
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: '36px',
    paddingTop: '43px',
    paddingBottom: '41px',
    //textAlign: 'center',
  },
  loginForm: {
    width: '518px',
    margin: '28px',
    textAlign: 'center',
  },
  imageArea: {
    backgroundImage: 'url("/images/login.jpeg")',
    flexGrow: 1,
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

});

class Master extends Component {
  state = { showUsername: true };

  render() {
    const { classes, title, children } = this.props;

    return (
      <div className={classes.main}>
        <div className={classes.loginForm}>
          <img src="/images/loginLogo.png" alt="logo" className={classes.logo}></img>
          <h1 className={classes.header}>{title}</h1>
          {children}
        </div>
        <div className={classes.imageArea}>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Master);
