export const apiKey = window.env.REACT_APP_GOOGLE_API_KEY;

export const componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name',
};

export const fieldMap = {
  street_number: 'address1Number',
  route: 'address1Route',
  locality: 'city',
  country: 'country',
  administrative_area_level_1: 'stateProvince',
  postal_code: 'postalCode',
};

export const geocodePlaceId = placeId => {
  return fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${apiKey}`)
    .then(response => response.json())
    .then(response => response.results[0]);
};

export const handleAddressSearchDone = (selection, onFieldChange) => {
  if (selection && selection.place_id) {
    return geocodePlaceId(selection.place_id).then(results => {
      if (results) {
        const latLng = results.geometry.location;

        fillInAddress(results.address_components, onFieldChange);
        onFieldChange('latitude')(latLng.lat);
        onFieldChange('longitude')(latLng.lng);
      } else {
        console.log('no results for some reason:', results);
      }
    });
  }
};

let addressParts = { address1Number: null, address1Route: null };

// TODO: clear out fields that are not provided by Google
const fillInAddress = (addressComponents, onFieldChange) => {
  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  if (!!addressComponents) {
    for (let i = 0; i < addressComponents.length; i++) {
      const addressType = addressComponents[i].types[0];
      const googleComponentName = componentForm[addressType];

      if (!!googleComponentName) {
        const value = addressComponents[i][googleComponentName];
        const fieldName = fieldMap[addressType];

        fillInField(fieldName, value, onFieldChange);
      }
    }

    const { address1Number, address1Route } = addressParts;
    let address1 = '';

    if (address1Number && address1Route) {
      // after street address components are retrieved, combine them into one string
      address1 = `${address1Number} ${address1Route}`;
    } // else set to empty string if address not provided by place fetch
    onFieldChange('address1')(address1);
  }
};

const fillInField = (fieldName, value, onFieldChange) => {
  try {
    switch (fieldName) {
      case 'address1Number':
      case 'address1Route':
        const newAddressParts = {
          ...addressParts,
          [fieldName]: value,
        };

        addressParts = newAddressParts;
        break;
      default:
        onFieldChange(fieldName)(value);
    }
  }
  catch (error) {
    console.error(fieldName + 'not found in Google results. Skipping...');
  }
};
