import React from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

//should be able to use like
//<SelectedColumn items={items} />
//except the Kendo react library doesn't like wrapping the GridColumn with another component
//so for now do this..
//{SelectedColumn({items})}
const SelectedColumn = (items, isVisible = true) => (
  <GridColumn
    filterable={false}
    field="selected"
    width={isVisible ? "50px" : '0px'}
    key="selected"
    headerSelectionValue={
      !!(items && items.length && items.every(item => item.selected))
    }
  />
);

export default SelectedColumn;
