import React, { Component } from 'react';
import { connect } from 'react-redux';
import SmallGrid from 'Components/Grid/SmallGrid';
import _ from 'lodash';
import moment from 'moment';
import { getSalesRepsIfNeeded } from 'actions/contact';
import { buildGridCellLink } from 'Components/buildGridCellLink';
import { GridColumn } from '@progress/kendo-react-grid';

class SmallBookingGrid extends Component {

  state = {
    salesRepsMap: {},
  };

  componentDidMount() {
    this.props.getSalesRepsIfNeeded();
    this.setupSalesReps(this.props.salesRepsData);
  }

  setupSalesReps = salesReps => {
    const salesRepsMap = {};

    salesReps && salesReps.forEach(s => salesRepsMap[s.id] = s.name);
    this.setState({ salesRepsMap });
  };

  linkToBooking = buildGridCellLink({
    url: booking => `/bookings/${booking.id}`,
    text: booking => booking.name,
  });

  render() {
    const { bookings } = this.props;
    const { salesRepsMap } = this.state;

    if (bookings && bookings.length < 1) return null;

    return (
      <SmallGrid data={bookings}>
        <GridColumn key="name" field="name" title="Booking Name" cell={this.linkToBooking}/>
        <GridColumn key="status" field="status" title="Status" cell={props => (
          <td>{_.startCase(props.dataItem.status)}</td>
        )}
        />
        <GridColumn key="phone" field="account.phone" title="Account Phone" />
        <GridColumn key="contact" field="contact.name" title="Contact" />
        <GridColumn
          key="dateBooked"
          field="dateBooked"
          title="Date Booked"
          cell={props => (
            <td>{moment(props.dataItem.dateBooked).format('l')}</td>
          )}
        />
        <GridColumn
          key="salesRepId"
          field="salesRepId"
          title="Sales Rep"
          cell={props => <td>{salesRepsMap[props.dataItem.salesRepId]}</td>}
        />
      </SmallGrid>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      salesReps: {
        salesRepsData,
      },
    },
  } = state;

  return {
    salesRepsData,
  };
};

const mapDispatchToProps = {
  getSalesRepsIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmallBookingGrid);
