import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import Modal from 'Components/Modal';
import TextField from 'Components/TextField';
import SectionHeader from 'Components/SectionHeader';
import CheckBox from 'Components/Checkbox';
import CoverDatePicker from 'Components/CoverDatePicker';
import { CurrencyField } from 'Components/currencyInput';
import ValidationBanner from 'Components/ValidationBanner';
import BookingContext from 'bookings/BookingContext';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  addDeposit,
  updateDeposit,
} from 'actions/booking';

const styles = theme => {
  return {
    overflowIfTooBig: {
      overflow: 'auto',
      height: 300,
    },
    container: {
      display: 'flex',
      width: '100%',
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 12,
    },
    flex: {
      display: 'flex',
    },
    sectionHeader: {
      paddingLeft: 12,
      marginTop: 16,
    },
    section: {
      display: 'flex',
      flex: '1 1 auto',
    },
    oneThirdWidth: {
      display: 'flex',
      width: '33%',
    },
    twoThirdWidth: {
      display: 'flex',
      width: '66%',
    },
    fullWidth: {
      display: 'flex',
      width: '100%',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
};

const comma = ", ";
const and = " and ";

class AddEditDepositModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deposit: props.deposit,
      errors: undefined,
    };

    this.confirmDeleteFileDialog = React.createRef();
    this.validationBanner = React.createRef();
  }

  handleFieldChange = (fieldName, parser) => value => {
    const deposit = {
      ...this.state.deposit,
      [fieldName]: parser ? parser(value) : value,
    };

    this.setState({ deposit });
  }

  handleCheckbox = fieldName => event => {
    const value = event.target.checked;

    const deposit = {
      ...this.state.deposit,
      [fieldName]: value,
    };

    this.setState({ deposit });
  }

  onSave = () => {
    const { id, addDeposit, updateDeposit, onRefresh, onCancel } = this.props;
    const { deposit } = this.state;
    let errors = this.getErrors();

    if (errors) {
      errors = this.formatErrorWithAnd(errors);
      this.validationBanner.current.open(errors);
    } else {
      deposit.id === undefined ?
        addDeposit(id, deposit).then(onRefresh).then(onCancel) :
        updateDeposit(id, deposit).then(onRefresh).then(onCancel);
    }
  }

  isFieldProvided(field) {
    if (field === undefined || field == null || field.length < 1) {
      return false;
    }

    return true;
  }

  isCurrencyFieldProvided(field) {
    if (field === undefined || field == null || isNaN(field) || field < 1) {
      return false;
    }

    return true;
  }

  addError(errors, newError) {
    if (errors === undefined || errors === null || errors.length === 0) {
      return newError;
    } else {
      return errors + comma + newError;
    }
  }

  formatErrorWithAnd(errors) {
    const lastComma = errors.lastIndexOf(comma);

    if (lastComma >= 0) {
      const endOfLastComma = lastComma + comma.length;
      let newErrors = errors.substring(0, lastComma) + and + errors.substring(endOfLastComma);

      return newErrors;
    } else {
      return errors;
    }
  }

  getErrors = () => {
    const { deposit } = this.state;
    let validationErrors = '';

    if (!this.isFieldProvided(deposit.dueDate)) {
      validationErrors = this.addError(validationErrors, 'Due Date');
    }
    if (!this.isCurrencyFieldProvided(deposit.dueAmount)) {
      validationErrors = this.addError(validationErrors, 'Due Amount');
    }

    validationErrors = validationErrors ? validationErrors + ' must be provided.' : undefined;

    return validationErrors;
  }

  render() {
    const {
      classes,
      onCancel,
    } = this.props;

    const {
      deposit,
      errors,
    } = this.state;

    return (
      <Modal
        isOpened={true}
        onCancel={onCancel}
        title={deposit && deposit.id === undefined ? "Add New Deposit" : "Edit Deposit"}
        onSave={this.onSave}
        addTitleBottomBorder={false}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '525px' }}
      >
        <Paper className={classes.overflowIfTooBig}>
          <div className={classNames(classes.container, classes.flexColumn)}>
            <div className={classNames(classes.section, classes.flexColumn)}>
              <SectionHeader className={classes.sectionHeader}>Deposit Details</SectionHeader>
              <div className={classes.flex}>
                <div className={classNames(classes.flexColumn)}>
                  <div className={classNames(classes.flex)}>
                    <div className={classNames(classes.oneThirdWidth)}>
                      <CoverDatePicker
                        label="Due Date"
                        fieldName="dueDate"
                        error={errors && errors.dueDate}
                        value={deposit.dueDate}
                        onFieldChange={this.handleFieldChange('dueDate')}
                        minDate={moment().subtract(10, 'years').toDate()}
                      />
                    </div>
                    <div className={classNames(classes.oneThirdWidth)}>
                      <CurrencyField
                        label="Due Amount"
                        fieldName="dueAmount"
                        value={deposit.dueAmount}
                        onFieldChange={this.handleFieldChange('dueAmount', parseFloat)}
                      />
                    </div>
                    <div className={classNames(classes.oneThirdWidth)}>
                      <CheckBox
                        label="Paid"
                        checked={deposit.isPaid}
                        onChange={this.handleCheckbox('isPaid')}
                        disabled={(deposit.isPaid && (deposit.id > 0))}  // don't allow paid checkbox to be unchecked for existing deposits
                      />
                    </div>
                  </div>
                  <div className={classNames(classes.flex)}>
                    <div className={classNames(classes.fullWidth)}>
                      <TextField
                        label="Comment"
                        value={deposit.comment}
                        onFieldChange={this.handleFieldChange('comment')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
        <ValidationBanner
          innerRef={this.validationBanner}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  addDeposit,
  updateDeposit,
};

const AddEditDepositModalContainer = props => {
  const {
    onBookingChanged,
  } = useContext(BookingContext);

  return (<AddEditDepositModal
    {...props}
    onBookingChanged={onBookingChanged}
  />);
};

export default withStyles(styles)(connect(undefined, mapDispatchToProps)(AddEditDepositModalContainer));
