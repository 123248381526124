import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import OrderList from './OrderList';
import OrderNew from './OrderNew';
import Order from './Order';
import { Route } from 'react-router-dom';

class Orders extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/orders" component={OrderList} />
        <Route exact path="/orders/new" component={OrderNew} />
        <Route path="/orders/:id" component={Order} />
      </Switch>
    );
  }
}

export default withRouter(Orders);
