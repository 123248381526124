import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import Header from 'Components/Header';
import EmailTemplates from './EmailTemplates';
import EmailAutomation from './EmailAutomation';
import EmailAccounts from './EmailAccounts';

const styles = theme => ({
  EmailManagement: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    minWidth: 0,
  },
  tabContainer: {
    height: 40,
    minHeight: 40,
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingBottom: 24,
  },
  content: {
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const EmailManagement = ({
  classes,
}) => {
  const tabs = ['Templates', 'Automation', 'Accounts'];
  const [tabIndex, setTabIndex] = useState(0);

  return (<>
    <div className={classes.header}>
      <Header>Email Management</Header>
    </div>
    <div className={classes.EmailManagement}>

      <div className={classes.tabContainer}>
        <BlueTextTab
          onTabChange={setTabIndex}
          tabs={tabs}
          tabIndex={tabIndex}
        />
      </div>
      <div className={classes.content}>
        {tabIndex === 0 &&
          <EmailTemplates />
        }
        {tabIndex === 1 &&
          <EmailAutomation />
        }
        {tabIndex === 2 &&
          <EmailAccounts />
        }
      </div>
    </div>
  </>);
};

export default withStyles(styles)(EmailManagement);
