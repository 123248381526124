import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import Table from 'Components/Table';
import classNames from 'classnames';
import TextField from 'Components/TextField';
import PercentageField from 'Components/PercentageField';
import { CurrencyField } from 'Components/currencyInput';
import {
  getDiscountsConfig,
  updateDiscountsConfig,
} from 'actions/booking';
import IconButton from 'Components/Buttons/IconButton';
import {
  FileCopyOutlined,
} from '@material-ui/icons';

import BookingContext from 'bookings/BookingContext';

const styles = theme => ({
  container: {
    padding: '13px 26px',
  },
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  table: {
    fontSize: 12,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    '& th': {
      padding: '6px 13px',
    },
    '& tr': {
      borderTop: 0,
      borderBottom: 0,
    },
  },
  borderedCell: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
  },
  rowLabel: {
    color: theme.palette.grey[300],
    paddingTop: 11,
    paddingBottom: 11,
  },
  centerText: {
    textAlign: 'center',
  },
  sectionLabel: {
    height: 30,
    display: 'flex',
    paddingLeft: 13,
    marginTop: 13,
    alignItems: 'center',
  },
  moveDown: {
    marginTop: 13,
    marginRight: 0,
  },
  exemptContents: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftColumn: {
    minWidth: 126,
  },
  rightColumn: {
    flexGrow: 1,
  },
});

const dimensions = {
  width: 320,
  height: 580,
};

class Discounts extends Component {
  state = {
    discountConfig: {
    },
  }

  componentDidUpdate(prevProps) {
    const {
      getDiscountsConfig,
      isOpened,
      bookingId,
    } = this.props;

    if (isOpened && !prevProps.isOpened) {
      getDiscountsConfig(bookingId).then(discountConfig => this.setState({ discountConfig }));
    }
  }

  onSave = () => {
    const {
      updateDiscountsConfig,
      bookingId,
      onClose,
      onFinancialDetailsChanged,
      onChitChatUpdated,
    } = this.props;

    updateDiscountsConfig(bookingId, this.state.discountConfig).then(() => {
      onClose();
      onFinancialDetailsChanged();
      onChitChatUpdated();
    });
  }

  onCancel = () => {
    this.props.onClose();
  }

  copyToAll = newAmount => () => {
    const discountConfig = {
      ...this.state.discountConfig,
      foodDiscount: newAmount,
      beverageDiscount: newAmount,
      liquorDiscount: newAmount,
      laborDiscount: newAmount,
      equipmentDiscount: newAmount,
    };

    this.setState({ discountConfig });
  }

  onFieldChange = fieldName => value => {
    const discountConfig = {
      ...this.state.discountConfig,
      [fieldName]: value,
    };

    this.setState({ discountConfig });
  }

  render() {
    const {
      isOpened,
      classes,
    } = this.props;

    const {
      discountConfig,
    } = this.state;

    return (<Modal
      isOpened={isOpened}
      dimensions={dimensions}
      onCancel={this.onCancel}
      onSave={this.onSave}
      title="Discounts & Promo"
      addTitleBottomBorder={true}
    >
      <div className={classes.container}>
        <Table
          className={classes.table}
          header={
            <tr className={classes.header}>
              <th scope="col">Type</th>
              <th scope="col" colspan="2" className={classes.centerText}>Discount</th>
            </tr>
          }
        >
          <tr>
            <th scope="row" className={classes.rowLabel}>Food</th>
            <td className={classes.borderedCell}>
              <PercentageField
                value={discountConfig.foodDiscount}
                onFieldChange={this.onFieldChange('foodDiscount')}
              />
            </td>
            <td className={classes.centerText}>
              <IconButton
                height={40}
                icon={FileCopyOutlined}
                onClick={this.copyToAll(discountConfig.foodDiscount)}
              />
            </td>
          </tr>
          <tr>
            <th scope="row" className={classes.rowLabel}>Beverage</th>
            <td className={classes.borderedCell}>
              <PercentageField
                value={discountConfig.beverageDiscount}
                onFieldChange={this.onFieldChange('beverageDiscount')}
              />
            </td>
            <td className={classes.centerText}>
              <IconButton
                height={40}
                icon={FileCopyOutlined}
                onClick={this.copyToAll(discountConfig.beverageDiscount)}
              />
            </td>
          </tr>
          <tr>
            <th scope="row" className={classes.rowLabel}>Liquor</th>
            <td className={classes.borderedCell}>
              <PercentageField
                value={discountConfig.liquorDiscount}
                onFieldChange={this.onFieldChange('liquorDiscount')}
              />
            </td>
            <td className={classes.centerText}>
              <IconButton
                height={40}
                icon={FileCopyOutlined}
                onClick={this.copyToAll(discountConfig.liquorDiscount)}
              />
            </td>
          </tr>
          <tr>
            <th scope="row" className={classes.rowLabel}>Equipment</th>
            <td className={classes.borderedCell}>
              <PercentageField
                value={discountConfig.equipmentDiscount}
                onFieldChange={this.onFieldChange('equipmentDiscount')}
              />
            </td>
            <td className={classes.centerText}>
              <IconButton
                height={40}
                icon={FileCopyOutlined}
                onClick={this.copyToAll(discountConfig.equipmentDiscount)}
              />
            </td>
          </tr>
          <tr>
            <th scope="row" className={classes.rowLabel}>Labor</th>
            <td className={classes.borderedCell}>
              <PercentageField
                value={discountConfig.laborDiscount}
                onFieldChange={this.onFieldChange('laborDiscount')}
              />
            </td>
            <td className={classes.centerText}>
              <IconButton
                height={40}
                icon={FileCopyOutlined}
                onClick={this.copyToAll(discountConfig.laborDiscount)}
              />
            </td>
          </tr>
        </Table>
        <div className={classes.exemptContents}>
          <CurrencyField
            margin="none"
            label="Fixed Dollar Amount"
            value={discountConfig.flatDiscount}
            onFieldChange={this.onFieldChange('flatDiscount')}
          />
          <TextField
            margin="none"
            label="Comment"
            value={discountConfig.comment}
            onFieldChange={this.onFieldChange('comment')}
          />
        </div>
        <div className={classNames(classes.header, classes.sectionLabel)}>
          Promo Code
        </div>
        <div className={classes.exemptContents}>
          <TextField
            margin="none"
            label="Promo"
            value={discountConfig.promoCode}
            onFieldChange={this.onFieldChange('promoCode')}
          />
        </div>
      </div>
    </Modal>
    );
  }
}
const mapDispatchToProps = {
  getDiscountsConfig,
  updateDiscountsConfig,
};
const taxAndServicesWithContext = props => {
  const {
    onFinancialDetailsChanged,
    onChitChatUpdated,
  } = useContext(BookingContext);

  return (<Discounts
    {...props}
    onFinancialDetailsChanged={onFinancialDetailsChanged}
    onChitChatUpdated={onChitChatUpdated}
  />);
};
const styledDiscountsWithContext = withStyles(styles)(taxAndServicesWithContext);

export { styledDiscountsWithContext as Discounts };
export default connect(undefined, mapDispatchToProps)(styledDiscountsWithContext);
