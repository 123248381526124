import { SET_LOCALE } from 'actions';

const initialState = {
  locale: 'en-US',
  language: 'en',
};

export function locale(state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
      const { locale } = action;
      const languageDashCountry = locale.split('-');//assuming all locals in format '{language}-{region/county}'

      return {
        ...state,
        locale: locale,
        language: languageDashCountry[0],
      };
    default:
      return state;
  }
}
