import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemIcon } from '@material-ui/core';

const drawerCollapsedWidth = 80; //for screen sizes above small

const styles = theme => ({
  link: {
    display: 'flex',
    height: 62,
    '&:hover': {
      borderRightColor: theme.palette.secondary.main,
      '& $listItem': {
        background: lighten(theme.palette.secondary.main, 0.75),
        borderRightColor: theme.palette.secondary.main,
      },
    },
  },
  activeLink: {
    background: theme.palette.secondary.main,
    '& svg, & $listText': {
      color: theme.palette.background.paper,
    },
    '& $listItem': {
      borderRightColor: theme.palette.secondary.main,
    },
  },
  listIcons: {
    margin: 0,
    color: theme.common.navy,
    overflow: 'visible',
    width: 30,
    [theme.breakpoints.up('sm')]: {
      width: drawerCollapsedWidth,
    },
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  listText: {
    color: theme.palette.grey[800],
    fontSize: 15,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    borderRightWidth: 3,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.background.paper,
  },
});

const CoverNavLink = props => {
  const { classes, icon, name } = props;

  return (
    <NavLink
      {...props} // this component is mainly to sync styling, so be sure to follow react-router NavLink API
      className={classes.link}
      activeClassName={classes.activeLink}
    >
      <ListItem button className={classes.listItem}>
        <ListItemIcon classes={{ root: classes.listIcons }}>
          {icon}
        </ListItemIcon>
        <div className={classes.listText}>
          {name}
        </div>
      </ListItem>
    </NavLink>
  );
};

export default withStyles(styles)(CoverNavLink);
