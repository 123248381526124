import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { provideIntlService } from '@progress/kendo-react-intl';
import classNames from 'classnames';

const styles = theme => ({
  contentSection: {
    color: theme.common.black,
    fontSize: 15,
  },
  label: {
    color: theme.palette.primary.main,
  },
});

class SubTotal extends Component {

  render() {
    const { classes, order, className } = this.props;

    return (
      <div className={classNames(className, classes.contentSection)}>
        <div>
          <span className={classes.label}>Financial Details</span>
        </div>
        <div>
          <span className={classes.label}>Subtotal:</span> {provideIntlService(this).formatNumber(order.subTotal, 'c')}
        </div>
        {!!order.serviceCharge &&
        <div>
          <span className={classes.label}>Service Charge:</span> {provideIntlService(this).formatNumber(order.serviceCharge, 'c')}
        </div>
        }
        {!!order.orderDelivery && !!order.orderDelivery.deliveryCharge &&
        <div>
          <span className={classes.label}>Delivery Charge:</span> {provideIntlService(this).formatNumber(order.orderDelivery.deliveryCharge, 'c')}
        </div>
        }
        {!!order.gratuity &&
        <div>
          <span className={classes.label}>Gratuity:</span> {provideIntlService(this).formatNumber(order.gratuity, 'c')}<br />
        </div>
        }
        <div>
          <span className={classes.label}>Tax:</span> {provideIntlService(this).formatNumber(order.taxTotal, 'c')}<br />
        </div>
        <div>
          <span className={classes.label}>Total:</span> {provideIntlService(this).formatNumber(order.total, 'c')}<br />
        </div>
        {!!order.couponDiscount &&
        <div>
          <span className={classes.label}>Coupon Discount:</span> {provideIntlService(this).formatNumber(order.couponDiscount, 'c')}<br />
        </div>
        }
        <div>
          <span className={classes.label}>Balance Due:</span> {provideIntlService(this).formatNumber(order.balanceDue, 'c')}<br />
        </div>
      </div>

    );
  }
}

export default withStyles(styles)(SubTotal);
