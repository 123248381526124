import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import ISetting from 'models/ISetting';
import CoverDatePicker from 'Components/CoverDatePicker';
import {
  Button,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import moment from 'moment';
import { getDateString, removeOldDates } from 'helpers/dateHelpers';

const styles = createStyles((theme: Theme) => ({
  grazeHolidays: {
    marginLeft: -6,
  },
  addButtonContainer: {
    marginLeft: 1,
  },
  addButton: {
    verticalAlign: 'bottom',
  },
  deleteButon: {
    width: 32,
    minWidth: 'initial',
  },
  deleteIcon: {
    color: theme.palette.grey[500],
  },
  datePicker: {
    display: 'inline-block',
    margin: '0 10px',
  },
  dateString: {
    verticalAlign: 'middle',
  },
}));

interface IProps extends WithStyles {
  setting: ISetting,
  settingAddOption: (settingKey: string, newValue: any) => void,
  settingDeleteOption: (settingKey: string, optionIndex: number) => void,
}

interface IState {
  holidayDate: Date,
}

const defaultState: IState = {
  holidayDate: null,
}

class GrazeHolidays extends Component<IProps, IState> {
  state = defaultState;

  private addHolidayDate = (event: any) => {
    const { holidayDate } = this.state;
    const { setting, settingAddOption } = this.props;
    const dateValid = !!holidayDate && moment(holidayDate).isValid();

    if (!dateValid) {
      return;
    }

    const newHoliday = getDateString(holidayDate)
    const alreadyExists = setting.value.find((holiday: string) => holiday === newHoliday) !== undefined;

    if (alreadyExists) {
      return;
    }

    settingAddOption(setting.key, newHoliday);
  }

  public render() {
    const { classes, setting, settingDeleteOption } = this.props;
    const { holidayDate } = this.state;
    const allHolidays = removeOldDates(setting.value.map((date: string) => new Date(date)));

    return (
      <div className={classes.grazeHolidays}>
        {allHolidays.map((holidayDate: Date, holidayIndex: number) =>
          <div key={`holiday-date-${holidayIndex}`}>
            <Button
              className={classes.deleteButon}
              id="delete"
              onClick={() => settingDeleteOption(setting.key, holidayIndex)}
            >
              <DeleteIcon className={classes.deleteIcon} />
            </Button>
            <span className={classes.dateString}>
              {holidayDate.toDateString()}
            </span>
          </div>
        )}
        <div className={classes.addButtonContainer}>
          <HighlightButton
            className={classes.addButton}
            disabled={holidayDate === null}
            onClick={this.addHolidayDate}
            aria-label="Add A New Holiday Date"
          >
            <AddIcon/>
          </HighlightButton>
          <CoverDatePicker
            className={classes.datePicker}
            label="New Holiday Date"
            value={holidayDate}
            onFieldChange={(holidayDate: Date) => this.setState({ holidayDate })}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GrazeHolidays);
