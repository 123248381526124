import React, { Component } from 'react';
import Modal from 'Components/Modal';
import SimpleDialog from 'Components/SimpleDialog';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';

const dimensions = {
  width: 350,
  height: 213,
};

const initalState = {
  selectedPrint: undefined,
};

const allPrints = [
  { name: 'Proposal', report: 'BookingPrint-Print-Proposal.repx' },
  { name: 'Invoice', report: 'BookingPrint-Print-Invoice.repx' },
  { name: 'Event Order', report: 'BookingPrint-Print-EventOrder.repx' },
  { name: 'Item Details', report: 'BookingPrint-Print-ItemDetails.repx' },
  { name: 'Scheduled Tasks', report: 'BookingPrint-Print-ScheduledTasks.repx' },
];

class Prints extends Component {
  state = initalState;

  notSetupDialog = React.createRef();

  componentDidUpdate = prevProps => {
    const { isOpened } = this.props;

    if (!isOpened && prevProps.isOpened) {
      //closing
      this.setState(initalState);
    }
  }

  onCancel = () => {
    this.props.onGenerate();//empty means close
  }

  onGenerate = () => {
    const {
      selectedPrint,
    } = this.state;

    if (selectedPrint) {
      this.props.onGenerate(this.state.selectedPrint);
    } else {
      this.notSetupDialog.current.open();
    }
  }

  printChanged = print => {
    if (!print) {
      this.notSetupDialog.current.open();
    }

    this.setState({ selectedPrint: print });
  }

  render() {
    const {
      isOpened,
    } = this.props;

    const {
      selectedPrint,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        dimensions={dimensions}
        onCancel={this.onCancel}
        onSave={this.onGenerate}
        title="Print Template"
        saveText="Generate"
        addTitleBottomBorder={true}
        isSaveDisabled={!Boolean(selectedPrint)}
      >
        <Select
          label="Template"
          name="templateId"
          value={selectedPrint}
          onFieldChange={this.printChanged}
        >
          {allPrints.map(print =>
            <MenuItem
              key={print.name}
              value={print.report}
            >
              {print.name}
            </MenuItem>)}

        </Select>
        <SimpleDialog
          message="You have not yet finalized this custom print."
          innerRef={this.notSetupDialog}
          onlyOkayButton={true}
        />
      </Modal>);
  }
}

export default Prints;
