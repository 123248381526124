import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import ProcessorGrid from './ProcessorGrid';
import { Link } from 'react-router-dom';
import { getMerchantAccounts, getMerchantAccountLink, addMerchntAccount, setDefaultMerchantAccount } from 'actions/merchantAccount';
import PaymentProcessor from 'models/PaymentProcessor';
import Modal from 'Components/Modal';
import TextField from 'Components/TextField';

const newAccountId = '00000000-0000-0000-0000-000000000000';

const styles = () => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flex: 1,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 16px',
  },
  topButtons: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    display: 'flex',
    margin: 8,
  },
  tableContainer: {
    width: 650,
  },
  buttonLink: {
    margin: 5,
  },
  fieldsRowContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const PaymentProcessors = ({
  classes,
  merchantAccounts,
  getMerchantAccounts,
  addMerchntAccount,
  getMerchantAccountLink,
  setDefaultMerchantAccount,
}) => {
  const [ freedomPayAccountId, setFreedomPayAccountId ] = useState(null);
  const [ freedomPayAccountIdentifications, setFreedomPayAccountIdentifications ] = useState({});

  const editMerchantAccount = async merchantAccountId => {
    const merchantAccount = merchantAccounts.find(account => account.id === merchantAccountId);

    if (!merchantAccount) {
      return;
    }

    const paymentProcessor = merchantAccount.status === 'Unknown' ? PaymentProcessor.Freedompay : PaymentProcessor.Stripe;

    switch (paymentProcessor) {
      case PaymentProcessor.Stripe:
        const merchantAccountLink = await getMerchantAccountLink(merchantAccountId);

        window.location.href = merchantAccountLink;

        break;
      case PaymentProcessor.Freedompay:
        openFreedomPayForm(merchantAccountId);

        break;
      default:
        return;
    }
  };

  const onAddClick = paymentProcessor => async event => {
    event.preventDefault();

    switch (paymentProcessor) {
      case PaymentProcessor.Stripe:
        const newMerchantAccount = await addMerchntAccount();
        const merchantAccountLink = await getMerchantAccountLink(newMerchantAccount.id);

        window.location.href = merchantAccountLink;

        break;
      case PaymentProcessor.Freedompay:
        openFreedomPayForm(newAccountId);

        break;
      default:
        return;
    }
  };

  const saveFreedomPayAccount = async () => {
    const { esKey, accountId, terminalId } = freedomPayAccountIdentifications;

    await addMerchntAccount(freedomPayAccountId, esKey, accountId, terminalId);
    getMerchantAccounts();
    closeFreedomPayForm();
  };

  const openFreedomPayForm = accountId => {
    setFreedomPayAccountIdentifications({});
    setFreedomPayAccountId(accountId);
  };

  const closeFreedomPayForm = () => {
    setFreedomPayAccountIdentifications({});
    setFreedomPayAccountId(null);
  };

  const onFieldChange = fieldId => value => {
    setFreedomPayAccountIdentifications({
      ...freedomPayAccountIdentifications,
      [fieldId]: value,
    });
  };

  const placeHolderText = () => {
    return freedomPayAccountId === null || freedomPayAccountId === newAccountId ? '' : '*************';
  };

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={classes.content}>
          <div className={classes.tableContainer}>
            <ProcessorGrid
              merchantAccounts={merchantAccounts}
              editMerchantAccount={editMerchantAccount}
              setDefaultMerchantAccount={setDefaultMerchantAccount}
            />
          </div>
        </div>
        <div className={classes.buttons}>
          <div className={classes.topButtons}>
            <div className={classes.button}>
              <HighlightButton
                onClick={onAddClick(PaymentProcessor.Stripe)}
                aria-label={'Add Stripe Account'}
                className={classes.spaceButtons}
              >
                <AddIcon />
              </HighlightButton>
              <Link to={""} className={classes.buttonLink} onClick={onAddClick(PaymentProcessor.Stripe)}>Stripe - register new or connect existing account</Link>
            </div>
            <div className={classes.button}>
              <HighlightButton
                onClick={onAddClick(PaymentProcessor.Freedompay)}
                aria-label={'Add FreedomPay Account Credentials'}
                className={classes.spaceButtons}
              >
                <AddIcon />
              </HighlightButton>
              <Link to={""} className={classes.buttonLink} onClick={onAddClick(PaymentProcessor.Freedompay)}>FreedomPay - add account credentials</Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpened={freedomPayAccountId !== null}
        onCancel={closeFreedomPayForm}
        title="FreedomPay Account Credentials"
        onSave={saveFreedomPayAccount}
        addTitleBottomBorder={false}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '525px' }}
      >
        <div className={classes.fieldsRowContainer}>
          <TextField
            label="EsKey"
            placeholder={placeHolderText()}
            value={freedomPayAccountIdentifications.esKey}
            onFieldChange={onFieldChange('esKey')}
          />
          <TextField
            label="Account Id"
            placeholder={placeHolderText()}
            value={freedomPayAccountIdentifications.accountId}
            onFieldChange={onFieldChange('accountId')}
          />
          <TextField
            label="Terminal Id"
            placeholder={placeHolderText()}
            value={freedomPayAccountIdentifications.terminalId}
            onFieldChange={onFieldChange('terminalId')}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  merchantAccounts: state.merchantAccount.merchantAccounts,
});

const mapDispatchToProps = {
  getMerchantAccounts,
  getMerchantAccountLink,
  addMerchntAccount,
  setDefaultMerchantAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentProcessors));
