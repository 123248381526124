import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import ChitChatMain from './ChitChatMain';
import { Route } from 'react-router-dom';

class ChitChat extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/chitchat" component={ChitChatMain} />
      </Switch>
    );
  }
}

export default withRouter(ChitChat);
