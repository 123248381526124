import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionHeader from 'Components/SectionHeader';
import { withStyles } from '@material-ui/core/styles';
import { provideIntlService } from '@progress/kendo-react-intl';
import moment from 'moment';
import StoreMenuItemDescription from 'Components/StoreMenuItemDescription';
import {
  updateStoreOrderAPI,
  deleteStoreOrderItem,
  addItemToStoreOrder,
} from 'actions/order';
import {
  Button,
} from '@material-ui/core';
import {
  Edit,
} from '@material-ui/icons';
import Avatar from 'Components/Avatar';
import StoreOrderItemModifier from './ModifierModal/StoreOrderItemModifier';
import AlacarteModal from '../alacarte';

const styles = theme => ({
  sectionHeader: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: '18px',
  },
  contentSection: {
    flex: 1,
    display: 'flex',
    height: '500px',
  },
  itemList: {
    width: '450px',
    overflow: 'auto',
  },
  orderDetails: {
    display: 'flex',
    paddingTop: 10,
    flexGrow: '1',
    justifyContent: 'space-between',
    borderLeft: '2px solid #E6EAEE',
    flexWrap: 'wrap',
    width: '450px',
  },
  locationDetails: {
    width: '50%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: '14px',
  },
  clientDetails: {
    width: '50%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingRight: 15,
    fontSize: '14px',
  },
  detailsContainer: {
    display: 'flex',
    width: '100%',
    padding: '0px 15px',
    marginLeft: 5,
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemCost: {
    minWidth: 50,
  },
  label: {
  },
  subLabel: {
  },
  itemDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 4,
    paddingRight: 5,
  },
  itemQtyContainer: {
    display: 'flex',
    border: '1px solid #2189F8',
    borderRadius: 4,
    padding: '0px 2px 0px 4px',
    width: '75px',
  },
  itemQtyLabel: {
    paddingRight: 2,
    textAlign: 'left',
  },
  itemQtyValue: {
    width: '40px',
    textAlign: 'center',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  itemPrice: {
    fontSize: '11px',
    color: '#2189F8',
    paddingRight: 5,
  },
  itemTotalPrice: {
    fontSize: 15,
    paddingRight: 10,
    fontWeight: 700,
  },
  itemButtonContainer: {
    display: 'flex',
  },
  itemButton: {
    padding: 4,
    display: 'flex',
    fontSize: '11px',
    color: '#2189F8',
    textTransform: 'none',
    minWidth: '30px',
  },
  buttonDivider: {
    borderRight: '1px solid',
    alignSelf: 'center',
    height: 11,
  },
  priceDivider: {
    borderRight: '1px solid',
    alignSelf: 'center',
    height: 11,
    color: '#2189F8',
    marginRight: 5,
  },
  imageContainer: {
    margin: 15,
    width: '30%',
  },
  imageContent: {
    width: '70%',
    paddingTop: 10,
  },
  img: {
    margin: 'auto',
    minWidth: '100%',
    minHeight: '100%',
    width: '115px',
    height: '75px',
  },
  itemContainer: {
    display: 'flex',
    paddingBottom: 5,
    borderBottom: '1px solid #E6EAEE',
    alignItems: 'flex-start',
    marginTop: 5,
    marginRight: 10,
  },
  itemName: {
    width: '100%',
    display: 'flex',
    fontSize: 15,
    fontWeight: 'bold',
  },
  modifierContainer: {
    width: '100%',
    textAlign: 'left',
    fontSize: '11px',
    paddingRight: 20,
  },
  modifierSelected: {
    fontSize: '11px',
  },
  editDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    padding: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  button: {
    width: '126px',
    height: '32px',
    borderRadius: 4,
  },
  sectionTitle: {
    padding: 5,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '18px',
    backgroundColor: '#F2F2F2',
    height: '35px',
  },
  financial: {
    flex: 1,
    width: '337px',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '15px',
  },
  financialLabel: {
    width: '200px',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  financialValue: {
    width: '200px',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 25,
    textAlign: 'right',
    marginLeft: 10,
    marginRight: 10,
  },
  totalLabel: {
    fontSize: '15px',
    color: '#2189F8',
    fontWeight: 300,
  },
  orderNumber: {
    position: 'absolute',
    top: '33px',
    left: '12px',
    fontSize: '15px',
  },
  avatar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.common.bluegrey,
    fontSize: 32,
    fontWeight: '600',
    height: '75px',
    color: theme.palette.grey['50'],
  },
  editIcon: {
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
    },
    fontSize: 'large',
    marginLeft: 5,
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 57,
  },
  headerLabel: {
    fontSize: '12px',
    color: '#706E6B',
  },
  headerInfo: {
    fontSize: '16px',
    color: '#2B2826',
  },
  container: {
    display: 'flex',
  },
  contactField: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  contactLabel: {
    fontSize: 12,
    color: '#706E6B',
  },
  contactInfo: {
    fontSize: 13,
  },
  deliveryField: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    width: 80,
  },
});

class StoreOrderReview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedOrderItem: {},
      modifiersModalVisible: false,
      alacarteModalVisible: false,
    };
    this.formatter = provideIntlService(this);
  }

  componentDidMount() {
    this.props.updateStoreOrderAPI();
  }

  onModifiersModalSave = data => {
    this.setState({
      modifiersModalVisible: false,
    });
  }

  onAlacarteModalSave = data => {
    this.setState({
      alacarteModalVisible: false,
    });
  }

  onOpenAlacarteModal = data => {
    this.setState({
      alacarteModalVisible: true,
    });
  }

  updateModifiersForSelectedItem = returnModifiers => {
    const { selectedOrderItem } = this.state;

    selectedOrderItem.modifiers = returnModifiers.modifiers;
    selectedOrderItem.quantity = returnModifiers.quantity;

    this.props.addItemToStoreOrder(selectedOrderItem);

    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  getOrderId = () => {
    const orderId = this.props.storeOrder.id;

    return orderId ? orderId : "";
  }

  onEditItem = itemSelected => {
    if (itemSelected.orderItem.menuCategoryItemId) {
      this.setState({
        selectedOrderItem: itemSelected,
        modifiersModalVisible: true,
      });
    } else {
      this.setState({
        selectedOrderItem: itemSelected,
        alacarteModalVisible: true,
      });
    }
  }

  getImage = item => {
    if (item.imageUrl) {
      return item.imageUrl;
    }
    if (item.menuCategoryItem && item.menuCategoryItem.imageUrl) {
      return item.menuCategoryItem.imageUrl;
    }
  }

  render() {
    const {
      classes,
      storeOrder,
    } = this.props;

    const {
      modifiersModalVisible,
      selectedOrderItem,
      alacarteModalVisible,
    } = this.state;

    this.intlService = provideIntlService(this);

    const isDelivery = storeOrder.orderDelivery;
    const totalServiceCharge = storeOrder.serviceCharge + storeOrder.perItemServiceCharge;

    return (

      <>
        {storeOrder.id ?
          <>
            <div className={classes.orderNumber}>Order: {this.getOrderId()}</div>
            <SectionHeader className={classes.sectionHeader}>Review Your Cart</SectionHeader>
            <div className={classes.contentSection}>
              <div className={classes.itemList}>
                {storeOrder.orderItems && storeOrder.orderItems.filter(orderItem => orderItem.recordStatus === 'Active').map((item, index) => (
                  <div key={index}>
                    <div className={classes.itemContainer}>
                      <div className={classes.imageContainer}>
                        {!!this.getImage(item) ?
                          <img className={classes.img} alt="item" src={this.getImage(item)} />
                          :
                          <Avatar name={item.name} />
                        }
                      </div>
                      <div className={classes.imageContent}>
                        <div className={classes.itemHeader}>
                          <div className={classes.itemName}>
                            {item.name}
                          </div>
                          <div className={classes.itemTotalPrice}>
                            ${item.price}
                          </div>
                        </div>
                        <div className={classes.modifierContainer}>
                          <StoreMenuItemDescription item={item} />
                        </div>
                        <div className={classes.itemDetails}>
                          <div className={classes.itemQtyContainer}>
                            <label className={classes.itemQtyLabel}>Qty:</label>
                            <input
                              value={item.quantity}
                              className={classes.itemQtyValue}
                              type="number"
                              disabled={true}
                            />
                          </div>
                          <div className={classes.itemButtonContainer}>
                            <Button onClick={e => this.onEditItem({ orderItem: item, index })} className={classes.itemButton}>Edit</Button>
                            <div className={classes.buttonDivider}></div>
                            <Button onClick={e => this.props.deleteStoreOrderItem({ orderItem: item, index })} className={classes.itemButton}>Remove</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={classes.orderDetails}>
                <div className={classes.detailsContainer}>
                  <div className={classes.clientDetails}>
                    <div className={classes.header}>
                      <div>
                        <div className={classes.headerLabel}>Contact <Edit className={classes.editIcon} onClick={e => this.props.handleStepClick(0)} /></div>
                        <div className={classes.headerInfo}>
                          {storeOrder.customerName}
                        </div>
                      </div>
                    </div>
                    <div className={classes.container}>
                      <div className={classes.contactField}>
                        <div className={classes.contactLabel}>Phone</div>
                        <div className={classes.contactInfo}> {storeOrder.customerPhone} </div>
                      </div>
                    </div>
                    {isDelivery && storeOrder.orderDelivery &&
                      <>
                        <div>
                          <div className={classes.contactLabel}>Address:</div>
                        </div>
                        <div>
                          <div className={classes.contactInfo}>{storeOrder.orderDelivery.address1} {storeOrder.orderDelivery.address2} <br />
                            {storeOrder.orderDelivery.city}, {storeOrder.orderDelivery.stateProvince} {storeOrder.orderDelivery.postalCode}</div>
                        </div>
                      </>
                    }
                  </div>
                  {isDelivery && storeOrder.orderDelivery &&
                    <div className={classes.locationDetails}>
                      <div className={classes.header}>
                        <div>
                          <div className={classes.headerLabel}>Delivery Address</div>
                          <div className={classes.headerInfo}>
                            {storeOrder.orderDelivery.address1}
                          </div>
                        </div>
                      </div>
                      <div className={classes.container}>
                        <div className={classes.deliveryField}>
                          <div className={classes.contactLabel}>City</div>
                          <div className={classes.contactInfo}> {storeOrder.orderDelivery.city} </div>
                        </div>
                        <div className={classes.deliveryField}>
                          <div className={classes.contactLabel}>State</div>
                          <div className={classes.contactInfo}>{storeOrder.orderDelivery.stateProvince}</div>
                        </div>
                      </div>
                      <div className={classes.container}>
                        <div className={classes.deliveryField}>
                          <div className={classes.contactLabel}>Zip Code</div>
                          <div className={classes.contactInfo}> {storeOrder.orderDelivery.postalCode} </div>
                        </div>
                        <div className={classes.deliveryField}>
                          <div className={classes.contactLabel}>Delivery Zone</div>
                          <div className={classes.contactInfo}>{storeOrder.locationId}</div>
                        </div>
                      </div>
                    </div>}
                </div>
                <div className={classes.sectionTitle}>Order Summary</div>
                <div className={classes.detailsContainer}>
                  <div className={classes.financial}>
                    <div className={classes.financialLabel}>
                      <div>
                        <span className={classes.label}>Estimated Ready Time:</span>
                      </div>
                      <div>
                        <span className={classes.label}>Subtotal:</span>
                      </div>
                      <div>
                        <span className={classes.subLabel}>Service Charge:</span>
                      </div>
                      <div>
                        <span className={classes.subLabel}>Delivery Fee: </span>
                      </div>
                      <div>
                        <span>&nbsp;</span>
                      </div>
                      <div>
                        <span className={classes.subLabel}>Total before tax:</span>
                      </div>
                      <div>
                        <span className={classes.subLabel}>Tax to be collected:</span>
                      </div>
                      <div>
                        <span className={classes.subLabel}>&nbsp;</span>
                      </div>
                      <div>
                        <span className={classes.totalLabel}>TOTAL: </span>
                      </div>
                    </div>

                    <div className={classes.financialValue}>
                      <div>
                        {storeOrder.estimatedReadyTimeMin && <span className={classes.contactInfo}>{moment(storeOrder.estimatedReadyTimeMin).local().format('h:mm A')}&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
                        <span className={classes.contactInfo}>{moment(storeOrder.estimatedReadyTimeMax).local().format('h:mm A')}</span>
                      </div>
                      <div>
                        <span>{this.formatter.formatNumber(storeOrder.subTotal, 'c')}</span>
                      </div>
                      <div>
                        <span>{this.formatter.formatNumber(totalServiceCharge, 'c')}</span>
                      </div>
                      <div>
                        {/* TODO */}
                        <span>$0.00</span>
                      </div>
                      <div>
                        <span>&nbsp;</span>
                      </div>
                      <div>
                        <span>{this.formatter.formatNumber(storeOrder.subTotal, 'c')}</span>
                      </div>
                      <div>
                        <span>{this.formatter.formatNumber(storeOrder.taxTotal, 'c')}</span>
                      </div>
                      <div>
                        <span>&nbsp;</span>
                      </div>
                      <div>
                        <span className={classes.totalLabel}>{this.formatter.formatNumber(storeOrder.total, 'c')}</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </> :
          <>
            Processing Order
          </>
        }
        {modifiersModalVisible && <StoreOrderItemModifier
          onModalClosed={this.onModifiersModalSave}
          updateSetsOfModifiers={this.updateModifiersForSelectedItem}
          selectedOrderItem={selectedOrderItem} />}
        {alacarteModalVisible && <AlacarteModal
          onModalClosed={this.onAlacarteModalSave}
          selectedOrderItem={selectedOrderItem}
        />}
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeOrder,
      storeMenuSelectedCategoryItems,
      storeAllMenuItems,
    },
  } = state;

  return {
    storeOrder,
    storeMenuSelectedCategoryItems,
    storeAllMenuItems,
  };
};

const mapDispatchToProps = {
  updateStoreOrderAPI,
  deleteStoreOrderItem,
  addItemToStoreOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StoreOrderReview));
