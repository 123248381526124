import React, { Component } from 'react';
import { connect } from 'react-redux';
import OneDimensionalGraphWidget from 'Components/GraphWidgets/OneDimensionalGraphWidget';
import BarGraphWidget from 'Components/GraphWidgets/BarGraphWidget';
import { withStyles } from '@material-ui/core/styles';
import { widgetGraphs } from 'helpers/enums';
import { getBookingTotalSummary, getSelectedBookingTotalSummary } from 'actions/reports';

const styles = () => ({
  graphContainer: {
    display: 'flex',
    flexGrow: 1,
    paddingBottom: '3.2%',
  },
  lastGraphContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  graphGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class BookingGraphs extends Component {
  state = {
    allBookings: {
      bookingCount: 0,
      totalOfType: 0,
      totalEvents: 0,
    },
    selectedBookings: {},
  }

  componentDidMount() {
    const getBookingTotalSummary = this.props.getBookingTotalSummary();

    if (this.props.selectedBookingIds.length === 0) {
      Promise.all([getBookingTotalSummary, this.props.getSelectedBookingTotalSummary(this.props.selectedBookingIds)])
        .then(([allBookings, selectedBookings]) =>
          this.setState({ allBookings, selectedBookings }));
    } else {
      getBookingTotalSummary.then(allBookings => this.setState({ allBookings }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedBookingIds !== this.props.selectedBookingIds) {
      this.props.getSelectedBookingTotalSummary(this.props.selectedBookingIds)
        .then(selectedBookings => this.setState({ selectedBookings }));
    }
  }

  render() {
    const {
      classes,
      bookingsCountSettings,
      bookingsTypeSettings,
      bookingEventsSettings,
    } = this.props;

    const {
      allBookings,
      selectedBookings,
    } = this.state;

    return (
      <div className={classes.graphGroupContainer}>
        <div className={classes.graphContainer}>
          <OneDimensionalGraphWidget
            denominator={allBookings.bookingCount}
            differenceIndicator={0}
            numerator={selectedBookings.bookingCount}
            settings={bookingsCountSettings}
          />
        </div>
        <div className={classes.graphContainer}>
          <OneDimensionalGraphWidget
            denominator={allBookings.totalOfType}
            differenceIndicator={0}
            numerator={selectedBookings.totalOfType}
            settings={bookingsTypeSettings}
          />
        </div>
        <div className={classes.lastGraphContainer}>
          <BarGraphWidget
            data={allBookings.eventCountsByWeek}
            denominator={allBookings.totalEvents}
            differenceIndicator={6}
            numerator={selectedBookings.totalEvents}
            settings={bookingEventsSettings}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    widgets,
    api: { bookings: { selectedBookingIds } },
  } = state;

  return {
    bookingsCountSettings: widgets[widgetGraphs.bookingsCount],
    bookingsTypeSettings: widgets[widgetGraphs.bookingsType],
    bookingEventsSettings: widgets[widgetGraphs.bookingEvents],
    selectedBookingIds,
  };
};

const mapDispatchToProps = {
  getBookingTotalSummary,
  getSelectedBookingTotalSummary,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BookingGraphs));
