import {
  RECEIVE_COMPANY,
  ITEMS_REQUEST_BEGIN,
  LOCATIONS_REQUEST_BEGIN,
  RECEIVE_LOCATION,
  LOCATION_NOT_RECEIVED,
  LOCATION_SELECTED,
  RECEIVE_ITEMS,
  RECEIVE_LOCATIONS,
  ADD_LOCATION,
  FETCH_LIBRARY_ITEM_IMAGE_SUCCESS,
  ITEMS_REQUEST_END,
  ITEM_TAGS_RECEIVED,
} from 'actions/constants';
import _ from 'lodash';

const initialState = {
  selectedIds: {},
  selectedLocations: [],
  locationMeta: {
    loading: false,
  },
  items: {
    selected: [],
    loading: false,
    selectedIds: {},
  },
  taxTypes: [],
  locationDetails: {},
  itemTags: [],
};

function recalculateParams(params, meta) {
  if (params.skip === meta.skip) {
    return params;
  }

  return {
    ...params,
    skip: meta.skip,
  };
}

function logReduxActions(action) {
  if (/^redux-oidc/.test(action.type) && window.env.REACT_APP_APPLICATION_INSIGHTS_ENABLED === 'true') {
    if (action.type === 'redux-oidc/USER_FOUND') {
      const user = action.payload.profile.email;
      //const expires = action.payload.expires_at;

      window.appInsights.setAuthenticatedUserContext(user);

      window.appInsights.trackEvent({ name: action.type });
    }
    else {
      if (action.type === 'redux-oidc/SESSION_TERMINATED') {
        window.appInsights.clearAuthenticatedUserContext();
      }
      window.appInsights.trackEvent({ name: action.type });
    }
  }
}

export function admin(state = initialState, action) {
  logReduxActions(action);

  switch (action.type) {
    case RECEIVE_COMPANY:
      const { company } = action;

      return {
        ...state,
        company: company,
      };
    case ITEMS_REQUEST_BEGIN: {
      const { params } = action;

      return {
        ...state,
        items: {
          ...state.items,
          loading: true,
          params,
        },
      };
    }
    case ITEMS_REQUEST_END: {
      return {
        ...state,
        items: {
          ...state.items,
          loading: false,
        },
      };
    }
    case LOCATIONS_REQUEST_BEGIN: {
      const { params } = action;

      return {
        ...state,
        locationMeta: {
          ...state.locationMeta,
          loading: true,
          params,
        },
      };
    }
    case RECEIVE_LOCATION: {
      const { locationDetails } = action;

      return {
        ...state,
        locationDetails,
      };
    }
    case LOCATION_NOT_RECEIVED: {
      const { error } = action;

      console.error('ERROR: LOCATION_NOT_RECEIVED'
        + '\nThis location\'s details may have been deleted.\n', error);

      return {
        ...state,
        error,
      };
    }
    case LOCATION_SELECTED: {
      const { locationMeta, selectedIds, selectedLocations } = state;
      const newLocations = [];
      const { id, isSelected, isAll } = action;
      let locations;

      if (isAll !== undefined) {
        //locations = ,//add in a 'selected' field
        locations = locationMeta.locations.map(location => {

          if (!location.selected && isAll) {
            newLocations.push(location);
          }
          location.selected = isAll;
          selectedIds[location.id] = isAll;

          return location;
        });
      } else {
        selectedIds[id] = isSelected;
        locations = locationMeta.locations.map(location => {
          if (location.id === id) {
            if (!location.selected && isSelected) {
              newLocations.push(location);
            }
            location.selected = isSelected;
          }

          return location;
        });
      }

      const stillSelected = selectedLocations.filter(location => {
        return selectedIds[location.id] === true;
      });

      const combined = [...newLocations, ...stillSelected];

      return {
        ...state,
        locationMeta: {
          ...locationMeta,
          locations,
        },
        selectedLocations: combined,
        selectedIds,
      };
    }
    case RECEIVE_ITEMS: {
      const { items: { params: currentParams, selectedIds } } = state;
      const { params, skipParamCheck, response: { data, meta } } = action;

      if (skipParamCheck) {
        return {
          ...state,
          items: {
            ...state.items,
            data,
          },
        };
      }

      if (!_.isEqual(currentParams, params)) {
        //you're paging very fast or API is too slow. So got a response for a different page than we expected
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          data: data.map(item => {
            item.selected = selectedIds[item.id] === true;

            return item;
          }),
          params: recalculateParams(currentParams, meta),
          loading: false,
          meta,
        },
      };
    }
    case RECEIVE_LOCATIONS: {
      const { locationMeta, selectedIds } = state;

      if (!_.isEqual(locationMeta.params, action.params)) {
        //params don't match
        return state;
      }

      const { response: { data, meta } } = action;
      let params = recalculateParams(locationMeta.params, meta);

      return {
        ...state,
        locationMeta: {
          locations: data.map(location => {
            location.selected = selectedIds[location.id] === true;

            return location;
          }),
          params: params,
          meta,//should params be on the meta from the API?
          loading: false,
        },
      };
    }

    case ADD_LOCATION: {
      let locationadded = action.locationDetails;
      let locationMeta = state.locationMeta;
      const { skip, total } = locationMeta.meta;

      return {
        ...state,
        locationMeta: {
          ...locationMeta,
          locations: [locationadded, ...locationMeta.locations],
          meta: {
            skip,
            total: total + 1,
          },
        },
      };
    }

    case FETCH_LIBRARY_ITEM_IMAGE_SUCCESS: {
      //does this really need to be added to the redux store?
      let itemImage = action.itemImage;

      if (itemImage && itemImage.content) {
        itemImage = `data:image/png;base64,${itemImage.content}`;
      }

      return {
        ...state,
        itemImage,
      };
    }

    case ITEM_TAGS_RECEIVED: {
      const { itemTags } = action;

      return {
        ...state,
        itemTags,
      };
    }

    default:
      return state;
  }
}
