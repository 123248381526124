import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
  container: {
    fontSize: 17,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 32, //keep lined up with text in CoverExpansionPanel
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[800],
  },
});

const SectionHeader = ({ children, classes: { container }, className }) => (
  <div className={classNames(container, className)}>
    {children}
  </div>
);

export default withStyles(styles)(SectionHeader);
