import {
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_BEGIN,
} from 'actions/constants';

const initialState = {
  uploading: false,
};

export function file(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE_BEGIN: {
      return {
        ...state,
        uploading: true,
      };
    }
    case UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        uploading: false,
        uploadedFile: action.response,
      };
    }

    case UPLOAD_FILE_FAILURE: {
      let error = action.error;

      console.error('UPLOAD ERROR', error);

      return {
        ...state,
        uploadedFile: undefined,
        uploading: false,
      };
    }
    default:
      return state;
  }
}
