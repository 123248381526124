import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import GraphWidget from './GraphWidget';
import {
  Chart,
  ChartTooltip,
  ChartArea,
  ChartPlotArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartSeriesItemTooltip,
} from '@progress/kendo-react-charts';
import DifferenceIndicator from './DifferenceIndicator';

const styles = theme => ({
  graphContainer: {
    height: '24%',
  },
  totalContainer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'space-between',
  },
  totalDescription: {
    color: theme.palette.grey[200],
    fontSize: '12px',
  },
});

class BarGraph extends React.Component {
  constructor(props) {
    super(props);

    this.chartContainerRef = React.createRef();
    this.chartRef = React.createRef();

    this.state = {
      adjustChartMargin: 0,
      dimensionInPx: 0,
    };
  }

  render() {
    const {
      classes,
      data,
      denominator,
      differenceIndicator,
      numerator,
      settings,
      theme,
    } = this.props;
    const { adjustChartMargin, dimensionInPx } = this.state;

    return (
      <GraphWidget
        minHeight="175px"
        minWidth="250px"
        numerator={numerator}
        differenceIndicator={differenceIndicator}
        settings={settings}
      >
        <div ref={this.chartContainerRef} className={classes.graphContainer}>
          <Chart ref={this.chartRef} style={{ height: dimensionInPx }}>
            <ChartTooltip />
            <ChartPlotArea margin={0} />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem>
                <ChartCategoryAxisItem categories={data.categories} />
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                line={{ visible: false }}
                majorGridLines={{ visible: false }}
                visible={false}
              />
            </ChartValueAxis>
            <ChartArea margin={adjustChartMargin} />
            <ChartSeries>
              <ChartSeriesItem type="column" data={data.values} color={theme.palette.secondary.main} opacity={1} >
                <ChartSeriesItemTooltip background={theme.palette.secondary.main} />
              </ChartSeriesItem>
            </ChartSeries>
          </Chart>
        </div>
        <div className={classes.totalContainer}>
          <div className={classes.totalDescription}>
            {settings.isLabelVisible ? settings.label : ''}
          </div>
          <div>
            <DifferenceIndicator differenceIndicator={denominator} units={settings.denominatorUnits} withColor={false} withSign={false} />
          </div>
        </div>
      </GraphWidget>
    );
  }

  componentDidMount() {
    this.updateDimensionsIfNeeded();
  }

  componentDidUpdate() {
    this.updateDimensionsIfNeeded();
  }

  updateDimensionsIfNeeded() {
    const { dimensionInPx: prevDimensionInPx, adjustChartMargin: prevAdjustChartMargin } = this.state;

    const kendoMarginAdjustment = -.02;
    const chart = this.chartRef.current;
    const adjustChartMargin = chart ? chart.element.clientWidth * kendoMarginAdjustment : 0;

    const container = this.chartContainerRef.current;
    let dimensionInPx = 0;

    if (container) {
      dimensionInPx = container.clientHeight;
    }

    const isWithinTolerance = Math.abs(prevDimensionInPx - dimensionInPx) <= 2;

    if ((prevDimensionInPx !== dimensionInPx && !isWithinTolerance) || prevAdjustChartMargin !== adjustChartMargin) {
      this.setState({ dimensionInPx, adjustChartMargin });
    }
  }
}

export default withStyles(styles, { withTheme: true })(BarGraph);
