import React from 'react';
import { WithStyles, withStyles, Theme } from '@material-ui/core/styles';
import {
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Checkbox,
    Button,
    Select,
    MenuItem,
    FormControlLabel,
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IMenuItem from '../../models/IMenuItem';
import IMenuItemModifier from '../../models/IMenuItemModifier';
import IOrderItemModifier from '../../models/IOrderItemModifier';

const useStyles = (theme: Theme) => ({
    rootModifire: {
        width: '100%',
    },
    panelExpanded: {
        margin: '5px 0',
    },
    heading: {
    },
    panelDetails: {
        paddingTop: 0,
    },
    modifierContainer: {
        width: '100%',
        marginTop: '-10px',
    },
    modifierInlineOption: {
        display: 'inline-block',
    },
    childContainer: {
        width: '100%',
        marginTop: 10,
        marginLeft: '10%',
    },
    formControlLabel: {
        fontSize: 14,
    },
    sizeIcon: {
        width: 20,
        height: 20,
    },
    buttonOption: {
        fontSize: '12px',
        lineHeight: '12px',
        marginRight: 10,
        marginBottom: 10,
    },
    optionMessage: {
        fontWeight: 300,
    },
    dropdownOptions: {
        minWidth: '50%',
        marginBottom: 10,
    },
    dropdownSelect: {
        padding: '2px 30px 0px 0px',
        fontSize: '14px',
    },
    dropdownIcon: {
        height: 25,
        width: 25,
    },
    dropdownMenuItem: {
        fontSize: '14px',
    },
    errorMessage: {
        color: '#ff0000',
        transition: '1s',
        opacity: 0,
    },
    errorMessageActive: {
        opacity: 1,
    },
});

type Props = WithStyles & {
    editModifires?: boolean;
    selectedMenuItem: IMenuItem;
    allSelectedItemModifiers: {[id: number]: IMenuItemModifier};
    orderItemModifiers: { [id: number]: IOrderItemModifier };
    setOrderItemModifiers: (orderItemModifiers: { [id: number]: IOrderItemModifier }) => void;
    setModifiersError: (modifiersError: string) => void;
}

interface IState {
    errorMessage: string;
    errorModifierId: number;
}

class ProductModifiers extends React.Component<Props, IState> {
    public state = {
        errorMessage: '',
        errorModifierId: 0,
    }

    public componentDidMount() {
        const { editModifires, selectedMenuItem } = this.props;
        if (!editModifires && !!selectedMenuItem) {
            this.setDefaultModifires();
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.orderItemModifiers !== this.props.orderItemModifiers) {
            const modifiersError = this.validateAllModifiers();
            this.props.setModifiersError(modifiersError);
        }
    }

    public render() {
        const { classes, selectedMenuItem } = this.props;
        if (!selectedMenuItem || selectedMenuItem.modifiers.length === 0) {
          return null;
      }

        const { modifiers } = selectedMenuItem;

        return (<>
            {this.sortOptions(modifiers).map((menuItemModifier: IMenuItemModifier, index: number) => (
                <ExpansionPanel
                    defaultExpanded={index === 0 && this.needSelectSize()}
                    key={`menu-item-modifier-id-${menuItemModifier.id}`}
                    className={classes.rootModifire}
                    classes={{ expanded: classes.panelExpanded }}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-content-modifire-id-${menuItemModifier.id}`}
                        id={`panel-root-modifire-id-${menuItemModifier.id}`}
                    >
                        <Typography className={classes.heading}>
                            {menuItemModifier.name}
                            <span className={classes.optionMessage}>
                                {this.getOptionMessage(menuItemModifier)}
                            </span>
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        {this.renderModifierOptions(menuItemModifier)}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))}
        </>);
    };

    private renderCheckbox = (option: IMenuItemModifier) => {
        const { classes } = this.props;

        return (
            <FormControlLabel
                classes={{ label: classes.formControlLabel }}
                label={<>
                    <span>
                        {option.name}
                    </span>
                    <span className={classes.optionMessage}>
                        {this.getOptionMessage(option)}
                    </span>
                </>}
                control={
                    <Checkbox
                        name={option.name}
                        checked={this.isModifierSelected(option.id)}
                        icon={<CheckBoxOutlineBlank className={classes.sizeIcon} />}
                        checkedIcon={<CheckBox className={classes.sizeIcon} />}
                        onClick={() => this.onClickModifire(option.id)}
                    />
                }
            />
        )
    }

    private renderButton = (option: IMenuItemModifier) => {
        const { classes } = this.props;

        return (
            <Button
                className={classes.buttonOption}
                variant="contained"
                color={this.isModifierSelected(option.id) ? 'primary' : 'secondary'}
                size="small"
                onClick={() => this.onClickModifire(option.id)}
            >
                <span>
                    {option.name}
                    <span className={classes.optionMessage}>
                        {this.getOptionMessage(option)}
                    </span>
                </span>
            </Button>
        )
    }

    private renderCheckboxOptions = (menuItemModifier: IMenuItemModifier) =>
        this.sortOptions(menuItemModifier.options).map((option: IMenuItemModifier) =>
            <div key={option.id}>
                {this.renderCheckbox(option)}
                {this.renderErrorMessage(option)}
                {this.renderChildOptions(option)}
            </div>
        )

    private renderButtonOptions = (menuItemModifier: IMenuItemModifier) => {
        const { classes } = this.props;

        return (
            this.sortOptions(menuItemModifier.options).map((option: IMenuItemModifier) =>
                <div
                    key={option.id}
                    className={!option.options.length || !this.isModifierSelected(option.id)
                        ? classes.modifierInlineOption
                        : ''
                    }
                >
                    {this.renderButton(option)}
                    {this.renderChildOptions(option)}
                </div>
            )
        )
    }

    private renderDropdownOptions = (menuItemModifier: IMenuItemModifier) => {
        if (!menuItemModifier.options.length) {
            return null;
        }

        const { classes } = this.props;
        const selectedModifierOptions = this.getSelectedModifireOptions(menuItemModifier);
        const selectedOption = selectedModifierOptions.length === 1 ? selectedModifierOptions[0] : null;

        return (<>
            <Select
                className={classes.dropdownOptions}
                classes={{ select: classes.dropdownSelect, icon: classes.dropdownIcon }}
                variant='outlined'
                color='secondary'
                value={selectedOption ? selectedOption.id : 0}
                onChange={event => this.onClickModifire(parseInt(event.target.value))}
            >
                {this.sortOptions(menuItemModifier.options).map((option: IMenuItemModifier) =>
                    <MenuItem
                        className={classes.dropdownMenuItem}
                        key={`option-modifier-id-${option.id}`}
                        value={option.id}
                    >
                      <span>
                        {option.name}
                        <span className={classes.optionMessage}>
                            {this.getOptionMessage(option)}
                        </span>
                      </span>
                    </MenuItem>
                )}
            </Select>
            {this.renderChildOptions(selectedOption)}
        </>)
    }

    private needSelectSize = (): boolean =>
        !this.props.selectedMenuItem.price
        && !!this.props.selectedMenuItem.modifiers.length
        && !Object.keys(this.props.orderItemModifiers).length;

    private renderChildOptions = (menuItemModifier: IMenuItemModifier) => {
        const { classes } = this.props;
        if (!menuItemModifier || !this.isModifierSelected(menuItemModifier.id)) {
            return null;
        }

        return (
            <div className={classes.childContainer}>
                {this.renderModifierOptions(menuItemModifier)}
            </div>
        )
    }

    private renderModifierOptions = (menuItemModifier: IMenuItemModifier) => {
        const { classes } = this.props;

        return (
            <div key={`menu-item-modifier-id-${menuItemModifier.id}`} className={classes.modifierContainer}>
                {menuItemModifier.rule === 'PickMany'
                    ? this.renderCheckboxOptions(menuItemModifier)
                    : menuItemModifier.style === 'Button'
                        ? this.renderButtonOptions(menuItemModifier)
                        : this.renderDropdownOptions(menuItemModifier)
                }
            </div>
        )
    }

    private renderErrorMessage = (menuItemModifier: IMenuItemModifier) => {
        const { classes } = this.props;
        const showError = menuItemModifier.id === this.state.errorModifierId;
        return (
            <div className={
                showError
                    ? `${classes.errorMessage} ${classes.errorMessageActive}`
                    : classes.errorMessage
            }>
                <span style={{ display: showError ? 'inline' : 'none' }}>{this.state.errorMessage}</span>
            </div>
        )
    }

    private getOptionMessage = (menuItemModifier: IMenuItemModifier): string => {
        let priceMessage = menuItemModifier.price ? `+$${menuItemModifier.price.toFixed(2)}` : '';
        const quantityMessage = this.getQuantityMessage(menuItemModifier);

        if (!!priceMessage && !!quantityMessage) {
            priceMessage += ' ';
        }

        return !!priceMessage || !!quantityMessage ? ` (${priceMessage}${quantityMessage})` : '';
    }

    private getQuantityMessage = (menuItemModifier: IMenuItemModifier): string => {
        let message = '';
        if (menuItemModifier.rule === 'PickOne') {
            return message;
        }
        if (!!menuItemModifier.minimumQuantity) {
            message += `min ${menuItemModifier.minimumQuantity}`;
        }
        if (!!menuItemModifier.minimumQuantity && !!menuItemModifier.maximumQuantity) {
            message += ' and '
        }
        if (!!menuItemModifier.maximumQuantity) {
            message += `max ${menuItemModifier.maximumQuantity}`;
        }

        return message;
    }

    private showError = (errorModifierId: number, errorMessage: string) => {
        this.setState({
            errorMessage,
            errorModifierId,
        }, () => {
            setTimeout(() => {
                this.setState({
                    errorMessage: '',
                    errorModifierId: 0,
                })
            }, 5000);
        })
    }

    private sortOptions = (options: IMenuItemModifier[]): IMenuItemModifier[] => {
        return options
            .sort((a, b) => {
                if(a.id < b.id) { return -1; }
                if(a.id > b.id) { return 1; }
                return 0;
            })
            .sort((a, b) => {
                if(a.sortOrder < b.sortOrder) { return -1; }
                if(a.sortOrder > b.sortOrder) { return 1; }
                return 0;
            });
    }

    private isModifierSelected = (modifireId: number): boolean => {
        const { orderItemModifiers } = this.props;

        return !!orderItemModifiers[modifireId] && orderItemModifiers[modifireId].recordStatus === 'Active';
    }

    private getModifire = (modifireId: number): IMenuItemModifier => {
        const { allSelectedItemModifiers } = this.props;

        return allSelectedItemModifiers[modifireId];
    }

    private getSelectedModifireOptions = (menuItemModifier: IMenuItemModifier): IMenuItemModifier[] => {
        const { orderItemModifiers } = this.props;
        const selectedModifireIds = Object
            .values(orderItemModifiers)
            .filter(orderModifier => orderModifier.recordStatus === 'Active')
            .map(orderModifier => orderModifier.menuItemModifierId)

        return menuItemModifier.options
            .filter((option: IMenuItemModifier) => selectedModifireIds.includes(option.id));
    }

    private changeOrderItemModifier = (
        menuItemModifier: IMenuItemModifier,
        orderItemModifiers: { [id: number]: IOrderItemModifier },
        selected: boolean = null
    ): void => {
        if (!orderItemModifiers[menuItemModifier.id]) {
            orderItemModifiers[menuItemModifier.id] = {
                menuItemModifierId: menuItemModifier.id,
                menuItemModifier,
                quantity: 1,
                recordStatus: selected === null || selected ? 'Active' : 'Deleted',
            }
        } else {
            orderItemModifiers[menuItemModifier.id].recordStatus = selected === null
                ? orderItemModifiers[menuItemModifier.id].recordStatus !== 'Active' ? 'Active' : 'Deleted'
                : selected ? 'Active' : 'Deleted';
        }

        const modifireDeletedBeforeSaved =
            orderItemModifiers[menuItemModifier.id].recordStatus !== 'Active' && !orderItemModifiers[menuItemModifier.id].id;
        if (modifireDeletedBeforeSaved) {
            delete orderItemModifiers[menuItemModifier.id];
        }
    }

    private isValidByModifierRules = (isGoinToBeSelected: boolean, parentModifire: IMenuItemModifier, selectedQuantity: number): string => {
        if (parentModifire.rule === 'PickOne') {
            return '';
        }
        if (isGoinToBeSelected && (!!parentModifire.maximumQuantity && parentModifire.maximumQuantity <= selectedQuantity)) {
            return `Max limit is ${parentModifire.maximumQuantity}`;
        }
        if (!isGoinToBeSelected && (!!parentModifire.minimumQuantity && parentModifire.minimumQuantity >= selectedQuantity)) {
            return `Min limit is ${parentModifire.minimumQuantity}`;
        }

        return '';
    }

    private validateAllModifiers = (): string => {
        const { allSelectedItemModifiers, selectedMenuItem } = this.props;
        if (this.needSelectSize()) {
            return `Please select a ${selectedMenuItem.modifiers[0].name}`;
        }

        for (const modifier of Object.values(allSelectedItemModifiers)) {
            if (modifier.rule === 'PickOne' || (!modifier.minimumQuantity && !modifier.maximumQuantity)) {
                continue;
            }

            const selectedOptions = this.getSelectedModifireOptions(modifier);
            const parentModifire = !modifier.parentMenuItemModifierId ? '' : `${allSelectedItemModifiers[modifier.parentMenuItemModifierId].name} - `;

            if (!!modifier.minimumQuantity && selectedOptions.length < modifier.minimumQuantity) {
                return `${parentModifire}${modifier.name} min limit is ${modifier.minimumQuantity}`;
            }
            if (!!modifier.maximumQuantity && selectedOptions.length > modifier.maximumQuantity) {
                return `${parentModifire}${modifier.name} max limit is ${modifier.maximumQuantity}`;
            }
        }

        return '';
    }

    private onClickModifire = (menuItemModifierId: number) => {
        const modifire = this.getModifire(menuItemModifierId);
        const parentModifire = this.getModifire(modifire.parentMenuItemModifierId);
        const selectedOptions = this.getSelectedModifireOptions(parentModifire);
        const isGoinToBeSelected = !this.isModifierSelected(menuItemModifierId);
        const errorMessage = this.isValidByModifierRules(isGoinToBeSelected, parentModifire, selectedOptions.length);

        if (errorMessage) {
            this.showError(menuItemModifierId, errorMessage);
            return;
        }

        const orderItemModifiers = { ...this.props.orderItemModifiers };
        const needOptionsReset = isGoinToBeSelected && parentModifire.rule === 'PickOne';
        const needChildOptionsReset = !needOptionsReset && !isGoinToBeSelected && !!modifire.options.length;
        const needParentSelect = isGoinToBeSelected && !!parentModifire.parentMenuItemModifierId;

        if (needChildOptionsReset) {
            modifire.options.forEach(option => this.changeOrderItemModifier(option, orderItemModifiers, false));
        }
        if (needOptionsReset) {
            (() => {
                const resetOptions = (options: IMenuItemModifier[]) => {
                    options.forEach(option => {
                        this.changeOrderItemModifier(option, orderItemModifiers, false);
                        resetOptions(option.options);
                    });
                }
                resetOptions(parentModifire.options);
            })();
        }
        if (needParentSelect) {
            this.changeOrderItemModifier(parentModifire, orderItemModifiers, true);
        }

        this.changeOrderItemModifier(modifire, orderItemModifiers);

        this.props.setOrderItemModifiers(orderItemModifiers);
    }

    private setDefaultModifires = () => {
        const { selectedMenuItem } = this.props;

        const filterDefaultModifires = (modifiers: IMenuItemModifier[], filtered: IMenuItemModifier[]) => {
            modifiers.forEach(modifier => {
                if (modifier.default) {
                    filtered.push(modifier);
                }
                filterDefaultModifires(modifier.options, filtered);
            })
        }

        const orderItemModifiers = { ...this.props.orderItemModifiers };
        const filteredModifiers = [] as IMenuItemModifier[];
        filterDefaultModifires(selectedMenuItem.modifiers, filteredModifiers);

        filteredModifiers.forEach(defaultModifire => {
            this.changeOrderItemModifier(defaultModifire, orderItemModifiers, true);
            if (defaultModifire.parentMenuItemModifierId) {
                const parentModifire = this.getModifire(defaultModifire.parentMenuItemModifierId);
                this.changeOrderItemModifier(parentModifire, orderItemModifiers, true);
            }
        });

        this.props.setOrderItemModifiers(orderItemModifiers);
    }
};

export default withStyles(useStyles as any)(ProductModifiers);
