import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  quickAddCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minWidth: 226,
    maxWidth: 226,
    height: 170,
    padding: 16,
    background: 'transparent',
    border: `1px solid ${theme.palette.grey[100]}`,
    color: theme.palette.grey[100],
    borderRadius: 4,
    fontSize: 14,
    marginBottom: 16,
    cursor: 'pointer',
  },
  icon: {
    fontSize: 64,
  },
});

class QuickAddEventCard extends Component {
  render() {
    const { classes, onClick } = this.props;

    return (
      <div className={classes.quickAddCard} onClick={onClick}>
        <AddIcon classes={{ root: classes.icon }}/>
        <p>Quick Add Event</p>
      </div>
    );
  }
}

export default withStyles(styles)(QuickAddEventCard);
