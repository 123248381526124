import { callApi } from 'shared/CallApi';
import { salesDataReceived } from './api';

export const getSalesData = (mockSeed, date) =>
  dispatch =>
    dispatch(
      callApi(`Sales/salesdata?fakeSeed=${mockSeed}&date=${date.format()}`)
    )
      .then(result => result.json())
      .then(newJson => {
        dispatch(salesDataReceived(newJson, {}));

        return newJson;
      });
