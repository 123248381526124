import { callApi } from 'shared/CallApi';

export const getVenues = venueConfigId => dispatch =>
  dispatch(callApi(`venue/${venueConfigId}`))
    .then(results => results.json());

export const getRoomAvailability = venueConfigId => dispatch =>
  dispatch(callApi(`venue/${venueConfigId}/Availability`))
    .then(results => results.json());

export const updateVenue = (venueConfigId, venue) => dispatch =>
  dispatch(callApi(`venue/${venueConfigId}`, { body: venue }))
    .then(results => results.json());

export const addOneOffRoom = (venueConfigId, room) => dispatch =>
  dispatch(callApi(`venue/${venueConfigId}/custom`, { body: room }));
