import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CallbackComponent } from 'redux-oidc';
import userManager from './userManager';
import { CircularProgress } from '@material-ui/core';
import { Redirect as RouterRedirect } from 'react-router-dom';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1000,
    background: theme.palette.grey[100],
    width: '100vw',
    height: '100vh',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    margin: theme.spacing.unit,
  },
});

class Redirect extends Component {
  state = {};

  loginCallBack = args => {
    this.setState({ isLoggedIn: true });
  }

  loginCallBackError = error => {
    if (!!error && !!error.message) {
      const clockIsBehind = error.message.includes('iat is in the future');
      const clockIsAhead = error.message.includes('exp is in the past');

      if (clockIsAhead || clockIsBehind) {
        alert('Your computer clock does not match the time on our servers. Please set your computer clock to the correct time and retry your connection.');
      }
    }

    console.error(error);
    this.loginCallBack();
  }

  render() {
    const { isLoggedIn } = this.state;

    if (isLoggedIn) {
      return <RouterRedirect to="/" />;
    }
    const { classes } = this.props;

    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.loginCallBack}
        errorCallback={this.loginCallBackError}
      >
        <div className={classes.root}>
          <div className={classes.loading}>
            <CircularProgress className={classes.progress} />
            Redirecting...
          </div>
        </div>
      </CallbackComponent>
    );
  }
}

export default withStyles(styles)(Redirect);
