import React, { Component } from 'react';
import {
  TextField as MaterialTextField,
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  flexGrowWithPad: {
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
  },
  label: {
    color: theme.palette.grey[200],
  },
  input: {
    color: theme.palette.grey[700],
  },
});

class TextField extends Component {

  fieldChanged = event => {
    if (!this.props.onFieldChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    this.props.onFieldChange(event.target.value);
  }

  onBlur = () => {
    this.props.onBlur && this.props.onBlur();
  }

  render() {
    const {
      classes,
      disabled,
      value,
      fieldName,
      label,
      placeholder,
      error,
      type,
      inputAdornment,
      className,
      inputComponent,
      margin,
      inputRef,
      helperText,
      endAdornment,
      maxLength,
      minValue,
      maxValue,
    } = this.props;

    return (
      <MaterialTextField
        label={label}
        placeholder={placeholder}
        error={error}
        className={classNames(classes.flexGrowWithPad, className)}
        onChange={this.fieldChanged}
        name={fieldName}
        margin={margin || "normal"}
        value={value || ''}
        disabled={disabled}
        type={type}
        helperText={helperText}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        inputProps={{
          maxLength: maxLength,
          min: minValue,
          max: maxValue,
          classes: {
            root: classes.input,
            disabled: classes.input,
          },
          inputComponent: inputComponent,
          onBlur: this.onBlur,
          startAdornment: inputAdornment,
          endAdornment: endAdornment,
        }}
        inputRef={inputRef}
      />
    );
  }
}

export default withStyles(styles)(TextField);
