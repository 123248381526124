import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SimpleDialog from 'Components/SimpleDialog';
import moment from 'moment';
import { Clear as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

const styles = theme => ({
  deleteIcon: {
    color: theme.common.softPink,
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editIcon: {
    color: theme.palette.grey[700],
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

class Payment extends Component {

  confirmDeleteDialog = React.createRef();

  onEdit = () => {
    const { payment, onEdit } = this.props;

    onEdit && onEdit(payment);
  }

  onDelete = () => {
    const { payment, onDelete } = this.props;

    this.confirmDeleteDialog.current.open()
      .then(() => {
        onDelete && onDelete(payment.id);
      })
      .catch(() => {/* noop */});
  }

  translatePaymentMethod = method => {
    switch (method) {
      case 0:
        return 'Cash';
      case 1:
        return 'Check';
      case 2:
        return 'House Account';
      default:
        return '';
    }
  }

  render() {
    const { classes, className, payment, formatter } = this.props;
    const dateAppliedFormatted = moment(payment.dateApplied).format('MM/DD/YYYY');

    return (
      <>
        <tr className={className}>
          <td>{dateAppliedFormatted}</td>
          <td style={{ textAlign: 'right', paddingRight: 6 }}>{formatter.formatNumber(payment.amount, 'c')}</td>
          <td>{this.translatePaymentMethod(payment.paymentMethod)}</td>
          <td>{payment.receivedBy}</td>
          <td>{payment.madeBy}</td>
          <td>
            <div style={{ textAlign: 'center' }}>
              <label htmlFor="payment-edit">
                <EditIcon
                  aria-label={`Edit Payment`}
                  className={classes.editIcon}
                  onClick={this.onEdit}
                />
              </label>
              <label htmlFor="payment-delete">
                <DeleteIcon
                  aria-label={`Delete Payment`}
                  className={classes.deleteIcon}
                  onClick={this.onDelete}
                />
              </label>
            </div>
          </td>
        </tr>
        <SimpleDialog
          message="Are you sure you want to delete this payment?"
          innerRef={this.confirmDeleteDialog}
        />
      </>
    );
  }
}

export default withStyles(styles)(Payment);
