import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core';
import Avatar from 'Components/Avatar';

const styles = theme => ({
  overflow: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: 12,
    height: '480px',

  },
  fitContent: {
    height: 'fit-content',
  },
  card: {
    width: 140,
    maxHeight: 140,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    boxShadow: `0 2px 2px 0 rgba(0,0,0,0.2)`,
    cursor: 'pointer',
    position: 'relative',
  },
  cardTitle: {
    textAlign: 'center',
    fontSize: '13px',
    marginTop: 5,
    overflow: 'hidden',
    padding: '0px 5px 0px 5px',
  },
  selectedTriangle: {
    height: 121,
    width: 120,
    position: 'absolute',
    backgroundImage: 'url(/icons/check-corner.svg)',
    backgroundRepeat: 'no-repeat',
    borderRadius: 4,
  },
});

export class StoreMenuImagePreview extends Component {

  onItemSelected = item => () => {
    this.props.onItemSelected(item);
  }

  render() {
    const { classes, menuItems } = this.props;

    return (
      <div className={classes.overflow}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={24}
          classes={{
            container: classes.fitContent,
          }}
        >
          { menuItems.map((menuItem,index) => (
            <Grid item key={index}>
              <Card className={classes.card} onClick={this.onItemSelected(menuItem)}>
                {!!menuItem.imageUrl ?
                  <CardMedia
                    style={{ width: 140, height: 93 }}
                    image={menuItem.imageUrl}
                  />
                  :
                  <Avatar name={menuItem.name} />
                }
                <CardContent className={classes.cardTitle}>
                  {menuItem.name}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(StoreMenuImagePreview);
