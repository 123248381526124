import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import SectionHeader from 'Components/SectionHeader';
import Map from 'Components/Map';
import { getSites } from 'actions/sites';
import IconButton from 'Components/Buttons/IconButton';
import { Add as AddIcon } from '@material-ui/icons';
import CustomSiteModal from './CustomSiteModal';
import SmallGrid from 'Components/Grid/SmallGrid';
import {
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import { provideIntlService } from '@progress/kendo-react-intl';
import {
  updateStoreFocusedEvent,
} from 'actions/booking';
import classNames from 'classnames';

const styles = theme => ({
  sitesContainer: {
    display: 'flex',
  },
  siteSelection: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.common.paper,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  gridContainer: {
    height: 190,
    flexGrow: 1,
  },
  mapContainer: {
    height: '400px',
    flexGrow: 1,
    width: '30%',
  },
  expanded: {
    marginTop: 0,
  },
  header: {
    margin: '10px 0 0',
    paddingRight: 32,
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconButton: {
    padding: 0,
    color: theme.palette.grey[800],
  },
  gridsContainer: {
    width: '70%',
    height: '400px',
  },
});

class SiteSelection extends Component {
  constructor(props) {
    super(props);
    this.formatter = provideIntlService(this);
    this.state = {
      isHittingApi: false,
    };
  }

  componentDidMount() {
    this.props.getSites();
  }

  onSiteClick = clickedLocation => {
    const { focusedEvent } = this.props;

    if (clickedLocation.selected) {
      focusedEvent.bookingEventSites = focusedEvent.bookingEventSites.filter(site => site.siteId !== clickedLocation.id);
      this.setState({ focusedSite: null });
    } else {
      const bookingEventSite = {
        ...clickedLocation.siteAddress,
        ...clickedLocation,
        siteAddress: null,
      };

      if (bookingEventSite.id) delete bookingEventSite.id;

      if (focusedEvent.bookingEventSites) {
        focusedEvent.bookingEventSites.push(bookingEventSite);
      } else {
        focusedEvent.bookingEventSites = [bookingEventSite];
      }

      this.setState({ focusedSite: bookingEventSite });
    }

    this.props.updateStoreFocusedEvent(focusedEvent);
  }

  getSelectedSites = sites => {
    return sites.filter(s => s.selected)
      .map(s => s.siteAddress);
  }

  openCustomSiteModal = () => {
    this.setState({ isCustomSiteModalOpen: true });
  }

  closeCustomSiteModal = () => {
    this.setState({ isCustomSiteModalOpen: false });
  }

  saveCustomSite = customSite => {
    const {
      focusedEvent,
    } = this.props;

    if (focusedEvent.bookingEventSites) {
      focusedEvent.bookingEventSites.push(customSite);
    } else {
      focusedEvent.bookingEventSites = [customSite];
    }

    this.setState({ focusedSite: customSite });
    this.props.updateStoreFocusedEvent(focusedEvent);

    this.closeCustomSiteModal();
  }

  deleteCustomSite = index => {
    const {
      focusedEvent,
    } = this.props;

    focusedEvent.bookingEventSites = focusedEvent.bookingEventSites.filter((site, siteIndex) => siteIndex !== index);

    this.props.updateStoreFocusedEvent(focusedEvent);
  }

  render() {
    const { classes, sites, focusedEvent } = this.props;
    const { isCustomSiteModalOpen, focusedSite } = this.state;

    const formattedSites = sites ? sites
      .map((site, index) => ({
        ...site,
        index: index,
        rateString: `${this.formatter.formatNumber(site.rate, 'c2')}/${site.rateType}`,
        selected: focusedEvent.bookingEventSites && focusedEvent.bookingEventSites.some(br => br.siteId === site.id),
      })) : [];

    const customSites = focusedEvent.bookingEventSites ? focusedEvent.bookingEventSites.filter(site => !site.siteId).map((site, index) => ({
      ...site,
      index: index,
    })) : [];

    return (
      <div className={classes.siteSelection}>
        <SectionHeader className={classes.header}>
          Site Picker
          <IconButton
            height={24}
            icon={AddIcon}
            onClick={this.openCustomSiteModal}
            className={classes.iconButton}
          />
        </SectionHeader>
        <div className={classes.sitesContainer}>
          <div className={classNames(classes.gridsContainer, classes.siteSelection)}>
            {customSites && customSites.length > 0 && <CoverExpansionPanel title="Custom Sites" defaultExpanded={true} customstyles={{ expanded: classes.expanded }}>
              <SmallGrid
                data={customSites}
                resizable={true}
              >
                <Column field="name" title="Site" cell={this.nameCell} />
                <Column field="address1" title="Address" />
                <Column field="city" title="City" width="100px" />
                <Column field="stateProvince" title="State" width="75px" />
                <Column field="postalCode" title="Zip" width="75px" />
                <Column field="price" title="Site Price" format="{0:c}" />
                <Column cell={props => (
                  <td style={{ textAlign: 'center' }}>
                    <button onClick={() => this.deleteCustomSite(props.dataItem.index)}>Delete</button>
                  </td>
                )} />
              </SmallGrid>
            </CoverExpansionPanel>}
            <CoverExpansionPanel title="Sites" defaultExpanded={true} customstyles={{ expanded: classes.expanded }}>
              <SmallGrid
                data={formattedSites}
                resizable={true}
              >
                <Column
                  field=""
                  width="50px"
                  cell={props => (
                    <td style={{ textAlign: 'center' }}>
                      <input
                        className="k-checkbox"
                        type="checkbox"
                        id={`location-${props.dataItem.id}`}
                        checked={props.dataItem.selected}
                        onChange={() => this.onSiteClick(props.dataItem)}
                      />
                      <label htmlFor={`location-${props.dataItem.id}`} className="k-checkbox-label"></label>
                    </td>
                  )}
                />
                <Column field="name" title="Site" cell={this.nameCell} />
                <Column field="siteAddress.address1" title="Address" />
                <Column field="siteAddress.city" title="City" width="100px" />
                <Column field="siteAddress.stateProvince" title="State" width="75px" />
                <Column field="siteAddress.postalCode" title="Zip" width="75px" />
                <Column field="rateString" title="Rate" width="100px" />
              </SmallGrid>
            </CoverExpansionPanel>

            {isCustomSiteModalOpen &&
              <CustomSiteModal
                isOpened={true}
                onSaveCustomSite={this.saveCustomSite}
                onClose={this.closeCustomSiteModal}
              />
            }
          </div>
          <div className={classes.mapContainer}>
            {sites && sites.length > 0 &&
              <Map
                locations={focusedEvent.bookingEventSites}
                selectedLocation={focusedSite}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    api: {
      sites,
    },
    booking: {
      [ownProps.storeType]: {
        focusedEvent,
      },
    },
  } = state;

  return {
    sites,
    focusedEvent,
  };
};

const mapDispatchToProps = {
  getSites,
  updateStoreFocusedEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SiteSelection));
