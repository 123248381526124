import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  dropdownCell: {
    width: '100%',
  },
  text: {
    color: theme.palette.grey['300'],
    fontSize: '14px',
  },
});

class DropdownCell extends Component {
  optionChanged = options => {
    this.props.onChange(options.target);
  }

  render() {
    const { classes, options, value, name, maxWidth } = this.props;

    return (
      <td style={{ maxWidth }}>
        <FormControl margin="none" className={classes.dropdownCell}>
          <Select
            value={value || 0}
            onChange={this.optionChanged}
            name={name}
            classes={{
              select: classes.text,
            }}
          >
            {options.map(option =>
              <MenuItem key={option.id} value={option.id || -1} className={classes.text}>{option.name}</MenuItem>
            )}
          </Select>
        </FormControl>
      </td>
    );
  }
}

DropdownCell.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  name: PropTypes.string,
};

DropdownCell.defaultProps = {
  options: [],
  name: '',
};

export default withStyles(styles)(DropdownCell);
