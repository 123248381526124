import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { Tooltip } from '@material-ui/core';

const styles = theme => ({
  root: {
    border: '1px solid',
    borderRadius: '4px',
    minWidth: 44,
    height: 32,
    textTransform: 'initial',
    background: theme.palette.secondary.contrastText,
    borderColor: theme.palette.grey[100],
    color: theme.palette.grey[400],
    '&:hover': {
      background: lighten(theme.palette.secondary.main, 0.75),
    },
  },
  active: {
    color: '#4DA1FF',
  },
});

class HeaderButton extends Component {
  render() {
    const { classes, children, minWidth, isActive, name, icon, ...rest } = this.props;
    const style = minWidth && { minWidth };

    return (
      <Tooltip title={name} aria-label={name}>
        <Button {...rest}
          style={style}
          classes={{
            root: classNames(classes.root, isActive && classes.active),
          }}
        >
          {children}
          {icon}
        </Button>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(HeaderButton);
