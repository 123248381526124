
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  TextField,
} from '@material-ui/core';
import FilterOptions from './FilterOptions';
import Checkbox from './Checkbox';

const styles = theme => {

  return {
    spendRange: {
      alignItems: 'center',
      display: 'flex',
      padding: '0 1% 1% 1%',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 10,
      },
    },
    statuses: {
      paddingLeft: '1%',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 30,
      },
      display: 'flex',
      flexDirection: 'column',
    },
    filterHeader: {
      fontSize: 13,
      color: theme.palette.grey[500],
      textTransform: 'uppercase',
      padding: '1%',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    minMaxColor: {
      color: theme.palette.grey[700],
    },
    throughDash: {
      paddingTop: 19,
      paddingLeft: 6,
      paddingRight: 6,
      color: theme.palette.grey[700],
    },
    noMargin: {
      margin: 0,
    },
    rightPaper: {
      paddingTop: 17,
    },
  };
};

class FilterBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        filterId: 1,
      },
    };
  }

  handleChange = field => value => {
    const filter = {
      ...this.state.filter,
      [field]: value,
    };

    this.setState({ filter });
    this.props.onFilterChanged(filter);
  }

  filterOptionClicked = newOption => {
    const filter = {
      ...this.state.filter,
      filterId: newOption.id,
    };

    this.setState({ filter });
    this.props.onFilterChanged(newOption);
  }

  render() {
    const {
      classes,
      customerTypes,
    } = this.props;
    const {
      filter: {
        filterId,
      },
    } = this.state;
    const minMaxProps = {
      classes: {
        root: classes.minMaxColor,
      },
    };

    return (
      <Paper className={classes.rightPaper}>
        <div className={classes.filterHeader}>Types</div>
        {customerTypes.map(customerType => (
          <FilterOptions
            isActive={filterId === customerType.id}
            key={customerType.id}
            option={customerType}
            onClick={this.filterOptionClicked}
          />))}
        <div className={classes.filterHeader}>Spend Range</div>
        <div className={classes.spendRange}>
          <Checkbox
            onChange={this.handleChange('useMinMax')}
          />
          <TextField
            className={classes.noMargin}
            InputLabelProps={minMaxProps}
            id="minSpend"
            label="Min"
            onChange={this.handleChange('minSpend')}
            margin="dense"
          />
          <div className={classes.throughDash}>-</div>
          <TextField
            className={classes.noMargin}
            InputLabelProps={minMaxProps}
            id="maxSpend"
            label="Max"
            onChange={this.handleChange('maxSpend')}
            margin="dense"
          />

        </div>
        <div className={classes.filterHeader}>Status</div>
        <div className={classes.statuses}>
          <Checkbox
            label="Bookings"
            onChange={this.handleChange('bookings')}
          />
          <Checkbox
            label="Proposals"
            onChange={this.handleChange('proposal')}
          />
        </div>
        <div className={classes.filterHeader}>Reference</div>
        <div className={classes.statuses}>
          <Checkbox
            label="All"
            onChange={this.handleChange('all')}
          />
          <Checkbox
            label="Referral"
            onChange={this.handleChange('referral')}
          />
          <Checkbox
            label="Website"
            onChange={this.handleChange('website')}
          />
          <Checkbox
            label="Email Marketing"
            onChange={this.handleChange('email')}
          />
        </div>
      </Paper>

    );
  }
}
export default withStyles(styles)(FilterBox);
