import { callApi } from 'shared/CallApi';
import {
  SITES_RECEIVED,
} from './constants';

export const sitesReceived = sites => ({
  type: SITES_RECEIVED,
  sites,
});

export const getSites = () => dispatch =>
  dispatch(callApi(`Site`))
    .then(result => result.json())
    .then(sites => {
      dispatch(sitesReceived(sites));

      return sites;
    });

export const saveLatLng = (siteConfigId, latLngUpdate) => {
  let okay = true;
  let status = 0;
  let statusText;

  return dispatch =>
    dispatch(callApi(`Site/${siteConfigId}/latlng`, { body: latLngUpdate }))
      .then(result => {
        if (!result.ok) {
          status = result.status;
          statusText = result.statusText;
          okay = false;
        }

        return result.json();
      })
      .then(json => {
        if (okay) {
          return json;
        }
        if (json) {
          throw Error(json);
        }
        if (statusText || status) {
          throw Error(`${status} - ${statusText}`);
        }
        throw Error('unknown');
      });
};
