import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  header: {
    color: theme.palette.text.primary,
    fontSize: '27px',
    lineHeight: '27px', //mocks have lineHeight of 37px, but set it to fontSize, when the extra is done as padding/margin elsewhere
  },
});

const Header = ({ children, classes: { header } }) => (<h4 className={header}>{children}</h4>);

export default withStyles(styles)(Header);
