
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
} from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => {

  return {
    checkBoxContainer: {
      marginLeft: 0,
    },
    checkboxWithLabel: {
      color: theme.palette.grey[100],
      paddingLeft: 0,
      paddingTop: 9,
      paddingBottom: 9,
      marginLeft: -3,
    },
    checkbox: {
      paddingTop: 15,
      paddingBottom: 0,
    },
    checkBoxLabel: {
      color: theme.palette.grey[300],
    },
  };
};

class Checkbox extends Component {
  checkBoxClicked = event => {
    this.props.onChange(event.target.checked);
  }

  render() {
    const {
      classes,
      label,
    } = this.props;

    return (
      label ? <FormControlLabel
        classes={{
          root: classes.checkBoxContainer,
          label: classes.checkBoxLabel,
        }}
        control={
          <MaterialCheckbox
            className={classes.checkboxWithLabel}
            disableRipple={true}
            onChange={this.checkBoxClicked}
          />
        }
        label={label}
      />
        :
        <MaterialCheckbox
          className={classNames(classes.checkbox, classes.checkboxWithLabel)}
          onChange={this.checkBoxClicked}
          disableRipple={true}
        />
    );
  }
}

export default withStyles(styles)(Checkbox);
