import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MoreVert from '@material-ui/icons/MoreVert';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import { provideIntlService } from '@progress/kendo-react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';

import SimpleDialog from 'Components/SimpleDialog';

const styles = theme => ({
  eventCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 226,
    height: 170,
    padding: 16,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 4,
    fontSize: 13,
    marginBottom: 16,
    cursor: 'pointer',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  grey: {
    color: theme.palette.grey[200],
    padding: '4px 0',
  },
  name: {
    width: 160,
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    lineHeight: '18px',
    maxHeight: 54,
    display: '-webkit-box',
  },
  buttonContainer: {
    margin: '-8px -8px 0 0',
  },
  iconButton: {
    color: theme.palette.grey[200],
    padding: 8,
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: 10,
  },
  focused: {
    borderColor: theme.palette.action.active,
    borderWidth: 2,
  },
  unincluded: {
    borderColor: theme.palette.grey[100],
    background: theme.palette.grey[50],
    color: theme.palette.grey[200],
  },
});

class EventCard extends Component {
  state = {
    optionsMenu: null,
  };

  formatter = provideIntlService(this);

  confirmDeleteDialog = React.createRef();

  openOptionsMenu = domEvent => {
    domEvent.stopPropagation();
    this.setState({ optionsMenu: domEvent.currentTarget });
  };

  closeOptionsMenu = domEvent => {
    domEvent && domEvent.stopPropagation();
    this.setState({ optionsMenu: null });
  };

  onDeleteEvent = domEvent => {
    const { onDelete } = this.props;

    if (domEvent) domEvent.stopPropagation();

    this.closeOptionsMenu();
    this.confirmDeleteDialog.current.open()
      .then(() => {
        if (onDelete) {
          onDelete();
        } else {
          console.error('onDelete not set on EventCard');
        }
      });
  }

  onCloneEvent = domEvent => {
    const { onClone } = this.props;

    if (domEvent) domEvent.stopPropagation();

    this.closeOptionsMenu();
    if (onClone) onClone();
  }

  onUnincludeEvent = domEvent => {
    domEvent.stopPropagation();
    this.closeOptionsMenu();
    this.props.onSetIsIncludedInBookingTotal && this.props.onSetIsIncludedInBookingTotal();
  }

  onIncludeEvent = domEvent => {
    domEvent.stopPropagation();
    this.closeOptionsMenu();
    this.props.onSetIsIncludedInBookingTotal && this.props.onSetIsIncludedInBookingTotal();
  }

  render() {
    const { classes, event, isFocused, onSelectEvent } = this.props;
    const { optionsMenu } = this.state;

    return (
      <div
        onClick={onSelectEvent}
        className={classNames(
          classes.eventCard,
          isFocused && classes.focused,
          event.includedInBookingTotal === false && classes.unincluded
        )}
      >
        <div className={classes.top}>
          <div className={classes.nameDate}>
            <p className={classes.name}>{event.name}</p>
            <p className={classes.grey}>{event.startDateTime && moment(event.startDateTime).clone().format('l')}</p>
          </div>

          <div className={classes.buttonContainer}>
            <IconButton
              className={classNames(classes.iconButton)}
              onClick={this.openOptionsMenu}
              aria-label="Event options"
              aria-owns="event-card-menu"
              aria-haspopup="true"
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="event-card-menu"
              anchorEl={optionsMenu}
              open={Boolean(optionsMenu)}
              onClose={this.closeOptionsMenu}
            >
              <MenuItem onClick={this.onDeleteEvent}>Delete</MenuItem>
              <MenuItem onClick={this.onCloneEvent}>Clone</MenuItem>
              {event.includedInBookingTotal ?
                <MenuItem onClick={this.onUnincludeEvent}>Uninclude</MenuItem>
                :
                <MenuItem onClick={this.onIncludeEvent}>Include</MenuItem>
              }
            </Menu>
          </div>
        </div>
        <div className={classes.bottom}>
          <div className={classes.bottomData}>
            <p className={classes.grey}>TIME</p>
            <p>{event.startDateTime && moment(event.startDateTime).clone().format('LT')} - {event.endDateTime && moment(event.endDateTime).clone().format('LT')}</p>
          </div>

          <div className={classes.bottomData}>
            <p className={classes.grey}>VALUE</p>
            <p>{this.formatter.formatNumber(event.estimatedSpend || 0, 'c')}</p>
          </div>
        </div>
        <SimpleDialog
          message="Are you sure you want to delete this event?"
          innerRef={this.confirmDeleteDialog}
        />
      </div>
    );
  }
}

EventCard.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onSetIsIncludedInBookingTotal: PropTypes.func.isRequired, //only used for setting Uninclude/Include
};

export default withStyles(styles)(EventCard);
