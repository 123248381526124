import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Slide,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const styles = theme => ({
  alert: {
    margin: 30,
  },
  alignCenter: {
    textAlign: 'center',
  },
  root: {
    zIndex: theme.zIndex.modal + 1,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SimpleDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.rootClass = {
      root: props.classes.root,
    };
  }

  open = dynamicMessage => {
    this.promise = new Promise(
      (resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      }
    );

    this.setState({ isOpen: true, dynamicMessage });

    return this.promise;
  }

  noClicked = () => {
    this.reject();
    this.setState({ isOpen: false });
  }

  yesClicked = () => {
    const { yesClicked } = this.props;

    if (yesClicked) {
      yesClicked();
    }

    this.resolve();
    this.setState({ isOpen: false });
  }

  render() {
    const { classes, message, onlyOkayButton } = this.props;
    const { isOpen, dynamicMessage } = this.state;

    return (
      <Dialog
        classes={this.rootClass}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted={true}
      >
        <div className={classes.alert}>
          <DialogContentText className={classes.alignCenter}>
            {dynamicMessage || message || 'set message'}
          </DialogContentText>
          <DialogActions>
            {onlyOkayButton ?
              <Button onClick={this.yesClicked} color="primary">
                Okay
              </Button> :
              <>
                <Button onClick={this.yesClicked} color="primary">
                  Yes
                </Button>
                <Button onClick={this.noClicked} color="primary">
                  No
                </Button>
              </>
            }
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  message: PropTypes.string,
  onlyOkayButton: PropTypes.bool,
  yesClicked: PropTypes.func,
};

// We need an intermediary variable for handling the recursive nesting.
export default withStyles(styles)(SimpleDialog);

