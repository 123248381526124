import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AddressManager from 'Components/AddressManager';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  templateHeader: {
    display: 'flex',
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    color: theme.palette.grey[800],
    paddingBottom: 5,
    borderBottom: `2px solid ${theme.palette.grey.A200}`,
  },
  addressType: {
    width: 200,
  },
  addressAndMap: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    color: theme.palette.action.active,
  },
  disabledForm: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
  manager: {
    display: 'flex',
    flex: 1,
  },
});

const OrderAddressManager = ({
  classes,
  order,
  contactAddresses,
  locations,
  contactId,
  onAddressChange,
  onHandlePrimaryAddress,
  onNewAddress,
  onDeleteAddress,
  onSelectAddress,
  selectedAddressId,
  onSelectLocation,
  isEditing,
}) => {
  const isDelivery = order.type === 'Delivery';

  return (
    <div className={classes.root}>
      <div className={classes.templateHeader}>
        <div className={classes.addressType}>
          Associated Addresses
        </div>
        <div className={classes.addressAndMap}>
          Address & Map
        </div>
      </div>
      <div className={isDelivery ? classes.manager : classes.disabledForm}>
        <AddressManager
          addresses={contactAddresses.filter(address => address.recordStatus !== 'Deleted')}
          parentId={contactId}
          isEditing={isEditing}
          onAddressChange={onAddressChange}
          onHandlePrimaryAddress={onHandlePrimaryAddress}
          onNewAddress={onNewAddress}
          onDeleteAddress={onDeleteAddress}
          onSelectAddress={onSelectAddress}
          selectedAddressId={selectedAddressId}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(OrderAddressManager);
