var removeDiacritics = require('diacritic').clean;

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
var specialCharsRegex = /[.*+?^${}()|[\]\\]/g;

// http://www.ecma-international.org/ecma-262/5.1/#sec-15.10.2.6
var wordCharacterRegex = /[a-z0-9_]/i;

var whitespacesRegex = /\s+/;

function escapeRegexCharacters(str) {
  return str.replace(specialCharsRegex, '\\$&');
}

//modeled after https://github.com/moroshko/autosuggest-highlight/blob/master/src/match.js
//added ability to match inside a word and not just the beginning
const matcher = query => {
  if (!query) {
    return () => [];
  }

  const arrayOfResults = query
    .trim()
    .split(whitespacesRegex)
    // If query is blank, we'll get empty string here, so let's filter it out.
    .filter(function(word) {
      return word.length > 0;
    })
    .map(word => (
      {
        regex: new RegExp(escapeRegexCharacters(word), 'i'),
        wordLen: word.length,
      }));

  return text =>
    arrayOfResults
      .reduce((result,  searchTerms) => {
        var index = text.search(searchTerms.regex);

        if (index > -1) {
          result.push([index, index + searchTerms.wordLen]);

          // Replace what we just found with spaces so we don't find it again.
          text =
            text.slice(0, index) +
            new Array(searchTerms.wordLen + 1).join(' ') +
            text.slice(index + searchTerms.wordLen);
        }

        return result;
      }, [])
      .sort(function(match1, match2) {
        return match1[0] - match2[0];
      });

};

const match = (text, query) => {
  text = removeDiacritics(text);
  query = removeDiacritics(query);

  return (
    query
      .trim()
      .split(whitespacesRegex)
      // If query is blank, we'll get empty string here, so let's filter it out.
      .filter(function(word) {
        return word.length > 0;
      })
      .reduce(function(result, word) {
        var wordLen = word.length;
        var prefix = wordCharacterRegex.test(word[0]) ? '\\b' : '';

        var regex = new RegExp(prefix + escapeRegexCharacters(word), 'i');
        var index = text.search(regex);

        if (index > -1) {
          result.push([index, index + wordLen]);

          // Replace what we just found with spaces so we don't find it again.
          text =
            text.slice(0, index) +
            new Array(wordLen + 1).join(' ') +
            text.slice(index + wordLen);
        }

        return result;
      }, [])
      .sort(function(match1, match2) {
        return match1[0] - match2[0];
      })
  );
};

export { matcher, match };

export default match;
