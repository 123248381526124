import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { addContact } from 'actions/contact';
import { addLead } from 'actions/lead';
import { addAccount } from 'actions/account';
import {
  Drawer,
  List,
} from '@material-ui/core';
import IconButton from 'Components/Buttons/IconButton';
import {
  Home as HomeIcon,
  Today as CalendarIcon,
  Forum as ChitChatIcon,
  Person as ContactIcon,
  Menu as HamburgerIcon,
  RestaurantMenu as OrderIcon,
  Business as AccountIcon,
  CardGiftcard as LeadIcon,
  ListAlt as BookingIcon,
  Laptop as AdminIcon,
} from '@material-ui/icons';
import ReportsIcon from 'shared/icons/prints';
import BookingWizard from 'bookings/BookingWizard/BookingWizard';
import SimpleDialog from 'Components/SimpleDialog';
import NavPill from './NavPill';
import SideNavContext from './SideNavContext';
import { userIsInRole, adminRole } from 'helpers/userHelpers';
import {
  addStatusHistory,
} from 'actions/statusHistory';

const drawerWidth = 239;
const drawerCollapsedWidth = 80; //for screen sizes above small

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    minHeight: '100%',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  drawerPaperClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 30,
    [theme.breakpoints.up('sm')]: {
      width: drawerCollapsedWidth,
    },
  },
  divider: {
    borderTop: `1.5px solid ${theme.palette.secondary.main}`,
    paddingTop: '40px',
  },
  hamburgerContainer: {
    width: 30,
    [theme.breakpoints.up('sm')]: { //.up becomes (min-width: 600px)
      width: drawerCollapsedWidth,
    },
    textAlign: 'center',
  },
  hamburgerButton: {
    color: theme.palette.grey[100],
    [theme.breakpoints.down('xs')]: { //.down('sm') becomes (max-width: 1023.95px)
      padding: 0,
    },
  },
});

const SideNav = ({
  theme,
  classes,
  recents,
  addAccount,
  addContact,
  addLead,
  hasAccessToAdmin,
  currentLocation,
  user,
  addStatusHistory,
  statuses,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isBookingWizardOpen, setIsBookingWizardOpen] = useState(false);
  const [hoverMenuAnchorEl, setHoverMenuAnchorEl] = useState(null);
  const history = useHistory();
  const okDialog = React.createRef();

  const handleDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onSetHoverMenuAnchorEl = target => {
    setHoverMenuAnchorEl(target);
  };

  const createAccount = () => {
    if (currentLocation && currentLocation.id) {
      createAccountAndGo();
    } else {
      okDialog.current.open('Please select a location before creating an account')
        .then(() => createAccountAndGo());
    }
  };

  const createAccountAndGo = () => {
    addAccount({ name: 'New', locationId: currentLocation.id }).then(account => {
      history.push(`/accounts/${account.id}`);
    });
  };

  const createBooking = () => {
    setIsBookingWizardOpen(true);
  };

  const createContact = () => {
    if (currentLocation && currentLocation.id) {
      createContactAndGo();
    } else {
      okDialog.current.open('Please select a location before creating a contact')
        .then(() => createContactAndGo());
    }
  };

  const createContactAndGo = () => {
    const newContact = {
      firstName: 'New',
      locationId: currentLocation.id,
    };

    addContact(newContact).then(newEntity => {
      history.push(`/contacts/${newEntity.id}`);
    });
  };

  const createLead = () => {
    if (currentLocation && currentLocation.id) {
      createLeadAndGo();
    } else {
      okDialog.current.open('Please select a location before creating a lead')
        .then(() => createLeadAndGo());
    }
  };

  const createLeadAndGo = () => {
    addLead({ firstName: 'New', locationId: currentLocation.id }).then(lead => {
      history.push(`/leads/${lead.id}`);
      if (lead.statusId) addStatusHistory('Lead', lead.id, lead.statusId);
    });
  };

  const createOrder = () => {
    if (currentLocation && currentLocation.id) {
      history.push(`/orders/new`);
    } else {
      okDialog.current.open('Please select a location before starting an order')
        .then(() => history.push(`/orders/new`));
    }
  };

  const closeBookingWizard = () => {
    setIsBookingWizardOpen(false);
  };

  const isHomePath = (match, location) => {
    if (!match) return location.pathname === '/';

    return true;
  };

  const sideNavContext = {
    hoverMenuAnchorEl,
    onSetHoverMenuAnchorEl,
  };

  const userIsCoverAdmin = userIsInRole(user, adminRole);

  return (
    <SideNavContext.Provider value={sideNavContext}>
      <Drawer
        open={isDrawerOpen}
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
        }}
      >
        <List className={classes.divider}>
          <NavPill
            to="/home"
            icon={<HomeIcon />}
            name="Home"
            isActive={isHomePath}
          />
          {userIsCoverAdmin ? <>
            <NavPill
              to="/calendar"
              icon={<CalendarIcon />}
              name="Calendar"
            />
            <NavPill
              to="/chitchat"
              icon={<ChitChatIcon />}
              name="Chit Chat"
            />
            <NavPill
              to="/accounts"
              icon={<AccountIcon />}
              name="Accounts"
              recents={recents.Account}
              onAddNew={createAccount}
            /></> : <></>}
          <NavPill
            to="/contacts"
            icon={<ContactIcon />}
            name="Contacts"
            recents={recents.Contact}
            onAddNew={createContact}
          />
          {userIsCoverAdmin ? <>
          <NavPill
            to="/leads"
            icon={<LeadIcon />}
            name="Leads"
            recents={recents.Lead}
            onAddNew={createLead}
          />
          <NavPill
            to="/bookings"
            icon={<BookingIcon />}
            name="Bookings"
            recents={recents.Booking}
            onAddNew={createBooking}
          /></> : <></>}
          <NavPill
            to="/reports"
            icon={<ReportsIcon />}
            name="Reports"
          />
          <NavPill
            to="/orders"
            icon={<OrderIcon />}
            name="Orders"
            recents={recents.Order}
            onAddNew={createOrder}
          />
          {hasAccessToAdmin &&
            <NavPill
              to="/admin"
              icon={<AdminIcon />}
              name="Admin"
            />
          }
        </List>
        <div className={classes.hamburgerContainer}>
          <IconButton
            className={classes.hamburgerButton}
            color={theme.palette.grey[100]}
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            icon={HamburgerIcon}
          >
          </IconButton>
        </div>
      </Drawer>
      {isBookingWizardOpen && <BookingWizard onClose={closeBookingWizard} />}
      <SimpleDialog innerRef={okDialog} onlyOkayButton={true} />
    </SideNavContext.Provider>
  );
};

SideNav.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    recents,
    api: {
      currentLocation,
    },
    oidc: {
      user,
    },
    settings: {
      statuses,
    },
  } = state;

  return {
    recents,
    currentLocation,
    user,
    statuses,
  };
};

const mapDispatchToProps = {
  addContact,
  addAccount,
  addLead,
  addStatusHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(SideNav));
