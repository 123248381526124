import React, { Component } from 'react';
import ChitChat from 'Components/ChitChat';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { ENTITY_TYPES } from 'constants/entityTypes';
import ThreadFilter from './ThreadFilter';

const styles = theme => ({
  body: {
    display: 'flex',
    flexGrow: 1,
    margin: '24px 24px 0',
    height: 'calc(100% - 51px - 24px)',
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
  },
  mainContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '100%',
  },
  chitChatWrapper: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 0,
  },
  filters: {
    display: 'flex',
    alignItems: 'space-evenly',
    padding: 32,
  },
  selectLocationMessage: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class ChitChatAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: 'all',
    };
  }

  onFilterClick = filterName => {
    this.setState({ filter: filterName });
  }

  // TODO: DELETE
  hardCodedFilter = threads => {
    const re = RegExp('hyper|shire|friends','gi');

    return threads.filter(t => {
      return re.test(t.recordName);
    });
  }

  render() {
    const { classes, currentLocation, threads } = this.props;
    const { filter } = this.state;

    return (
      <div className={classes.body}>
        <Paper className={classes.paper}>
          <div className={classes.mainContent}>
            <div className={classes.filters}>
              <ThreadFilter
                name="ALL MESSAGES"
                threads={threads}
                onFilterClick={() => this.onFilterClick('all')}
                isActive={filter === 'all'}
              />
              <ThreadFilter
                name="WHAT I FOLLOW"
                threads={threads && this.hardCodedFilter(threads)} // TODO
                onFilterClick={() => this.onFilterClick('following')}
                isActive={filter === 'following'}
              />
              <ThreadFilter
                name="INBOX"
                threads={[]} // TODO
                onFilterClick={() => this.onFilterClick('inbox')}
                isActive={filter === 'inbox'}
              />
              <ThreadFilter
                name="SAVED"
                threads={[]} // TODO
                onFilterClick={() => this.onFilterClick('saved')}
                isActive={filter === 'saved'}
              />
              <ThreadFilter
                name="DRAFT"
                threads={[]} // TODO
                onFilterClick={() => this.onFilterClick('draft')}
                isActive={filter === 'draft'}
              />
            </div>
            <div className={classes.chitChatWrapper}>
              {currentLocation && currentLocation.id ?
                <ChitChat
                  relationshipId={1} // TODO: a hack until we find an entity to attach to. Location?
                  chitChatRelationship={ENTITY_TYPES.main}
                  recordName="Main"
                  isFullWidth={true}
                  filter={filter}
                />
                :
                <div className={classes.selectLocationMessage}>Please Select a Location to see Chit Chat</div>
              }
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      currentLocation,
      chitChat: {
        threads,
      },
    },
  } = state;

  return {
    currentLocation,
    threads,
  };
};

export default connect(mapStateToProps, undefined)(withStyles(styles)(ChitChatAll));
