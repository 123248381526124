import React, { Component } from 'react';
import { ContactMail } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';
import { Link } from 'react-router-dom';
import {
  Menu,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = theme => ({
  container: {
    fontSize: 12,
    display: 'flex',
    color: theme.palette.grey[900],
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 9,
    paddingBottom: 9,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  iconContainer: {
    paddingTop: 3,
    paddingRight: 16,
  },
  contentContainer: {
    flexGrow: 1,
    position: 'relative',
  },
  link: {
    color: theme.palette.action.active,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  primary: {
    color: theme.palette.error.dark,
  },
  other: {
    color: theme.common.darkGreen,
  },
  contentRow: {
    display: 'flex',
  },
  label: {
    color: theme.palette.grey[300],
    width: '50%',
  },
  downButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

class AccountContact extends Component {
  state = {};

  onEdit = () => {
    this.closeToolsMenu();
    this.props.onEdit(this.props.item);
  }

  openToolsMenu = event => {
    this.setState({ toolsOpenedBy: event.currentTarget });
  }

  closeToolsMenu = () => {
    this.setState({ toolsOpenedBy: null });
  }

  onRemove = () => {
    this.closeToolsMenu();
    this.props.onRemove(this.props.item);
  }

  onMarkPrimary = () => {
    this.closeToolsMenu();
    this.props.onMarkPrimary(this.props.item);
  }

  render() {
    const {
      item,
      classes,
      isPrimary,
      baseUrl,
    } = this.props;

    const {
      toolsOpenedBy,
    } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <ContactMail className={isPrimary ? classes.primary : classes.other} />
        </div>
        <div className={classes.contentContainer}>
          <Link className={classes.link} to={`${baseUrl}${item.id}`}>{item.name}</Link>
          {item.title &&
            <div className={classes.title}>{item.title}</div>
          }
          <div className={classes.contentRow}>
            <div className={classes.label}>Phone:</div>
            <div>{item.phone}</div>
          </div>
          <div className={classes.contentRow}>
            <div className={classes.label}>Email:</div>
            <div>{item.email}</div>
          </div>
          <ArrowDownButton className={classes.downButton} onClick={this.openToolsMenu} />
        </div>
        <Menu
          id="menu-options"
          anchorEl={toolsOpenedBy}
          open={Boolean(toolsOpenedBy)}
          onClose={this.closeToolsMenu}
        >
          <MenuItem onClick={this.onEdit}>Edit</MenuItem>
          <MenuItem onClick={this.onRemove}>Remove</MenuItem>
          {!isPrimary && <MenuItem onClick={this.onMarkPrimary}>Mark as Primary</MenuItem>}
        </Menu>
      </div>
    );
  }
}

AccountContact.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onMarkPrimary: PropTypes.func,
  item: PropTypes.object.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default withStyles(styles)(AccountContact);
