import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SectionHeader from 'Components/SectionHeader';
import CoverDateTimePicker from 'Components/CoverDateTimePicker';
import { cloneOrder } from 'actions/order';
import moment from 'moment';
import Checkbox from 'Components/Checkbox';

const styles = theme => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  picker: {
    width: 300,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    padding: '20px 32px',
  },
  checkboxes: {
    display: 'flex',
    marginLeft: -14,
  },
  sectionHeader: {
    width: '100%',
  },
});

class Clone extends Component {
  state = {
    isOpened: false,
    isCloning: false,
    clone: {
      includeFiles: true,
      includeNotes: true,
    },
  };

  open = () => {
    const { currentLocation } = this.props;

    this.setState({
      isOpened: true,
      clone: {
        requestedDateTime: moment(this.getNearestHalfHour()).format(),
        locationId: currentLocation.id,
        includeFiles: true,
        includeNotes: true,
      },
      datePickerMin: this.getNearestHalfHour(),
    });
  }

  close = () => {
    this.setState({ isOpened: false, isCloning: false });
  }

  handleSave = () => {
    const { storeOrder } = this.props;
    const { clone } = this.state;

    this.setState({ isCloning: true });
    this.props.cloneOrder(storeOrder.id, clone)
      .then(order => {
        this.close();
        this.props.onCloneSuccess(order.id);
      }).catch(() => {
        console.error('Order Clone failed for some reason.');
        this.setState({ isCloning: false });
      });
  }

  handleChange = fieldName => value => {
    const clone = {
      ...this.state.clone,
      [fieldName]: value,
    };

    this.setState({ clone });
  }

  handleDateTimeChange = fieldName => changedEventArgs => {
    if (!changedEventArgs) {
      return;
    }
    const newDate = moment(changedEventArgs.value).format();

    this.handleChange(fieldName)(newDate);
  }

  getNearestHalfHour = () => {
    const minutesToNextHalfHour = 30 - (moment().minute() % 30);

    return moment().add(minutesToNextHalfHour, 'minutes').toDate();
  }

  render() {
    const { classes } = this.props;
    const {
      isOpened,
      clone,
      datePickerMin,
      isCloning,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        onCancel={this.close}
        title={"Clone Order"}
        onSave={this.handleSave}
        saveText="Clone"
        dimensions={{ width: '385px', height: '385px' }}
        isSaveDisabled={isCloning}
      >
        <div className={classes.main}>
          <SectionHeader className={classes.sectionHeader}>
              When do you need it?
          </SectionHeader>
          <div className={classes.section}>
            <CoverDateTimePicker
              label="Requested Time"
              value={clone.requestedDateTime}
              onChange={this.handleDateTimeChange('requestedDateTime')}
              className={classes.picker}
              minDate={datePickerMin}
            />
          </div>
          <SectionHeader className={classes.sectionHeader}>
              Sections to Clone
          </SectionHeader>
          <div className={classes.section}>
            <div className={classes.checkboxes}>
              <Checkbox
                label="Notes"
                checked={clone.includeNotes}
                onFieldChange={this.handleChange('includeNotes')}
              />
              <Checkbox
                label="Files"
                checked={clone.includeFiles}
                onFieldChange={this.handleChange('includeFiles')}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      currentLocation,
    },
    storeReducer: {
      storeOrder,
    },
  } = state;

  return {
    currentLocation,
    storeOrder,
  };
};

const mapDispatchToProps = {
  cloneOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Clone));
