import React, { Component } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { withStyles } from '@material-ui/core/styles';
import { Clear as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import SectionHeader from 'Components/SectionHeader';
import CoverTimePicker from 'Components/CoverTimePicker';
import Modal from 'Components/Modal';
import moment from 'moment';
import SimpleDialog from 'Components/SimpleDialog';
import AddEditTimeModal from './AddEditTimeModal';
import {
  getEventTimes,
  // deleteEventTime,
} from 'actions/booking';
import { connect } from 'react-redux';

const styles = theme => {
  return {
    sectionHeader: {
      paddingLeft: '16px !important', // important needed here
      marginTop: 20,
    },
    general: {
      display: 'flex',
      width: '50%',
      marginBottom: 20,
    },
    times: {
      display: 'flex',
      flexDirection: 'column',
      height: '250px',
    },
    oneFourthWidth: {
      width: '25%',
    },
    deleteIcon: {
      color: theme.common.softPink,
      marginLeft: 8,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    editIcon: {
      color: theme.palette.grey[700],
      '&:hover': {
        cursor: 'pointer',
      },
    },
    grid: {
      '& .k-grid': {
        fontSize: 15,
        color: theme.palette.primary.main,
        border: 'none',
        '& .k-grid-container': {
          width: '100%',
          borderBottom: `1px solid ${theme.palette.grey[100]}`,
        },
        '& tbody tr': {
          height: 30,
        },
        '& tbody tr:hover': {
          backgroundColor: 'rgb(248,248,248) !important', // important needed here
        },
        '& .k-grid-norecords td': {
          borderRight: 'none',
        },
        '& td': {
          borderWidth: '0 1px 1px 0px',
          borderColor: theme.palette.grey[100],
          paddingTop: '0.1em',
          paddingBottom: '0.1em',
          paddingLeft: 16,
          paddingRight: 16,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        '& .k-grid-header': {
          color: theme.palette.grey[600],
          '& th': {
            borderLeftWidth: 0,
            borderColor: theme.palette.grey[100],
          },
          backgroundColor: theme.palette.grey[50] + '!important', // important needed here
          '& .k-link': {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
        '& .k-grid-content .k-alt': {
          backgroundColor: 'rgba(0,112,210,0.05)',
        },
        '& .k-icon': {
          color: theme.palette.grey[600],
        },
        '& .k-sorted': {
          backgroundColor: 'rgba(0,112,210,0.05)',
        },
      },
      '& .k-widget ::selection': {
        background: theme.palette.action.inputHighlight,
        textShadow: 'none',
        color: theme.palette.common.black,
      },
    },
    bottom: {
      paddingLeft: '16px',
      height: '30px',
      lineHeight: '30px',
      verticalAlign: 'middle',
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    new: {
      fontSize: 15,
      color: theme.palette.primary.main,
      display: 'inline-block',
      cursor: 'pointer',
    },
  };
};

// Dynamic column widths inspired by:
// https://www.telerik.com/kendo-react-ui/components/grid/columns/width/#toc-setting-a-minimum-column-width
const ADJUST_PADDING = 24;
const COLUMN_MIN = 35;
const EXPANDO_COL_WIDTH = 33;
const DELETE_COL_WIDTH = 36;

const columns = [
  { field: "title", title: "Additional Time", isVisible: true },
  { field: "startTimeFormatted", title: "Start Time", isVisible: true },
  { field: "endTimeFormatted", title: "End Time", isVisible: true },
  { field: "note", title: "Notes", isVisible: true },
];

const htmlDescription = ({ dataItem }) => (
  <td>
    <div dangerouslySetInnerHTML={{ __html: dataItem.note }} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} />
  </td>
);

const comma = ", ";
const and = " and ";

class BookingTimeDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventTimes: [],
      gridCurrent: 0, // do I need this
      sort: [],
      isAddEditModalOpen: false,
      hasErrors: {},
    };

    this.validationBanner = React.createRef();
  }

  errorModal = React.createRef();

  componentDidMount() {
    this.getTimes();
  }

  getId = () => {
    const { bookingEvent } = this.props;

    return bookingEvent.id;
  }

  getTimes = () => {
    const { bookingId, bookingEvent, getEventTimes } = this.props;
    const { sort:
      [
        sortData,
      ],
    } = this.state;

    bookingEvent && getEventTimes && getEventTimes(bookingId, this.getId(), sortData).then(times => {
      this.setState({ eventTimes: this.formatTimes(times) });
    });
  }

  formatTimes = times => {
    times.forEach(t => {
      t.startTimeFormatted = moment(t.startTime).format('hh:mm A').toString();
      t.endTimeFormatted = t.endTime ? moment(t.endTime).format('hh:mm A').toString() : undefined;
    });

    return times;
  }

  componentDidUpdate(prevProps) {
    const { isEditing } = this.props;

    if (isEditing && !prevProps.isEditing) {
      this.setupGridWidth();
    }
  }

  closePickerModal = () => {
    this.setState({ isAddEditModalOpen: false });
    this.getTimes();
  }

  setToEditMode = () => {
    const { bookingEvent, onLaunchEditMode } = this.props;

    if (bookingEvent.isSaved === true) {
      onLaunchEditMode();
    }
  }

  onOkayClicked = () => {
    const errors = this.checkErrors();

    if (!errors.length) {
      this.props.onClose();

      return;
    }

    let hasErrors = {};

    errors.forEach(error => {
      hasErrors[error.field] = true;
    });
    this.setState({ hasErrors });

    if (errors.length === 1) {
      this.errorModal.current.open(errors[0].error);

      return;
    }

    this.errorModal.current.open('Please fix the errors before continuing.');
  }

  onTimeDeleted = time => {
    const { bookingId, deleteEventTime } = this.props;
    const { bookingEvent } = this.props;

    deleteEventTime && deleteEventTime(bookingId, this.getId(), bookingEvent.isSaved, time.id)
      .then(this.getTimes);
  }

  onTimeEdited = dataItem => {
    this.setToEditMode();
    this.setState({ timeEdit: dataItem, isAddEditModalOpen: true });
  }

  onAddNewClicked = () => {
    const timeEdit = {
      id: undefined,
      title: '',
      startTime: moment(this.props.bookingEvent.startDate).startOf('day').toDate(),
      endTime: moment(this.props.bookingEvent.startDate).startOf('day').toDate(),
      note: '',
    };

    this.setToEditMode();
    this.setState({ timeEdit, isAddEditModalOpen: true });
  }

  shouldCallApi = sortEvt => {
    if (sortEvt && sortEvt.length === 0) {
      return true;
    }

    const sortField = sortEvt[0] && sortEvt[0].field;

    if (sortField === undefined || sortField === 'startTimeFormatted' || sortField === 'endTimeFormatted') {
      return true;
    }

    return false;
  }

  onSortChange = sortEvt => {
    this.setState(
      { sort: sortEvt.sort }, () => {

        if (this.shouldCallApi(sortEvt.sort)) {
          this.getTimes();
        }
      });
  }

  onItemChange = event => {
    const inEditId = event.dataItem.id;
    const eventTimes = this.state.eventTimes.map(item =>
      item.id === inEditId ? { ...item, [event.field]: event.value } : item
    );

    this.setState({ eventTimes });
  };

  getColumn(column) {
    if (column.isVisible) {
      switch (column.field) {
        case 'title':
          return <GridColumn key={column.field} field={column.field} title="Additional Time" width={this.setWidth(200)} />;
        case 'startTimeFormatted':
          return <GridColumn key={column.field} field={column.field} title="Start Time" width={this.setWidth(150)} />;
        case 'endTimeFormatted':
          return <GridColumn key={column.field} field={column.field} title="End Time" width={this.setWidth(150)} />;
        case 'note':
          return <GridColumn key={column.field} field={column.field} title="Notes" width={this.setWidth(330)} format="{0:C}" cell={htmlDescription} />;
        default:
          return <GridColumn key={column.field} field={column.field} title={column.title} />;
      }
    }
  }

  // Needed for dynamic column resizing and this.setWidth()
  setupGridWidth = () => {
    const { isEditing } = this.props;
    const unhideableWidth = EXPANDO_COL_WIDTH + (isEditing ? DELETE_COL_WIDTH : 0);
    const grid = document.querySelector('.k-grid');

    this.setState({ gridCurrent: grid.offsetWidth - unhideableWidth });
  }

  setWidth = (minWidth = COLUMN_MIN) => {
    const { gridCurrent } = this.state;
    const visibleColumns = columns.filter(c => c.isVisible);

    let width = minWidth + gridCurrent / visibleColumns.length;

    width = width < COLUMN_MIN ? width : width - ADJUST_PADDING;

    return width;
  }

  updateGridItem = updatedItem => {
    const { eventTimes } = this.state;

    const timeIndex = eventTimes && eventTimes.findIndex(t => t.id === updatedItem.id);

    if (timeIndex >= 0) {
      eventTimes[timeIndex] = updatedItem;
      this.setState({ eventTimes });
    }
  }

  addError(errors, newError) {
    if (errors === undefined || errors === null || errors.length === 0) {
      return newError;
    }
    if (errors.indexOf(newError) > -1) {
      return errors;
    }

    return errors + comma + newError;
  }

  formatErrorWithAnd(errors) {
    const lastComma = errors.lastIndexOf(comma);

    if (lastComma >= 0) {
      const endOfLastComma = lastComma + comma.length;
      let newErrors = errors.substring(0, lastComma) + and + errors.substring(endOfLastComma);

      return newErrors;
    } else {
      return errors;
    }
  }

  checkErrors = () => {
    const errors = [];

    const { bookingEvent } = this.props;

    if (!bookingEvent.startTime) {
      errors.push({ field: 'startTime', error: 'A valid Start Time is required!' });
    }
    if (!bookingEvent.endTime) {
      errors.push({ field: 'endTime', error: 'A valid End Time is required!' });
    }

    return errors;
  }

  isFieldProvided(field) {
    if (field === undefined || field == null || field.trim().length < 1) {
      return false;
    }

    return true;
  }

  render() {
    const {
      eventTimes,
      sort,
      timeEdit,
      isAddEditModalOpen,
      hasErrors,
    } = this.state;

    const {
      classes,
      bookingEvent,
      onClose,
      bookingId,
      onFieldChange,
    } = this.props;

    let data = eventTimes;

    if (!this.shouldCallApi(sort)) {
      data = orderBy(data, sort);
    }

    return (
      <Modal
        isOpened={true}
        onCancel={onClose}
        showCancel={false}
        title="Time Details"
        onSave={this.onOkayClicked}
        saveText="Okay"
        addTitleBottomBorder={true}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '750px' }}
      >
        <SectionHeader className={classes.sectionHeader}>General Time Details</SectionHeader>
        <div className={classes.general}>
          <CoverTimePicker
            name="startTime"
            label="Start Time"
            value={bookingEvent.startTime}
            onFieldChange={onFieldChange('startTime')}
            className={classes.oneFourthWidth}
            error={hasErrors.startTime}
          />
          <CoverTimePicker
            name="endTime"
            label="End Time"
            value={bookingEvent.endTime}
            onFieldChange={onFieldChange('endTime')}
            className={classes.oneFourthWidth}
            error={hasErrors.endTime}
          />
        </div>
        <div className={classes.times}>
          {columns.length > 0 &&
          <div className={classes.grid}>
            <Grid
              data={data}
              style={{ maxHeight: '188px' }}
              reorderable={true}
              sortable={true}
              sort={sort}
              onSortChange={this.onSortChange}
              onItemChange={this.onItemChange}
            >
              {columns.map(column => this.getColumn(column))}
              <GridColumn
                field=""
                title=""
                sortable={false}
                cell={props => {
                  return (
                    <td>
                      <EditIcon classes={{ root: classes.editIcon }} onClick={() => this.onTimeEdited(props.dataItem)} />
                      <DeleteIcon classes={{ root: classes.deleteIcon }} onClick={() => this.onTimeDeleted(props.dataItem)} />
                    </td>
                  );
                }}
              />
            </Grid>
          </div>}
          <div className={classes.bottom}>
            <div className={classes.new} onClick={this.onAddNewClicked}>+ Add New</div>
          </div>
          {isAddEditModalOpen && <AddEditTimeModal
            bookingId={bookingId}
            bookingEvent={bookingEvent}
            onCancel={this.closePickerModal}
            time={timeEdit}
          />}

        </div>
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.errorModal}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  getEventTimes,
  // deleteEventTime,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(BookingTimeDetailsModal));
