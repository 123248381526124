import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import SimpleDialog from 'Components/SimpleDialog';
import moment from 'moment';
import { Clear as DeleteIcon } from '@material-ui/icons';

const styles = theme => ({
  deleteIcon: {
    color: theme.common.softPink,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
});

class Payment extends Component {

  confirmCancelPaymentDialog = React.createRef();

  onRefund = () => {
    const { payment, onRefund } = this.props;

    this.confirmCancelPaymentDialog.current.open()
      .then(() => {
        onRefund && onRefund(payment.id);
      });
  }

  render() {
    const { className, classes, payment, formatter } = this.props;
    const dateAppliedFormatted = moment(payment.dateApplied).format('MM/DD/YYYY');

    if (payment.totalPaid === 0) {
      return null;
    }

    return (
      <>
        <tr className={className}>
          <td>{dateAppliedFormatted}</td>
          <td style={{ textAlign: 'right', paddingRight: 6 }}>
            {payment.totalAmount
              ?
              formatter.formatNumber(payment.totalAmount, 'c')
              :
              <Tooltip
                title={
                  <div>
                    <div>Paid: {formatter.formatNumber(payment.totalPaid, 'c')}</div>
                    <div>Refund: {formatter.formatNumber(payment.totalRefund, 'c')}</div>
                  </div>
                }
              >
                <span>{formatter.formatNumber(payment.totalRefund * -1, 'c')}</span>
              </Tooltip>
            }
          </td>
          <td style={{ textAlign: 'right', paddingRight: 6 }}>
            {formatter.formatNumber(payment.totalTip, 'c')}
          </td>
          <td>
            {payment.maskedCardNumber && payment.cardIssuer
              ?
              <Tooltip
                title={
                  <div>
                    <div>{payment.cardIssuer}</div>
                    {payment.totalRefund
                      ? <>
                        <div>Paid: {formatter.formatNumber(payment.totalPaid, 'c')}</div>
                        <div>Refund: {formatter.formatNumber(payment.totalRefund, 'c')}</div>
                      </>
                      :
                      <div>Amount: {formatter.formatNumber(payment.totalAmount, 'c')}</div>
                    }
                    <div>Tip: {formatter.formatNumber(payment.totalTip ? payment.totalTip : 0, 'c')}</div>
                  </div>
                }
              >
                <span>{`${payment.cardIssuer} - ${payment.maskedCardNumber.slice(-4)}`}</span>
              </Tooltip>
              :
              payment.paymentMethod
            }
          </td>
          <td>{payment.receivedBy}</td>
          <td>
            <div style={{ textAlign: 'center' }}>
              <Tooltip className={!payment.totalAmount ? classes.hidden : ''} title={`Make a refund of ${formatter.formatNumber(payment.totalAmount, 'c')}`}>
                <DeleteIcon
                  aria-label="Refund"
                  className={classes.deleteIcon}
                  onClick={this.onRefund}
                />
              </Tooltip>
            </div>
          </td>
        </tr>
        <SimpleDialog
          message="Are you sure you want to refund this payment?"
          innerRef={this.confirmCancelPaymentDialog}
        />
      </>
    );
  }
}

export default withStyles(styles)(Payment);
