import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Navs from './Navs';
import Home from 'dashboard';
import Calendar from 'calendar';
import ChitChat from 'chitchat';
import Contacts from 'contacts';
import Accounts from 'accounts';
import Bookings from 'bookings';
import Admin from './admin';
import Leads from 'leads';
import Orders from 'orders';
import Reports from 'reports';
import DebugColor from 'shared/DebugColor';
import { withStyles } from '@material-ui/core/styles';
import { setCurrentLocation } from 'actions/location';
import { userIsInRole, userLocations, subscriptionOwnerRole, adminRole } from 'helpers/userHelpers';

const styles = {
  noAccess: {
    padding: 60,
    textAlign: 'center',
  },
};

class Portal extends Component {
  state = {
    noAccessToAnyLocations: null,
  };

  render() {

    const {
      currentLocation,
      setCurrentLocation,
      classes,
      user,
    } = this.props;

    const locations = userLocations(user);

    return (
      <Navs
        currentLocation={currentLocation}
        canSwitchLocations={true}
        setCurrentLocation={setCurrentLocation}
        hasAccessToAdmin={userIsInRole(user, adminRole) || userIsInRole(user, subscriptionOwnerRole)}
      >
        {!locations ?
          <div className={classes.noAccess}>
            You do not have access to any locations. Please check your account and re-login.
          </div> :
          <>
            <Route exact path='/' component={Home} />
            <Route path='/home' component={Home} />
            <Route path='/calendar' component={Calendar} />
            <Route path='/chitchat' component={ChitChat} />
            <Route path='/contacts' component={Contacts} />
            <Route path='/accounts' component={Accounts} />
            <Route path='/leads' component={Leads} />
            <Route path='/bookings' component={Bookings} />
            <Route path='/orders' component={Orders} />
            <Route path='/reports' component={Reports} />
            <Route path='/admin' component={Admin} />
            <Route path='/colors' component={DebugColor} />
          </>}
      </Navs>
    );
  }
}

const mapStateToProps = state => {
  const { api: { currentLocation },
    oidc: { user } } = state;

  return { currentLocation, user };
};

const mapDispatchToProps = {
  setCurrentLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Portal));
