import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import EventDetailsTab from './EventDetailsTab';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import MenuTab from './MenuTab';
import SimpleDialog from 'Components/SimpleDialog';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddEventSectionModal from './AddEventSectionModal';
import {
  getEventDetails,
  updateStoreFocusedEvent,
  addEventSection,
  deleteEventSection,
  storeBookingSetIsEditing,
} from 'actions/booking';
import { connect } from 'react-redux';
import moment from 'moment';

const styles = theme => ({
  eventDetailsTabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    minWidth: 0,
  },
  tabContainer: {
    height: 40,
    minHeight: 40,
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 0,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  sectionButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  iconButton: {
    color: theme.palette.common.black,
  },
});
const tabs = ['Details', 'Menu'];

class EventTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      isSectionsModalOpen: false,
      localEvent: props.focusedEvent,
    };
    this.venueRoomUnavailable = React.createRef();
    this.confirmDeleteSectionDialog = React.createRef();
    this.noSectionsAvailableDialog = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focusedEvent !== this.props.focusedEvent) {
      this.setState({ localEvent: this.props.focusedEvent });
    }
  }

  onFieldChange = fieldName => value => {
    const localEvent = {
      ...this.state.localEvent,
      [fieldName]: value,
    };

    this.updateEventThrottled(localEvent);
  }

  handleTabChange = tabIndex => {
    this.setState({ tabIndex });
  };

  isFieldChanged = fieldName => {
    const { eventRef, localEvent } = this.state;

    if (!eventRef) {
      return false;
    }
    if (localEvent.isSaved) {
      return false;
    }

    const originalField = eventRef[fieldName];
    const updatedField = localEvent[fieldName];

    if (originalField === updatedField) {
      return false;
    }

    //console.log(`${fieldName} ${originalField} => ${updatedField}`); //for debugging

    if (moment.isDate(originalField) && moment.isDate(updatedField)) {
      return originalField.getTime() !== updatedField.getTime();
    }

    return true;
  }

  updateEventThrottled = localEvent => {
    this.setState({ localEvent });

    if (this.updateStore) {
      clearTimeout(this.updateStore);
    }

    this.updateStore = setTimeout(() => {
      this.props.updateStoreFocusedEvent(localEvent, 'manager');
      // .then(updatedEvent => {
      // if (updatedEvent.roomsAreUnavailable) {
      //   this.venueRoomUnavailable.current.open();
      // }
      // });
    }, 500);
  }

  openSectionsModal = () => {
    this.setState({ isSectionsModalOpen: true });
  }

  closeSectionsModal = () => {
    this.setState({ isSectionsModalOpen: false });
  }

  addEventSection = section => {
    this.props.addEventSection(section);
    this.closeSectionsModal();
  }

  deleteEventSection = section => {
    this.confirmDeleteSectionDialog.current.open().then(() => {
      this.props.deleteEventSection(section);
    });
  }

  render() {
    const {
      classes,
      isSelectPackagesModalOpen,
      storeBookingSetIsEditing,
    } = this.props;

    const {
      tabIndex,
      localEvent,
      isSectionsModalOpen,
    } = this.state;

    if (!localEvent) {
      return null;
    }

    return (
      <div className={classes.eventDetailsTabsRoot} onDoubleClick={() => storeBookingSetIsEditing(true)}>
        <div className={classes.tabContainer}>
          <BlueTextTab
            onTabChange={this.handleTabChange}
            tabs={tabs}
            tabIndex={tabIndex}
          />
          {tabIndex === 0 && <div className={classes.sectionButtonContainer}>
            <IconButton className={classes.iconButton} onClick={this.openSectionsModal}>
              <AddIcon />
            </IconButton>
          </div>}
        </div>
        <div className={classes.content}>
          {tabIndex === 0 &&
            <EventDetailsTab
              event={localEvent}
              isFieldChanged={this.isFieldChanged}
              onFieldChange={this.onFieldChange}
              onDeleteEventSection={this.deleteEventSection}
            />
          }
          {tabIndex === 1 &&
            <MenuTab
              focusedEvent={localEvent}
              isSelectPackagesModalOpen={isSelectPackagesModalOpen}
            />
          }
        </div>

        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.venueRoomUnavailable}
          message="Your room is unavailable at that time!"
        />

        <SimpleDialog
          message="Are you sure you want to delete this section?"
          innerRef={this.confirmDeleteSectionDialog}
        />

        <SimpleDialog
          message="This event already has all available sections."
          innerRef={this.noSectionsAvailableDialog}
          onlyOkayButton={true}
        />

        <AddEventSectionModal
          isOpen={isSectionsModalOpen}
          onCancel={this.closeSectionsModal}
          onSave={this.addEventSection}
          event={localEvent}
        />

      </div >
    );
  }
}

const mapStateToProps = state => {
  const {
    booking: {
      manager: {
        storeBooking,
        focusedEventIndex,
        focusedEvent,
      },
    },
  } = state;

  return {
    storeBooking,
    focusedEventIndex,
    focusedEvent,
  };
};

const mapDispatchToProps = {
  getEventDetails,
  updateStoreFocusedEvent,
  addEventSection,
  deleteEventSection,
  storeBookingSetIsEditing,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventTabs));
