import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SectionHeader from 'Components/SectionHeader';
import { CurrencyField } from 'Components/currencyInput';
import TextField from 'Components/TextField';
import CoverTimePicker from 'Components/CoverTimePicker';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RichTextField from 'Components/RichTextField';
import SimpleDialog from 'Components/SimpleDialog';
import {
  addShiftToFocusedEvent,
  updateShiftOnFocusedEvent,
} from 'actions/booking';
import { connect } from 'react-redux';

const styles = () => ({

  formSection: {
    flexGrow: 1,
    padding: '16px 33px 50px 33px',
  },
  topSectionContainer: {
    padding: '0 24px 20px 24px',
  },
  halfWidth: {
    width: '50%',
  },
  quarterWidth: {
    width: '23%', //leave this 23%. This aligns the top row better over the bottom row than 25%
  },
  fieldsRowContainer: {
    display: 'flex',
  },
});

const timeInMustBeBeforeTimeOutError = 'Time In must be before Time Out!';

export class ShiftPickerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shift: { ...props.shift },
      hasErrors: {},
    };
    this.froalaConfig = {
      zIndex: props.theme.zIndex.modal + 1,
    };
  }

  errorModal = React.createRef();

  componentDidMount() {
    const { shift } = this.props;

    this.setState({ shift });
  }

  componentDidUpdate(prevProps) {
    if (this.props.shift !== prevProps.shift) {
      this.setState({ shift: { ...this.props.shift } });
    }
  }

  save = () => {
    const { shift } = this.state;
    const { shiftTypes, existingShiftIndex } = this.props;

    if (!shift.name && shift.shiftTypeId && shiftTypes) {
      const shiftType = shiftTypes.find(shiftType => shiftType.id === shift.shiftTypeId);

      shift.name = shiftType.name || '';
    }

    if (!!existingShiftIndex || existingShiftIndex === 0) {
      console.log('edit shift here');
      this.props.updateShiftOnFocusedEvent(existingShiftIndex, shift).then(this.props.onModalClosed);
    } else {
      this.props.addShiftToFocusedEvent(shift).then(this.props.onModalClosed);
    }
  }

  onFieldChange = (fieldName, parser) => value => {
    const { shiftTypes } = this.props;
    let updatedShift = { ...this.state.shift };

    if (!value && (fieldName === 'timeIn' || fieldName === 'timeOut')) {
      //if we let the user blank the time field, when they fill it back in it will be today's date.
      //which makes checking if time in before time out hard. So just avoid the issue completely
      this.errorModal.current.open('Time field is required');

      return;
    }

    if (fieldName === 'shiftTypeId') {
      const shiftType = shiftTypes.find(shiftType => shiftType.id === value);

      updatedShift.name = shiftType.name || '';
    }

    updatedShift = {
      ...updatedShift,
      [fieldName]: parser ? parser(value) : value,
    };

    this.setState({ shift: updatedShift });
  }

  onCancel = () => {
    this.props.onModalClosed(); //Can't swap out for onCancel={this.props.onModalClosed} because it passes an event as arguments
  }

  onSave = () => {
    const errors = this.checkErrors();

    if (!errors.length) {
      this.save();

      return;
    }
    let hasErrors = {};

    errors.forEach(error => {
      hasErrors[error.field] = true;
    });
    this.setState({ hasErrors });

    if (errors.length === 1) {
      this.errorModal.current.open(errors[0].error);

      return;
    }

    if (errors.length === 2 && errors[0].error === timeInMustBeBeforeTimeOutError) {
      this.errorModal.current.open(errors[0].error);
    } else {
      this.errorModal.current.open('Please fix the errors before continuing.');
    }
  }

  checkErrors = () => {
    const errors = [];

    const {
      shift: {
        numberOfEmployees,
        rate,
        timeIn,
        timeOut,
        shiftTypeId,
        name,
      },
    } = this.state;

    if (!numberOfEmployees) {
      errors.push({ field: 'numberOfEmployees', error: '# Needed is required!' });
    }
    if (!rate) {
      errors.push({ field: 'rate', error: 'Rate is required!' });
    }
    if (!timeIn) {
      errors.push({ field: 'timeIn', error: 'Time In is required!' });
    }
    if (!timeOut) {
      errors.push({ field: 'timeOut', error: 'Time Out is required!' });
    }
    if (timeIn && timeOut && timeIn >= timeOut) {
      errors.push({ field: 'timeIn', error: timeInMustBeBeforeTimeOutError });
      errors.push({ field: 'timeOut', error: 'Time Out must be after Time In!' });
    }
    if (!shiftTypeId && !name) {
      errors.push({ field: 'name', error: 'Shift name is required!' });
    }

    return errors;
  }

  shiftTypeMenuItem = item => (
    <MenuItem value={item.id}>{item.name}</MenuItem>
  )

  render() {
    const {
      classes,
      storeDefaultShiftTypes,
    } = this.props;
    const {
      shift: {
        numberOfEmployees,
        rate,
        timeIn,
        timeOut,
        notes,
        name,
        shiftTypeId,
      },
      hasErrors,
    } = this.state;

    return (
      <Modal
        isOpened={true}
        onCancel={this.onCancel}
        onSave={this.onSave}
        title={"Shift Picker"}
        addTitleBottomBorder={true}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '775px' }}
      >
        <SectionHeader>
          Shift Information
        </SectionHeader>
        <div className={classes.topSectionContainer}>
          <div className={classes.fieldsRowContainer}>
            {shiftTypeId ? <Select
              label="Shift"
              value={shiftTypeId}
              className={classes.halfWidth}
              onFieldChange={this.onFieldChange('shiftTypeId')}
            >
              {this.shiftTypeMenuItem(storeDefaultShiftTypes.server)}
              {this.shiftTypeMenuItem(storeDefaultShiftTypes.bartender)}
              {this.shiftTypeMenuItem(storeDefaultShiftTypes.busser)}
              {this.shiftTypeMenuItem(storeDefaultShiftTypes.hostess)}
              {this.shiftTypeMenuItem(storeDefaultShiftTypes.captain)}
            </Select> :
              <TextField
                label="Shift"
                error={hasErrors.name}
                value={name}
                className={classes.quarterWidth}
                onFieldChange={this.onFieldChange('name')}
              />}
            <TextField
              label="# Needed"
              error={hasErrors.numberOfEmployees}
              value={numberOfEmployees}
              className={classes.quarterWidth}
              onFieldChange={this.onFieldChange('numberOfEmployees')}
              type="number"
            />
            <CurrencyField
              label="Rate"
              error={hasErrors.rate}
              value={rate}
              className={classes.quarterWidth}
              onFieldChange={this.onFieldChange('rate', parseFloat)}
            />
          </div>
          <div className={classes.fieldsRowContainer}>
            <CoverTimePicker
              label="Time In"
              error={hasErrors.timeIn}
              value={timeIn}
              showClearButton={false}
              className={classes.halfWidth}
              onFieldChange={this.onFieldChange('timeIn')}
            />
            <CoverTimePicker
              label="Time Out"
              error={hasErrors.timeOut}
              value={timeOut}
              showClearButton={false}
              className={classes.halfWidth}
              onFieldChange={this.onFieldChange('timeOut')}
            />
          </div>
        </div>
        <SectionHeader>
          Notes
        </SectionHeader>
        <div className={classes.formSection}>
          <RichTextField
            value={notes}
            onFieldChange={this.onFieldChange('notes')}
            config={this.froalaConfig}
          />
        </div>
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.errorModal}
        />
      </Modal >);
  }
}

const mapStateToProps = state => {
  const {
    booking: {
      storeBooking,
      focusedEventIndex,
      focusedEvent,
      storeDefaultShiftTypes,
      shiftTypes,
    },
  } = state;

  return {
    storeBooking,
    focusedEventIndex,
    focusedEvent,
    storeDefaultShiftTypes,
    shiftTypes,
  };
};

const mapDispatchToProps = {
  addShiftToFocusedEvent,
  updateShiftOnFocusedEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ShiftPickerModal));
