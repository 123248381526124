import React, { Component } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import {
  Modal,
  IconButton,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getCardPaymentUrl } from 'actions/orderPayment';

const styles = () => ({
  paymentModal: {
    height: '50%',
    width: '50%',
    margin: 'auto',
    maxWidth: 610,
  },
  paymentContainer: {
    backgroundColor: '#fff',
    height: '100%',
  },
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 1,
    right: 5,
    top: 5,
    padding: 0,
    color: '#177db8',
  },
  toOrderSection: {
    position: 'absolute',
    bottom: 35,
    left: 0,
    width: '100%',
    display: 'flex',
  },
  toOrderColumn: {
    width: '50%',
  },
  toOrderButton: {
    margin: 'auto',
    display: 'block',
    textTransform: 'initial',
  },
});

class PaymentGatewayModal extends Component {
  state = {
    cardPaymentUrl: '',
    paymentHeight: 513,
  };

  setHeight = height => {
    this.setState({ paymentHeight: height });
  }

  handleMessage = event => {
    if (!event.data || !event.data.id) {
      return;
    }

    switch (event.data.id) {
      case 'hpp-done':
        this.props.paymentSuccess();
        break;
      case 'set-height':
        this.setHeight(event.data.height);
        break;
      default:
        break;
    }
  }

  initPayment = () => {
    const { getCardPaymentUrl, storeOrder, amount } = this.props;

    getCardPaymentUrl(storeOrder.id, amount)
      .then(cardPaymentUrl => {
        this.setState({ cardPaymentUrl });
      });
  }

  closePayment = () => {
    const { closePayment } = this.props;

    this.setState({ cardPaymentUrl: '' }, closePayment);
  }

  componentDidMount() {
    window.addEventListener('message', this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initPayment();
    }
  }

  render() {
    const { classes, isOpen, showToOrderButton } = this.props;
    const { paymentHeight, cardPaymentUrl } = this.state;

    return (
      <Modal
        className={classes.paymentModal}
        open={isOpen}
        style={{ height: paymentHeight }}
      >
        <div className={classes.paymentContainer}>
          <IconButton className={classes.closeButton} onClick={this.closePayment}>
            <CloseIcon />
          </IconButton>
          <div className={classes.toOrderSection} style={{ display: showToOrderButton ? 'flex' : 'none' }}>
            <div className={classes.toOrderColumn}></div>
            <div className={classes.toOrderColumn}>
              <Button variant="contained" color="secondary" className={classes.toOrderButton} onClick={this.closePayment}>
                Proceed to Order
              </Button>
            </div>
          </div>
          {cardPaymentUrl &&
          <iframe
            id="payment-form"
            title="Payment form"
            style={{ width: '100%', height: '100%', backgroundColor: '#fff', border: 'none' }}
            src={cardPaymentUrl}>
          </iframe>
          }
        </div>
      </Modal>
    );
  }
}

PaymentGatewayModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closePayment: PropTypes.func.isRequired,
  paymentSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  storeOrder: state.storeReducer.storeOrder,
});

const mapDispatchToProps = {
  getCardPaymentUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentGatewayModal));
