import React, { Component } from 'react';
import { getGroupedPickLists } from 'actions/pickList';
import { connect } from 'react-redux';
import PickBranch from 'Components/PickBranch';

class PickList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedNodes: {},
    };
  }

  componentDidMount() {
    this.props.getGroupedPickLists();
  }

  onToggleExpand = category => () => {
    const newExpanded = !this.state.expandedNodes[category.name];
    const expandedNodes = {
      ...this.state.expandedNodes,
      [category.name]: newExpanded,
    };

    this.setState({ expandedNodes });
  }

  render() {
    const { expandedNodes } = this.state;
    const { selectedListId, onPickListPicked, picklists } = this.props;

    if (!picklists) {
      return null;
    }

    console.log(picklists);

    return picklists.map(category => (
      <PickBranch
        key={category.name}
        expanded={expandedNodes[category.name]}
        onToggleExpand={this.onToggleExpand(category)}
        onPickListPicked={onPickListPicked}
        pickLists={category.items}
        selectedListId={selectedListId}
      >
        {category.name}
      </PickBranch>
    ));
  }
}

const mapDispatchToProps = {
  getGroupedPickLists,
};

const mapStateToProps = state =>  {
  return {
    picklists: state.pickList.picklistGroups,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PickList);
