import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Route, withRouter } from 'react-router-dom';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import Header from 'Components/Header';
import Breadcrumb from 'Components/Breadcrumb';

export class CompanyHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {},
    };
  }

  getTranslations() {
    if (!this.translations) {
      const localizationService = provideLocalizationService(this);

      this.translations = {
        company: localizationService.toLanguageString('company.label'),
        location: localizationService.toLanguageString('location.labelPlural'),
      };
    }

    return this.translations;
  }

  render() {
    const { company, franchise, locationDetails } = this.props;
    const translations = this.getTranslations();

    return (
      <>
        <Header> {translations.company} </Header>
        <Breadcrumb>
          <Link disabled to="/admin/company">{translations.company}</Link>
          <Link to="/admin/company">{ company.name }</Link>
          <Route path="/admin/company/locations" render={() => (
            <Link to="/admin/company/locations"> {translations.location} </Link>
          )} />
          <Route path="/admin/company/franchise" render={() => (
            <Link to="/admin/company/franchise"> {franchise.name} </Link>
          )} />
          <Route path="/admin/company/locations/:id" render={({ match }) => (
            <Link to={'/admin/company/locations/' + match.params.id}>{ locationDetails.name }</Link>
          )} />
        </Breadcrumb>
      </>
    );
  }
}

const mapStateToProps = state =>
{
  return {
    company: state.admin.company || {},
    franchise: { name: 'Greater Denver Franchise' },
    locationDetails: state.admin.locationDetails || {},//don't use location because the router uses this.props.location
  };
};

registerForLocalization(CompanyHeader);
export default withRouter(connect(mapStateToProps)(CompanyHeader));
