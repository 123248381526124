import { callApi } from 'shared/CallApi';

export const getCoupons = () => async dispatch =>
  dispatch(callApi('coupon'))
    .then(results => results.json());

export const getCoupon = couponId => async dispatch =>
  dispatch(callApi(`coupon/${couponId}`))
    .then(results => results.json())
    .catch(console.error);

export const updateCoupon = coupon => async dispatch =>
  dispatch(callApi(`coupon/${coupon.id}`, { body: coupon, method: 'PUT'  }))
    .then(results => results.json())
    .catch(console.error);

export const createCoupon = coupon => async dispatch =>
  dispatch(callApi(`coupon`, { body: coupon, method: 'POST'  }))
    .then(results => results.json())
    .catch(console.error);

export const deleteCoupon = coupon => async dispatch =>
  dispatch(callApi(`coupon/${coupon.id}`, { body: coupon, method: 'DELETE'  }))
    .then(results => results.json())
    .catch(console.error);
