import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import classNames from 'classnames';

const styles = theme => ({
  coverDateTime: {
    '& input.e-control.e-datetimepicker': {
      color: theme.palette.grey[300],
      paddingBottom: 2,
      fontSize: 16,
      '&.e-disabled': {
        cursor: 'default',
      },
      '&::selection': {
        background: theme.palette.action.inputHighlight,
        textShadow: 'none',
        color: theme.palette.grey[900],
      },
    },
    '&.e-float-input': {
      marginBottom: 0,
      '& input:valid ~ label.e-float-text': {
        top: -11,
      },
    },
    '& label.e-float-text': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    '& label.e-label-bottom': {
      fontSize: `16px !important`,
    },
    '& td.e-selected span.e-day': {
      background: `${theme.palette.primary.dark} !important`, //currently selected day
    },
    '& .e-btn': {
      color: `${theme.palette.primary.dark} !important`, //today button
    },
  },
  coverDateTimeDisabled: {
    '& span.e-input-group-icon.e-icons, input.e-control': {
      pointerEvents: 'none',
      opacity: '0.5',
    },
  },
  noErrors: {
    '& label.e-float-text': {
      color: `${theme.palette.grey[200]} !important`,
    },
    '& .e-input-focus label.e-float-text': {
      color: theme.palette.primary.dark + '!important',
    },
    '& .e-float-line::before, & .e-float-line::after': {
      background: `${theme.palette.primary.dark} !important`,
    },
    '& .e-icons': {
      color: `${theme.palette.grey[300]} !important`, //clickable calendar and clear icon
    },
    '& .e-active': {
      color: theme.palette.primary.dark + '!important',
    },
  },
  errors: {
    '& $coverDateTime': {
      borderBottomColor: `${theme.palette.error.main} !important`,
    },
    '& label.e-float-text': {
      color: `${theme.palette.error.main} !important`,
    },
    '& .e-float-line::before, & .e-float-line::after': {
      background: `${theme.palette.error.main} !important`,
    },
    '& .e-date-icon': {
      color: `${theme.palette.error.main} !important`,
    },
    '& .e-time-icon': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  pickContainer: {
    flexGrow: 1,
  },

});

class CoverDateTimePicker extends Component {
  render() {
    const { onChange, value, label, name, disabled, classes, minDate, maxDate, error, className, ...rest } = this.props;

    return (
      <div
        disabled={disabled}
        className={classNames(
          classes.pickContainer,
          className,
          error ? classes.errors : classes.noErrors,
          { [classes.coverDateTimeDisabled]: disabled }
        )}
      >
        <DateTimePickerComponent
          id={name}
          value={value || ''}
          change={onChange}
          min={minDate}
          max={maxDate}
          strictMode="true"
          cssClass={classes.coverDateTime}
          floatLabelType="Auto"
          placeholder={label}
          {...rest}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CoverDateTimePicker);
