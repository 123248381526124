import React, { Component } from 'react';
import { Paper, withStyles, Table, TableBody } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Task from 'dashboard/main/Task';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minWidth: '300px',
    marginRight: 24,
  },
  heading: {
    padding: 24,
    fontSize: '18px',
    fontWeight: '500',
    borderBottom: `1px solid ${theme.palette.grey['50']}`,
  },
  scrollArea: {
    width: '100%',
    overflowY: 'auto',
    height: `calc(100% - ${theme.spacing.unit * 6 + 27}px)`,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  more: {
    color: theme.palette.grey['200'],
    fontSize: '14px',
    fontWeight: 'normal',
    float: 'right',
  },
});

class TasksWidget extends Component {

  render() {
    const { classes, tasks = [] } = this.props;

    return (
      <Paper className={classes.root}>
        <h3 className={classes.heading}>Tasks<span className={classes.more}>All Tasks <ArrowRight /></span></h3>
        <div className={classes.scrollArea}>
          <Table>
            <TableBody>
              {tasks.map(task => <Task key={task.id} task={task} onRefresh={this.props.onRefresh} />)}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(TasksWidget);
