import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
  const buttonOverRides = {
    minHeight: 32,
    fontWeight: 400,
    borderRadius: 0,
    borderRight: 'none',
    '&:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '0 4px 4px 0',
    },
    '&:only-child': {
      borderRadius: 4,
    },
  };

  return {
    miniButtonWrapper: {
      marginRight: 24,
      '& button': buttonOverRides, //is there a better was to wire up styles for button AND anchors?
      '& a': buttonOverRides,
    },
  };

};

class MiniButtonSet extends Component {
  render() {
    const { classes, children, ...rest } = this.props;

    return (
      <div {...rest} className={classes.miniButtonWrapper}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(MiniButtonSet);
