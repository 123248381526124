import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';
import {
  getMenuAssignments,
  menuAssignmentsReset,
  menuAssignmentsSave,
} from 'actions/masterMenuManagement';
import SaveBar from 'Components/SaveBar';
import AssignedMenusPanel from './AssignedMenusPanel';
import { MenuType } from 'models/MenuType';

const styles = theme => ({
  menuAssignment: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
});

class MenuAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuSuggestions: [],
    };
  }

  componentDidMount() {
    this.props.getMenuAssignments();
    this.setupSuggestions(this.props.menus);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.menus !== this.props.menus) {
      this.setupSuggestions(this.props.menus);
    }
  }

  setupSuggestions = menus => {
    const { comboBoxSuggestions: menuSuggestions } = setupComboBoxSuggestions(menus);

    this.setState({ menuSuggestions });
  }

  render() {
    const {
      classes,
      menuAssignmentsSave,
      menuAssignmentsReset,
      menuAssignmentsChanged,
    } = this.props;
    const {
      menuSuggestions, //todo move to redux?
    } = this.state;

    return (<>
      <div className={classes.menuAssignment}>
        <AssignedMenusPanel
          purpose={MenuType.QuickOrder}
          title="Quick Order"
          menuSuggestions={menuSuggestions}
        />
        <AssignedMenusPanel
          purpose={MenuType.Booking}
          title="Booking"
          menuSuggestions={menuSuggestions}
        />
        <AssignedMenusPanel
          purpose={MenuType.OnlineOrder}
          title="Online Order"
          menuSuggestions={menuSuggestions}
        />
      </div>
        <div className={classes.saveBar}>
          {menuAssignmentsChanged &&
            <SaveBar
              onSave={menuAssignmentsSave}
              onCancel={menuAssignmentsReset}
              isSticky={true}
            />
          }
        </div>
    </>);
  }
}

const mapStateToProps = state => {
  const {
    masterMenuManagement: {
      masterMenus,
      isLoading,
      menuAssignments,
      menuAssignmentsChanged,
    },
  } = state;

  return {
    menus: masterMenus, // is fetched from main page of this section
    isLoading,
    menuAssignments,
    menuAssignmentsChanged,
  };
};

const mapDispatchToProps = {
  getMenuAssignments,
  menuAssignmentsReset,
  menuAssignmentsSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuAssignment));

