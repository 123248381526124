
import React, { Component } from 'react';
import { withTheme } from '@material-ui/core';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
} from '@progress/kendo-react-charts';
import { formatNumber } from './LargeBarChartWidget';

class CustomerChart extends Component {
  constructor(props) {
    super(props);

    this.yAxisLabels = {
      padding: 20,
      font: '12px "Open Sans", "Helvetica Neue", sans-serif',
      color: props.theme.palette.grey.A200,
      content: formatNumber,
    };

    this.xAxisLabels = {
      padding: 20,
      font: '12px "Open Sans", "Helvetica Neue", sans-serif',
      format: 'c0',
      color: props.theme.palette.grey.A200,
    };
  }

  render() {
    const {
      theme,
      categories,
      data,
    } = this.props;

    return (
      <>
        <div style={{ height: 0 }}>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="0" height="0" style={{ visibility: 'hidden' }}>
            <defs>
              <linearGradient id="svg-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="50%" style={{ stopColor: theme.common.blue, stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: theme.common.blue, stopOpacity: 0.05 }} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <Chart zoomable={false}>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={categories} labels={this.xAxisLabels} />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem labels={this.yAxisLabels} />
          </ChartValueAxis>
          <ChartSeries>
            <ChartSeriesItem type="area" data={data} opacity={0.1} color={"url(#svg-gradient)"} />
            <ChartSeriesItem type="line" data={data} line={{ style: 'step' }} color={theme.common.blue} opacity={1} />
          </ChartSeries>
        </Chart>
      </>
    );
  }
}

export default withTheme()(CustomerChart);
