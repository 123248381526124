export const setupComboBoxSuggestions = widgets => {
  if (widgets) {
    const comboBoxMap = {};
    let comboBoxSuggestions = [];

    comboBoxSuggestions = widgets.map(suggestion => ({
      value: suggestion,
      label: suggestion.name,
    }));

    widgets.forEach(widget => {
      comboBoxMap[widget.id] = widget.name;
    });

    return { comboBoxSuggestions, comboBoxMap };
  }
};
