import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import TextField from 'Components/TextField';
import classNames from 'classnames';

const styles = theme => ({
  coverDateTime: {
    '& input.e-control.e-datepicker': {
      color: theme.palette.grey[700],
      paddingBottom: 6,
      paddingTop: 6,
      lineHeight: '19px', //line height has to be `Xpx`
      fontSize: 16,
      '&.e-disabled': {
        cursor: 'default',
      },
      '&::selection': {
        background: theme.palette.action.inputHighlight,
        textShadow: 'none',
        color: theme.palette.common.black,
      },
    },
    '&.e-float-input': {
      marginBottom: 0,
      '& input:valid ~ label.e-float-text': {
        top: -11,
      },
    },
    '& label.e-float-text': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    '& label.e-label-bottom': {
      fontSize: `16px !important`,
    },
    '& td.e-selected span.e-day': {
      background: `${theme.palette.primary.dark} !important`, //currently selected day
    },
    '& .e-btn': {
      color: `${theme.palette.primary.dark} !important`, //today button
    },
  },
  noErrors: {
    '& label.e-float-text': {
      color: `${theme.palette.grey[200]} !important`,
    },
    '& .e-input-focus label.e-float-text': {
      color: theme.palette.primary.dark + '!important',
    },
    '& .e-float-line::before, & .e-float-line::after': {
      background: `${theme.palette.primary.dark} !important`,
    },
    '& .e-icons': {
      color: `${theme.palette.grey[300]} !important`, //clickable calendar and clear icon
    },
    '& .e-active': {
      color: theme.palette.primary.dark + '!important',
    },
  },
  errors: {
    '& $coverDateTime': {
      borderBottomColor: `${theme.palette.error.main} !important`,
    },
    '& label.e-float-text': {
      color: `${theme.palette.error.main} !important`,
    },
    '& .e-float-line::before, & .e-float-line::after': {
      background: `${theme.palette.error.main} !important`, //the
    },
    '& .e-date-icon': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  pickContainer: {
    flexGrow: 1,
    margin: '16px 16px 8px',
  },
});

class CoverDatePicker extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    const minutesToNextHalfHour = 30 - (moment(now).clone().minute() % 30);
    const nearestHalfHour = moment(now).clone().add(minutesToNextHalfHour, 'minutes').toDate();
    const tenYearsOut = moment(now).clone().add(10, 'years').toDate();

    this.state = {
      defaultMin: nearestHalfHour,
      defaultMax: tenYearsOut,
    };
    this.datePicker = React.createRef();
  }

  onChange = changedEventArgs => {
    const { onFieldChange, label, name } = this.props;

    onFieldChange ? onFieldChange(changedEventArgs.value) : console.error(`CoverDatePicker(${label}/${name}) does not have an onFieldChange event`);
  }

  render() {
    const {
      value,
      label,
      name,
      classes,
      minDate,
      maxDate,
      minWidth,
      disabled,
      error,
      className,
      ...rest
    } = this.props;
    const { defaultMin, defaultMax } = this.state;
    const style = minWidth ? { minWidth: minWidth } : undefined;
    const dateOnly = moment(value).clone().format('l') || ''; //'l' converts to 6/9/2014

    if (disabled) {
      return (
        <TextField
          disabled={true}
          label={label}
          className={className}
          error={error}
          value={dateOnly}
        />
      );
    }
    //{error ? //THE cssClass prop only appends together CSS classes. So removing the error class, just appends them together. So we'll need 2 different components :-(

    return (
      <div
        className={classNames(classes.pickContainer, className, error ? classes.errors : classes.noErrors)}
        style={style}>
        <DatePickerComponent
          {...rest}
          id={name}
          ref={this.datePicker}
          value={dateOnly}
          change={this.onChange}
          min={minDate || defaultMin}
          max={maxDate || defaultMax}
          strictMode="true"
          cssClass={classes.coverDateTime}
          floatLabelType="Auto"
          placeholder={label}
          label={label}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CoverDatePicker);
