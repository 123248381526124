import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';

const styles = theme => ({
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    '&:first-child': {
      borderRadius: '4px 0 0 0',
    },
    minHeight: 'unset',
  },
  tab: {
    flexGrow: 1,
    textTransform: 'initial',
    minWidth: 95,
    fontSize: 13,
    minHeight: 'unset',
  },
  tabSelected: {
    color: theme.palette.common.black,
  },
  labelContainer: {
    paddingTop: 8,
    paddingBottom: 8,
  },
});

class BlueUnderlineTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: props.tabIndex || 0,
    };
  }

  handleChange = (_event, tabIndex) => {
    this.setState({ tabIndex });
    this.props.onTabChange(tabIndex);
  };

  render() {
    const {
      classes,
      tabs,
    } = this.props;
    const {
      tabIndex,
    } = this.state;

    return (
      <Tabs value={tabIndex} onChange={this.handleChange} classes={{ root: classes.tabs }}>
        {tabs.map(tab =>
          <Tab
            label={tab}
            key={tab}
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              labelContainer: classes.labelContainer,
            }}
            disableRipple
          />
        )}
      </Tabs>
    );
  }
}

export default withStyles(styles)(BlueUnderlineTab);
