import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import TextField from 'Components/TextField';
import Select from 'Components/Select';
import SectionHeader from 'Components/SectionHeader';
import VenueFinder from './VenueFinder';
import {
  updateStoreFocusedEvent,
} from 'actions/booking';
import { getParentRooms, getRooms, getAllSetupStyles, getUnavailableRooms } from 'actions/rooms';
import SimpleDialog from 'Components/SimpleDialog';
import IconButton from 'Components/Buttons/IconButton';
import CustomRoomModal from './CustomRoomModal';
import _ from 'lodash';

const styles = theme => ({
  venues: {
    backgroundColor: theme.palette.background.default,
  },
  sectionHeader: {
    marginTop: 12,
    paddingRight: 32,
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.paper,
  },
  row: {
    display: 'flex',
    flexGrow: 1,
  },
  half: {
    display: 'flex',
    width: '50%',
  },
  venueFinderContainer: {
    overflow: 'auto',
    height: 291,
  },
  iconButton: {
    padding: 0,
    color: theme.palette.grey[800],
  },
  setupStyleSelect: {
    width: '200px',
  },
});

class VenuesSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedEvent: { ...props.focusedEvent },
    };
    this.notAvailableWarning = React.createRef();
  }

  setupEvent = () => {
    const {
      focusedEvent,
      setupStyles,
    } = this.props;

    if (!focusedEvent.venueSetupStyleId && setupStyles && setupStyles[0]) {
      focusedEvent.venueSetupStyleId = setupStyles[0].id;
    }

    this.props.getUnavailableRooms(focusedEvent);

    this.setState({
      focusedEvent,
    });
  }

  componentDidMount() {
    this.props.getAllSetupStyles();
    this.props.getRooms();

    this.setupEvent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focusedEvent !== this.props.focusedEvent || prevProps.setupStyles !== this.props.setupStyles) {
      this.setupEvent();
    }
  }

  handleFieldChange = fieldName => value => {
    const {
      focusedEvent,
    } = this.state;

    const updatedEvent = {
      ...focusedEvent,
      [fieldName]: value,
    };

    this.props.updateStoreFocusedEvent(updatedEvent);
    getUnavailableRooms(updatedEvent);
  }

  getUnavailableRooms = _.debounce(event => {

    this.props.getUnavailableRooms(event);
  }, 1000);

  selectRoom = room => {
    const {
      focusedEvent,
    } = this.state;

    if (!room.selected) {
      const bookingRoom = { roomId: room.id, name: room.name, roomType: room.type, recordStatus: 'Active' };

      if (focusedEvent.bookingEventRooms) {
        focusedEvent.bookingEventRooms.push(bookingRoom);
      } else {
        focusedEvent.bookingEventRooms = [bookingRoom];
      }
    }
    else {
      focusedEvent.bookingEventRooms = focusedEvent.bookingEventRooms.filter(eventRoom => eventRoom.roomId !== room.id);
    }

    this.props.updateStoreFocusedEvent(focusedEvent);
  }

  openCustomRoomModal = () => {
    this.setState({ isCustomRoomModalOpen: true });
  }

  onSaveCustomRoom = room => {
    const {
      focusedEvent,
    } = this.state;

    if (focusedEvent.bookingEventRooms) {
      focusedEvent.bookingEventRooms.push(room);
    } else {
      focusedEvent.bookingEventRooms = [room];
    }

    this.props.updateStoreFocusedEvent(focusedEvent);

    this.onCloseCustomRoomDialog();
  }

  onCloseCustomRoomDialog = () => {
    this.setState({ isCustomRoomModalOpen: false });
  }

  render() {
    const {
      classes,
      setupStyles,
      storeType,
    } = this.props;
    const {
      roomAvailability,
      isCustomRoomModalOpen,
      isLoading,
      focusedEvent,
    } = this.state;

    return (
      <div className={classes.venues}>
        <SimpleDialog
          message="You picked a venue that is Not Available!"
          innerRef={this.notAvailableWarning}
          onlyOkayButton={true}
        />
        <SectionHeader className={classes.sectionHeader}>Event Details</SectionHeader>
        {focusedEvent && <div className={classes.topContainer}>
          <div className={classes.form}>
            <div className={classes.row}>
              <Select
                className={classes.setupStyleSelect}
                label="Setup Style"
                value={focusedEvent.venueSetupStyleId}
                onFieldChange={this.handleFieldChange('venueSetupStyleId')}
              >
                {setupStyles.map(setupStyle => (
                  <MenuItem key={setupStyle.id} value={setupStyle.id}>{setupStyle.name}</MenuItem>
                ))}
              </Select>
              <TextField
                label="# of Guests"
                fieldName="estimatedGuestCount"
                value={focusedEvent.estimatedGuestCount}
                type="number"
                onFieldChange={this.handleFieldChange('estimatedGuestCount')}
              />
              <TextField
                label="Setup Time"
                fieldName="venueSetupTimeInMinutes"
                value={focusedEvent.venueSetupTimeInMinutes}
                type="number"
                onFieldChange={this.handleFieldChange('venueSetupTimeInMinutes')}
              />
              <TextField
                label="Teardown Time"
                fieldName="venueTearDownTimeInMinutes"
                value={focusedEvent.venueTearDownTimeInMinutes}
                type="number"
                onFieldChange={this.handleFieldChange('venueTearDownTimeInMinutes')}
              />
            </div>
          </div>
        </div>}

        <SectionHeader className={classes.sectionHeader}>
          Venue Finder
          <IconButton
            height={24}
            icon={AddIcon}
            onClick={this.openCustomRoomModal}
            className={classes.iconButton}
          />
        </SectionHeader>
        <div className={classes.venueFinderContainer}>
          <VenueFinder
            roomAvailability={roomAvailability}
            setupStyleId={focusedEvent.venueSetupStyleId}
            onSelectVenue={this.selectRoom}
            isLoading={isLoading}
            storeType={storeType}
          />
        </div>
        {isCustomRoomModalOpen &&
          <CustomRoomModal
            isOpened={true}
            saveCustomRoom={this.onSaveCustomRoom}
            onClose={this.onCloseCustomRoomDialog}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    booking: {
      [ownProps.storeType]: {
        storeBooking,
        focusedEventIndex,
        focusedEvent,
      },
    },
    api: {
      currentLocation,
      rooms,
      parentRooms,
      setupStyles,
    },
  } = state;

  return {
    storeBooking,
    focusedEventIndex,
    currentLocation,
    rooms,
    parentRooms,
    setupStyles,
    focusedEvent,
    storeType: ownProps.storeType,
  };
};

const mapDispatchToProps = {
  getRooms,
  getParentRooms,
  getUnavailableRooms,
  getAllSetupStyles,
  updateStoreFocusedEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VenuesSelection));
