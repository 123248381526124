import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  FolderOpen as FolderIcon,
} from '@material-ui/icons';

const styles = theme => ({
  folders: {
    display: 'flex',
    marginTop: 16,
    padding: 16,
    justifyContent: 'space-around',
  },
  folder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 140,
    height: 140,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    cursor: 'pointer',
    marginRight: 16,
    padding: 16,
    fontSize: 13,
  },
  folderTitle: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '400',
    paddingLeft: 0,
    paddingRight: 0,
  },
  selected: {
    borderColor: theme.palette.action.active,
    borderWidth: 2,
    '& $folderTitle': {
      fontWeight: '500',
    },
  },
  folderIcon: {
    color: theme.palette.grey[800],
    fontSize: 60,
  },
});

const EmailFolderManager = ({
  classes,
  onFolderSelected,
  folders,
  selectedFolder,
}) => {

  return (
    <div className={classes.folders}>
      {folders && folders.map(folder =>
        <div className={classNames(classes.folder, selectedFolder.id === folder.id && classes.selected)} onClick={() => onFolderSelected(folder)} key={folder.id}>
          <FolderIcon className={classes.folderIcon} />
          <span className={classes.folderTitle}>{folder.name}</span>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(EmailFolderManager);

