export const REMINDERS = [
  { id: 0, name: '0 minutes' },
  { id: 5, name: '5 minutes' },
  { id: 10, name: '10 minutes' },
  { id: 15, name: '15 minutes' },
  { id: 30, name: '30 minutes' },
  { id: 60, name: '1 hour' },
  { id: 120, name: '2 hours' },
  { id: 300, name: '5 hours' },
  { id: 1440, name: '1 day' },
  { id: 2880, name: '2 days' },
  { id: 10080, name: '1 week' },
];
