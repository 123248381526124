import {
  SET_CURRENT_CONTACT,
  DELETE_CONTACT,
  RESET_CURRENT_CONTACT,
  CLONE_CONTACT,
  RECEIVE_SALES_REPS,
  FETCH_SALES_REPS_BEGIN,
  CONTACT_NOTES_RECEIVED,
  CONTACT_NOTE_ADDED,
  CONTACT_NOTE_UPDATED,
  CONTACT_NOTE_DELETED,
  RECEIVE_CONTACTS,
  RECEIVE_CONTACT_NAME_AND_IDS,
  UPDATE_CURRENT_CONTACT,
} from './constants';
import { defaultContactsGridColumns } from 'constants/gridColumnDefaults';
import { callApi } from 'shared/CallApi';
import _ from 'lodash';
import {
  fetchContactsBegin,
  contactColumnsReceived,
  fetchProposalsBegin,
  proposalsReceived,
} from './api';
import {
  logChitChatSystemMessage,
} from 'actions/chitchat';
import { ENTITY_TYPES } from 'constants/entityTypes';

export const contactsReceived = (data, append) => ({
  type: RECEIVE_CONTACTS,
  data,
  append,
});

export const setCurrentContact = contact => ({
  type: SET_CURRENT_CONTACT,
  contact,
});

export const updateCurrentContact = contact => dispatch => dispatch({
  type: UPDATE_CURRENT_CONTACT,
  contact,
});

export const contactDeleted = response => ({
  type: DELETE_CONTACT,
  response,
});

export const currentContactReset = () => ({
  type: RESET_CURRENT_CONTACT,
});

export const contactCloned = response => ({
  type: CLONE_CONTACT,
  response,
});

export const fetchSalesRepsBegin = () => ({
  type: FETCH_SALES_REPS_BEGIN,
});

export const receivedSalesReps = salesRepsData => ({
  type: RECEIVE_SALES_REPS,
  salesRepsData,
});

export const contactNameAndIdsReceived = nameAndIds => ({
  type: RECEIVE_CONTACT_NAME_AND_IDS,
  nameAndIds,
});

export const searchContacts = searchTerm => dispatch => {
  return dispatch(callApi(`contact/search?searchTerm=${searchTerm}`))
    .then(result => result.json())
    .catch(console.error);
};

export const getProposals = contactId => {
  return dispatch => {
    dispatch(fetchProposalsBegin(contactId));

    return getProposalsApi(dispatch, contactId).then(json => {
      dispatch(proposalsReceived(json));

      return json;
    });
  };
};

export const getAccountsForContact = contactId =>
  dispatch =>
    dispatch(callApi(`contact/${contactId}/accounts`))
      .then(result => result.json());

export const deleteAccountContact = (contactId, accountId) =>
  dispatch =>
    dispatch(callApi(`contact/${contactId}/accounts/${accountId}`, { method: 'DELETE' }));

export const addAccountContact = (contactId, accountId) =>
  dispatch =>
    dispatch(callApi(`contact/${contactId}/accounts/${accountId}`, { method: 'POST' }));

export const setAccountAsPrimary = (contactId, accountId) =>
  dispatch =>
    dispatch(callApi(`contact/${contactId}/accounts/${accountId}/SetAsPrimary`, { method: 'PUT' }));

const getProposalsApi = (dispatch, contactId) => {
  let okay = true;
  let status = 0;
  let statusText;

  return dispatch(callApi(`contact/${contactId}/proposals`))
    .then(result => {
      if (!result.ok) {
        status = result.status;
        statusText = result.statusText;
        okay = false;
      }

      return result.json();
    })
    .then(json => {
      if (okay) {
        return json;
      }
      if (json.message) {
        throw Error(json.message);
      }
      if (statusText || status) {
        throw Error(`${status} - ${statusText}`);
      }
      throw Error('unknown');
    });
};

export const searchContactsGlobal = () => {
  return (dispatch, getState) => {
    const {
      searchReducer: {
        searchText,
      },
    } = getState();

    return dispatch(callApi(`Contact?%24filter=contains%28Name%2C%20%27${searchText}%27%29%20OR%20contains%28Email%2C%20%27${searchText}%27%29%20OR%20contains%28Phone%2C%20%27${searchText}%27%29`))
      .then(result => result.json())
      .then(result => {
        dispatch(contactsReceived(result));
      })
      .catch(console.error);
  };
};

export const getContacts = (params, append = false) => {
  return dispatch => {
    dispatch(fetchContactsBegin(params));

    return getContactsApi(dispatch, params).then(result => {
      // TODO: Find a better to clean up bad address

      dispatch(contactsReceived(result, append));

      return result;
    });
  };
};

const getContactsApi = (dispatch, params) => {
  return dispatch(callApi(`contact?${params}`)).then(result => result.json())
    .catch(console.error);
};

export const getSalesRepsIfNeeded = () => {
  return (dispatch, getState) => {
    const { api: { salesReps: { salesRepsData } } } = getState();

    return getSalesRepsApi(dispatch).then(apiSalesReps => {
      if (_.isEqual(apiSalesReps, salesRepsData)) {
        return salesRepsData;
      }
      dispatch(receivedSalesReps(apiSalesReps));

      return apiSalesReps;

    });
  };
};

const getSalesRepsApi = dispatch => {
  dispatch(fetchSalesRepsBegin());

  return dispatch(callApi('QuickPick/salesReps'))
    .then(result => result.json())
    .catch(console.error);
};

export const saveContacts = contacts => {
  let okay = true;
  let status = 0;
  let statusText;

  // This is bandaid and we need a more clear solution
  const simpleContacts = contacts.map(function(item) {
    delete item.accountContacts;

    return item;
  });

  return dispatch => dispatch(callApi(`contact/batch`, {
    body: simpleContacts,
    method: 'POST',
  }))
    .then(result => {
      if (!result.ok) {
        status = result.status;
        statusText = result.statusText;
        okay = false;
      }

      return result.json();
    })
    .then(json => {
      if (okay) {
        return json;
      }
      if (json.message) {
        throw Error(json.message);
      }
      if (statusText || status) {
        throw Error(`${status} - ${statusText}`);
      }
      throw Error('unknown');
    });
};

export const getContact = contactId => {
  return dispatch => dispatch(callApi(`contact/${contactId}`))
    .then(result => result.json())
    .then(json => {
      dispatch(setCurrentContact(json));

      return json;
    });
};

export const saveContact = contact => {
  if (contact && contact.contactAddresses && contact.contactAddresses.length) {
    contact.contactAddresses = contact.contactAddresses.map(address => {
      if (address.id && address.id < 0) delete address.id;

      return address;
    });
  }

  return dispatch => dispatch(callApi(`contact/${contact.id}`, { body: contact, method: 'PUT' }))
    .then(response => response.json())
    .then(contact => {
      if (contact.contactAddresses) {
        contact.contactAddresses = contact.contactAddresses.filter(address => address.recordStatus !== 'Deleted');
      }
      dispatch(setCurrentContact(contact));

      return contact;
    });
};

export const addContact = contact => (dispatch, getState) => {
  const { api: { currentLocation }, oidc: { user } } = getState();

  contact.locationContacts = [{ locationId: currentLocation.id }];
  if (!contact.contactAddresses || contact.contactAddresses.length === 0) {
    contact.contactAddresses = [{ primary: true }];
  }

  return dispatch(callApi(`contact`, { body: contact, method: 'POST' }))
    .then(response => response.json())
    .then(response => {
      const relationship = {
        id: response.id,
        entityType: ENTITY_TYPES.contact,
      };

      dispatch(logChitChatSystemMessage(`Contact created by ${user.profile.name}`, relationship, `Contact - ${response.name}`));

      return response;
    });
};

export const deleteContact = contactId => {
  return dispatch => dispatch(callApi(`contact/${contactId}`, { method: 'DELETE' }))
    .then(response => {
      dispatch(contactDeleted(response));
    });
};

export const clearCurrentContact = () => {
  return dispatch => {
    dispatch(currentContactReset());
  };
};

export const notesReceived = response => ({
  type: CONTACT_NOTES_RECEIVED,
  response,
});

export const noteAdded = response => ({
  type: CONTACT_NOTE_ADDED,
  response,
});

export const noteUpdated = response => ({
  type: CONTACT_NOTE_UPDATED,
  response,
});

export const noteDeleted = response => ({
  type: CONTACT_NOTE_DELETED,
  response,
});

export function cloneContact(contact) {
  return dispatch => dispatch(callApi(`contact/${contact.id}/clone`, { body: contact }))
    .then(result => result.json())
    .then(json => {
      dispatch(setCurrentContact(json));

      return json;
    });
}

export function getContactColumns() {
  return dispatch => dispatch(callApi('userSetting/contact_columns'))
    .then(response => response.json())
    .then(columnString => {
      const columnList = columnString ? columnString.split(',') : [];

      dispatch(contactColumnsReceived(columnList));
    })
    .catch(error => {
      console.error(error);
    });
}

export const getContactNameAndIds = () => {
  return dispatch => {
    return dispatch(callApi(`Contact/NameAndId`))
      .then(response => response.json())
      .then(nameAndIds => {
        dispatch(contactNameAndIdsReceived(nameAndIds));
      });
  };
};

export function saveContactColumns(columns) {
  return dispatch => {
    const setting = columns.length ? columns.join() : defaultContactsGridColumns.default.join();

    dispatch(callApi('userSetting/contact_columns', { method: 'PUT', body: setting }))
      .then(response => response.json())
      .then(dispatch(contactColumnsReceived(columns)));
  };
}

export function reorderContactColumns(columns) {
  return dispatch => dispatch(
    callApi('userSetting/contact_columns', { method: 'PUT', body: columns.join() }))
    .then(response => response.json());
}
