import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { StepLabel } from '@material-ui/core';

import classNames from 'classnames';

const styles = theme => ({
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 27,
    overflow: 'visible',
    cursor: 'pointer',
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 27,
    height: 27,
    background: theme.common.white,
    borderRadius: 13.5,
    border: `1px solid ${theme.palette.primary.dark}`,
    overflow: 'hidden',
  },
  activeStep: {
    '& $content,': {
      background: theme.palette.primary.dark,
      color: theme.common.white,
    },
  },
  completeStep: {
    borderColor: theme.common.emerald,
    '& $content,': {
      background: theme.common.emerald,
      color: theme.common.white,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelRoot: {
    marginTop: 8,
  },
  activeLabel: {
    color: theme.palette.primary.dark,
  },
  completeLabel: {
    color: theme.common.emerald,
  },
});

class CircleStep extends Component {

  render() {
    const { classes, label, isActive, isComplete, onStepClick, stepIndex } = this.props;

    return (
      isComplete ?
        <div className={classes.step} onClick={() => onStepClick(stepIndex)}>
          <div className={classNames(classes.circle, classes.completeStep)}>
            <div className={classes.content}>
              {stepIndex + 1}
            </div>
          </div>
          <StepLabel
            classes={{
              root: classes.labelRoot,
              label: classes.completeLabel,
            }}
          >
            {label}
          </StepLabel>
        </div>
        :
        <div className={classes.step} onClick={() => onStepClick(stepIndex)}>
          <div className={classNames(classes.circle, isActive && classes.activeStep)}>
            <div className={classes.content}>
              {stepIndex + 1}
            </div>
          </div>
          <StepLabel
            classes={{
              root: classes.labelRoot,
              label: classNames(isActive && classes.activeLabel),
            }}
          >
            {label}
          </StepLabel>
        </div>
    );
  }
};

export default withStyles(styles)(CircleStep);
