import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tags from "@yaireo/tagify/dist/react.tagify";
import classNames from 'classnames';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { getTags } from 'actions/item';

const styles = theme => ({
  tagIcon: {
    fill: '#060f4e',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '16px',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    userSelect: 'none',
    flexShrink: '0',
    marginLeft: '5px',
  },
  tagsContainer: {
    '& tags': {
      fontSize: '16px',
      border: '0',
      margin: '0',
      marginTop: '6px',
      flex: 1,
      width: '100%',
    },
    '& div': {
      width: '100%',
    },
    '&:hover': {
      borderBottom: '2px solid rgb(100,100,100)',
      paddingBottom: 0,
    },
    '& .searchIcon': {
      marginTop: '12px',
      marginLeft: '5px',
      fill: '#bcbcbc',
      fontSize: '25px',
      width: 30,
    },
    '& .tagify__tag__removeBtn': {
      font: '17px Serif',
      width: 22,
    },
    borderBottom: '1px solid rgb(97,97,97)',
    display: 'flex',
    border: '0',
    flexDirection: 'row',
    marginTop: '6px',
    paddingBottom: 1,
  },
});

class MenuItemFilterField extends Component {
  state = {};

  componentDidMount() {
    this.props.getTags();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.itemTags !== prevProps.itemTags) {
      this.setState({
        tagSuggestions: this.props.itemTags.map(tag => {
          return {
            value: tag.name,
            type: 'tag',
            id: tag.id,
          };
        }),
      });
    }
  }

  fieldChanged = event => {
    if (!this.props.onChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    if (!event.target.value) {
      this.props.onChange(null);

      return;
    }

    this.props.onChange(JSON.parse(event.target.value));
  }

  render() {

    const {
      classes,
      disabled,
      className,
    } = this.props;

    const { tagSuggestions } = this.state;

    const tagifySettings = {
      whitelist: tagSuggestions,
      placeholder: " try 'Burger' or 'Breakfast'",
      dropdown: {
        enabled: 0,
      },
      templates: {
        tag: function (v, tagData) {
          try {
            return `<tag title='${v}' contenteditable='false' spellcheck="false" class='tagify__tag ${tagData.class ? tagData.class : ""}' ${this.getAttributes(tagData)}>
                        <x title='remove tag' class='tagify__tag__removeBtn'></x>
                        ${tagData.type === 'tag' ? `<svg class="${classes.tagIcon}" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize small"><path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z"></path></svg>` : `<svg class="${classes.tagIcon}" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize small"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>`}
                        <div>
                            <span class='tagify__tag-text'>${v}</span>
                        </div>
                    </tag>`;
          }
          catch (err) { }
        },
      },
    };

    return <div className={className || classes.tagsContainer}>
      <SearchIcon className="searchIcon" />
      <Tags
        claOssName={classNames(classes.tagInput, className)}
        readOnly={disabled}
        settings={tagifySettings}
        onChange={this.fieldChanged}
      />
    </div>;
  }
}

const mapStateToProps = state => {
  const {
    admin: {
      itemTags,
    },
  } = state;

  return {
    itemTags,
  };
};

const mapDispatchToProps = {
  getTags,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MenuItemFilterField));
