import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import GridIcon from '@material-ui/icons/GridOn';
import { List as DisplayListViewIcon } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { getCategories } from 'actions/item';
import {
  setStoreMenuItemsFromACategory,
  setStoreMenuFilter,
  filterMenu,
} from 'actions/storemenu';
import Cart from './Cart';
import { addItemToStoreOrder } from 'actions/order';
import { connect } from 'react-redux';
import { defaultItemLibraryGrid } from 'constants/apiParamDefaults';
import StoreMenuImagePreview from './StoreMenuImagePreview';
import List from './List';
import StoreMenuItemModifier from '../ModifierModal/StoreMenuItemModifier';
import { Drawer } from '@material-ui/core';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AlacarteModal from '../../alacarte';
import MenuItemFilterField from 'Components/Menu/MenuItemFilterField';
import MenuSelectionDropDown from './MenuSelectionDropDown';
import CategoryNavigation from './CategoryNavigation';
import { updateStoreOrderItem } from 'actions/order';

const styles = theme => {
  return {
    container: {
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexGrow: 1,
      height: '480px',
    },
    categoryContainer: {
      minWidth: 290,
      maxWidth: 290,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 12,
      height: '480px',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 6,
      backgroundColor: theme.palette.grey[50],
      minHeight: 44,
      width: '100%',
    },
    selectionBar: {
      width: '87%',
    },
    gridContainer: {
      display: 'flex',
      flexGrow: 1,
    },
    cartContainer: {
      minWidth: 222,
    },
    isDrawerOpen: {

    },
    drawerPaper: {
      position: 'absolute',
      width: 400,
      backgroundColor: 'fff',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    cartPanel: {
      background: 'fff',
    },
    icon: {
      fontSize: 26,
    },
    button: {
      border: `1px solid ${theme.palette.grey[50]}`,
      color: theme.palette.grey[300],
      minHeight: '40px',
      minWidth: '40px',
      marginLeft: '13px',
      padding: 0,
      borderRadius: '0 4px 4px 0',
      fontSize: 26,
    },
    bagButton: {
      border: '1px solid #2189F8',
      color: '#0070D2',
      minHeight: '44px',
      minWidth: '44px',
      marginLeft: '13px',
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: '3px',
      position: 'absolute',
      top: '95px',
      right: '15px',
      height: '44px',
      width: '44px',
    },
    bagButtonActive: {
      backgroundColor: '#0070D2',
      color: 'white',
      borderColor: '#2189F8',
    },
    bagButtonCounter: {
      border: `1px solid #2189F8`,
      backgroundColor: '#DEDEDE',
      minHeight: '6px',
      minWidth: '14px',
      borderRadius: '10px',
      fontSize: 10,
      padding: '0px 4px 0px 4px',
      color: '#0070D2',
      position: 'absolute',
      top: '98px',
      right: '20px',
    },
    orderNumber: {
      position: 'absolute',
      top: '33px',
      left: '12px',
      fontSize: '15px',
    },
    menuSelection: {
      position: 'absolute',
      top: '70px',
      left: '2px',
      fontSize: '15px',
    },
    alacarte: {
      background: 'white',
      position: 'absolute',
      textTransform: 'initial',
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
      right: '12px',
      margin: '4px',
    },
    tagsContainer: {
      '& tags': {
        fontSize: '16px',
        border: '0',
        margin: '0',
        flex: 1,
        width: '100%',
        height: '100%',
      },
      '& div': {
        width: '100%',
      },
      '&:hover': {
        paddingBottom: 0,
      },
      '& .searchIcon': {
        marginLeft: '15px',
        fill: '#bcbcbc',
        fontSize: '25px',
        width: 36,
        alignSelf: 'center',
      },
      '& .tagify__tag__removeBtn::after ': {
        width: 9,
        margin: 3,
      },
      display: 'flex',
      width: '100%',
      border: '0',
      flexDirection: 'row',
      marginTop: '3px',
      paddingBottom: 1,
    },
  };
};

export class StoreMenuSelection extends Component {
  constructor(props) {
    super(props);
    const initialSort = [{ field: 'name', dir: 'asc' }];

    this.state = {
      menuItems: props.storeMenuSelectedCategoryItems,
      selectedMenuItem: {},
      selectedCategory: props.storeMenuCategories[0],
      isImagesOpened: props.isImagesOpenedFirst ? true : false,
      sort: initialSort,
      modifiersModalVisible: false,
      alacarteModalVisible: false,
      activeFilters: {
        ...defaultItemLibraryGrid,
        ...this.getFieldAndDirection(initialSort),
        includeImages: true,
        includeTagSummary: true,
        pageSize: null,
      },
      isDrawerOpen: false,
    };
  }

  componentDidMount() {
    this.setFilter([]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeMenuSelectedCategoryItems !== this.props.storeMenuSelectedCategoryItems) {
      this.setState({ menuItems: this.props.storeMenuSelectedCategoryItems });
    }
    if (prevProps.storeMenuCategories !== this.props.storeMenuCategories && this.props.storeMenuCategories && this.props.storeMenuCategories.length > 0 && !this.props.storeMenuCategories.some(category => category.id === this.state.selectedCategory.id)) {
      this.setState({ selectedCategory: this.props.storeMenuCategories[0] });
    }
  }

  toggleViews = () => { //TODO: temp
    this.setState(prevState => ({ isImagesOpened: !prevState.isImagesOpened }));
  }

  rightButtons = () => {
    const { isImagesOpened } = this.state;

    return (
      <div>
        <HighlightButton
          variant="left"
          minWidth={34}
          pressed={isImagesOpened}
          onClick={this.toggleViews}
        >
          <GridIcon />
        </HighlightButton>
        <HighlightButton
          variant="right"
          minWidth={34}
          pressed={!isImagesOpened}
          onClick={this.toggleViews}
        >
          <DisplayListViewIcon />
        </HighlightButton>
      </div>
    );
  }

  onItemSelected = itemSelected => {
    this.setState({
      selectedMenuItem: itemSelected,
      modifiersModalVisible: true,
    });
  }

  onCartButton = () => {
    const { isDrawerOpen } = this.state;

    this.setState({
      isDrawerOpen: !isDrawerOpen,
    });
  }

  // Need to keep track of selected items on front end, so filtered API results can persist selections
  updateSelectedItems = menuItemId => {
    const { selectedItemIds } = this.state;
    const indexOfId = selectedItemIds.indexOf(menuItemId);

    this.setState({ modifiersModalVisible: true });

    return indexOfId > -1 ?
      selectedItemIds.filter(id => id !== menuItemId) :
      [...selectedItemIds, menuItemId];
  }

  massageData = apiItems => {
    const { selectedItemIds } = this.state;

    return apiItems.map(menuItem => {
      const selected = selectedItemIds.indexOf(menuItem.id) !== -1;

      return {
        ...menuItem,
        quantity: this.props.defaultQuantity,
        selected,
      };
    });
  }

  categoryPicked = selectedCategory => {
    this.setState({ selectedCategory });
  }

  onSave = () => {
    const { menuItems } = this.state;

    this.props.onSave(menuItems);
  }

  getFieldAndDirection = sort => {
    if (sort.length === 0) {
      return { sortField: '', sortDirection: '' };
    }

    const { field: sortField, dir: sortDirection } = sort[0];

    return { sortField, sortDirection };
  }

  onSortChange = sort => {
    this.setState({ sort });
  }

  onModifiersModalSave = data => {
    this.setState({
      modifiersModalVisible: false,
    });
  }

  onAlacarteModalSave = data => {
    this.setState({
      alacarteModalVisible: false,
    });
  }

  onOpenAlacarteModal = data => {
    this.setState({
      alacarteModalVisible: true,
    });
  }

  getCartItemCount = () => {
    if (this.props.storeOrder.orderItems) {
      return this.props.storeOrder.orderItems.filter(orderItem => orderItem.recordStatus === 'Active').length;
    }
  }

  getOrderId = () => {
    const orderId = this.props.storeOrder.id;

    return orderId ? orderId : "";
  }

  setFilter = filter => {
    const { selectedCategory } = this.state;

    this.props.filterMenu(filter, selectedCategory);
  };

  render() {
    const {
      classes,
    } = this.props;
    const {
      menuItems,
      selectedMenuItem,
      isImagesOpened,
      sort,
      modifiersModalVisible,
      isDrawerOpen,
      alacarteModalVisible,
    } = this.state;

    return (

      <div>
        <div className={classes.orderNumber}>Order: {this.getOrderId()}</div>
        <div className={classes.menuSelection}><MenuSelectionDropDown/></div>
        <div className={classes.filterContainer}>
          <MenuItemFilterField
            className={classes.tagsContainer}
            onChange={this.setFilter}
          />
          <Button
            variant="outlined"
            aria-label="Add to Cart"
            onClick={this.onOpenAlacarteModal}
            className={classes.alacarte}
          >
            Open Item
          </Button>
          <Button
            variant="outlined"
            aria-label="Add to Cart"
            className={isDrawerOpen ? classNames(classes.bagButton, classes.bagButtonActive) : classes.bagButton}
            onClick={this.onCartButton}>
            <ShoppingCartIcon style={{ fontSize: 30 }} className={classes.icon} />
          </Button>
          <div className={classes.bagButtonCounter}>
            {this.getCartItemCount()}
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.categoryContainer}>
            <CategoryNavigation
              setSelectedCategory={this.categoryPicked}
            />
          </div>
          <div className={classes.gridContainer}>
            {isImagesOpened ?
              <StoreMenuImagePreview
                menuItems={menuItems}
                onItemSelected={this.onItemSelected}
              />
              :
              <List
                menuItems={menuItems}
                onItemSelected={this.onItemSelected}
                onSelectAll={this.onSelectAll}
                onSortChange={this.onSortChange}
                sort={sort}
              />
            }
            <Drawer
              variant="persistent"
              anchor="right"
              open={isDrawerOpen}
              classes={{
                paper: classes.drawerPaper,
                docked: classNames(classes.docked, !isDrawerOpen && classes.dockedShift),

              }}
            >
              <div className={classes.cartPanel}>
                <Cart />
              </div>
            </Drawer>
          </div>

        </div>
        {modifiersModalVisible && <StoreMenuItemModifier
          onModalClosed={this.onModifiersModalSave}
          updateStoreOrderItem={this.props.updateStoreOrderItem}
          menuItem={selectedMenuItem} />}
        {alacarteModalVisible && <AlacarteModal
          onModalClosed={this.onAlacarteModalSave}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeOrder,
      storeMenuCategories,
      storeMenuSelectedCategoryItems,
      storeMenuRepresentedItemTags,
      storeMenuSelectedTags,
      storeMenuFilter,
    },
  } = state;

  return {
    storeOrder,
    storeMenuCategories,
    storeMenuSelectedCategoryItems,
    storeMenuRepresentedItemTags,
    storeMenuSelectedTags,
    storeMenuFilter,
  };
};

const mapDispatchToProps = {
  getCategories,
  addItemToStoreOrder,
  setStoreMenuItemsFromACategory,
  setStoreMenuFilter,
  filterMenu,
  updateStoreOrderItem,
};

export const MenuItemModal = withStyles(styles)(StoreMenuSelection);

const MenuItemModalContainer = connect(mapStateToProps, mapDispatchToProps)(MenuItemModal);

export default MenuItemModalContainer;
