import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import DataLoader from 'Components/DataLoader';
import { MenuItem } from '@material-ui/core';
import Select from 'Components/Select';
import TextField from 'Components/TextField';
import Checkbox from 'Components/Checkbox';
import { connect } from 'react-redux';
import { getProposals } from 'actions/proposal';
import { PROPOSAL_TYPES } from 'constants/proposalTypes';
import ContactSelect from 'Components/ContactSelect';
import AccountSelect from 'Components/AccountSelect';
import { saveBooking } from 'actions/booking';
import { getAccount, addAccount, addAccountContact } from 'actions/account';
import { getContact, addContact } from 'actions/contact';
import { addStatusHistory } from 'actions/statusHistory';
import { saveLead } from 'actions/lead';
import { ENTITY_TYPES } from 'constants/entityTypes';
import {
  Radio,
  FormControlLabel,
} from '@material-ui/core';

const styles = () => ({
  halfWidthColumn: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  or: {
    display: 'flex',
    marginTop: 16,
  },
  radioWrap: {
    display: 'flex',
    paddingLeft: 16,
  },
  newContact: {
    display: 'flex',
  },
});

class Convert extends Component {
  constructor(props) {
    super(props);
    const accountExists = !!props.lead.accountId;

    this.state = {
      isLoading: true,
      proposals: [],
      conversions: {
        isNewBooking: false,
        isNewAccount: !accountExists,
        isNewContact: true,
        accountId: props.lead.accountId,
        bookingName: '',
        accountName: props.lead.company || props.lead.name || '',
        contactFirstName: props.lead.firstName,
        contactLastName: props.lead.lastName,
        selectedProposal: {},
      },
      omitBooking: false,
      omitContact: false,
      invalidFields: [],
    };
  }

  componentDidMount() {
    this.getProposals();
  }

  getProposals = () => {
    this.props.getProposals(PROPOSAL_TYPES.lead, this.props.lead.id).then(proposals => {
      this.setState({
        proposals,
        isLoading: false,
        omitBooking: !proposals.length,
      });
    });
  }

  selectProposal = bookingId => {
    const { proposals, conversions } = this.state;
    const { lead } = this.props;
    const selectedProposal = proposals.find(p => p.id === bookingId);

    this.setState({
      conversions: {
        ...conversions,
        bookingName: selectedProposal.name,
        accountName: lead.name || '',
        contactFirstName: lead.firstName || '',
        contactLastName: lead.lastName || '',
        isNewBooking: !!selectedProposal,
        isNewAccount: !selectedProposal.accountId && !lead.accountId,
        isNewContact: !selectedProposal.contactId,
        bookingId: selectedProposal.id,
        contactId: selectedProposal.contactId,
        accountId: selectedProposal.accountId || lead.accountId,
        selectedProposal,
      },
    });
  }

  handleChange  = fieldName => value => {
    const conversions = {
      ...this.state.conversions,
      [fieldName]: value,
    };

    this.setState({ conversions }, () => this.validateField(fieldName));
  }

  // AKA attach this Contact or Account to the selected Booking
  handleSelect = fieldName => selection => {
    const conversions = {
      ...this.state.conversions,
      [fieldName]: selection && selection.id,
    };

    this.setState({ conversions }, () => this.validateField(fieldName));
  }

  handleCheckbox = fieldName => checked => {
    const state = {
      ...this.state,
      [fieldName]: checked,
    };

    this.setState(state);
  }

  handleRadioButton = fieldName => event =>  {
    const isNew = event.target.value === 'new';

    this.handleChange(fieldName)(isNew);
  }

  isFormValid = () => {
    return this.state.invalidFields.length === 0;
  }

  validateForm = () => {
    const { conversions, omitBooking, omitContact, proposals } = this.state;
    const requiredFields = [];

    if (conversions.isNewAccount) {
      requiredFields.push('accountName');
    } else {
      requiredFields.push('accountId');
    }
    if (!omitContact) {
      if (conversions.isNewContact && !omitContact) {
        requiredFields.push('contactFirstName');
      } else {
        requiredFields.push('contactId');
      }
    }
    if (proposals.length > 0 && !omitBooking) {
      requiredFields.push('bookingName');
      requiredFields.push('bookingId');
    }

    const invalidFields = requiredFields.filter(fieldName => !conversions[fieldName]);

    this.setState({ invalidFields });

    return Promise.resolve();
  }

  validateField = fieldName => {
    const { conversions, omitBooking, omitContact } = this.state;
    let isValid = true;
    const value = conversions[fieldName];

    switch (fieldName) {
      case 'accountName':
        isValid = conversions.isNewAccount && !!value;
        break;
      case 'accountId':
        isValid = !conversions.isNewAccount && !!value;
        break;
      case 'contactFirstName':
        isValid = !omitContact && conversions.isNewContact && !!value;
        break;
      case 'contactId':
        isValid = !omitContact && !conversions.isNewContact && !!value;
        break;
      case 'bookingName':
        isValid = !omitBooking && conversions.isNewBooking && !!value;
        break;
      case 'bookingId':
        isValid = !omitBooking && !conversions.isNewBooking && !!value;
        break;
      default:
        return;
    }

    let invalidFields = [...this.state.invalidFields];
    const indexOfField = invalidFields.indexOf(fieldName);

    if (!isValid) {
      if (indexOfField === -1) invalidFields.push(fieldName);
    } else {
      if (indexOfField !== -1) invalidFields.splice(indexOfField, 1);
    }

    this.setState({ invalidFields });
  }

  isInvalid = fieldName => {
    return this.state.invalidFields.indexOf(fieldName) !== -1;
  }

  convertLead = () => {
    this.validateForm().then(async () => {
      if (!this.isFormValid()) {
        return;
      }

      const { conversions, omitBooking, omitContact } = this.state;
      const {
        lead,
        leadStatuses,
        saveBooking,
        getAccount,
        addAccount,
        getContact,
        addContact,
        addAccountContact,
        saveLead,
        onConvertLeadStarted,
        onConvertLeadSuccess,
      } = this.props;
      const isNewBooking = !omitBooking && !!conversions.bookingId;
      const isNewAccount = conversions.isNewAccount;
      const isNewContact = conversions.isNewContact;
      const booking = conversions.selectedProposal;

      onConvertLeadStarted();

      const account = isNewAccount
        ? await addAccount({ name: conversions.accountName })
        : await getAccount(conversions.accountId);

      let contact = null;

      if (!omitContact) {
        contact = isNewContact
          ? await addContact({ firstName: conversions.contactFirstName, lastName: conversions.contactLastName })
          : await getContact(conversions.contactId);

        await addAccountContact(account.id, contact.id);
        account.contact = contact.name;
      }

      if (isNewBooking) {
        booking.proposal = false;
        booking.name = conversions.bookingName;
        booking.entityType = ENTITY_TYPES.account;
        booking.entityId = account.id;

        saveBooking(booking);
      }

      delete lead.leadStatus;
      lead.statusId = leadStatuses.find(status => status.name === 'Converted').id;

      await saveLead(lead);
      this.props.addStatusHistory('Lead', lead.id, lead.statusId);

      onConvertLeadSuccess({
        isNewBooking,
        isNewAccount,
        isNewContact,
        account,
        booking: omitBooking ? null : booking,
        contact: omitContact ? null : contact,
      });
    });
  }

  render() {
    const {
      classes,
      onClose,
    } = this.props;
    const {
      proposals,
      conversions,
      isLoading,
      omitBooking,
      omitContact,
    } = this.state;

    const isSaveDisabled = !omitBooking && !conversions.bookingId;

    return (
      isLoading ? <DataLoader isLoading={true} inline={true} /> :
        <Modal
          isOpened={true}
          onCancel={onClose}
          title="Convert"
          onSave={this.convertLead}
          addTitleBottomBorder={true}
          saveText="Convert"
          isSaveDisabled={isSaveDisabled}
        >
          {proposals.length > 0 &&
          <CoverExpansionPanel title="Booking" defaultExpanded={true}>
            <div className={classes.halfWidthColumn}>
              <Select
                label="Proposal"
                value={conversions.bookingId}
                onFieldChange={this.selectProposal}
                name="bookingId"
                disabled={omitBooking}
              >
                {proposals.map(proposal =>
                  <MenuItem key={proposal.id} value={proposal.id}>{proposal.name}</MenuItem>
                )}
              </Select>
              <Checkbox
                label="Don't Create Booking"
                checked={omitBooking}
                onFieldChange={this.handleCheckbox('omitBooking')}
              />
            </div>
            <div className={classes.or}></div>
            <div className={classes.halfWidthColumn}>
              <TextField
                label="Booking Name"
                value={conversions.bookingName}
                onFieldChange={this.handleChange('bookingName')}
                disabled={omitBooking}
                error={this.isInvalid('bookingName')}
              />
            </div>
          </CoverExpansionPanel>
          }
          <CoverExpansionPanel title="Account" defaultExpanded={true}>
            <div className={classes.halfWidthColumn}>
              <div className={classes.radioWrap}>
                <FormControlLabel
                  label="Choose Existing"
                  checked={!conversions.isNewAccount}
                  onChange={this.handleRadioButton('isNewAccount')}
                  value="existing"
                  control={<Radio disableRipple />}
                />
              </div>
              <AccountSelect
                label="Choose Existing"
                parentObject={conversions}
                fieldName={'accountId'}
                value={conversions.accountId}
                onSelect={this.handleSelect}
                disabled={conversions.isNewAccount}
                error={this.isInvalid('accountId')}
              />
            </div>
            <div className={classes.or}>OR</div>
            <div className={classes.halfWidthColumn}>
              <div className={classes.radioWrap}>
                <FormControlLabel
                  label="Create New"
                  checked={conversions.isNewAccount}
                  onChange={this.handleRadioButton('isNewAccount')}
                  value="new"
                  control={<Radio disableRipple />}
                />
              </div>
              <TextField
                label="Create New Account"
                value={conversions.accountName}
                onFieldChange={this.handleChange('accountName')}
                disabled={!conversions.isNewAccount}
                error={conversions.isNewAccount && this.isInvalid('accountName')}
              />
            </div>
          </CoverExpansionPanel>
          <CoverExpansionPanel title="Contact" defaultExpanded={true}>
            <div className={classes.halfWidthColumn}>
              <div className={classes.radioWrap}>
                <FormControlLabel
                  label="Choose Existing"
                  checked={!conversions.isNewContact}
                  onChange={this.handleRadioButton('isNewContact')}
                  value="existing"
                  disabled={omitContact}
                  control={<Radio disableRipple />}
                />
              </div>
              <ContactSelect
                label="Choose Existing"
                parentObject={conversions}
                fieldName="contactId"
                onSelect={this.handleSelect}
                disabled={omitContact || conversions.isNewContact}
                error={this.isInvalid('contactId')}
              />
              <Checkbox
                label="Don't Create Contact"
                checked={omitContact}
                onFieldChange={this.handleCheckbox('omitContact')}
              />
            </div>
            <div className={classes.or}>OR</div>
            <div className={classes.halfWidthColumn}>
              <div className={classes.radioWrap}>
                <FormControlLabel
                  label="Create New"
                  checked={conversions.isNewContact}
                  onChange={this.handleRadioButton('isNewContact')}
                  value="new"
                  disabled={omitContact}
                  control={<Radio disableRipple />}
                />
              </div>
              <div className={classes.newContact}>
                <TextField
                  label="First Name"
                  value={conversions.contactFirstName}
                  onFieldChange={this.handleChange('contactFirstName')}
                  disabled={omitContact || !conversions.isNewContact}
                  error={conversions.isNewContact && this.isInvalid('contactFirstName')}
                />
                <TextField
                  label="Last Name"
                  value={conversions.contactLastName}
                  onFieldChange={this.handleChange('contactLastName')}
                  disabled={omitContact || !conversions.isNewContact}
                  error={conversions.isNewContact && this.isInvalid('contactLastName')}
                />
              </div>
            </div>
          </CoverExpansionPanel>
        </Modal>
    );
  }
}

const mapStateToProps = state => ({
  leadStatuses: state.settings.statuses && state.settings.statuses.length
    ? state.settings.statuses.find(status => status.type === 'Lead').statuses
    : [],
});

const mapDispatchToProps = {
  getProposals,
  saveBooking,
  getAccount,
  addAccount,
  getContact,
  addContact,
  addAccountContact,
  saveLead,
  addStatusHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Convert));
