import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import LeadList from './LeadList';
import Lead from './Lead';
import { Route } from 'react-router-dom';

class Leads extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/leads" component={LeadList} />
        <Route path="/leads/:id" component={Lead} />
      </Switch>
    );
  }
}

export default withRouter(Leads);
