import React, { Component } from 'react';
import { connect } from 'react-redux';
import ComboBox from 'Components/ComboBox';
import { getAccountNameAndIds } from 'actions/account';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';

class AccountSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountsMap: {},
    };
  }

  componentDidMount() {
    this.props.getAccountNameAndIds();
    this.setupAccountSuggestions(this.props.accounts);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accounts !== this.props.accounts) {
      this.setupAccountSuggestions(this.props.accounts);
    }
  }

  setupAccountSuggestions = accounts => {
    if (accounts) {
      const { comboBoxSuggestions, comboBoxMap } = setupComboBoxSuggestions(accounts);

      this.setState({ accountSuggestions: comboBoxSuggestions, accountsMap: comboBoxMap });
    }
  }

  onSelect = pick => {
    const { fieldName } = this.props;
    const value = pick && pick.value;

    this.props.onSelect(fieldName)(value);
  }

  render() {
    const {
      parentObject,
      label,
      fieldName,
      onQuickAdd,
    } = this.props;
    const {
      accountSuggestions,
      accountsMap,
    } = this.state;

    return (
      <ComboBox
        {...this.props}
        label={label || 'Account'}
        value={accountsMap[parentObject[fieldName]]}
        suggestions={accountSuggestions}
        isClearable={true}
        isCreatable={!!onQuickAdd}
        handleChange={this.onSelect}
        handleQuickAdd={onQuickAdd}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      accounts,
    },
  } = state;

  return {
    accounts: accounts.nameAndIds,
  };
};

const mapDispatchToProps = {
  getAccountNameAndIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelect);
