import React, { Component } from 'react';
import GridIcon from '@material-ui/icons/GridOn';
import { withStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import HighlightButton from 'Components/Buttons/HighlightButton';
import GridColumnEditMenu from 'Components/Grid/GridColumnEditMenu';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  MenuItem,
  Menu,
  Button,
} from '@material-ui/core';

const toolbarStyles = theme => ({
  root: {
    paddingRight: 20,
    paddingLeft: 12,
    display: 'flex',
    height: 85,
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.grey[800],
  },
  buttonContainer: {
    display: 'flex',
  },
  category: {
    fontSize: 15,
  },
  title: {
    paddingLeft: 6,
    fontSize: 15,
  },
  customFilter: {
    display: 'flex',
  },
  filterSelect: {
    width: '100%',
  },
  filterButton: {
    textTransform: 'initial',
    fontSize: 18,
    fontWeight: 'normal',
    color: theme.palette.grey[800],
  },
  buttonDisabled: {
    color: theme.palette.grey[800] + '!important',
  },
  buttonText: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  spaceButtons: {
    marginLeft: 10,
  },
  setPositionGridMenu: {
    position: 'relative',
  },
  filterDropDown: {
    color: theme.palette.text.primary,
  },
});

class GridToolBar extends Component {
  constructor(props) {
    super(props);
    const defaultFilter = { id: 1, name: `All ${props.gridContext}`, filter: { logic: 'and', filters: [] } };
    const customFilters = props.customFilters ? [defaultFilter, ...props.customFilters] : [defaultFilter];

    this.state = {
      activeCustomFilter: this.props.filterId ? customFilters.find(filter => filter.id === this.props.filterId) || defaultFilter : defaultFilter,
      customFilters,
      anchorEl: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeCustomFilter !== this.props.activeCustomFilter || prevProps.customFilters !== this.props.customFilters) {
      this.updateFilter();
    }
  }

  updateFilter = () => {
    const { gridContext, activeCustomFilter, customFilters } = this.props;
    const defaultFilter = { id: 1, name: `All ${gridContext}`, filter: {} };
    let newActiveFilter = defaultFilter;
    let newFilters = [defaultFilter];

    if (customFilters.length > 0) {
      newFilters = [
        defaultFilter,
        ...customFilters,
      ];
    }

    if (activeCustomFilter) {
      newActiveFilter = activeCustomFilter;
    }
    this.setState({ customFilters: newFilters, activeCustomFilter: newActiveFilter });
  }

  openCustomFilterMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  closeCustomFilterMenu = () => {
    this.setState({ anchorEl: null });
  }

  applyFilter = filterId => {
    this.closeCustomFilterMenu();
    this.props.onApplyCustomFilter(filterId);
  }

  render() {
    const {
      classes,
      isFilterToggled = false,
      isGridToggled = false,
      columns,
      onColumnsSubmit,
      onCloseMenu,
      onGridEditClick,
      gridContext,
      onAddClick,
      onFilterClick,
      additionalButtons,
      filter,
      filterId,
    } = this.props;
    const {
      customFilters,
      activeCustomFilter,
      anchorEl,
    } = this.state;

    return (
      <div className={classes.root}>

        {filter ? filter : <div>
          <span className={classes.title}>{gridContext}</span>
          <Button
            className={classes.customFilter}
            classes={{
              root: classes.filterButton,
              disabled: classes.buttonDisabled,
              text: classes.buttonText,
            }}
            onClick={this.openCustomFilterMenu}
            aria-owns={'simple-menu'}
            aria-haspopup="true"
            disabled={customFilters.length < 2}
          >
            {activeCustomFilter.name}
            <DropDownIcon className={classes.filterDropDown} />
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.closeCustomFilterMenu}
          >
            {customFilters && customFilters.map(filter =>
              <MenuItem key={filter.id} value={filter.id} selected={filterId ? filter.id === filterId : false} onClick={() => this.applyFilter(filter.id)}>{filter.name}</MenuItem>
            )}
          </Menu>
        </div>}

        <div className={classes.buttonContainer}>
          {additionalButtons}

          {onAddClick &&
            <HighlightButton
              onClick={onAddClick}
              aria-label={`Add ${gridContext}`}
              className={classes.spaceButtons}
            >
              <AddIcon />
            </HighlightButton>
          }

          {/* {onEditClick &&
            <HighlightButton
              pressed={isEditToggled}
              onClick={onEditClick}
              aria-label={`Edit ${gridContext}`}
              className={classes.spaceButtons}
            >
              <EditIcon />
            </HighlightButton>
          } */}
          {onGridEditClick &&
            <div className={classes.setPositionGridMenu}>
              <HighlightButton
                onClick={onGridEditClick}
                aria-label="Edit Columns"
                className={classes.spaceButtons}
              >
                <GridIcon />
              </HighlightButton>
              <GridColumnEditMenu columns={columns} isMenuVisible={isGridToggled} onColumnsSubmit={onColumnsSubmit} onCloseMenu={onCloseMenu} />
            </div>
          }
          {onFilterClick &&
            <HighlightButton
              pressed={isFilterToggled}
              onClick={onFilterClick}
              aria-label="Filter List"
              className={classes.spaceButtons}
            >
              <FilterListIcon />
            </HighlightButton>
          }
        </div>
      </div>
    );
  }
};

export default withStyles(toolbarStyles)(GridToolBar);
