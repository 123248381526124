import React, { useRef, useState, useEffect } from 'react';
import EmailFolderManager from './EmailFolderManager';
import EmailTrigger from './EmailTrigger';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SimpleDialog from 'Components/SimpleDialog';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { EMAIL_TYPES } from 'constants/entityTypes';
import { getStatuses } from 'actions/settings';
import { getEmailTriggers } from 'actions/emailTriggers';
import { Card } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

const styles = () => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  mainContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '16px 32px',
  },
  header: {
    paddingLeft: 16,
    fontSize: 16,
    fontWeight: 'bold',
  },
  hr: {
    margin: '16px 0',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const EmailAutomation = ({
  classes,
  emailTriggers,
  getEmailTriggers,
  getStatuses,
  statuses,
}) => {
  const dialog = useRef();
  const folders = [
    { id: 1, name: EMAIL_TYPES.order },
    { id: 2, name: EMAIL_TYPES.lead },
    { id: 3, name: EMAIL_TYPES.booking },
    { id: 4, name: EMAIL_TYPES.other },
  ];
  const [selectedFolder, setSelectedFolder] = useState(folders[0]);
  const [triggersEdit, setTriggersEdit] = useState(emailTriggers);
  const [statusOptions, setStatusOptions] = useState([]);
  const [nextId, setNextId] = useState(-1);

  useEffect(() => {
    getEmailTriggers();
  }, []);

  useEffect(() => {
    setTriggersEdit(emailTriggers);
  }, [emailTriggers]);

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    statuses && setStatusOptions(getFilteredStatuses(statuses));
  }, [statuses]);

  const getFilteredStatuses = statusGroups => {
    let groups = [...statusGroups];

    if (selectedFolder === EMAIL_TYPES.order) {
      groups = statusGroups.filter(group => group.type === 'PickupOrder' || group.type === 'DeliveryOrder');
    }

    return groups.map(group => group.statuses.map(status => status)).reduceRight((accumulator, currentValue) => accumulator.concat(currentValue));;
  };

  const addTrigger = () => {
    const newTrigger = {
      name: null,
      id: nextId,
      entityType: selectedFolder.name,
      conditions: [],
      emailTemplateId: null,
      isNew: true,
    };

    const newTriggers = [
      ...triggersEdit,
      newTrigger,
    ];

    setTriggersEdit(newTriggers);
    setNextId(nextId - 1);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.mainContent}>
        <EmailFolderManager onFolderSelected={setSelectedFolder} selectedFolder={selectedFolder} folders={folders} />
        <hr className={classes.hr} />
        <div className={classes.toolbar}>
          <div className={classes.header}>
            Automated Email Triggers
          </div>
          <HighlightButton
            onClick={addTrigger}
            aria-label="Add A New Email Trigger"
          >
            <AddIcon />
          </HighlightButton>
        </div>
        <div className={classes.emailTriggerDisplay}>
          {triggersEdit
            .filter(t => t.entityType === selectedFolder.name)
            .map(trigger =>
              <EmailTrigger trigger={trigger} statusOptions={statusOptions} key={trigger.id} />
            )}
        </div>
      </Card>
      <SimpleDialog innerRef={dialog} />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    emailTriggers,
    settings: {
      statuses,
    },
  } = state;

  return {
    emailTriggers: emailTriggers.data,
    statuses,
  };
};

const mapDispatchToProps = {
  getEmailTriggers,
  getStatuses,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmailAutomation));
