import React, { Component } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import TextField from 'Components/TextField';
import Select from 'Components/Select';
import SectionHeader from 'Components/SectionHeader';
import RichTextField from 'Components/RichTextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getNoteTypeKey } from 'constants/noteTypes';

const styles = theme => ({
  addNoteContent: {
    padding: '10px 15px 20px 30px',
    display: 'flex',
  },
  addNoteContainer: {
    margin: 20,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
  },
  notesContainer: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  fullWidth: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    flexGrow: 0,
  },
  pad: {
    paddingTop: 16,
  },
  noteContentColumn1: {
    flexGrow: 1,
  },
  noteContentColumn2: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    justifyContent: 'space-between',
  },
});

class AddEditNoteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: props.note,
      errors: undefined,
    };
  }

  onFieldChange = fieldName => value => {
    const note = {
      ...this.state.note,
      [fieldName]: value,
    };

    this.setState({ note });
  }

  errorsExist = () => {
    const { note } = this.state;
    const subjectNotProvided = note.subject && note.subject.trim().length ? false : true;
    const errors = subjectNotProvided  ? { subject: true } : undefined;

    this.setState({ errors });

    return errors ? true : false;
  }

  onSave = () => {
    if (!this.errorsExist()) {
      this.props.onSave && this.props.onSave(this.state.note);
    }
  }

  render() {
    const { classes, onCancel } = this.props;
    const {
      note,
      errors,
    } = this.state;

    return (
      <Modal
        isOpened={true}
        onSave={this.onSave}
        onCancel={onCancel}
        title={note.id ? "Edit Note" : "Add Note"}
        addTitleBottomBorder={false}
      >
        <SectionHeader>
          Notes
        </SectionHeader>
        <div className={classes.notesContainer} style={this.maxHeight}>
          <div className={classes.addNoteContainer}>
            <div className={classes.addNoteContent}>
              <div className={classes.noteContentColumn1}>
                <TextField
                  label="Subject"
                  value={note.subject}
                  className={classes.fullWidth}
                  onFieldChange={this.onFieldChange('subject')}
                  error={errors && errors.subject}
                />
                <div className={classes.pad}>
                  <RichTextField
                    value={note.body}
                    onFieldChange={this.onFieldChange('body')}
                    config={{
                      zIndex: 1301, //need to specify in modals
                    }}
                  />
                </div>
              </div>
              <div className={classes.noteContentColumn2}>
                <Select
                  label="Note Type"
                  value={getNoteTypeKey(note.noteType)}
                  className={classes.fullWidth}
                  onFieldChange={this.onFieldChange('noteType')}
                >
                  <MenuItem value="general">General</MenuItem>
                  <MenuItem value="internal">Internal</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(AddEditNoteModal);
