import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from 'Components/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  halfWidth: {
    width: '45%',
  },
  contactInput: {
    color: theme.palette.grey[700],
  },
});

class QuickContact extends Component {

  render() {
    const {
      classes,
      contact,
      handleContactFieldChange,
    } = this.props;

    return (
      <div
        className={classes.container}
      >
        <TextField
          label="First Name"
          value={contact.firstName}
          className={classes.halfWidth}
          onFieldChange={handleContactFieldChange('firstName')}
        />
        <TextField
          label="Last Name"
          value={contact.lastName}
          className={classes.halfWidth}
          onFieldChange={handleContactFieldChange('lastName')}
        />
        <TextField
          label="Title"
          value={contact.title}
          className={classes.halfWidth}
          onFieldChange={handleContactFieldChange('title')}
        />
        <TextField
          label="Cell"
          value={contact.phone}
          className={classes.halfWidth}
          onFieldChange={handleContactFieldChange('phone')}
        />
        <TextField
          label="Phone"
          value={contact.phone2}
          className={classes.halfWidth}
          onFieldChange={handleContactFieldChange('phone2')}
        />
        <TextField
          label="Email"
          value={contact.email}
          className={classes.halfWidth}
          onFieldChange={handleContactFieldChange('email')}
        />
      </div>);
  }
}

export default withStyles(styles)(QuickContact);
