import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SimpleDialog from 'Components/SimpleDialog';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DataLoader from 'Components/DataLoader';

const dimensions = {
  width: 350,
  height: 213,
};

const initalState = {
  selectedPrint: undefined,
  loading: false,
};

const allPrints = [
  { name: 'Invoice', report: 'OrderPrint-Print-Invoice.repx' },
  { name: 'Receipt', report: 'OrderPrint-Print-Receipt.repx' },
  { name: 'Ticket', report: 'OrderPrint-Print-Ticket.repx' },
  { name: 'Item Details by Prep Area', report: 'OrderPrint-Print-ItemDetailsByPrepArea.repx' },
  { name: 'Item Details by Staff', report: 'OrderPrint-Print-ItemDetailsByStaff.repx' },
];

const styles = theme => ({
  loader: {
    margin: "auto",
    marginTop: '10px',
  },
});

class OrderPrints extends Component {
  state = initalState;

  notSetupDialog = React.createRef();

  componentDidUpdate = prevProps => {
    const { isOpened } = this.props;

    if (!isOpened && prevProps.isOpened) {
      //closing
      this.setState(initalState);
    }
  }

  onCancel = () => {
    this.props.onCancel();
  }

  onGenerate = () => {
    const {
      selectedPrint,
    } = this.state;

    if (selectedPrint) {
      this.setState({ loading: true });
      this.props.onGenerate(this.state.selectedPrint);
    } else {
      this.notSetupDialog.current.open();
    }
  }

  printChanged = print => {
    if (!print) {
      this.notSetupDialog.current.open();
    }

    this.setState({ selectedPrint: print });
  }

  render() {
    const {
      isOpened,
      classes,
    } = this.props;

    const {
      selectedPrint,
      loading,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        dimensions={dimensions}
        onCancel={this.onCancel}
        onSave={this.onGenerate}
        title="Print Template"
        saveText="Generate"
        addTitleBottomBorder={true}
        isSaveDisabled={!Boolean(selectedPrint) || loading}
      > { loading && <DataLoader className={classes.loader} />}
        { !loading && <Select
          label="Template"
          name="templateId"
          value={selectedPrint}
          onFieldChange={this.printChanged}
        >
          {allPrints.map(print =>
            <MenuItem
              key={print.name}
              value={print.report}
            >
              {print.name}
            </MenuItem>)}

        </Select>}
        <SimpleDialog
          message="You have not yet finalized this custom print."
          innerRef={this.notSetupDialog}
          onlyOkayButton={true}
        />
      </Modal>);
  }
}

export default withStyles(styles)(OrderPrints);
