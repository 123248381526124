import React, { Component } from 'react';
import { connect } from 'react-redux';

class KendoGrid extends Component {

  render() {
    return (
      <h3> Display Profile </h3>
    );
  }
}

const mapDispatchToProps = {

};

export default connect(undefined, mapDispatchToProps)(KendoGrid);
