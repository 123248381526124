import { callApi } from 'shared/CallApi';
import IDeliveryZone from 'models/IDeliveryZone';

export const getDeliveryZones = () => (dispatch: any) =>
  dispatch(callApi('DeliveryZone'))
    .then((results: any) => results.json())
    .catch(console.error);

export const getDeliveryZone = (DeliveryZoneId: number) => (dispatch: any) =>
  dispatch(callApi(`DeliveryZone/${DeliveryZoneId}`))
    .then((results: any) => results.json())
    .catch(console.error);

export const updateDeliveryZone = (DeliveryZone: IDeliveryZone) => (dispatch: any) =>
  dispatch(callApi(`DeliveryZone/${DeliveryZone.id}`, { body: DeliveryZone, method: 'PUT'  }))
    .then((results: any) => results.json())
    .catch(console.error);

export const createDeliveryZone = (DeliveryZone: IDeliveryZone) => (dispatch: any) =>
  dispatch(callApi('DeliveryZone', { body: DeliveryZone, method: 'POST'  }))
    .then((results: any) => results.json())
    .catch(console.error);

export const deleteDeliveryZone = (DeliveryZoneId: number) => (dispatch: any) =>
  dispatch(callApi(`DeliveryZone/${DeliveryZoneId}`, { method: 'DELETE'  }))
    .then((results: any) => results.json())
    .catch(console.error);
