import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import {
  ArrowDropDown as DropDownIcon,
} from '@material-ui/icons';
import { Switch, Route } from 'react-router-dom';
import TopFilter from './TopFilter';
// import MainTopFilter from './main/TopFilter';

const styles = theme => {
  return {
    toolbar: {
      fontSize: 18,
      minHeight: 64,
      paddingLeft: 20,
      paddingRight: 30,
      textTransform: 'none',
      display: 'flex',
      backgroundColor: theme.palette.grey[50],
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    buttonText: {
      fontSize: 18,
      textTransform: 'none',
      verticalAlign: 'baseline',
      marginLeft: 60,
    },
  };
};

class DashboardNav extends Component {
  constructor(props) {
    super(props);
    let dropmenutitle = 'Main';

    if (props.location.pathname === '/home/sales') {
      dropmenutitle = 'Sales';
    } else if (props.location.pathname === '/home/customers') {
      dropmenutitle = 'Customers';
    }
    this.state = {
      open: false,
      dropmenutitle,
    };
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = param => event => {
    this.setState({ dropmenutitle: param });

    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { open, dropmenutitle } = this.state;

    return (
      <div className={classes.toolbar}>
        <div>
          Dashboard
          { false &&  // Quickorders only
          <Button className={classes.buttonText}
            buttonRef={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
          >
            {dropmenutitle} <span><DropDownIcon /> </span>
          </Button>
          }
        </div>
        <div>
          <Switch>
            <Route path="/home/sales" component={TopFilter} />
            <Route path="/home/customers" component={TopFilter} />
            {/* when none of the above match, <NoMatch> will be rendered
            <Route component={MainTopFilter} /> */}
          </Switch>
        </div>
        { false &&  // Quickorders only

        <Popper open={open} anchorEl={this.anchorEl} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    <NavLink to={'/home/main'}>
                      <MenuItem onClick={this.handleClose('Main')}>
                        Main
                      </MenuItem>
                    </NavLink>
                    <NavLink to={'/home/sales'}>
                      <MenuItem onClick={this.handleClose('Sales')}>
                        Sales
                      </MenuItem>
                    </NavLink>
                    <NavLink to={'/home/customers'}>
                      <MenuItem onClick={this.handleClose('Customers')}>
                      Customers
                      </MenuItem>
                    </NavLink>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        }
      </div>
    );
  }
}

DashboardNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(DashboardNav));
