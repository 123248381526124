import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from './TextField';

function CurrencyInput(props) {
  const { inputRef, onChange, minValue, maxValue, ...other } = props;

  const validate = values => {
    if (minValue && values.floatValue < minValue) {
      return false;
    }
    if (maxValue && values.floatValue > maxValue) {
      return false;
    }

    return true;
  };

  return (
    <NumberFormat
      {...other}
      decimalScale={2}
      fixedDecimalScale={true}
      getInputRef={inputRef}
      isAllowed={validate}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value > 0 ? values.value : 0,
          },
        });
      }}
      thousandSeparator={true}
      prefix="$"
    />
  );
}

CurrencyInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

//just a helper function could totally just directly do the below markup instead
export const CurrencyField = props => (
  <TextField
    {...props}
    inputComponent={CurrencyInput} />
);

export const inputProps = {
  inputComponent: CurrencyInput,
};
