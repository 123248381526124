export const NOTE_TYPES = {
  internal: 0,
  general: 1,
};

const noteTypeKeys = Object.keys(NOTE_TYPES).reduce(
  (acc, key) => {
    acc[NOTE_TYPES[key]] = key;

    return acc;
  },
  {}
);

export const getNoteTypeKey = noteType => {
  return noteTypeKeys[noteType] || noteType;
};

