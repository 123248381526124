import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'Components/Select';
import {
  changeMenu,
} from 'actions/storemenu';

const styles = theme => ({
  root: {

  },
  dropdownCell: {
    width: '100%',
  },
  text: {
    color: theme.palette.grey['300'],
    fontSize: '14px',
  },
  fullWidth: {
    width: '200px',
  },
});

export class MenuSelectionDropDown extends Component {
  state = {
    menu: 1,
  };

  handleFieldChange = value => {
    this.setState({ menu: value });
    this.props.changeMenu(value - 1);
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      menu,
    } = this.state;

    return (

      <Select
        label="Menu"
        value={menu}
        className={classes.fullWidth}
        onFieldChange={this.handleFieldChange}
      >
        { this.props.storeMenus.map( (menu,index) =>
          <MenuItem value={index + 1} key={'menuitem-' + index}>{menu.name}</MenuItem>
        ) }
      </Select>
    );
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeMenus,
    },
  } = state;

  return {
    storeMenus,
  };
};

const mapDispatchToProps = {
  changeMenu,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MenuSelectionDropDown));
