import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  MenuItem,
  Button,
} from '@material-ui/core';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { CurrencyField } from 'Components/currencyInput';
import TextField from 'Components/TextField';
import TimeField from 'Components/TimeField';
import Select from 'Components/Select';
import {
  NavigateBefore,
  NavigateNext,
} from '@material-ui/icons';
import ImageUpload from './ImageUpload';
import classNames from 'classnames';
import {
  registerForIntl,
  provideIntlService,
} from '@progress/kendo-react-intl';
import {
  uploadFile,
} from 'actions/file';
import {
  createMenuItem,
  updateMenuItem,
  deleteMenuItem,
  clearSelectedMenuItem,
  archiveMenuItem,
} from 'actions/menu';
import {
  getPickLists,
} from 'actions/pickList';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import RichTextField from 'Components/RichTextField';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import HighlightButton from 'Components/Buttons/HighlightButton';
import SimpleDialog from 'Components/SimpleDialog';
import ModifierSortableList from '../modifierTemplateManagement/ModifierSortableList';
import ModifierTemplateSelectorModal from './ModifierTemplateSelectorModal';
import ModifierEditorModal from './ModifierEditorModal';
import _ from 'lodash';
import {
  startModifierTemplateStack,
} from 'actions/menuItemModifier';
import MultiSelect from 'Components/MultiSelect';
import StringComboBox from 'Components/StringComboBox';
import Rules from './Rules';
import { convertTimeStringToSeconds } from 'helpers/convertTimeStringToSeconds';
import { getLocations } from 'actions/location';

const styles = theme => ({
  modalBody: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto',
  },
  contentHeader: {
    backgroundColor: theme.palette.grey[50],
    fontSize: '17px',
    height: '31px',
    paddingLeft: '25px',
  },
  editModifiersContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: 'right',
  },
  flexGrowWithPad: {
    flexGrow: 1,
    margin: '16px 25px 8px 25px',
  },
  halfWidth: {
    display: 'flex',
    width: '50%',
  },
  quarterWidth: {
    display: 'flex',
    width: '25%',
  },
  buttons: {
    flexGrow: 0,
    fontSize: '13px',
    fontWeight: 100,
    margin: '8px 7px',
    minHeight: '32px',
    padding: '5px 16px',
    textTransform: 'none',
  },
  addNewButton: {
    backgroundColor: theme.palette.grey[200],
    color: theme.common.white,
    maxWidth: '120px',
    '&:hover': {
      color: theme.common.black,
    },
  },
  tagsButton: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  marginWithMarginHr: {
    color: theme.palette.grey[200],
    margin: '4px 0',
  },
  tabBackground: {
    backgroundColor: theme.common.white,
  },
  tagsArea: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    padding: '18px',
    overflowY: 'auto',
  },
  masonry: {
    display: 'grid',
    gridGap: '48px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: '252px 252px auto',
    listStyle: 'none',
    margin: '24px',
  },
  twoColumns: {
    gridColumn: '2 / 4',
    maxHeight: '252px',
  },
  threeColumns: {
    gridColumn: '2 / 5',
  },
  twoRows: {
    gridRow: '2 / 4',
  },
  cellContents: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  fullRow: {
    display: 'flex',
    width: '100%',
  },
  flexRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  contents2x1: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'wrap',
    maxHeight: 220,
    justifyContent: 'space-between',
  },
  contents3x1: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '370px',
  },
  modifierContainer: {
    marginTop: '48px',
  },
  divider: {
    color: theme.common.black,
  },
  arrowIcons: {
    fontSize: 35,
  },
  imageUploadContainer: {
    maxHeight: 250,
    overflow: 'hidden',
  },
  modifierButtons: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    textAlign: 'center',
    width: '100%',
  },
  modifierButton: {
    margin: '10px',
    width: '140px',
    padding: '4px',
  },
  additionalPadding: {
    padding: '0 10px',
  },
  editDescription: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  itemDescription: {
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
});

const tabs = ['Labels', 'Item Notes', 'Rules', 'Nutrition'];
const dimensions = { hasMargin: false, height: '100%', width: '100%' };

class ViewEditItemModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      isModifierTemplateModalOpen: false,
      isModifierEditorModalOpen: false,
      modifierEditorModalTitle: '',
      item: this.emptyItem(),
      locations: [],
    };
    this.froalaConfig = {
      zIndex: props.theme.zIndex.modal + 1,
      placeholderText: 'Enter text here',
      toolbarButtons: ['bold', 'italic', 'underline', 'textColor', 'align'],
    };
  }

  errorModal = React.createRef();

  dialog = React.createRef();

  componentDidMount() {
    this.updateItemRef();
    this.props.getPickLists();

    const locations = this.props.getLocations();

    this.setState({ locations });
  }

  componentDidUpdate(prevProps) {
    const { item: currentItem } = this.state;

    this.updateItemRef();
    if (currentItem && !currentItem.id) { // in add new library item, check if default category or default tax type has changed
      if (prevProps.defaultCategoryId !== this.props.defaultCategoryId) {
        this.setState({
          item: {
            ...currentItem,
            categoryId: this.props.defaultCategoryId,
          },
        });
      }
      if (prevProps.defaultRevenueTypeId !== this.props.defaultRevenueTypeId) {
        this.setState({
          item: {
            ...currentItem,
            revenueTypeId: this.props.defaultRevenueTypeId,
          },
        });
      }
      if (!currentItem.locationId) {
        this.setState({
          item: {
            ...currentItem,
            locationId: this.state.locations[0].id,
          },
        });
      }
    }
    if (prevProps.uploadedFile !== this.props.uploadedFile) {
      this.setState({
        item: {
          ...currentItem,
          imageUrl: this.props.uploadedFile.fileUrl,
        },
      });
    }
  }

  componentWillUnmount() {

  }

  emptyItem = () => {
    return {
      id: undefined,
      name: undefined,
      revenueTypeId: this.props.defaultRevenueTypeId, // default based on category selected
      categoryId: this.props.defaultCategoryId, // default based on category selected
      tags: [],
    };
  }

  updateItemRef() {
    let { item } = this.state;
    const { currentItemId, currentItem } = this.props;

    if (currentItem && (!item || (currentItemId !== item.id || currentItem.lastModifiedUtcDateTime !== item.lastModifiedUtcDateTime))) {

      item = currentItem;
      var preparationTimeStringDateObj = new Date(item.preparationTimeInSeconds * 1000);
      var preparationTimeString = ("0" + preparationTimeStringDateObj.getUTCHours()).slice(-2) + ':' + ("0" + preparationTimeStringDateObj.getUTCMinutes()).slice(-2);

      item.preparationTimeString = preparationTimeString;
      this.setState({
        item,
        isDirtyCheck: item,
        itemTagModalOpened: false,
      });
    }
  }

  fieldChange = (fieldName, parseFunct) => value => {
    this.valueChange(fieldName, parseFunct)(value);
  }

  valueChange = (fieldName, parseFunct) => value => {
    value = parseFunct ? parseFunct(value) : value;
    let item = {
      ...this.state.item
      , [fieldName]: value,
    };

    if (fieldName === 'preparationTimeString') { // preparation time has changed, convert it to seconds
      var seconds = convertTimeStringToSeconds(item.preparationTimeString);

      item.preparationTimeInSeconds = seconds;
    }

    this.setState({ item });
  }

  priceChange = fieldName => value => {
    const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
    // all this sucks.  a number field should be able to handle this.

    if (!floatRegExp.test(value) && value !== '') {
      return '';
    }
    let item = {
      ...this.state.item
      , [fieldName]: value,
    };

    this.setState({ item });
  }

  tabChange = tabIndex => {
    this.setState({ tabIndex });
  };

  initIntlService() {
    if (!this.intlService) {
      this.intlService = provideIntlService(this);
    }
  }

  onItemSaved = () => {
    const { item } = this.state;
    const { createMenuItem, updateMenuItem, closeFunc } = this.props;

    if (item.name && item.revenueTypeId) {

      // all this sucks.  a number field should be able to handle this.
      if (!item.price) item.price = 0;

      item.id === undefined ? createMenuItem(item) : updateMenuItem(item);
      closeFunc();
      this.setState({ item: this.emptyItem() });
    } else {
      this.errorModal.current.open('Name and Revenue Type are required fields');
    }
  }

  onItemDelete = () => {
    const { item } = this.state;

    this.dialog.current.open('Are you sure you want to delete this?').then(() => {
      this.props.deleteMenuItem(item.id).then(() => {
        this.props.closeFunc();
        this.setState({ item: this.emptyItem() });
      }).catch(error => {
        this.dialog.current.open(<span dangerouslySetInnerHTML={{ __html: error.message }}></span>).then(() => {
          this.props.archiveMenuItem(item).then(() => {
            this.props.closeFunc();
            this.setState({ item: this.emptyItem() });
          });
        });
      });
    });
  }

  onItemCancelled = () => {
    this.props.closeFunc();
    this.setState({ item: this.emptyItem() });
  }

  yesClicked = () => {
    if (!this.onUploadItemRef) {
      return;
    }

    this.onUploadItemRef.current.click();
    this.onUploadItemRef.current.value = null;
    this.onUploadItemRef = null;
  }

  onNewItemUpload = async onUploadItemRef => {
    const { item } = this.state;
    const { createMenuItem } = this.props;

    this.onUploadItemRef = onUploadItemRef;

    if (item.name && item.revenueTypeId) {
      return this.dialog.current.open('Menu item will be saved before uploading an image. Continue?')
        .then(async () => {
          const updatedItem = await createMenuItem(item);

          this.setState({ item: updatedItem, isDirtyCheck: updatedItem });

          return updatedItem;
        })
        .catch(() => {
          return null;
        });
    } else {
      this.errorModal.current.open('Menu item will be saved before uploading an image. To continue Name and Revenue Type are required.');
    }

    return null;
  }

  removeImageUpload = () => {
    const newItem = !this.state.item || !this.state.item.id;

    if (newItem) {
      return;
    }

    this.dialog.current.open('Are you sure you want to delete this image?').then(() => {
      this.setState({
        item: {
          ...this.state.item,
          imageUrl: undefined,
        },
      });
    });
  }

  fileChangedHandler = event => {
    if (event.target.files.length) {
      const formData = new FormData();

      formData.append('file', event.target.files[0]);
      this.props.uploadFile('menuItem', this.state.item.id, 'public', formData)
        .then(response => {
          if (response.url) {
            this.setState({
              item: {
                ...this.state.item,
                imageUrl: response.url,
              },
            });
          }
        }).catch(error => {
          this.errorModal.current.open('Image upload failed. Please try saving this item without an image, then try again.');
        });
    }
  }

  isDirty = () => {
    const that = this;

    //TODO:  intent of isDirty() is to enable/disable arrow left/right buttons when data is changed?
    //If so, there is an issue with item.description due to richtextfield adding <p></p> and this function sometimes returning false.
    //Disabling the dirty check for description for now.
    return Object.keys(this.state.isDirtyCheck).some(key => key !== 'description' && key !== 'tags' && that.state.isDirtyCheck[key] !== that.state.item[key]);
  }

  leftButton = () => {
    const isDirty = this.isDirty();

    return (
      <Button onClick={this.props.onPreviousClick} disabled={isDirty}>
        <NavigateBefore className={this.props.classes.arrowIcons} />
      </Button>
    );
  }

  closeItemTags = () => {
    this.setState({ itemTagModalOpened: false, selectedTagIds: [] });
  }

  saveItemTags = tags => {
    const item = {
      ...this.state.item,
      tags,
    };

    this.setState({ item });
  }

  openItemTags = () => {
    const selectedTagIds = this.state.item.tags.map(tag => tag.id);

    this.setState({ itemTagModalOpened: true, selectedTagIds });
  }

  rightButton = () => {
    const isDirty = this.isDirty();

    return (
      <Button onClick={this.props.onNextClick} disabled={isDirty}>
        <NavigateNext className={this.props.classes.arrowIcons} />
      </Button>
    );
  }

  openModifierTemplateModal = () => {
    this.setState({
      ...this.state,
      isModifierTemplateModalOpen: true,
    });
  }

  closeModifierTemplateModal = () => {
    this.setState({
      ...this.state,
      isModifierTemplateModalOpen: false,
    });
  }

  addModifierFromTemplates = modifierTemplates => {
    const { modifiers = [] } = this.state.item;

    this.setState({
      ...this.state,
      isModifierTemplateModalOpen: false,
      item: {
        ...this.state.item,
        modifiers: modifiers.concat(this.convertTemplatesToModfiers(modifierTemplates)),
      },
    });

  }

  convertTemplatesToModfiers = templates => {
    templates.forEach(template => {
      template.menuItemModifierTemplateId = template.id;
      template.id = undefined;
      this.convertTemplatesToModfiers(template.options);
    });

    return templates;
  }

  onModifierEdit = (modifier, index) => {
    this.setState({
      ...this.state,
      modifierInEditIndex: index,
      modifierEditorModalTitle: `Edit ${modifier.name} Modifier`,
      isModifierEditorModalOpen: true,
    });

    this.props.startModifierTemplateStack(modifier);
  }

  onAddNewModifier = () => {
    this.setState({
      ...this.state,
      modifierInEditIndex: undefined,
      modifierEditorModalTitle: 'Add new modifier',
      isModifierEditorModalOpen: true,
    });
    this.props.startModifierTemplateStack({
      rule: 'PickOne',
      style: 'Button',
    });
  }

  closeModifierEditorModal = () => {
    this.setState({
      ...this.state,
      modifierInEdit: undefined,
      modifierInEditIndex: undefined,
      isModifierEditorModalOpen: false,
    });
  }

  saveModifier = savedModifier => {
    const { modifiers = [] } = this.state.item;
    const { modifierInEditIndex } = this.state;

    if (modifierInEditIndex !== undefined) {
      modifiers[modifierInEditIndex] = savedModifier;
    } else {
      modifiers.push(savedModifier);
    }

    this.setState({
      ...this.state,
      isModifierEditorModalOpen: false,
      item: {
        ...this.state.item,
        modifiers: modifiers,
      },
    });
  }

  onModifierDelete = (deletedModifier, deletedIndex) => {
    const { modifiers = [] } = this.state.item;

    if (deletedModifier.id) {
      deletedModifier.recordStatus = "Deleted";
      this.setState({
        ...this.state,
        isModifierTemplateModalOpen: false,
        item: {
          ...this.state.item,
          modifiers: modifiers.map(itemModifier => itemModifier.id === deletedModifier.id ? deletedModifier : itemModifier),
        },
      });
    } else {
      this.setState({
        ...this.state,
        isModifierTemplateModalOpen: false,
        item: {
          ...this.state.item,
          modifiers: modifiers.filter((itemModifier, itemModifierIndex) => itemModifierIndex !== deletedIndex),
        },
      });
    }
  }

  onModifierClone = (item, index) => {
    const newOption = _.cloneDeep(item);

    newOption.name = `${newOption.name} Copy`;

    this.resetOptionsIdRecurse(newOption);

    const { modifiers = [] } = this.state.item;

    modifiers.push(newOption);

    this.setState({
      ...this.state,
      isModifierTemplateModalOpen: false,
      item: {
        ...this.state.item,
        modifiers,
      },
    });
  }

  resetOptionsIdRecurse = modifier => {
    modifier.id = undefined;
    if (modifier.options) {
      modifier.options.forEach(option => {
        this.resetOptionsIdRecurse(option);
      });
    }
  }

  onModifiersSorted = modifiers => {
    this.setState({
      ...this.state,
      item: {
        ...this.state.item,
        modifiers: modifiers.map((modifier, index) => ({ ...modifier, sortOrder: index * 100 })),
      },
    });
  }

  handleSelectionRemove = fieldName => selection => {
    const { item } = this.state;
    const values = item[fieldName].split(', ');
    const indexOfRemovedValue = values.indexOf(selection);

    values.splice(indexOfRemovedValue, 1);

    const commaDelimitedString = values.join(', ');

    this.fieldChange(fieldName)(commaDelimitedString);
  }

  handleQuickAdd = fieldName => value => {
    const { item } = this.state;
    const valueString = item[fieldName].concat(', ', value);

    this.fieldChange(fieldName)(valueString);
  }

  handleQuickAddTag = value => {
    const { item: { tags } } = this.state;
    const newTags = [...tags, value];

    this.fieldChange('tags')(newTags);
  }

  render() {
    const {
      item,
      tabIndex,
      isModifierTemplateModalOpen,
      isModifierEditorModalOpen,
      modifierInEdit,
      modifierEditorModalTitle,
      locations,
    } = this.state;
    let {
      classes,
      theme,
      revenueTypes,
      isModalOpen,
      tags,
      uploading,
      pickListDictionary,
    } = this.props;

    if (!item) {
      //the filled state gets messes up if we render the inputs before item is available
      return null;
    };

    this.initIntlService();

    let modalProps = {
      'aria-labelledby': "Item Library - Item Detail",
      'aria-describedby': "viewing item library",
      dimensions: dimensions,
      addTitleBottomBorder: true,
      isOpened: isModalOpen,
      onCancel: this.onItemCancelled,
      title: "Item Library - Item Detail",
      onSave: this.onItemSaved,
    };

    if (item && item.id) {
      modalProps = {
        ...modalProps,
        onDelete: this.onItemDelete,
        leftItem: this.leftButton(),
        rightItem: this.rightButton(),
      };
    }
    const prepAreaOptions = pickListDictionary && pickListDictionary['Prep Area'] && pickListDictionary['Prep Area'].pickListOptions;
    const storageAreaOptions = pickListDictionary && pickListDictionary['Storage Area'] && pickListDictionary['Storage Area'].pickListOptions;
    const responsibleStaffOptions = pickListDictionary && pickListDictionary['Responsible Staff'] && pickListDictionary['Responsible Staff'].pickListOptions;
    const servicewareOptions = pickListDictionary && pickListDictionary['Serviceware'] && pickListDictionary['Serviceware'].pickListOptions;

    return (
      <Modal
        {...modalProps}
      >
        <div className={classes.modalBody}>
          <div className={classes.masonry}>
            <Paper elevation={0} className={classes.cellContents}>
              <Typography variant="h4" style={{ fontSize: '27px', fontWeight: 'bold' }}>
                {item.name}
              </Typography>
              <Typography style={{ color: theme.palette.secondary.main, fontSize: '27px' }}>
                {this.intlService.formatNumber(!!item.price ? item.price : 0, 'c')}
                <span className={classes.divider}>&nbsp;|&nbsp;</span>
                {(item.revenueTypeId && !!revenueTypes && revenueTypes.length && revenueTypes.find(c => c.id === item.revenueTypeId) && revenueTypes.find(c => c.id === item.revenueTypeId).name) || '(Revenue Type)'}
              </Typography>
              <hr className={classes.marginWithMarginHr} />
              <div className={classes.editDescription}>
                <RichTextField
                  value={item.description}
                  onFieldChange={this.fieldChange('description')}
                  className={classes.itemDescription}
                  config={{
                    placeholderText: "Enter Description Text Here",
                    toolbarButtons: ['bold', 'italic', 'underline', 'textColor', 'align'],
                  }}
                />
              </div>
            </Paper>
            <Paper elevation={1} className={classNames(classes.cellContents, classes.twoColumns)}>
              <Typography variant="subtitle1" className={classes.contentHeader}>Item Details</Typography>
              <div className={classes.contents2x1}>
                <div className={classes.halfWidth}>
                  <TextField
                    label="Item Name"
                    onFieldChange={this.fieldChange('name')}
                    name="name"
                    maxLength={200}
                    value={item.name || ''}
                  />
                </div>
                <div className={classes.halfWidth}>
                  <TextField
                    label="Item ID"
                    name="customerSystemId"
                    maxLength={50}
                    onFieldChange={this.fieldChange('customerSystemId')}
                    value={item.customerSystemId || ''}
                  />
                  <TextField
                    label="PLU Code"
                    name="pluCode"
                    maxLength={50}
                    onFieldChange={this.fieldChange('pluCode')}
                    value={item.pluCode || ''}
                  />
                </div>
                <div className={classes.halfWidth}>
                  <Select
                    label="Location"
                    value={item.locationId || ''}
                    onFieldChange={this.fieldChange('locationId')}
                    name="location"
                  >
                    {locations && locations.map(location =>
                      <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                    )}
                  </Select>
                </div>
                <div className={classNames(classes.halfWidth)}>
                  <MultiSelect
                    label="Item Tags"
                    values={item.tags}
                    dataSource={tags}
                    onFieldChange={this.fieldChange('tags')}
                    onQuickAdd={this.handleQuickAddTag}
                    placeholder="Type or Select..."
                    isClearable={true}
                  />
                </div>
              </div>
            </Paper>
            <Paper elevation={1} className={classes.cellContents}>
              <Typography variant="subtitle1" className={classes.contentHeader}>Item Image</Typography>
              <div className={classNames(classes.imageUploadContainer)}>
                <ImageUpload
                  onFileChanged={this.fileChangedHandler}
                  onRemoveUpload={this.removeImageUpload}
                  imageSrc={item.imageUrl}
                  isLoading={uploading && !item.imageUrl}
                  newItem={!item.id}
                  onNewItemUpload={this.onNewItemUpload}
                />
              </div>
            </Paper>
            <div className={classes.leftColumn}>
              <Paper elevation={1} className={classNames(classes.twoRows)}>
                <Typography variant="subtitle1" className={classes.contentHeader}>Pricing Information</Typography>
                <div className={classes.cellContents}>
                  <CurrencyField
                    label="Item Price"
                    onFieldChange={this.priceChange('price')}
                    name="price"
                    minValue={0}
                    maxValue={99999}
                    value={item.price || ''}
                  />
                  <div className={classes.flexRow}>
                    <div className={classes.halfWidth}>
                      <CurrencyField
                        label="Item Cost"
                        name="cost"
                        minValue={0}
                        maxValue={99999}
                        onFieldChange={this.priceChange('cost')}
                        value={item.cost || ''}
                      />
                    </div>
                    <div className={classes.halfWidth}>
                      <CurrencyField
                        label="Service Charge"
                        name="serviceCharge"
                        minValue={0}
                        maxValue={99999}
                        onFieldChange={this.priceChange('serviceCharge')}
                        value={item.serviceCharge || ''}
                      />
                    </div>
                  </div>
                  <Select
                    label="Revenue Type"
                    value={item.revenueTypeId || ''}
                    onFieldChange={this.fieldChange('revenueTypeId')}
                    name="revenueTypeId"
                  >
                    {revenueTypes && revenueTypes.map(revenueType =>
                      <MenuItem key={revenueType.id} value={revenueType.id}>{revenueType.name}</MenuItem>
                    )}
                  </Select>
                </div>
              </Paper>
              <Paper elevation={1} className={classNames(classes.twoRows, classes.modifierContainer)}>
                <Typography variant="subtitle1" className={classes.contentHeader}>Modifiers</Typography>
                <div className={classes.fullRow}>
                  {item.modifiers && <ModifierSortableList items={item.modifiers.sort((a, b) => parseInt(a.sortOrder, 10) - parseInt(b.sortOrder, 10))}
                    onEdit={this.onModifierEdit}
                    onSorted={this.onModifiersSorted}
                    onDelete={this.onModifierDelete}
                    onClone={this.onModifierClone} />}
                </div>
                <div className={classes.modifierButtons}>
                  <PrimaryButton className={classes.modifierButton} onClick={this.onAddNewModifier}>Add new</PrimaryButton>
                  <HighlightButton className={classes.modifierButton} onClick={this.openModifierTemplateModal}>Add from template</HighlightButton>

                </div>
              </Paper>
            </div>
            <Paper elevation={1} className={classNames(classes.cellContents, classes.threeColumns)}>
              <Typography variant="subtitle1" className={classes.contentHeader}>Kitchen Information</Typography>
              <div className={classes.contents3x1}>
                <div className={classes.flexRow}>
                  <div className={classes.quarterWidth}>
                    <StringComboBox
                      label="Prep Area"
                      value={item.preparationArea}
                      dataSource={prepAreaOptions}
                      isClearable={true}
                      maxLength={50}
                      onChange={this.fieldChange('preparationArea')}
                      onQuickAdd={this.fieldChange('preparationArea')}
                      valueType="string"
                    />
                  </div>
                  <div className={classes.quarterWidth}>
                    <TimeField
                      label="Prep Time"
                      onFieldChange={this.fieldChange('preparationTimeString')}
                      name="preparationTimeString"
                      value={item.preparationTimeString || '00:00'}
                    />
                  </div>
                  <div className={classes.halfWidth}>
                    <StringComboBox
                      label="Storage Area"
                      value={item.storageArea}
                      dataSource={storageAreaOptions}
                      isClearable={true}
                      onChange={this.fieldChange('storageArea')}
                      onQuickAdd={this.fieldChange('storageArea')}
                      valueType="string"
                    />
                  </div>
                  <div className={classes.halfWidth}>
                    <MultiSelect
                      values={item.responsibleStaff}
                      dataSource={responsibleStaffOptions}
                      onFieldChange={this.fieldChange('responsibleStaff')}
                      onRemoveSelection={this.handleSelectionRemove('responsibleStaff')}
                      onQuickAdd={this.handleQuickAdd('responsibleStaff')}
                      label="Responsible Staff"
                      placeholder="Type or Select..."
                      isClearable={true}
                      valueType="string"
                    />
                  </div>
                  <div className={classes.halfWidth}>
                    <MultiSelect
                      values={item.serviceware}
                      dataSource={servicewareOptions}
                      onFieldChange={this.fieldChange('serviceware')}
                      onRemoveSelection={this.handleSelectionRemove('serviceware')}
                      onQuickAdd={this.handleQuickAdd('serviceware')}
                      label="Serviceware"
                      placeholder="Type or Select..."
                      isClearable={true}
                      valueType="string"
                    />
                  </div>
                  <TextField
                    label="Notes"
                    name="notes"
                    maxLength={2000}
                    onFieldChange={this.fieldChange('notes')}
                    value={item.notes || ''}
                  />
                </div>
              </div>
            </Paper>

            <Paper elevation={1} className={classNames(classes.cellContents, classes.threeColumns)}>
              <Typography variant="subtitle1" className={classes.contentHeader}>Additional Information</Typography>
              <div className={classes.tabBackground}>
                <BlueTextTab
                  onTabChange={this.tabChange}
                  tabs={tabs}
                  tabIndex={tabIndex} />
              </div>
              {tabIndex === 0 &&
                <div className={classes.flexGrowWithPad}>
                  <RichTextField
                    value={item.labels}
                    onFieldChange={this.valueChange('labels')}
                    config={this.froalaConfig}
                  />
                </div>
              }
              {tabIndex === 1 &&
                <div className={classes.flexGrowWithPad}>
                  <RichTextField
                    value={item.itemNotes}
                    onFieldChange={this.valueChange('itemNotes')}
                    config={this.froalaConfig}
                  />
                </div>
              }
              {tabIndex === 2 &&
                <div className={classes.additionalPadding}>
                  <Rules
                    menuItem={item}
                    onFieldChange={this.fieldChange}
                  />
                </div>
              }
              {tabIndex === 3 &&
                <div className={classNames(classes.additionalPadding, classes.quarterWidth)}>
                  <TextField
                    label="Calories"
                    name="calories"
                    onFieldChange={this.fieldChange('calories')}
                    value={item.calories || ''}
                    type="number"
                  />
                </div>
              }
            </Paper>
          </div>
        </div>
        <ModifierTemplateSelectorModal
          isOpened={isModifierTemplateModalOpen}
          onModalClosed={this.closeModifierTemplateModal}
          onSave={this.addModifierFromTemplates}
          title={'Add modifier from template'}
        />
        <ModifierEditorModal
          isOpened={isModifierEditorModalOpen}
          onModalClosed={this.closeModifierEditorModal}
          onSave={this.saveModifier}
          modifier={modifierInEdit}
          title={modifierEditorModalTitle}
        />
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.errorModal}
        />
        <SimpleDialog
          innerRef={this.dialog}
          yesClicked={this.yesClicked}
        />
      </Modal >
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    menuItem: {
      currentItem,
      currentItemId,
      tags,
    },
    api: {
      categories,
      revenueTypes,
    },
    file: {
      uploadedFile,
      uploading,
    },
    pickList: {
      pickListDictionary,
    },
    oidc: { user },
  } = state;

  return {
    currentItem,
    currentItemId,
    categories,
    tags: tags.data,
    revenueTypes,
    closeFunc: ownProps.closeFunc,
    uploading,
    uploadedFile,
    pickListDictionary,
    user,
  };
};

const mapDispatchToProps = {
  createMenuItem,
  updateMenuItem,
  deleteMenuItem,
  archiveMenuItem,
  clearSelectedMenuItem,
  startModifierTemplateStack,
  uploadFile,
  getPickLists,
  getLocations,
};

registerForIntl(ViewEditItemModal);

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ViewEditItemModal));
