import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SimpleDialog from 'Components/SimpleDialog';
import CheckBox from 'Components/Checkbox';
import moment from 'moment';
import { Clear as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

const styles = theme => ({
  deleteIcon: {
    color: theme.common.softPink,
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editIcon: {
    color: theme.palette.grey[700],
    verticalAlign: 'top',
    marginLeft: 8,
    marginTop: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

class Deposit extends Component {

  confirmDeleteDialog = React.createRef();

  onEdit = () => {
    const { deposit, onEdit } = this.props;

    onEdit && onEdit(deposit);
  }

  onDelete = () => {
    const { deposit, onDelete } = this.props;

    this.confirmDeleteDialog.current.open()
      .then(() => {
        onDelete && onDelete(deposit.id);
      })
      .catch(() => {/* noop */});
  }

  render() {
    const { classes, deposit, formatter } = this.props;
    const dueDateFormatted = moment(deposit.dueDate).format('MM/DD/YYYY');
    const paidDateFormatted = deposit.payment ? moment(deposit.payment.dateApplied).format('MM/DD/YYYY') : '';
    const formattedPaidAmount = deposit.payment ? formatter.formatNumber(deposit.payment.amount, 'c') : '';

    return (
      <>
        <tr>
          <td>{dueDateFormatted}</td>
          <td>{formatter.formatNumber(deposit.dueAmount, 'c')}</td>
          <td>{deposit.comment}</td>
          <td>
            <CheckBox
              checked={deposit.isPaid}
              disabled={true}
            />
          </td>
          <td>{paidDateFormatted}</td>
          <td>{formattedPaidAmount}</td>
          <td>
            <div>
              <label htmlFor="deposit-edit">
                <EditIcon
                  aria-label={`Edit Deposit`}
                  className={classes.editIcon}
                  onClick={this.onEdit}
                />
              </label>
              {!deposit.isPaid &&  // don't allow deletions for paid deposits
              <label htmlFor="deposit-delete">
                <DeleteIcon
                  aria-label={`Delete Deposit`}
                  className={classes.deleteIcon}
                  onClick={this.onDelete}
                />
              </label>}
            </div>
          </td>
        </tr>
        <SimpleDialog
          message="Are you sure you want to delete this deposit?"
          innerRef={this.confirmDeleteDialog}
        />
      </>
    );
  }
}

export default withStyles(styles)(Deposit);
