import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from 'Components/TextField';
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    display: 'flex',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  half: {
    display: 'flex',
    width: '50%',
  },
  checkbox: {
    marginBottom: -28,
  },
  checkboxGroup: {
    display: 'flex',
    margin: '10px 16px 0',
  },
  checkboxLabel: {
    color: '#9B9B9B',
    marginLeft: -5,
  },
});

export class Rules extends Component {
  state = {
    minQtyError: false,
    defaultQtyError: false,
  };

  validateDecimal = (fieldName, value) => {
    if (!value) return;
    const float = parseFloat(value).toFixed(2);

    if (float === 'NaN') {
      if (fieldName === 'minimumQuantity') this.setState({ minQtyError: true });
      if (fieldName === 'defaultQuantity') this.setState({ defaultQtyError: true });
    } else {
      if (fieldName === 'minimumQuantity') this.setState({ minQtyError: false });
      if (fieldName === 'defaultQuantity') this.setState({ defaultQtyError: false });
      this.props.onFieldChange(fieldName)(float);
    }
  }

  parser = value => {
    const number = parseFloat(value);

    if (value[value.length - 1] === '.') return parseFloat(value.replace('.', '')).toString().concat('.');
    if (!!number && number !== 'NaN') return number;
  }

  checkboxChange = fieldName => event => {
    this.props.onFieldChange(fieldName)(event.currentTarget.checked);
  }

  render() {
    const {
      classes,
      menuItem,
      onFieldChange,
    } = this.props;
    const {
      minQtyError,
      defaultQtyError,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.row}>
            <div className={classes.half}>
              <TextField
                label="Minimum Quantity"
                value={menuItem.minimumQuantity}
                onFieldChange={onFieldChange('minimumQuantity', this.parser)}
                helperText={minQtyError ? 'Must be a number' : null}
                onBlur={() => this.validateDecimal('minimumQuantity', menuItem.minimumQuantity)}
                error={minQtyError}
                className={classes.half}
              />
              <TextField
                label="Default Quantity"
                value={menuItem.defaultQuantity}
                onFieldChange={onFieldChange('defaultQuantity')}
                onBlur={() => this.validateDecimal('defaultQuantity', menuItem.defaultQuantity)}
                helperText={defaultQtyError ? 'Must be a number' : null}
                error={defaultQtyError}
                className={classes.half}
              />
            </div>
            <div className={classes.half}>
              {/* TODO: hidden for BETA */}
              {/* <Select
              label="Rounding"
              value={menuItem.roundingRule}
              onFieldChange={onFieldChange('roundingRule')}
              className={classes.half}
            >
              <MenuItem key={'up'} value={'up'}>Round Up</MenuItem>
              <MenuItem key={'down'} value={'down'}>Round Down</MenuItem>
              <MenuItem key={'none'} value={'none'}>None</MenuItem>
            </Select>
            <Checkbox
              label="Link to Guest Count"
              checked={menuItem.isQuantityLinkedToGuestCount}
              onFieldChange={onFieldChange('isQuantityLinkedToGuestCount')}
              className={classNames(classes.half, classes.checkbox)}
            /> */}
            </div>
          </div>

          <div className={classes.checkboxGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={menuItem.noTax}
                  onChange={this.checkboxChange('noTax')}
                  value="noTax"
                  color="primary"
                  className={classes.checkboxWidth}
                  disableRipple={true}
                />
              }
              label="No Tax"
              classes={{ label: classes.checkboxLabel }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={menuItem.noServiceCharge}
                  onChange={this.checkboxChange('noServiceCharge')}
                  value="noServiceCharge"
                  color="primary"
                  className={classes.checkboxWidth}
                  disableRipple={true}
                />
              }
              label="No Service Charge"
              classes={{ label: classes.checkboxLabel }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={menuItem.noGratuity}
                  onChange={this.checkboxChange('noGratuity')}
                  value="noGratuity"
                  color="primary"
                  className={classes.checkboxWidth}
                  disableRipple={true}
                />
              }
              label="No Gratuity"
              classes={{ label: classes.checkboxLabel }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Rules);
