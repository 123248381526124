import { callApi } from 'shared/CallApi';
import _ from 'lodash';
import { REQUEST_TYPES } from 'constants/requestTypes';
import { FETCH_REVENUE_TYPES_SUCCESS } from 'actions/constants';
import { defaultMenuItemGridColumns } from 'constants/gridColumnDefaults';
import {
  fetchItemsBegin,
  fetchItemsSuccess,
  fetchCategoriesSuccess,
  fetchLibraryItemImageBegin,
  fetchLibraryItemImageSuccess,
  fetchLibraryItemImageFailure,
  fetchItemsEnded,
  fetchTaxTypesBegin,
  itemTagsReceived,
  itemColumnsReceived,
} from './admin';

export const fetchRevenueTypesSuccess = revenueTypes => ({
  type: FETCH_REVENUE_TYPES_SUCCESS,
  revenueTypes,
});
export const getItemsIfNeeded = (params, requestType = REQUEST_TYPES.regular) =>
  (dispatch, getState) => {
    const { admin: { items: { params: oldParams, data } } } = getState();

    const newParams = {
      ...oldParams,
      ...params,
    };

    if (requestType === REQUEST_TYPES.regular && _.isEqual(oldParams, newParams)) {
      return new Promise(resolve => resolve({ data })); //does not return meta
    }

    dispatch(fetchItemsBegin(newParams));

    return dispatch(getItemsApi(newParams)).then(result => {
      dispatch(fetchItemsSuccess(result, newParams));

      return result;
    }).finally(() => dispatch(fetchItemsEnded()));
  };

export function getItem(id) {
  return dispatch => dispatch(callApi(`LibraryItem/${id}`))
    .then(response => response.json());
}

export function saveItem(item) {
  return dispatch => dispatch(callApi(`LibraryItem`, {
    body: item,
    method: 'PUT',
  }))
    .then(() => dispatch(getItemsIfNeeded({}, REQUEST_TYPES.forceCacheUpdate)));
}

export function saveItems(items) {
  return dispatch => dispatch(callApi(`LibraryItem/BatchSave`, {
    body: items,
    method: 'POST',
  }))
    .then(() => dispatch(getItemsIfNeeded({}, REQUEST_TYPES.forceCacheUpdate)));
}

export function addItem(item) {
  return dispatch => dispatch(callApi(`LibraryItem`, {
    body: item,
    method: 'POST',
  }))
    .then(() => dispatch(getItemsIfNeeded({}, REQUEST_TYPES.forceCacheUpdate)));
}

export function deleteItem(id) {
  return dispatch => dispatch(callApi(`libraryitem/${id}`, {
    method: 'DELETE',
  }))
    .then(() => { dispatch(getItemsIfNeeded({}, REQUEST_TYPES.forceCacheUpdate)); });
}

export const getItemsApi = ({ pageSize, skip, sortField = '', sortDirection = '', includeImages, includeTagSummary, filters, categoryId, tags }) =>
  dispatch => {
    const filtersToQueryString = encodeURIComponent(JSON.stringify(filters));
    const tagsToQueryString = encodeURIComponent(JSON.stringify(tags));

    return dispatch(
      callApi(`libraryItem?pageSize=${pageSize}&skip=${skip}&orderBy=${sortField}&direction=${sortDirection}&filters=${filtersToQueryString}&category_id=${categoryId}&tags=${tagsToQueryString}${includeImages ? '&includeImages=true' : ''}${includeTagSummary ? '&includeTagSummary=true' : ''}`))
      .then(response => response.json())
      .catch(console.error);
  };

export const getMenuItemColumns = () => dispatch => {
  return dispatch(callApi('userSetting/item_columns'))
    .then(response => response.json())
    .then(columnString => {
      const columns = columnString && !(columnString.status && columnString.status === 500) ? columnString.split(',') : [];

      dispatch(itemColumnsReceived(columns));
    });
};

export const saveMenuItemColumns = columns => dispatch => {
  const setting = columns.length ? columns.join() : defaultMenuItemGridColumns.default.join();

  dispatch(callApi('userSetting/item_columns', { method: 'PUT', body: setting }))
    .then(response => response.json())
    .then(dispatch(itemColumnsReceived(columns)));
};

export const reorderMenuItemColumns = columns => {
  return dispatch => dispatch(
    callApi('userSetting/item_columns', { method: 'PUT', body: columns.join() }))
    .then(response => response.json());
};

export function getCategories() {
  return dispatch => dispatch(
    callApi('libraryItem/category'))
    .then(response => response.json())
    .then(categories => {
      dispatch(fetchCategoriesSuccess(categories));

      return categories;
    });
}

export function saveCategories(categories) {
  return dispatch => {
    return dispatch(callApi('libraryItem/category', { body: categories }))
      .then(response => response.json())
      .then(updatedCategories => {
        dispatch(fetchCategoriesSuccess(updatedCategories));

        return updatedCategories;
      });
  };
}

export const getTags = () =>
  dispatch => dispatch(callApi('itemtag'))
    .then(response => response.json())
    .then(itemTags => dispatch(itemTagsReceived(itemTags)));

export const getAllTags = () =>
  dispatch => dispatch(callApi('itemtag/all'))
    .then(response => response.json());

export const updateItemTags = itemTags =>
  dispatch => dispatch(callApi('itemtag', { body: itemTags }))
    .then(response => response.json());

export function reorder(categoryId, items) {
  const idAndIndex = items.map((item, index) => {
    return { id: item.id, index };
  });

  return dispatch => dispatch(
    callApi(`libraryitem/reorder/${categoryId ? categoryId : ''}`, { body: idAndIndex }))
    .then(() => dispatch(fetchItemsSuccess({ data: items }, null, true)));
}

export function getItemImage(id) {
  return async dispatch => {
    dispatch(fetchLibraryItemImageBegin());

    return getItemImageApi(dispatch, id)
      .then(response => {
        dispatch(fetchLibraryItemImageSuccess(response));

        return response;
      }).catch(error => {
        dispatch(fetchLibraryItemImageFailure(error));
      });
  };
}

function getItemImageApi(dispatch, id) {
  return dispatch(callApi(`libraryitem/${id}/image`))
    .then(response => response.json());
}

export const getRevenueTypes = () => {
  return dispatch => {
    dispatch(fetchTaxTypesBegin());

    return dispatch(callApi('RevenueType'))
      .then(response => response.json())
      .then(revenueTypes => {
        dispatch(fetchRevenueTypesSuccess(revenueTypes));

        return revenueTypes;
      });
  };
};
