import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';

//note can't use theme here
const styles = () => {
  return {
    container: {
      display: 'flex',
      fontSize: 13,
      flexGrow: 1,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '100%', //it's overflowing the container and hiding the right border
      minHeight: 40,
    },
    padBig: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    padSmall: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
};
//background-color: rgba(229,251,242,0.47);
//background-color: rgba(229,251,242,0.8);

class TimelineEvent extends Component {

  getTimeText(momentStart, momentEnd) {
    const {
      militaryTime,
    } = this.props;

    const startText = militaryTime ? momentStart.format('H:mm') : momentStart.format('h:mm A');
    const endText = militaryTime ? momentEnd.format('H:mm') : momentEnd.format('h:mm A');

    return `${startText} - ${endText}`;
  }

  buildStyle = () => {
    const {
      color,
      event: { isShadow },
      disabledColor,
    } = this.props;

    //lighten(X,1) is white
    //lighten(X,0) is same color
    //a shadow event is an event that shows up on the timeline, but really just denotes the room is used by the event this one "shadows"
    if (isShadow) {
      return {
        color: disabledColor,
        backgroundColor: lighten(color, 0.95), //1 is same color
      };
    }

    //regular event
    return {
      border: '1px solid',
      color,
      borderColor: color,
      backgroundColor: lighten(color, 0.9), //0 is white
    };
  }

  render() {
    const {
      classes,
      event: {
        name,
        data: { syncFusionStart, syncFusionEnd },
      },
    } = this.props;

    const momentStart = moment(syncFusionStart);
    const momentEnd = moment(syncFusionEnd);
    const time = this.getTimeText(momentStart, momentEnd);
    const duration = moment.duration(momentEnd.diff(momentStart));
    const isBig = duration.asMinutes() > 120;
    const style = this.buildStyle();

    return (
      <div className={classNames(classes.container, isBig ? classes.padBig : classes.padSmall)} style={style}>
        <div className={classes.text}>
          {name}
        </div>
        {isBig && <div className={classes.text}>
          {time}
        </div>}
      </div>
    );
  }
}

export default withStyles(styles)(TimelineEvent);
