import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import HighlightButton from 'Components/Buttons/HighlightButton';
import CircleStepper from 'Components/Stepper/CircleStepper';
import classNames from 'classnames';

const styles = theme => ({
  eventsContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: 12,
    minHeight: 55,
    maxHeight: 55,
  },
  wizardName: {
    fontSize: 13,
    paddingTop: 17,
    paddingBottom: 16,
    color: theme.palette.secondary.dark,
  },
  generalDetails: {
    fontSize: 20,
    color: theme.palette.grey[800],
  },
  headerContainer: {
    textAlign: 'center',
    position: 'relative',
  },
  notesButton: {
    position: 'absolute',
    right: 0,
    top: 6,
    marginTop: 20,
    marginRight: 14,
    minWidth: 45,
    width: 102,
  },
  notesButtonOn: {
    color: theme.palette.getContrastText(theme.palette.action.active),
    backgroundColor: theme.palette.action.active,
    '&:hover': {
      backgroundColor: theme.palette.action.active,
    },
  },
  wizardThemeBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18,
    color: theme.common.white,
  },
});

class StepHeader extends Component {
  onClickNotes = () => {
    this.props.onClickNotes();
  }

  header = () => {
    const { classes, title, wizardThemeColor } = this.props;

    return (
    <>
      <div className={classes.wizardThemeBar} style={{ background: wizardThemeColor }}>
        {title}
      </div>
      <div className={classes.wizardName}>
        {title} Wizard
      </div>
    </>
    );
  }

  render() {
    const {
      activeStep,
      steps,
      classes,
      notesWizard,
      bar: Bar,
      onStepClick,
    } = this.props;

    if (!activeStep) {
      return (
        <div className={classes.headerContainer}>
          {this.header()}
          <div className={classes.generalDetails}>
            General Details
          </div>
        </div>);
    }

    const currentStepName = steps[activeStep];
    const isOnNotesPage = notesWizard || currentStepName === 'Notes';

    return (<>
      <div className={classes.headerContainer}>
        {this.header()}
        <CircleStepper steps={steps} activeStep={activeStep} onStepClick={onStepClick} />
        <HighlightButton onClick={this.onClickNotes} className={classNames(classes.notesButton, isOnNotesPage && classes.notesButtonOn)}>
          Notes
        </HighlightButton>
      </div>

      {activeStep > 1 && Bar && !isOnNotesPage && <div className={classes.eventsContainer}>
        <Bar />
      </div>}
    </>);
  };
}

export default withStyles(styles)(StepHeader);
