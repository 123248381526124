import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import AsyncSelect from 'react-select/async';
import {
  NoSsr,
  Paper,
  MenuItem,
  Typography,
  Grid,
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { apiKey } from 'shared/GoogleApi';

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: '16px 16px 8px',
  },
  input: {
    display: 'flex',
    height: 61,
    padding: 0,
    color: theme.palette.grey[700],
    '& div[aria-hidden="true"]': { //Decrease padding around icons to make input size match (height: 32px)
      padding: '6px',
    },
  },
  valueContainer: {
    color: theme.palette.grey[700],
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingTop: 16,
    paddingBottom: 5,
    width: 0,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.grey[200],
  },
  singleValue: {
    fontSize: 16,
    color: theme.palette.grey[700],
    width: '100%',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    color: theme.palette.grey[200],
  },
  menuItem: {
    color: theme.palette.grey[300],
  },
  menu: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    borderRadius: '4px',
  },
  poweredByGoogle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '4px 8px',
  },
  label: {
    color: theme.palette.grey[200],
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: 8,
  },
  container: {
    flexWrap: 'noWrap',
  },
});

const Control = props => {
  return (
    <TextField
      fullWidth={true}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.selectProps.classes.menuItem}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <div
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </div>
  );
}

function SingleValue(props) {
  return (
    <div className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </div>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.menu} {...props.innerProps}>
      {props.children}
      <div className={props.selectProps.classes.poweredByGoogle}>
        <img src="/images/powered_by_google_on_white.png" alt="Powered by Google" />
      </div>
    </Paper>
  );
}

const components = {
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  Control,
};

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');

  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const AddressSearch = forwardRef((props, ref) => {
  const { classes, onSelection, className, label, disabled } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [isReset, setIsReset] = React.useState(false);
  const loaded = React.useRef(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
          document.querySelector('head'),
          'google-maps',
        );
      }

      loaded.current = true;
    }

    return function cleanup() {
      loaded.current = false;
    };
  });

  const reset = () => {
    setInputValue(null);
    setIsReset(true); // clears out input when called (useful in AddressManager)
  };

  const handleInputChange = value => {
    setInputValue(value);
    setIsReset(false);
  };

  const handleSelection = selection => {
    onSelection && onSelection(selection);
    setIsReset(false);
  };

  const fetchPredictions = (request, callback) => {
    autocompleteService.current.getPlacePredictions(request, callback);
  };

  const getPredictions = () => {
    let active = true;

    if ((!autocompleteService.current || !autocompleteService.current.getPlacePredictions) && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    return new Promise(resolve => {
      if (!autocompleteService.current) {
        resolve(undefined);
      }

      if (inputValue === '') {
        resolve([]);
      }
      fetchPredictions({ input: inputValue }, results => {
        if (active) {
          resolve(results);
        }
      });

      return () => {
        active = false;
        resolve(undefined);
      };
    });
  };

  useImperativeHandle(ref, () => {
    return {
      reset: reset,
    };
  });

  return (
    <div className={classNames(className, classes.root)} {...props}>
      <NoSsr>
        <AsyncSelect
          isClearable={true}
          classes={classes}
          inputValue={inputValue || ''}
          components={components}
          onInputChange={handleInputChange}
          onChange={handleSelection}
          loadOptions={getPredictions}
          minimumInput={2}
          isDisabled={disabled}
          placeholder={label || 'Search Address or Place...'}
          backspaceRemoves={true}
          controlShouldRenderValue={!isReset}
          blurInputOnSelect={true}
          formatOptionLabel={option => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map(match => [match.offset, match.offset + match.length]),
            );

            return (
              <Grid container alignItems="center" classes={{ container: classes.container }}>
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs={10}>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </NoSsr>
    </div>
  );
});

export default withStyles(styles)(AddressSearch);
