import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import classNames from 'classnames';
import SelectionBar from 'Components/SelectionBar';
import PackageItemGrid from './PackageitemGrid';
import _ from 'lodash';
import Checkbox from 'Components/Checkbox';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import { getPackages, createMenuFromPackage } from 'actions/eventMenu';
import { provideIntlService } from '@progress/kendo-react-intl';
import BookingContext from 'bookings/BookingContext';

const styles = theme => ({
  flexGrow: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '85%',
    maxHeight: '85%',
    overflow: 'auto',
  },
  headerBlock: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[700]}`,
    height: 60,
    minHeight: 60,
    paddingLeft: 20,
    color: theme.palette.grey[800],
    fontSize: 18,
  },
  bold: {
    fontWeight: 'bold',
  },
  subHeader: {
    fontWeight: 'normal',
    paddingLeft: 50,
  },
  selectAll: {
    opacity: '0.75',
    color: theme.palette.grey[300],
  },
  checkbox: {
    paddingLeft: 3,
  },
  formSection: {
    display: 'flex',
    flexGrow: 1,
  },
  formGroupColumn: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '50%',
  },
  formGroupRow: {
    display: 'flex',
    flexGrow: 1,
  },
  formField: {
    flexGrow: 1,
    marginRight: '5%',
  },
  fieldsRowContainer: {
    display: 'flex',
  },
  label: {
    color: theme.palette.grey[200],
  },
  input: {
    color: theme.palette.grey[300],
  },
  selectionBarContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: 12,
    minHeight: 55,
    maxHeight: 55,
  },
  packageSection: {
    '& .k-header': {
      '& .k-checkbox, & .k-checkbox-label': {
        visibility: 'hidden',
        display: 'none',
        pointerEvents: 'none',
      },
    },
  },
});
const categoryTitle = {
  name: 'PACKAGES',
  id: 0,
};

export class SelectPackagesModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      selectedPackage: categoryTitle,
      isSelectAllItemsChecked: true,
    };
    this.formatter = provideIntlService(this);
  }

  componentDidMount() {
    this.props.getPackages().then(packages => {
      if (!packages.length) {
        return;
      }
      this.setState({ packages });
      this.setPackage(packages[0]);
    });
  }

  setPackage = selectedPackage => {
    this.setState({ selectedPackage, menuSections: undefined });
    if (!selectedPackage.menuId) {
      return;
    }
    // TODO get from store
    // this.props.getMenu(selectedPackage.menuId).then(menu => {
    //   const menuSections = menu.menuSections;//do we need anything else off menu?

    //   if (this.state.isSelectAllItemsChecked) {
    //     menuSections.map(m => m.items).reduce((section1, section2) => section1.concat(section2)).forEach(item => item.selected = true);
    //   }

    //   this.setState({ menuSections }); //should we check that state.selectedPackage is still okay? Like if the user is clicking trough really fast to different packages
    // });
  }

  updateSelected = menuSection => ({ id }) => {
    let newSelectedValue;

    menuSection.items = menuSection.items.map(item => {

      if (item.id === id) {
        newSelectedValue = !item.selected;

        return {
          ...item,
          selected: !item.selected,
        };
      }

      return item;
    });

    const { isSelectAllItemsChecked } = this.state;

    if (newSelectedValue === false && isSelectAllItemsChecked) {
      this.setState({ isSelectAllItemsChecked: false });
    }
    else if (newSelectedValue === true && !isSelectAllItemsChecked && this.areAllItemsSelected()) {
      this.setState({ isSelectAllItemsChecked: true });
    }

    this.forceUpdate();//or we could call setState on state.menuSection
  };

  handleSelectAllChange = event => {
    const { menuSections } = this.state;

    const isSelectAllItemsChecked = event.target.checked;

    for (let i = 0; i < menuSections.length; i++) {
      menuSections[i].items = menuSections[i].items.map(item => ({
        ...item,
        selected: isSelectAllItemsChecked,
      }));
    }

    this.setState({
      menuSections: menuSections,
      isSelectAllItemsChecked,
    });
  }

  areAllItemsSelected = () => {
    for (let i = 0; i < this.state.menuSections.length; i++) {
      if (!this.state.menuSections[i].items.every(item => item.selected)) {
        return false;
      }
    }

    return true;
  }

  addPackageToEvent = () => {
    const { selectedPackage, menuSections } = this.state;
    const { menuId } = this.props;

    this.props.createMenuFromPackage(menuId, selectedPackage, menuSections)
      .then(this.props.onModalClosed)
      .then(this.props.onFinancialDetailsChanged);
  }

  render() {
    const { classes, onModalClosed, isOpened, defaultQuantity } = this.props;
    const {
      packages,
      selectedPackage,
      isSelectAllItemsChecked,
      menuSections,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        onCancel={onModalClosed}
        onSave={this.addPackageToEvent}
        title="Select Packages"
        saveText="Add Selected Package"
        addTitleBottomBorder={true}
        dimensions={{ width: '85%', height: '85%', maxWidth: '1287px', maxHeight: '85%' }}
      >
        <div className={classNames(classes.flexGrow, classes.packages)}>
          <div className={classes.selectionBarContainer}>
            <SelectionBar
              onSelectionChange={this.setPackage}
              leftFixedOptions={[categoryTitle]}
              options={packages}
              selectedOption={selectedPackage}
              showConfig={false}
            />
          </div>
          <div className={classes.headerBlock}>
            <div className={classes.bold}>{_.startCase(selectedPackage.name)}</div>
            <div className={classes.subHeader}>
              {selectedPackage.packagePrice
                ? `${this.formatter.formatNumber(selectedPackage.packagePrice, 'c2')} per person`
                : 'Choose a package to see details'
              }
            </div>
          </div>
          <div className={classes.selectAll}>
            {menuSections &&
              <Checkbox
                label="Select All Items"
                onChange={this.handleSelectAllChange}
                checked={isSelectAllItemsChecked}
                styleMods={{ root: classes.checkbox }}
              />
            }
          </div>
          <div className={classes.flexGrow}>
            {menuSections && menuSections.map(menuSection => (
              <div className={classes.packageSection} key={menuSection.id}>
                <CoverExpansionPanel title={menuSection.name} defaultExpanded={true}>
                  <PackageItemGrid
                    items={menuSection.items}
                    onSelectionChange={this.updateSelected(menuSection)}
                    defaultQuantity={defaultQuantity}
                  />
                </CoverExpansionPanel>
              </div>
            ))}
          </div>
        </div>
      </Modal >);
  }
}

const mapDispatchToProps = {
  getPackages,
  createMenuFromPackage,
};

const SelectPackagesModalWithContext = props => {
  const {
    onFinancialDetailsChanged,
  } = useContext(BookingContext);

  return (<SelectPackagesModal
    {...props}
    onFinancialDetailsChanged={onFinancialDetailsChanged}
  />);
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(SelectPackagesModalWithContext));
