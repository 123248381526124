import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import classNames from 'classnames';

//note can't use theme here
const styles = () => {
  return {
    container: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      fontSize: 13,
      height: 22,
      marginBottom: 3,
      marginLeft: 6,
      marginTop: 3,
      paddingLeft: 3,
    },
    tiny: {
      maxWidth: 131,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    borderBottom: {
      borderBottom: '3px solid',
    },
    borderLeft: {
      borderLeft: '3px solid',
    },
    time: {
      marginRight: 6,
      textAlign: 'right',
      width: 53,
    },
  };
};

//needed for css nested selector to add the bottom line to a multiday event in the weekview
const eventDateClass = 'event-date';

class Event extends Component {

  getTimeText() {
    const {
      event,
      militaryTime,
      isContinued,
    } = this.props;

    if (isContinued) {
      return 'cont.';
    }
    const momentDate = event.data ? moment(event.data.syncFusionStart) : moment(event.syncFusionStart);

    return militaryTime ? momentDate.format('H:mm') : momentDate.format('h:mm a');
  }

  getContainerStyle() {
    const {
      classes,
      event,
      isContinued,
    } = this.props;

    if (isContinued) {
      return classNames(classes.container, classes.borderBottom, eventDateClass);
    }

    return event.data && event.data.count > 1 ? classNames(classes.container, classes.borderBottom, classes.borderLeft, eventDateClass) : classNames(classes.container, classes.borderLeft, eventDateClass);
  }

  render() {
    const {
      classes,
      color,
      timeColor,
      event,
      tiny,
    } = this.props;

    const time = this.getTimeText();
    const containerStyle = this.getContainerStyle();

    return (
      <div className={containerStyle} style={{ borderColor: color || 'black' }}>
        <div className={classes.time} style={{ color: timeColor }}>
          {time}
        </div>
        <div className={tiny && classes.tiny} style={{ color: color }}>
          {event.name}
        </div>
      </div>
    );
  }
}

const EventContainer = withStyles(styles)(Event);

export default EventContainer;
