import React, { Component } from 'react';
import { Card, withStyles, Button } from '@material-ui/core';

const styles = theme => ({
  root: {
    flex: '0 1 auto',
    width: '390px',
    padding: theme.spacing.unit * 2,
    position: 'relative',
    backgroundImage: `linear-gradient(${theme.common.blue}, ${theme.common.blue2})`,
    color: theme.common.white,
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    color: theme.common.white,
  },
  text: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    color: theme.common.white,
  },
  button: {
    backgroundColor: theme.common.white,
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
  },
});

class UnlimitedWidget extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root}>
        <h4 className={classes.title}>
          Get Cover Unlimited
        </h4>
        <div className={classes.text}>You're missing out on some amazing features as well as the ability to host an unlimited # of Events!</div>
        <Button className={classes.button}>Get Cover Unlimited</Button>
      </Card>
    );
  }
}

export default withStyles(styles)(UnlimitedWidget);
