import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import classNames from 'classnames';
import CheckBox from 'Components/Checkbox';
import TextField from 'Components/TextField';
import CoverDatePicker from 'Components/CoverDatePicker';
import {
  getTaxConfig,
  updateTaxConfig,
} from 'actions/booking';
import BookingContext from 'bookings/BookingContext';
import TaxRatesTable from 'Components/TaxRatesTable';

const styles = theme => ({
  container: {
    padding: '13px 26px',
  },
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  sectionLabel: {
    height: 30,
    display: 'flex',
    paddingLeft: 13,
    marginTop: 13,
    alignItems: 'center',
  },
  moveDown: {
    marginTop: 13,
    marginRight: 0,
  },
  exemptContents: {
    display: 'flex',
    paddingTop: 8,
  },
  leftColumn: {
    minWidth: 126,
  },
  rightColumn: {
    flexGrow: 1,
  },
});

const dimensions = {
  width: 376,
  height: 503,
};

class TaxAndServices extends Component {
  state = {
    taxConfig: {
    },
  }

  componentDidUpdate(prevProps) {
    const {
      getTaxConfig,
      isOpened,
      bookingId,
    } = this.props;

    if (isOpened && !prevProps.isOpened) {
      getTaxConfig(bookingId).then(taxConfig => this.setState({ taxConfig }));
    }
  }

  onSave = () => {
    const {
      updateTaxConfig,
      bookingId,
      onClose,
      onFinancialDetailsChanged,
      onChitChatUpdated,
    } = this.props;

    updateTaxConfig(bookingId, this.state.taxConfig).then(() => {
      onClose();
      onFinancialDetailsChanged();
      onChitChatUpdated();
    });
  }

  onCancel = () => {
    this.props.onClose();
  }

  onFieldChange = fieldName => value => {
    const taxConfig = {
      ...this.state.taxConfig,
      [fieldName]: value,
    };

    this.setState({ taxConfig });
  }

  handlePercentageChange = (taxTypeName, fieldName) => value =>  {
    const originalTaxSchedule = { ...this.state.taxConfig };
    const editedItem = originalTaxSchedule.taxConfigTypes.find(t => t.name === taxTypeName);

    editedItem[fieldName] = value;

    const newRevenueDetails = originalTaxSchedule.taxConfigTypes.map(item => item.taxTypeId === editedItem.taxTypeId ? editedItem : item);

    const newTaxSchedule = {
      ...originalTaxSchedule,
      taxConfigTypes: newRevenueDetails,
    };

    this.setState({ taxConfig: newTaxSchedule });
  };

  handleCheckbox = fieldName => event => {
    const value = event.target.checked;

    const taxConfig = {
      ...this.state.taxConfig,
      [fieldName]: value,
    };

    this.setState({ taxConfig });
  }

  render() {
    const {
      isOpened,
      classes,
    } = this.props;

    const {
      taxConfig,
    } = this.state;

    return (<Modal
      isOpened={isOpened}
      dimensions={dimensions}
      onCancel={this.onCancel}
      onSave={this.onSave}
      title="Tax & Service"
      addTitleBottomBorder={true}
    >
      <div className={classes.container}>
        <TaxRatesTable
          onPercentageFieldChange={this.handlePercentageChange}
          taxSchedule={taxConfig}
        />
        <div className={classNames(classes.header, classes.sectionLabel)}>
          Exempt Status
        </div>
        <div className={classes.exemptContents}>
          <div className={classes.leftColumn}>
            <CheckBox
              className={classes.moveDown}
              label="Tax Exempt"
              checked={taxConfig.isTaxExempt}
              onChange={this.handleCheckbox('isTaxExempt')}
            />
          </div>
          <div className={classes.rightColumn}>
            <TextField
              margin="none"
              label="License #"
              value={taxConfig.taxExemptLicenseNumber}
              onFieldChange={this.onFieldChange('taxExemptLicenseNumber')}
            />
            <CoverDatePicker
              label="Expiration"
              showClearButton={false}
              value={taxConfig.taxExemptExpirationDate}
              onFieldChange={this.onFieldChange('taxExemptExpirationDate')}
            />
          </div>
        </div>
      </div>
    </Modal>
    );
  }
}
const mapDispatchToProps = {
  getTaxConfig,
  updateTaxConfig,
};
const taxAndServicesWithContext = props => {
  const {
    onFinancialDetailsChanged,
    onChitChatUpdated,
  } = useContext(BookingContext);

  return (<TaxAndServices
    {...props}
    onFinancialDetailsChanged={onFinancialDetailsChanged}
    onChitChatUpdated={onChitChatUpdated}
  />);
};
const styledTaxAndServicesWithContext = withStyles(styles)(taxAndServicesWithContext);

export { styledTaxAndServicesWithContext as TaxAndServices };
export default connect(undefined, mapDispatchToProps)(styledTaxAndServicesWithContext);
