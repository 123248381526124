import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Button } from '@material-ui/core';
import TextField from 'Components/TextField';
import SaveBar from 'Components/SaveBar';
import RichTextEmailEditorWithToolbar from 'Components/RichTextEmailEditorWithToolbar';
import noop from 'lodash';
import MultiEmailInput from 'Components/MultiEmailInput';
import SimpleDialog from 'Components/SimpleDialog';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import SectionContext from 'Components/Contexts/SectionContext';
import { CHIT_CHAT_TYPES } from 'constants/entityTypes';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  getEmailTemplates,
  sendEmail,
} from 'actions/emailTemplates';
import {
  saveNewChitChatThread,
} from 'actions/chitchat';

const styles = theme => ({
  email: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 75%',
    border: `1px solid ${theme.palette.grey[50]}`,
    marginLeft: 14,
  },
  tabContainer: {
    height: 40,
    minHeight: 40,
    backgroundColor: theme.palette.grey[50],
    borderRadius: '4px 4px 0 0',
  },
  emailComponent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
  },
  toFromContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 16px 32px',
  },
  to: {
    flex: 1,
  },
  cc: {
    width: '50%',
    marginRight: 0,
  },
  bcc: {
    width: '50%',
    marginLeft: 0,
  },
  subject: {
    display: 'flex',
    flex: 1,
  },
  flex: {
    display: 'flex',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  ccButton: {
    alignSelf: 'flex-end',
    width: 60,
    marginRight: 16,
  },
});

class Email extends Component {

  constructor(props) {
    super(props);
    const autoAttachFileId = this.getFileId(props);
    const { defaultEmail } = this.props;

    this.state = {
      email: {
        to: defaultEmail ? [defaultEmail] : [],
      },
      autoAttachFileId,
      isCcVisible: false,
    };
    this.okDialog = React.createRef();
  }

  componentDidMount() {
    this.props.getEmailTemplates();
  }

  componentDidUpdate() {
    const autoAttachFileId = this.getFileId(this.props);

    if (autoAttachFileId && autoAttachFileId !== this.state.autoAttachFileId) {
      this.setState({ autoAttachFileId });
    }
  }

  getFileId = props => {
    if (props.autoAttachFileId) {
      return props.autoAttachFileId;
    }
    if (!props.location || !props.location.search) {
      return undefined;
    }
    const results = props.location.search.match(/fileId=(\d*)/i);

    if (!results || results.length !== 2) {
      return undefined;
    }

    return parseInt(results[1], 10);
  }

  handleTemplate = template => {
    const { email } = this.state;

    email.subject = template.subject;
    this.setState({ email });
  }

  handleChange = fieldName => value => {
    const email = {
      ...this.state.email,
      [fieldName]: value,
    };

    this.setState({ email });
  }

  toggleCC = () => {
    const { isCcVisible, email } = this.state;

    email.cc = [];
    email.bcc = [];

    this.setState({ isCcVisible: !isCcVisible, email });
  }

  sendEmail = () => {
    const {
      email,
    } = this.state;
    const {
      sendEmail,
      onEmailDone,
    } = this.props;

    this.setState({ isSending: true });

    const emailRequest = {
      subject: email.subject,
      content: email.body,
      recipients: this.mapAddressesToRecipient(email.to),
    };

    if (email.cc && email.cc.length) {
      emailRequest.carbonCopyRecipients = this.mapAddressesToRecipient(email.cc);
    }
    if (email.bcc && email.bcc.length) {
      emailRequest.blindCarbonCopyRecipients = this.mapAddressesToRecipient(email.bcc);
    }

    sendEmail(emailRequest).then(() => {
      this.setState({ email: {}, isSending: false });
      this.logChitChat(emailRequest);
      onEmailDone && onEmailDone();
    }).catch(() => {
      this.okDialog.current.open('Email did not send. Please check address and try again.');
      this.setState({ isSending: false });
    });
  }

  mapAddressesToRecipient = emailAddresses => {
    return emailAddresses.map(emailAddress => {
      return { emailAddress };
    });
  }

  logChitChat = email => {
    const { saveNewChitChatThread, relationship, chitChatRecordName, user } = this.props;
    const recipientsString = email.recipients.map(r => r.emailAddress).join(', ');
    const maxLength = 1000;
    const emailContent = email.content.length < maxLength ? email.content : '';
    const chitChatThread = {
      baseMessage: {
        subject: email.subject,
        content: emailContent,
        time: moment().format(),
        recordName: chitChatRecordName,
        from: user.profile.name,
        to: recipientsString,
      },
      chitChatRelationship: relationship.entityType,
      relationshipId: relationship.entityId,
    };

    return saveNewChitChatThread(CHIT_CHAT_TYPES.email, chitChatThread);
  }

  onCancel = () => {
    this.props.onEmailCancel();
    this.setState({ email: {}, isSending: false });
  }

  render() {
    const {
      classes,
      relationship,
    } = this.props;
    const {
      email,
      autoAttachFileId,
      isCcVisible,
      isSending,
    } = this.state;

    return (
      <Paper className={classes.email}>
        <div className={classes.tabContainer}>
          <BlueTextTab
            tabs={['Email']}
            tabIndex={0}
            onTabChange={() => noop}
          />
        </div>
        <div className={classes.emailComponent}>
          <div className={classes.toFromContainer}>
            <div className={classes.flexColumn}>
              <div className={classes.flex}>
                <MultiEmailInput
                  label="To"
                  value={email.to}
                  onChange={this.handleChange('to')}
                  className={classes.to}
                />
                <Button className={classes.ccButton} onClick={this.toggleCC}>CC/BCC</Button>
              </div>

              {isCcVisible &&
                <div className={classes.flex}>
                  <MultiEmailInput
                    label="CC"
                    value={email.cc}
                    onChange={this.handleChange('cc')}
                    className={classes.cc}
                    autoFocus={true}
                  />
                  <MultiEmailInput
                    label="BCC"
                    value={email.bcc}
                    onChange={this.handleChange('bcc')}
                    className={classes.bcc}
                  />
                </div>
              }
            </div>
            <TextField
              label="Subject"
              value={email.subject}
              className={classes.subject}
              onFieldChange={this.handleChange('subject')}
            />
          </div>

          <RichTextEmailEditorWithToolbar
            onFieldChange={this.handleChange('body')}
            config={{ placeholderText: 'Enter Email...' }}
            value={email.body}
            autoAttachFileId={autoAttachFileId}
            relationship={relationship}
            onTemplateSelected={this.handleTemplate}
          />
        </div>
        <SaveBar saveText="Send" onCancel={this.onCancel} onSave={this.sendEmail} isSaveDisabled={isSending} />
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={this.okDialog}
        />
      </Paper>
    );
  }
}

export const EmailWithNoContext = withStyles(styles)(Email);

const mapStateToProps = state => {
  const {
    oidc: { user },
  } = state;

  return { user };
};

const mapDispatchToProps = {
  getEmailTemplates,
  sendEmail,
  saveNewChitChatThread,
};

//do connect here because I want the dispatch prop passed into this component
const EmailWithContext = connect(mapStateToProps, mapDispatchToProps)(props => {
  const {
    relationship,
    onEmailDone,
    autoAttachFileId,
    chitChatRecordName,
    onEmailCancel,
    defaultEmail,
  } = useContext(SectionContext);

  const {
    dispatch,
    ...rest
  } = props;

  return (<EmailWithNoContext
    {...rest}
    relationship={relationship}
    onEmailDone={onEmailDone}
    autoAttachFileId={autoAttachFileId}
    chitChatRecordName={chitChatRecordName}
    onEmailCancel={onEmailCancel}
    defaultEmail={defaultEmail}
  />);
});

export default EmailWithContext;
