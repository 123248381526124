import React, { Component } from 'react';
import Master from './master';
import OauthLogin from './OauthLogin';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  footer: {
    paddingTop: '18px',
    fontSize: '14px',
  },
  signupLink: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.dark, //need this on anchor tags to override lightning design
    },
  },
});

const registerURL = `${window.env.REACT_APP_OAUTH_URL}/Account/Account/Register`;

class Splash extends Component {

  render() {
    const { classes } = this.props;

    return (
      <Master title="Sign In To Cover">
        <OauthLogin />
        <div className={classes.footer}>
          Don't have a login?&nbsp;
          <a className={classes.signupLink} href={registerURL}>Get Started Today!</a>
        </div>
        <div className={classes.footer}>
          <span className={classes.signupLink}>I forgot my password</span>
        </div>
      </Master>
    );
  }
}

export default withStyles(styles)(Splash);
