import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CoverTextField from 'Components/TextField';
import MaterialTextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';

// Note: Most of this is adapted from the Demo: https://material-ui.com/demos/autocomplete/#react-select
// https://react-select.com/home
// Note: There is a new AutoComplete component in Material Design https://material-ui.com/components/autocomplete/. Thought we would need to bump material design to 4.0.0+
const styles = theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
  },
  root: {
    flexGrow: 1,
    margin: '16px 16px 8px',
  },
  input: {
    display: 'flex',
    padding: 0,
    '& div[aria-hidden="true"]': { //Decrease padding around icons to make input size match (height: 32px)
      padding: '6px',
    },
    color: theme.palette.grey[700],
  },
  valueContainer: {
    color: theme.palette.grey[700],
    height: 28,
    paddingTop: 4,
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 100%',
    maxWidth: '95%',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.grey[200],
  },
  singleValue: {
    fontSize: 16,
    color: theme.palette.grey[700],
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    color: theme.palette.grey[200],
  },
  menuItem: {
    color: theme.palette.grey[300],
  },
  menu: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    borderRadius: '4px',
  },
  label: {
    color: theme.palette.grey[200],
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.selectProps.classes.menuItem}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.menu} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

// TODO: Combine with regular ComboBox, get rid of repeated setupComboBoxSuggestions
class StringComboBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      suggestions: [],
      map: {},
    };
  }

  componentDidMount() {
    this.setUpSuggestions(this.props.dataSource);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.setUpSuggestions(this.props.dataSource);
    }
  }

  setUpSuggestions = dataSource => {
    if (dataSource && dataSource.length) {
      const { comboBoxSuggestions, comboBoxMap } = setupComboBoxSuggestions(dataSource);

      this.setState({ suggestions: comboBoxSuggestions, map: comboBoxMap });
    }
  }

  handleSelection = pick => {
    const value = pick ? pick.value.name : '';

    this.props.onChange(value);
  }

  Control = props => {
    if (props.isFocused !== this.state.isFocused) {
      // need to get isFocused from react-select props for shrink-label
      this.setState({ isFocused: props.isFocused });
    }

    return (
      <MaterialTextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    );
  }

  render() {
    const {
      classes,
      value,
      label,
      onQuickAdd,
      disabled,
      error,
    } = this.props;
    const {
      isFocused,
      suggestions,
    } = this.state;
    const selection = { value: value, label: value };

    components.Control = this.Control; // to give component access to Control state

    return (
      <div className={classes.wrapper}>
        {disabled ?
          <CoverTextField
            label={label}
            value={value || ''}
            disabled={true} // easy way to match disabled styling without re-working this whole component
          />
          :
          <div className={classes.root}>
            {onQuickAdd ?
              <CreatableSelect
                {...this.props}
                onChange={this.handleSelection}
                onCreateOption={onQuickAdd}
                options={suggestions}
                components={components}
                value={selection}
                textFieldProps={{
                  label: label,
                  InputLabelProps: {
                    shrink: isFocused || !!value,
                    classes: {
                      root: classes.label,
                    },
                  },
                  error,
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} // keeps menu in right place when in modal
                menuPortalTarget={document.body}
              />
              :
              <Select
                {...this.props}
                onChange={this.handleSelection}
                options={suggestions}
                components={components}
                value={selection}
                textFieldProps={{
                  label: label,
                  InputLabelProps: {
                    shrink: isFocused || !!value,
                    classes: {
                      root: classes.label,
                    },
                  },
                  error,
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} // keeps menu in right place when in modal
                menuPortalTarget={document.body}
              />
            }
          </div>
        }
      </div>

    );
  }
}

export default withStyles(styles)(StringComboBox);
