import { callApi } from 'shared/CallApi';
import { fetchCalendarEventsBegin, calendarEventsReceived, disSchedulerDaySelected } from './api';
import { EVENT_TYPE_FILTER_CLICK, TASK_COMPLETED, UPDATING_CALENDAR_EVENTS } from './constants';
import { defaultSelectedEventTypes } from 'constants/eventTypes';
import ICalendarEvent from 'models/ICalendarEvent';
import EventType from 'models/EntityType';
import moment from 'moment';
import CalendarEventType from 'models/CalendarEventType';

const calendarEventApiUrl: string = 'calendarEvent';

export const updatingCalendarEvent = (updating: boolean) => ({
  type: UPDATING_CALENDAR_EVENTS,
  updating,
});

export const handleEventTypeFilterClick = (eventType: EventType, isChecked: boolean) => ({
  type: EVENT_TYPE_FILTER_CLICK,
  eventType,
  isChecked,
});

export const taskCompleted = (response: ICalendarEvent) => ({
  type: TASK_COMPLETED,
  response,
});

export const getAgenda = ({ startDate, skip, top}: any) => (dispatch: any) => {
  const NextMonthDate = moment(startDate).clone().add(1, 'month');
  const eventTypes = [
    CalendarEventType.Task,
    CalendarEventType.Personal,
    CalendarEventType.Booking,
    CalendarEventType.Proposal
  ]

  return getCalendarAndBookingEventsByDates({ firstDate: startDate, lastDate: NextMonthDate, eventTypes, skip, top })(dispatch);
}

export const getWeekAgenda = ({ startDate, eventTypes }: any) => (dispatch: any) => {
  const NextWeekDate = moment(startDate).clone().add(7, 'days');

  return getCalendarAndBookingEventsByDates({ firstDate: startDate, lastDate: NextWeekDate, eventTypes })(dispatch);
}

export const getCalendarEventsByDates = (params: any) => async (dispatch: any) => {
  dispatch(fetchCalendarEventsBegin());
  const { firstDate, lastDate, eventTypes, skip, top } = params;

  const oDataSkip = `$skip=${skip ? skip : 0}&`;
  const oDataTop = top ? `$top=${top}&` : '';
  const oDataOrderBy = '$orderBy=StartDateTime&';

  const endDateFilter = lastDate ? ` and EndDateTime le ${moment(lastDate).format()}` : '';
  let eventTypesFilter = '';
  if (eventTypes && eventTypes.length) {
    const calendarEventType = 'Cover.Domain.Models.Enums.CalendarEventType';
    eventTypesFilter = ` and (CalendarEventType eq ${calendarEventType}'${eventTypes.join(`' or CalendarEventType eq ${calendarEventType}'`)}')`
  }
  const oDataFilter = `$filter=StartDateTime ge ${moment(firstDate).format()}${endDateFilter}${eventTypesFilter}`;

  const calendarEvents: ICalendarEvent[] = await dispatch(callApi(
    `${calendarEventApiUrl}?${oDataSkip}${oDataTop}${oDataOrderBy}${oDataFilter}`))
      .then((response: any) => response.json());

  const calendarEventsWithFusionProps = addFusionProps(calendarEvents);

  dispatch(calendarEventsReceived(calendarEventsWithFusionProps, params));

  return calendarEventsWithFusionProps;
};

export const getCalendarAndBookingEventsByDates = (params: any) => async (dispatch: any) => {
  dispatch(fetchCalendarEventsBegin());
  const { firstDate, lastDate, eventTypes, skip, top } = params;

  if (!eventTypes || !eventTypes.length) {
    return [];
  }

  const eventTypesString = eventTypes.join('&eventTypes=');

  const calendarAndBookingEvents: ICalendarEvent[] = await dispatch(callApi(
    `${calendarEventApiUrl}/withBookings?firstDate=${moment(firstDate).format()}&lastDate=${moment(lastDate).format()}&eventTypes=${eventTypesString}&skip=${skip ? skip : 0}&top=${top ? top : 0}`))
      .then((response: any) => response.json());

  const calendarEventsWithFusionProps = addFusionProps(calendarAndBookingEvents);

  dispatch(calendarEventsReceived(calendarEventsWithFusionProps, params));

  return calendarEventsWithFusionProps;
};

const flipToExludedEventTypes = (eventTypes: string[] = []) => {
  const excludedEventTypes: string[] = [];

  defaultSelectedEventTypes.forEach(eventType => {
    if (eventTypes.indexOf(eventType) === -1) {
      excludedEventTypes.push(eventType);
    }
  });

  return encodeURIComponent(JSON.stringify(excludedEventTypes));
};

export const getTasks = (skip = 0, pageSize = 30) => {
  const eventTypes = ['Task'];

  return (dispatch: any) => dispatch(callApi(`Events/calendarEvent?excludedEventTypes=${flipToExludedEventTypes(eventTypes)}&excludeCompletedTasks=true&skip=${skip}&pageSize=${pageSize}`))
    .then((result: any) => result.json());
};

export const deleteCalendarEvent = (calendarEventId: number) => (dispatch: any) => {
  dispatch(updatingCalendarEvent(true));
  return dispatch(callApi(`${calendarEventApiUrl}/${calendarEventId}`, { method: 'delete' }))
    .then(() => { dispatch(updatingCalendarEvent(false)) });
}

export const saveCalendarEvent = (calendarEvent: ICalendarEvent) => (dispatch: any) => {
  dispatch(updatingCalendarEvent(true));
  return (calendarEvent.id ? update(calendarEvent)(dispatch) : add(calendarEvent)(dispatch))
    .then(() => { dispatch(updatingCalendarEvent(false)) });
};

export const markTaskCompleted = (eventId: number) => {
  return (dispatch: any ) => dispatch(callApi(`Events/calendarEvent/complete/${eventId}`, { method: 'put' }))
    .then((response: any) => dispatch(taskCompleted(response)));
};

export const onSchedulerDaySelected = (selectedDate: any) => {
  return (dispatch: any) => dispatch(disSchedulerDaySelected(selectedDate));
};

const add = (calendarEvent: ICalendarEvent) => (dispatch: any) => {
  return dispatch(callApi(calendarEventApiUrl, { body: calendarEvent }))
    .then((response: any) => response.json());
}

const update = (calendarEvent: ICalendarEvent) => (dispatch: any) => {
  return dispatch(callApi(`${calendarEventApiUrl}/${calendarEvent.id}`, { method: 'put', body: calendarEvent }))
    .then((response: any) => response.json());
}

const addFusionProps = (calendarEvents: ICalendarEvent[]) => {
  return calendarEvents.map((calendarEvent: ICalendarEvent) => {
    return {
      ...calendarEvent,
      syncFusionStart: new Date(calendarEvent.startDateTime),
      syncFusionEnd: new Date(calendarEvent.endDateTime),
    };
  });
};
