import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import {
  AccountBox as ContactIcon,
  LocalTaxi as DeliveryIcon,
} from '@material-ui/icons';
import StoreIcon from '@material-ui/icons/Store';
import moment from 'moment';
import classNames from 'classnames';
import OrderContext from './OrderContext';

const styles = theme => ({
  sectionTitle: {
    padding: 5,
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    fontSize: '18px',
    backgroundColor: '#F2F2F2',
    paddingLeft: 22,
    height: '35px',
    position: 'sticky',
    top: 0,
  },
  detailsContainer: {
    display: 'flex',
    width: '100%',
    padding: 15,
  },
  clientDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: '14px',
    width: '50%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 57,
  },
  headerLabel: {
    fontSize: '12px',
    color: '#706E6B',
  },
  headerInfo: {
    fontSize: '16px',
    color: '#2B2826',
  },
  container: {
    display: 'flex',
  },
  iconBox: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactsIcon: {
    color: '#2FBFCE',
    fontSize: 42,
    marginLeft: -5,
    marginRight: 5,
  },
  deliveryIcon: {
    backgroundColor: '#532FCE',
    color: theme.palette.background.paper,
    borderRadius: 4,
    padding: 4,
    minWidth: 32,
    minHeight: 32,
    marginRight: 10,
  },
  locationIcon: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.background.paper,
    borderRadius: 4,
    padding: 4,
    minWidth: 32,
    minHeight: 32,
    marginRight: 10,
  },
  contactField: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginBottom: 10,
    flex: 1,
    height: 37,
    minWidth: 70,
    '&:last-child': {
      marginRight: 0,
    },
  },
  contactLabel: {
    fontSize: 12,
    color: '#706E6B',
  },
  contactInfo: {
    fontSize: 13,
  },
  truncate: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  flexStronger: {
    flex: 2,
  },
  pickupReadyTime: {
    alignItems: 'flex-end',
  },
  readyRange: {
    display: 'flex',
  },
});

function QuickFacts ({ classes, storeOrder }) {
  const { orderLocation } = useContext(OrderContext);
  const locationAddress = orderLocation && orderLocation.accountAddress;
  const EstimatedReadyTime = () => {
    return <div className={classes.contactField}>
      <div className={classes.contactLabel}>Estimated Ready Time</div>
      <div className={classes.readyRange}>
        {storeOrder.estimatedReadyTimeMin && <div className={classes.contactInfo}>{moment(storeOrder.estimatedReadyTimeMin).local().format('h:mm A')}&nbsp;&nbsp;-&nbsp;&nbsp;</div>}
        <div className={classes.contactInfo}>{moment(storeOrder.estimatedReadyTimeMax).local().format('h:mm A')}</div>
      </div>
    </div>;
  };

  return (<>
      <div className={classes.sectionTitle}>Quick Facts</div>
      <div className={classes.detailsContainer}>
        <div className={classes.clientDetails}>
          <div className={classes.header}>
            <div className={classes.iconBox}>
              <ContactIcon className={classes.contactsIcon} />
            </div>
            <div>
              <div className={classes.headerLabel}>Contact</div>
              <div className={classes.headerInfo}>
                {storeOrder.customerName || ''}
              </div>
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.contactField}>
              <div className={classes.contactLabel}>Phone</div>
              <div className={classes.contactInfo}> {storeOrder.customerPhone || ''} </div>
            </div>
            <div className={classNames(classes.contactField, classes.flexStronger)}>
              <div className={classes.contactLabel}>Email</div>
              <Tooltip title={storeOrder.customerEmail} enterDelay={800} >
                <div className={classNames(classes.contactInfo, classes.truncate)}> {storeOrder.customerEmail || ''} </div>
              </Tooltip>
            </div>
          </div>
          {!!storeOrder.orderDelivery && storeOrder.type === 'Delivery' &&
            <>
              <div>
                <div className={classes.contactLabel}>Address:</div>
              </div>
              <div>
                <div className={classNames(classes.contactInfo, classes.truncate)}>
                  {storeOrder.orderDelivery.address1} {storeOrder.orderDelivery.address2}&nbsp;
                  {storeOrder.orderDelivery.city}{storeOrder.orderDelivery.city ? ',' : ''} {storeOrder.orderDelivery.stateProvince} {storeOrder.orderDelivery.postalCode}
                </div>
              </div>
            </>
          }
        </div>
        {!!storeOrder.orderDelivery && storeOrder.type === 'Delivery' ?
          <div className={classes.clientDetails}>
            <div className={classes.header}>
              <div className={classes.iconBox}>
                <DeliveryIcon className={classes.deliveryIcon} />
              </div>
              <div>
                <div className={classes.headerLabel}>Delivery Address</div>
                <div className={classes.headerInfo}>
                  {storeOrder.orderDelivery.address1}
                </div>
              </div>
            </div>
            <div className={classes.container}>
              <div className={classNames(classes.contactField, classes.flexStronger)}>
                <div className={classes.contactLabel}>City</div>
                <div className={classNames(classes.contactInfo, classes.truncate)}> {storeOrder.orderDelivery.city} </div>
              </div>
              <div className={classes.contactField}>
                <div className={classes.contactLabel}>State</div>
                <div className={classes.contactInfo}>{storeOrder.orderDelivery.stateProvince}</div>
              </div>
              <div className={classes.contactField}>
                <div className={classes.contactLabel}>Zip Code</div>
                <div className={classes.contactInfo}> {storeOrder.orderDelivery.postalCode} </div>
              </div>
            </div>
            <div className={classes.container}>
              <EstimatedReadyTime />
            </div>
          </div>
          :
          <div className={classes.clientDetails}>
            {orderLocation && locationAddress &&
              <div>
                <div className={classes.header}>
                  <div className={classes.iconBox}>
                    <StoreIcon className={classes.locationIcon} />
                  </div>
                  <div>
                    <div className={classes.headerLabel}>Pickup Address</div>
                    <div className={classes.headerInfo}>
                      {locationAddress.address}
                    </div>
                  </div>
                </div>
                <div className={classes.container}>
                  <div className={classNames(classes.contactField, classes.flexStronger)}>
                    <div className={classes.contactLabel}>City</div>
                    <div className={classNames(classes.contactInfo, classes.truncate)}>{locationAddress.city}</div>
                  </div>
                  <div className={classes.contactField}>
                    <div className={classes.contactLabel}>State</div>
                    <div className={classes.contactInfo}>{locationAddress.state}</div>
                  </div>
                  <div className={classes.contactField}>
                    <div className={classes.contactLabel}>Zip Code</div>
                    <div className={classes.contactInfo}>{locationAddress.postalCode}</div>
                  </div>
                </div>
                <div className={classNames(classes.container, classes.pickupReadyTime)}>
                  <EstimatedReadyTime />
                </div>
              </div>
            }
          </div>
        }
      </div>
    </>);
}

export default withStyles(styles)(QuickFacts);
