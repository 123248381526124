import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SmallGrid from 'Components/Grid/SmallGrid';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import { IconButton } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import { MoveToInbox as DownloadIcon } from '@material-ui/icons';
import classNames from 'classnames';

const styles = theme => ({
  iconCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  iconButton: {
    padding: 0,
    color: theme.palette.primary.main,
  },
  textTruncate: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  linkCell: {
    '&:hover': {
      color: theme.palette.primary.dark,
      textDecoration: 'underline !important',
      cursor: 'pointer',
    },
  },
});

class ProposalGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProposalWizardOpen: false,
    };
  }

  nameModalCell = ({ dataItem }) => {
    const { onNameClick, classes } = this.props;

    return (
      <td>
        <div
          className={classNames(classes.textTruncate, classes.linkCell)}
          onClick={() => onNameClick(dataItem.id)}
        >
          {dataItem.name}
        </div>
      </td>
    );
  };

  render() {
    const {
      classes,
      proposals,
    } = this.props;

    return (
      <SmallGrid data={proposals}>
        <Column
          field="name"
          title="Proposal Name"
          cell={this.nameModalCell}
          editable={false}
        />
        <Column field="startDateTime" title="Date" editable={false} width="100px"
          cell={props => (
            <td>{moment(props.dataItem.startDateTime).format('l')}</td>
          )}
        />
        <Column field="startDateTime" title="Time" editable={false} width="160px"
          cell={props => (
            <td>{moment(props.dataItem.startDateTime).format('LT')} - {moment(props.dataItem.endDateTime).format('LT')}</td>
          )}
        />
        <Column field="associatedContactName" title="Contact Person" editable={false} />
        <Column field="contact.title" title="Contact Title" editable={false} />
        <Column field="contact.phone" title="Contact #" editable={false} />
        <Column field="proposalStatus" title="Status" editable={false}
          cell={props => (
            <td>{_.startCase(props.dataItem.proposalStatus)}</td>
          )}
        />
        <Column field="proposalPdf" title="PDF" editable={false} width="60px"
          cell={props => (
            <td className={classes.iconCell}>
              <IconButton classes={{ root: classes.iconButton }}>
                <DownloadIcon />
              </IconButton>
            </td>
          )}
        />
      </SmallGrid>
    );
  }
}
export default withStyles(styles)(ProposalGrid);
