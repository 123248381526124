import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import Checkbox from 'Components/Checkbox';
import PropTypes from 'prop-types';
import MenuCategoryContext from './MenuCategoryContext';

const styles = theme => ({
  checkboxWidth: {
    width: 182,
  },
  formSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 9,
    width: 450, //this allows for 2 columns of checkboxes - which matches mocks. Remove width to use the full width
  },
  container: {
    overflowX: 'auto',
  },
});

export class BulkModifiersModal extends Component {

  state = {
    sets: [],
  };

  componentDidMount() {
    const sets = [...this.props.sets];

    this.setState({ sets });
  }

  onSave = () => {
    //call API?
    const {
      updateSetsOfModifiers,
      onModalClosed,
    } = this.props;

    updateSetsOfModifiers(this.state.sets).then(onModalClosed);
  }

  checkChanged = (set, modifier) => event => {
    modifier.selected = event.target.checked;

    const sets = [...this.state.sets]; //this is okay that this is not a deep copy because the parent components doesn't used this object at all

    this.setState({ sets });
  }

  render() {
    const { classes, onModalClosed, title } = this.props;
    const { sets } = this.state;

    return (
      <Modal
        isOpened={true}
        onCancel={onModalClosed}
        onSave={this.onSave} // TODO
        title={title || "Select Bulk Modifiers"}
        addTitleBottomBorder={true}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '775px' }}
      >
        <div className={classes.container}>
          {sets.map(set =>
            <CoverExpansionPanel title={set.label} defaultExpanded={true} key={set.setId}>
              <div className={classes.formSection}>
                {set.modifiers.map(modifier => (
                  <Checkbox
                    label={modifier.name}
                    checked={modifier.selected}
                    onChange={this.checkChanged(set, modifier)}
                    className={classes.checkboxWidth}
                    key={modifier.id}
                  />
                ))}
              </div>
            </CoverExpansionPanel>
          )}
        </div>
      </Modal>);

  }
}

BulkModifiersModal.propTypes = {
  sets: PropTypes.array.isRequired,
  onModalClosed: PropTypes.func.isRequired,
  title: PropTypes.string,
};

const BulkModifiersModalContainer = props => {
  const {
    updateSetsOfModifiers,
  } = useContext(MenuCategoryContext);

  return (<BulkModifiersModal
    {...props}
    updateSetsOfModifiers={updateSetsOfModifiers}
  />);
};

export default withStyles(styles)(BulkModifiersModalContainer);
