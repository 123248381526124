import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Splash from './Splash';
import Redirect from './Redirect';
import Portal from 'portal';
import userManager from './userManager';

class Authenticate extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.user && !this.props.user) {
      //user is logging out!
      //removeUser from usermanager removed the user from the OIDC's internal storage
      //Fixes bug where re-logging in will attempt to log in the previous user (using this internal storage) and the new user at the same time
      userManager.removeUser();
    }
  }

  render() {
    const { user } = this.props;

    return (

      <Router>
        <Switch>
          <Route
            exact
            path="/signin-oidc"
          >
            <Redirect />
          </Route>
          <Route
            path="*"
            component={user ? Portal : Splash}
          />
        </Switch>
      </Router>);
  }
}

const mapStateToProps = state => {
  const { user } = state.oidc;

  if (!user || user.expired) {
    return {};
  }

  return { user };
};

export default connect(mapStateToProps)(Authenticate);
