import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  Collapse,
  MenuItem,
  InputAdornment,
  IconButton,
  Menu,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Subject as TemplateIcon,
  FlashOn as ConditionIcon,
  Add as AddIcon,
  Clear as XIcon,
} from '@material-ui/icons';
import SaveBar from 'Components/SaveBar';
import TextField from 'Components/TextField';
import EmailTemplatesModal from 'Components/EmailTemplatesModal';
import Select from 'Components/Select';
import { EMAIL_TYPES } from 'constants/entityTypes';
import {
  saveEmailTrigger,
  createEmailTrigger,
  deleteEmailTrigger,
} from 'actions/emailTriggers';

const styles = theme => ({
  trigger: {
    flex: 1,
    margin: '16px 0',
  },
  saved: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
  },
  tools: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 60,
  },
  text: {
    display: 'flex',
    flex: 1,
    fontSize: 14,
    fontWeight: 500,
    width: 180,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  icon: {
    cursor: 'pointer',
  },
  property: {
    flex: 2,
    marginRight: 16,
  },
  hr: {
    margin: '8px 0',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    display: 'flex',
  },
  conditions: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    fontSize: 16,
    marginTop: 32,
  },
  triggerConditionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
  },
  triggerCondition: {
    display: 'flex',
    alignItems: 'baseline',
  },
  addButton: {
    marginLeft: 16,
  },
  saveBar: {
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    height: 50,
  },
  conditionProperty: {
    marginRight: 16,
  },
  statusSelect: {
    maxWidth: 275,
    flex: 2,
  },
  deleteCondition: {
    alignSelf: 'flex-end',
    margin: '0 16px',
  },
  and: {
    marginBottom: '-16px',
  },
});

const EmailTrigger = ({
  classes,
  trigger,
  statusOptions,
  saveEmailTrigger,
  createEmailTrigger,
  deleteEmailTrigger,
  emailTemplates,
}) => {
  const entityTypes = Object.values(EMAIL_TYPES);
  const [optionsOpenedBy, setOptionsOpenedBy] = useState(null);
  const [isExpanded, setIsExpanded] = useState(trigger.isNew);
  const [isTemplatesModalOpened, setIsTemplatesModalOpened] = useState(false);
  const [triggerEdit, setTriggerEdit] = useState(trigger);
  const [triggerSaved, setTriggerSaved] = useState(trigger);
  const [nextId, setNextId] = useState(-1);
  const templateSaved = emailTemplates.find(t => t.id === triggerSaved.emailTemplateId);
  const templateEdit = emailTemplates.find(t => t.id === triggerEdit.emailTemplateId);

  useEffect(() => {
    setTriggerEdit(trigger);
  }, [trigger]);

  useEffect(() => {
    setTriggerSaved(trigger);
  }, [trigger]);

  const onSave = () => {
    if (triggerEdit.id > 0) {
      saveEmailTrigger(triggerEdit).then(trigger => {
        if (trigger.id) setTriggerSaved(trigger);
      // else show error dialog TODO
      });
    } else {
      createEmailTrigger(triggerEdit).then(trigger => {
        if (trigger.id) setTriggerSaved(trigger);
      // else show error dialog TODO
      });
    }
    setIsExpanded(false);
  };

  const onCancelEdit = () => {
    setTriggerEdit(trigger);
    setIsExpanded(false);
  };

  const handleFieldChange = fieldName => value => {
    const updatedtriggerEdit = {
      ...triggerEdit,
      [fieldName]: value,
    };

    setTriggerEdit(updatedtriggerEdit);
  };

  const handleConditionChange = (triggerConditionId, value) => {
    const updatedConditions = triggerEdit.conditions.map(condition => {
      return condition.id === triggerConditionId ? { ...condition, value } : condition;
    });

    const updatedtriggerEdit = {
      ...triggerEdit,
      conditions: updatedConditions,
    };

    setTriggerEdit(updatedtriggerEdit);
    setOptionsOpenedBy(null);
  };

  const setTemplate = value => {
    if (value) {
      const updatedtriggerEdit = {
        ...triggerEdit,
        emailTemplateId: value.id,
      };

      setTriggerEdit(updatedtriggerEdit);
    }
    setIsTemplatesModalOpened(false);
  };

  const isSaveDisabled = () => {
    return !triggerEdit.emailTemplateId;
  };

  const handleDelete = () => {
    // tODO: delete temps
    return deleteEmailTrigger(triggerSaved);
  };

  const getStatusName = triggerCondition => {
    const status = statusOptions.find(status => status.id === parseInt(triggerCondition.value, 10));

    if (status) {
      return `${status.type} - ${status.name}`;
    }
  };

  const addTriggerCondition = () => {
    const newTriggerCondtion = {
      id: nextId,
      field: 'OrderStatusId', // TODO: field will have more options
      recordStatus: 'Active',
    };

    const newTriggerEdit = {
      ...triggerEdit,
      conditions: [
        ...triggerEdit.conditions,
        newTriggerCondtion,
      ],
    };

    setTriggerEdit(newTriggerEdit);
    setNextId(nextId - 1);
  };

  const deleteTriggerCondition = triggerConditionId => {
    const updatedConditions = triggerEdit.conditions.map(condition => {
      return condition.id === triggerConditionId ? { ...condition, recordStatus: 'Deleted' } : condition;
    });

    const newTriggerEdit = {
      ...triggerEdit,
      conditions: updatedConditions,
    };

    setTriggerEdit(newTriggerEdit);
  };

  const openOptionsMenu = event => {
    setOptionsOpenedBy(event.currentTarget);
  };

  return (
    <Card className={classes.trigger}>
      <div className={classes.saved}>
        <div className={classes.text}>
          <span className={classes.property}>{triggerSaved.name}</span>
          <span className={classes.property}>Conditions: {triggerSaved.conditions.length}</span>
          <span className={classes.property}>Template: {templateSaved && templateSaved.name}</span>
        </div>
        <div className={classes.tools}>
          <EditIcon className={classes.icon} onClick={() => setIsExpanded(!isExpanded)} />
          <DeleteIcon className={classes.icon} onClick={() => handleDelete()} />
        </div>
      </div>

      <Collapse in={isExpanded} unmountOnExit>
        <hr className={classes.hr} />
        <div className={classes.form}>
          <div className={classes.top}>
            <TextField
              label="Name"
              value={triggerEdit.name}
              onFieldChange={handleFieldChange('name')}
            />
            <Select
              label="Type"
              value={triggerEdit.entityType}
              onFieldChange={handleFieldChange('entityType')}
              name="entityType"
            >
              {entityTypes.map(entityType => <MenuItem key={entityType} value={entityType}>{entityType}</MenuItem>)}
            </Select>

            <TextField
              label="Email Template"
              value={templateEdit && templateEdit.name}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setIsTemplatesModalOpened(!isTemplatesModalOpened)}>
                    <TemplateIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div className={classes.conditions}>
            <span>Send this e-mail when:</span>
            {triggerEdit.conditions.filter(condition => condition.recordStatus === 'Active').map((condition, index) =>
              <div key={condition.id} className={classes.triggerConditionWrapper}>
                {index > 0 && <span className={classes.and}>and&nbsp;</span>}
                <div className={classes.triggerCondition}>
                  <span className={classes.conditionProperty}>{triggerEdit.entityType} Status is changed to: </span>
                  <TextField
                    label="Status"
                    className={classes.statusSelect}
                    value={getStatusName(condition)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={openOptionsMenu}>
                          <ConditionIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Menu
                    id="status-menu"
                    anchorEl={optionsOpenedBy}
                    open={Boolean(optionsOpenedBy)}
                    onClose={() => setOptionsOpenedBy(null)}
                  >
                    {statusOptions.map(status => <MenuItem key={status.id} value={status.id} onClick={() => handleConditionChange(condition.id, status.id)}>{`${status.type} - ${status.name}`}</MenuItem>)}
                  </Menu>
                  <IconButton className={classes.deleteCondition} onClick={() => deleteTriggerCondition(condition.id)}>
                    <XIcon />
                  </IconButton>
                </div>
              </div>
            )}
            <div className={classes.addButton}>
              <IconButton onClick={addTriggerCondition}>
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <SaveBar className={classes.saveBar} onSave={onSave} onCancel={onCancelEdit} isSticky={true} isSaveDisabled={isSaveDisabled()} />
      </Collapse>
      {isTemplatesModalOpened &&
      <EmailTemplatesModal
        isOpened={true}
        onUseTemplate={setTemplate}
      />}
    </Card>

  );
};

const mapStateToProps = state => {
  const {
    emailTemplates,
  } = state;

  return {
    emailTemplates: emailTemplates.data,
  };
};
const mapDispatchToProps = {
  saveEmailTrigger,
  createEmailTrigger,
  deleteEmailTrigger,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmailTrigger));
