import React, { Component } from 'react';
import SiteSelection from '../SiteSelection';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  sites: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
});

class BookingWizardSites extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.sites}>
        <SiteSelection classes={{ gridContainer: classes.gridContainer }} storeType="wizard" />
      </div>
    );
  };
}

export default withStyles(styles)(BookingWizardSites);
