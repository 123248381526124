import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import MenuManagement from './MenuManagement';
import { Route } from 'react-router-dom';
import { provideIntlService } from '@progress/kendo-react-intl';

class MenuManagementContainer extends Component {
  formatter = provideIntlService(this);

  render() {
    return (
      <Switch>
        <Route exact path="/admin/menu" component={() => <MenuManagement formatter={this.formatter} />} />
      </Switch>
    );
  }
}

export default withRouter(MenuManagementContainer);
