import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Map from 'Components/Map';
import { Tooltip } from '@material-ui/core';
import AddressFields from 'Components/AddressFields';
import AddressSearch from 'Components/AddressSearch';
import SimpleDialog from 'Components/SimpleDialog';
import Checkbox from 'Components/Checkbox';
import classNames from 'classnames';
import { handleAddressSearchDone } from 'shared/GoogleApi';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { selectedAddressFieldIsChanged, workingAddressesSelectAddress } from 'actions/address';

const styles = theme => ({
  addressManager: {
    display: 'flex',
    flexGrow: 1,
    maxHeight: 375,
  },
  addressAndMap: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  addressFields: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: 300,
  },
  fullWidth: {
    display: 'flex',
    width: '100%',
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    minWidth: 275,
    margin: '16px 0 8px 25px',
  },
  listContainer: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  selectedAddress: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  primaryAddress: {
    fontWeight: 500,
  },
  addressName: {
    padding: '6px 8px 6px 16px',
    cursor: 'pointer',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  addressNames: {
    fontSize: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 200,
    paddingTop: 8,
    borderRight: `2px solid ${theme.palette.grey['A200']}`,
  },
  buttonDivider: {
    borderRight: `2px solid ${theme.palette.grey['A200']}`,
    alignSelf: 'center',
    height: 15,
  },
  bottomButton: {
    padding: 6,
    color: theme.palette.primary.dark,
    cursor: 'pointer',
  },
  bottomButtonDisabled: {
    color: theme.palette.grey['A200'],
    cursor: 'auto',
    pointerEvents: 'none',
  },
  bottomButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderTop: `1px solid ${theme.palette.grey['A200']}`,
  },
  belowMap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '-6px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  select: {
    display: 'flex',
    flex: 1,
    marginLeft: -16,
  },
  addressSearchDisabled: {
    display: 'flex',
    cursor: 'default',
    margin: '42px 16px 24px',
    color: theme.palette.grey[200],
    '&:hover $doubleClick': {
      visibility: 'visible',
    },
  },
  doubleClick: {
    visibility: 'hidden',
    marginLeft: 16,
  },
  fieldIsEdited: {
    background: theme.palette.error.light,
  },
});

class AddressManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAddress: null,
      addressParts: {},
    };
    this.mapContainer = React.createRef();
    this.addressSearchRef = React.createRef();
    this.dialog = React.createRef();
    this.okDialog = React.createRef();
  }

  componentDidMount() {
    this.selectActiveAddress();
  }

  componentDidUpdate(prevProps) {
    const { parentId, selectedAddressId, addresses } = this.props;

    if (prevProps.parentId !== parentId) {
      this.selectPrimaryAddress();
    }
    if (prevProps.selectedAddressId !== selectedAddressId) {
      this.selectActiveAddress();
    }
    if (prevProps.addresses !== addresses) {
      this.selectActiveAddress();
    }
  }

  selectPrimaryAddress = () => {
    const { addresses, workingAddressesSelectAddress } = this.props;
    const selectedAddress = addresses && addresses.length > 0 ? addresses.find(address => address.primary) || addresses[0] : null;

    if (selectedAddress && selectedAddress.id) {
      this.setState({ selectedAddress });
      workingAddressesSelectAddress(selectedAddress.id);
    }
  }

  selectActiveAddress = () => {
    const { addresses, selectedAddressId, workingAddressesSelectAddress } = this.props;
    const selectedAddress = addresses && addresses.length > 0 ? addresses.find(a => a.id === selectedAddressId) : null;

    if (selectedAddressId && selectedAddress) {
      this.setState({ selectedAddress });
      workingAddressesSelectAddress(selectedAddress.id);
    } else {
      this.selectPrimaryAddress();
    }
  }

  selectEntityAddress = clickedAddress => {
    const { addresses, workingAddressesSelectAddress } = this.props;
    const selectedAddress = addresses.find(o => o.id === clickedAddress.id);

    this.resetAddressSearchInput();
    if (selectedAddress && selectedAddress.id) {
      this.setState({ selectedAddress });
      workingAddressesSelectAddress(selectedAddress.id);
    }
  }

  resetAddressSearchInput = () => {
    this.addressSearchRef && this.addressSearchRef.reset && this.addressSearchRef.reset();
  }

  onFieldChange = fieldName => value => {
    const { selectedAddress: { id } } = this.state;

    this.props.onAddressChange(id, fieldName, value);
  }

  isFieldChanged = fieldName => {
    const { selectedAddressFieldIsChanged, isEditing } = this.props;

    return isEditing && selectedAddressFieldIsChanged(fieldName);
  }

  onAddressSearchDone = selection => {
    const { selectedAddress } = this.state;
    const hasAddress = !!selectedAddress && Object.keys(selectedAddress).some(fieldKey => fieldKey !== 'id' && !!selectedAddress[fieldKey]);

    if (!!selection && hasAddress) {
      const { dialog } = this;

      dialog && dialog.current && dialog.current
        .open('Do you want to replace the existing address?')
        .then(() => {
          handleAddressSearchDone(selection, this.onFieldChange);
        });
    } else {
      handleAddressSearchDone(selection, this.onFieldChange); // Google Service will use local onFieldChange after parsing results
    }
  }

  onDeleteAddress = () => {
    const { selectedAddress } = this.state;
    const { addresses } = this.props;
    const isOnlyAddress = addresses.length === 1;

    if (selectedAddress.primary || isOnlyAddress) {
      const message = `Cannot delete the primary address! Please ${isOnlyAddress ? 'add' : 'choose'} another address to be the primary before you delete this one.`;

      this.okDialog.current.open(message);
    } else {
      this.dialog.current.open(`Are you sure you want to delete ${selectedAddress.address1 || 'this address'}?`)
        .then(() => this.props.onDeleteAddress(selectedAddress.id));
    }
  }

  isNullOrWhitespace = string => {
    if (typeof string === 'undefined' || string == null) return true;

    return string.replace(/\s/g, '').length < 1;
  }

  getFriendlyAddressName = address => {
    if (!this.isNullOrWhitespace(address.address1)) return address.address1;
    if (!this.isNullOrWhitespace(address.city)) return address.city;
    if (!this.isNullOrWhitespace(address.stateProvince)) return address.stateProvince;

    return 'New Address';
  }

  sortPrimaryToTop = addresses => {
    return addresses.reduce((acc, address) => {
      if (address.primary === true) {
        return [address, ...acc];
      }

      return [...acc, address];
    }, []);
  }

  handlePrimaryCheckbox = checked => {
    const { selectedAddress } = this.state;

    if (checked) {
      this.props.onHandlePrimaryAddress(selectedAddress.id, checked);
    } else {
      this.okDialog.current.open('To change, please make another address the primary.');
    }
  }

  onNewAddress = () => {
    this.resetAddressSearchInput();
    this.props.onNewAddress();
  }

  render() {
    const { classes, isEditing, addresses } = this.props;
    const { selectedAddress } = this.state;
    const addressesLoaded = !!addresses && addresses.length > 0;

    return (
      <div className={classes.addressManager}>
        <div className={classes.addressNames}>
          <div className={classes.listContainer}>
            <ul>
              {addressesLoaded && this.sortPrimaryToTop(addresses).map(address => (
                <li
                  className={
                    classNames(
                      classes.addressName,
                      address.primary && classes.primaryAddress,
                      selectedAddress && address.id === selectedAddress.id && classes.selectedAddress)
                  }
                  onClick={() => this.selectEntityAddress(address)}
                  key={address.id}
                >
                  <span>{this.getFriendlyAddressName(address)}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.bottomButtons}>
            <div className={classes.bottomButton} onClick={this.onNewAddress}>Add New</div>
            <div className={classes.buttonDivider}></div>
            <div className={classNames(classes.bottomButton, !addressesLoaded && classes.bottomButtonDisabled)} onClick={this.onDeleteAddress}>Delete</div>
          </div>
        </div>
        {addressesLoaded && selectedAddress &&
          <div className={classes.addressAndMap}>
            <div className={classes.addressFields}>
              <div className={classes.fullWidth}>
                {isEditing ?
                  <AddressSearch
                    innerRef={node => this.addressSearchRef = node}
                    onSelection={this.onAddressSearchDone}
                  />
                  :
                  <Tooltip title="Double-click to edit" placement="top">
                    <div className={classes.addressSearchDisabled}>
                      Search Address or Place...
                    </div>
                  </Tooltip>
                }
              </div>
              <AddressFields
                address={selectedAddress}
                onFieldChange={this.onFieldChange}
                isFieldChanged={this.isFieldChanged}
                isEditing={isEditing}
              />
            </div>
            <div className={classes.mapContainer} ref={this.mapContainer}>
              <Map
                zoom={10}
                locations={addresses}
                selectedLocation={selectedAddress}
              />
              <div className={classes.belowMap}>
                <div className={classNames(classes.select, this.isFieldChanged('type') && classes.fieldIsEdited)}>
                  <Select
                    label="Type"
                    name="type"
                    value={selectedAddress.type}
                    onFieldChange={this.onFieldChange('type')}
                    disabled={!isEditing}
                  >
                    <MenuItem value="Home">Home</MenuItem>
                    <MenuItem value="Work">Work</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </div>
                <div className={classNames(classes.checkbox, this.isFieldChanged('primary') && classes.fieldIsEdited)}>
                  <Checkbox
                    label="Primary"
                    value="Primary"
                    checked={selectedAddress && !!selectedAddress.primary}
                    onFieldChange={this.handlePrimaryCheckbox}
                    disabled={!isEditing}
                  />
                </div>
              </div>
            </div>
          </div>
        }
        <SimpleDialog innerRef={this.dialog} />
        <SimpleDialog onlyOkayButton={true} innerRef={this.okDialog} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  selectedAddressFieldIsChanged,
  workingAddressesSelectAddress,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(AddressManager));
