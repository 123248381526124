import React, { Component } from 'react';
import { getRelationships } from 'actions/proposal';
import { connect } from 'react-redux';
import ComboBox from 'Components/ComboBox';
import NewLeadModal from 'leads/NewLeadModal';

class RelatedToComboBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relationshipsMap: {},
      isNewLeadModalOpen: false,
      newLead: {},
    };
    this.relationshipFieldNames = {
      'Lead': 'leadId',
      'Contact': 'contactId',
      'Account': 'accountId',
    };
  }

  componentDidMount() {
    this.props.getRelationships();
    this.setupSuggestions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.proposalRelationships !== this.props.proposalRelationships) {
      this.setupSuggestions();
    }
  }

  setupSuggestions = () => {
    const { proposalRelationships } = this.props;

    if (proposalRelationships) {
      const comboBoxMap = {};
      let comboBoxSuggestions = [];

      comboBoxSuggestions = proposalRelationships.map(item => ({
        value: item,
        label: item.name,
      }));

      proposalRelationships.forEach(item => {
        comboBoxMap[item.id] = item.name;
      });

      this.setState({ relationshipsSuggestions: comboBoxSuggestions, relationshipsMap: comboBoxMap });
    }
  }

  onSelect = pick => {
    const key = pick && pick.value.proposalRelationshipType;
    const fieldName = this.relationshipFieldNames[key];
    const promise = new Promise(resolve => {
      this.props.handleChange('proposalRelationshipId')(pick && pick.value.compositeKey);
      resolve();
    });

    promise.then(() => {
      this.props.handleChange(fieldName)(pick && pick.value.id);
    });
  }

  onQuickAddLead = value => {
    this.setState({ isNewLeadModalOpen: true, newLead: { name: value } });
  }

  closeNewLeadModal = newLeadId => {
    if (newLeadId) {
      this.updateFromQuickAdd(newLeadId);
    }

    this.setState({ isNewLeadModalOpen: false, newLead: null });
  }

  updateFromQuickAdd = ({ newLeadId, newAccountId }) => {
    let newRelatedToPick = null;

    if (!!newLeadId) {
      newRelatedToPick = {
        value: {
          proposalRelationshipType: 'Lead',
          compositeKey: `Lead-${newLeadId}`,
          id: newLeadId,
        },
      };
    } else if (!!newAccountId) {
      newRelatedToPick = {
        value: {
          proposalRelationshipType: 'Account',
          compositeKey: `Account-${newAccountId}`,
          id: newAccountId,
        },
      };
    }

    this.onSelect(newRelatedToPick);
  }

  render() {
    const {
      value,
    } = this.props;
    const {
      relationshipsSuggestions,
      relationshipsMap,
      isNewLeadModalOpen,
      newLead,
    } = this.state;

    return (
      <>
      <ComboBox
        label="Related To"
        value={relationshipsMap[value]}
        suggestions={relationshipsSuggestions}
        isClearable={true}
        isCreatable={true}
        handleChange={this.onSelect}
        handleQuickAdd={this.onQuickAddLead}
        disabled={true}
      />
      {isNewLeadModalOpen && <NewLeadModal onClose={this.closeNewLeadModal} newLead={newLead} isOpened={true} />}
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      proposalRelationships,
    },
  } = state;

  return {
    proposalRelationships,
  };
};

const mapDispatchToProps = {
  getRelationships,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedToComboBox);
