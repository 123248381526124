import React, { Component } from 'react';
import { Card, withStyles, LinearProgress } from '@material-ui/core';
import Table from 'Components/Table';

const styles = theme => ({
  card: {
    flexGrow: 1,
    marginTop: 30,
  },
  cardheading: {
    fontSize: 20,
    fontWeight: 'normal',
    padding: 15,
    paddingBottom: 30,
  },
  headingRow: {
    height: 44,
    color: theme.palette.grey['200'],
    fontSize: 12,
    fontWeight: 'normal',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  dataRow: {
    height: 70,
  },

  contentColumn: {
    display: 'flex',
    height: 70,
    alignItems: 'center',
  },
  graphContainer: {
    flexGrow: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },

});
const referralColumn = {
  width: 127,
  paddingLeft: 30,
  paddingRight: 30,
};

class ReferralSourcesGridWidget extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.card}>
        <div className={classes.cardheading}>Referral Sources</div>
        <Table
          header={<tr className={classes.headingRow}>
            <th style={referralColumn}>Referral</th>
            <th>Value</th>
          </tr>
          }>
          <tr>
            <td className={classes.tabletd} style={referralColumn}>Instagram</td>
            <td className={classes.contentColumn}>
              <div>
                $3,550
              </div>
              <div className={classes.graphContainer}>
                <LinearProgress variant="determinate" value={70} />
              </div>
            </td>
          </tr>
        </Table>
      </Card>
    );
  }
}

export default withStyles(styles)(ReferralSourcesGridWidget);
