import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';
import CircleStep from './CircleStep';

const styles = theme => ({
  circleStepper: {
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'center',
    height: 66,
    padding: '0 16px',
  },
  connectorRoot: {
    flex: 'unset',
  },
  line: {
    width: 72,
    marginTop: -15,
    borderColor: theme.palette.grey[100],
    borderTopWidth: 2,
  },
  lineActive: {
    '& $line': {
      borderColor: theme.palette.primary.dark,
      borderTopStyle: 'dashed',
    },
  },
  lineComplete: {
    '& $line': {
      borderColor: theme.common.emerald,
    },
  },
});

class CircleStepper extends Component {

  getIsActive = step => {
    return step === this.props.activeStep;
  }

  getIsComplete = step => {
    return step < this.props.activeStep;
  }

  render() {
    const { classes, activeStep, steps, onStepClick } = this.props;

    return (
      <Stepper
        classes={{
          root: classes.circleStepper,
        }}
        activeStep={activeStep}
        connector={
          <StepConnector
            active={this.getIsActive(this.props.index)}
            completed={this.getIsComplete(this.props.index)}
            classes={{
              root: classes.connectorRoot,
              line: classes.line,
              active: classes.lineActive,
              completed: classes.lineComplete,
              disabled: '',
            }}
          />}
      >
        {steps.map((label, index) => {
          return (
            <CircleStep
              key={label}
              label={label}
              stepIndex={index}
              isActive={this.getIsActive(index)}
              isComplete={this.getIsComplete(index)}
              onStepClick={onStepClick}
            />
          );
        })}
      </Stepper>
    );
  }
};

export default withStyles(styles)(CircleStepper);
