import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
} from '@material-ui/core';
import Checkbox from 'Components/Checkbox';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import { provideIntlService } from '@progress/kendo-react-intl';
import _ from 'lodash';

const styles = theme => ({
  formItemSection: {
    display: 'flex',
    flexFlow: 'wrap',
    marginLeft: 9,
    padding: '5px',
    width: '100%',
  },
  multiSelectItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginRight: 20,
  },
  checkboxWidth: {
    width: '220px',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
  },
  selectedOptionButton: {
    border: '1px solid',
    borderRadius: '4px',
    fontSize: '13px',
    minHeight: '30px',
    padding: '0 13px',
    minWidth: 'unset',
    textTransform: 'initial',
    background: theme.palette.secondary.contrastText,
    borderColor: '#2189F8',
    height: '25px',
  },
  optionButton: {
    border: '1px solid',
    borderRadius: '4px',
    fontSize: '13px',
    minHeight: '32px',
    padding: '0 13px',
    minWidth: 'unset',
    textTransform: 'initial',
    background: theme.palette.secondary.dark,
    borderColor: theme.palette.grey[100],
    color: theme.palette.secondary.contrastText,
  },
  selectSection: {
    padding: 8,
  },
  outlineInput: {
    padding: '5px',
    border: '1px solid #2189F8',
    borderRadius: 4,
    fontSize: '13px',
    '&:focus': {
      outline: 'none',
      border: '1px solid #2189F8',
    },
  },
  select: {
    width: '100%',
  },
});

class Modifier extends Component {
  constructor(props) {
    super(props);

    this.formatter = provideIntlService(this);
  }

  validateStackRule = modifierStack => {
    const { modifiersState } = this.props;

    let currentLevel = modifierStack.length - 1;
    let valid = true;

    const parentModifier = modifierStack[currentLevel - 1];

    if (!parentModifier || (currentLevel - 1 !== 0 && modifiersState[parentModifier.id])) {
      return true;
    }

    modifierStack.pop();

    if ((parentModifier && parentModifier.rule === 'PickMany' && parentModifier.maximumQuantity > 0
      && parentModifier.options.filter(option => modifiersState[option.id]).length === parentModifier.maximumQuantity)
      || (modifierStack.length > 1 && !this.validateStackRule(modifierStack))) {
      valid = false;
    }

    return valid;
  }

  checkChanged = modifierStack => checked => {
    const { modifiersState } = this.props;
    const modifier = modifierStack[modifierStack.length - 1];

    const parentModifier = modifierStack.length > 1 ? modifierStack[modifierStack.length - 2] : undefined;

    // Validate the rule
    if (checked && !this.validateStackRule(_.cloneDeep(modifierStack))) {
      return;
    }

    if (!checked && modifier.options && modifier.options.length > 0) {
      modifier.options.forEach(option => {
        modifiersState[option.id] = false;
      });
    }

    modifiersState[modifier.id] = checked;

    if (parentModifier && parentModifier.options.some(option => modifiersState[option.id])) {
      modifiersState[parentModifier.id] = true;
    }

    this.props.updateModifierState(modifiersState);
  }

  buttonChanged = modifierStack => event => {
    const { modifiersState } = this.props;

    const modifier = modifierStack[modifierStack.length - 1];
    const parentModifier = modifierStack.length > 1 ? modifierStack[modifierStack.length - 2] : undefined;

    // Validate the rule
    if (!modifiersState[modifier.id] && !this.validateStackRule(_.cloneDeep(modifierStack))) {
      return;
    }

    modifiersState[modifier.id] = !modifiersState[modifier.id];
    if (parentModifier.rule === 'PickOne' && modifiersState[modifier.id]) {
      parentModifier.options.forEach(option => {
        if (option.id !== modifier.id) {
          modifiersState[option.id] = false;
        }
      });
    }

    if (parentModifier.options.some(option => modifiersState[option.id])) {
      modifiersState[parentModifier.id] = true;
    }

    this.props.updateModifierState(modifiersState);
  }

  selectChange = modifierStack => event => {
    const { modifiersState } = this.props;
    const modifierId = event.target.value;

    const parentModifier = modifierStack[modifierStack.length - 1];

    if (modifierId) {
      modifierStack.push(parentModifier.options.find(option => option.id === modifierId));
    }

    // Validate the rule
    if (!modifierId || !this.validateStackRule(_.cloneDeep(modifierStack))) {
      return;
    }

    // reset other selection
    if (parentModifier.options) {
      parentModifier.options.forEach(option => {
        modifiersState[option.id] = false;
      });
    }

    modifiersState[modifierId] = true;
    modifiersState[parentModifier.id] = true;

    this.props.updateModifierState(modifiersState);
  }

  formatModifierName = modifier => {
    if (modifier.price > 0) {
      return `${modifier.name} (+${this.formatter.formatNumber(modifier.price, 'c')})`;
    }

    return modifier.name;
  }

  generateRuleText = modifier => {
    if (modifier.rule === 'PickMany' && modifier.options && modifier.options.length > 0) {
      switch (modifier.maximumQuantity) {
        case 1:
          return ' (pick any 1 option)';
        case undefined:
          return '';
        case null:
          return '';
        default:
          return ` (pick any ${modifier.maximumQuantity} options)`;
      }
    }

    return '';
  }

  renderOptions = modifierStack => {
    const {
      classes,
      modifiersState,
    } = this.props;

    const modifier = modifierStack[modifierStack.length - 1];

    console.log(modifierStack);
    console.log(modifier);

    if (!modifier)
      return;

    const pickOneSelectedOption = modifier.rule === "PickOne" && modifiersState && modifier.options && modifier.options.find(option => modifiersState[option.id]);

    return (<>
      {modifier.rule === 'PickOne' && modifier.style === 'Dropdown' && modifier.options && modifier.options.length > 0 &&
        <FormControl component="fieldset" className={classes.formItemSection}>
          <div className={classes.selectSection}>
            <Select className={classes.select}
              value={pickOneSelectedOption && pickOneSelectedOption.id}
              onClick={this.selectChange(modifierStack)}
              key={modifier.id}
              input={<OutlinedInput classes={{ input: classes.outlineInput }} notched={false} labelWidth={50} />}
            >
              {modifier.options.filter(option => !option.disabled).map((option, index) => (
                <MenuItem value={option.id} key={index} >{this.formatModifierName(option)}
                </MenuItem>
              ))}
            </Select>
          </div>
          {pickOneSelectedOption && pickOneSelectedOption.options && this.renderOptions(modifierStack.concat(pickOneSelectedOption))}
        </FormControl>
      }
      {modifier && modifier.rule === "PickMany" &&
        <div className={classes.formItemSection}>
          {modifier.options && modifier.options.filter(option => !option.disabled).map((modifierOption, index) => (
            <div className={classes.multiSelectItem} key={index}>
              <Checkbox
                label={`${this.formatModifierName(modifierOption)} ${this.generateRuleText(modifierOption)}`}
                checked={modifiersState[modifierOption.id]}
                onFieldChange={this.checkChanged(modifierStack.concat(modifierOption))}
                className={classes.checkboxWidth}
                key={modifierOption.id}
              />
              {
                this.renderOptions(modifierStack.concat(modifierOption))
              }
            </div>
          ))}
        </div>
      }
      {modifier.rule === "PickOne" && modifier.style === 'Button' &&
        <div className={classes.multiSelectItem}>
          {modifier.options &&
            modifier.options.filter(option => !option.disabled).map((option, index) => (
              <FormControl key={option.id} variant='outlined' className={classes.formControl}>
                <Button onClick={this.buttonChanged(modifierStack.concat(option))} className={modifiersState[option.id] ? classes.optionButton : classes.selectedOptionButton} >
                  {this.formatModifierName(option)}
                </Button>
              </FormControl>
            ))
          }
          {pickOneSelectedOption && pickOneSelectedOption.options && this.renderOptions(modifierStack.concat(pickOneSelectedOption))}
        </div>
      }
    </>);
  }

  render() {
    const {
      modifier,
    } = this.props;

    return (<>
      {modifier &&
        <div>
          <CoverExpansionPanel title={`${modifier.name} ${this.generateRuleText(modifier)}`} defaultExpanded={false} key={modifier.id}>
            {this.renderOptions([modifier])}
          </CoverExpansionPanel>
        </div>}
    </>);
  }
}

export default withStyles(styles)(Modifier);
