import {
  RECEIVE_LEAD,
  DELETE_LEAD,
  CLONE_LEAD,
  LEAD_NOTES_RECEIVED,
  LEAD_NOTE_ADDED,
  LEAD_NOTE_UPDATED,
  LEAD_NOTE_DELETED,
} from './constants';
import { REQUEST_TYPES } from 'constants/requestTypes';
import { defaultLeadsGridColumns } from 'constants/gridColumnDefaults';
import { callApi } from 'shared/CallApi';
import {
  fetchLeadsBegin,
  leadsReceived,
  leadColumnsReceived,
  fetchProposalsBegin,
  proposalsReceived,
} from './api';
import {
  getStatuses,
} from './settings';

export const leadReceived = lead => ({
  type: RECEIVE_LEAD,
  lead,
});

export const leadDeleted = response => ({
  type: DELETE_LEAD,
  response,
});

export const leadCloned = response => ({
  type: CLONE_LEAD,
  response,
});

export const notesReceived = response => ({
  type: LEAD_NOTES_RECEIVED,
  response,
});

export const noteAdded = response => ({
  type: LEAD_NOTE_ADDED,
  response,
});

export const noteUpdated = response => ({
  type: LEAD_NOTE_UPDATED,
  response,
});

export const noteDeleted = response => ({
  type: LEAD_NOTE_DELETED,
  response,
});

const getProposalsApi = (dispatch, leadId) => {
  let okay = true;
  let status = 0;
  let statusText;

  return dispatch(callApi(`lead/${leadId}/proposals`))
    .then(result => {
      if (!result.ok) {
        status = result.status;
        statusText = result.statusText;
        okay = false;
      }

      return result.json();
    })
    .then(json => {
      if (okay) {
        return json;
      }
      if (json.message) {
        throw Error(json.message);
      }
      if (statusText || status) {
        throw Error(`${status} - ${statusText}`);
      }
      throw Error('unknown');
    });
};

export const getLeads = (params, append = false) => {
  return dispatch => {
    dispatch(fetchLeadsBegin(params));

    return getLeadsApi(dispatch, params).then(result => {
      dispatch(leadsReceived(result, append));

      return result;
    });
  };
};

const getLeadsApi = (dispatch, params) => {
  return dispatch(callApi(`lead?${params}`))
    .then(result => result.json())
    .catch(console.error);
};

export const saveLeads = leads => {
  let okay = true;
  let status = 0;
  let statusText;

  return dispatch => dispatch(callApi(`lead/BatchSave`, {
    body: leads,
    method: 'POST',
  }))
    .then(result => {
      if (!result.ok) {
        status = result.status;
        statusText = result.statusText;
        okay = false;
      }

      return result;
    })
    .then(json => {
      if (okay) {
        return json;
      }
      if (json.message) {
        throw Error(json.message);
      }
      if (statusText || status) {
        throw Error(`${status} - ${statusText}`);
      }
      throw Error('unknown');
    })
    .then(() => {
      dispatch(getLeads({}, REQUEST_TYPES.forceCacheUpdate));
    });
};

export const getLead = leadId => {
  return dispatch => dispatch(callApi(`lead/${leadId}`))
    .then(result => result.json())
    .then(json => {
      dispatch(leadReceived(json));

      return json;
    });
};

export const saveLead = lead =>
  dispatch => dispatch(callApi(`lead/${lead.id}`, { method: 'PUT', body: lead }))
    .then(response => response.json());

export const addLead = lead => (dispatch, getState) => {
  const { settings: { statuses } } = getState();

  if (statuses) {
    const initialStatus = statuses.find(statusGroup => statusGroup.type === 'Lead')
      .statuses.find(status => status.name === 'Prospecting'); //TODO: change to point to a systemStatusCode?

    if (initialStatus) lead.statusId = initialStatus.id;

    return dispatch(callApi('lead', { body: lead }))
      .then(response => response.json());
  } else {
    return dispatch(getStatuses());
  }
};

export const deleteLead = leadId =>
  dispatch => dispatch(callApi(`lead/${leadId}`, { method: 'DELETE' }))
    .then(response => {
      dispatch(leadDeleted(response));
    });

export function cloneLead(lead) {
  return dispatch => dispatch(callApi(`lead/${lead.id}/clone`, { body: lead }))
    .then(result => result.json())
    .then(json => {
      dispatch(leadReceived(json));

      return json;
    });
}

export function getLeadColumns() {
  return dispatch => dispatch(callApi(`userSetting/lead_columns`))
    .then(response => response.json())
    .then(columnString => {
      const columnList = columnString ? columnString.split(',') : [];

      dispatch(leadColumnsReceived(columnList));

      return columnList;
    })
    .catch(error => {
      console.error(error);
    });
}

export const saveLeadColumns = columns => dispatch => {
  const setting = columns.length ? columns.join() : defaultLeadsGridColumns.default.join();

  dispatch(callApi('userSetting/lead_columns', { method: 'PUT', body: setting }))
    .then(response => response.json())
    .then(dispatch(leadColumnsReceived(columns)));
};

export const reorderLeadColumns = columns => {
  return dispatch => dispatch(
    callApi('userSetting/lead_columns', { method: 'PUT', body: columns.join() }))
    .then(response => response.json());
};

export const getProposals = leadId => {
  return dispatch => {
    dispatch(fetchProposalsBegin(leadId));

    return getProposalsApi(dispatch, leadId).then(json => {
      dispatch(proposalsReceived(json));

      return json;
    });
  };
};
