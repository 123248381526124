import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import WhiteButton from './WhiteButton';
import { ArrowDropDown } from '@material-ui/icons';
import classNames from 'classnames';

const styles = theme => ({
  arrowDown: {
    color: theme.palette.common.black,
    padding: '0 !important',
    minWidth: 0,
    minHeight: 26,
  },
  noBorder: {
    border: 'none',
  },
});

// For use with Menus, usually wrapped in a MiniButtonSet
class ArrowDownButton extends Component {
  render() {
    const { classes, isBorderless, onClick, className, ...rest } = this.props;

    return (
      <WhiteButton
        {...rest}
        onClick={onClick}
        className={classNames(className, classes.arrowDown, isBorderless && classes.noBorder, className)}
      >
        <ArrowDropDown />
      </WhiteButton>
    );
  }
}

export default withStyles(styles)(ArrowDownButton);
