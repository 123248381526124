import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Company from './company/';
import Defaults from './defaults/';
import Display from './display/';
import CustomNames from './customNames/';
import ItemManagementContainer from './itemManagement';
import MenuManagementContainer from './menumanagement';
import ModifierTemplateManagement from './modifierTemplateManagement';
import Email from './email/';
import Preferences from './preferences/';
import Prints from './prints/';
import Profile from './profile/';
import Settings from './settings/';
import { bindActionCreators } from 'redux';
import { getCompany } from 'actions/company';
import EmailTemplateEditor from './email/EmailTemplateEditor';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '24px 24px 10px',
  },
});

class Admin extends Component {
  componentDidMount() {
    this.props.getCompany();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainContent}>
        <Switch>
          <Route path='/admin/profile' component={Profile} />
          <Route path='/admin/company' component={Company} />
          <Route path='/admin/defaults' component={Defaults} />
          <Route path='/admin/custom-names' component={CustomNames} />
          <Route path='/admin/modifier-templates' component={ModifierTemplateManagement} />
          <Route path='/admin/prints' component={Prints} />
          <Route path='/admin/email' component={Email} />
          <Route exact path="/admin/email-templates/:id" component={EmailTemplateEditor} />
          <Route exact path="/admin/email-templates/:newTemplateType/new" component={EmailTemplateEditor} />
          <Route path='/admin/display' component={Display} />
          <Route path='/admin/settings' component={Settings} />
          <Route path='/admin/preferences' component={Preferences} />
          <Route path='/admin/items' component={ItemManagementContainer} />
          <Route path='/admin/menu' component={MenuManagementContainer} />
          <Route component={ItemManagementContainer} />
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators({ getCompany }, dispatch);

  return actions;
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(Admin));
