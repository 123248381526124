
import { LocalizationProvider } from '@progress/kendo-react-intl';
import CustomLocalizationService from './CustomLocalizationService';

class CustomLocalizationProvider extends LocalizationProvider {
  getLocalizationService() {
    return new CustomLocalizationService(this.props.language);
  }
}

export default CustomLocalizationProvider;
