import {
  SET_WIDGET_GRAPH_TITLE,
  SET_WIDGET_GRAPH_DATA_SET,
  SET_WIDGET_GRAPH_TYPE,
  SET_WIDGET_GRAPH_COLOR,
  SET_WIDGET_GRAPH_COMPARISON,
  SET_WIDGET_GRAPH_LABEL,
  SET_WIDGET_GRAPH_SETTINGS,
} from 'actions/constants';
import { oneDimensionalWidgetGraphType, twoDimensionalWidgetGraphType, widgetGraphs, widgetGraphUnits } from 'helpers/enums';

const initialState = {
  [widgetGraphs.itemLibraryProducts]: {
    id: widgetGraphs.itemLibraryProducts,
    graphTypeOptions: oneDimensionalWidgetGraphType,
    graphType: oneDimensionalWidgetGraphType.progress,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total Item Library Count',
    numeratorUnits: widgetGraphUnits.none,
    denominatorUnits: widgetGraphUnits.none,
    differenceUnits: widgetGraphUnits.percent,
    title: '# of Products',
  },
  [widgetGraphs.itemLibraryPrice]: {
    id: widgetGraphs.itemLibraryPrice,
    graphTypeOptions: oneDimensionalWidgetGraphType,
    graphType: oneDimensionalWidgetGraphType.donut,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total Price Item Library',
    numeratorUnits: widgetGraphUnits.dollars,
    denominatorUnits: widgetGraphUnits.dollars,
    differenceUnits: widgetGraphUnits.dollars,
    title: 'Category Price',
  },
  [widgetGraphs.itemLibraryCost]: {
    id: widgetGraphs.itemLibraryCost,
    graphTypeOptions: twoDimensionalWidgetGraphType,
    graphType: twoDimensionalWidgetGraphType.bar,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'MTD Cost Increase',
    numeratorUnits: widgetGraphUnits.dollars,
    denominatorUnits: widgetGraphUnits.dollars,
    differenceUnits: widgetGraphUnits.dollars,
    title: 'Total Cost',
  },
  [widgetGraphs.contactsCount]: {
    id: widgetGraphs.contactsCount,
    graphTypeOptions: oneDimensionalWidgetGraphType,
    graphType: oneDimensionalWidgetGraphType.progress,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total Contact Count',
    numeratorUnits: widgetGraphUnits.none,
    denominatorUnits: widgetGraphUnits.none,
    differenceUnits: widgetGraphUnits.percent,
    title: '# of Contacts',
  },
  [widgetGraphs.contactsSpend]: {
    id: widgetGraphs.contactsSpend,
    graphTypeOptions: oneDimensionalWidgetGraphType,
    graphType: oneDimensionalWidgetGraphType.donut,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total Spend',
    numeratorUnits: widgetGraphUnits.dollars,
    denominatorUnits: widgetGraphUnits.dollars,
    differenceUnits: widgetGraphUnits.dollars,
    title: 'Contact Spend',
  },
  [widgetGraphs.contactsEvents]: {
    id: widgetGraphs.contactsEvents,
    graphTypeOptions: twoDimensionalWidgetGraphType,
    graphType: twoDimensionalWidgetGraphType.bar,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total # of Events (Weekly)',
    numeratorUnits: widgetGraphUnits.none,
    denominatorUnits: widgetGraphUnits.none,
    differenceUnits: widgetGraphUnits.none,
    title: 'Contact Events Booked',
  },
  [widgetGraphs.dashboardEvents]: {
    id: widgetGraphs.dashboardEvents,
    graphTypeOptions: twoDimensionalWidgetGraphType,
    graphType: twoDimensionalWidgetGraphType.line,
    isDifferenceVisible: false,
    isLabelVisible: false,
    numeratorUnits: widgetGraphUnits.none,
    denominatorUnits: widgetGraphUnits.none,
    differenceUnits: widgetGraphUnits.none,
    title: '',
  },
  [widgetGraphs.bookingsCount]: {
    id: widgetGraphs.bookingsCount,
    graphTypeOptions: oneDimensionalWidgetGraphType,
    graphType: oneDimensionalWidgetGraphType.progress,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total Account Count',
    numeratorUnits: widgetGraphUnits.none,
    denominatorUnits: widgetGraphUnits.none,
    differenceUnits: widgetGraphUnits.percent,
    title: '# of Bookings',
  },
  [widgetGraphs.bookingsType]: {
    id: widgetGraphs.bookingsType,
    graphTypeOptions: oneDimensionalWidgetGraphType,
    graphType: oneDimensionalWidgetGraphType.donut,
    isDifferenceVisible: false,
    isLabelVisible: true,
    label: 'Total Bookings',
    numeratorUnits: widgetGraphUnits.none,
    denominatorUnits: widgetGraphUnits.none,
    differenceUnits: widgetGraphUnits.none,
    title: 'Type: Weddings',
  },
  [widgetGraphs.bookingEvents]: {
    id: widgetGraphs.bookingEvents,
    graphTypeOptions: twoDimensionalWidgetGraphType,
    graphType: twoDimensionalWidgetGraphType.bar,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total Booked Events (Weekly)',
    numeratorUnits: widgetGraphUnits.none,
    denominatorUnits: widgetGraphUnits.none,
    differenceUnits: widgetGraphUnits.none,
    title: 'Events Booked Today',
  },
  [widgetGraphs.ordersCount]: {
    id: widgetGraphs.ordersCount,
    graphTypeOptions: oneDimensionalWidgetGraphType,
    graphType: oneDimensionalWidgetGraphType.progress,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total Account Count',
    numeratorUnits: widgetGraphUnits.none,
    denominatorUnits: widgetGraphUnits.none,
    differenceUnits: widgetGraphUnits.percent,
    title: '# of Orders',
  },
  [widgetGraphs.ordersSpend]: {
    id: widgetGraphs.orderssSpend,
    graphTypeOptions: oneDimensionalWidgetGraphType,
    graphType: oneDimensionalWidgetGraphType.donut,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total Value',
    numeratorUnits: widgetGraphUnits.dollars,
    denominatorUnits: widgetGraphUnits.dollars,
    differenceUnits: widgetGraphUnits.dollars,
    title: 'Value',
  },
  [widgetGraphs.ordersEvents]: {
    id: widgetGraphs.ordersEvents,
    graphTypeOptions: twoDimensionalWidgetGraphType,
    graphType: twoDimensionalWidgetGraphType.bar,
    isDifferenceVisible: true,
    isLabelVisible: true,
    label: 'Total Orders (Weekly)',
    numeratorUnits: widgetGraphUnits.none,
    denominatorUnits: widgetGraphUnits.none,
    differenceUnits: widgetGraphUnits.none,
    title: 'Orders Today',
  },
};

export function widgets(state = initialState, action) {
  switch (action.type) {
    case SET_WIDGET_GRAPH_TITLE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          title: action.payload.title,
        },
      };
    case SET_WIDGET_GRAPH_DATA_SET:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          dataSet: action.payload.dataSet,
        },
      };
    case SET_WIDGET_GRAPH_TYPE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          graphType: action.payload.graphType,
        },
      };
    case SET_WIDGET_GRAPH_COLOR:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          color: action.payload.color,
        },
      };
    case SET_WIDGET_GRAPH_COMPARISON:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isComparisonVisible: action.payload.isComparisonVisible,
        },
      };
    case SET_WIDGET_GRAPH_LABEL:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          label: action.payload.label,
        },
      };
    case SET_WIDGET_GRAPH_SETTINGS:
      return {
        ...state,
        [action.settings.id]: {
          ...state[action.settings.id],
          ...action.settings,
        },
      };
    default:
      return state;
  }
}
