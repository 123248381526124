import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import SmallGrid from 'Components/Grid/SmallGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const dimensions = {
  width: 708,
  height: 561,
};

const initalState = {
  selectedTemplate: undefined,
};

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    height: dimensions.height - 100,
  },
  halfWidth: {
    width: '50%',
    flexGrow: 1,
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftBorder: {
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.grey[50],
    padding: 35,
    overflow: 'hidden',
    flexGrow: 1,
    display: 'flex',
  },
  header: {
    textAlign: 'center',
    color: theme.palette.grey[500],
    padding: 8,
    backgroundColor: theme.palette.grey[50],
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  templateContainer: {
    flexGrow: 1,
    padding: 10,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
  },
  templateContent: {
    transform: 'scale(.75)',
    transformOrigin: 'left top',
    width: '133%',
    height: '133%',
  },
});

class EmailTemplatesModal extends Component {
  state = initalState;

  notSetupDialog = React.createRef();

  componentDidUpdate = prevProps => {
    const { isOpened } = this.props;

    if (!isOpened && prevProps.isOpened) {
      //closing
      this.setState(initalState);
    }
  }

  onCancel = () => {
    this.props.onUseTemplate();//empty means close
  }

  onUseTemplate = () => {
    this.props.onUseTemplate(this.state.selectedTemplate);
  }

  rowSelected = e => {
    const selectedTemplate = e.dataItem;

    this.setState({ selectedTemplate });
  }

  render() {
    const {
      isOpened,
      classes,
      templates,
    } = this.props;

    const {
      selectedTemplate,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        dimensions={dimensions}
        onCancel={this.onCancel}
        onSave={this.onUseTemplate}
        isSaveDisabled={!Boolean(selectedTemplate)}
        title="Email Templates"
        saveText="Use Template"
        addTitleBottomBorder={true}

      >
        <div className={classes.container}>
          <div className={classes.halfWidth}>
            <SmallGrid
              data={templates}
              scrollable="none"
              onRowClick={this.rowSelected}
              selectedField="selected"
            >
              <GridColumn field="name" title="Subject" />
              <GridColumn field="type" title="Type" />
            </SmallGrid>
          </div>
          <div className={classNames(classes.halfWidth, classes.flex)}>
            <div className={classes.header}>Preview</div>
            <div className={classes.leftBorder}>
              {selectedTemplate &&
                <div className={classes.templateContainer} >
                  <div
                    className={classes.templateContent}
                    dangerouslySetInnerHTML={{ __html: selectedTemplate.htmlContent }}>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </Modal>);
  }
}

const mapStateToProps = state => {
  const {
    emailTemplates: {
      data,
    },
  } = state;

  return {
    templates: data,
  };
};

export default connect(mapStateToProps, undefined)(withStyles(styles)(EmailTemplatesModal));
