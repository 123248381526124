import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core';
import TextField from 'Components/TextField';
import ComboBox from 'Components/ComboBox';
import CoverDatePicker from 'Components/CoverDatePicker';
import WizardContext from './WizardContext';
import { getContactNameAndIds } from 'actions/contact';
import { getBooking } from 'actions/booking';
import { connect } from 'react-redux';
import TemplatePanel from './TemplatePanel';
import RelatedToComboBox from './RelatedToComboBox';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';
import { updateStoreBooking } from 'actions/booking';

const styles = () => {
  return {
    mainPadding: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    form: {
      display: 'flex',
    },
    fieldRow: {
      display: 'flex',
    },
    leftFields: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '40%',
      marginLeft: -16,
    },
    rightFields: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '50%',
      marginRight: -16,
    },
    managerInput: {
      minWidth: 107,
    },
  };
};

class ProposalWizardGeneral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactsMap: {},
    };
  }

  componentDidMount() {
    const { getContactNameAndIds } = this.props;

    getContactNameAndIds();
    this.setupContactSuggestions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contacts !== this.props.contacts) {
      this.setupContactSuggestions();
    }
  }

  setupContactSuggestions = () => {
    const { contacts } = this.props;

    if (contacts) {
      const { comboBoxSuggestions, comboBoxMap } = setupComboBoxSuggestions(contacts);

      this.setState({ contactSuggestions: comboBoxSuggestions, contactsMap: comboBoxMap });
    }
  }

  onFieldChange = fieldName => value => {
    const { storeBooking, updateStoreBooking } = this.props;

    const updatedStoreBooking = {
      ...storeBooking,
      [fieldName]: value,
    };

    updateStoreBooking(updatedStoreBooking);
  }

  // If an existing Contact is picked from the list, set associatedContactName AND associatedContactId
  handleSelectedContact = pick => {
    const { contactsMap } = this.state;
    const contactId = pick && pick.value && pick.value.id;
    const selectedContact = contactsMap[contactId];

    this.setState( { selectedContact });
    this.onFieldChange('contactId')(contactId);
  }

  // If typing a one-off, set associatedContactName and null out associatedContactId
  handleOneOffContact = () => {
    this.setState( { selectedContact: null });
    this.onFieldChange('contactId')(null);
  }

  render() {
    const {
      classes,
      generalInfoErrors = {},
      storeBooking,
    } = this.props;
    const {
      selectedContact,
      contactSuggestions,
    } = this.state;

    return (
      <div className={classes.mainPadding}>
        <div className={classes.form}>
          <div className={classes.leftFields}>
            <TextField
              label="Name of Proposal"
              fieldName="name"
              error={generalInfoErrors.name}
              value={storeBooking.name}
              onFieldChange={this.onFieldChange('name')}
            />
            <div className={classes.fieldRow}>
              <TextField
                label="Event Manager"
                fieldName="eventManager"
                className={classes.managerInput}
                value={storeBooking.eventManager}
                onFieldChange={this.onFieldChange('eventManager')}
              />
              <CoverDatePicker
                label="Date Created"
                fieldName="dateBooked"
                error={generalInfoErrors.dateBooked}
                value={storeBooking.dateBooked}
                onFieldChange={this.onFieldChange('dateBooked')}
              />
            </div>
          </div>
          <div className={classes.rightFields}>
            <RelatedToComboBox
              value={storeBooking.entityId}
              handleChange={this.onFieldChange}
            />
            <div className={classes.fieldRow}>
              <ComboBox
                label="Associated Contact"
                value={selectedContact}
                suggestions={contactSuggestions}
                isCreatable={true}
                isClearable={true}
                handleChange={this.handleSelectedContact}
                handleQuickAdd={this.handleOneOffContact}
              />
            </div>
          </div>
        </div>
        <TemplatePanel />
      </div>
    );
  }
}

//Container to move context into props
const ProposalWizardGeneralContainer = props => {
  const {
    selectedCards,
    generalInfoErrors,
  } = useContext(WizardContext);

  return (<ProposalWizardGeneral
    {...props}
    generalInfoErrors={generalInfoErrors}
    selectedCards={selectedCards}
  />);
};

const mapStateToProps = state => {
  const {
    booking: {
      wizard: {
        storeBooking,
      },
    },
    contact,
  } = state;

  return {
    contacts: contact.nameAndIds,
    storeBooking,
  };
};

const mapDispatchToProps = {
  getContactNameAndIds,
  getBooking,
  updateStoreBooking,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProposalWizardGeneralContainer));
