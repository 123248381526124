import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
  contentSection: {
    color: theme.common.black,
    fontSize: 15,
  },
  label: {
    color: theme.palette.primary.main,
  },
});

class CustomerDetails extends Component {

  render() {
    const { classes, order, className } = this.props;

    return (
      <div className={classNames(className, classes.contentSection)}>
        <div>
          <span className={classes.label}>Customer Details</span>
        </div>
        <div>
          <span className={classes.label}>Name:</span> {order.customerName ? order.customerName : ''}
        </div>
        <div>
          <span className={classes.label}>Phone:</span> {order.customerPhone ? order.customerPhone : ''}<br />
        </div>
        <div>
          <span className={classes.label}>Zip Code:</span> {order.zip ? order.zip : '34102'}<br />
        </div>
      </div>

    );
  }
}

export default withStyles(styles)(CustomerDetails);
