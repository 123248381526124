import { EVENT_TYPE_FILTER_CLICK, UPDATING_CALENDAR_EVENTS } from 'actions/constants';
import { defaultSelectedEventTypes } from 'constants/eventTypes';

const initialState = {
  selectedEventTypes: defaultSelectedEventTypes,
  updating: false,
};

export function calendar(state = initialState, action) {
  switch (action.type) {
    case EVENT_TYPE_FILTER_CLICK:
      const { eventType, isChecked } = action;
      const selectedEventTypes = [...state.selectedEventTypes];
      const indexOfEventType = selectedEventTypes.indexOf(eventType);

      if (isChecked) {
        if (indexOfEventType === -1) {
          selectedEventTypes.push(eventType);
        }
      } else {
        if (indexOfEventType !== -1) {
          selectedEventTypes.splice(indexOfEventType, 1);
        }
      }

      return {
        ...state,
        selectedEventTypes,
      };

    case UPDATING_CALENDAR_EVENTS:
      const { updating } = action;

      return {
        ...state,
        updating,
      };

    default:
      return state;
  }
}
