import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import { Avatar } from '@material-ui/core';

const styles = theme => ({
  filter: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 120,
    minWidth: 125,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[50],
    },
  },
  active: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.85),
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.7),
    },
  },
  threadCount: {
    color: theme.palette.action.active,
  },
  avatarWrapper: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
  },
  avatar: {
    height: 60,
    width: 60,
    backgroundColor: theme.palette.grey[50],
    '& svg': {
      fontSize: 40,
    },
  },
  filterName: {
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
  },
});

class ReportFilter extends Component {
  render() {
    const { classes, name, onFilterClick, isActive, icon } = this.props;

    return (
      <div className={classNames(classes.filter, isActive && classes.active)} onClick={onFilterClick}>
        <div className={classes.avatarWrapper}>
          <Avatar className={classes.avatar}>
            {icon}
          </Avatar>
        </div>
        <div className={classes.filterName}>
          {name}
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(ReportFilter);
