import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from 'Components/Grid';
import { defaultPackagesGridColumns } from 'constants/gridColumnDefaults';
import _ from 'lodash';
import { GridColumn } from '@progress/kendo-react-grid';
import { provideIntlService } from '@progress/kendo-react-intl';
import classNames from 'classnames';
import SelectedColumn from 'Components/Grid/SelectedColumn';

const styles = theme => ({
  selectAll: {
    opacity: 0.75,
    color: theme.palette.grey[300],
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

class PackageItemGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      items: props.items || {},
      columns: [{
        field: 'Loading...',
        title: 'Loading...',
        isVisible: true,
      }],
    };
    this.formatter = provideIntlService(this);
  }

  componentDidMount() {
    this.setupGridColumns();
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;

    if (prevProps.items !== items) {
      this.setState({ items });
    }
  }

  setupGridColumns = apiColumns => {
    apiColumns = apiColumns && apiColumns.length > 0 ? apiColumns : defaultPackagesGridColumns.default;
    const allColumns = defaultPackagesGridColumns.all;
    const defaultColumns = defaultPackagesGridColumns.default;
    const columns = allColumns.map(column => {
      return {
        field: column,
        title: _.startCase(column),
        isVisible: apiColumns.indexOf(column) !== -1,
        isDefault: defaultColumns.indexOf(column) !== -1,
      };
    });

    this.setState({ columns });
  };

  displayPrice = dataItem => {
    if (dataItem.price === 0) {
      return 0;
    }
    if (!dataItem.price) {
      return '-';
    }

    return this.formatter.formatNumber(this.props.defaultQuantity * dataItem.price, 'c2');
  }

  getColumn = column => {
    const { classes } = this.props;
    const { items } = this.props;

    if (column.isVisible) {
      switch (column.field) {
        case 'selected':
          return SelectedColumn(items);
        case 'item':
          return <GridColumn width={150} key={column.field} field="name" title="Item" />;
        case 'calories':
          return <GridColumn width={100} key={column.field} field={column.field} title="Calories" />;
        case 'additionalPrice': // API sets Price field == AdditionalPrice for Package Items, so here we use "price"
          return <GridColumn width={100} key={column.field} field="price" title="Addl. Price" cell={props => (
            <td>{props.dataItem.price ? this.formatter.formatNumber(props.dataItem.price, 'c2') : '-'}</td>
          )} />;
        case 'description':
          return <GridColumn key={column.field} field={column.field} title="Description" cell={props => (
            <td className={classNames(classes.truncate)}>{props.dataItem.description}</td>
          )} />;
        case 'allergens':
          return <GridColumn width={140} key={column.field} field={column.field} title="Allergens" />;
        default:
          return <GridColumn key={column.field} field={column.field} title={column.title} />;
      }
    }
  }

  render() {
    const { columns } = this.state;
    const { items, onSelectionChange } = this.props;

    return (
      <Grid
        items={items}
        isEditable={false}
        isQuickAdding={false}
        minHeight="80px"
        height={'unset'}
        selectionChanged={onSelectionChange}
        selectedField="selected"
      >
        {columns.map(this.getColumn)}
      </Grid>
    );
  }
}

export default withStyles(styles)(PackageItemGrid);
