import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import Note from 'Components/Note';
import DataLoader from 'Components/DataLoader';
import classNames from 'classnames';
import IconButton from 'Components/Buttons/IconButton';
import AddIcon from '@material-ui/icons/Add';
import AddEditNoteModal from 'Components/AddEditNoteModal';
import PropTypes from 'prop-types';
import SectionContext from 'Components/Contexts/SectionContext';
import { connect } from 'react-redux';
import NoteService from 'actions/NoteService';

const styles = theme => ({
  bookingNotesPanel: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  positionLoadingBar: {
    position: 'relative',
    minHeight: 100,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  noNotes: {
    padding: 16,
  },
  addNote: {
    height: 25,
    padding: 0,
    color: theme.palette.common.black,
  },
});

class NotesPanel extends Component {
  state = {
    notes: {
      generalNotes: [],
      internalNotes: [],
    },
    isNoteModalOpen: false,
    note: undefined,
  };

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastUpdate !== this.props.lastUpdate) {
      this.refresh();
    }
  }

  refresh = () => {
    const { onRefresh, noteService } = this.props;

    noteService.get().then(notes => {
      this.setState({ notes });
    });
    onRefresh && onRefresh();
  }

  addNoteClicked = noteType => synthEvent => {
    const newNote = {
      noteType: noteType,
    };

    this.setState({ isNoteModalOpen: true, note: newNote });
    synthEvent.stopPropagation();
  }

  onModalCancel = () => {
    this.setState({ isNoteModalOpen: false });
  }

  onModalSave = note => {
    this.onModalCancel();
    note.id === undefined ?
      this.props.noteService.add(note).then(this.refresh) :
      this.props.noteService.update(note).then(this.refresh);
  }

  onEdit = note => {
    this.props.displayAddButton ?
      this.setState({ isNoteModalOpen: true, note: note }) :
      this.props.onEditNote(note);//parent component will re-pass in props.lastUpdate when edit is finished
  }

  onDelete = noteId => {
    this.props.noteService.delete(noteId).then(this.refresh);
  }

  render() {

    const {
      classes,
      displayAddButton,
    } = this.props;
    const {
      isNoteModalOpen,
      note, //the note we're editing
      notes: { generalNotes, internalNotes },
    } = this.state;

    if (!generalNotes && !internalNotes) {
      return (
        <div className={classNames(classes.bookingNotesPanel, classes.positionLoadingBar)}>
          <DataLoader isLoading={true} inline={true} />
        </div>);
    }

    const addGeneralNoteButton = displayAddButton ? <IconButton icon={AddIcon} className={classes.addNote} onClick={this.addNoteClicked('general')} /> : undefined;
    const addInternalNoteButton = displayAddButton ? <IconButton icon={AddIcon} className={classes.addNote} onClick={this.addNoteClicked('internal')} /> : undefined;

    return (
      <div className={classes.bookingNotesPanel}>
        <CoverExpansionPanel
          title="General"
          defaultExpanded={true}
          rightcontent={addGeneralNoteButton}
        >
          <div className={classes.flexColumn}>
            {generalNotes.length ?
              generalNotes.map(note => {
                return <Note note={note} key={note.id} color="purple" onDelete={this.onDelete} onEdit={this.onEdit} />;
              })
              :
              <div className={classes.noNotes}>no general notes</div>}
          </div>
        </CoverExpansionPanel>
        <CoverExpansionPanel
          title="Internal"
          defaultExpanded={true}
          rightcontent={addInternalNoteButton}
        >
          <div className={classes.flexColumn}>
            {internalNotes.length ?
              internalNotes.map(note => {
                return <Note note={note} key={note.id} color="blue" onDelete={this.onDelete} onEdit={this.onEdit} />;
              })
              :
              <div className={classes.noNotes}>no internal notes</div>
            }
          </div>
        </CoverExpansionPanel>
        {isNoteModalOpen && <AddEditNoteModal onSave={this.onModalSave} onCancel={this.onModalCancel} note={note} />}
      </div>
    );
  }
}

NotesPanel.propTypes = {
  noteService: PropTypes.object,
  onEditNote: PropTypes.func, //not used if displayAddButton is true
  displayAddButton: PropTypes.bool,
};

export const NotesPanelWithNoContext = withStyles(styles)(NotesPanel);

//do connect here because I want the dispatch prop passed into this component
const NotesPanelWithContext = connect()(props => {
  const {
    relationship,
    chitChatRecordName,
  } = useContext(SectionContext);

  const {
    dispatch,
    ...rest
  } = props;

  return (<NotesPanelWithNoContext
    {...rest}
    noteService={new NoteService(dispatch, relationship, chitChatRecordName)}
  />);
});

export default NotesPanelWithContext;
