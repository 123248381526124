import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';

//note can't use theme here
const styles = () => {
  return {
    date: {
      display: 'flex',
      fontSize: 13,
      flexDirection: 'column',
      flexGrow: 1,
      border: '1px solid',
      borderRadius: 4,
      padding: 7,
      maxWidth: '100%', //it's overflowing the container and hiding the right border
    },
    lessPadding: {
      padding: '0 7px', //when isAllDay
    },
    eventText: {
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    eventTime: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
};

class DayEvent extends Component {

  getTimeText() {
    const {
      event: { syncFusionStart, syncFusionEnd },
      isMilitaryTime,
    } = this.props;

    const momentStart = moment(syncFusionStart);
    const momentEnd = moment(syncFusionEnd);
    const startText = isMilitaryTime ? momentStart.format('H:mm') : momentStart.format('h:mm A');
    const endText = isMilitaryTime ? momentEnd.format('H:mm') : momentEnd.format('h:mm A');

    return `${startText} - ${endText}`;
  }

  render() {
    const {
      classes,
      color,
      event: { name, allDay },
    } = this.props;

    const time = this.getTimeText();

    return (
      <div className={classNames(classes.date, allDay && classes.lessPadding)} style={{ color: color, borderColor: color, backgroundColor: lighten(color, 0.9) }}>
        <div className={classes.eventText}>
          {name}
        </div>
        {!allDay && <div className={classes.eventTime}>
          {time}
        </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(DayEvent);
