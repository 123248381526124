import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  root: {
    border: '1px solid',
    borderRadius: '4px',
    fontSize: '13px',
    minHeight: '32px',
    padding: '0 13px',
    minWidth: 'unset',
    textTransform: 'initial',
    background: theme.palette.secondary.contrastText,
    borderColor: theme.palette.grey[100],
    color: theme.palette.secondary.dark,
  },
  active: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.85),
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.7),
    },
  },
});

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class WhiteButton extends Component {
  render() {
    const { classes, children, minWidth, isActive, navigate, onClick, ...rest } = this.props;
    const style = minWidth && { minWidth };

    let wireOnClick = onClick;

    if (navigate) {
      //only way to get this to work with react-router.
      //Copied from https://github.com/ReactTraining/react-router/blob/e951e1d78edacbdf72c458e97938f92da3a43512/packages/react-router-dom/modules/Link.js#L35
      wireOnClick = event => {
        try {
          if (onClick) onClick(event);
        } catch (ex) {
          event.preventDefault();
          throw ex;
        }
        const { target } = rest;

        if (
          !event.defaultPrevented && // onClick prevented default
          event.button === 0 && // ignore everything but left clicks
          (!target || target === "_self") && // let browser handle "target=_blank" etc.
          !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault();
          navigate();
        }
      };
    }

    return (
      <Button {...rest}
        style={style}
        onClick={wireOnClick}
        classes={{
          root: classNames(classes.root, isActive && classes.active),
        }}
      >
        {children}
      </Button>
    );
  }
}

export default withStyles(styles)(WhiteButton);
