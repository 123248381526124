import React, { Component, useContext } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { withStyles } from '@material-ui/core/styles';
import { Clear as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import Modal from 'Components/Modal';
import TextField from 'Components/TextField';
import SectionHeader from 'Components/SectionHeader';
import ComboBoxAsync from 'Components/ComboBoxAsync';
import ComboBox from 'Components/ComboBox';
import SimpleDialog from 'Components/SimpleDialog';
import TabContainer from 'Components/TabContainer';
import BlueUnderlineTab from 'Components/Tabs/BlueUnderlineTab';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BookingContext from './BookingContext';
import {
  saveStoreBooking,
  updateStoreBooking,
} from 'actions/booking';
import { searchAccounts } from 'actions/account';
import { searchContacts } from 'actions/contact';
import FilesService from 'actions/FilesService';
import {
  getReferences,
  getBookingCategories,
  getBookingThemes,
  getBookingStatuses,
} from 'actions/pickListOption';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';
import { handleComboBoxPick } from 'Components/handleComboBoxChange';
import QuickAccountModal from 'Components/QuickAccountModal';
import SectionContext from 'Components/Contexts/SectionContext';
import _ from 'lodash';

const styles = theme => {
  return {
    overflowIfTooBig: {
      overflow: 'auto',
      marginTop: theme.spacing.unit * 2,
      height: 520,
    },
    container: {
      display: 'flex',
      width: '100%',
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 12,
    },
    flex: {
      display: 'flex',
    },
    sectionHeader: {
      paddingLeft: 12,
      marginTop: 16,
    },
    section: {
      display: 'flex',
      flex: '1 1 auto',
    },
    halfWidth: {
      display: 'flex',
      width: '50%',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    deleteIcon: {
      color: theme.common.softPink,
      verticalAlign: 'top',
      marginLeft: 8,
      marginTop: 8,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    editIcon: {
      color: theme.palette.grey[700],
      verticalAlign: 'top',
      marginLeft: 16,
      marginTop: 8,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };
};

const tabs = ['Details', 'Image'];
const defaultCrop = {
  width: 600,
  height: 400,
};

class BookingDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: props.storeBooking,
      account: null,
      contact: null,
      siteContact: null,
      referencesMap: {},
      categoryMap: {},
      themeMap: {},
      statusMap: {},
      tabIndex: 0,
      isCropping: false,
      src: null,
      crop: defaultCrop,
      srcAsBlob: null,
      cropFileName: '',
      errors: {},
      bookingImageFile: null,
    };

    this.confirmDeleteFileDialog = React.createRef();
  }

  componentDidMount() {
    const {
      references,
      bookingCategories,
      bookingThemes,
    } = this.props;

    this.setupDetails();

    this.props.getReferences();
    this.props.getBookingCategories();
    this.props.getBookingThemes();
    // this.props.getBookingStatuses().then(this.setupStatusSuggestions); TODO
    this.setupReferenceSuggestions(references);
    this.setupCategorySuggestions(bookingCategories);
    this.setupThemeSuggestions(bookingThemes);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.references !== this.props.references) {
      this.setupReferenceSuggestions(this.props.references);
    }
    if (prevProps.bookingCategories !== this.props.bookingCategories) {
      this.setupCategorySuggestions(this.props.bookingCategories);
    }
    if (prevProps.bookingThemes !== this.props.bookingThemes) {
      this.setupThemeSuggestions(this.props.bookingThemes);
    }
  }

  setupDetails = () => {
    const { storeBooking } = this.props;
    let { account, contact, siteContact } = this.state;

    if (storeBooking.account) account = storeBooking.account;
    if (storeBooking.contact) contact = storeBooking.contact;
    if (storeBooking.siteContact) siteContact = storeBooking.siteContact;
    this.setState({ account, contact, siteContact });
  }

  getBookingImage = () => {
    this.props.filesService.getBookingImage().then(this.setBookingImageUrl);
  }

  setBookingImageUrl = fileUrl => {
    const { booking } = this.state;

    booking.imageUrl = fileUrl;

    this.setState({
      booking,
      isCropping: false,
      src: fileUrl,
      crop: defaultCrop,
      srcAsBlob: null,
      cropFileName: '',
    });
  }

  deleteBookingImage = () => {
    const { booking, bookingImageFile } = this.state;

    this.confirmDeleteFileDialog.current.open().then(() => {
      this.props.filesService.delete(bookingImageFile.id);
      booking.imageUrl = null;
      this.setState({ booking });
    });
  }

  onReset(event) {
    // To enable re-upload of same file
    event.target.value = null;
  }

  handleTabChange = tabIndex => {
    const { bookingImageFile, booking } = this.state;

    this.setState({ tabIndex });
    if (tabIndex === 1 && !bookingImageFile && booking.imageUrl) {
      this.props.filesService.get().then(files => {
        const bookingFile = files.find(file => file.url === booking.imageUrl);

        this.setState({ bookingImageFile: bookingFile });
      });
    }
  };

  handleFieldChange = fieldName => value => {
    const booking = {
      ...this.state.booking,
      [fieldName]: value,
    };

    this.setState({ booking });

    return Promise.resolve();
  }

  onQuickPick = fieldName => pick => {
    handleComboBoxPick(this.handleFieldChange, fieldName, pick);
  }

  onAccountSelect = selectedAccount => {
    const { booking } = this.state;

    if (!selectedAccount) { // clear account
      const updatedStoreBooking = {
        ...booking,
        accountId: null,
      };

      this.setState({ account: null, booking: updatedStoreBooking });

      return;
    }

    if (!selectedAccount.__isNew__) {
      const updatedStoreBooking = {
        ...booking,
        accountId: selectedAccount.id,
        account: null,
      };

      if (selectedAccount && selectedAccount.primaryContactId) {
        updatedStoreBooking.contactId = selectedAccount.primaryContactId;
        updatedStoreBooking.contact = null;
        updatedStoreBooking.siteContactId = selectedAccount.primaryContactId;
        updatedStoreBooking.siteContact = null;
      } else {
        updatedStoreBooking.contactId = null;
        updatedStoreBooking.siteContactId = null;
      }

      this.setState({ account: selectedAccount, booking: updatedStoreBooking });
    }
  }

  pickContact = fieldName => selectedContact => {
    const { booking } = this.state;

    const whichContact = {
      'contactId': 'contact',
      'siteContactId': 'siteContact',
    }[fieldName];

    if (!selectedContact) { // clear contact
      const updatedStoreBooking = {
        ...booking,
        [fieldName]: null,
        [whichContact]: null,
      };

      this.setState({
        [whichContact]: null,
        booking: updatedStoreBooking,
      });

      return;
    }

    if (!selectedContact.__isNew__) {
      const updatedStoreBooking = {
        ...booking,
        [fieldName]: selectedContact.id,
        [whichContact]: null,
      };

      this.setState({
        [whichContact]: selectedContact,
        booking: updatedStoreBooking,
      });
    }
  }

  saveBookingDetails = () => {
    const { tabIndex, isCropping } = this.state;

    if (tabIndex === 0) {
      const errors = this.getErrors();

      if (!_.isEmpty(errors)) return;

      this.saveAndClose();
    } else if (tabIndex === 1) {
      if (isCropping) {
        this.cropBookingImageAndUpload();
      } else {
        this.saveAndClose();
      }
    }
  }

  saveAndClose = () => {
    const { booking } = this.state;
    const { onChitChatUpdated, onClose } = this.props;

    this.props.updateStoreBooking(booking);
    this.props.saveStoreBooking(booking).then(() => {
      onClose();
      onChitChatUpdated();
    });
  }

  setupReferenceSuggestions = references => {
    if (references) {
      const { comboBoxSuggestions, comboBoxMap } = setupComboBoxSuggestions(references);

      this.setState({ referenceSuggestions: comboBoxSuggestions, referencesMap: comboBoxMap });
    }
  }

  setupCategorySuggestions = bookingCategories => {
    if (bookingCategories) {
      const { comboBoxSuggestions, comboBoxMap } = setupComboBoxSuggestions(bookingCategories);

      this.setState({ categorySuggestions: comboBoxSuggestions, categoryMap: comboBoxMap });
    }
  }

  setupThemeSuggestions = themes => {
    if (themes) {
      const { comboBoxSuggestions, comboBoxMap } = setupComboBoxSuggestions(themes);

      this.setState({ themeSuggestions: comboBoxSuggestions, themeMap: comboBoxMap });
    }
  }

  setupStatusSuggestions = statuses => {
    if (statuses) {
      const { comboBoxSuggestions, comboBoxMap } = setupComboBoxSuggestions(statuses);

      this.setState({ statusSuggestions: comboBoxSuggestions, statusMap: comboBoxMap });
    }
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const newFileName = 'Cropped - ' + e.target.files[0].name;

      reader.addEventListener("load", () =>
        this.setState({ src: reader.result, isCropping: true, cropFileName: newFileName })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop);

      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop) {

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');

          return;
        }
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
        this.setState({ srcAsBlob: blob });
      }, 'image/jpg');
    });
  }

  cropBookingImageAndUpload = () => {
    const { srcAsBlob, cropFileName } = this.state;
    const formData = new FormData();

    formData.append('file', srcAsBlob, cropFileName);

    this.props.filesService.addBookingImage(formData).then(this.setBookingImageUrl);
  }

  onQuickAdd = () => value => {
    this.setState({ isNewAccountModalOpen: true, newAccount: { name: value } });
  }

  closeNewAccountModal = () => {
    this.setState({ isNewAccountModalOpen: false, newAccount: null });
  }

  getErrors = () => {
    const { booking } = this.state;
    const bookingNameHasError = booking.name ? false : true;
    const accountHasError = booking.accountId ? false : true;

    const errors = bookingNameHasError || accountHasError ?
      {
        name: bookingNameHasError,
        account: accountHasError,
      } : {};

    this.setState({ errors });

    return errors;
  }

  render() {
    const {
      classes,
      onClose,
      isOpened,
      displayDate,
      displayTime,
      searchContacts,
      searchAccounts,
    } = this.props;
    const {
      booking,
      account,
      contact,
      siteContact,
      referenceSuggestions,
      categorySuggestions,
      themeSuggestions,
      statusSuggestions,
      referencesMap,
      categoryMap,
      themeMap,
      statusMap,
      tabIndex,
      isCropping,
      src,
      crop,
      isNewAccountModalOpen,
      newAccount,
      errors,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        onCancel={onClose}
        title="Booking Details"
        onSave={this.saveBookingDetails}
        addTitleBottomBorder={false}
      >

        <Paper className={classes.overflowIfTooBig}>
          <BlueUnderlineTab
            onTabChange={this.handleTabChange}
            tabs={tabs}
            tabIndex={tabIndex}
          />
          {tabIndex === 0 &&
            <TabContainer>
              <div className={classNames(classes.container, classes.flexColumn)}>
                <div className={classNames(classes.section, classes.flexColumn)}>
                  <SectionHeader className={classes.sectionHeader}>Booking Details</SectionHeader>
                  <div className={classes.flex}>
                    <div className={classNames(classes.halfWidth, classes.flexColumn)}>
                      <TextField
                        label="Booking"
                        value={booking.name}
                        onFieldChange={this.handleFieldChange('name')}
                        error={errors.name}
                      />
                      <div className={classNames(classes.flex, classes.dateTime)}>
                        <TextField
                          label="Date"
                          value={displayDate}
                          disabled={true}
                        />
                        <TextField
                          label="Time"
                          value={displayTime}
                          disabled={true}
                        />
                      </div>
                      <ComboBox
                        label="Status"
                        value={statusMap[booking.statusId]}
                        suggestions={statusSuggestions}
                        isClearable={true}
                        handleChange={this.onQuickPick('statusId')}
                      />
                    </div>
                    <div className={classNames(classes.halfWidth, classes.flexColumn)}>
                      <ComboBox
                        label="Category"
                        value={categoryMap[booking.bookingCategoryId]}
                        suggestions={categorySuggestions}
                        isClearable={true}
                        handleChange={this.onQuickPick('bookingCategoryId')}
                      />
                      <ComboBox
                        label="Theme"
                        value={themeMap[booking.bookingThemeId]}
                        suggestions={themeSuggestions}
                        isClearable={true}
                        handleChange={this.onQuickPick('bookingThemeId')} onQuickPick
                      />
                      <TextField
                        label="Event Manager"
                        value={booking.eventManager}
                        onFieldChange={this.handleFieldChange('eventManager')}
                      />
                    </div>
                  </div>
                </div>

                <div className={classNames(classes.section, classes.flexColumn)}>
                  <SectionHeader className={classes.sectionHeader}>Client Information</SectionHeader>
                  <div className={classes.flex}>
                    <div className={classNames(classes.halfWidth, classes.flexColumn)}>
                      <ComboBoxAsync
                        label="Account"
                        value={account}
                        getData={searchAccounts}
                        autoLoadOptions={true}
                        resultCount={5}
                        onSelect={this.onAccountSelect}
                        onCreateOption={this.onQuickAdd('account')}
                        error={errors.account}
                      />
                      <ComboBox
                        label="Reference"
                        value={referencesMap[booking.referenceTypeId]}
                        suggestions={referenceSuggestions}
                        isClearable={true}
                        handleChange={this.onQuickPick('referenceTypeId')}
                      />
                    </div>
                    <div className={classNames(classes.halfWidth, classes.flexColumn)}>
                      <ComboBoxAsync
                        label="Contact"
                        value={contact}
                        getData={searchContacts}
                        autoLoadOptions={true}
                        resultCount={5}
                        onSelect={this.pickContact('contactId')}
                        // onCreateOption={this.onQuickAddContact}TODO
                      />
                      <ComboBoxAsync
                        label="Site Contact"
                        value={siteContact}
                        getData={searchContacts}
                        autoLoadOptions={true}
                        resultCount={5}
                        onSelect={this.pickContact('siteContactId')}
                        // onCreateOption={this.onQuickAddContact}TODO
                      />
                    </div>
                  </div>
                </div>
              </div>
              {isNewAccountModalOpen && <QuickAccountModal
                onClose={this.closeNewAccountModal}
                account={newAccount}
                isOpened={true}
              />}

            </TabContainer>
          }

          {tabIndex === 1 &&
            <TabContainer>
              <div className={classNames(classes.container, classes.flexColumn)}>
                <div className={classNames(classes.section, classes.flexColumn)}>
                  <SectionHeader className={classes.sectionHeader}>Image</SectionHeader>
                  <div className={classes.flex}>
                    <div className={classNames(classes.imageUploadContainer)}>
                      {!isCropping && booking.imageUrl &&
                        <img alt="booking" style={{ maxWidth: '100%' }} src={booking.imageUrl} />
                      }
                    </div>
                    {!isCropping &&
                      <div>
                        <input
                          className={classes.fileInput}
                          accept="image/*"
                          id="button-image-edit"
                          type="file"
                          onChange={this.onSelectFile}
                          onClick={this.onReset}
                          hidden
                        />
                        <label htmlFor="button-image-edit">
                          <EditIcon
                            aria-label={`Edit Image`}
                            className={classes.editIcon}
                            onClick={this.onReset}
                          />
                        </label>
                      </div>
                    }
                    {!isCropping && booking.imageUrl &&
                      <div>
                        <DeleteIcon className={classes.deleteIcon} onClick={this.deleteBookingImage} />
                      </div>
                    }
                    <SimpleDialog
                      message="Are you sure you want to delete this image?"
                      innerRef={this.confirmDeleteFileDialog}
                    />
                    {isCropping && src &&
                      <div className={classNames(classes.imageUploadContainer)}>
                        <ReactCrop
                          src={src}
                          crop={crop}
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          onChange={this.onCropChange}
                          locked="true"
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </TabContainer>
          }

        </Paper>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      references,
      bookingCategories,
      bookingThemes,
    },
    booking: {
      manager: {
        storeBooking,
      },
    },
  } = state;

  return {
    references,
    bookingCategories,
    bookingThemes,
    storeBooking,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    saveStoreBooking,
    searchContacts,
    searchAccounts,
    getReferences,
    getBookingCategories,
    getBookingThemes,
    getBookingStatuses,
    updateStoreBooking,
  }, dispatch),
  dispatch, //I want dispatch as a property
});

const BookingDetailsModalContainer = connect(mapStateToProps, mapDispatchToProps)(props => {
  const {
    onBookingChanged,
    onChitChatUpdated,
  } = useContext(BookingContext);

  const {
    relationship,
  } = useContext(SectionContext);

  const {
    dispatch,
    ...rest
  } = props;

  return (<BookingDetailsModal
    {...rest}
    onBookingChanged={onBookingChanged}
    onChitChatUpdated={onChitChatUpdated}
    relationship={relationship}
    filesService={new FilesService(relationship, dispatch)}
  />);
});

export default withStyles(styles)(BookingDetailsModalContainer);
