import React, { Component } from 'react';
import {
  FormControl,
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';

const styles = theme => ({
  flexGrowWithPad: {
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
  },
  label: {
    color: theme.palette.grey[200],
  },
  input: {
    color: theme.palette.grey[700],
    flex: 1,
  },
});

class MultiEmailInput extends Component {

  handleAddChip = chip => {
    const { value, onChange } = this.props;

    value.push(chip);
    onChange(value);
  }

  handleDeleteChip = (chip, index) => {
    const { value, onChange } = this.props;

    value.splice(index, 1);
    onChange(value);
  }

  validateEmail = value => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(value).toLowerCase());
  }

  render() {
    const {
      classes,
      disabled,
      label,
      className,
      value,
    } = this.props;

    return (
      <FormControl className={classNames(classes.flexGrowWithPad, className)} margin="normal" disabled={disabled}>
        <ChipInput
          value={value}
          onAdd={chip => this.handleAddChip(chip)}
          onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
          newChipKeys={[',', 'Tab']}
          label={label}
          onBeforeAdd={this.validateEmail}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          InputProps={{
            classes: {
              root: classes.input,
              disabled: classes.input,
            },
          }}
        />
      </FormControl>
    );
  }
}

export default withStyles(styles)(MultiEmailInput);
