import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import AccountList from './AccountList';
import Account from './Account';
import { Route } from 'react-router-dom';

class Accounts extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/accounts" component={AccountList} />
        <Route path="/accounts/:id" component={Account} />
      </Switch>
    );
  }
}

export default withRouter(Accounts);
