import React, { Component } from 'react';
import HomeMain from './main';
import HomeSales from './sales';
import QuickOrderSales from './sales/quickOrders';
import HomeCustomers from './customers';
import DashboardNav from './DashboardNav';
import { Route } from "react-router-dom";

export class Dashboard extends Component {
  render() {
    return (
      <>
        <DashboardNav />

        {/* <Route exact path='/home' component={HomeMain} /> */}
        <Route exact path='/' component={QuickOrderSales} />
        <Route exact path='/home' component={QuickOrderSales} />
        <Route path='/home/main' component={HomeMain} />
        <Route path='/home/sales' component={HomeSales} />
        <Route path='/home/customers' component={HomeCustomers} />

      </>
    );
  }
}

export default Dashboard;
