import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { widgetGraphUnits } from 'helpers/enums';

const styles = theme => ({
  rowPositive: {
    color: theme.palette.success,
  },
  rowNegative: {
    color: theme.palette.error.main,
  },
});

class DifferenceIndicator extends React.Component {
  dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  render() {
    const { differenceIndicator, units = widgetGraphUnits.percent, withColor = true, withSign = true } = this.props;

    const indicator = units === widgetGraphUnits.dollars ? this.dollarFormatter.format(differenceIndicator) : differenceIndicator;
    const plusMinusSignAndNumber = `${(withSign && differenceIndicator >= 0 ? '+' : '')}${indicator}`;
    const percentSign = units === widgetGraphUnits.percent ? '%' : '';

    const differenceDisplay = `${plusMinusSignAndNumber}${percentSign}`;
    const diffClass = differenceIndicator >= 0 ? this.props.classes.rowPositive : this.props.classes.rowNegative;

    return (
      <span className={withColor ? diffClass : ''}>
        {differenceDisplay}
      </span>
    );
  }
}

export default withStyles(styles)(DifferenceIndicator);
