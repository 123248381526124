import React, { Component, useContext } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';
import DataLoader from 'Components/DataLoader';
import AddIcon from '@material-ui/icons/Add';
import File from 'Components/File';
import HighlightButton from 'Components/Buttons/HighlightButton';
import SimpleDialog from 'Components/SimpleDialog';
import SectionContext from 'Components/Contexts/SectionContext';
import BookingContext from 'bookings/BookingContext';
import FilesService from 'actions/FilesService';
import { uploadFile } from 'actions/file';

const styles = theme => ({
  filesPanel: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  sectionRow: {
    display: 'flex',
    padding: 8,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    justifyContent: 'space-between',
    fontSize: 17,
    color: theme.palette.grey[800],
    alignItems: 'flex-end',
    flexFlow: 'row-reverse',
  },
  sectionTitle: {
    fontSize: 17,
    color: theme.palette.grey[800],
  },
  fileInput: {
    display: 'none',
  },
});

class FilesPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      isLoading: false,
    };

    this.confirmFileDelete = React.createRef();
    this.okDialog = React.createRef();
  }

  componentDidMount() {
    this.getFiles(); //this should fire everytime
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filesService !== this.props.filesService) {
      this.getFiles();
    }
    if (prevProps.filesLastUpdated !== this.props.filesLastUpdated) {
      this.getFiles();
    }
  }

  getFiles = () => {
    this.props.filesService.get().then(files => {
      this.setState({ files });
    });
  }

  resetValue(event) {
    // To enable re-upload of same file
    event.target.value = null;
  }

  fileChangedHandler = event => {
    const { relationship, uploadFile, onFileAddSuccess, filesService } = this.props;

    if (relationship && event.target.files.length) {
      const formData = new FormData();
      const fileUpload = event.target.files[0];

      this.setState({ isLoading: true });

      formData.append('file', fileUpload);
      uploadFile(relationship.entityType, relationship.entityId, filesService.accessType, formData).then(file => {
        if (file) {
          this.setState({
            files: [file, ...this.state.files],
          });
          onFileAddSuccess && onFileAddSuccess(file);
        }
        this.setState({ isLoading: false });
      }).catch(() => {
        this.okDialog.current.open('File upload failed. Please try again.');
      });
    }
  }

  onItemDeleted = file => {
    this.confirmFileDelete.current.open(`Are you sure you want to delete ${file.name}?`).then(() => {
      this.props.filesService.delete(file.id)
        .then(this.getFiles);
    });
  }

  onItemToggleShare = file => {
    this.props.filesService.share(file.id, !file.isShared)
      .then(this.getFiles);
  }

  render() {
    const { classes, filesService } = this.props;
    const { files, isLoading } = this.state;

    return (
      <div className={classes.filesPanel}>
        <div className={classes.sectionRow}>
          <input
            className={classes.fileInput}
            id="button-file"
            type="file"
            onChange={this.fileChangedHandler}
            onClick={this.resetValue}
            hidden
          />
          <label htmlFor="button-file">
            <HighlightButton
              component="span"
              aria-label={`Add File`}
              onClick={this.resetValue}
            >
              <AddIcon />
            </HighlightButton>
          </label>
          {isLoading &&
            <Fade
              in={isLoading}
              style={{
                width: 28,
                height: 28,
              }}
              unmountOnExit
            >
              <DataLoader />
            </Fade>}
          <div className={classes.sectionTitle}>Attached Files</div>
        </div>
        <div className={classes.flexColumn}>
          {files && files.map(file => {
            return filesService && filesService.entityType === "booking" ?
              <File file={file} key={file.id} onDelete={this.onItemDeleted} onToggleShare={this.onItemToggleShare} /> :
              <File file={file} key={file.id} onDelete={this.onItemDeleted} />;
          })}
        </div>
        <SimpleDialog innerRef={this.confirmFileDelete} />
        <SimpleDialog onlyOkayButton={true} innerRef={this.okDialog} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  uploadFile,
};

const FilesPanelWithContext = connect()(props => {
  const {
    onChitChatUpdated,
  } = useContext(BookingContext);

  const {
    relationship,
  } = useContext(SectionContext);

  const {
    dispatch,
    ...rest
  } = props;

  return (<FilesPanel
    {...rest}
    onChitChatUpdated={onChitChatUpdated}
    filesService={new FilesService(relationship, dispatch)}
    relationship={relationship}
  />);
});

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(FilesPanelWithContext));
