import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import CompanyHeader from './CompanyHeader';
import Splash from './splash';
import Franchise from './franchise';

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {},
    };
  }

  render() {
    return (
      <>
        <CompanyHeader />
        <Switch>
          <Route path="/admin/company/franchise" component={Franchise} />
          <Route path="/admin/company" component={Splash} />
          <Route component={Splash} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = state =>  {
  return {
    company: state.admin.company,
  };
};

export default withRouter(connect(mapStateToProps)(Company));
