import {
  SET_STORE_ORDER,
  RECEIVE_ORDER,
  DELETE_ORDER,
  RESET_CURRENT_ORDER,
  CLONE_ORDER,
  RECEIVE_SALES_REPS,
  FETCH_SALES_REPS_BEGIN,
  ADD_STORE_ORDER_ITEM,
  UPDATE_STORE_ORDER,
  REVERT_STORE_MANAGED_ORDER,
  ORDER_WIDGET_DATA_RECEIVED,
  SET_STORE_ORIGINAL_ORDER,
  RECEIVE_ORDERS,
  RECEIVE_ORDERS_LIST,
  CLEAR_STORE_ORDER,
  ORDER_DASHBOARD_DATA_RECEIVED,
} from './constants';
import { ENTITY_TYPES } from 'constants/entityTypes';
import { REQUEST_TYPES } from 'constants/requestTypes';
import { defaultOrdersGridColumns } from 'constants/gridColumnDefaults';
import { callApi } from 'shared/CallApi';
import {
  fetchOrdersBegin,
  orderColumnsReceived,
} from './api';
import {
  saveContact,
} from './contact';
import {
  logChitChatSystemMessage,
} from './chitchat';
import {
  workingAddressesSelectAddress,
  initWorkingAddresses,
} from './address';
import moment from 'moment';
import _ from 'lodash';
import { toODataString } from '@progress/kendo-data-query';

export const orderReceived = order => ({ type: RECEIVE_ORDER, order });
export const orderDeleted = response => ({ type: DELETE_ORDER, response });
export const currentOrderReset = () => ({ type: RESET_CURRENT_ORDER });
export const orderCloned = response => ({ type: CLONE_ORDER, response });
export const clearStoreOrder = () => ({ type: CLEAR_STORE_ORDER });
export const fetchSalesRepsBegin = () => ({ type: FETCH_SALES_REPS_BEGIN });
export const receivedSalesReps = salesRepsData => ({ type: RECEIVE_SALES_REPS, salesRepsData });
export const addStoreOrderItem = item => ({ type: ADD_STORE_ORDER_ITEM, item });
export const setStoreOriginalOrder = storeOriginalOrder => ({ type: SET_STORE_ORIGINAL_ORDER, storeOriginalOrder });
export const revertStoreOrder = item => ({ type: REVERT_STORE_MANAGED_ORDER });
export const editStoreOrderItem = item => ({ type: ADD_STORE_ORDER_ITEM, item });
export const updateStoreOrder = storeOrder => ({ type: UPDATE_STORE_ORDER, storeOrder });
export const orderWidgetDataReceived = widgetData => ({ type: ORDER_WIDGET_DATA_RECEIVED, widgetData });
export const orderDashboardDataReceived = dashboardData => ({ type: ORDER_DASHBOARD_DATA_RECEIVED, dashboardData });

export const setStoreOrder = storeOrder => ({
  type: SET_STORE_ORDER, storeOrder: {
    ...storeOrder,
    timeNeeded: moment(storeOrder.requestedDateTime).clone().format('LT'),
  },
});

export const ordersReceived = (data, append) => ({
  type: RECEIVE_ORDERS,
  data,
  append,
});

export const ordersListReceived = (list, append) => ({
  type: RECEIVE_ORDERS_LIST,
  list,
  append,
});

export const addItemToStoreOrder = item => dispatch => {
  dispatch(addStoreOrderItem(item));
  dispatch(updateStoreOrderAPI());

  return;
};

export const deleteStoreOrderItem = selection =>
  (dispatch, getState) => {
    const {
      storeReducer: {
        storeOrder,
      },
    } = getState();

    const updatedOrderItems = storeOrder.orderItems.map(orderItem => {
      return orderItem.id === selection.orderItem.id ? { ...orderItem, recordStatus: 'Deleted' } : orderItem;
    });

    const updatedStoreOrder = {
      ...storeOrder,
      orderItems: updatedOrderItems,
    };

    dispatch(updateStoreOrder(updatedStoreOrder));
    dispatch(updateStoreOrderAPI());
  };

export const updateStoreOrderItem = selectedOrderItem =>
  (dispatch, getState) => {
    const cloneStoreOrder = { ...getState().storeReducer.storeOrder };
    const newItem = !selectedOrderItem.id;

    if (newItem) {
      cloneStoreOrder.orderItems.push(selectedOrderItem);
    } else {
      cloneStoreOrder.orderItems = cloneStoreOrder.orderItems.map(orderItem => {
        return orderItem.id === selectedOrderItem.id
          ? {
            ...orderItem,
            notes: selectedOrderItem.notes,
            quantity: selectedOrderItem.quantity,
            noTax: selectedOrderItem.noTax,
            noServiceCharge: selectedOrderItem.noServiceCharge,
            noGratuity: selectedOrderItem.noGratuity,
            orderItemModifiers: selectedOrderItem.orderItemModifiers,
          }
          : orderItem;
      });
    }

    dispatch(setStoreOrder(cloneStoreOrder));
    dispatch(updateStoreOrderAPI());
  };

export const getDashboardData = (dateRange, params) => {
  return dispatch => {
    getOrderDashboardDataApi(dispatch, dateRange, params).then(result => dispatch(orderDashboardDataReceived(result)));
  };
};

const getOrderDashboardDataApi = (dispatch, dateRange, params) => {
  return dispatch(callApi(`OrderWidget/Dashboard?dateRange=${dateRange}&${params}`)).then(result => result.json())
    .catch(console.error);
};

export const getOrders = (params, append) => {
  return dispatch => {
    dispatch(fetchOrdersBegin(params));
    // sample to get all $orderby=id desc&$skip=0

    if (!append) {
      getOrderWidgetData(dispatch, params).then(result => {
        dispatch(orderWidgetDataReceived(result));
      });
    }

    return getListOrdersApi(dispatch, params).then(result => {
      dispatch(ordersListReceived(result, append));
    });
  };
};

export const getStatOrders = (params, append) => {
  return dispatch => {
    dispatch(fetchOrdersBegin(params));
    // sample to get all $orderby=id desc&$skip=0

    if (!append) {
      getOrderWidgetData(dispatch, params).then(result => {
        dispatch(orderWidgetDataReceived(result));
      });
    }

    return getFullOrdersApi(dispatch, params).then(result => {
      // TODO: Find a better to clean up bad address
      const validAddresses = result && result.filter(order => { return order.customerName; });
      // TODO: Remove after Beta

      dispatch(ordersReceived(validAddresses, append));
    });
  };
};

export const searchOrdersGlobal = () => {
  return (dispatch, getState) => {
    const {
      searchReducer: {
        searchText,
      },
    } = getState();

    return dispatch(callApi(`order?$filter= contains(customerName, '${searchText}') OR contains(customerPhone, '${searchText}')`))
      .then(result => result.json())
      .then(result => {
        dispatch(ordersReceived(result));
      })
      .catch(console.error);
  };
};

export const createStoreOrder = prepopulatedContactId => (dispatch, getState) => {
  const { api: { currentLocation }, settings: { statuses } } = getState();

  if (!currentLocation || !currentLocation.id) {
    throw new Error('You must select a location to start an order');
  }

  const storeOrder = {
    orderItems: [],
    locationId: currentLocation.id,
    type: 'Delivery',
    asap: true,
    requestedDateTime: moment().format(),
    contactId: prepopulatedContactId,
    orderStatusId: getStatusId('Created', 'Delivery', statuses),
  };

  dispatch(setStoreOrder(storeOrder)); // to initialize the wizard

  createOrderAPI(dispatch, storeOrder).then(response => {
    dispatch(setStoreOrder(response));
  }).catch(err => {
    console.error(err);
  });
};

export const updateStoreOrderAPI = () => {
  return (dispatch, getState) => {
    const {
      storeReducer: {
        storeOrder,
      },
    } = getState();

    const sanitizedStoreOrder = removeTemporaryIds(storeOrder);

    return updateOrderAPI(dispatch, sanitizedStoreOrder).then(response => {
      dispatch(setStoreOrder(response));
    })
      .catch(err => {
        console.error(err);
      });
  };
};

export const replaceStoreOrderAPI = storeOrder => {
  return dispatch => {

    return updateOrderAPI(dispatch, storeOrder).then(response => {
      dispatch(orderChitChatSystemMessage('Order changed'));
      dispatch(setStoreOrder(response));
      dispatch(setStoreOriginalOrder(_.cloneDeep(response)));
    })
      .catch(err => {
        console.error(err);
      });
  };
};

export const deleteWorkingStoreOrder = () => {
  return (dispatch, getState) => {
    const {
      storeReducer: {
        storeOrder,
      },
    } = getState();

    return dispatch(deleteStoreOrder(storeOrder.id));
  };
};

export function deleteStoreOrder(id) {
  return dispatch => dispatch(callApi(`order/${id}`, {
    method: 'DELETE',
  }))
    .catch(console.error);
}

const getFullOrdersApi = (dispatch, params) => {
  return dispatch(callApi(`order?${params}`)).then(result => result.json())
    .catch(console.error);
};

const getListOrdersApi = (dispatch, params) => {
  return dispatch(callApi(`order/list?${params}`)).then(result => result.json())
    .catch(console.error);
};

const getOrderWidgetData = (dispatch, params) => {
  return dispatch(callApi(`OrderWidget?${params}`)).then(result => result.json())
    .catch(console.error);
};

export const saveOrders = orders => {
  let okay = true;
  let status = 0;
  let statusText;

  return dispatch => dispatch(callApi(`orders/BatchSave`, {
    body: orders,
    method: 'POST',
  })).then(result => {
    if (!result.ok) {
      status = result.status;
      statusText = result.statusText;
      okay = false;
    }

    return result.json();
  }).then(json => {
    if (okay) {
      return json;
    }
    if (json.message) {
      throw Error(json.message);
    }
    if (statusText || status) {
      throw Error(`${status} - ${statusText}`);
    }
    throw Error('unknown');
  }).then(() => {
    dispatch(getOrders({}, REQUEST_TYPES.forceCacheUpdate));
  });
};

export const createOrderAPI = (dispatch, order) => {
  return dispatch(callApi(`order`, { body: order }))
    .then(result => {
      if (result.ok) {
        return result.json();
      }

      throw new Error('Something went wrong.');
    });
};

export const updateOrderAPI = (dispatch, order) => {
  return dispatch(callApi(`order/${order.id}`, { body: order, method: 'PUT' }))
    .then(result => {
      if (result.ok) {
        return result.json();
      }

      throw new Error('Something went wrong.');
    });
};

export const applyCoupon = (orderId, couponCode, couponId = null) => {
  return dispatch => dispatch(callApi(`order/${orderId}/${couponId ? 'CouponId' : 'CouponCode'}/${couponId ? couponId : couponCode}`, { method: 'POST' }))
    .then(result => {
      if (result.status === 204) {
        return null;
      }
      else if (result.ok) {
        return result.json();
      }

      throw new Error('Something went wrong.');
    })
    .then(order => {
      if (order !== null) {
        dispatch(orderReceived(order));
        dispatch(setStoreOrder(order));
        dispatch(setStoreOriginalOrder(order));
      }

      return order;
    });
};

export const removeCoupon = orderId => {
  return dispatch => dispatch(callApi(`order/${orderId}/Coupon`, { method: 'DELETE' }))
    .then(result => {
      if (result.ok) {
        return result.json();
      }

      throw new Error('Something went wrong.');
    })
    .then(order => {
      dispatch(orderReceived(order));
      dispatch(setStoreOrder(order));
      dispatch(setStoreOriginalOrder(order));

      return order;
    });
};

export const getOrder = orderId => {
  return dispatch => dispatch(callApi(`order/${orderId}`))
    .then(result => {
      if (result.ok) return result.json();
      throw new Error('Something went wrong.', result);
    })
    .then(order => {
      dispatch(orderReceived(order));
      dispatch(setStoreOrder(order));
      dispatch(setStoreOriginalOrder(order));

      return order;
    });
};

export const deleteOrder = orderId => {
  return dispatch => dispatch(callApi(`orders/${orderId}`, { method: 'DELETE' })).then(response => {
    dispatch(orderDeleted(response));
  });
};

export const clearCurrentOrder = () => {
  return dispatch => {
    dispatch(currentOrderReset());
  };
};

export const cloneOrder = (orderId, cloneOptions) => dispatch => {
  return dispatch(callApi(`order/${orderId}/clone`, { method: 'POST', body: cloneOptions }))
    .then(result => {
      if (result.ok) return result.json();
      throw new Error('Something went wrong.');
    });
};

export function getOrderColumns() {
  return dispatch => dispatch(callApi('userSetting/order_columns'))
    .then(response => response.json())
    .then(columnString => {
      const columnList = columnString
        ? columnString.split(',')
        : [];

      dispatch(orderColumnsReceived(columnList));

      return columnList;
    })
    .catch(error => {
      console.error(error);
    });
}

export const saveOrderColumns = columns => dispatch => {
  const setting = columns.length ? columns.join() : defaultOrdersGridColumns.default.join();

  dispatch(callApi('userSetting/order_columns', { method: 'PUT', body: setting }))
    .then(response => response.json())
    .then(dispatch(orderColumnsReceived(columns)));
};

export const reorderOrderColumns = columns => dispatch => {
  dispatch(callApi('userSetting/order_columns', { method: 'PUT', body: columns.join() }))
    .then(response => response.json());
};

export const getOrdersByContact = contactId => dispatch => {
  const filterByContact = toODataString({ filter: { filters: [{ field: 'contactId', operator: 'eq', value: contactId }] } });

  return getFullOrdersApi(dispatch, filterByContact);
};

export const updateContactAddressesAndStoreOrderAPI = () => (dispatch, getState) => {
  const {
    contact: {
      currentContact,
    },
    address: {
      workingAddresses,
      selectedAddressId,
    },
    storeReducer: {
      storeOrder,
    },
  } = getState();
  const selectedAddress = workingAddresses.find(a => a.id === selectedAddressId);

  if (dispatch(isAddressCollectionChanged()) === true) {
    const updatedContact = {
      ...currentContact,
      contactAddresses: [...workingAddresses],
    };

    return dispatch(saveContact(updatedContact)).then(contact => {
      dispatch(orderChitChatSystemMessage('Contact Address updated'));
      dispatch(initWorkingAddresses(contact.contactAddresses)).then(() => {
        let updatedSelectedAddress;

        if (selectedAddressId < 0) { // a new address was created, so find the updated address
          updatedSelectedAddress = selectedAddress.address1 && workingAddresses.find(a => a.address1 === selectedAddress.address1);
        } else {
          updatedSelectedAddress = workingAddresses.find(a => a.id === selectedAddressId);
        }

        if (updatedSelectedAddress) {
          dispatch(workingAddressesSelectAddress(updatedSelectedAddress.id));

          return dispatch(updateOrderDeliveryWithNewAddress(contact, updatedSelectedAddress));
        }
        throw new Error('Something went wrong with that address change. Please try again.');
      });
    });
  } else {
    const updatedOrder = {
      ...storeOrder,
      orderDelivery: mapAddressToOrderDelivery(selectedAddress, storeOrder),
    };

    return dispatch(replaceStoreOrderAPI(updatedOrder));
  }
};

const updateOrderDeliveryWithNewAddress = (contact, selectedAddress) => (dispatch, getState) => {
  const {
    storeReducer: {
      storeOrder,
    },
  } = getState();

  const newAddress = contact.contactAddresses.find(a => a.address1 === selectedAddress.address1 && a.postalCode === selectedAddress.postalCode);

  if (newAddress && newAddress.id) {
    const updatedStoreOrder = {
      ...storeOrder,
      orderDelivery: mapAddressToOrderDelivery(newAddress, storeOrder),
    };

    dispatch(workingAddressesSelectAddress(newAddress.id));

    return dispatch(replaceStoreOrderAPI(updatedStoreOrder));
  } else {
    throw new Error('Address was not saved successfully. Please include a Postal Code and try again.');
  }
};

const isAddressCollectionChanged = () => (dispatch, getState) => {
  const {
    address: {
      workingAddresses,
      workingAddressesRef,
    },
  } = getState();

  if (workingAddresses && workingAddressesRef && workingAddresses.length !== workingAddressesRef.length) return true;
  if (workingAddresses.some(a => a.id < 0)) return true;

  const stateKvps = workingAddresses.flatMap(toKvpString);
  const refKvps = workingAddressesRef.flatMap(toKvpString);

  return refKvps.some(refKvp => stateKvps.findIndex(stateKvp => stateKvp === refKvp) === -1);
};

// For change-checking, turn every key-value-pair into a unique string with ID.
const toKvpString = address => {
  return Object.entries(address).map(kvp => `${address.id}-${[kvp[0]]}-${kvp[1]}`);
};

export const mapAddressToOrderDelivery = (address, storeOrder) => {
  if (storeOrder.type === 'Delivery' && address) {
    const updatedOrderDelivery = {
      ...storeOrder.orderDelivery,
      orderId: storeOrder.id,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      stateProvince: address.stateProvince,
      postalCode: address.postalCode,
      country: address.country,
      latitude: address.latitude,
      longitude: address.longitude,
      type: address.type,
      contactAddressId: address.id,
    };

    if (storeOrder.orderDelivery && storeOrder.orderDelivery.id) updatedOrderDelivery.id = storeOrder.orderDelivery.id;

    return updatedOrderDelivery;
  }

  return null; // only set orderDelivery if is Delivery
};

const removeTemporaryIds = storeOrder => {
  if (storeOrder.orderDelivery && storeOrder.orderDelivery.contactAddressId < 0) delete storeOrder.orderDelivery.contactAddressId;

  return storeOrder;
};

export const orderChitChatSystemMessage = message => (dispatch, getState) => {
  const {
    storeReducer: {
      storeOrder,
    },
  } = getState();
  let chitChatRecordName = 'Order ' + storeOrder.id;

  if (storeOrder.customerName) chitChatRecordName += ` - ${storeOrder.customerName}`;
  const relationship = {
    id: storeOrder.id,
    entityType: ENTITY_TYPES.order,
  };

  return dispatch(logChitChatSystemMessage(message, relationship, chitChatRecordName));
};

export const getStatusId = (systemStatusCode, orderType, statuses) => {
  if (statuses && orderType) {
    if (orderType === 'Pickup') {
      const pickupStatuses = statuses.find(collection => collection.type === 'PickupOrder');

      if (pickupStatuses && pickupStatuses.statuses && pickupStatuses.statuses.length > 0) {
        const found = pickupStatuses.statuses.find(status => status.systemStatusCode === systemStatusCode);

        if (found) return found.id;
      }
    } else if (orderType === 'Delivery') {
      const deliveryStatuses = statuses.find(collection => collection.type === 'DeliveryOrder');

      if (deliveryStatuses && deliveryStatuses.statuses && deliveryStatuses.statuses.length > 0) {
        const found = deliveryStatuses.statuses.find(status => status.systemStatusCode === systemStatusCode);

        if (found) return found.id;
      }
    }
  }
};
