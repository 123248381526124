import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = theme => {
  return {
    dayOfWeek: {
      fontSize: 13,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
    },
    dayOfMonth: {
      fontWeight: 'bold',
      fontSize: 11,
    },
    container: {
      display: 'flex',
      textAlign: 'center',
    },
    weatherConatiner: {
      flexGrow: 1,
      textAlign: 'center',
    },
    dateContainer: {
      width: 63,
    },
  };
};

class HeaderDayAtGlance extends Component {

  render() {
    const {
      date,
      classes,
      palette,
    } = this.props;
    const momentForDay = moment(date);
    const dayOfWeek = momentForDay.format('ddd');
    const dayOfMonth = momentForDay.format('D');

    return (
      <div className={classes.container}>
        <div className={classes.dateContainer}>
          <div className={classes.dayOfWeek} style={{ color: palette.primary.main }}>{dayOfWeek}</div>
          <div className={classes.dayOfMonth} style={{ color: palette.grey.A200 }}>{dayOfMonth}</div>
        </div>
        <div className={classes.weatherConatiner}>
          <img src="/images/cloudy.png" alt="cloudy" className={classes.logo}></img>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(HeaderDayAtGlance);
