import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SectionHeader from 'Components/SectionHeader';
import TextField from 'Components/TextField';
import Checkbox from 'Components/Checkbox';
import CoverDatePicker from 'Components/CoverDatePicker';
import { getClonePreferences, saveClonePreferences, cloneBooking } from 'actions/booking';
import moment from 'moment';

const styles = theme => ({
  halfWidthColumn: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: theme.palette.grey[200],
    fontSize: 15,
    paddingLeft: 16,
  },
  spaceSections: {
    marginTop: 5,
    marginBottom: 15,
  },
  startEndTimes: {
    margin: '16px 16px 12px 8px',
    flexGrow: 1,
    alignSelf: 'flex-end',
    fontSize: 16,
    color: theme.palette.grey[700],
  },
});

class Clone extends Component {
  state = {
    isOpened: false,
    booking: {},
    clonePreferences: this.props.clonePreferences,
  };

  open = existingBooking => {
    this.props.getClonePreferences().then(clonePreferences => this.setState({ clonePreferences }));

    this.setState({
      isOpened: true,
      booking: {
        ...existingBooking,
        name: existingBooking.name + ' (Copy)', //pass in booking (and isopened) via props is probably better once we have an API
        selectedStartTime: existingBooking.startDateTime,
      },
    });
  }

  close = () => {
    this.setState({ isOpened: false });
  }

  isUserPreference = option => {
    const { clonePreferences } = this.state;

    return !!clonePreferences ? clonePreferences.indexOf(option) !== -1 : false;
  }

  handleCheckbox = fieldName => event => {
    const isChecked = event.target.checked;
    const clonePreferences = [...this.state.clonePreferences];
    const indexOfPreference = clonePreferences.indexOf(fieldName);

    if (isChecked) {
      if (indexOfPreference === -1) {
        clonePreferences.push(fieldName);
      }
    } else {
      if (indexOfPreference !== -1) {
        clonePreferences.splice(indexOfPreference, 1);
      }
    }

    this.setState({ clonePreferences });
  }

  handleSave = () => {
    const { booking, clonePreferences } = this.state;

    this.props.saveClonePreferences(clonePreferences);
    this.props.cloneBooking(booking, clonePreferences)
      .then(newBookingId => {
        this.close();
        this.props.onCloneSuccess(newBookingId);
      });
  }

  handleChange = fieldName => value => {
    const booking = {
      ...this.state.booking,
      [fieldName]: value,
    };

    this.setState({ booking });
  }

  handleStartDateChange = value => {
    const { booking } = this.state;
    const dateValue = moment(value);
    const startDateTime = moment(booking.startDateTime);

    const newStartDate = dateValue.set({
      'hour': startDateTime.hour(),
      'minute': startDateTime.minute(),
    }).toDate();

    this.handleChange('selectedStartTime')(newStartDate);
  }

  render() {
    const { classes } = this.props;
    const {
      isOpened,
      booking,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        onCancel={this.close}
        title={"Clone Booking"}
        onSave={this.handleSave}
        saveText="Clone"
      >
        <SectionHeader>
          New Booking
        </SectionHeader>
        <div className={classes.flex}>
          <TextField
            label="Booking Name"
            value={booking.name}
            onFieldChange={this.handleChange('name')}
          />
          <div className={classes.flex}>
            <CoverDatePicker
              label="Date"
              value={booking.selectedStartTime}
              onFieldChange={this.handleStartDateChange}
              minDate={moment().subtract(10, 'years').toDate()}
            />
            <div className={classes.startEndTimes}>
              {`${moment(booking.startDateTime).format('LT')} - ${moment(booking.endDateTime).format('LT')}`}
            </div>
          </div>
        </div>
        <SectionHeader className={classes.spaceSections}>
          Sections to Clone
        </SectionHeader>
        <div className={classes.flex}>
          <div className={classes.flexColumn}>
            <div className={classes.label}>
              Details
            </div>
            <Checkbox
              label="General"
              checked={this.isUserPreference('general')}
              onChange={this.handleCheckbox('general')}
            />
            <Checkbox
              label="Guest Details"
              checked={this.isUserPreference('guestDetails')}
              onChange={this.handleCheckbox('guestDetails')}
            />
            <Checkbox
              label="Venue Details"
              checked={this.isUserPreference('venueDetails')}
              onChange={this.handleCheckbox('venueDetails')}
            />
            <Checkbox
              label="Site Details"
              checked={this.isUserPreference('siteDetails')}
              onChange={this.handleCheckbox('siteDetails')}
            />
            <Checkbox
              label="Shifts"
              checked={this.isUserPreference('shifts')}
              onChange={this.handleCheckbox('shifts')}
            />
            <Checkbox
              label="Financials"
              checked={this.isUserPreference('financials')}
              onChange={this.handleCheckbox('financials')}
            />
          </div>
          <div className={classes.flexColumn}>
            <div className={classes.label}>
              Menu
            </div>
            <Checkbox
              label="Name"
              checked={this.isUserPreference('menuName')}
              onChange={this.handleCheckbox('menuName')}
            />
            <Checkbox
              label="Sections"
              checked={this.isUserPreference('menuSections')}
              onChange={this.handleCheckbox('menuSections')}
            />
            <Checkbox
              label="Quantities"
              checked={this.isUserPreference('menuQuantities')}
              onChange={this.handleCheckbox('menuQuantities')}
            />
          </div>
          <div className={classes.flexColumn}>
            <div className={classes.label}>
              Chit Chat
            </div>
            <Checkbox
              label="Notes"
              checked={this.isUserPreference('notes')}
              onChange={this.handleCheckbox('notes')}
            />
            <Checkbox
              label="Emails"
              checked={this.isUserPreference('emails')}
              onChange={this.handleCheckbox('emails')}
            />
            <Checkbox
              label="Tasks"
              checked={this.isUserPreference('tasks')}
              onChange={this.handleCheckbox('tasks')}
            />
            <Checkbox
              label="Posts"
              checked={this.isUserPreference('posts')}
              onChange={this.handleCheckbox('posts')}
            />
          </div>
          <div className={classes.flexColumn}>
            <div className={classes.label}>
              Other
            </div>
            <Checkbox
              label="Files"
              checked={this.isUserPreference('files')}
              onChange={this.handleCheckbox('files')}
            />
            <Checkbox
              label="Activities"
              checked={this.isUserPreference('activities')}
              onChange={this.handleCheckbox('activities')}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    settings: {
      bookings: {
        clonePreferences,
      },
    },
  } = state;

  return {
    clonePreferences,
  };
};

const mapDispatchToProps = {
  getClonePreferences,
  saveClonePreferences,
  cloneBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Clone));
