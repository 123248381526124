import React, { useContext } from 'react';
import { connect } from 'react-redux';
import StatusStepper from 'Components/Stepper/StatusStepper';
import BookingContext from './BookingContext';

const BookingStatusBar = ({ storeBooking }) => {
  const { statusHistory, onUpdateStatus, closeBooking } = useContext(BookingContext);

  const handleBannerConfirm = statusId => {
    onUpdateStatus(statusId);
  };

  return (
    <StatusStepper
      type="Booking"
      activeStatusId={storeBooking.statusId}
      onBannerConfirm={handleBannerConfirm}
      onCompleteFinalStep={closeBooking}
      statusHistory={statusHistory}
    />
  );
};

const mapStateToProps = state => {
  const {
    booking: {
      manager: {
        storeBooking,
      },
    },
  } = state;

  return {
    storeBooking,
  };
};

export default connect(mapStateToProps, undefined)(BookingStatusBar);
