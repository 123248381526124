import React, { useContext } from 'react';
import StatusStepper from 'Components/Stepper/StatusStepper';
import LeadContext from './LeadContext';

const LeadStatusBar = () => {
  const { lead, statusHistory, onUpdateStatus, onLastStep } = useContext(LeadContext);

  const handleBannerConfirm = statusId => {
    onUpdateStatus(statusId);
  };

  return (
    <StatusStepper
      activeStatusId={lead.statusId}
      finalStepIndex={5}
      onBannerConfirm={handleBannerConfirm}
      onCompleteFinalStep={onLastStep}
      type="Lead"
      statusHistory={statusHistory}
    />
  );
};

export default LeadStatusBar;
