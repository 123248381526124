import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { InsertDriveFile as NoteIcon } from '@material-ui/icons';
import { Menu, MenuItem } from '@material-ui/core';
import copy from 'clipboard-copy';
import SimpleDialog from 'Components/SimpleDialog';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';
import classNames from 'classnames';
import moment from 'moment';

const styles = theme => ({
  note: {
    display: 'flex',
    flexGrow: 1,
    padding: 8,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    minHeight: 57,
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginTop: 2,
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: 18,
  },
  contentColumn: {
    flexGrow: 1,
    padding: '0 8px',
    whiteSpace: 'normal',
  },
  madeOn: {
    display: 'flex',
    marginBottom: 2,
    color: theme.palette.grey[200],
  },
  title: {
    color: theme.palette.primary.dark,
  },
  noteIcon: {
    height: 24,
    width: 24,
    color: theme.palette.common.white,
  },
  purple: {
    backgroundColor: theme.common.purple,
  },
  blue: {
    backgroundColor: theme.palette.primary.dark,
  },
});

class Note extends Component {

  state = {
    optionsMenu: null,
  };

  confirmDeleteDialog = React.createRef();

  openOptionsMenu = event => {
    this.setState({ optionsMenu: event.currentTarget });
  };

  closeOptionsMenu = () => {
    this.setState({ optionsMenu: null });
  };

  onEditNote = () => {
    const { note, onEdit } = this.props;

    this.closeOptionsMenu();
    onEdit && onEdit(note);
  }

  onDeleteNote = () => {
    const { note, onDelete } = this.props;

    this.closeOptionsMenu();
    this.confirmDeleteDialog.current.open()
      .then(() => {
        onDelete && onDelete(note.id);
      })
      .catch(() => {/* noop */});
  }

  onCopyNote = () => {
    this.closeOptionsMenu();
    const { note } = this.props;

    copy(note.subject + ": " + note.body);
  }

  buildDateLine = () => {
    const { note } = this.props;

    if (!note.noteBy && !note.dateAdded) {
      return null;
    }

    if (note.noteBy && note.dateAdded) {
      return `${moment(note.dateAdded).format('l')} - ${note.noteBy}`;
    }

    return note.dateAdded ? moment(note.dateAdded).format('l') : note.noteBy;
  }

  render() {
    const { classes, note, color } = this.props;
    const { optionsMenu } = this.state;
    const dateLine = this.buildDateLine();

    return (
      <div className={classes.note}>
        <div className={classNames(classes.iconBox, classes[color], classes.noteIcon)}>
          <NoteIcon classes={{ root: classes.icon }} />
        </div>
        <div className={classes.contentColumn}>
          <p className={classNames(classes.title)}>{note.subject}</p>
          {dateLine && <p className={classNames(classes.madeOn, classes.noteSmall)}>
            {dateLine}
          </p>}
          <p
            dangerouslySetInnerHTML={{ __html: note.body }}
          >
          </p>
        </div>
        <div>
          <ArrowDownButton
            onClick={this.openOptionsMenu}
            aria-label="Note options"
            aria-owns="note-menu"
            aria-haspopup="true"
          >
          </ArrowDownButton>
          <Menu
            id="note-menu"
            anchorEl={optionsMenu}
            open={Boolean(optionsMenu)}
            onClose={this.closeOptionsMenu}
          >
            <MenuItem onClick={this.onEditNote}>Edit</MenuItem>
            <MenuItem onClick={this.onCopyNote}>Copy</MenuItem>
            <MenuItem onClick={this.onDeleteNote}>Delete</MenuItem>
          </Menu>
        </div>
        <SimpleDialog
          message="Are you sure you want to delete this note?"
          innerRef={this.confirmDeleteDialog}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Note);
