import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SectionHeader from 'Components/SectionHeader';
import Grid from '@material-ui/core/Grid';
import PickListEdit from 'Components/PickList/PickListEdit';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  button: {
    color: theme.palette.secondary.main,
    textTransform: 'none',
  },
  gridItem: {
    textAlign: 'center',
  },
  gridReversePadding: {
    padding: '12px',
  },
  gridContainer: {
    backgroundColor: theme.palette.background.default,
  },
  gridContainerWhiteBG: {
    backgroundColor: 'white',
  },
});

export class ManageTemplatesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickListItems: [...props.options],
    };
  }

  updateItems = pickListItems => {
    this.setState({ pickListItems });
  }

  render() {
    const { classes, onModalClosed } = this.props;
    const { pickListItems } = this.state;

    return (
      <Modal
        isOpened={true}
        onCancel={onModalClosed}
        onSave={onModalClosed} //TODO
        title={"Manage Templates"}
        addTitleBottomBorder={true}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '775px' }}
      >

        <div>
          <SectionHeader>
            Categories
          </SectionHeader>
          <div className={classes.gridReversePadding}>
            <Grid container spacing={12} className={classes.gridContainerWhiteBG}>
              <Grid item xs={6}>
                <div style={{ display: 'inline-block', width: '24rem', paddingRight: '20px' }}>
                  <div style={{ display: 'inline-block', width: '24rem' }}><PickListEdit items={pickListItems} updateItems={this.updateItems} /></div>
                </div>
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Button variant="outlined" className={classes.button}>
                  Save As New Template
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal >);
  }
}

export default withStyles(styles)(ManageTemplatesModal);
