export const widgetColor = {
  primary: 'primary',
  secondary: 'secondary',
};

export const widgetGraphs = {
  itemLibraryProducts: 'itemLibraryProducts',
  itemLibraryPrice: 'itemLibraryPrice',
  itemLibraryCost: 'itemLibraryCost',
  contactsCount: 'contactsCount',
  contactsSpend: 'contactsSpend',
  contactsEvents: 'contactsEvents',
  dashboardEvents: 'dashboardEvents',
  bookingsCount: 'bookingsCount',
  bookingsType: 'bookingsType',
  bookingEvents: 'bookingEvents',
  ordersCount: 'ordersCount',
  ordersSpend: 'ordersSpend',
  ordersEvents: 'ordersEvents',
};

export const oneDimensionalWidgetGraphType = {
  donut: 'donut',
  progress: 'progress',
};

export const twoDimensionalWidgetGraphType = {
  bar: 'bar',
  line: 'line',
};

export const widgetGraphUnits = {
  dollars: 'dollars',
  none: 'none',
  percent: 'percent',
};
