import React, { Component } from 'react';

class Preferences extends Component {

  render() {
    return (
      <h3> Preferences general </h3>
    );
  }
}

export default Preferences;
