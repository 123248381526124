import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { addMenuCategoryToEvent } from 'actions/eventMenu';
import { ArrowDropDown } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import HighlightButton from 'Components/Buttons/HighlightButton';
import {
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = theme => ({
  miniButton: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    minHeight: 32,
    padding: '0 15px',
    textTransform: 'initial',
    fontWeight: 400,
    borderRadius: 0,
    borderRight: 'none',
    '&:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '0 4px 4px 0',
    },
  },
  arrowDown: {
    color: theme.palette.common.black,
    padding: 0,
    minWidth: 0,
    marginRight: 10,
  },
});

class NewButton extends Component {
  state = {};

  openSelectPackagesModal = () => {
    const {
      enterEditMode,
    } = this.props;

    this.setState({ toolsOpenedBy: null });
    if (enterEditMode) {
      enterEditMode();
    }
    this.props.openSelectPackagesModal();
  }

  addMenuSection = () => {
    const {
      addMenuCategoryToEvent,
      // newSectionName,
      onMenuSectionAdded = () => { },
      enterEditMode,
    } = this.props;

    this.closeToolsMenu();
    if (enterEditMode) {
      enterEditMode();
    }

    addMenuCategoryToEvent().then(onMenuSectionAdded);
  }

  render = () => {
    const {
      classes,
      openSelectPackagesModal,
      isToolbarStyle,
    } = this.props;

    const toolsOpenedBy = this.state.toolsOpenedBy;

    return (<>
      {isToolbarStyle ?
        <HighlightButton
          onClick={this.openToolsMenu}
          aria-label={`Add to Menu`}
          className={classes.spaceButtons}
        >
          <AddIcon />
        </HighlightButton>
        :
        <>
          <Button className={classes.miniButton} onClick={this.addMenuSection}>New</Button>
          <Button
            className={classNames(classes.miniButton, classes.arrowDown)}
            onClick={this.openToolsMenu}
          >
            <ArrowDropDown />
          </Button>
        </>
      }

      <Menu
        id="menu-picker"
        anchorEl={toolsOpenedBy}
        open={Boolean(toolsOpenedBy)}
        onClose={this.closeToolsMenu}
      >
        {openSelectPackagesModal && <MenuItem onClick={this.openSelectPackagesModal}>Select Packages</MenuItem>}
        <MenuItem onClick={this.addMenuSection}>Add Menu Section</MenuItem>
      </Menu>
    </>);
  }

  openToolsMenu = event => {
    this.setState({ toolsOpenedBy: event.currentTarget });
  }

  closeToolsMenu = () => {
    this.setState({ toolsOpenedBy: null });
  }
}

const mapDispatchToProps = {
  addMenuCategoryToEvent,
};

NewButton.propTypes = {
  openSelectPackagesModal: PropTypes.func,
  isToolbarStyle: PropTypes.bool,
  newSectionName: PropTypes.string,
  enterEditMode: PropTypes.func,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(NewButton));
