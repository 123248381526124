import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    verticalAlign: 'top',
    display: 'inline-block',
    paddingBottom: '25px',
  },
});

export class CompanyBullet extends Component {
  render() {
    const { label, line1, line2, theme, classes } = this.props;
    let flex = 1;

    if (line1 && line1.length > 40) {
      flex = 2;
    }

    return (
      <div style={{ flex }} className={classes.container}>
        {label && <div style={{ color: theme.palette.grey[300] }}>{label}</div>}
        {line1 && <div style={{ color: theme.palette.dark }}>{line1}</div>}
        {line2 && <div style={{ color: theme.palette.grey[200] }}>{line2}</div>}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CompanyBullet);
