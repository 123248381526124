import {
  ADD_TO_RECENTS,
  REMOVE_RECENT,
  RESET_RECENTS,
} from './constants';

export const addToRecents = (record, url, recordType) => ({
  type: ADD_TO_RECENTS,
  record,
  url,
  recordType,
});

export const removeRecent = (id, recordType) => ({
  type: REMOVE_RECENT,
  id,
  recordType,
});

export const resetRecents = () => ({
  type: RESET_RECENTS,
});
