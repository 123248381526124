import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core/styles';
import { GridColumn } from '@progress/kendo-react-grid';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Clear as DeleteIcon,
} from '@material-ui/icons';
import Grid from 'Components/Grid/Grid';
import HighlightButton from 'Components/Buttons/HighlightButton';
import TextField from 'Components/TextField';
import Checkbox from 'Components/Checkbox';
import { formatMoney } from 'helpers/moneyHelpers';
import {
  getDeliveryZones,
  createDeliveryZone,
  updateDeliveryZone,
  deleteDeliveryZone,
} from 'actions/deliveryZones';
import {
  getSettingAreas,
  saveSettingValues,
} from 'actions/settings';
import IDeliveryZone from 'models/IDeliveryZone';
import DeliveryZoneType from 'models/DeliveryZoneType';
import { groupBy } from 'helpers/groupBy';
import Modal from 'Components/Modal';
import SaveBar from 'Components/SaveBar';
import SimpleDialog from 'Components/SimpleDialog';

const dialog = React.createRef() as any;

const styles = createStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    maxWidth: 1060,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 16px',
  },
  topButtons: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    display: 'flex',
    margin: 8,
  },
  tableContainer: {
  },
  gridElement: {
    padding: 0,
  },
  undeliverableContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  undeliverableCheckbox: {
    height: 47,
  },
  undeliverableCheckboxLabel: {
    fontSize: 16,
  },
  chargeColumn: {
    textAlign: 'right !important',
  },
  fieldsRowContainer: {
    display: 'flex',
  },
  modifyIcon: {
    cursor: 'pointer',
    marginRight: 7,
    marginLeft: 3,
    color: theme.palette.grey[700],
    fontSize: 26,
  },
  errorMessage: {
    color: '#ff0000',
    textAlign: 'center',
  },
  zoneRules: {
    textAlign: 'justify',
    whiteSpace: 'initial',
  },
  undeliverableMessage: {
    width: '100%',
    margin: '10px 0',
  },
  placeholderMessage: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
  },
}));

const NameField = [
  "",
  "Postal Code",
  "Distance (Miles)",
  "Time (Minutes)",
];

const GRAZE_ORDER_UNDELIVERABLE_ZONE = 'GRAZE_ORDER_UNDELIVERABLE_ZONE';

interface IProps extends WithStyles {
  selectedSettingId: number;
  undeliverableZoneMessage: string;
  getDeliveryZones: () => Promise<IDeliveryZone[]>;
  createDeliveryZone: (DeliveryZone: IDeliveryZone) => Promise<IDeliveryZone>;
  updateDeliveryZone: (DeliveryZone: IDeliveryZone) => Promise<IDeliveryZone>;
  deleteDeliveryZone: (DeliveryZoneId: number) => void;
  getSettingAreas: () => Promise<any>;
  saveSettingValues: (settingsKeyValues: { [key: string]: any }) => Promise<any>;
  company: any;
}

interface IState {
  selectedDeliveryZone: IDeliveryZone,
  groupedDeliveryZones: { [key: string]: IDeliveryZone[] },
  undeliverableZoneMessage: string,
}

const defaultState: IState = {
  selectedDeliveryZone: null,
  groupedDeliveryZones: {},
  undeliverableZoneMessage: '',
}

class DeliveryZones extends Component<IProps, IState> {
  state = defaultState;

  private openZoneModal = (deliveryZone: IDeliveryZone = null) => {
    const selectedDeliveryZone = deliveryZone
      ? deliveryZone
      : {
        type: this.getDeliveryZoneType(),
      } as IDeliveryZone;

    this.setState({ selectedDeliveryZone });
  }

  private closeAddZoneModal = () => {
    this.setState({ selectedDeliveryZone: null });
  }

  private setDeliveryZoneProp = (key: string) => (value: any) => {
    const selectedDeliveryZone = {
      ...this.state.selectedDeliveryZone,
      [key]: value,
    };

    this.setState({ selectedDeliveryZone })
  }

  private saveDeliveryZone = async () => {
    const { createDeliveryZone, updateDeliveryZone } = this.props;
    const deliveryZone = { ...this.state.selectedDeliveryZone };
    const newDeliveryZone = !deliveryZone.id;

    this.setState({ selectedDeliveryZone: null });

    if (newDeliveryZone) {
      await createDeliveryZone(deliveryZone);
    } else {
      await updateDeliveryZone(deliveryZone);
    }

    this.getDeliveryZones();
  }

  private deleteDeliveryZone = async (deliveryZone: IDeliveryZone) => {
    const { deleteDeliveryZone } = this.props;

    return dialog.current.open('Are you sure you want to delete this zone?').then(async () => {
      await deleteDeliveryZone(deliveryZone.id);
      this.getDeliveryZones();
  });
  }

  private getDeliveryZoneType = () => {
    const { selectedSettingId } = this.props;

    return Object.keys(DeliveryZoneType)[selectedSettingId - 1];
  }

  private getDeliveryZones = async () => {
    const deliveryZones = await this.props.getDeliveryZones();
    const groupedDeliveryZones = groupBy(deliveryZones, 'type');

    this.setState({ groupedDeliveryZones });
  }

  private saveUndeliverableMessage = async () => {
    await this.props.saveSettingValues({ [GRAZE_ORDER_UNDELIVERABLE_ZONE]: this.state.undeliverableZoneMessage });
    this.props.getSettingAreas();
  }

  private cancelUndeliverableMessageEdit = () => {
    this.setState({ undeliverableZoneMessage: this.props.undeliverableZoneMessage });
  }

  public async componentDidMount() {
    this.setState({ undeliverableZoneMessage: this.props.undeliverableZoneMessage });
    this.getDeliveryZones();
    this.props.getSettingAreas();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.undeliverableZoneMessage !== this.props.undeliverableZoneMessage) {
      this.setState({ undeliverableZoneMessage: this.props.undeliverableZoneMessage });
    }
  }

  public render() {
    const { classes, selectedSettingId, company } = this.props;
    const { groupedDeliveryZones, selectedDeliveryZone, undeliverableZoneMessage } = this.state;
    const selectedDeliveryZoneType = this.getDeliveryZoneType();
    const selectedTypeZones = groupedDeliveryZones[selectedDeliveryZoneType];
    const isUndeliverableMessageChange = undeliverableZoneMessage != this.props.undeliverableZoneMessage;
    const noCompanyAddress = !company || !company.address || !company.city || !company.state || !company.postalCode;
    const modalMode = !!selectedDeliveryZone && !!selectedDeliveryZone.id ? 'Edit' : 'Add';
    const duplicateValue =
      !!selectedDeliveryZone &&
      (
        !!selectedTypeZones &&
        !!selectedTypeZones.find(zone => zone.id !== selectedDeliveryZone.id && zone.value == selectedDeliveryZone.value)
      );

    return (<>
      {noCompanyAddress
      ? (
        <div className={classes.placeholderMessage}>
          Please set company address above to turn this feature on.
        </div>
      )
      : (
        <div className={classes.container}>
          <div className={classes.main}>
            <div className={classes.content}>
              <div className={classes.tableContainer}>
                <Grid items={(selectedTypeZones ? selectedTypeZones : []).sort((a: IDeliveryZone, b: IDeliveryZone) => a.value > b.value ? 1 : -1)}>
                  <GridColumn
                    field="undeliverable"
                    title="Undeliverable"
                    width="110px"
                    cell={props => (
                      <td style={{ textAlign: 'center', padding: 0 }}>
                        <Checkbox
                          className={classes.gridElement}
                          disabled={true}
                          checked={props.dataItem.undeliverable}
                          onClick={() => {}}
                          onFieldChange={() => {}}
                        />
                      </td>
                    )}
                  />
                  <GridColumn
                    field="value"
                    title={NameField[selectedSettingId]}
                    width="130px"
                    cell={props => (
                      <td style={{ textAlign: 'center', padding: 0 }}>
                        <span className={classes.gridElement}>
                          {
                            selectedDeliveryZoneType === DeliveryZoneType.PostalCode
                              ? props.dataItem.value
                              : `From ${props.dataItem.value} ${selectedDeliveryZoneType === DeliveryZoneType.Distance
                                ? 'miles'
                                : 'minutes'}`
                          }
                        </span>
                      </td>
                    )}
                  />
                  <GridColumn
                    field="charge"
                    title="Charge"
                    width="130px"
                    cell={props => (
                      <td className={classes.chargeColumn}>
                        <span className={classes.gridElement}>
                          {props.dataItem.charge && !props.dataItem.undeliverable ? `$${formatMoney(props.dataItem.charge)}` : ''}
                        </span>
                      </td>
                    )}
                  />
                  <GridColumn field="" title="" width="100px" cell={props => (
                    <td className={classes.crudIcons}>
                      <EditIcon
                        className={classes.modifyIcon}
                        onClick={() => this.openZoneModal(props.dataItem)}
                        aria-label={`Edit Zone`}
                      />
                      <DeleteIcon
                        className={classes.modifyIcon}
                        onClick={() => this.deleteDeliveryZone(props.dataItem)}
                        aria-label={`Delete Zone`}
                      />
                    </td>
                  )}
                  />
                </Grid>
              </div>
            </div>
            <div className={classes.buttons}>
              <div className={classes.topButtons}>
                <div className={classes.button}>
                  <HighlightButton
                    onClick={(event: any) => this.openZoneModal()}
                    aria-label={modalMode}
                    className={classes.spaceButtons}
                  >
                    <AddIcon />
                  </HighlightButton>
                </div>
              </div>
              <div className={classes.zoneRules}>
                For delivery addresses that match multiple rule types (Postal Code, Distance, Travel Time),
                the most expensive delivery charge will apply.
                If an address matches any rule that is "undeliverable",
                that order will be undeliverable.
              </div>
              <div>
                <TextField
                  className={classes.undeliverableMessage}
                  label="Online Order Message If Undeliverable"
                  value={undeliverableZoneMessage}
                  onFieldChange={(value: string) => this.setState({ undeliverableZoneMessage: value })}
                />
              </div>
            </div>
          </div>
          <Modal
            isOpened={selectedDeliveryZone !== null}
            onCancel={this.closeAddZoneModal}
            title={`${modalMode} ${this.getDeliveryZoneType()} Delivery Zone`}
            onSave={this.saveDeliveryZone}
            isSaveDisabled={duplicateValue}
            addTitleBottomBorder={false}
            dimensions={{ width: 'unset', height: 'unset', maxWidth: '525px' }}
          >
            {selectedDeliveryZone !== null && <>
              <div className={classes.fieldsRowContainer}>
                <div className={classes.undeliverableContainer}>
                  <Checkbox
                    className={classes.undeliverableCheckbox}
                    classes={{ label: classes.undeliverableCheckboxLabel }}
                    label="Undeliverable"
                    checked={selectedDeliveryZone.undeliverable}
                    onFieldChange={this.setDeliveryZoneProp('undeliverable')}
                  />
                </div>
                <TextField
                  className={classes.gridElement}
                  label={NameField[selectedSettingId]}
                  value={selectedDeliveryZone.value}
                  type="number"
                  onFieldChange={this.setDeliveryZoneProp('value')}
                />
                <TextField
                  className={classes.gridElement}
                  label="Charge"
                  value={selectedDeliveryZone.undeliverable ? null : selectedDeliveryZone.charge}
                  type="number"
                  disabled={selectedDeliveryZone.undeliverable}
                  onFieldChange={this.setDeliveryZoneProp('charge')}
                />
              </div>
              <div
                style={{ visibility: duplicateValue ? 'visible' : 'hidden' }}
                className={classes.errorMessage}
              >
                This zone already exists.
              </div>
            </>}
          </Modal>
          {isUndeliverableMessageChange && <SaveBar onSave={this.saveUndeliverableMessage} onCancel={this.cancelUndeliverableMessageEdit} />}
          <SimpleDialog innerRef={dialog} />
        </div>
      )
      }</>
    );
  }
};

const mapStateToProps = (state: any) => {
  const {
    admin: {
      company,
    },
    settings: {
      allAreaSettings,
    },
  } = state;

  return {
    company,
    undeliverableZoneMessage: allAreaSettings[GRAZE_ORDER_UNDELIVERABLE_ZONE]
    ? allAreaSettings[GRAZE_ORDER_UNDELIVERABLE_ZONE].value
    : '',
  };
};

const mapDispatchToProps = {
  getDeliveryZones,
  createDeliveryZone,
  updateDeliveryZone,
  deleteDeliveryZone,
  getSettingAreas,
  saveSettingValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeliveryZones));
