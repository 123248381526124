import React, { Component } from 'react';
import { connect } from 'react-redux';
import OneDimensionalGraphWidget from 'Components/GraphWidgets/OneDimensionalGraphWidget';
import BarGraphWidget from 'Components/GraphWidgets/BarGraphWidget';
import { withStyles } from '@material-ui/core/styles';
import { widgetGraphs } from 'helpers/enums';
import { getContactTotalSummary, getSelectedContactTotalSummary } from 'actions/reports';

const styles = () => ({
  graphContainer: {
    display: 'flex',
    flexGrow: 1,
    paddingBottom: '3.2%',
  },
  lastGraphContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  graphGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class ContactGraphs extends Component {
  state = {
    allContacts: {
      contactCount: 0,
      totalSpend: 0,
      totalEvents: 0,
    },
    selectedContacts: {},
  }

  componentDidMount() {
    const getContactTotalSummary = this.props.getContactTotalSummary();

    if (this.props.selectedContacts && this.props.selectedContacts.length === 0) {
      Promise.all([getContactTotalSummary, this.props.getSelectedContactTotalSummary(this.props.selectedIds)])
        .then(([allContacts, selectedContacts]) =>
          this.setState({ allContacts, selectedContacts }));
    } else {
      getContactTotalSummary.then(allContacts => this.setState({ allContacts }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedIds !== this.props.selectedIds) {
      this.props.getSelectedContactTotalSummary(this.props.selectedIds)
        .then(selectedContacts => this.setState({ selectedContacts }));
    }
  }

  render() {
    const {
      classes,
      contactsCountSettings,
      contactsSpendSettings,
      contactsEventsCostSettings,
    } = this.props;

    const {
      allContacts,
      selectedContacts,
    } = this.state;

    return (
      <div className={classes.graphGroupContainer}>
        <div className={classes.graphContainer}>
          <OneDimensionalGraphWidget
            denominator={allContacts.contactCount}
            differenceIndicator={0}
            numerator={selectedContacts.contactCount}
            settings={contactsCountSettings}
          />
        </div>
        <div className={classes.graphContainer}>
          <OneDimensionalGraphWidget
            denominator={allContacts.totalSpend}
            differenceIndicator={0}
            numerator={selectedContacts.totalSpend}
            settings={contactsSpendSettings}
          />
        </div>
        <div className={classes.lastGraphContainer}>
          <BarGraphWidget
            data={allContacts.eventCountsByWeek}
            denominator={allContacts.totalEvents}
            differenceIndicator={6}
            numerator={selectedContacts.totalEvents}
            settings={contactsEventsCostSettings}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    widgets,
    contact: selectedContacts,
  } = state;

  return {
    contactsCountSettings: widgets[widgetGraphs.contactsCount],
    contactsSpendSettings: widgets[widgetGraphs.contactsSpend],
    contactsEventsCostSettings: widgets[widgetGraphs.contactsEvents],
    selectedContacts,
  };
};

const mapDispatchToProps = {
  getContactTotalSummary,
  getSelectedContactTotalSummary,
};

const ContactGraphsContainer = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactGraphs));

export default ContactGraphsContainer;
