import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BookingGrid from './BookingGrid';
import BookingGraphs from './BookingGraphs';

const styles = () => ({
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '3%',
  },
  mainContent: {
    display: 'flex',
    flex: '1 0 auto',
    padding: '24px 24px 0',
  },
});

class BookingList extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainContent}>
        <div className={classes.gridContainer}>
          <BookingGrid
            // onTotalRecordsCalculated={this.getTotalRecords}
            // onBookingSelected={this.bookingsSelected}
          />
        </div>
        <BookingGraphs />
      </div>
    );
  }
}

export default withStyles(styles)(BookingList);
