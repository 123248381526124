import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Prompt } from 'react-router';
import { useHistory } from "react-router-dom";
import { Paper, Fade } from '@material-ui/core';
import DataLoader from 'Components/DataLoader';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MenuSelector from './MenuSelector';
import { getTags } from 'actions/item';
import {
  getMasterMenus,
  addMenu,
  deleteMenu,
  initWorkingMasterMenu,
  updateMenuApi,
  dropAddMenuCategoryItem,
  moveMenuCategoryItem,
  handleMenuFieldChange,
  resetWorkingMasterMenu,
} from 'actions/masterMenuManagement';
import { getMenuItems } from 'actions/menu';
import HighlightButton from 'Components/Buttons/HighlightButton';
import SimpleDialog from 'Components/SimpleDialog';
import Menu from './Menu';
import MenuItemCard from './MenuItemCard';
import {
  Add as AddIcon,
} from '@material-ui/icons';
import MenuItemFilterField from 'Components/Menu/MenuItemFilterField';

const styles = theme => ({
  menuBuilder: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  selectionBarAndButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
  },
  selectionBar: {
    display: 'flex',
    margin: '16px 0',
    width: '95%',
    minHeight: 32,
  },
  content: {
    height: '71vh',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '58%',
  },
  menuItemListContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
  menuItemList: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
  },
  menuItemCard: {
    display: 'flex',
    color: theme.palette.text.primary,
    margin: '8px 2%',
    width: '29%',
  },
  menuItemCardCopy: {
    display: 'flex',
    color: theme.palette.text.primary,
    margin: '8px 2%',
    width: '29%',
    '& ~ data-rbd-placeholder-context-id': {
      display: 'none !important',
    },
    '& ~ $menuItemCard': { //working?
      transform: 'none !important',
    },
    background: 'lightblue',
  },
  menuItemHeader: {
    top: 0,
    position: 'sticky',
    zIndex: 2,
    backgroundColor: theme.palette.grey[50],
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  menuItemHeaderText: {
    height: 40,
  },
  overflow: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  // TODO: stop sibling menu items from animating
  clone: {
    '& + div': {
      display: 'none !important',
    },
    '& ~ $menuItemCard': { //working?
      transform: 'none !important',
    },
  },
  placeholderMessage: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    padding: 32,
    fontSize: 20,
    color: theme.palette.grey[500],
  },
  searchField: {
    marginLeft: 0,
    marginRight: 0,
  },
});

const MenuManagement = ({
  classes,
  getMasterMenus,
  getMenuItems,
  getTags,
  addMenu,
  deleteMenu,
  masterMenus,
  workingMasterMenu,
  workingMasterMenuId,
  initWorkingMasterMenu,
  updateMenuApi,
  dropAddMenuCategoryItem,
  moveMenuCategoryItem,
  isWorkingMasterMenuChanged,
  menuItems,
  formatter,
  tags,
  isLoading,
  menuItemsLoading,
  resetWorkingMasterMenu,
}) => {
  const dialog = React.createRef();
  let history = useHistory();
  const [filter, setFilter] = useState('');
  const menuOptions = masterMenus.map(menu => ({ name: menu.name, id: menu.id }));

  useEffect(() => {
    getMenusApi();
  }, []);

  useEffect(() => {
    getMenuItemsApi();
  }, []);

  useEffect(() => {
    getTagsApi();
  }, []);

  async function getMenusApi() {
    await getMasterMenus();
  }

  async function getMenuItemsApi(params = '', append = false) {
    await getMenuItems(params, append);
  }

  async function getTagsApi() {
    await getTags();
  }

  const deleteMenuApi = menuId => {
    dialog.current.open(`Are you sure you want to delete the entire menu ${workingMasterMenu.name}?`).then(() => {
      deleteMenu(menuId).then(getMenusApi);
    });
  };

  const selectMenu = menu => {
    if (isWorkingMasterMenuChanged) {
      dialog.current.open('Save your changes to this menu?').then(() => {
        updateMenuApi().then(() => initWorkingMasterMenu(menu.id));
      }).catch(() => {
        initWorkingMasterMenu(menu.id);
      });
    } else {
      initWorkingMasterMenu(menu.id);
    }
  };

  const handleDragEnd = dragResult => {
    const { source, destination, draggableId } = dragResult;
    const isFromMenuItemList = source.droppableId === 'menuItemList';

    if (!destination) {
      return;
    }

    if (isFromMenuItemList) {
      dropAddMenuCategoryItem(draggableId, destination);
    } else if (draggableId.indexOf('menuCategoryItem') > -1) {
      moveMenuCategoryItem(source, destination, draggableId);
    }
  };

  const tagSuggestions = tags.map(tag => {
    return {
      value: tag.name,
      type: 'tag',
      id: tag.id,
    };
  });

  const filterMenuItems = filters => {
    let oDataFilters = [];

    if (filters && filters.length > 0 && filters.some(filter => filter.type === 'tag')) {
      oDataFilters.push(`(${filters.filter(filter => filter.type === 'tag').map(tag => `(itemTags/any(itemTag: itemTag/itemTagId eq ${tag.id}))`).join('or')})`);
    }
    if (filters && filters.length > 0 && filters.some(filter => !filter.type)) {
      oDataFilters.push(`(${filters.filter(filter => !filter.type).map(tag => `(contains(name, '${tag.value}')) or (contains(description, '${tag.value}'))`).join('or')})`);
    }

    if (oDataFilters.length > 0) {
      const filterString = oDataFilters.join('and');

      setFilter(filterString);
      getMenuItemsApi(`$filter=${filterString}`);
    } else {
      setFilter('');
      getMenuItemsApi();
    }
  };

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 100;

    if (bottom && !menuItemsLoading) {
      getMenuItemsApi(`$skip=${menuItems.length}${filter.length > 0 ? `&$filter=${filter}` : ''}`, true);
    }
  };

  const draggableMenuItemId = item => {
    return `menuItem${item.id.toString()}`;
  };

  const handleBlockedNavigation = nextLocation => {
    promptToSaveThenGo(nextLocation);

    return false; //returning false blocks Navigation. User can continue after prompt.
  };

  const promptToSaveThenGo = nextLocation => {
    dialog.current.open('Save your changes?')
      .then(updateMenuApi)
      .catch(resetWorkingMasterMenu)
      .then(() => history.push(nextLocation.pathname));
  };

  function Copyable(props) {
    return (
      <Droppable droppableId={props.droppableId} isDropDisabled={true} type="menuItem">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            key={props.droppableId}
            className={classes.menuItemList}
          >
            {props.items.map((item, index) => (
              <Draggable key={item.id} draggableId={draggableMenuItemId(item)} index={index}>
                {(provided, snapshot) => (
                  <React.Fragment>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classes.menuItemCard}
                    >
                      <MenuItemCard
                        menuItem={item}
                        formatter={formatter}
                      />
                    </div>
                    {snapshot.isDragging && (
                      <div className={classes.menuItemCardCopy}>
                        <MenuItemCard
                          menuItem={item}
                          formatter={formatter}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={classes.selectionBarAndButton}>
        <div className={classes.selectionBar}>
          <MenuSelector
            onSelection={selectMenu}
            menus={menuOptions}
          />
        </div>
        <HighlightButton
          onClick={addMenu}
          aria-label="Add A New Menu"
        >
          <AddIcon />
        </HighlightButton>
      </div>
      <div className={classes.menuBuilder}>
        <div className={classes.content}>
          <Paper onScroll={handleScroll} className={classNames(classes.menuItemListContainer, classes.overflow)}>
            <div className={classes.menuItemHeader}>
              <MenuItemFilterField
                whitelist={tagSuggestions}
                onChange={filterMenuItems}
              />
            </div>
            <Copyable items={menuItems} droppableId="menuItemList" />
          </Paper>
          <Paper className={classNames(classes.menuContainer, classes.overflow)}>
            {workingMasterMenu && workingMasterMenuId ?
              <Menu
                onDeleteMenu={() => deleteMenuApi(workingMasterMenuId)}
                formatter={formatter}
              />
              :
              isLoading ?
                <div className={classes.placeholderMessage}>Loading...</div>
                :
                <div className={classes.placeholderMessage}>Select a Menu Above or Click + to Add a New One!</div>
            }
          </Paper>
        </div>
      </div>
      <SimpleDialog innerRef={dialog} />
      {isLoading && <div className={classes.loader}>
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <DataLoader />
        </Fade>
      </div>}
      <Prompt
        when={isWorkingMasterMenuChanged}
        message={handleBlockedNavigation}
      />
    </DragDropContext>
  );
};

const mapStateToProps = state => {
  const {
    masterMenuManagement: {
      masterMenus,
      workingMasterMenu,
      workingMasterMenuId,
      isWorkingMasterMenuChanged,
      isLoading,
    },
    menuItem,
    admin: {
      itemTags,
    },
  } = state;

  return {
    masterMenus,
    workingMasterMenu,
    workingMasterMenuId,
    isWorkingMasterMenuChanged,
    menuItems: menuItem.data,
    tags: itemTags,
    menuItemsLoading: menuItem.loading,
    isLoading,
  };
};

const mapDispatchToProps = {
  getMenuItems,
  getMasterMenus,
  addMenu,
  deleteMenu,
  initWorkingMasterMenu,
  updateMenuApi,
  dropAddMenuCategoryItem,
  moveMenuCategoryItem,
  getTags,
  handleMenuFieldChange,
  resetWorkingMasterMenu,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MenuManagement));
