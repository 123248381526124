import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => {
  return {
    eventTypeTag: {
      minWidth: 120,
      padding: 4,
      marginRight: 8,
      textAlign: 'center',
      textTransform: 'capitalize',
      border: '1px solid',
      borderRadius: 4,
      fontSize: 13,
    },
    Personal: {
      color: theme.palette.personalEvent,
      borderColor: theme.palette.personalEvent,
    },
    Proposal: {
      color: theme.palette.proposalEvent,
      borderColor: theme.palette.proposalEvent,
    },
    Booking: {
      color: theme.palette.bookingEvent,
      borderColor: theme.palette.bookingEvent,
    },
    Task: {
      color: theme.palette.taskEvent,
      borderColor: theme.palette.taskEvent,
    },
  };
};

class EventTypeTag extends Component {

  getTagName = calendarEventType => ({
    'Task': 'Task/ToDo',
  })[calendarEventType] || calendarEventType;

  render() {
    const {
      classes,
      calendarEventType,
    } = this.props;

    return (
      <div className={classNames(classes.eventTypeTag, classes[calendarEventType])}>
        {this.getTagName(calendarEventType)}
      </div>
    );
  }
}

export default withStyles(styles)(EventTypeTag);
