import React, { useContext, useState } from 'react';
import NavLink from 'Components/NavLink';
import { useRouteMatch } from 'react-router-dom';
import Recents from './Recents';
import SideNavContext from './SideNavContext';

const NavPill = ({
  to,
  icon,
  name,
  onAddNew,
  recents,
  isActive,
}) => {
  const enterDelay = 200;
  const exitDelay = 200;
  const [ thisEl, setThisEl ] = useState(null);
  const [ isHoveringOverNavLink, setIsHoveringLink ] = useState(false);
  const [ isHoveringOverMenu, setIsHoveringMenu ] = useState(false);
  const match = useRouteMatch(to);
  const {
    hoverMenuAnchorEl,
    onSetHoverMenuAnchorEl,
  } = useContext(SideNavContext);

  // for tablet, click opens menu if already on the link's url
  const handleClick = event => {
    if (match && match.isExact) {
      event.preventDefault();
      enterNavLink(event);
    } else {
      closeHoverMenu();
    }
  };

  const closeHoverMenu = () => {
    setIsHoveringLink(false);
    setIsHoveringMenu(false);
  };

  const enterNavLink = event => {
    event.persist();
    setThisEl(event.target);
    setIsHoveringLink(true);
    setTimeout(() => {
      onSetHoverMenuAnchorEl(event.target);
    }, enterDelay);
  };

  const leaveNavLink = () => {
    setTimeout(() => {
      setIsHoveringLink(false);
    }, exitDelay);
  };

  const enterHoverMenu = () => {
    setIsHoveringMenu(true);
  };

  const leaveHoverMenu = () => {
    setIsHoveringMenu(false);
  };

  const isMenuOpen = hoverMenuAnchorEl === thisEl && (isHoveringOverNavLink || isHoveringOverMenu);

  return (
    <>
      <NavLink
        to={to}
        onMouseEnter={enterNavLink}
        onMouseLeave={leaveNavLink}
        onClick={handleClick}
        isActive={isActive}
        icon={icon}
        name={name}
      />
      {recents &&
        <Recents
          name={name}
          recents={recents}
          anchorEl={hoverMenuAnchorEl}
          isOpen={isMenuOpen}
          onHoverMenuClose={closeHoverMenu}
          onMenuEnter={enterHoverMenu}
          onMenuLeave={leaveHoverMenu}
          onAddNew={onAddNew}
        />
      }
    </>
  );
};

export default NavPill;
