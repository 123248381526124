import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { GridColumn } from '@progress/kendo-react-grid';
import ODataGrid from 'Components/Grid/ODataGrid';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  saveContacts,
  getContacts,
  getContactColumns,
  saveContactColumns,
  reorderContactColumns,
  searchContactsGlobal,
  addContact,
} from 'actions/contact';
import SelectedColumn from 'Components/Grid/SelectedColumn';
import dropdownFilterCell from 'Components/Grid/dropdownFilterCell';
import { getLocations } from 'actions/location';
import GridToolBar from 'Components/Grid/GridToolBar';
import { buildGridCellLink } from 'Components/buildGridCellLink';
import { contactsSelected } from 'actions/api';
import { getStateODataSettings } from 'actions/settings'; // this should replace page size

class ContactGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIds: {},
      isFilterable: false,
      isEditable: false,
      isGridToggled: false,
      contacts: [],
      columns: props.columns,
      activeCustomFilter: null,
    };
    this.linkOptions = {
      url: contact => `/contacts/${contact.id}`,
      text: contact => contact.name,
    };
  }

  customFilters = [
    {
      id: 2, name: 'Contacts Added Today',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'createdUtcDateTime',
            operator: 'gt',
            value: moment().startOf('day').utc().toDate(),
          },
          {
            field: 'createdUtcDateTime',
            operator: 'lt',
            value: moment().endOf('day').utc().toDate(),
          },
        ],
      },
    },
    {
      id: 3, name: 'Contacts Added This Week',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'createdUtcDateTime',
            operator: 'gt',
            value: moment().startOf('week').utc().toDate(),
          },
          {
            field: 'createdUtcDateTime',
            operator: 'lt',
            value: moment().endOf('week').utc().toDate(),
          },
        ],
      },
    },
    {
      id: 4, name: 'Active Contacts',
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'orders/$count',
            operator: 'gt',
            value: 0,
          },
          {
            field: 'bookings/$count',
            operator: 'gt',
            value: 0,
          },
        ],
      },
    },
    {
      id: 5, name: 'Inactive Contacts',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'orders/$count',
            operator: 'eq',
            value: 0,
          },
          {
            field: 'bookings/$count',
            operator: 'eq',
            value: 0,
          },
        ],
      },
    },
  ];

  componentDidMount() {
    this.props.contactsSelected({});
    this.props.getContactColumns();

    this.setupLocations(this.props.getLocations());
  }

  componentDidUpdate(prevProps) {
    if (this.props.contacts && (prevProps.contacts !== this.props.contacts || (this.props.contacts.length !== 0 && this.state.contacts.length === 0))) {
      this.setState({ contacts: this.setSelectedContacts(this.state.selectedIds) });
    }
    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: this.props.columns });
    }
    if (prevProps.searchText !== this.props.searchText) {
      if (this.props.searchText) {
        this.props.searchContactsGlobal();
      }
    }
  }

  setSelectedContacts = (selectedIds = {}) => {
    const { contacts } = this.props;

    if (!contacts.length) {
      return contacts;//avoid creating a new array each time
    }

    return contacts.map(contact => ({
      ...contact,
      selected: selectedIds[contact.id] === true,
    }));
  }

  saveContacts = contacts => {
    return this.props.saveContacts(contacts)
      .then(data => {
        this.cancelEditMode();
        this.setState({ contacts: data });
      })
      .catch(window.alert); //TODO: handle errors better. Mockups?
  }

  enterEditMode = () => {
    this.setState({ isEditable: true });
  }

  cancelEditMode = () => {
    this.setState({ isEditable: false });
  }

  createContact = () => {
    const { addContact, currentLocation, history } = this.props;

    if (currentLocation) {
      const newContact = {
        firstName: 'New',
        locationId: currentLocation.id,
      };

      addContact(newContact).then(newEntity => {
        history.push(`/contacts/${newEntity.id}`);
      });
    } else {
      console.error('Current Location is not set.');
    }
  };

  onFilterClick = () => {
    this.setState({ isFilterable: !this.state.isFilterable });
  }

  onContactSelected = ({ id, isSelected, isAll }) => {
    const { contacts, selectedIds } = this.state;
    let newContacts;

    if (isAll !== undefined) {
      newContacts = contacts.map(c => {
        c.selected = isAll;
        selectedIds[c.id] = isAll;

        return c;
      });
    } else {
      newContacts = contacts.map(c => {
        if (c.id === id) {
          c.selected = isSelected;
        }
        selectedIds[c.id] = c.selected;

        return c;
      });
    }
    this.setState({ contacts: newContacts, selectedIds });
    this.props.contactsSelected(selectedIds);
  };

  onGridEditClick = () => {
    this.setState({ isGridToggled: !this.state.isGridToggled });
  }

  getToolbar = () => (
    <GridToolBar
      isFilterToggled={this.state.isFilterable}
      isEditToggled={this.state.isEditable}
      isGridToggled={this.state.isGridToggled}
      onFilterClick={this.onFilterClick}
      onEditClick={this.enterEditMode}
      onAddClick={this.createContact}
      onGridEditClick={this.onGridEditClick}
      onColumnsSubmit={this.saveGridColumns}
      onCloseMenu={this.onGridEditClose}
      columns={this.state.columns}
      gridContext="Contacts"
      customFilters={this.customFilters}
      onApplyCustomFilter={this.applyCustomFilter}
      activeCustomFilter={this.state.globalFilter}
    />
  )

  applyCustomFilter = filterId => {
    const foundFilter = this.customFilters.find(filter => filter.id === filterId);

    this.setState({ globalFilter: foundFilter });
  }

  onSalesRepChange = props => newValue => {
    props.onChange({ dataItem: props.dataItem, field: newValue.name, value: newValue.value });
  }

  saveGridColumns = columns => {
    this.props.saveContactColumns(columns);
  }

  onGridEditClose = () => {
    this.setState({ isGridToggled: false });
  }

  reorderColumns = event => {
    const { columns: reOrderColumns } = event;
    // If the data field does not match the column field name, we have to fix it here:
    const fieldNames = {

    };
    const reorderedColumns = reOrderColumns.sort((a, b) => a.orderIndex - b.orderIndex).map(column => fieldNames[column.field] || column.field);

    this.props.reorderContactColumns(reorderedColumns);
  }

  render() {
    const {
      contacts,
      isEditable,
      isFilterable,
      columns,
      globalFilter,
      sort,
    } = this.state;

    const {
      isLoading,
      getStateODataSettings,
    } = this.props;

    return (
      columns.length > 0 &&
      <ODataGrid
        getData={this.props.getContacts}
        items={contacts}
        isLoading={isLoading}
        selectionChanged={this.onContactSelected}
        isSortable={!isEditable}
        filterable={!isEditable && isFilterable}
        isEditable={isEditable}
        pageSize={getStateODataSettings()}
        onSave={this.saveContacts}
        onCancel={this.cancelEditMode}
        toolBar={this.getToolbar()}
        onColumnReorder={this.reorderColumns}
        filter={(globalFilter && globalFilter.filter)}
        sort={sort}
      >
        {columns.map(column => this.getColumn(column))}
      </ODataGrid>
    );
  }

  setupLocations = locations => {
    const dropDownOptions = locations.map(s => (
      {
        text: s.name,
        id: s.id,
      }));
    const locationsFilterCell = dropdownFilterCell(dropDownOptions, 'Locations');

    this.setState({ locationsFilterCell });
  };

  getColumn(column) {
    const {
      isEditable,
      contacts,
      locationsFilterCell,
    } = this.state;

    if (column.isVisible) {
      switch (column.field) {
        case 'selected':
          return SelectedColumn(contacts, !isEditable);
        case 'last':
          return isEditable ?
            <GridColumn key={column.field} field={column.field} title={column.title} /> :
            <GridColumn key={column.field} field={column.field} title={column.title} cell={buildGridCellLink(this.linkOptions)} />;
        case 'location':
          return (
            <GridColumn
              key={column.field}
              field="location"
              title={column.title}
              sortable={false}
              filterCell={locationsFilterCell}
            />);
        case 'account':
          return (
            <GridColumn
              key={column.field}
              field="account"
              title={column.title}
              sortable={false}
              editable={false}
              filterable={false}
            />);
        default:
          return (
            <GridColumn
              key={column.field}
              field={column.field}
              title={column.title}
              cell={isEditable ? null : buildGridCellLink({ url: this.linkOptions.url, text: contact => contact[column.field] })}
            />
          );
      }
    }
  }
}

const mapStateToProps = state => {
  const {
    api: {
      currentLocation,
    },
    contact: {
      contacts,
      isLoading,
    },

    settings: {
      contacts: {
        columns,
      },
    },
    searchReducer: {
      searchText,
    },
  } = state;

  return {
    contacts: contacts,
    columns,
    isLoading,
    searchText,
    currentLocation,
  };
};

const mapDispatchToProps = {
  saveContacts,
  getContacts,
  contactsSelected,
  getContactColumns,
  saveContactColumns,
  reorderContactColumns,
  getLocations,
  searchContactsGlobal,
  addContact,
  getStateODataSettings,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactGrid));
