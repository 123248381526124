import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getCoupons,
  deleteCoupon,
} from 'actions/coupon';
import {
  getSettingAreas,
  saveSettingValues,
} from 'actions/settings';
import {
  getRevenueTypes,
} from 'actions/item';
import { withStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import HighlightButton from 'Components/Buttons/HighlightButton';
import SimpleDialog from 'Components/SimpleDialog';
import CouponGrid from './CouponGrid';
import CouponModal from './CouponModal';
import DiscountType from 'models/DiscountType';

const GRATUITY_AFTER_DISCOUNT = 'GRATUITY_AFTER_DISCOUNT';
const SERVICE_CHARGE_AFTER_DISCOUNT = 'SERVICE_CHARGE_AFTER_DISCOUNT';
const TAX_AFTER_DISCOUNT = 'TAX_AFTER_DISCOUNT';

const styles = () => ({
  coupon: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flex: 1,
  },
  name: {
    display: 'flex',
    width: '65%',
  },
  lower: {
    display: 'flex',
    padding: '24px 16px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 16px',
  },
  topButtons: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    margin: '0 5px',
  },
  addButton: {
    marginBottom: 5,
  },
  booleanOption: {
    padding: '2px 12px',
  },
  primary: {
    marginLeft: -10,
  },
  tableContainer: {
    width: 650,
  },
});

const Coupons = ({
  classes,
  getCoupons,
  getRevenueTypes,
  deleteCoupon,
  revenueTypes,
  gratuityAfterDiscount,
  serviceChargeAfterDiscount,
  taxAfterDiscount,
  getSettingAreas,
  saveSettingValues,
}) => {
  const [ coupons, setCoupons ] = useState([]);
  const [ couponEdit, setCouponEdit ] = useState(null);
  const [ usedCouponCodes, setUsedCouponCodes ] = useState([]);
  const [ applySettings, setApplySettings ] = useState({ gratuityAfterDiscount: false, serviceChargeAfterDiscount: false, taxAfterDiscount: false });
  const dialog = React.createRef();

  useEffect(() => {
    getRevenueTypes();
    getCouponsApi();
    getSettingAreas();
  }, []);

  useEffect(() => {
    updateApplySettings();
  }, [gratuityAfterDiscount, serviceChargeAfterDiscount, taxAfterDiscount]);

  function updateApplySettings() {
    setApplySettings({ gratuityAfterDiscount, serviceChargeAfterDiscount, taxAfterDiscount });
  }

  async function getCouponsApi() {
    var dbCoupons = await getCoupons();

    setCoupons(dbCoupons);
  }

  function editCoupon(selectedCoupon) {
    setCouponEdit(selectedCoupon);
    setUsedCouponCodes(coupons.filter(coupon => coupon.code !== selectedCoupon.code).map(coupon => coupon.code));
  }

  function closeModal() {
    setCouponEdit(null);
    getCouponsApi();
  }

  function onAddClick() {
    editCoupon({ active: true, discountType: DiscountType.Flat, couponDiscounts: [] });
  }

  function handleDeleteCoupon(coupon) {
    dialog.current.open('Are you sure you want to delete this coupon?').then(() => {
      deleteCoupon(coupon).then(getCouponsApi);
    });
  }

  async function saveSettingValue(key, name, value) {
    setApplySettings({
      ...applySettings,
      [name]: value,
    });

    await saveSettingValues({ [key]: value });
    getSettingAreas();
  }

  return (
    <div className={classes.coupon}>
      <div className={classes.main}>
        <div className={classes.content}>
          <div className={classes.tableContainer}>
            <CouponGrid coupons={coupons} revenueTypes={revenueTypes} onEditCoupon={editCoupon} onDeleteCoupon={handleDeleteCoupon} onCloseModal={closeModal} />
          </div>
        </div>
        <div className={classes.buttons}>
          <div className={classes.topButtons}>
            <div className={classes.addButton}>
              <HighlightButton
                onClick={onAddClick}
                aria-label={`Add Coupon Code`}
                className={classes.spaceButtons}
              >
                <AddIcon />
              </HighlightButton>
            </div>
          </div>
          <div className={classes.button}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.booleanOption}
                  checked={applySettings.gratuityAfterDiscount}
                  onChange={event => saveSettingValue(GRATUITY_AFTER_DISCOUNT, 'gratuityAfterDiscount', event.target.checked)}
                />
              }
              label="Apply Gratuity After Discount"
            />
          </div>
          <div className={classes.button}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.booleanOption}
                  checked={applySettings.serviceChargeAfterDiscount}
                  onChange={event => saveSettingValue(SERVICE_CHARGE_AFTER_DISCOUNT, 'serviceChargeAfterDiscount', event.target.checked)}
                />
              }
              label="Apply Service Charge After Discount"
            />
          </div>
          <div className={classes.button}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.booleanOption}
                  checked={applySettings.taxAfterDiscount}
                  onChange={event => saveSettingValue(TAX_AFTER_DISCOUNT, 'taxAfterDiscount', event.target.checked)}
                />
              }
              label="Apply Tax After Discount"
            />
          </div>
        </div>
      </div>
      <SimpleDialog innerRef={dialog} />
      {couponEdit && <CouponModal coupon={couponEdit} usedCouponCodes={usedCouponCodes} revenueTypes={revenueTypes} onCloseModal={closeModal} />}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    api,
    settings: {
      allAreaSettings,
    },
  } = state;

  return {
    revenueTypes: api && api.revenueTypes ? api.revenueTypes : [],
    gratuityAfterDiscount: allAreaSettings[GRATUITY_AFTER_DISCOUNT] ? allAreaSettings[GRATUITY_AFTER_DISCOUNT].value : false,
    serviceChargeAfterDiscount: allAreaSettings[SERVICE_CHARGE_AFTER_DISCOUNT] ? allAreaSettings[SERVICE_CHARGE_AFTER_DISCOUNT].value : false,
    taxAfterDiscount: allAreaSettings[TAX_AFTER_DISCOUNT] ? allAreaSettings[TAX_AFTER_DISCOUNT].value : false,
  };
};

const mapDispatchToProps = {
  getCoupons,
  getRevenueTypes,
  deleteCoupon,
  getSettingAreas,
  saveSettingValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Coupons));
