import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core';
import Avatar from 'Components/Avatar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: 70,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    cursor: 'pointer',
    border: '0.5px solid #D3D3D3',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  content: {
    flex: '1 0 auto',
    textAlign: 'center',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    paddingRight: 5,
  },
  cover: {
    minWidth: 70,
    height: 45,
    borderRadius: 5,
  },
  selected: {
    backgroundColor: '#4A90E2',
    '& h5': {
      color: 'white',
    },
  },
  avatar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.common.bluegrey,
    fontSize: 30,
    fontWeight: '600',
    minWidth: '70px',
    maxWidth: '70px',
    height: 45,
    color: theme.palette.grey['50'],
    borderRadius: 5,
  },
  cardThumbnail: {
    marginLeft: 10,
    marginTop: 10,
  },
  catName: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
});

class MenuCategoryCard extends Component {

  onSelected = category => () => {
    this.props.onSelect(category);
  }

  render() {
    const { category, classes, selected , ...rest } = this.props;

    return (<Card className={classNames(classes.root, selected ? classes.selected : '')}
      {...rest}
      onClick={this.onSelected(category)}>
      <div className={classes.cardThumbnail}>
        {!!category.imageUrl ?
          <CardMedia
            className={classes.cover}
            image={category.imageUrl}
            title={category.name}
          />
          :
          <Avatar className={classes.avatar} name={category.name} />
        }
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="subtitle2" className={classes.catName}>
            {category.name}
          </Typography>
        </CardContent>
      </div>
    </Card>);
  }
}

export default withStyles(styles)(MenuCategoryCard);
