import React, { Component } from 'react';
import { LibraryBooks as MenuIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';
import {
  Menu,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = theme => ({
  container: {
    fontSize: 12,
    display: 'flex',
    color: theme.palette.grey[900],
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 9,
    paddingBottom: 9,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  iconContainer: {
    paddingTop: 3,
    paddingRight: 16,
  },
  contentContainer: {
    flexGrow: 1,
    position: 'relative',
  },
  link: {
    color: theme.palette.action.active,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  default: {
    color: theme.palette.error.dark,
  },
  other: {
    color: theme.common.darkGreen,
  },
  contentRow: {
    display: 'flex',
  },
  label: {
    color: theme.palette.grey[300],
    width: '50%',
  },
  downButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

class AssignedMenu extends Component {
  state = {};

  openToolsMenu = event => {
    this.setState({ toolsOpenedBy: event.currentTarget });
  }

  closeToolsMenu = () => {
    this.setState({ toolsOpenedBy: null });
  }

  onRemove = () => {
    this.closeToolsMenu();
    this.props.onRemove(this.props.item);
  }

  onMarkDefault = () => {
    this.closeToolsMenu();
    this.props.onMarkDefault(this.props.item);
  }

  render() {
    const {
      item,
      classes,
      _default,
    } = this.props;

    const {
      toolsOpenedBy,
    } = this.state;

    return !!item && (
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <MenuIcon className={_default ? classes.default : classes.other} />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.link}>{item.name}</div>
          <div className={classes.contentRow}>
            <div className={classes.label}>Categories: </div>
            <div>{item.menuCategories.length}</div>
          </div>
          <ArrowDownButton className={classes.downButton} onClick={this.openToolsMenu} />
        </div>
        <Menu
          id="menu-options"
          anchorEl={toolsOpenedBy}
          open={Boolean(toolsOpenedBy)}
          onClose={this.closeToolsMenu}
        >
          <MenuItem onClick={this.onRemove}>Remove</MenuItem>
          {!_default && <MenuItem onClick={this.onMarkDefault}>Mark as Default</MenuItem>}
        </Menu>
      </div>
    );
  }
}

AssignedMenu.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onMarkDefault: PropTypes.func,
  item: PropTypes.object.isRequired,
  _default: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AssignedMenu);
