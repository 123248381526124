import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';

const styles = theme => ({
  root: {
    borderRadius: 4,
  },
  close: {
    padding: '4px 0 4px 16px',
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  icon: {
    fontSize: 22,
    marginRight: 16,
  },
  miniButton: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    minHeight: 32,
    padding: '0 15px',
    textTransform: 'initial',
    fontWeight: 400,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
});

class ConfirmationBannerAutoClose extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }
  }

  open = message => {
    this.setState({ isOpen: true, message });
  }

  close = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ isOpen: false });
  };

  render() {
    const { classes } = this.props;
    const { isOpen, message } = this.state;
    const bannerMessage = message || 'Are you sure you want to do this?';

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        classes={{
          root: classes.root,
        }}
        onClose={this.close}
        ContentProps={{
          'aria-describedby': 'message-id',
          headlineMapping: {
            body1: "div",
            body2: "div",
          },
          classes: {
            root: classes.info,
          },
        }}
        message={
          <span id="message-id">
            {bannerMessage}
          </span>
        }
        autoHideDuration={3000}

      />
    );
  }
}

ConfirmationBannerAutoClose.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmationBannerAutoClose);
