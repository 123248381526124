import React, { Component } from 'react';
import Chooser from './chooser';
import { registerForIntl, provideIntlService } from '@progress/kendo-react-intl';

class DateFormatter extends React.Component {
  render() {
    const { format, label } = this.props;

    return (
      <div className="col-xs-12 col-sm-6 example-col">
        <label>{label}</label> {provideIntlService(this).formatDate(new Date(2000, 10, 6), format)}
      </div>
    );
  }
}

registerForIntl(DateFormatter);

class CurrencyFormatter extends React.Component {
  render() {
    return (
      <div className="col-xs-12 col-sm-6 example-col">
        <label>Locale currency:</label> {provideIntlService(this).formatNumber(1234.12, 'c')}
      </div>
    );
  }
}

registerForIntl(CurrencyFormatter);

class Defaults extends Component {
  render() {
    return (
      <>
        <h3> Defaults general </h3>
        <Chooser />
        <DateFormatter format="EEEE, d.MM.y" label="day week. day.month.year" />
        <DateFormatter format={{ date: 'full' }} label="full date" />
        <DateFormatter format={{ date: 'long' }} label="long date" />
        <DateFormatter format={{ date: 'medium' }} label="medium date" />
        <DateFormatter format={{ date: 'short' }} label="short date" />
        <CurrencyFormatter />
      </>
    );
  }
}

export default Defaults;
