import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AssignedMenu from './AssignedMenu';
import SimpleDialog from 'Components/SimpleDialog';
import {
  Add,
} from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import classNames from 'classnames';
import ComboBox from 'Components/ComboBox';
import {
  menuAssignmentOnSelect,
  menuAssignmentOnRemove,
  menuAssignmentMarkDefault,
} from 'actions/masterMenuManagement';

const styles = theme => ({
  assignedMenus: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 4,
    margin: 16,
  },
  filterRow: {
    display: 'flex',
    padding: 16,
    justifyContent: 'flex-end',
  },
  title: {
    display: 'flex',
    padding: 16,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
});

class AssignedMenusPanel extends Component {
  state = {
    isAddingMenu: false,
  }

  dialog = React.createRef();

  filterAndSort = menus => {
    const { purpose } = this.props;

    return menus.filter(menu => (menu.recordStatus === "Active" || !menu.recordStatus) && menu.assignment.toLowerCase() === purpose.toLowerCase()).sort((a, b) => {
      return a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1;
    });
  }

  onRemoveMenu = menu => {
    this.dialog.current.open('Are you sure you want to remove this menu?').then(() => {
      this.props.menuAssignmentOnRemove(menu.id, this.props.purpose);
    });
  }

  onMarkDefault = menu => {
    this.props.menuAssignmentMarkDefault(menu.id, this.props.purpose);
  }

  onMenuSelect = payload => {
    this.setState({ startAddMenu: false });

    if (!payload) {
      return; //use clicked the X
    }
    this.props.menuAssignmentOnSelect(payload.value, this.props.purpose);
  }

  onStartAddMenu = () => {
    this.setState({ isAddingMenu: true });
  }

  onLeaveAddMenu = () => {
    this.setState({ isAddingMenu: false });
  }

  getAssignedMenu = menuId => {
    const { masterMenus } = this.props;

    return masterMenus.find(mm => mm.id === menuId); //todo: limit data?
  }

  render() {
    const {
      classes,
      title,
      menuAssignments,
      menuSuggestions,
    } = this.props;
    const {
      isAddingMenu,
    } = this.state;

    const filteredMenuAssignments = this.filterAndSort(menuAssignments);

    return (

      <div className={classes.assignedMenus}>
        {!isAddingMenu ?
          <div className={classes.filterRow}>
            <HighlightButton onClick={this.onStartAddMenu}>
              <Add />
            </HighlightButton>
          </div>
          :
          <div>
            <ComboBox
              autoFocus={true}
              onBlur={this.onLeaveAddMenu}
              label="Add Menu"
              suggestions={
                menuSuggestions.filter(suggestion => !filteredMenuAssignments.find(assignment => assignment.menuId === suggestion.value.id))
                || []
              }
              isClearable={true}
              handleChange={this.onMenuSelect}
              openMenuOnFocus={true}
            />
          </div>
        }
        <div className={classNames(classes.title)}>
          {title} Menus ({filteredMenuAssignments.length})
        </div>
        {filteredMenuAssignments && filteredMenuAssignments.length > 0 && filteredMenuAssignments.map(menuAssignment => (
          <AssignedMenu
            item={this.getAssignedMenu(menuAssignment.menuId)}
            onRemove={this.onRemoveMenu}
            onMarkDefault={this.onMarkDefault}
            key={menuAssignment.id}
            _default={!!menuAssignment.default}
          />))}
        <SimpleDialog
          innerRef={this.dialog}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    masterMenuManagement: {
      menuAssignments,
      masterMenus,
    },
  } = state;

  return {
    menuAssignments,
    masterMenus,
  };
};

const mapDispatchToProps = {
  menuAssignmentOnSelect,
  menuAssignmentOnRemove,
  menuAssignmentMarkDefault,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssignedMenusPanel));
