import React, { useRef, useState, useEffect } from 'react';
import EmailAccount from './EmailAccount';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SimpleDialog from 'Components/SimpleDialog';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { getEmailAccounts } from 'actions/emailAccounts';
import { Card } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

const styles = () => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  mainContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '16px 32px',
  },
  header: {
    paddingLeft: 16,
    fontSize: 16,
    fontWeight: 'bold',
  },
  hr: {
    margin: '16px 0',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const EmailAccounts = ({
  classes,
  emailAccounts,
  getEmailAccounts,
}) => {
  const dialog = useRef();
  const [nextId, setNextId] = useState(-1);
  const [emailAccountsEdit, setEmailAccountsEdit] = useState(emailAccounts);

  useEffect(() => {
    getEmailAccounts();
  }, []);

  useEffect(() => {
    setEmailAccountsEdit(emailAccounts);
  }, [emailAccounts]);

  const addAccount = () => {
    const newAccount = {
      name: null,
      id: nextId,
      isNew: true,
    };

    const newAccounts = [
      ...emailAccountsEdit,
      newAccount,
    ];

    setEmailAccountsEdit(newAccounts);
    setNextId(nextId - 1);
  };

  const deleteUnsavedAccount = () => {
    console.log('TODO');
  };

  return (
    <div className={classes.root}>
      <Card className={classes.mainContent}>
        <div className={classes.toolbar}>
          <div className={classes.header}>
            Email Accounts
          </div>
          <HighlightButton
            onClick={addAccount}
            aria-label="Add A New Email Account"
          >
            <AddIcon />
          </HighlightButton>
        </div>
        <div className={classes.emailTriggerDisplay}>
          {emailAccountsEdit
            .map(emailAccount =>
              <EmailAccount emailAccount={emailAccount} key={emailAccount.id} onCancelUnsaved={deleteUnsavedAccount} />
            )}
        </div>
      </Card>
      <SimpleDialog innerRef={dialog} />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    emailAccounts,
  } = state;

  return {
    emailAccounts: emailAccounts.data,
  };
};

const mapDispatchToProps = {
  getEmailAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmailAccounts));
