import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const styles = {
  container: {
    flexGrow: 1,
    padding: 40,
  },
};

class SelectEvent extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.container}>
        Select an event to the left to view details, or create a new one!
      </Paper>
    );
  }
}

export default withStyles(styles)(SelectEvent);
