import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import StatusTable from './StatusTable';
import { Add } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { getStatuses, getSystemStatuses, saveStatus, saveStatuses, deleteStatus, setStatusGroup } from 'actions/settings';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({ //TODO fix scroll
  manager: {
    display: 'flex',
  },
  quickListCategories: {
    width: 222,
    display: 'inline-block',
    verticalAlign: 'top',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  quickPicksContainer: {
    width: 460,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  buttons: {
    verticalAlign: 'top',
    width: 250,
    height: 32,
    paddingLeft: 50,
    paddingTop: 35,
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  programArea: {
    fontSize: 16,
    padding: 3,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[50],
  },
  invalidConfiguration: {
    color: 'red',
  },
});

class StatusManager extends Component {
  state = {

  };

  componentDidMount() {
    this.props.getStatuses();
    this.props.getSystemStatuses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.statuses && this.props.statuses !== prevProps.statuses) {
      const statuses = this.props.statuses.map(statusGroup => this.isValid(statusGroup) ? { ...statusGroup, valid: true } : { ...statusGroup, valid: false });

      this.setState({
        statuses: statuses,
      });
    }
  }

  isValid = group => {
    if (group.statuses[0].systemStatusCode !== 'Cancelled' ||
      group.statuses[1].systemStatusCode !== 'Created' ||
      group.statuses[group.statuses.length - 1].systemStatusCode !== 'Completed'
      || !group.statuses.some(status => status.systemStatusCode === 'Placed')) {
      return false;
    }

    return true;
  };

  addItem = () => {
    const {
      selectedStatusGroup,
    } = this.state;

    selectedStatusGroup.statuses.push({ name: 'New Status', type: selectedStatusGroup.type, sortOrder: selectedStatusGroup.statuses.length * 100 });

    this.setState({ selectedStatusGroup });
    this.props.setStatusGroup(selectedStatusGroup);
  }

  onSort = sortedItemsArray => {
    const {
      selectedStatusGroup,
    } = this.state;

    selectedStatusGroup.statuses = sortedItemsArray.map((option, index) => ({ ...option, sortOrder: index * 100 }));

    this.setState({ selectedStatusGroup });

    this.props.saveStatuses(selectedStatusGroup.statuses);
    this.props.setStatusGroup(selectedStatusGroup);
  }

  onItemDelete = item => {
    const {
      selectedStatusGroup,
    } = this.state;

    selectedStatusGroup.statuses = selectedStatusGroup.statuses.filter((status, index) => index !== item.index);

    this.setState({ selectedStatusGroup });
    if (item.id) {
      this.props.deleteStatus(item);
    }
    this.props.setStatusGroup(selectedStatusGroup);
  }

  onItemEdit = editedItem => {
    const {
      selectedStatusGroup,
    } = this.state;

    this.props.saveStatus(editedItem).then(savedItem => {
      selectedStatusGroup.statuses[editedItem.index] = savedItem;

      this.setState({ selectedStatusGroup });
      this.props.setStatusGroup(selectedStatusGroup);
    });
  }

  selectStatusGroup = type => {
    const { statuses } = this.props;

    this.setState({
      selectedStatusGroup: statuses.find(group => group.type === type),
    });
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      selectedStatusGroup,
      statuses,
    } = this.state;

    return (
      <div className={classes.manager}>
        <div className={classes.quickListCategories}>
          <div className={classes.programArea}>Status Type</div>
          <MenuList>
            {
              statuses && statuses.map(statusGroup =>
                <MenuItem
                  key={`StatusGroup-${statusGroup.type}`}
                  selected={selectedStatusGroup && (statusGroup.type === selectedStatusGroup.type)}
                  className={!statusGroup.valid ? classes.invalidConfiguration : ''}
                  onClick={() => this.selectStatusGroup(statusGroup.type)}>
                  {statusGroup.type}
                </MenuItem>)
            }
          </MenuList>

        </div>
        <div className={classes.quickPicksContainer}>
          <StatusTable
            statusGroup={selectedStatusGroup}
            onItemDelete={this.onItemDelete}
            onItemEdit={this.onItemEdit}
            onSort={this.onSort}
          />
        </div>
        <div className={classes.buttons}>
          <HighlightButton
            disabled={!selectedStatusGroup}
            onClick={this.addItem}
          >
            <Add />
          </HighlightButton>
        </div>
      </div>);
  }
}

const mapStateToProps = state => {
  const {
    settings: {
      statuses,
    },
  } = state;

  return {
    statuses,
  };
};

const mapDispatchToProps = {
  getStatuses,
  getSystemStatuses,
  saveStatus,
  saveStatuses,
  deleteStatus,
  setStatusGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusManager));
