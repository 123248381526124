import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Popper,
  Paper,
  Button,
} from '@material-ui/core';
import IconButton  from 'Components/Buttons/IconButton';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import { CALENDAR_MODE, CALENDAR_VIEW } from 'constants/calendarMode';
import moment from 'moment';
import {
  NavigateBefore,
  NavigateNext,
  Palette,
  LibraryBooks,
  Menu as HamburgerIcon,
  FilterList,
  Settings,
  Alarm,
  Today as Calendar,
  ArrowDropDown as DropDownIcon,
} from '@material-ui/icons';
import './MiniCalendar.scss';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

const styles = theme => {
  const toolContainer = {
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    height: '64px',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  return {
    toolbar: {
      ...toolContainer,
    },
    leftToolBar: {
      ...toolContainer,
      width: 547,
      justifyContent: 'space-evenly',
    },
    giveSpace: {
      marginLeft: 7,
      marginRight: 7,
    },
    calendarLabel: {
      fontSize: 18,
    },
    buttonText: {
      width: 224,
      fontSize: 27,
      color: theme.palette.grey.A400,
      textTransform: 'none',
      fontWeight: 'normal',
    },
    tooltips: {
      border: 'none',
    },
  };
};

class ToolBar extends Component {

  state = {
    calendarAnchorEl: null,
  };

  dateLabel = () => {
    const {
      currentMode,
      selectedDate,
    } = this.props;
    const momentDate = moment(selectedDate);

    if (currentMode === CALENDAR_MODE.Month) {
      return momentDate.format("MMMM YYYY"); //January 2019
    }
    if (currentMode === CALENDAR_MODE.Week) {
      return momentDate.format("MMMM YYYY"); //January 2019
    }

    return momentDate.format("MMM Do YYYY"); //Jan 1st 2019
  }

  toggleCalendar = event => {
    const { calendarAnchorEl } = this.state;

    !calendarAnchorEl ? this.openCalendar(event) : this.closeCalendar();
  }

  openCalendar = event => {
    this.setState({ calendarAnchorEl: event.currentTarget });
  };

  closeCalendar = () => {
    this.setState({ calendarAnchorEl: null });
  };

  onCalendarDateSelected = args => {
    this.closeCalendar();
    this.props.onCalendarDateSelected(args);
  }

  render() {
    const {
      classes,
      currentMode,
      onDrawerOpen,
      currentView,
      selectedDate,
    } = this.props;

    const dateLabel = this.dateLabel();
    const currentDate = moment(selectedDate).format();

    return (
      <div className={classes.toolbar}>
        <div className={classes.calendarLabel}>
          <IconButton onClick={onDrawerOpen} aria-label="Open drawer" icon={HamburgerIcon} />
          <span>
            Calendar
          </span>
        </div>
        <HighlightButton onClick={this.props.onTodayClicked}>
          TODAY
        </HighlightButton>
        <div>
          <IconButton onClick={this.props.onPrevious} fontSize={37} icon={NavigateBefore} />
          <IconButton onClick={this.props.onNext} fontSize={37} icon={NavigateNext} />
        </div>
        <>
          <Button
            className={classes.buttonText}
            aria-owns="monthly-calendar"
            aria-haspopup="true"
            onClick={this.toggleCalendar}
          >{dateLabel}<DropDownIcon />
          </Button>
          <Popper
            open={Boolean(this.state.calendarAnchorEl)}
            anchorEl={this.state.calendarAnchorEl}
            placement='bottom'
            id="monthly-calendar"
          >
            <Paper>
              {(currentMode === CALENDAR_MODE.Month) &&
              <CalendarComponent
                start="Year"
                depth="Year"
                value={currentDate}
                change={this.onCalendarDateSelected}
                showTodayButton={false} />}
              {(currentMode === CALENDAR_MODE.Day || currentMode === CALENDAR_MODE.Week || currentMode === CALENDAR_MODE.Agenda) &&
              <CalendarComponent
                value={currentDate}
                change={this.onCalendarDateSelected}
                showTodayButton={false} />}
            </Paper>
          </Popper>
        </>
        <div>
          <HighlightButton
            className={classNames(classes.giveSpace, classes.tooltips)}
          >
            <Tooltip title="Calendar Color-Coding Coming Soon">
              <Palette color="disabled" />
            </Tooltip>
          </HighlightButton>
          <HighlightButton
            className={classNames(classes.giveSpace, classes.tooltips)}
          >
            <Tooltip title="Calendar Grouping Coming Soon">
              <LibraryBooks color="disabled" />
            </Tooltip>
          </HighlightButton>
        </div>
        <div className={classes.leftToolBar}>
          <div>
            <HighlightButton
              className={classNames(classes.giveSpace, classes.tooltips)}
            >
              <Tooltip title="Calendar Filtering Coming Soon">
                <FilterList color="disabled" />
              </Tooltip>
            </HighlightButton>
            <HighlightButton
              className={classNames(classes.giveSpace, classes.tooltips)}
            >
              <Tooltip title="Calendar Settings Coming Soon">
                <Settings color="disabled" />
              </Tooltip>
            </HighlightButton>
          </div>
          <div>
            <HighlightButton
              variant="left"
              minWidth={34}
              pressed={currentView === CALENDAR_VIEW.Timeline}
              onClick={this.props.onSwitchView(CALENDAR_VIEW.Timeline)}
            >
              <Alarm />
            </HighlightButton>
            <HighlightButton
              variant="right"
              minWidth={34}
              pressed={currentView === CALENDAR_VIEW.Calendar}
              onClick={this.props.onSwitchView(CALENDAR_VIEW.Calendar)}
            >
              <Calendar />
            </HighlightButton>
          </div>
          <div>
            <HighlightButton
              variant="left"
              pressed={currentMode === CALENDAR_MODE.Day}
              onClick={this.props.onDayClick}
            >
              DAY
            </HighlightButton>
            <HighlightButton
              variant="middle"
              pressed={currentMode === CALENDAR_MODE.Week}
              onClick={this.props.onWeekClick}
            >
              WEEK
            </HighlightButton>
            <HighlightButton
              variant="middle"
              pressed={currentMode === CALENDAR_MODE.Month}
              onClick={this.props.onMonthClick}
              disabled={currentView === CALENDAR_VIEW.Timeline}
            >
              MONTH
            </HighlightButton>
            <HighlightButton
              variant="right"
              pressed={currentMode === CALENDAR_MODE.Agenda}
              onClick={this.props.onAgendaClick}
              disabled={currentView === CALENDAR_VIEW.Timeline}
            >
              AGENDA
            </HighlightButton>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ToolBar);
