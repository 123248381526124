import React, { Component } from 'react';
import { widgetGraphs } from 'helpers/enums';
import { connect } from 'react-redux';
import { Card, withStyles } from '@material-ui/core';
import LineGraphWidget from 'Components/GraphWidgets/LineGraphWidget';

const styles = theme => ({
  card: {
    padding: 16,
    width: 276,
  },
  heading: {
    color: theme.palette.grey['200'],
    fontSize: 12,
    height: 18,
    fontWeight: 'normal',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
  number: {
    height: 50,
    width: '100%',
    fontSize: 36,
    color: theme.palette.grey['500'],
    textAlign: 'center',
  },
  percent: {
    width: '100%',
    textAlign: 'center',
    color: theme.common.green,
    height: 22,
  },
  graph: {
    height: 50,
  },
});

class EventsGraphWidget extends Component {
  render() {
    const { dashboardEventsSettings, classes } = this.props;

    return (
      <Card className={classes.card}>
        <div className={classes.heading}>Total # of events</div>
        <div className={classes.number}>15</div>
        <div className={classes.percent}>+114%</div>
        <div className={classes.graph}>
          <LineGraphWidget
            data={[12, 19, 7, 15, 14, 21, 5]}
            settings={dashboardEventsSettings}
          />
        </div>

      </Card>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: { tasks },
    widgets,
  } = state;

  return {
    dashboardEventsSettings: widgets[widgetGraphs.dashboardEvents],
    tasks,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(EventsGraphWidget));
