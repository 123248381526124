import { MERCHANTACCOUNT_RECEIVED } from 'actions';
import IMerchantAccount from 'models/IMerchantAccount';

const initialState = {
  merchantAccounts: [] as IMerchantAccount[],
};

export function merchantAccount(state = initialState, action: any) {
  switch (action.type) {
    case MERCHANTACCOUNT_RECEIVED:
      const { merchantAccounts } = action;

      return {
        ...state,
        merchantAccounts
      };

    default:
      return state;
  }
}
