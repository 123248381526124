import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  updateMenuApi,
  handleAddCategory,
  resetWorkingMasterMenu,
  collapseCategories,
  expandCategories,
  workingMenuUpdateName,
} from 'actions/masterMenuManagement';
import SaveBar from 'Components/SaveBar';
import TextField from 'Components/TextField';
import HighlightButton from 'Components/Buttons/HighlightButton';
import MenuCategoryCard from './MenuCategoryCard';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  FastForwardOutlined as ExpandCollapse,
} from '@material-ui/icons';

const styles = theme => ({
  menu: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  menuCategories: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 10px 6px',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.grey[50],
    zIndex: 2,
  },
  giveSpace: {
    marginLeft: 7,
    marginRight: 7,
  },
  menuField: {
    display: 'flex',
    width: 240,
  },
  categories: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '12px 16px',
  },
  placeholderMessage: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    padding: 32,
    fontSize: 20,
    color: theme.palette.grey[500],
  },
  expandAll: {
    transform: `rotate(90deg)`,
  },
  collapseAll: {
    transform: `rotate(270deg)`,
  },
  saveBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 999,
    width: '100%',
  },
});

const Menu = ({
  classes,
  workingMasterMenuId,
  menuName,
  menuCategories,
  onDeleteMenu,
  updateMenuApi,
  workingMenuUpdateName,
  handleAddCategory,
  isWorkingMasterMenuChanged,
  resetWorkingMasterMenu,
  collapseCategories,
  expandCategories,
  formatter,
  isLoading,
  newMenuId,
}) => {
  const categoriesEndRef = useRef(null);
  const nameInput = useRef(null);
  const [visibleMenuCategories, setVisibleMenuCategories] = useState([]);

  useEffect(() => {
    focusNameInput(newMenuId);
  }, [newMenuId, menuName]);

  useEffect(() => {
    setVisibleCategories();
  }, [menuCategories]);

  const onMenuSave = () => {
    updateMenuApi();
  };

  const addMenuCategory = () => {
    handleAddCategory().then(scrollToBottom);
  };

  const scrollToBottom = () => {
    categoriesEndRef && categoriesEndRef.current && categoriesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const focusNameInput = newMenuId => {
    if (newMenuId === workingMasterMenuId && menuName === 'New' && nameInput && nameInput.current) {
      nameInput.current.focus();
      nameInput.current.select();
    }
  };

  const setVisibleCategories = () => {
    const visibleCategories = menuCategories && menuCategories.length > 0
      ? menuCategories.filter(mc => mc && mc.menuId === workingMasterMenuId && mc.recordStatus === 'Active')
      : [];

    setVisibleMenuCategories(visibleCategories);
  };

  return (
    <div className={classes.menu}>
      {workingMasterMenuId &&
        <div className={classes.menuCategories}>
          <div className={classes.topBar}>
            <div className={classes.menuField}>
              <TextField
                label="Menu Name"
                value={menuName}
                onFieldChange={workingMenuUpdateName}
                inputRef={nameInput}
              />
            </div>
            <div className={classes.toolbar}>
              <HighlightButton
                onClick={expandCategories}
                aria-label="Expand All Categories"
                className={classes.giveSpace}
              >
                <ExpandCollapse className={classes.expandAll}/>
              </HighlightButton>
              <HighlightButton
                onClick={collapseCategories}
                aria-label="Collapse All Categories"
                className={classes.giveSpace}
              >
                <ExpandCollapse className={classes.collapseAll}/>
              </HighlightButton>
              <HighlightButton
                onClick={addMenuCategory}
                aria-label="Add Menu Category"
                className={classes.giveSpace}
              >
                <AddIcon />
              </HighlightButton>
              <HighlightButton
                onClick={onDeleteMenu}
                aria-label="Delete This Menu"
                className={classes.giveSpace}
              >
                <DeleteIcon />
              </HighlightButton>
            </div>
          </div>
          <div className={classes.categories}>
            {visibleMenuCategories && visibleMenuCategories.length ?
              visibleMenuCategories.map(menuCategory =>
                <MenuCategoryCard
                  menuCategory={menuCategory}
                  key={menuCategory.id}
                  formatter={formatter}
                />
              )
              :
              <div className={classes.placeholderMessage}>Click + to Add a New Category</div>
            }
            <div ref={categoriesEndRef} />
          </div>
        </div>
      }
      {isWorkingMasterMenuChanged &&
        <div className={classes.saveBar}>
          <SaveBar
            onSave={onMenuSave}
            onCancel={resetWorkingMasterMenu}
            isSticky={false}
            isSaveDisabled={isLoading}
          />
        </div>
      }
    </div>
  );
};

const mapStateToProps = state => {
  const {
    masterMenuManagement: {
      workingMasterMenuId,
      menuName,
      menuCategories,
      isWorkingMasterMenuChanged,
      isLoading,
      newMenuId,
    },
  } = state;

  return {
    workingMasterMenuId,
    menuName,
    menuCategories,
    isWorkingMasterMenuChanged,
    isLoading,
    newMenuId,
  };
};

const mapDispatchToProps = {
  updateMenuApi,
  handleAddCategory,
  resetWorkingMasterMenu,
  collapseCategories,
  expandCategories,
  workingMenuUpdateName,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Menu));
