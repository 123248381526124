import React, { useContext } from 'react';
import { connect } from 'react-redux';
import StatusStepper from 'Components/Stepper/StatusStepper';
import OrderContext from './OrderContext';

const OrderStatusBar = ({ storeOrder }) => {
  const { statusHistory, onUpdateOrderStatus, onCompleteOrder } = useContext(OrderContext);

  const handleBannerConfirm = statusId => {
    onUpdateOrderStatus(statusId);
  };

  return (
    <StatusStepper
      statusHistory={statusHistory}
      activeStatusId={storeOrder.orderStatusId}
      onBannerConfirm={handleBannerConfirm}
      onCompleteFinalStep={onCompleteOrder}
      type={`${storeOrder.type}Order`}
    />
  );
};

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeOrder,
    },
  } = state;

  return {
    storeOrder,
  };
};

export default connect(mapStateToProps, undefined)(OrderStatusBar);
