import React, { Component, useContext } from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SectionHeader from 'Components/SectionHeader';
import ActivityDateTimePicker from 'Components/ActivityDateTimePicker';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';
import RichTextField from 'Components/RichTextField';
import TextField from 'Components/TextField';
import Activity from 'Components/Activity';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import AddEditEventModal from 'calendar/AddEditEventModal';
import classNames from 'classnames';
import moment from 'moment';
import { REMINDERS } from 'constants/reminders';
import SectionContext from 'Components/Contexts/SectionContext';
import { connect } from 'react-redux';
import CalendarEventService from 'actions/CalendarEventService';
import ICalendarEvent from 'models/ICalendarEvent';
import CalendarEventType from 'models/CalendarEventType';
import { saveNewChitChatThread, logChitChatSystemMessage, fetchChitChat, deleteThread } from 'actions/chitchat';
import { CHIT_CHAT_TYPES } from 'constants/entityTypes';

const styles = createStyles((theme: Theme) => ({
  activityPanel: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: '100%',
    height: '100%',
  },
  tabContainer: {
    height: 40,
    minHeight: 40,
    backgroundColor: theme.palette.grey[50],
  },
  miniTextField: {
    marginLeft: 'unset !important',
    marginRight: 'unset !important',
    flexGrow: 0,
    '& label': {
      fontSize: 15,
      color: theme.palette.action.active,
      fontWeight: 'bold',
    },
    '& input[type="text"]': {
      fontSize: 15,
      color: theme.palette.action.active,
    },
    '& div::before': {
      borderBottom: 'none !important',
    },
    '& div::after': {
      borderBottom: 'none !important',
    },
  },
  grey200: {
    color: theme.palette.grey[200],
  },
  grey300: {
    color: theme.palette.grey[300],
  },
  black: {
    color: theme.palette.common.black,
  },
  filterTimeline: {
    width: '100%',
    verticalAlign: 'middle',
    fontSize: 17,
    height: 33,
    lineHeight: '33px',
    paddingLeft: '24px',
  },
  activitiesSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  noActivity: {
    padding: 16,
  },
  hr: {
    margin: '2px 0',
  },
  header: {
    backgroundColor: 'transparent',
    paddingLeft: 8,
    fontWeight: 'bold',
  },
  flexGrowWithPad: {
    flexGrow: 1,
    marginRight: theme.spacing.unit * 3,
  },
  width100: {
    width: '100%',
  },
  width25: {
    width: '25%',
  },
  leftField: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
  },
  fontSelect: {
    fontSize: '15px',
  },
  fontMenu: {
    fontSize: '15px',
    color: theme.palette.grey[300],
  },
  addActivityContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 8,
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  margin16: {
    margin: 16,
  },
  addActivityRow: {
    padding: '0 8px',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .fr-view': {
      whiteSpace: 'normal', // wraps text in froala editor
      color: theme.palette.grey[300],
      paddingTop: '4px',
      paddingBottom: '16px',
    },
    fontSize: '15px',
    fontFamily: `${theme.typography.fontFamily} !important`,
    color: theme.palette.grey[300],
    '& .fr-placeholder': {
      color: theme.palette.grey[200],
      fontFamily: `${theme.typography.fontFamily} !important`,
      paddingTop: '4px',
      paddingBottom: '16px',
    },
  },
  overflow: {
    overflow: 'auto',
  },
  rowContentColumn1: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginRight: 48,
    paddingBottom: '16px',
    justifyContent: 'flex-end',
  },
  rowContentColumn2: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '16px',
    justifyContent: 'flex-end',
  },
  addButton: {
    padding: '0 20px',
  },
}));

const blankCalendarEvent: ICalendarEvent = {
  calendarEventType: CalendarEventType.Action,
  name: '',
  description: ''
};

interface IState {
  calendarEvents: ICalendarEvent[],
  isCalendarEditModalOpen: boolean,
  appointment: any,
  tabIndex: number,
  calendarEvent: ICalendarEvent,
  errors: any,
  beginDatePicker: {
    min: Date,
    max: Date,
  },
  reminder: string
}

class ActivityPanel extends Component<any, IState> {
  constructor(props: any) {
    super(props);
    const now = new Date();
    const minutesToNextHalfHour = 30 - (moment(now).minute() % 30);
    const nearestHalfHour = moment(now).add(minutesToNextHalfHour, 'minutes').toDate();
    const tenYearsOut = moment(now).add(10, 'years').toDate();

    this.froalaConfig = {
      placeholderText: 'Comment',
      toolbarButtons: ['bold', 'italic', 'underline', 'textColor', 'align'], // delete this line to restore default
    };

    this.state = {
      calendarEvents: [] as ICalendarEvent[],
      isCalendarEditModalOpen: false,
      appointment: undefined,
      tabIndex: 0,
      calendarEvent: blankCalendarEvent,
      errors: {},
      beginDatePicker: {
        min: nearestHalfHour,
        max: tenYearsOut,
      },
      reminder: ''
    };
  };

  froalaConfig: any;

  componentDidMount() {
    this.getActivities();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.id !== this.props.id) {
      this.getActivities();
    }
    if (prevProps.calendarEvents !== this.props.calendarEvents) {
      this.getActivities();
    }
  }

  logChitChatSystemMessage = async (content: string, recordName: string) => {
    const { CalendarEventService, logChitChatSystemMessage, fetchChitChat } = this.props;

    const relationship = {
      id: CalendarEventService.entityId,
      entityType: CalendarEventService.entityType,
    };

    const message = await logChitChatSystemMessage(content, relationship, recordName);

    await fetchChitChat(CalendarEventService.entityType, CalendarEventService.entityId);

    return message;
  }

  logChitChatUserMessage = async (subject: string, content: string) => {
    const { user, CalendarEventService, saveNewChitChatThread, fetchChitChat } = this.props;

    const chitChatThread = {
      baseMessage: {
        subject,
        content,
        time: moment().format(),
        from: user ? user.profile.name : '',
      },
      chitChatRelationship: CalendarEventService.entityType,
      relationshipId: CalendarEventService.entityId,
    };

    const response = await saveNewChitChatThread(CHIT_CHAT_TYPES.userCreatedAction, chitChatThread);

    fetchChitChat(CalendarEventService.entityType, CalendarEventService.entityId);

    return response.json();
  }

  replaceChitChatUserMessage = async (subject: string, content: string, chitChatMessageId: number) => {
    const { CalendarEventService, deleteThread } = this.props;

    await deleteThread(CalendarEventService.entityType, CalendarEventService.entityId, chitChatMessageId);

    const replacedMessage = await this.logChitChatUserMessage(subject, content);

    return replacedMessage;
  }

  onModalCancel = () => {
    this.setState({ isCalendarEditModalOpen: false });
  }

  getActivities = () => {
    this.props.CalendarEventService.get()
      .then((calendarEvents: ICalendarEvent[]) => {

        this.setState({ calendarEvents });
      });
  }

  onUpdate = async () => {
    if (!this.errorsExist()) {
      const { calendarEvent, tabIndex } = this.state;
      const action = tabIndex === 0;
      const newEvent = !calendarEvent.id;

      if (action) {
        calendarEvent.completedDateTime = moment().format();
      }

      calendarEvent.calendarEventType = action ? CalendarEventType.Action : CalendarEventType.Task;

      if (newEvent) {
        if (action) {
          const userMessage = await this.logChitChatUserMessage(calendarEvent.name, calendarEvent.description);
          calendarEvent.chitChatMessageId = userMessage.id;
        }

        await this.props.CalendarEventService.add(calendarEvent);

      } else {
        if (action && calendarEvent.chitChatMessageId) {
          let replacedUserMessage = await this.replaceChitChatUserMessage(calendarEvent.name, calendarEvent.description, calendarEvent.chitChatMessageId);
          calendarEvent.chitChatMessageId = replacedUserMessage.id;

          await this.props.CalendarEventService.update(calendarEvent);

        }
      }

      this.onRefresh();
    }
  }

  onDelete = async (calendarEvent: ICalendarEvent) => {
    await this.props.CalendarEventService.delete(calendarEvent.id);

    if (calendarEvent.calendarEventType === CalendarEventType.Action && calendarEvent.chitChatMessageId) {
      const { deleteThread, fetchChitChat, CalendarEventService} = this.props;

      await deleteThread(CalendarEventService.entityType, CalendarEventService.entityId, calendarEvent.chitChatMessageId);
      fetchChitChat(CalendarEventService.entityType, CalendarEventService.entityId);
    }

    this.onRefresh();
  }

  onEdit = (calendarEvent: ICalendarEvent) => {
    if (calendarEvent.calendarEventType === CalendarEventType.Task) {
      this.setState({ calendarEvent: blankCalendarEvent, tabIndex: 0, isCalendarEditModalOpen: true, appointment: calendarEvent, errors: {} });
    } else {
      this.setState({
        calendarEvent,
        tabIndex: calendarEvent.calendarEventType === CalendarEventType.Action ? 0 : 1,
        isCalendarEditModalOpen: false,
        appointment: undefined,
        errors: {}
      });
    }
  }

  onComplete = async (calendarEvent: ICalendarEvent) => {
    calendarEvent.completedDateTime = moment().format();

    await this.props.CalendarEventService.update(calendarEvent);

    if (calendarEvent.calendarEventType === CalendarEventType.Task) {
      await this.logChitChatSystemMessage('Task Completed', `Task - ${calendarEvent.name}`);
    }

    this.onRefresh();
  }

  onRefresh = () => {
    const { refresh } = this.props;

    this.getActivities();
    this.handleTabChange(0);
    refresh && refresh();
  }

  resetActivity = () => {
    const calendarEvent = {
      ...blankCalendarEvent,
      calendarEventType: this.state.tabIndex === 0 ? CalendarEventType.Action : CalendarEventType.Task,
    } as ICalendarEvent;

    this.setState({ calendarEvent, errors: undefined });
  }

  handleTabChange = (tabIndex: number) => {
    this.resetActivity();
    this.setState({ tabIndex });
  };

  onChange = (fieldName: string) => (event: any) => {
    const calendarEvent = {
      ...this.state.calendarEvent,
      [fieldName]: event.target.value,
    };

    this.setState({ calendarEvent });
  }

  onFieldChange = (fieldName: string) => (value: any) => {
    const calendarEvent = {
      ...this.state.calendarEvent,
      [fieldName]: value,
    };

    this.setState({ calendarEvent });
  }

  handleDateTimeChange = (changedEventArgs: any) => {
    if (!changedEventArgs || !changedEventArgs.isInteracted) {
      return;
    }

    const calendarEvent = {
      ...this.state.calendarEvent,
      startDateTime: changedEventArgs.value,
      endDateTime: moment(changedEventArgs.value).add(1, 'hours').utc().format(),
    };

    this.setState({ calendarEvent });
  };

  errorsExist = () => {
    const { calendarEvent } = this.state;
    const nameNotProvided = calendarEvent.name ? false : true;
    let startTimeNotProvided = false;

    if (this.state.tabIndex === 1) {
      startTimeNotProvided = calendarEvent.startDateTime ? false : true;
    }
    const errors = nameNotProvided || startTimeNotProvided ? { name: nameNotProvided, startTime: startTimeNotProvided } : undefined;

    this.setState({ errors });

    return errors ? true : false;
  }

  render() {
    const {
      classes,
      mainContent,
    } = this.props;
    const {
      tabIndex,
      calendarEvents,
      calendarEvent,
      errors,
      beginDatePicker,
      isCalendarEditModalOpen,
      appointment,
      reminder
    } = this.state;

    const titleLabel = tabIndex === 1 ? "Task Title" : "Action Title";

    return (
      <>
        <div className={classes.activityPanel}>
          <div className={mainContent ? classNames(classes.addActivityContainer, classes.margin16) : classes.addActivityContainer}>
            <div className={classes.tabContainer}>
              <BlueTextTab
                tabs={['Action', 'New Task']}
                tabIndex={tabIndex}
                onTabChange={this.handleTabChange}
              />
            </div>

            <div className={classes.addActivityRow}>
              <div className={classNames(classes.leftField)}>
                <TextField
                  label={titleLabel}
                  value={calendarEvent.name}
                  className={classes.miniTextField}
                  onFieldChange={this.onFieldChange('name')}
                  error={errors && errors.name} />
              </div>
            </div>

            <div className={classes.addActivityRow}>
              <div className={classNames(classes.width100)}>
                <RichTextField
                  value={calendarEvent.description}
                  onFieldChange={this.onFieldChange('description')}
                  config={this.froalaConfig}
                  error={errors && errors.description}
                />
              </div>
            </div>

            {tabIndex === 1 &&
            <div className={classes.addActivityRow}>
              <div className={mainContent ? classes.width25 : classes.width100}>
                <ActivityDateTimePicker
                  name="startTime"
                  label="Task Start"
                  floatLabelType="Never"
                  value={calendarEvent.startDateTime}
                  onChange={this.handleDateTimeChange}
                  minDate={beginDatePicker.min}
                  maxDate={beginDatePicker.max}
                  error={errors && errors.startTime}
                />
              </div>
            </div>
            }

            <div className={classes.addActivityRow}>
              <div className={classes.rowContentColumn1}>
                <div className={classes.overflow}>
                  {tabIndex === 1 &&
                    <>
                    <div className={mainContent ? classes.width25 : classes.width100}>
                      <Select
                        style={{ width: '100%', color: !!reminder ? '#637280' : '#9B9B9B' }}
                        value={reminder || 'none'}
                        onChange={(event: any) => this.setState({ reminder: event.target.value })}
                        name="reminder"
                        classes={{
                          select: classes.fontSelect,
                          icon: classes.black,
                        }}
                        disableUnderline={true}
                      >
                        <MenuItem value="none" disabled className={classes.fontMenu}>
                          Reminder
                        </MenuItem>
                        {REMINDERS && REMINDERS.map(reminder =>
                          <MenuItem key={reminder.id} value={reminder.id}
                            className={classNames(classes.fontMenu)}
                          >{reminder.name}</MenuItem>
                        )}
                      </Select>
                    </div>
                    </>}
                </div>
              </div>
              <div className={classes.rowContentColumn2}>
                <PrimaryButton onClick={this.onUpdate} className={classes.addButton}>{this.state.calendarEvent && this.state.calendarEvent.id ? 'Save' : 'Add'}</PrimaryButton>
              </div>
            </div>

          </div>

          <hr className={classes.hr}/>

          <div className={classes.filterTimeline}>
            Filter Timeline <ArrowDownButton isBorderless={true} />
          </div>

          <hr className={classes.hr}/>

          <div className={classes.activitiesSection}>
            <SectionHeader className={classes.header}>Next Steps</SectionHeader>
            {calendarEvents && calendarEvents.length ?
              calendarEvents.filter(calendarEvent => !calendarEvent.completedDateTime).map(calendarEvent => {
                return <Activity calendarEvent={calendarEvent} key={`${calendarEvent.calendarEventType}-${calendarEvent.id}`} onDelete={this.onDelete} onEdit={this.onEdit} onComplete={this.onComplete} />;
              })
              :
              <div className={classes.noActivity}>no next steps</div>}

            <hr className={classes.hr}/>

            <SectionHeader className={classes.header}>Past Activity</SectionHeader>
            {calendarEvents && calendarEvents.length ?
              calendarEvents.filter(calendarEvent => !!calendarEvent.completedDateTime).map(calendarEvent => {
                return <Activity calendarEvent={calendarEvent} key={`${calendarEvent.calendarEventType}-${calendarEvent.id}`} onDelete={this.onDelete} onEdit={this.onEdit} onComplete={this.onComplete} />;
              })
              :
              <div className={classes.noActivity}>no past calendarEvent</div>}
          </div>

          {isCalendarEditModalOpen && (
            <AddEditEventModal
              onModalClosed={this.onModalCancel}
              appointment={appointment}
              onRefresh={this.onRefresh}
              hasRelationship={true}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.oidc.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  logChitChatSystemMessage: (message: string, relationship: any, recordName: string) => dispatch(logChitChatSystemMessage(message, relationship, recordName)),
  saveNewChitChatThread: (chitChatType: any, chitChatThread: any) => dispatch(saveNewChitChatThread(chitChatType, chitChatThread)),
  fetchChitChat: (type: any, id: number) => dispatch(fetchChitChat(type, id)),
  deleteThread: (entityType: string, entityId: number, chitChatThreadId: number) =>
    dispatch(deleteThread(entityType, entityId, chitChatThreadId)),
  dispatch,
});

const ActivitiesPanelWithNoContext = withStyles(styles)(ActivityPanel);

const ActivitiesPanelWithContext = connect(mapStateToProps, mapDispatchToProps)(props => {
  const {
    relationship,
  } = useContext(SectionContext);

  const {
    logChitChatSystemMessage,
    saveNewChitChatThread,
    fetchChitChat,
    deleteThread,
    dispatch,
  } = props;

  return (<ActivitiesPanelWithNoContext
    CalendarEventService={new CalendarEventService(dispatch, relationship)}
    logChitChatSystemMessage={logChitChatSystemMessage}
    saveNewChitChatThread={saveNewChitChatThread}
    fetchChitChat={fetchChitChat}
    deleteThread={deleteThread}
  />);
});

export default ActivitiesPanelWithContext;
