import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SectionHeader from 'Components/SectionHeader';
import Table from 'Components/Table';
import classNames from 'classnames';
import { getBorderColor } from 'shared/themes';
import { connect } from 'react-redux';
import {
  getOrderPayments,
  addOrderPayment,
  refundOrderPayment,
} from 'actions/orderPayment';
import {
  getOrder,
  applyCoupon,
  removeCoupon,
} from 'actions/order';
import Payment from './Payment';
import { provideIntlService } from '@progress/kendo-react-intl';
import PaymentMethodDetails from './PaymentMethodDetails';
import { fade } from '@material-ui/core/styles/colorManipulator';
import FinancialsSummary from './FinancialsSummary';
import QuickFacts from './QuickFacts';

const styles = theme => {
  const tableBorderColor = getBorderColor(theme);

  return {
    root: {
      display: 'flex',
      backgroundColor: theme.palette.common.paper,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1,
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginRight: 16,
    },
    mainFieldsContainer: {
      width: '100%',
    },
    subTotalContainer: {
    },
    paymentsContainer: {
      display: 'flex',
      width: '100%',
      padding: 15,
      flexDirection: 'column',
    },
    paymentsTable: {
      lineHeight: '33px',
      '& td, & th': {
        padding: '0 5px',
      },
      '& th': {
        color: theme.palette.grey[300],
      },
      '& td': {
        borderLeft: `1px solid ${tableBorderColor}`,
      },
    },
    table: {
      color: theme.palette.primary.main,
      '& thead': {
        backgroundColor: theme.palette.grey[50],
        border: `1px solid ${tableBorderColor}`,
      },
      '& td': {
        border: `1px solid ${tableBorderColor}`,
      },
    },
    addPayment: {
      display: 'flex',
    },
    menu: {
      display: 'flex',
      backgroundColor: theme.palette.common.paper,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    toolBarButton: {
      padding: 0,
      minHeight: 32,
      minWidth: 32,
      marginRight: 10,
      borderColor: theme.palette.action.active,
    },
    iconStyle: {
      color: theme.palette.common.black,
      margin: 0,
      height: 18,
    },
    form: {
      display: 'flex',
      justifyContent: 'space-between',
      alignSelf: 'flex-end',
      position: 'absolute',
      padding: 5,
    },
    cardRoot: {
      display: 'flex',
      color: theme.palette.text.primary,
      margin: 8,
      '&:hover $cardCrudIcons': {
        visibility: 'visible',
      },
    },
    cardContent: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      padding: 8,
    },
    cardDetail: {
      display: 'flex',
      flexDirection: 'column',

    },
    cardTopRow: {
      display: 'flex',
    },
    cardDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    cardName: {
      fontSize: 14,
      fontWeight: 'bold',
      marginLeft: 25,
      maxWidth: 400,
      width: 200,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    cardDescription: {
      whiteSpace: 'normal',
      margin: '6px 0px 0px 30px',
      wordBreak: 'break-word',
      maxWidth: 300,
      overflow: 'hidden',
    },
    cardDescriptionText: {
      overflow: 'hidden',
      position: 'relative',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
      marginLeft: 25,
      maxWidth: 200,
    },
    cardCrudIcons: {
      visibility: 'hidden',
      marginLeft: -56,
      padding: 1,
      backgroundColor: fade(theme.palette.common.white, 0.5),
      height: 27,
      borderRadius: '0 0 0 4px',
    },
    sectionHeader: {
      flexGrow: 1,
      textAlign: 'center',
      fontSize: '18px',
    },
    orderDetails: {
      display: 'flex',
      flexGrow: '1',
      justifyContent: 'space-between',
      borderLeft: '2px solid #E6EAEE',
      flexWrap: 'wrap',
    },
    locationDetails: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: '14px',
    },
    clientDetails: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: '14px',
    },
    label: {
    },
    subLabel: {
      fontWeight: 300,
    },
    sectionTitle: {
      padding: 5,
      width: '100%',
      display: 'flex',
      justifyContent: 'left',
      fontSize: '18px',
      backgroundColor: '#F2F2F2',
      paddingLeft: 22,
      height: '35px',
    },
    detailsContainer: {
      display: 'flex',
      width: '100%',
      padding: 15,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 57,
    },
    quickDetails: {
      display: 'flex',
      flexGrow: '1',
      justifyContent: 'space-between',
      borderLeft: '2px solid #E6EAEE',
      flexWrap: 'wrap',
    },
  };
};

class PaymentTab extends Component {
  state = {
    payments: [],
  };

  paymentSuccess = () => {
    const { storeOrder } = this.props;

    this.props.getOrder(storeOrder.id);
    this.refreshPayments();
  }

  refreshPayments = () => {
    const { getOrderPayments, storeOrder } = this.props;

    getOrderPayments && getOrderPayments(storeOrder.id).then(payments => {
      this.setState({ payments });
    }).catch(() => {
      console.error('Something went wrong looking up order payments.');
    });
  }

  onRefund = paymentId => {
    const { refundOrderPayment, storeOrder } = this.props;

    refundOrderPayment(paymentId)
      .then(() => {
        this.props.getOrder(storeOrder.id);
        this.refreshPayments();
      });
  }

  componentDidMount() {
    this.formatter = provideIntlService(this);
    this.refreshPayments();
  }

  componentDidUpdate(prevProps) {
    const { storeOrder } = this.props;
    const newlyCreatedOrder = !!storeOrder && !!prevProps.storeOrder && storeOrder.id !== prevProps.storeOrder.id;

    if (newlyCreatedOrder) {
      this.refreshPayments();
    }
  }

  render() {
    const {
      classes,
      storeOrder,
      user,
      addOrderPayment,
      applyCoupon,
      removeCoupon,
    } = this.props;
    const { payments } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.column}>
          <div className={classes.addPayment}>
            <div className={classes.subTotalContainer}>
            </div>
            <div className={classes.mainFieldsContainer}>
              <PaymentMethodDetails
                order={storeOrder}
                user={user}
                addOrderPayment={addOrderPayment}
                paymentSuccess={this.paymentSuccess}
                applyCoupon={applyCoupon}
                removeCoupon={removeCoupon}
              />
            </div>
          </div>
          <SectionHeader>
            Payment Information
          </SectionHeader>
          <div className={classes.flexContainer}>
            <div className={classes.paymentsContainer}>
              <Table className={classNames(classes.paymentsTable, classes.table)} header={
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col" style={{ textAlign: 'center' }}>Amount</th>
                  <th scope="col" style={{ textAlign: 'center' }}>Tip</th>
                  <th scope="col">Method</th>
                  <th scope="col">Received By</th>
                  <th scope="col"></th>
                </tr>
              }>
                {payments && payments.length > 0 ? payments.map(payment =>
                  <Payment
                    payment={payment}
                    key={payment.id}
                    onRefund={this.onRefund}
                    formatter={this.formatter}
                    customerName={storeOrder.customerName}
                  />)
                  :
                  <tr height="100px"><td colSpan="6" text-align="center">No Payments</td></tr>
                }
              </Table>
              <div> &nbsp;</div>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>
        <div className={classes.column}>
          <div className={classes.quickDetails}>
            {storeOrder.orderDelivery &&
              <QuickFacts storeOrder={storeOrder} />
            }
          </div>
          <FinancialsSummary storeOrder={storeOrder} />
        </div>
      </div>);
  }
}

const mapStateToProps = state => ({
  user: state.oidc.user,
});

const mapDispatchToProps = {
  getOrderPayments,
  addOrderPayment,
  refundOrderPayment,
  getOrder,
  applyCoupon,
  removeCoupon,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PaymentTab));
