import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Tooltip,
} from '@material-ui/core';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';

const styles = theme => {
  return {
    button: {
      backgroundColor: lighten(theme.palette.grey[50], 0.77), //1 is white. 0 is same. Note: same as FCFCFC
      borderColor: theme.palette.action.active,
      color: theme.palette.grey[400], //on the calendar toolbar the text is black, but it's not black everywhere else. Using grey[400] accross the board for consistency
      fontSize: 13,
      minHeight: 32,
      minWidth: 32,
      padding: 0,
      textTransform: 'initial',
      marginLeft: -1, //to prevent double boarders when buttons are directly adjactent
      '&$disabled': {
        borderColor: theme.palette.action.active, //just a hack to set the border color on disabled buttons
      },
    },
    disabled: { },
    padLeftAndRight: {
      paddingLeft: 13,
      paddingRight: 13,
    },
    middleButton: {
      borderLeftColor: `${theme.palette.grey.A100} !important`,
      borderRightColor: `${theme.palette.grey.A100} !important`,
      borderRadius: '0px 0px 0px 0px',
    },
    leftButton: {
      borderRadius: '4px 0px 0px 4px',
      borderRightColor: `${theme.palette.grey.A100} !important`,
    },
    rightButton: {
      borderRadius: '0px 4px 4px 0px',
      borderLeftColor: `${theme.palette.grey.A100} !important`,
    },
    pressedButton: {
      backgroundColor: theme.palette.grey[100],//background-color: rgba(216,216,216,0.5);
    },
  };
};

class HighlightButton extends Component {
  render() {
    const {
      classes,
      children,
      styles,
      className,
      minWidth,
      pressed,
      variant,
      'aria-label': ariaLabel,
      isLoading,
      disabled,
      ...rest
    } = this.props;
    const style = minWidth && { minWidth };

    let mainStyle;

    if (variant === 'left') {
      mainStyle = classes.leftButton;
    } else if (variant === 'right') {
      mainStyle = classes.rightButton;
    } else if (variant === 'middle') {
      mainStyle = classes.middleButton;
    }

    const button = (
      <Button
        {...rest}
        style={style}
        variant="outlined"
        aria-label={ariaLabel}
        disabled={disabled || isLoading}
        classes={
          {
            root: classNames(
              classes.button,
              mainStyle,
              {
                [classes.pressedButton]: pressed,
                [classes.padLeftAndRight]: !React.isValidElement(children), //will also add padding if array of elements
              },
              className),
            disabled: classes.disabled,
          }
        }
      >
        {children}
      </Button>
    );

    if (ariaLabel) {
      return (
        <Tooltip title={ariaLabel} placement="top">
          {button}
        </Tooltip>
      );
    }

    return button;
  }
}

HighlightButton.propTypes = {
  variant: PropTypes.string,
  pressed: PropTypes.bool,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(HighlightButton);
