import { callApi } from 'shared/CallApi';
import {
  SET_STORE_MENU,
  UPDATE_STORE_MENU_SELECTED_CATEGORY_ITEMS,
  SET_STORE_MENU_FILTER,
  SET_STORE_MENU_CATEGORIES,
  SET_STORE_MENU_INDEX,
  UPDATE_STORE_MENU,
} from './constants';

export const setStoreMenu = storeMenuConfig => ({ type: SET_STORE_MENU, storeMenuConfig });
export const changeMenuReducer = storeMenuConfig => ({ type: UPDATE_STORE_MENU, storeMenuConfig });

export const setStoreMenuIndex = storeCurrentMenuIndex => ({ type: SET_STORE_MENU_INDEX, storeCurrentMenuIndex });
export const updateStoreMenuSelectedCategoryItems = menuItems => ({ type: UPDATE_STORE_MENU_SELECTED_CATEGORY_ITEMS, menuItems });
export const setStoreMenuFilter = (filteredMenuItems, filteredCategories, currentCategoryItems) => ({ type: SET_STORE_MENU_FILTER, filteredMenuItems, filteredCategories, currentCategoryItems });
export const setStoreMenuCategories = storeMenuCategories => ({ type: SET_STORE_MENU_CATEGORIES, storeMenuCategories });

export const getStoreMenu = menuType => dispatch => {
  return dispatch(getStoreMenuApi(menuType)).then(menus => {
    dispatch(setStoreMenuIndex(0));
    const storeAllItems = getAllStoreItems(menus);

    const storeMenus = menus;
    const storeMenuCategories = getStoreMenuCategories(storeMenus[0]);
    const storeMenuRepresentedItemTags = getTagsFromMenu(storeMenus[0]);
    const storeMenuSelectedCategoryItems = getAllMenuItemsFromAllCategories(storeMenus[0]);
    const storeMenuConfig = {
      storeAllItems,
      storeMenus,
      storeMenuCategories,
      storeMenuSelectedCategoryItems,
      storeMenuRepresentedItemTags,
    };

    dispatch(setStoreMenu(storeMenuConfig));  //TODO: split code for booking/order?
  });
};

const getStoreMenuApi = menuType => //TODO: TYPE for menuType, TS?
  dispatch => {
    return dispatch(
      callApi(`menu/${menuType}`))
      .then(response => response.json())
      .catch(console.error);
  };

export const changeMenu = index =>
  (dispatch, getState) => {
    const { storeReducer: { storeMenus } } = getState();

    dispatch(setStoreMenuIndex(index));
    const storeMenuCategories = getStoreMenuCategories(storeMenus[index]);
    const storeMenuRepresentedItemTags = getTagsFromMenu(storeMenus[index]);
    const storeMenuSelectedCategoryItems = getAllMenuItemsFromAllCategories(storeMenus[index]);
    const storeMenuConfig = {
      storeMenuCategories,
      storeMenuSelectedCategoryItems,
      storeMenuRepresentedItemTags,
    };

    dispatch(changeMenuReducer(storeMenuConfig));
  };

export const getStoreMenuCategories = storeMenu => {
  if (storeMenu && storeMenu.menuCategories) {
    return storeMenu.menuCategories.map(CategoryItem => {
      return { name: CategoryItem.name, id: CategoryItem.id, imageUrl: CategoryItem.imageUrl };
    });
  }
};

export const filterMenu = (filter, selectedCategory) => (dispatch, getState) => {
  const { storeReducer: {
    storeMenus,
    storeCurrentMenuIndex,
    storeAllMenuItems,
  } } = getState();

  if (filter && filter.length > 0) {
    const tagFilters = filter && filter.filter(filter => filter.type === 'tag').map(tag => tag.value);
    const searchFilter = filter && filter.filter(filter => filter.type !== 'tag').map(tag => tag.value.toLowerCase());

    const allMenuItems = getAllMenuItemsFromAllCategories(storeMenus[storeCurrentMenuIndex]);

    const filteredMenuItems = allMenuItems.filter(menuItem => ((!tagFilters || tagFilters.length === 0)
      || (menuItem.menuItem.tags
        && tagFilters.every(tag => menuItem.menuItem.tags.some(menuItemTag => menuItemTag === tag))))
      && ((!searchFilter || searchFilter.length === 0)
        || searchFilter.every(searchTerm => menuItem.name.toLowerCase().indexOf(searchTerm) > -1)));

    const categoryIds = filteredMenuItems.map(item => item.menuCategoryId);

    const categories = storeMenus[storeCurrentMenuIndex].menuCategories.filter(category => categoryIds.indexOf(category.id) > -1);
    const currentMenuItems = categoryIds.length > 0 ? filteredMenuItems.filter(menuItem => selectedCategory && categoryIds.indexOf(selectedCategory.id) > -1 ? menuItem.menuCategoryId === selectedCategory.id : menuItem.menuCategoryId === categoryIds[0]) : [];

    return dispatch(setStoreMenuFilter(filteredMenuItems, categories, currentMenuItems));
  } else {
    if ( !selectedCategory || !storeMenus[storeCurrentMenuIndex].menuCategories) {
      return dispatch(setStoreMenuFilter(storeMenus[storeCurrentMenuIndex].menuCategories[0].menuCategoryItems));
    }
    const menuCategories = storeMenus[storeCurrentMenuIndex].menuCategories.find(category =>
      category.id === selectedCategory.id);

    return dispatch(setStoreMenuFilter(storeAllMenuItems,
      storeMenus[storeCurrentMenuIndex].menuCategories,
      selectedCategory && menuCategories ? menuCategories.menuCategoryItems : storeMenus[storeCurrentMenuIndex].menuCategories[0].menuCategoryItems));
  }
};

export const setStoreMenuItemsFromACategory = selectedCategory => (dispatch, getState) => {
  const { storeReducer: {
    filteredMenuItems,
  } } = getState();

  return dispatch(updateStoreMenuSelectedCategoryItems(filteredMenuItems.filter(item => item.menuCategoryId === selectedCategory.id)));
};

export const getAllMenuItemsFromAllCategories = storeMenu => {
  let menuItems = [];

  if (storeMenu && storeMenu.menuCategories) {
    for (var i = 0; i < storeMenu.menuCategories.length; i++) {
      const menuCategory = storeMenu.menuCategories[i];

      returnCategoryItemRec(menuItems, menuCategory.menuCategories);

      for (var j = 0; j < menuCategory.menuCategoryItems.length; j++) {
        menuCategory.menuCategoryItems[j].menuItem.menuCategoryItemId = menuCategory.menuCategoryItems[j].id;
        menuCategory.menuCategoryItems[j].menuItem.quantity = 1;
        menuItems.push(menuCategory.menuCategoryItems[j]);
      }
    }
  }

  return menuItems;
};

const returnCategoryItemRec = (menuItems, menuCategories) => {
  if (menuCategories) {
    for (var i = 0; i < menuCategories.length; i++) {
      const menuCategory = menuCategories[i];

      returnCategoryItemRec(menuItems, menuCategory.menuCategories);

      for (var j = 0; j < menuCategory.menuCategoryItems.length; j++) {

        menuCategory.menuCategoryItems[j].menuItem.menuCategoryItemId = menuCategory.menuCategoryItems[j].id;
        menuCategory.menuCategoryItems[j].menuItem.quantity = 1;
        menuItems.push(menuCategory.menuCategoryItems[j]);
      }
    }
  }
};

export const getAllStoreItems = menus => {
  let allStoreItems = [];

  if (menus && menus.length) {
    menus.forEach((menu,index) => {
      allStoreItems.push(...getAllMenuItemsFromAllCategories(menu, index));
    });
  }

  return allStoreItems;
};

export const getTagsFromMenu = storeMenu => {
  const menuCategories = storeMenu && storeMenu.menuCategories;

  if (menuCategories && menuCategories.length) {
    const tags = new Set();

    menuCategories.forEach(mc => mc.menuCategoryItems
      .forEach(mci => mci.menuItem.tags
        .forEach(tag => tags.add(tag))));

    return Array.from(tags);
  }

  return [];
};
