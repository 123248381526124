import React, { Component } from 'react';
import { connect } from 'react-redux';
import LargeBarChartWidget from 'Components/GraphWidgets/LargeBarChartWidget';
import CardStyledGraphWidget from 'Components/GraphWidgets/CardStyledGraphWidget';
import { getSalesData } from 'actions/sales';
import TopSellingItemWidget from './TopSellingItemWidget';
import ReferralSourcesGridWidget from './ReferralSourcesGridWidget';
import { Paper, withStyles } from '@material-ui/core';
import FilterBox from '../FilterBox';
import classNames from 'classnames';
import moment from 'moment';

const customerTypes = [
  {
    id: 1,
    name: 'All',
    amount: 398,
  },
  {
    id: 2,
    name: 'Meeting',
    amount: 152,
  },
  {
    id: 3,
    name: 'Banquet',
    amount: 54,
  },
  {
    id: 4,
    name: 'Wedding Reception',
    amount: 124,
  },
  {
    id: 5,
    name: 'Seminar',
    amount: 9,
  },
  {
    id: 6,
    name: 'Retirement',
    amount: 3,
  },
  {
    id: 7,
    name: 'Birthday Party',
    amount: 28,
  },
  {
    id: 8,
    name: 'Office Party',
    amount: 17,
  },
  {
    id: 9,
    name: 'Convention',
    amount: 32,
  },
  {
    id: 10,
    name: 'Wedding Ceremony',
    amount: 34,
  },
  {
    id: 11,
    name: 'Luncheon',
    amount: 25,
  },
  {
    id: 12,
    name: 'Delivery',
    amount: 10,
  },
];

const styles = theme => ({
  leftContainer: {
    flexGrow: 1,
  },
  rightContainer: {
    minWidth: 165,
    maxWidth: 275,
  },
  spaceRight: {
    marginRight: '1%',
    [theme.breakpoints.up('lg')]: {
      marginRight: 30,
    },
  },
  mainContent: {
    marginBottom: 30,
  },
  lowerContent: {
    display: 'flex',
    width: '100%',
    flex: '0 1 auto',
    flexWrap: 'wrap',
    marginBottom: 30,
  },
  card: {
    padding: 16,
    width: '100%',
  },
  cardchart: {
    flexGrow: 1,
  },
  cardrow: {
    display: 'flex',
  },
  cardpanel: {
    display: 'flex',
    overflow: 'auto',
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    padding: '24px 24px 0',
  },
  cardheading: {
    fontSize: 18,
    padding: '23px 30px',
  },
  chartContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
});

const allData = {
  eventRevenue: 42320,
  totalSales: 420,
  totalSalesRevenue: 520000,
  eventChange: .098,
  eventRevenueChange: -.119,
  salesChange: .122,
  salesRevenueChange: .196,
  graph1: [12, 19, 7, 15, 14, 21, 5],
  graph2: [22, 2, 1, 7, 5, 4, 1, 5],
  graph3: [20, 19, 70, 50, 40, 21, 50],
  graph4: [21, 91, 35, 55, 14, 21, 60],
};

const filterData = {
  eventRevenue: 29117,
  totalSales: 277,
  totalSalesRevenue: 322510,
  eventChange: .061,
  eventRevenueChange: .159,
  salesChange: -.075,
  salesRevenueChange: -.055,
  graph1: [...allData.graph1].reverse(),
  graph2: [...allData.graph2].reverse(),
  graph3: [...allData.graph3].reverse(),
  graph4: [...allData.graph4].reverse(),
};

export class HomeSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mockSeed: customerTypes[0].amount,
      salesdata: [],
      series: [{
        name: 'World',
        data: [],
      }],
    };
    this.date = moment();

  };

  componentWillMount() {
    this.getSalesData(this.state.mockSeed, this.date);
  }

  getSalesData = (mockSeed, date) => {
    this.props.getSalesData(mockSeed, date).then(newSalesData => {
      const categories = [];

      for (var i = 1;i <= newSalesData.length;i++) {
        categories.push(i);
      }

      this.setState({
        mockSeed: mockSeed,
        series: [{
          name: 'World',
          data: newSalesData,
        }],
        categories,
      });
    });
  }

  filterChanged = filter => {
    if (filter.amount) {
      this.getSalesData(filter.amount, this.date);
    } else {
      this.setState({ filter });
    }
  }

  render() {
    const {
      classes,
      theme,
    } = this.props;

    const {
      series,
      categories,
      mockSeed,
    } = this.state;
    const mockData = mockSeed === customerTypes[0].amount ? allData : filterData;

    return (
      <div className={classes.cardpanel}>
        <div className={classNames(classes.leftContainer, classes.spaceRight)}>
          <Paper className={classes.mainContent}>
            <div className={classes.cardheading}>Overview</div>
            <div className={classes.chartContainer}>
              <LargeBarChartWidget series={series} datacategories={categories} />
            </div>
          </Paper>
          <div className={classes.cardrow}>
            <div className={classNames(classes.cardchart, classes.spaceRight)}>
              <CardStyledGraphWidget
                heading="Total # of Events"
                number={mockSeed}
                percent={mockData.eventChange}
                graphdata={mockData.graph1}
                graphcolor={theme.common.red}
              />
            </div>
            <div className={classNames(classes.cardchart, classes.spaceRight)}>
              <CardStyledGraphWidget
                heading="Event Revenue"
                isCurrency={true}
                number={mockData.eventRevenue}
                percent={mockData.eventRevenueChange}
                graphdata={mockData.graph2}
                graphcolor={theme.common.green}
              />
            </div>
            <div className={classNames(classes.cardchart, classes.spaceRight)}>
              <CardStyledGraphWidget
                heading="Total # of Sales"
                number={mockData.totalSales}
                percent={mockData.salesChange}
                graphdata={mockData.graph3}
                graphcolor={theme.common.purple}
              />
            </div>
            <div className={classes.cardchart}>
              <CardStyledGraphWidget
                heading="Total Sales Revenue"
                isCurrency={true}
                number={mockData.totalSalesRevenue}
                percent={mockData.salesRevenueChange}
                graphdata={mockData.graph4}
                graphcolor={theme.common.yellow}
              />
            </div>
          </div>
          <div className={classes.lowerContent}>
            <div style={{ width: '66%' }}>
              <TopSellingItemWidget className={classes.spaceRight} />
            </div>
            <div style={{ width: '33%' }}>
              <ReferralSourcesGridWidget />
            </div>
          </div>
        </div>
        <div className={classes.rightContainer}>
          <FilterBox
            customerTypes={customerTypes}
            onFilterChanged={this.filterChanged}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { tasks } = state.api;

  return {
    tasks,
  };
};

const mapDispatchToProps = {
  getSalesData,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(HomeSales));
