import React from 'react';
import Modal from 'Components/Modal';
import Shifts from './Shifts';

const ShiftsModal = ({
  onModalClosed,
  shiftConfigId,
  defaultStartTime,
  defaultEndTime,
  storeType,
}) =>
  (<Modal
    isOpened={true}
    onCancel={onModalClosed}
    title="Select Shifts"
    saveText="Okay"
    showCancel={false}
    onSave={onModalClosed}
    addTitleBottomBorder={true}
  >
    <Shifts
      shiftConfigId={shiftConfigId}
      defaultStartTime={defaultStartTime}
      defaultEndTime={defaultEndTime}
      storeType={storeType}
    />
  </Modal>);

export default ShiftsModal;
