import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import moment from 'moment';

const styles = theme => ({
  masonry: {
    display: 'grid',
    gridTemplateColumns: '0 auto',
    gridTemplateRows: '130px',
  },
  gridBuffer: {
    width: '100%',
  },
  cellContents: {
    display: 'flex',
    width: '100%',
  },
  contactLabel: {
    color: theme.palette.grey[200],
  },
  contactInput: {
    color: theme.palette.grey[300],
  },
  flexGrowWithPad: {
    flexGrow: 1,
    margin: '16px 25px 8px 25px',
  },
  halfWidth: {
    display: 'flex',
    width: '50%',
  },
  twoColumns: {
    gridColumn: '2 / 4',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 2,
  },
  contents2x1: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
});

function AuditTrail({ classes, contact }) {
  return (
    <CoverExpansionPanel title="Audit Trail" defaultExpanded={true}>
      <div className={classes.gridBuffer}>
        <div className={classes.masonry}>
          <div className={classNames(classes.cellContents, classes.twoColumns)}>
            <div className={classes.contents2x1}>
              <div className={classes.halfWidth}>
                <TextField
                  label="Created By"
                  className={classes.flexGrowWithPad}
                  name="createdBy"
                  value={`${contact.createdBy || ''}  ${moment(contact.createdUtcDateTime).clone().format('l LT')}`}
                  margin="none"
                  disabled={true}
                  InputLabelProps={{
                    classes: {
                      root: classes.contactLabel,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.contactInput,
                      disabled: classes.contactInput,
                    },
                  }}
                />
              </div>
              <div className={classes.halfWidth}>
                <TextField
                  label="Last Modified"
                  className={classes.flexGrowWithPad}
                  name="lastModified"
                  value={`${contact.lastModifiedBy || ''}  ${moment(contact.lastModifiedUtcDateTime || contact.createdUtcDateTime).clone().format('l LT')}`}
                  margin="none"
                  disabled={true}
                  InputLabelProps={{
                    classes: {
                      root: classes.contactLabel,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.contactInput,
                      disabled: classes.contactInput,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CoverExpansionPanel>
  );
}

export default withStyles(styles)(AuditTrail);
