import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductDetailsModal from './ProductDetailsModal';

class StoreMenuItemModifier extends Component {

  onSave = orderItem => {
    const {
      updateStoreOrderItem,
      onModalClosed,
    } = this.props;

    updateStoreOrderItem(orderItem);
    onModalClosed();
  }

  render() {
    const {
      onModalClosed,
      title,
      menuItem,
    } = this.props;

    return (
      <ProductDetailsModal
        onModalClosed={onModalClosed}
        title={title}
        onSave={this.onSave}
        item={menuItem}
      />
    );
  }
}

StoreMenuItemModifier.propTypes = {
  onModalClosed: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default StoreMenuItemModifier;
