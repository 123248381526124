import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ItemTagModal from 'portal/admin/menumanagement/MenuItemSelectionModal/ItemTagModal';
import { provideIntlService } from '@progress/kendo-react-intl';
import {
  Button,
  ButtonBase,
} from '@material-ui/core';
import StoreOrderItemModifier from '../ModifierModal/StoreOrderItemModifier';
import Card from '@material-ui/core/Card';
import {
  addItemToStoreOrder,
} from 'actions/order';
import Avatar from 'Components/Avatar';
import StoreMenuItemDescription from 'Components/StoreMenuItemDescription';
import AlacarteModal from '../../alacarte';
import {
  deleteStoreOrderItem,
} from 'actions/order';

const styles = theme => ({
  tags: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  cartHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#0070D2',
    color: 'white',
    height: 36,
    fontWeight: 'bold',
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    borderTop: `1px solid ${theme.palette.grey[50]}`,
    alignItems: 'center',
  },
  cartTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    height: 36,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  cartValue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    height: 36,
    fontWeight: 'bold',
    padding: 4,
  },
  subtotalTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 11,
    height: 36,
    fontWeight: 'bold',
    padding: 4,
  },
  subtotalValue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 11,
    height: 36,
    fontWeight: 'bold',
    paddingRight: 10,
    color: '#4bca81',
    marginRight: 10,
  },
  image: {
    margin: 6,
    paddingRight: 8,
  },
  img: {
    margin: 'auto',
    maxWidth: '100%',
    width: '115px',
    height: '75px',
  },
  itemContainer: {
    display: 'flex',
    margin: 8,
  },
  imageContainer: {
    width: '35%',
  },
  imageContent: {
    width: '65%',
  },
  itemName: {
    width: '100%',
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
  },
  modifierContainer: {
    width: '100%',
    textAlign: 'left',
    fontSize: 11,
  },
  modifierSelected: {
    fontSize: 11,
  },
  itemDetails: {
    display: 'flex',
    // width: '235px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  itemQtyContainer: {
    display: 'flex',
    border: '1px solid #2189F8',
    borderRadius: 4,
    padding: '0px 2px 0px 4px',
  },
  itemQtyLabel: {
    paddingRight: 2,
    textAlign: 'left',
  },
  itemQtyValue: {
    width: '40px',
    textAlign: 'center',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  itemPrice: {
    fontSize: 14,
    fontWeight: 700,
  },
  itemButtonContainer: {
    display: 'flex',
    marginTop: '4px',
  },
  itemButton: {
    padding: 4,
    display: 'flex',
    fontSize: 11,
    color: '#2189F8',
    textTransform: 'none',
    minWidth: '30px',
  },
  buttonDivider: {
    borderRight: '1px solid',
    alignSelf: 'center',
    height: 11,
  },
  avatar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.common.bluegrey,
    fontSize: 32,
    fontWeight: '600',
    minWidth: '115px',
    height: '75px',
    color: theme.palette.grey['50'],
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
});

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemTagModalOpened: false,
      tags: [],
      cartTotal: 0,
      modifiersModalVisible: false,
      alacarteModalVisible: false,
      selectedOrderItem: {},
    };

    this.formatter = provideIntlService(this);
  }

  componentDidMount() {
    const { tags } = this.props;

    if (tags) {
      this.setState({ tags });
    }
  }

  componentDidUpdate(prevProps) {
    const { tags } = this.props;

    if (tags !== prevProps.tags) {
      this.setState({ tags });
    }
  }

  isSelected = tagId => {
    const { selectedTagIds } = this.props;

    return selectedTagIds.some(id => id === tagId);
  }

  // This is a tag that is represented in the visible menu items
  isRepresented = tagId => {
    const { representedTagIds } = this.props;

    return representedTagIds.some(id => id === tagId);
  }

  onSelectTag = id => () => {
    //the parent component will toggle the button on and off
    this.props.onSelectTag(id);
  }

  closeItemTags = () => {
    this.setState({ itemTagModalOpened: false });
  }

  saveItemTags = tags => {

    this.props.selectedTagIds.forEach(selctedTagId => {
      if (!tags.find(tag => tag.id === selctedTagId)) {
        //anything in this.props.selectedTagIds, but not in tags[] will need to call onSelect
        //remove because the button is hidden so there is no way for the user to unselect the item tag
        this.props.onSelectTag(selctedTagId);
      }
    });
    this.setState({ tags });
  }

  openItemTagModal = () => {
    const allDisplayedTagIds = this.state.tags.map(tag => tag.id);

    this.setState({ itemTagModalOpened: true, allDisplayedTagIds });
  }

  getCurrentOrderItems = () => {
    if (this.props.storeOrder.orderItems) {
      return this.props.storeOrder.orderItems.filter(orderItem => orderItem.recordStatus === 'Active');
    }

    return [];
  }

  onEditItem = (synthEvent, selection) => {
    synthEvent.stopPropagation();
    if (selection.orderItem.menuCategoryItemId) {
      this.setState({
        selectedOrderItem: selection,
        modifiersModalVisible: true,
      });
    } else {
      this.setState({
        selectedOrderItem: selection,
        alacarteModalVisible: true,
      });
    }
  }

  onFieldChange = fieldName => event => {
    this.setState({ [fieldName]: event.target.value });
  }

  getCartItemCount = () => {
    if (this.props.storeOrder.orderItems) {
      return this.props.storeOrder.orderItems.filter(orderItem => orderItem.recordStatus === 'Active').length;
    }
  }

  onModifiersModalSave = data => {
    this.setState({
      modifiersModalVisible: false,
    });
  }

  updateModifersForSelectedItem = returnModifiers => {
    const { selectedOrderItem } = this.state;

    selectedOrderItem.modifiers = returnModifiers.modifiers;
    selectedOrderItem.quantity = returnModifiers.quantity;

    this.props.addItemToStoreOrder(selectedOrderItem);

    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  getImage = item => {
    if (item.menuCategoryItem && item.menuCategoryItem.imageUrl) {
      return item.menuCategoryItem.imageUrl;
    }

    return '';
  }

  onAlacarteModalSave = data => {
    this.setState({
      alacarteModalVisible: false,
    });
  }

  onOpenAlacarteModal = data => {
    this.setState({
      alacarteModalVisible: true,
    });
  }

  render() {
    const {
      classes,
      storeOrder,
    } = this.props;
    const {
      allDisplayedTagIds,
      itemTagModalOpened,
      modifiersModalVisible,
      selectedOrderItem,
      alacarteModalVisible,
    } = this.state;

    return (
      <>
        <div className={classes.cartHeader}>
          <div className={classes.itemButtonContainer}>
            <div className={classes.cartTitle}>
              Cart
            </div>
            <div className={classes.cartValue}>
              ({this.getCartItemCount()})
            </div>
          </div>
          <div className={classes.itemButtonContainer}>
            <div className={classes.subtotalTitle}>
              Subtotal:
            </div>
            <div className={classes.subtotalValue}>
              {this.formatter.formatNumber(storeOrder.subTotal, 'c2')}
            </div>
          </div>
        </div>
        <div className={classNames(classes.tags)}>
          {this.getCurrentOrderItems().map((menuItem, index) => (
            <div key={index}>
              <ItemTagModal
                isOpened={itemTagModalOpened}
                checkedIds={allDisplayedTagIds}
                onModalClosed={this.closeItemTags}
                onSave={this.saveItemTags}
                title={`Modify Item Tags`}
              />
              <div>
                <Card className={classes.card} square={true}>
                  <div className={classes.itemContainer}>
                    <div className={classes.imageContainer}>
                      <ButtonBase className={classes.image}>
                        {this.getImage(menuItem) ?
                          <img className={classes.img} alt="item" src={this.getImage(menuItem)} />
                          :
                          <Avatar name={menuItem.name} />
                        }
                      </ButtonBase>
                    </div>
                    <div className={classes.imageContent}>
                      <div className={classes.itemHeader}>
                        <div className={classes.itemName}>
                          {menuItem.name}
                        </div>
                        <div className={classes.itemPrice}>
                          {this.formatter.formatNumber(menuItem.price, 'c2')}
                        </div>
                      </div>
                      <div className={classes.modifierContainer}>
                        <StoreMenuItemDescription item={menuItem} />
                      </div>
                      <div className={classes.itemDetails}>
                        <div className={classes.itemQty}>
                          <div className={classes.itemQtyContainer}>
                            <label className={classes.itemQtyLabel}>Qty: {menuItem.quantity}</label>
                          </div>
                        </div>
                        <div className={classes.itemButtonContainer}>
                          <Button className={classes.itemButton} onClick={synthEvent => this.onEditItem(synthEvent, { index, orderItem: menuItem })}>Edit</Button>
                          <div className={classes.buttonDivider}></div>
                          <Button
                            className={classes.itemButton}
                            onClick={() => this.props.deleteStoreOrderItem({ orderItem: menuItem, index })}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              {modifiersModalVisible &&
                <StoreOrderItemModifier
                  onModalClosed={this.onModifiersModalSave}
                  updateSetsOfModifiers={this.updateModifersForSelectedItem}
                  selectedOrderItem={selectedOrderItem}
                />
              }
              {alacarteModalVisible && <AlacarteModal
                onModalClosed={this.onAlacarteModalSave}
                selectedOrderItem={selectedOrderItem}
              />}
            </div>
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeOrder,
    },
  } = state;

  return {
    storeOrder,
  };
};

const mapDispatchToProps = {
  addItemToStoreOrder,
  deleteStoreOrderItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Cart));
