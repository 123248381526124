
import {
  FETCH_BOOKING_MENU_COLUMNS_BEGIN,
  BOOKING_MENU_COLUMNS_RECEIVED,
  CONTACT_COLUMNS_RECEIVED,
  BOOKING_COLUMNS_RECEIVED,
  LEAD_COLUMNS_RECEIVED,
  ORDER_COLUMNS_RECEIVED,
  ACCOUNT_COLUMNS_RECEIVED,
  MODIFIER_COLUMNS_RECEIVED,
  ITEM_COLUMNS_RECEIVED,
  CLONE_BOOKING_PREFERENCES_RECEIVED,
  RECEIVED_STATUSES,
  RECEIVED_SYSTEM_STATUSES,
  SET_STATUS_GROUP,
  MENU_SETTINGS_RECEIVED,
  SETTING_AREAS_RECEIVED,
} from 'actions/constants';
import {
  defaultMenuItemGridColumns,
  defaultContactsGridColumns,
  defaultBookingsGridColumns,
  defaultAccountsGridColumns,
  defaultOrdersGridColumns,
  defaultsModifierGridColumns,
  defaultLeadsGridColumns,
  defaultMenuCategoryItemsGridColumns,
  defaultBookingMenuGridColumns,
} from 'constants/gridColumnDefaults';
import _ from 'lodash';

export const setupGridColumns = (columnNames, defaultColumnSetting) => {
  const columns = columnNames && columnNames.length > 0 ? columnNames : defaultColumnSetting.default;
  const allColumns = defaultColumnSetting.all;
  const defaultColumns = defaultColumnSetting.default;

  const kendoFriendlyColumns = allColumns.map(column => {
    const indexOfColumnName = columns.indexOf(column);

    return {
      field: column,
      sortOrder: indexOfColumnName > -1 ? indexOfColumnName : allColumns.length,
    };
  }).sort((a, b) => a.sortOrder - b.sortOrder).map(column => {
    const indexOfColumnName = columns.indexOf(column.field);
    const isVisible = indexOfColumnName !== -1;

    return {
      field: column.field,
      title: _.startCase(column.field),
      isVisible,
      isDefault: defaultColumns.indexOf(column) !== -1,
    };
  });

  return kendoFriendlyColumns;
};

const initialState = {
  menu: {
    bookingMenuColumns: setupGridColumns(defaultBookingMenuGridColumns.default, defaultBookingMenuGridColumns),
    isFetchingBookingMenuColumns: false,
  },
  modifiers: {
    columns: setupGridColumns(defaultsModifierGridColumns.all, defaultsModifierGridColumns),
  },
  contacts: {
    columns: setupGridColumns(defaultContactsGridColumns.all, defaultContactsGridColumns),
  },
  accounts: {
    columns: setupGridColumns(defaultAccountsGridColumns.all, defaultAccountsGridColumns),
  },
  leads: {
    columns: setupGridColumns(defaultLeadsGridColumns.all, defaultLeadsGridColumns),
  },
  orders: {
    columns: setupGridColumns(defaultOrdersGridColumns.all, defaultOrdersGridColumns),
  },
  bookings: {
    columns: setupGridColumns(defaultBookingsGridColumns.all, defaultBookingsGridColumns),
    clonePreferences: [],
  },
  menuCategoryItems: {
    columns: setupGridColumns(defaultMenuCategoryItemsGridColumns.all, defaultMenuCategoryItemsGridColumns),
  },
  menuItems: {
    columns: setupGridColumns(defaultMenuItemGridColumns.all, defaultMenuItemGridColumns),
  },
  statuses: [],
  menuSettings: {},
  settingAreas: [],
  allAreaSettings: {},
  odata: {
    take: 30, // Take somehow converts to top with the odata toODataString
  },
};

export function settings(state = initialState, action) {
  switch (action.type) {

    case FETCH_BOOKING_MENU_COLUMNS_BEGIN: {
      return {
        ...state,
        menu: {
          ...state.menu,
          isFetchingBookingMenuColumns: true,
        },
      };
    }
    case BOOKING_MENU_COLUMNS_RECEIVED: {
      const { columns } = action;
      const usefulColumns = setupGridColumns(columns, defaultBookingMenuGridColumns);

      return {
        ...state,
        menu: {
          ...state.menu,
          bookingMenuColumns: usefulColumns.length > 0 ? usefulColumns : state.menu.bookingMenuColumns,
          isFetchingBookingMenuColumns: false,
        },
      };
    }
    case CONTACT_COLUMNS_RECEIVED: {
      const { columns } = action;
      const usefulColumns = setupGridColumns(columns, defaultContactsGridColumns);

      return {
        ...state,
        contacts: {
          ...state.contacts,
          columns: usefulColumns.length > 0 ? usefulColumns : state.contacts.columns,
        },
      };
    }
    case BOOKING_COLUMNS_RECEIVED: {
      const { columns } = action;
      const usefulColumns = setupGridColumns(columns, defaultBookingsGridColumns);

      return {
        ...state,
        bookings: {
          ...state.bookings,
          columns: usefulColumns.length > 0 ? usefulColumns : state.bookings.columns,
        },
      };
    }
    case LEAD_COLUMNS_RECEIVED: {
      const { columns } = action;
      const usefulColumns = setupGridColumns(columns, defaultLeadsGridColumns);

      return {
        ...state,
        leads: {
          ...state.leads,
          columns: usefulColumns.length > 0 ? usefulColumns : state.leads.columns,
        },
      };
    }
    case ORDER_COLUMNS_RECEIVED: {
      const { columns } = action;
      const usefulColumns = setupGridColumns(columns, defaultOrdersGridColumns);

      return {
        ...state,
        orders: {
          ...state.orders,
          columns: usefulColumns.length > 0 ? usefulColumns : state.orders.columns,
        },
      };
    }
    case ACCOUNT_COLUMNS_RECEIVED: {
      const { columns } = action;
      const usefulColumns = setupGridColumns(columns, defaultAccountsGridColumns);

      return {
        ...state,
        accounts: {
          ...state.accounts,
          columns: usefulColumns.length > 0 ? usefulColumns : state.accounts.columns,
        },
      };
    }
    case MODIFIER_COLUMNS_RECEIVED: {
      const { columns } = action;
      const usefulColumns = setupGridColumns(columns, defaultsModifierGridColumns);

      return {
        ...state,
        modifiers: {
          ...state.modifiers,
          columns: usefulColumns.length > 0 ? usefulColumns : state.modifiers.columns,
        },
      };
    }
    case ITEM_COLUMNS_RECEIVED: {
      const { columns } = action;
      const usefulColumns = setupGridColumns(columns, defaultMenuItemGridColumns);

      return {
        ...state,
        menuItems: {
          ...state.menuItems,
          columns: usefulColumns.length > 0 ? usefulColumns : state.menuItems.columns,
        },
      };
    }
    case CLONE_BOOKING_PREFERENCES_RECEIVED: {
      const { clonePreferences } = action;

      return clonePreferences.length > 0 ?
        {
          ...state,
          bookings: {
            ...state.bookings,
            clonePreferences,
          },
        } :
        state;
    };
    case RECEIVED_STATUSES: {
      const { statuses } = action;

      return {
        ...state,
        statuses,
      };
    }
    case RECEIVED_SYSTEM_STATUSES: {
      const { statuses } = action;

      return {
        ...state,
        systemStatuses: statuses,
      };
    }
    case SET_STATUS_GROUP: {
      const { statusGroup } = action;

      let { statuses } = state;

      statuses[statuses.findIndex(group => group.type === statusGroup.type)] = statusGroup;

      return {
        ...state,
        statuses: [...statuses],
      };
    }
    case MENU_SETTINGS_RECEIVED: {
      const { menuSettings } = action;

      return {
        ...state,
        menuSettings,
      };
    }
    case SETTING_AREAS_RECEIVED: {
      const { settingAreas } = action;

      return {
        ...state,
        settingAreas,
        allAreaSettings: settingAreas
          .reduce((settings, area) => {
            return area.settings
              .reduce((settings, setting) => {
                settings[setting.key] = setting;

                return settings;
              }, settings);
          }, {}),
      };
    }
    default:
      return state;
  }
}
