export const AUTH_TOKEN = 'AUTH_TOKEN';

export const SET_LOCALE = 'SET_LOCALE';

export const USERS_REGISTER_REQUEST = 'USERS_REGISTER_REQUEST';
export const USERS_REGISTER_SUCCESS = 'USERS_REGISTER_SUCCESS';
export const USERS_REGISTER_FAILURE = 'USERS_REGISTER_FAILURE';

export const USERS_GETALL_REQUEST = 'USERS_GETALL_REQUEST';
export const USERS_GETALL_SUCCESS = 'USERS_GETALL_SUCCESS';
export const USERS_GETALL_FAILURE = 'USERS_GETALL_FAILURE';

export const USERS_DELETE_REQUEST = 'USERS_DELETE_REQUEST';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAILURE = 'USERS_DELETE_FAILURE';

export const EVENT_MENU_CATEGORY_ADDED = 'EVENT_MENU_CATEGORY_ADDED';
export const EVENT_MENU_CATEGORY_UPDATED = 'EVENT_MENU_CATEGORY_UPDATED';
export const EVENT_MENU_CATEGORY_DELETED = 'EVENT_MENU_CATEGORY_DELETED';
export const MENU_WAS_RESET = 'MENU_WAS_RESET';
export const BOOKING_MENU_COLUMNS_RECEIVED = 'BOOKING_MENU_COLUMNS_RECEIVED';
export const FETCH_BOOKING_MENU_COLUMNS_BEGIN = 'FETCH_BOOKING_MENU_COLUMNS_BEGIN';

export const FETCH_REVENUE_TYPES_SUCCESS = 'FETCH_REVENUE_TYPES_SUCCESS';
export const RECEIVE_COMPANY = 'RECEIVE_COMPANY';
export const RECEIVE_LOCATIONS = 'RECEIVE_LOCATIONS';
export const ADD_LOCATION = 'ADD_LOCATION';
export const RECEIVE_LOCATION = 'RECEIVE_LOCATION';
export const REQUEST_LOCATION = 'REQUEST_LOCATION';
export const EDIT_LOCATION_BEGIN = 'EDIT_LOCATION_BEGIN';
export const LOCATION_NOT_RECEIVED = 'LOCATION_NOT_RECEIVED';
export const LOCATIONS_REQUEST_BEGIN = 'LOCATIONS_REQUEST_BEGIN';
export const ITEMS_REQUEST_BEGIN = 'ITEMS_REQUEST_BEGIN';
export const ITEMS_REQUEST_END = 'ITEMS_REQUEST_END';
export const RECEIVE_ITEMS = 'RECEIVE_ITEMS';
export const LOCATION_SELECTED = 'LOCATION_SELECTED';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const RECEIVE_LEADS = 'RECEIVE_LEADS';
export const RECEIVE_MODIFIER_CATEGORIES = 'RECEIVE_MODIFIER_CATEGORIES';
export const RECEIVE_OPERATORS = 'RECEIVE_OPERATORS';
export const RECEIVE_SETS = 'RECEIVE_SETS';
export const ITEM_COLUMNS_RECEIVED = 'ITEM_COLUMNS_RECEIVED';

export const FETCH_LIBRARY_ITEM_IMAGE_BEGIN = 'FETCH_LIBRARY_ITEM_IMAGE_BEGIN';
export const FETCH_LIBRARY_ITEM_IMAGE_SUCCESS = 'FETCH_LIBRARY_ITEM_IMAGE_SUCCESS';
export const FETCH_LIBRARY_ITEM_IMAGE_FAILURE = 'FETCH_LIBRARY_ITEM_IMAGE_FAILURE';
export const FETCH_TAX_TYPES_BEGIN = 'FETCH_TAX_TYPES_BEGIN';
export const FETCH_ALL_TAX_TYPES_SUCCESS = 'FETCH_ALL_TAX_TYPES_SUCCESS';
export const FETCH_DEFAULT_TAX_TYPES_SUCCESS = 'FETCH_DEFAULT_TAX_TYPES_SUCCESS';
export const ITEM_TAGS_RECEIVED = 'ITEM_TAGS_RECEIVED';

export const SET_WIDGET_GRAPH_SETTINGS = 'SET_WIDGET_GRAPH_SETTINGS';
export const SET_WIDGET_GRAPH_TITLE = 'SET_WIDGET_GRAPH_TITLE';
export const SET_WIDGET_GRAPH_DATA_SET = 'SET_WIDGET_GRAPH_DATA_SET';
export const SET_WIDGET_GRAPH_TYPE = 'SET_WIDGET_GRAPH_TYPE';
export const SET_WIDGET_GRAPH_COLOR = 'SET_WIDGET_GRAPH_COLOR';
export const SET_WIDGET_GRAPH_COMPARISON = 'SET_WIDGET_GRAPH_COMPARISON';
export const SET_WIDGET_GRAPH_LABEL = 'SET_WIDGET_GRAPH_LABEL';

export const RECEIVE_CONTACTS = 'RECEIVE_CONTACTS';

export const MODIFIERS_REQUEST_BEGIN = 'MODIFIERS_REQUEST_BEGIN';
export const RECEIVE_MODIFIERS = 'RECEIVE_MODIFIERS';
export const MODIFIER_COLUMNS_RECEIVED = 'MODIFIER_COLUMNS_RECEIVED';

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';

export const FETCH_BOOKINGS_BEGIN = 'FETCH_BOOKINGS_BEGIN';
export const FETCH_BOOKING = 'FETCH_BOOKING';
export const FETCH_BOOKINGS = 'FETCH_BOOKINGS';
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_FAILURE = 'FETCH_BOOKINGS_FAILURE';
export const BOOKINGS_SELECTED = 'BOOKINGS_SELECTED';
export const DELETE_BOOKING = 'DELETE_BOOKING';

export const BOOKING_UPDATE = 'BOOKING_UPDATE';
export const BOOKING_CREATE = 'BOOKING_CREATE';
export const SET_FOCUSED_EVENT_INDEX = 'SET_FOCUSED_EVENT_INDEX';
export const UPDATE_FOCUSED_EVENT = 'UPDATE_FOCUSED_EVENT';
export const SET_NEXT_TEMP_ID = 'SET_NEXT_TEMP_ID';
export const STORE_BOOKING_SET_IS_EDITING = 'STORE_BOOKING_SET_IS_EDITING';
export const STORE_BOOKING_REVERT_CHANGES = 'STORE_BOOKING_REVERT_CHANGES';
export const STORE_BOOKING_SET_STORE_TYPE = 'STORE_BOOKING_SET_STORE_TYPE';
export const CLEAR_STORE_BOOKING = 'CLEAR_STORE_BOOKING';

export const BOOKING_EVENT_RECEIVED = 'BOOKING_EVENT_RECEIVED';
export const BOOKING_EVENT_DELETED = 'BOOKING_EVENT_DELETED';
export const BOOKING_EVENT_SAVED = 'BOOKING_EVENT_SAVED';
export const BOOKING_EVENT_CLONED = 'BOOKING_EVENT_CLONE';
export const BOOKING_RECEIVED = 'BOOKING_RECEIVED';
export const RECEIVE_BOOKING = 'RECEIVE_BOOKING';
export const FETCH_PROPOSALS_BEGIN = 'FETCH_PROPOSALS_BEGIN';
export const PROPOSALS_RECEIVED = 'PROPOSALS_RECEIVED';
export const RECEIVE_PROPOSAL_RELATIONSHIPS = 'RECEIVE_PROPOSAL_RELATIONSHIPS';
export const CLONE_BOOKING_PREFERENCES_RECEIVED = 'CLONE_BOOKING_PREFERENCES_RECEIVED';
export const BOOKING_ACTIVITIES_RECEIVED = 'BOOKING_ACTIVITIES_RECEIVED';
export const BOOKING_ACTIVITY_ADDED = 'BOOKING_ACTIVITY_ADDED';
export const BOOKING_ACTIVITY_UPDATED = 'BOOKING_ACTIVITY_UPDATED';
export const BOOKING_ACTIVITY_DELETED = 'BOOKING_ACTIVITY_DELETED';
export const BOOKING_ACTIVITY_COMPLETED = 'BOOKING_ACTIVITY_COMPLETED';
export const SET_SHIFT_TYPES = 'SET_SHIFT_TYPES';
export const FOCUSED_EVENT_ADD_SECTION = 'FOCUSED_EVENT_ADD_SECTION';
export const FOCUSED_EVENT_DELETE_SECTION = 'FOCUSED_EVENT_DELETE_SECTION';

export const EVENT_SHIFT_ADDED = 'EVENT_SHIFT_ADDED';
export const EVENT_SHIFT_UPDATED = 'EVENT_SHIFT_UPDATED';
export const EVENT_SHIFT_DELETED = 'EVENT_SHIFT_DELETED';
export const EVENT_MENU_ITEM_ADDED = 'EVENT_MENU_ITEM_ADDED';
export const EVENT_MENU_ITEM_DELETED = 'EVENT_MENU_ITEM_DELETED';

export const FETCH_CHITCHAT_MESSAGES_BEGIN = 'FETCH_CHITCHAT_MESSAGES_BEGIN';
export const FETCH_CHITCHAT_MESSAGES_SUCCESS = 'FETCH_CHITCHAT_MESSAGES_SUCCESS';
export const FETCH_CHITCHAT_MESSAGES_FAILURE = 'FETCH_CHITCHAT_MESSAGES_FAILURE';
export const CONFIRMED_BOOKINGS_TOGGLED = 'CONFIRMED_BOOKINGS_TOGGLED';
export const BOOKING_SAVED = 'BOOKING_SAVED';

export const SET_CURRENT_CONTACT = 'SET_CURRENT_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const RESET_CURRENT_CONTACT = 'RESET_CURRENT_CONTACT';
export const CLONE_CONTACT = 'CLONE_CONTACT';
export const CONTACTS_REQUEST_BEGIN = 'CONTACTS_REQUEST_BEGIN';
export const CONTACTS_SELECTED = 'CONTACTS_SELECTED';
export const FETCH_SALES_REPS_BEGIN = 'FETCH_SALES_REPS_BEGIN';
export const RECEIVE_SALES_REPS = 'RECEIVE_SALES_REPS';
export const CONTACT_NOTES_RECEIVED = 'CONTACT_NOTES_RECEIVED';
export const CONTACT_NOTE_ADDED = 'CONTACT_NOTE_ADDED';
export const CONTACT_NOTE_UPDATED = 'CONTACT_NOTE_UPDATED';
export const CONTACT_NOTE_DELETED = 'CONTACT_NOTE_DELETED';
export const RECEIVE_CONTACT_NAME_AND_IDS = 'RECEIVE_CONTACT_NAME_AND_IDS';
export const UPDATE_CURRENT_CONTACT = 'UPDATE_CURRENT_CONTACT';

export const RECEIVE_LEAD = 'RECEIVE_LEAD';
export const DELETE_LEAD = 'DELETE_LEAD';
export const RESET_CURRENT_LEAD = 'RESET_CURRENT_LEAD';
export const CLONE_LEAD = 'CLONE_LEAD';
export const LEADS_SELECTED = 'LEADS_SELECTED';
export const LEADS_REQUEST_BEGIN = 'LEADS_REQUEST_BEGIN';
export const LEAD_NOTES_RECEIVED = 'LEAD_NOTES_RECEIVED';
export const LEAD_NOTE_ADDED = 'LEAD_NOTE_ADDED';
export const LEAD_NOTE_UPDATED = 'LEAD_NOTE_UPDATED';
export const LEAD_NOTE_DELETED = 'LEAD_NOTE_DELETED';

export const CALENDAR_EVENTS_REQUEST_BEGIN = 'CALENDAR_EVENTS_REQUEST_BEGIN';
export const UPDATING_CALENDAR_EVENTS = 'UPDATING_CALENDAR_EVENTS';
export const RECEIVE_CALENDAR_EVENTS = 'RECEIVE_CALENDAR_EVENTS';
export const SALES_DATA_RECEIVED = 'SALES_DATA_RECEIVED';
export const EVENT_TYPE_FILTER_CLICK = 'EVENT_TYPE_FILTER_CLICK';
export const TASK_COMPLETED = 'TASK_COMPLETED';

export const CONTACT_COLUMNS_RECEIVED = 'CONTACT_COLUMNS_RECEIVED';
export const BOOKING_COLUMNS_RECEIVED = 'BOOKING_COLUMNS_RECEIVED';
export const LEAD_COLUMNS_RECEIVED = 'LEAD_COLUMNS_RECEIVED';
export const ACCOUNT_COLUMNS_RECEIVED = 'ACCOUNT_COLUMNS_RECEIVED';

export const RECEIVED_STATUSES = 'RECEIVED_STATUSES';
export const RECEIVED_SYSTEM_STATUSES = 'RECEIVED_SYSTEM_STATUSES';
export const SET_STATUS_GROUP = 'SET_STATUS_GROUP';

// QUICK_ORDER
export const FETCH_ORDERS_BEGIN = 'FETCH_ORDERS_BEGIN';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const RECEIVE_ORDERS_LIST = 'RECEIVE_ORDERS_LIST';
export const RECEIVE_ORDER = 'RECEIVE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const SET_ORDER = 'SET_ORDER';
export const RESET_CURRENT_ORDER = 'RESET_CURRENT_ORDER';
export const CLONE_ORDER = 'CLONE_ORDER';
export const ORDERS_REQUEST_BEGIN = 'ORDERS_REQUEST_BEGIN';
export const ORDERS_SELECTED = 'ORDERS_SELECTED';
export const ORDER_RECEIVED = 'ORDER_RECEIVED';
export const ORDER_COLUMNS_RECEIVED = 'ORDER_COLUMNS_RECEIVED';
export const SET_STORE_ORDER = 'SET_STORE_ORDER';
export const CLEAR_STORE_ORDER = 'CLEAR_STORE_ORDER';
export const SET_STORE_ORIGINAL_ORDER = 'SET_STORE_ORIGINAL_ORDER';
export const SET_STORE_MENU_CATEGORIES = 'SET_STORE_MENU_CATEGORIES';

export const ADD_STORE_ORDER_ITEM = 'ADD_STORE_ORDER_ITEM';
export const ADD_STORE_MANAGED_ORDER_ITEM = 'ADD_STORE_MANAGED_ORDER_ITEM';
export const DELETE_STORE_ORDER_ITEM = 'DELETE_STORE_ORDER_ITEM';
export const DELETE_STORE_MANAGED_ORDER_ITEM = 'DELETE_STORE_MANAGED_ORDER_ITEM';
export const REVERT_STORE_MANAGED_ORDER = 'REVERT_STORE_MANAGED_ORDER';
export const EDIT_STORE_ORDER_ITEM = 'EDIT_STORE_ORDER_ITEM';
export const ORDER_WIDGET_DATA_RECEIVED = 'ORDER_WIDGET_DATA_RECEIVED';

export const FETCH_ACCOUNTS_BEGIN = 'FETCH_ACCOUNTS_BEGIN';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const RESET_CURRENT_ACCOUNT = 'RESET_CURRENT_ACCOUNT';
export const CLONE_ACCOUNT = 'CLONE_ACCOUNT';
export const ACCOUNTS_SELECTED = 'ACCOUNTS_SELECTED';
export const ACCOUNT_RECEIVED = 'ACCOUNT_RECEIVED';
export const RECEIVE_ACCOUNT_NAME_AND_IDS = 'RECEIVE_ACCOUNT_NAME_AND_IDS';
export const ON_SCHEDULER_DAY_SELECTED = 'ON_SCHEDULER_DAY_SELECTED';

export const ROOM_RECEIVE = 'ROOM_RECEIVE';
export const PARENT_ROOMS_RECEIVED = 'PARENT_ROOMS_RECEIVED';
export const SETUP_STYLES_RECEIVED = 'SETUP_STYLES_RECEIVED';
export const UNAVAILABLE_ROOMS_RECEIVED = 'UNAVAILABLE_ROOMS_RECEIVED';

export const ADD_TO_RECENTS = 'ADD_TO_RECENTS';
export const REMOVE_RECENT = 'REMOVE_RECENT';
export const RESET_RECENTS = 'RESET_RECENTS';

export const PACKAGES_RECEIVED = 'PACKAGES_RECEIVED';
export const PACKAGE_APPLIED_TO_MENU = 'PACKAGE_APPLIED_TO_MENU';

export const SITES_RECEIVED = 'SITES_RECEIVED';

export const REFERENCES_RECEIVED = 'REFERENCES_RECEIVED';
export const BOOKING_CATEGORIES_RECEIVED = 'BOOKING_CATEGORIES_RECEIVED';
export const BOOKING_THEMES_RECEIVED = 'BOOKING_THEMES_RECEIVED';
export const BOOKING_EVENT_TYPES_RECEIVED = 'BOOKING_EVENT_TYPES_RECEIVED';

export const CURRENT_LOCATION_SET = 'CURRENT_LOCATION_SET';

export const SET_STORE_MENU = 'SET_STORE_MENU';
export const SET_STORE_MENU_INDEX = 'SET_STORE_MENU_INDEX';
export const UPDATE_STORE_MENU = 'UPDATE_STORE_MENU';
export const UPDATE_STORE_MENU_SELECTED_CATEGORY_ITEMS = 'UPDATE_STORE_MENU_SELECTED_CATEGORY_ITEMS';
export const UPDATE_STORE_ORDER = 'UPDATE_STORE_ORDER';

export const MENU_ITEMS_REQUEST_BEGIN = 'MENU_ITEMS_REQUEST_BEGIN';
export const MENU_ITEMS_REQUEST_END = 'MENU_ITEMS_REQUEST_END';
export const RECEIVE_MENU_ITEMS = 'RECEIVE_MENU_ITEMS';
export const MENU_ITEM_SELECTED = 'MENU_ITEM_SELECTED';
export const ALL_ITEMS_TOGGLED = 'ALL_ITEMS_TOGGLED';
export const UPDATE_MENU_ITEM_SUCCESS = 'UPDATE_MENU_ITEM_SUCCESS';
export const CREATE_MENU_ITEM_SUCCESS = 'CREATE_MENU_ITEM_SUCCESS';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';
export const CLEAR_SELECTED_MENU_ITEM = 'CLEAR_SELECTED_MENU_ITEM';
export const RECEIVE_TAGS = 'RECEIVE_TAGS';
export const TAGS_REQUEST_BEGIN = 'TAGS_REQUEST_BEGIN';
export const TAGS_REQUEST_END = 'TAGS_REQUEST_END';
export const SET_STORE_MENU_FILTER = 'SET_STORE_MENU_FILTER';

export const UPLOAD_FILE_BEGIN = 'UPLOAD_FILE_BEGIN';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const MENUS_RECEIVED = 'MENUS_RECEIVED';
export const MENU_SAVED = 'MENU_SAVED';
export const MENU_ADDED = 'MENU_ADDED';
export const MENU_DELETED = 'MENU_DELETED';
export const INIT_WORKING_MASTER_MENU = 'INIT_WORKING_MASTER_MENU';
export const INIT_WORKING_MASTER_MENU_CATEGORIES = 'INIT_WORKING_MASTER_MENU_CATEGORIES';
export const SET_WORKING_MASTER_MENU_ID = 'SET_WORKING_MASTER_MENU_ID';
export const UPDATE_WORKING_MASTER_MENU = 'UPDATE_WORKING_MASTER_MENU';
export const UPDATE_WORKING_MASTER_MENU_NAME = 'UPDATE_WORKING_MASTER_MENU_NAME';
export const WORKING_MENU_ADD_MENU_CATEGORY = 'WORKING_MENU_ADD_MENU_CATEGORY';
export const WORKING_MENU_DELETE_MENU_CATEGORY = 'WORKING_MENU_DELETE_MENU_CATEGORY';
export const WORKING_MENU_DELETE_MENU_CATEGORY_ITEM = 'WORKING_MENU_DELETE_MENU_CATEGORY_ITEM';
export const UPDATE_WORKING_MASTER_MENU_CATEGORIES = 'UPDATE_WORKING_MASTER_MENU_CATEGORIES';
export const WORKING_MENU_ADD_MENU_CATEGORY_ITEM = 'WORKING_MENU_ADD_MENU_CATEGORY_ITEM';
export const WORKING_MENU_UPDATE_MENU_CATEGORY_ITEMS = 'WORKING_MENU_UPDATE_MENU_CATEGORY_ITEMS';
export const RESET_WORKING_MASTER_MENU = 'RESET_WORKING_MASTER_MENU';
export const WORKING_MENU_COLLAPSE_ALL_CATEGORIES = 'WORKING_MENU_COLLAPSE_ALL_CATEGORIES';
export const WORKING_MENU_EXPAND_ALL_CATEGORIES = 'WORKING_MENU_EXPAND_ALL_CATEGORIES';
export const WORKING_MENU_TOGGLE_EXPAND_CATEGORY = 'WORKING_MENU_TOGGLE_EXPAND_CATEGORY';
export const WORKING_MENU_NEXT_PLACEHOLDER_ID = 'WORKING_MENU_NEXT_PLACEHOLDER_ID';
export const WORKING_MENU_API_REQUEST_BEGIN = 'WORKING_MENU_API_REQUEST_BEGIN';
export const MENU_ASSIGNMENTS_RECEIVED = 'MENU_ASSIGNMENTS_RECEIVED';
export const MENU_ASSIGNMENTS_UPDATE = 'MENU_ASSIGNMENTS_UPDATE';
export const MENU_ASSIGNMENTS_RESET = 'MENU_ASSIGNMENTS_RESET';
export const MENU_SETTINGS_RECEIVED = 'MENU_SETTINGS_RECEIVED';

export const SETTING_AREAS_RECEIVED = 'SETTING_AREAS_RECEIVED';

export const WORKING_ADDRESS_SET_INIT = 'WORKING_ADDRESS_SET_INIT';
export const WORKING_ADDRESS_SET_UPDATE = 'WORKING_ADDRESS_SET_UPDATE';
export const WORKING_ADDRESS_SET_ADD_ADDRESS = 'WORKING_ADDRESS_SET_ADD_ADDRESS';
export const WORKING_ADDRESS_SET_SELECT_ADDRESS = 'WORKING_ADDRESS_SET_SELECT_ADDRESS';
export const WORKING_ADDRESS_SET_RESET = 'WORKING_ADDRESS_SET_RESET';
// Modifiers
export const MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_BEGIN = 'MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_BEGIN';
export const MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_END = 'MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_END';
export const RECEIVE_MENU_ITEM_MODIFIER_TEMPLATES = 'RECEIVE_MENU_ITEM_MODIFIER_TEMPLATES';
export const UPDATE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS = 'UPDATE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS';
export const CREATE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS = 'CREATE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS';
export const DELETE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS = 'DELETE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS';

export const START_MODIFIER_TEMPLATE_STACK = 'START_MODIFIER_TEMPLATE_STACK';
export const PUSH_MODIFIER_TEMPLATE_TO_STACK = 'PUSH_MODIFIER_TEMPLATE_TO_STACK';
export const REMOVE_MODIFIER_TEMPLATES_FROM_STACK = 'REMOVE_MODIFIER_TEMPLATES_FROM_STACK';
export const SAVE_MODIFIER_TEMPLATE = 'SAVE_MODIFIER_TEMPLATE';
export const UPDATE_MODIFIER_TEMPLATE_IN_STACK = 'UPDATE_MODIFIER_TEMPLATE_IN_STACK';

export const EMAIL_TEMPLATES_REQUEST_BEGIN = 'EMAIL_TEMPLATES_REQUEST_BEGIN';
export const EMAIL_TEMPLATES_REQUEST_END = 'EMAIL_TEMPLATES_REQUEST_END';
export const RECEIVE_EMAIL_TEMPLATES = 'RECEIVE_EMAIL_TEMPLATES';
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS';
export const SAVE_EMAIL_TEMPLATE = 'SAVE_EMAIL_TEMPLATE';
export const RECEIVE_EMAIL_TEMPLATES_MERGE_TAGS = 'RECEIVE_EMAIL_TEMPLATES_MERGE_TAGS';
export const EMAIL_SENT_SUCCESSFULLY = 'EMAIL_SENT_SUCCESSFULLY';
//Payment
export const PAYMENT_ACTION = 'PAYMENT_ACTION';

export const PICKLISTS_RECEIVED = 'PICKLISTS_RECEIVED';
export const PICKLISTS_GROUPS_RECEIVED = 'PICKLISTS_GROUPS_RECEIVED';

//SEARCH
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const EMAIL_TRIGGERS_REQUEST_BEGIN = 'EMAIL_TRIGGERS_REQUEST_BEGIN';
export const EMAIL_TRIGGERS_REQUEST_END = 'EMAIL_TRIGGERS_REQUEST_END';
export const RECEIVE_EMAIL_TRIGGERS = 'RECEIVE_EMAIL_TRIGGERS';
export const DELETE_EMAIL_TRIGGER_SUCCESS = 'DELETE_EMAIL_TRIGGER_SUCCESS';
export const SAVE_EMAIL_TRIGGER = 'SAVE_EMAIL_TRIGGER';

export const EMAIL_ACCOUNTS_REQUEST_BEGIN = 'EMAIL_ACCOUNTS_REQUEST_BEGIN';
export const EMAIL_ACCOUNTS_REQUEST_END = 'EMAIL_ACCOUNTS_REQUEST_END';
export const RECEIVE_EMAIL_ACCOUNTS = 'RECEIVE_EMAIL_ACCOUNTS';
export const DELETE_EMAIL_ACCOUNT_SUCCESS = 'DELETE_EMAIL_ACCOUNT_SUCCESS';
export const SAVE_EMAIL_ACCOUNT = 'SAVE_EMAIL_ACCOUNT';

//Merchant account
export const MERCHANTACCOUNT_RECEIVED = 'MERCHANTACCOUNT_RECEIVED';

export const ORDER_DASHBOARD_DATA_RECEIVED = 'ORDER_DASHBOARD_DATA_RECEIVED';
export const MENU_ITEM_SALES_DATA_RECEIVED = 'MENU_ITEM_SALES_DATA_RECEIVED';
