import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CoverDatePicker from 'Components/CoverDatePicker';
import NewOrderContext from './NewOrderContext';
import Select from 'Components/Select';
import { MenuItem } from '@material-ui/core';
import OrderAddressManager from 'orders/wizard/OrderAddressManager';
import ContactSearchField from 'Components/ContactSearchField';
import moment from 'moment';
import { getLocations } from 'actions/location';
import { connect } from 'react-redux';
import QuickContactModal from 'contacts/QuickContactModal';
import {
  addContact,
  getContact,
  saveContact,
  currentContactReset,
  setCurrentContact,
} from 'actions/contact';
import {
  replaceStoreOrderAPI,
  updateStoreOrder,
  mapAddressToOrderDelivery,
} from 'actions/order';
import {
  initWorkingAddresses,
  clearWorkingAddresses,
  workingAddressesAddNew,
  workingAddressesDelete,
  workingAddressesOnChange,
  workingAddressesSelectAddress,
  workingAddressesOnPrimaryChange,
} from 'actions/address';

const styles = () => {
  return {
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      alignContent: 'stretch',
      paddingTop: '24px',
    },
    gridReversePadding: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    fieldsRowContainer: {
      display: 'flex',
      marginLeft: -16,
      marginRight: -16,
    },
    bottomSectionContainer: {
      display: 'flex',
      marginTop: 15,
      height: 425,
    },
    halfWidth: {
      width: '50%',
    },
    fullWidth: {
      width: '100%',
    },
    quarterWidth: {
      width: '25%',
    },
    orderNumber: {
      position: 'absolute',
      top: '33px',
      left: '12px',
      fontSize: '15px',
    },
  };
};

class GeneralDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
      isQuickContactOpened: false,
      isEditingAddresses: false,
      dateNeeded: new Date(),
    };
  }

  componentDidMount() {
    const { getLocations, setIsNextDisabled, currentContact } = this.props;
    const isCustomerEmpty = !currentContact; // check order in progress, in case user came back here from later step

    this.setState({ locations: getLocations() });
    setIsNextDisabled(isCustomerEmpty);
    if (isCustomerEmpty) {
      this.getContactAddresses();
    } else {
      this.pickContact(currentContact);
    }
  }

  getContactAddresses = () => {
    const { storeOrder } = this.props;

    if (storeOrder.contactId) {
      this.props.getContact(storeOrder.contactId).then(contact => {
        const primaryAddress = contact.contactAddresses.find(a => a.primary);
        const selectedAddressId = primaryAddress ? primaryAddress.id : null;

        this.pickContact(contact);
        if (selectedAddressId) this.props.workingAddressesSelectAddress(selectedAddressId);
      });
    }
  }

  getOrderTimes = () => {
    const { storeOrder } = this.props;
    let minTime = moment();
    const maxTime = moment({ hour: 21, minute: 0, seconds: 0 }); // Closing time is hardcoded to 9 PM until we pull real data
    const timesArray = [];

    if (moment(storeOrder.requestedDateTime).isSame(moment(), 'day')) { // If order date is today, add ASAP option and filter start time to current time +30
      timesArray.push({ label: 'ASAP', value: 'ASAP' });

      minTime.seconds(0);
      if (minTime.minutes() < 15)
        minTime.minutes(30);
      else if (minTime.minutes() < 30)
        minTime.minutes(45);
      else if (minTime.minutes() < 45)
        minTime.minutes(0);
      else {
        minTime.minutes(15);
        minTime.hour(minTime.hour() + 1);
      }
    }
    else { // if this is a future order, allow order placed for any time
      minTime = moment({ hour: 9, minute: 0, seconds: 0 }); // Opening time is hardcoded to 9 AM until we pull real data
    }

    while (minTime.isBefore(maxTime)) {
      timesArray.push({ label: minTime.format('LT'), value: minTime.format('LT') });
      minTime.add(15, 'minute');
    }

    return timesArray;
  }

  onFieldChange = fieldName => value => {
    const { storeOrder } = this.props;

    const updatedStoreOrder = {
      ...storeOrder,
      [fieldName]: value,
    };

    if (fieldName === 'requestedDateTime') {
      if (moment(value).isSame(moment(), 'day')) {
        updatedStoreOrder.asap = true;
      }
      else {
        updatedStoreOrder.asap = false;
        updatedStoreOrder.requestedDateTime = moment(moment(value).format("YYYY/MM/DD") + " " + (storeOrder.asap ? moment({ hour: 9, minute: 0, seconds: 0 }).format('LT') : moment(storeOrder.requestedDateTime).format('LT'))).format();
      }
    }

    if (fieldName === 'timeNeeded') {
      if (value !== 'ASAP') {
        updatedStoreOrder.requestedDateTime = moment(moment(storeOrder.requestedDateTime).format("YYYY/MM/DD") + " " + value).format();
        updatedStoreOrder.asap = false;
      }
      else {
        updatedStoreOrder.requestedDateTime = moment(moment(storeOrder.requestedDateTime).format("YYYY/MM/DD")).format();
        updatedStoreOrder.asap = true;
      }
    }

    this.props.updateStoreOrder(updatedStoreOrder);
  }

  handleOrderTypeChange = value => {
    const { storeOrder, workingAddresses, selectedAddressId, updateStoreOrder, mapAddressToOrderDelivery } = this.props;

    const updatedStoreOrder = {
      ...storeOrder,
      type: value,
    };

    if (value === 'Pickup') {
      updatedStoreOrder.orderDelivery = null;
    } else if (value === 'Delivery') {
      const selectedAddress = workingAddresses && workingAddresses.length
        ? workingAddresses.find(a => a.id === selectedAddressId) || workingAddresses.find(a => a.primary)
        : workingAddresses[0];

      if (selectedAddress) updatedStoreOrder.orderDelivery = mapAddressToOrderDelivery(selectedAddress);
    }

    updateStoreOrder(updatedStoreOrder);
  }

  handleAddressChange = (selectedAddressId, fieldName, value) => {
    this.props.workingAddressesOnChange(selectedAddressId, fieldName, value);
  }

  onNewAddress = () => {
    this.props.workingAddressesAddNew();
  }

  onDeleteAddress = selectedAddressId => {
    this.props.workingAddressesDelete(selectedAddressId);
  }

  onHandlePrimaryAddress = (addressId, isChecked) => {
    this.props.workingAddressesOnPrimaryChange(addressId, isChecked);
  }

  handleSelectAddress = selectedAddress => {
    const storeOrder = {
      ...this.props.storeOrder,
      orderDelivery: mapAddressToOrderDelivery(selectedAddress, this.props.storeOrder),
    };

    this.props.workingAddressesSelectAddress(selectedAddress.id);
    this.props.updateStoreOrder(storeOrder);
  }

  pickContact = selectedContact => {
    const {
      storeOrder,
      updateStoreOrder,
      setIsNextDisabled,
      currentContactReset,
      initWorkingAddresses,
      workingAddressesSelectAddress,
      clearWorkingAddresses,
      setCurrentContact,
      workingAddresses,
      selectedAddressId,
      currentLocation,
    } = this.props;

    if (!selectedContact) { // clear contact
      const updatedStoreOrder = {
        ...storeOrder, // state for this page is managed in Wizard.js
        contactId: null,
        orderDelivery: {
          orderId: storeOrder.id,
        },
      };

      if (storeOrder.orderDelivery && storeOrder.orderDelivery.id) updatedStoreOrder.orderDelivery.id = storeOrder.orderDelivery.id;

      currentContactReset();
      clearWorkingAddresses();
      updateStoreOrder(updatedStoreOrder);
      setIsNextDisabled(true);

      return;
    }

    if (!selectedContact.__isNew__) {
      let selectedAddress = null;

      if (this.isThereANewAddress() === true) {
        const newAddresses = workingAddresses.filter(a => a.id < 0);

        selectedAddress = workingAddresses.find(a => a.id === selectedAddressId);

        selectedContact.contactAddresses = [
          ...selectedContact.contactAddresses,
          ...newAddresses,
        ];
      } else {
        selectedAddress = this.getSelectedAddressOrPrimary(selectedContact);
      }

      const updatedStoreOrder = {
        ...storeOrder,
        contactId: selectedContact.id,
        customerName: selectedContact.name,
        customerPhone: selectedContact.phone,
        customerEmail: selectedContact.email,
        orderDelivery: mapAddressToOrderDelivery(selectedAddress, storeOrder),
        locationId: currentLocation.id,
      };

      setCurrentContact(selectedContact);
      initWorkingAddresses(selectedContact.contactAddresses).then(() => {
        if (storeOrder.orderDelivery && storeOrder.orderDelivery.contactAddressId) {
          workingAddressesSelectAddress(storeOrder.orderDelivery.contactAddressId);
        } else {
          if (selectedAddress && selectedAddress.id) workingAddressesSelectAddress(selectedAddress.id);
        }
      });

      updateStoreOrder(updatedStoreOrder);
      setIsNextDisabled(false);
    }
  }

  getSelectedAddressOrPrimary = selectedContact => {
    const { storeOrder: { orderDelivery } } = this.props;
    let address = null;

    if (selectedContact && selectedContact.contactAddresses && selectedContact.contactAddresses.length > 0) {
      if (orderDelivery && orderDelivery.contactAddressId && selectedContact.id) {
        address = selectedContact.contactAddresses.find(a => {
          return a.id === orderDelivery.contactAddressId;
        });
      }

      if (!address) {
        address = selectedContact.contactAddresses.find(a => a.primary) || selectedContact.contactAddresses[0];
      }
    }

    return address;
  }

  // Checks if user started entering an address that they would like to add to an existing Contact
  isThereANewAddress = () => {
    const { workingAddresses } = this.props;

    if (!workingAddresses || workingAddresses.some(address => !!address.contactId)) return false; // don't count existing contact addresses

    return workingAddresses.some(address => Object.entries(address).some(kvp => {
      return kvp[0] !== 'id' && !this.isNullOrWhitespace(kvp[1]); // ignore the id placeholder, but check if anything else has entries.
    }));
  }

  isNullOrWhitespace = value => {
    if (typeof value === 'undefined' || value == null) return true;
    const string = value.toString();

    return string.replace(/\s/g, '').length < 1;
  }

  onQuickAddContact = (fieldName, payload) => {
    const newContact = {};
    const nameParts = payload && payload.split(' ');

    if (nameParts.length === 1) {
      newContact.firstname = payload;
    } else if (nameParts.length === 2) {
      newContact.firstname = nameParts[0] || '';
      newContact.lastname = nameParts[1] || '';
    }

    this.setState({
      isQuickContactOpened: true,
      newContact,
    });
  }

  closeQuickContactModal = () => {
    this.setState({
      isQuickContactOpened: false,
      newContact: {},
    });
  }

  quickContactSaved = newContact => {
    this.closeQuickContactModal();

    if (newContact) {
      this.pickContact(newContact);
    }
  }

  startEditAddress = () => {
    this.setState({
      isEditingAddresses: true,
    });
  }

  cancelEditAddress = () => { //TODO
    this.setState({
      isEditingAddresses: false,
    });
  }

  getOrderId = () => {
    const orderId = this.props.storeOrder.id;

    return orderId ? orderId : '';
  }

  getTime = date => {
    return moment(date).format('LT').toString();
  }

  render() {
    const {
      classes,
      storeOrder,
      contact,
      workingAddresses,
      selectedAddressId,
      currentContact,
    } = this.props;
    const {
      locations,
      isQuickContactOpened,
      newContact,
      isEditingAddresses,
    } = this.state;

    const orderTimes = this.getOrderTimes();

    return (
      <>
        <div className={classes.orderNumber}>Order: {this.getOrderId()}</div>
        <div className={classes.gridReversePadding}>
          <div className={classes.fieldsRowContainer}>
            <ContactSearchField
              className={classes.fullWidth}
              label="Customer"
              value={currentContact}
              autoLoadOptions={true}
              fieldName="firstname"
              searchOperator="contains"
              resultCount={5}
              placeholder="Search customer..."
              onSelectContact={this.pickContact}
              onCreateOption={this.onQuickAddContact}
            />
          </div>
          <div className={classes.fieldsRowContainer}>
            <CoverDatePicker
              className={classes.quarterWidth}
              label="Date"
              fieldName="requestedDateTime"
              value={storeOrder.requestedDateTime}
              onFieldChange={this.onFieldChange('requestedDateTime')}
            />
            <Select
              className={classes.quarterWidth}
              label="Time Needed"
              fieldName="timeNeeded"
              value={storeOrder.asap ? 'ASAP' : this.getTime(storeOrder.requestedDateTime)}
              onFieldChange={this.onFieldChange('timeNeeded')}
            >
              {orderTimes.map(time => <MenuItem value={time.value} key={time.value}>{time.label}</MenuItem>)}
            </Select>
            <Select
              className={classes.quarterWidth}
              label="Type"
              fieldName="type"
              value={storeOrder.type}
              onFieldChange={this.handleOrderTypeChange}
            >
              <MenuItem value={"Delivery"}>Delivery</MenuItem>
              <MenuItem value={"Pickup"}>Pickup</MenuItem>
            </Select>
            <Select
              className={classes.quarterWidth}
              label="Location"
              fieldName="locationId"
              value={storeOrder.locationId}
              onFieldChange={this.onFieldChange('locationId')}
            >
              {locations.map(location =>
                <MenuItem value={location.id}>{location.name}</MenuItem>
              )}
            </Select>
          </div>
          <div className={classes.bottomSectionContainer} onDoubleClick={this.startEditAddress}>
            <OrderAddressManager
              order={storeOrder}
              contactId={contact && contact.id}
              contactAddresses={workingAddresses}
              isEditing={isEditingAddresses}
              selectedAddressId={selectedAddressId}
              onAddressChange={this.handleAddressChange}
              onHandlePrimaryAddress={this.onHandlePrimaryAddress}
              onNewAddress={this.onNewAddress}
              onDeleteAddress={this.onDeleteAddress}
              onSelectLocation={this.handleSelectAddress}
              onSelectAddress={this.handleSelectAddress}
            />
          </div>
          <QuickContactModal
            isOpened={isQuickContactOpened}
            contact={newContact}
            onCancel={this.closeQuickContactModal}
            onSave={this.quickContactSaved}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      currentLocation,
    },
    storeReducer: {
      storeOrder,
    },
    contact: {
      currentContact,
    },
    address: {
      workingAddresses,
      selectedAddressId,
    },
  } = state;

  return {
    currentLocation,
    storeOrder,
    currentContact,
    workingAddresses,
    selectedAddressId,
  };
};

const mapDispatchToProps = {
  saveContact,
  addContact,
  getContact,
  setCurrentContact,
  currentContactReset,
  getLocations,
  replaceStoreOrderAPI,
  updateStoreOrder,
  initWorkingAddresses,
  clearWorkingAddresses,
  workingAddressesAddNew,
  workingAddressesDelete,
  workingAddressesOnChange,
  workingAddressesSelectAddress,
  workingAddressesOnPrimaryChange,
};

const GeneralDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GeneralDetails));

const GeneralDetailsWithContext = props => {
  const {
    orderChanged,
    order,
    setIsNextDisabled,
  } = useContext(NewOrderContext);

  return (<GeneralDetailsContainer
    {...props}
    order={order}
    orderChanged={orderChanged}
    setIsNextDisabled={setIsNextDisabled}
  />);
};

export default GeneralDetailsWithContext;
