import {
  MENU_ITEMS_REQUEST_BEGIN,
  RECEIVE_MENU_ITEMS,
  MENU_ITEMS_REQUEST_END,
  MENU_ITEM_SELECTED,
  UPDATE_MENU_ITEM_SUCCESS,
  CREATE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_SUCCESS,
  CLEAR_SELECTED_MENU_ITEM,
  TAGS_REQUEST_BEGIN,
  TAGS_REQUEST_END,
  RECEIVE_TAGS,
  ALL_ITEMS_TOGGLED,
  MENU_ITEM_SALES_DATA_RECEIVED,
} from 'actions/constants';

import { ConvertArrayToDictionary } from '../../helpers/dictionary';
import { SelectProperty } from '../../helpers/select';

const initialState = {
  data: [],
  loading: false,
  tags: {
    data: [],
    tagsList: [],
    loading: false,
  },
  sales: {},
};

export function menuItem(state = initialState, action) {
  switch (action.type) {
    case MENU_ITEMS_REQUEST_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case MENU_ITEMS_REQUEST_END: {
      return {
        ...state,
        loading: false,
      };
    }
    case RECEIVE_MENU_ITEMS: {
      const { response, append } = action;

      const newData = append ? state.data.concat(response) : response;

      return {
        ...state,
        data: newData,
        indexedData: ConvertArrayToDictionary(newData, 'id'),
        loading: false,
      };
    }
    case ALL_ITEMS_TOGGLED: {
      const { selected } = action;

      var dataUpdate = state.data.map(item => ({ ...item, selected: selected }));

      return {
        ...state,
        data: dataUpdate,
        indexedData: ConvertArrayToDictionary(dataUpdate, 'id'),
      };
    }
    case MENU_ITEM_SELECTED: {
      const { indexedData } = state;
      const { id, index, selected } = action;

      const menuItem = indexedData[id];

      menuItem.selected = selected;

      const data = [...state.data];

      data[index] = menuItem;

      return {
        ...state,
        currentItemId: id,
        currentItem: indexedData[id],
        indexedData: {
          ...state.indexedData,
          [id]: menuItem,
        },
        data,
      };
    }
    case CLEAR_SELECTED_MENU_ITEM: {
      return {
        ...state,
        currentItemId: undefined,
        currentItem: {
          id: undefined,
          name: undefined,
        },
      };
    }
    case UPDATE_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        data: state.data.map(menuItem => menuItem.id === action.menuItem.id ? { ...menuItem, ...action.menuItem } : menuItem),
        indexedData: {
          ...state.indexedData,
          [action.menuItem.id]: action.menuItem,
        },
        currentItemId: undefined,
        currentItem: undefined,
      };
    }
    case CREATE_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        data: state.data.concat(action.menuItem).sort(idAscending),
        indexedData: {
          ...state.indexedData,
          [action.menuItem.id]: action.menuItem,
        },
        currentItemId: undefined,
        currentItem: undefined,
      };
    }
    case DELETE_MENU_ITEM_SUCCESS: {

      const newIndexedData = state.indexedData;

      delete newIndexedData[action.menuItemId];

      return {
        ...state,
        data: state.data.filter(menuItem => menuItem.id !== action.menuItemId),
        indexedData: newIndexedData,
        currentItemId: undefined,
        currentItem: undefined,
      };
    }
    case TAGS_REQUEST_BEGIN: {
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: true,
        },
      };
    }
    case TAGS_REQUEST_END: {
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: false,
        },
      };
    }
    case RECEIVE_TAGS: {

      return {
        ...state,
        tags: {
          data: action.response,
          tagsList: SelectProperty(action.response, 'name'),
          loading: false,
        },
      };
    }
    case MENU_ITEM_SALES_DATA_RECEIVED: {

      return {
        ...state,
        sales: action.response,
      };
    }
    default:
      return state;
  }
}

const idAscending = (entity1, entity2) => {
  if (entity1.id > entity2.id) {
    return -1;
  }
  if (entity1.id < entity2.id) {
    return 1;
  }

  return 0;
};
