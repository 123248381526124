import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from 'Components/TextField';
import Select from 'Components/Select';
import SimpleDialog from 'Components/SimpleDialog';
import SaveBar from 'Components/SaveBar';
import { connect } from 'react-redux';
import {
  MenuItem,
} from '@material-ui/core';
import {
  saveEmailTemplate,
  createEmailTemplate,
  getEmailTemplate,
  deleteEmailTemplate,
  getEmailTemplateMergeTags,
} from 'actions/emailTemplates';
import { EMAIL_TYPES } from 'constants/entityTypes';
import EmailEditor from 'react-email-editor';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  fields: {
    display: 'flex',
  },
  emailEditor: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
  },
  smallerIcon: {
    fontSize: 14,
  },
  biggerButton: {
    height: 23,
    width: 23,
  },
});

class EmailTemplateEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {},
    };
    this.emailEditor = React.createRef();
    this.dialog = React.createRef();
    this.types = Object.values(EMAIL_TYPES);
  }

  componentDidMount() {
    const { match } = this.props;

    if (match.params.newTemplateType) {
      this.createNewTemplate(match.params.newTemplateType);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.getTemplate();
    }

    if (prevProps.mergeTags !== this.props.mergeTags) {
      this.emailEditor.current.setMergeTags(this.props.mergeTags);
    }
  }

  getTemplate = () => {
    this.props.getEmailTemplate(this.props.match.params.id)
      .then(template => {
        this.onTemplateLoad(template);
      });
  }

  createNewTemplate = templateType => {
    this.props.createEmailTemplate({ type: templateType })
      .then(template => this.props.history.push(`/admin/email-templates/${template.id}`));
  }

  handleFieldChange = fieldName => value => {
    const template = {
      ...this.state.template,
      [fieldName]: value,
    };

    this.setState({ template });
    if (fieldName === 'type') this.props.getEmailTemplateMergeTags(value);
  }

  onEditorLoad = () => {
    this.getTemplate();
  };

  onTemplateLoad = template => {
    this.setState({ template });

    this.props.getEmailTemplateMergeTags(template.type);
    if (template && template.jsonContent) {
      const json = JSON.parse(template.jsonContent);

      this.emailEditor.current.editor.loadDesign(json);
    }
  };

  saveTemplate = () => {
    const { template } = this.state;

    this.emailEditor.current.exportHtml(data => {
      const { design, html } = data;

      template.jsonContent = JSON.stringify(design);
      template.htmlContent = html;
      this.props.saveEmailTemplate(template).then(() => {
        this.props.history.push('/admin/email');
      });
    });
  };

  cancel = () => {
    const { template } = this.state;

    this.dialog.current.open('Are you sure you want to lose your changes?').then(() => {
      if (!template.htmlContent && !template.jsonContent && !template.name && !template.subject) {
        // Nothing to save. User is abandoning an empty draft, so just delete it.
        this.props.deleteEmailTemplate(template);
      }
      this.props.history.push('/admin/email');
    });
  }

  render() {
    const {
      classes,
      match,
    } = this.props;

    const {
      template,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.fields}>
          <TextField
            label="Name"
            value={template.name}
            onFieldChange={this.handleFieldChange('name')}
          />
          <TextField
            label="Subject"
            value={template.subject}
            onFieldChange={this.handleFieldChange('subject')}
          />
          <Select
            label="Type"
            value={template.type}
            onFieldChange={this.handleFieldChange('type')}
            name="type"
          >
            {this.types.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
          </Select>
        </div>
        <div className={classes.emailEditor}>
          {match.params.id &&
            <>
              <EmailEditor
                ref={this.emailEditor}
                onLoad={this.onEditorLoad}
              />
              <SaveBar onSave={this.saveTemplate} onCancel={this.cancel} />
            </>
          }
        </div>
        <SimpleDialog innerRef={this.dialog} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    emailTemplates: {
      mergeTags,
    },
  } = state;

  return {
    mergeTags,
  };
};
const mapDispatchToProps = {
  getEmailTemplateMergeTags,
  createEmailTemplate,
  saveEmailTemplate,
  getEmailTemplate,
  deleteEmailTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(EmailTemplateEditor)));
