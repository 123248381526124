import React, { Component } from 'react';
import { provideIntlService } from '@progress/kendo-react-intl';
import {
  Popover,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { inputProps } from 'Components/currencyInput';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};
const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};
const styles = {
  paper: {
    height: 36,
    alignItems: 'center',
    display: 'flex',
  },
  td: {
    cursor: 'initial',
  },
};

export default function popoverCellEdit(isEditing, onUpdate, width) {

  const inputStyle = { width };

  class PopoverCellEdit extends Component {

    constructor(props) {
      super(props);
      this.state = {
        isPopoverOpen: false,
      };
      this.isCurrency = props.format ? true : false;
    }

    openPopover = event => {
      event.stopPropagation();
      this.setState({ isPopoverOpen: true });
    }

    onKeyPressed = event => {
      if (event.key === 'Enter') {
        this.updateItem(event.target.value);
      }
      if (event.key === 'Escape') {
        this.setState({ isPopoverOpen: false });
      }
    }

    updateItem = value => {
      const {
        dataItem,
        field, //field should be set on the kendo's GridColumn for this to work
      } = this.props;

      if (value) {
        if (this.isCurrency) {
          value = parseFloat(value.replace(/[$,]/g,"")); //remove commas and dollar signs
        } else {
          value = parseFloat(value.replace(/[,]/g,"")); //remove commas and dollar signs
        }
      }

      dataItem[field] = value;
      this.setState({ isPopoverOpen: false });
      onUpdate(dataItem);
    }

    onBlur = event => {
      if (this.state.isPopoverOpen) {
        this.updateItem(event.target.value);
      }
    }

    // We handle closing the popover manually, so disabling this. It was contributing to a UI flicker.
    handlePopoverCloseEvent = event => {
      event.preventDefault();
      event.stopPropagation();
    }

    render() {
      const {
        classes: { paper, td },
        format,
        dataItem,
        field, //field should be set on the kendo's GridColumn for this to work
        theme,
      } = this.props;
      const {
        isPopoverOpen,
      } = this.state;

      const value = provideIntlService(this).formatNumber(dataItem[field], format);

      return (
        <td
          className={td}
          ref={node => { this.anchorEl = node; }}
          onClick={isEditing ? this.openPopover : () => {}}>
          <div>{value}</div>
          <Popover
            classes={{ paper }}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            anchorEl={this.anchorEl}
            style={{ zIndex: theme.zIndex.modal + 1 }}
            disableRestoreFocus={true}
            onClose={this.handlePopoverCloseEvent}
            open={isPopoverOpen}>
            <TextField
              style={inputStyle}
              autoFocus={true}
              defaultValue={value}
              onKeyDown={this.onKeyPressed}
              onBlur={this.onBlur}
              InputProps={this.isCurrency ? inputProps : null}
            />
          </Popover>
        </td>
      );
    }
  };

  return withStyles(styles, { withTheme: true })(PopoverCellEdit);
}
