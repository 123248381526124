
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Avatar,
} from '@material-ui/core';
import classNames from 'classnames';
import { registerForIntl, provideIntlService } from '@progress/kendo-react-intl';
import CustomerNumber from './CustomerNumber';
import CustomerChart from 'Components/GraphWidgets/CustomerChart';
import Table from 'Components/Table';
import FilterBox from '../FilterBox';

const customerTypes = [
  {
    id: 1,
    name: 'All',
    amount: 398,
  },
  {
    id: 2,
    name: 'Corporate',
    amount: 152,
  },
  {
    id: 3,
    name: 'Government',
    amount: 54,
  },
  {
    id: 4,
    name: 'Religious',
    amount: 124,
  },
  {
    id: 5,
    name: 'Education',
    amount: 9,
  },
  {
    id: 6,
    name: 'Tour & Travel',
    amount: 3,
  },
  {
    id: 7,
    name: 'Social',
    amount: 28,
  },
  {
    id: 8,
    name: 'Military',
    amount: 17,
  },
  {
    id: 9,
    name: 'Repeat Client',
    amount: 14,
  },
  {
    id: 10,
    name: 'Chamber Member',
    amount: 27,
  },
  {
    id: 11,
    name: 'Association Member',
    amount: 40,
  },
  {
    id: 12,
    name: 'VIP',
    amount: 30,
  },
  {
    id: 13,
    name: 'Tax Exempt',
    amount: 12,
  },
];

const styles = theme => {

  return {
    viewPort: {
      flexGrow: 1,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      display: 'flex',
      padding: '24px 24px 0',
    },
    displayFlex: {
      display: 'flex',
    },
    leftContainer: {
      flexGrow: 1,
      paddingRight: '1%',
      [theme.breakpoints.up('lg')]: {
        paddingRight: 30,
      },
    },
    rightContainer: {
      minWidth: 165,
      maxWidth: 275,
    },
    bottomContainer: {
      display: 'flex',
      flexGrow: 1,
      paddingTop: 30,
      marginBottom: 30,
    },
    cardHeader: {
      fontSize: 18,
      padding: '23px 30px',
    },
    bottomLeftContainer: {
      paddingTop: 24,
      paddingBottom: 24,
      display: 'flex',
      flexDirection: 'row',
    },
    bottomLeftCard: {
      marginRight: 30,
    },
    flexGrow: {
      flexGrow: 1,
    },
    avatar: {
      height: 38,
      width: 38,
      fontSize: 16,
    },
    currency: {
      textAlign: 'right',
    },
    customerNameCell: {
      width: '100%',
      paddingLeft: 30,
      paddingRight: 15,
    },
    fullWidth: {
      width: '100%',
    },
    eventType: {
      color: theme.palette.grey[200],
    },
    avatarCell: {
      paddingLeft: 30,
      paddingRight: 14,
    },
    amountCell: {
      paddingLeft: 14,
      paddingRight: 30,
    },
    mostValuableCustomersTable: {
      fontSize: 16,
      color: theme.palette.grey[800],
      '& tr': {
        height: 70,
      },
    },
    latestBookings: {
      fontSize: 14,
      color: theme.palette.grey[800],
      '& tr': {
        height: 40,
      },
      '& td': {
        paddingRight: 30,
      },
    },
    dateColumn: {
      color: theme.palette.grey.A400,
      paddingRight: '50px !important',
    },
    dot: {
      padding: '0',
      margin: '0',
      width: '9px',
      height: '9px',
      borderRadius: '4.5px',
      display: 'inline-block',
    },
  };
};

const mostValuableCustomers = [
  {
    name: 'Anne Block',
    avatar: 'AB',
    id: 1,
    amount: 5000,
  },
  {
    name: 'Domenick Hirthe',
    avatar: 'DH',
    id: 2,
    amount: 4000,
  },
  {
    name: '	Jaime Haley',
    avatar: 'JH',
    id: 3,
    amount: 3000,
  },
];

const latestBookings = [
  {
    id: 1,
    name: 'King Pin Wilson',
    date: '1/20/2021',
    eventType: 'B-Day',
    amount: 400,
  },
  {
    id: 2,
    name: 'Killer Croc',
    date: '1/20/2021',
    eventType: 'Wedding',
    amount: 4641.99,
  },
  {
    id: 3,
    name: 'Jack Russo',
    date: '1/19/2021',
    eventType: 'Delivery',
    amount: 10.95,
  },
  {
    id: 4,
    name: 'Caterease Software',
    date: '1/28/2021',
    eventType: 'Luncheon',
    amount: 1250,
  },
];

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const seriesData = [1062,875,950,1125,1062,812,875,725,900,937,850,1025];

class CustomerDashboard extends Component {
  constructor(props) {
    super(props);
    this.formatter = provideIntlService(this);
    this.state = {
      filterId: 1,
      customerCount: 395,
    };
  }

  handleChange = field => value => {
    this.setState({ [field]: value });
  }

  fakeColor = customer => {
    const zeroToTwo = customer.id % 3;
    const colors = this.props.theme.common;

    if (zeroToTwo === 1) {
      return colors.red;
    }
    if (zeroToTwo === 2) {
      return colors.green;
    }

    return colors.blue;
  }

  getDotColor = booking => {
    const zeroOrOne = booking.id % 2;
    const colors = this.props.theme.common;

    if (zeroOrOne === 1) {
      return colors.red;
    }

    return colors.green;
  }

  filterChanged = filter => {
    if (filter.amount) {
      this.setState({ customerCount: filter.amount });
    } else {
      this.setState({ filter });
    }
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      customerCount,
    } = this.state;
    const totalRevene = customerCount * 1214.96;
    const revenueChange = (((customerCount + 200) % 400) - 200) / 1000 ;//generate a number between -.20 and .20
    const changeAmount = (customerCount % 25) - 12;//generate a number between - (-12 and 12)
    const deposits = 2.32 * customerCount;
    const predictedRevenue = 16.44 * customerCount;

    const generateChart = [];

    for (let i = 0;i < 12;i++) {
      let index = (i + customerCount) % 12;

      generateChart.push(seriesData[index] * customerCount);
    }

    return (
      <div className={classes.viewPort}>
        <div className={classes.leftContainer}>
          <Paper>
            <div className={classes.cardHeader}>
              Customer Overview
            </div>
            <div className={classes.displayFlex}>
              <CustomerNumber
                isCurrency={true}
                value={totalRevene}
                changeNumber={revenueChange}
                changeIsPercentage={true}
                label={'Total Revenue'}
              />
              <CustomerNumber
                isCurrency={true}
                value={predictedRevenue}
                valueDecimals={2}
                changeNumber={revenueChange * -1}
                changeIsPercentage={true}
                label={'Predicted monthly Revenue'}
              />
              <CustomerNumber
                value={customerCount}
                changeNumber={changeAmount}
                label={'Active Customers'}
              />
              <CustomerNumber
                isCurrency={true}
                valueDecimals={2}
                value={deposits}
                label={'Customer Deposits'}
                hideRightBorder={true}
              />
            </div>
            <CustomerChart categories={months} data={generateChart} />
          </Paper>

          <div className={classes.bottomContainer}>
            <div className={classNames(classes.flexGrow, classes.bottomLeftCard)}>
              <Paper>
                <div className={classes.cardHeader}>
                  Most Profitable Customers
                </div>
                <Table className={classes.mostValuableCustomersTable}>
                  {mostValuableCustomers.map(customer => (
                    <tr key={customer.id}>
                      <td className={classes.avatarCell}>
                        <Avatar className={classes.avatar} style={{ backgroundColor: this.fakeColor(customer) }}>{customer.avatar}</Avatar>
                      </td>
                      <th scope="row" className={classes.fullWidth}>
                        {customer.name}
                      </th>
                      <td align="right" className={classNames(classes.currency, classes.amountCell)}>
                        {this.formatter.formatNumber(customer.amount, 'c2')}
                      </td>
                    </tr>
                  ))}
                </Table>
              </Paper>
            </div>
            <div className={classes.flexGrow}>
              <Paper>
                <div className={classes.cardHeader}>
                  Latest Bookings
                </div>
                <Table className={classes.latestBookings}>
                  {latestBookings.map(booking => (
                    <tr key={booking.id}>
                      <th scope="row" className={classNames(classes.customerNameCell)}>{booking.name}</th>
                      <td className={classes.dateColumn}>{booking.date}</td>
                      <td className={classes.eventType}>{booking.eventType}</td>
                      <td className={classes.currency} align="right">
                        <span>{this.formatter.formatNumber(booking.amount, 'c2')}&nbsp;</span>
                        <div className={classes.dot} style={{ backgroundColor: this.getDotColor(booking) }} />
                      </td>
                    </tr>
                  ))}
                </Table>
              </Paper>
            </div>
          </div>
        </div>
        <div className={classes.rightContainer}>
          <FilterBox
            customerTypes={customerTypes}
            onFilterChanged={this.filterChanged} />
        </div>
      </div>
    );
  }
}

registerForIntl(CustomerDashboard);

export default withStyles(styles, { withTheme: true })(CustomerDashboard);
