import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button }  from '@material-ui/core';

const styles = theme => ({
  root: {
    border: '1px solid',
    borderRadius: '4px',
    fontSize: '13px',
    minHeight: '32px',
    padding: '0 45px',
    textTransform: 'initial',
    backgroundColor: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.secondary.dark,
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[200],
  },
});

class PrimaryButton extends Component {
  render() {
    const { classes, children, isLoading, disabled, ...rest } = this.props;

    return (
      <Button {...rest} classes={{ root: classes.root, disabled: classes.disabled }} disabled={disabled || isLoading} >
        {children}
      </Button>
    );
  }
}

export default withStyles(styles)(PrimaryButton);
