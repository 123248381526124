import React, { Component } from 'react';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import StatusStepperStep from './StatusStepperStep';
import ConfirmationBanner from 'Components/ConfirmationBanner';
import { withStyles } from '@material-ui/core/styles';
import { getStatuses } from 'actions/settings';
import { getStatusHistory } from 'actions/statusHistory';
import moment from 'moment';

const styles = theme => ({
  StatusBar: {
    display: 'flex',
    flex: '1 0 auto',
    background: theme.palette.grey[50],
    height: 66,
    padding: '0 16px',
  },
  alternativeLabel: {
    alignItems: 'center',
  },
});

class StatusStepper extends Component {
  state = {
    activeStepIndex: 0,
    steps: [],
  };

  statusBanner = React.createRef();

  componentDidMount() {
    this.props.getStatuses();
  }

  componentDidUpdate(prevProps) {
    const { statuses, type, activeStatusId } = this.props;

    if (statuses && type && (type !== prevProps.type || statuses !== prevProps.statuses || activeStatusId !== prevProps.activeStatusId)) {
      this.renderActiveStatusUpdate();
    }
  }

  renderActiveStatusUpdate = () => {
    const { activeStatusId, statuses, type } = this.props;
    const statusGroup = statuses.find(statusGroup => statusGroup.type === type);

    if (statusGroup) {
      const steps = statusGroup.statuses;
      const activeStepIndex = steps && steps.findIndex(step => step.id === activeStatusId);

      this.setState({ steps, activeStepIndex });
    } else {
      console.error(`No Statuses found with type: ${type}`);
    }
  }

  handleStatusClick = stepIndex => {
    const { activeStepIndex } = this.props;
    const { steps } = this.state;
    let stepMessage = '';

    if (stepIndex === activeStepIndex) {
      stepMessage = `Mark ${steps[stepIndex].name} complete?`;
    } else if (stepIndex < activeStepIndex) {
      stepMessage = `Reset status to ${steps[stepIndex].name}?`;
    } else if (stepIndex > activeStepIndex) {
      stepMessage = `Set status to ${steps[stepIndex].name}?`;
    }

    this.setState({
      clickedStepIndex: stepIndex,
    });

    this.statusBanner.current.open(stepMessage);
  };

  handleBannerConfirm = () => {
    const { clickedStepIndex, steps } = this.state;
    const { finalStepIndex } = this.props;

    if (clickedStepIndex === finalStepIndex) {
      this.props.onCompleteFinalStep();
    } else {
      const statusId = steps[clickedStepIndex] && steps[clickedStepIndex].id;

      this.props.onBannerConfirm(statusId);
    }

    this.setState({
      clickedStepIndex: null,
    });
    this.statusBanner.current.close();
  };

  render() {
    const { classes, type, statusHistory } = this.props;
    const { steps, activeStepIndex } = this.state;

    const stepNames = steps && steps.map(step => step.name);

    const getIsActive = step => {
      return step === activeStepIndex;
    };

    const getIsComplete = stepId => {
      return statusHistory.some(status => status.statusId === stepId );
    };

    const isDisabled = step => {
      const isCanceledState = (activeStepIndex === 0 && step > activeStepIndex)
      || (step === 0 && activeStepIndex > 0);

      const isCompletedStep = steps[activeStepIndex] && steps[activeStepIndex].name === 'Completed';
      const isCompletedState = isCompletedStep && (activeStepIndex !== step);

      return isCanceledState || isCompletedState;
    };

    const getTooltip = stepId => {
      const existingStatus = statusHistory.find(status => status.statusId === stepId);

      if (existingStatus) {
        const setOnDateTime = existingStatus.lastModifiedUtcDateTime ? existingStatus.lastModifiedUtcDateTime : existingStatus.createdUtcDateTime;
        const setByName = existingStatus.createdByName ? existingStatus.createdByName : existingStatus.createdBy;

        return <div>
          {`Set on: ${moment(setOnDateTime).local().format('MMMM Do YYYY, h:mm:ss a')}`}<br/>
          {`Set by: ${setByName}`}
        </div>;
      }
    };

    const numOfSteps =  stepNames ? stepNames.length : 0;

    return (
      <>
        <Stepper
          activeStep={activeStepIndex}
          alternativeLabel={true}
          classes={{
            root: classes.StatusBar,
            alternativeLabel: classes.alternativeLabel,
          }}
        >
          {stepNames && stepNames.map((name, index) => {
            return (
              <StatusStepperStep
                index={index}
                key={`step-${index}`}
                tooltip={getTooltip(steps[index].id)}
                label={name}
                isActive={getIsActive(index)}
                isComplete={getIsComplete(steps[index].id)}
                isSkipped={index < activeStepIndex}
                isDisabled={isDisabled(index)}
                onClick={() => this.handleStatusClick(index)}
                type={type}
                numOfSteps={numOfSteps}
              />

            );
          })}
        </Stepper>
        <ConfirmationBanner
          innerRef={this.statusBanner}
          onConfirm={this.handleBannerConfirm}
        />
      </>
    );
  };
}

const mapStateToProps = state => {
  const {
    settings: {
      statuses,
    },
  } = state;

  return {
    statuses,
  };
};

const mapDispatchToProps = {
  getStatuses,
  getStatusHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusStepper));
