import React, { Component } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import Modal from 'Components/Modal';
import TextField from 'Components/TextField';
import SectionHeader from 'Components/SectionHeader';
import { connect } from 'react-redux';
import { CurrencyField } from 'Components/currencyInput';
import AddressSearch from 'Components/AddressSearch';
import AddressFields from 'Components/AddressFields';
import { handleAddressSearchDone } from 'shared/GoogleApi';

const styles = theme => {
  return {
    overflowIfTooBig: {
      overflow: 'auto',
      height: 555,
    },
    container: {
      display: 'flex',
      width: '100%',
      padding: '0 15px 12px',
    },
    flex: {
      display: 'flex',
    },
    sectionHeader: {
      marginTop: 16,
    },
    section: {
      display: 'flex',
      flex: '1 1 auto',
      padding: '0 16px',
    },
    halfWidth: {
      display: 'flex',
      width: '50%',
      flex: '1 1 auto',

    },
    fullWidth: {
      display: 'flex',
      width: '100%',
      flex: '1 1 auto',

    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
};

class CustomSiteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site: {},
    };
  }

  handleFieldChange = (fieldName, parser) => value => {
    const site = {
      ...this.state.site,
      [fieldName]: parser ? parser(value) : value,
    };

    this.setState({ site });
  }

  handleAddressFieldChange = fieldName => value => {
    const site = {
      ...this.state.site,
      siteAddress: {
        ...this.state.site.siteAddress,
        [fieldName]: value,
      },
    };

    this.setState({ site });
  }

  saveCustomSite = () => {
    const { site } = this.state;

    if (!!site.name) {
      this.props.onSaveCustomSite({ ...site.siteAddress, ...site, siteAddress: null });
    }
  }

  handleError = errorMessage => {
    this.errorDialog.current.open(errorMessage);
    this.setState({ isSaving: false });
  }

  onAddressSearchDone = selection => {
    handleAddressSearchDone(selection, this.handleAddressFieldChange); // Google Service will use local onFieldChange after parsing results
  }

  render() {
    const {
      classes,
      onClose,
      isOpened,
    } = this.props;
    const {
      site,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        onCancel={onClose}
        title="Add New Site"
        onSave={this.saveCustomSite}
        addTitleBottomBorder={true}
      >
        <Paper className={classes.overflowIfTooBig}>
          <SectionHeader className={classes.sectionHeader}>Site Details</SectionHeader>
          <div className={classes.section}>
            <TextField
              label="Site"
              value={site.name}
              onFieldChange={this.handleFieldChange('name')}
              className={classes.halfWidth}
            />
            <CurrencyField
              label="Price"
              fieldName="price"
              value={site.price}
              onFieldChange={this.handleFieldChange('price', parseFloat)}
              className={classes.halfWidth}
            />
          </div>

          <SectionHeader className={classes.sectionHeader}>Location</SectionHeader>
          <div className={classNames(classes.section, classes.flexColumn)}>
            <div className={classes.addressSearch}>
              <AddressSearch
                onSelection={this.onAddressSearchDone}
              />
            </div>
            <AddressFields
              address={site.siteAddress || {}}
              onFieldChange={this.handleFieldChange}
              isEditing={true}
            />
          </div>
        </Paper>
      </Modal>
    );
  }
}

const mapDispatchToProps = {

};

const mapStateToProps = () => {

  return {};
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CustomSiteModal));
