import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  item: {
    '&:empty': {
      display: 'none',//we get empty arrows for the breadcrumbs that don't render without this
    },
    '&:before': {
      content: '\'> \'',
    },
    '&:first-child:before': {
      content: '\'\'',
    },
    paddingRight: '5px',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.secondary.dark,
    },
  },
  breadcrumbContainer: {
    display: 'flex',
    height: '18px',
    letterSpacing: '1px',
    marginTop: '20px',
    marginBottom: '20px',
    minHeight: '18px',
    textTransform: 'uppercase',
  },
});

//simple component to just keep consistent piece of markup
const Breadcrumb = ({ children, classes }) => (
  <ol className={classes.breadcrumbContainer}>
    {children.map((child, index) =>
      <li key={`Breadcrumb-${index}`} className={classes.item}>{child}</li>
    )}
  </ol>
);

export default withStyles(styles)(Breadcrumb);
