import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Menu as HamburgerIcon,
  Clear as DeleteIcon,
  Edit as EditIcon,
  Done as DoneIcon,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  MenuItem,
  TextField,
} from '@material-ui/core';
import {
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import {
  Select,
} from '@material-ui/core';

const styles = theme => ({
  pickNameRow: {
    height: '35px',
    paddingLeft: 13,
    paddingRight: 13,
  },
  pickNameContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '150px',
  },
  crudIcons: {
    visibility: 'hidden',
  },
  modifyIcon: {
    marginRight: 7,
    marginLeft: 3,
    color: theme.palette.grey[700],
    fontSize: 26,
    cursor: 'pointer',
  },
  colorSquare: {
    height: 19,
    width: 19,
    borderRadius: 2,
    display: 'table-cell', //helps with verticlal alignment
  },
  colorContainer: {
    paddingLeft: 18,
  },
  dataRow: {
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
    '&:hover $crudIcons': {
      visibility: 'visible',
    },
  },
  inputFocused: {
    fontSize: 18,
    color: theme.palette.grey[700],
  },
  systemStatus: {
    width: '100%',
  },
});

var sortable = { cursor: 'row-resize' };
const DragHandle = SortableHandle(() => <HamburgerIcon style={sortable} />);

class QuickPickRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };

    this.textInputClasses = {
      classes: {
        focused: props.classes.inputFocused,
      },
    };

  }

  startEditing = () => {
    this.setState({ isEditing: true, editedItem: this.props.item });
  }

  editingDone = () => {
    const {
      onItemEdit,
      item,
    } = this.props;

    this.setState({ isEditing: false });

    if (this.state.editedItem === item) {
      return;
    }

    onItemEdit(this.state.editedItem);
  }

  fieldChanged = fieldName => event => {
    const { editedItem } = this.state;

    this.setState({
      editedItem: {
        ...editedItem,
        [fieldName]: event.target.value,
      },
    }
    );
  }

  onNameChange = event => {
    this.setState({ pickName: event.target.value });
  }

  enterPressed = event => {
    if (event.key !== 'Enter') {
      return;
    }
    this.editingDone();
  }

  isActiveChange = event => {
    const {
      onPickItemEdit,
      quickPick,
    } = this.props;

    const toggleCheck =
    {
      ...quickPick,
      isActive: event.target.checked,
    };

    onPickItemEdit(toggleCheck);
  }

  onDelete = () => {
    const {
      item,
    } = this.props;

    this.props.onItemDelete(item);
  }

  render() {
    const {
      item,
      classes,
      systemStatuses,
      type,
    } = this.props;

    const {
      isEditing,
      editedItem,
    } = this.state;

    const statuses = systemStatuses[type];

    return (
      <tr className={classes.dataRow}>
        {!isEditing && <td><DragHandle /></td>}
        <th scope="row" className={classes.pickNameRow} colSpan={isEditing ? 2 : 1}>
          {isEditing ?
            (
              <TextField
                autoFocus
                fullWidth
                onChange={this.fieldChanged("name")}
                name="name"
                value={editedItem.name}
                margin="none"
                InputProps={this.textInputClasses}
                onKeyPress={this.enterPressed}
              />)
            : (
              <div className={classes.pickNameContent}>
                <span>
                  {item.name}
                </span>
              </div>
            )}
        </th>
        <td className={classes.pickNameRow}>
          {isEditing ?
            <div className={classes.pickNameContent}>
              <Select
                className={classes.systemStatus}
                value={editedItem.systemStatusCode || ''}
                onChange={this.fieldChanged('systemStatusCode')}
              >
                <MenuItem key={`EmptyStatus`}></MenuItem>
                {statuses && statuses.map(status =>
                  <MenuItem key={status.code} value={status.code}>{status.displayName}</MenuItem>
                )}
              </Select><div >
                <DoneIcon onClick={this.editingDone} className={classes.modifyIcon} />
              </div>
            </div>
            :
            <div className={classes.pickNameContent}>
              <span>
                {item.systemStatusCode}
              </span>
              <div className={classes.crudIcons}>
                <EditIcon onClick={this.startEditing} className={classes.modifyIcon} />
                <DeleteIcon onClick={this.onDelete} className={classes.modifyIcon} />
              </div>
            </div>}
        </td>
      </tr >
    );
  }
}

QuickPickRow.propTypes = {
  onItemDelete: PropTypes.func.isRequired,
  onItemEdit: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    settings: {
      systemStatuses,
    },
  } = state;

  return {
    systemStatuses,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(SortableElement(QuickPickRow)));
