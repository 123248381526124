import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import TabContainer from 'Components/TabContainer';
import NotesPanel from 'Components/NotesPanel';
import ActivityPanel from 'Components/ActivityPanel';
import AccountContactsPanel from './AccountContactsPanel';
import BlueUnderlineTab from 'Components/Tabs/BlueUnderlineTab';
import FilesPanel from 'Components/FilesPanel';

const styles = theme => ({
  accountNotesTabs: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  tabsWrapper: {
    height: 40,
  },
});

const tabs = ['Activity', 'Notes', 'Contacts', 'Files'];

class AccountSideTabs extends Component {
  state = {
    tabIndex: 0,
  };

  handleChange = tabIndex => {
    this.setState({ tabIndex });
  };

  render() {
    const {
      classes,
      primaryContactUpdated,
    } = this.props;
    const { tabIndex } = this.state;

    return (
      <Paper className={classes.accountNotesTabs}>
        <div className={classes.tabsWrapper}>
          <BlueUnderlineTab
            onTabChange={this.handleChange}
            tabs={tabs}
            tabIndex={tabIndex} />
        </div>
        {tabIndex === 0 && <TabContainer><ActivityPanel /></TabContainer>}
        {tabIndex === 1 && <TabContainer><NotesPanel displayAddButton={true} /></TabContainer>}
        {tabIndex === 2 && <TabContainer><AccountContactsPanel primaryContactUpdated={primaryContactUpdated} /></TabContainer>}
        {tabIndex === 3 && <TabContainer><FilesPanel /></TabContainer>}
      </Paper>
    );
  }
}

export default withStyles(styles)(AccountSideTabs);
