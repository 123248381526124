import {
  EMAIL_ACCOUNTS_REQUEST_BEGIN,
  EMAIL_ACCOUNTS_REQUEST_END,
  RECEIVE_EMAIL_ACCOUNTS,
} from 'actions/constants';

const initialState = {
  data: [],
  isLoading: false,
};

export function emailAccounts(state = initialState, action) {
  switch (action.type) {
    case EMAIL_ACCOUNTS_REQUEST_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case EMAIL_ACCOUNTS_REQUEST_END: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case RECEIVE_EMAIL_ACCOUNTS: {
      const { response } = action;

      return {
        ...state,
        data: response,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
