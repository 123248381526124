import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { lighten } from '@material-ui/core/styles/colorManipulator';

//note can't use theme here
const styles = () => {
  return {
    container: { //container exists to set the width explicitly and move the actual event over 1 px.
      fontSize: 13,
      width: '100%',
      paddingLeft: 1,
    },
    borderItem: {
      display: 'flex',
      width: '100%',
      borderRadius: 4,
      alignItems: 'center',
      paddingLeft: 13,
      height: 21,
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    time: {
      minWidth: 55,
      textAlign: 'right',
      marginRight: 10,
    },
  };
};
//background-color: rgba(229,251,242,0.47);
//background-color: rgba(229,251,242,0.8);

class MonthEvent extends Component {

  getTimeText(momentStart) {
    const {
      militaryTime,
    } = this.props;

    return militaryTime ? momentStart.format('H:mm') : momentStart.format('h:mm a');
  }

  buildStyle = () => {
    const {
      color,
    } = this.props;

    //regular event
    return {
      border: '1px solid',
      color,
      borderColor: color,
      backgroundColor: lighten(color, 0.9), //0 is white
    };
  }

  render() {
    const {
      classes,
      event: {
        name,
        data: { syncFusionStart },
      },
    } = this.props;

    const momentStart = moment(syncFusionStart);
    const time = this.getTimeText(momentStart);
    const style = this.buildStyle();

    return (
      <div className={classes.container}>
        <div className={classes.borderItem} style={style}>
          <div className={classes.time}>
            {time}
          </div>
          <div className={classes.text}>
            {name}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MonthEvent);
