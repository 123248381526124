export function SelectProperty(array, propertyName) {
  const resultArray = [];

  if (!array) {
    return [];
  }

  for (let i = 0; i < array.length; i++) {
    resultArray.push(array[i][propertyName]);
  }

  return resultArray;
}
