import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid as KendoGrid } from '@progress/kendo-react-grid';
import DataLoader from 'Components/DataLoader';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  smallGrid: {
    height: 'inherit',
    '& .k-grid': {
      height: 'inherit',
      fontSize: 13,
      color: theme.palette.primary.main,
      '& .k-state-selected td': {
        backgroundColor: `${fade(theme.palette.secondary.dark,.05)}`, //seems to work without !important. Please comment if we need it
      },
      '& tr': {
        backgroundColor: theme.palette.background.paper, //fixes the .alt styling
        '&:hover': {
          backgroundColor: theme.palette.grey[50], //TODO: figure out what this should be
        },
      },
      '& .k-grid-header': {
        color: theme.palette.grey[500],
        '& .k-link': {
          color: theme.palette.grey[500],
          '&:hover': {
            cursor: 'default', //for SmallGrid, we disable links in the header, for now.
          },
        },
      },
      '& .k-grid-header-wrap': {
        borderRightWidth: 0,
      },
      '& thead th': {
        paddingLeft: 16,
        paddingRight: 16,
        borderWidth: '0 0 1px 0',
        borderColor: theme.palette.grey[100],
        backgroundColor: theme.palette.grey[50],
        '&::selection': {
          background: theme.palette.action.inputHighlight,
          color: theme.palette.grey[900],
        },
      },
      '& td': {
        padding: '6px 12px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: '1px !important',
        borderLeftWidth: 1,
        '&:first-child': {
          borderLeftWidth: 0,
          borderRightWidth: '1px !important',
        },
      },
      '& ::selection': {
        backgroundColor: theme.palette.action.inputHighlight + '!important',
        color: theme.palette.grey[900] + '!important',
      },
      '& .k-checkbox:checked+.k-checkbox-label::before': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
      '& .k-grid-norecords': {
        width: 300,
      },
    },
  },
});

class SmallGrid extends Component {
  render() {
    const {
      classes,
      data,
      children,
      isLoading,
      wrappedGrid, //You can send in a fully featured Grid if you just want the SmallGrid styling
    } = this.props;

    return (
      <div className={classes.smallGrid}>
        {wrappedGrid ? wrappedGrid :
        <>
          <KendoGrid {...this.props} data={data} resizable={true}>
            {children}
          </KendoGrid>
          <DataLoader isLoading={isLoading} inline={true} />
        </>
        }
      </div>
    );
  }
}
export default withStyles(styles)(SmallGrid);
