import React from 'react';
import ProgressBarWidget from './ProgressBarWidget';
import CircleGraphWidget from './CircleGraphWidget';
import { oneDimensionalWidgetGraphType } from 'helpers/enums';

class OneDimensionalGraphWidget extends React.Component {
  render() {
    const { denominator, differenceIndicator, numerator, onButtonClick, settings } = this.props;

    if (settings.graphType === oneDimensionalWidgetGraphType.progress) {
      return <ProgressBarWidget
        denominator={denominator}
        differenceIndicator={differenceIndicator}
        numerator={numerator}
        onButtonClick={onButtonClick}
        settings={settings}
      />;
    } else {
      return <CircleGraphWidget
        denominator={denominator}
        differenceIndicator={differenceIndicator}
        numerator={numerator}
        onButtonClick={onButtonClick}
        settings={settings}
      />;
    }
  }
}

export default OneDimensionalGraphWidget;
