import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import NotesPanel from 'Components/NotesPanel';
import SectionHeader from 'Components/SectionHeader';
import TextField from 'Components/TextField';
import RichTextField from 'Components/RichTextField';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { getNoteTypeKey } from 'constants/noteTypes';
import { connect } from 'react-redux';
import NoteService from 'actions/NoteService';
import SectionContext from 'Components/Contexts/SectionContext';

const styles = theme => ({
  addNoteContent: {
    padding: '10px 15px 20px 30px',
    display: 'flex',
  },
  addNoteContainer: {
    margin: 20,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
  },
  notesContainer: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  fullWidth: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    flexGrow: 0,
  },
  pad: {
    paddingTop: 16,
  },
  noteContentColumn1: {
    flexGrow: 1,
  },
  noteContentColumn2: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    justifyContent: 'space-between',
  },
});

const emptyNote = {
  noteType: 'internal',
  subject: '',
  body: '',
};

class NotesWizard extends Component {
  state = {
    newNote: emptyNote,
    errors: undefined,
    lastUpdate: new Date(),
  };

  constructor(props) {
    super(props);
    this.maxHeight = { maxHeight: props.maxHeight };
  }

  onFieldChange = fieldName => value => {
    const newNote = {
      ...this.state.newNote,
      [fieldName]: value,
    };

    this.setState({ newNote });
  }

  errorsExist = () => {
    const { newNote: note } = this.state;
    const subjectNotProvided = note.subject && note.subject.trim().length ? false : true;
    const errors = subjectNotProvided ? { subject: true } : undefined;

    this.setState({ errors });

    return errors ? true : false;
  }

  onUpdate = () => {
    if (this.errorsExist()) {
      return;
    }
    const { newNote: note } = this.state;

    if (note.id === undefined) {
      this.props.noteService.add(note)
        .then(() => {
          this.setState({
            newNote: emptyNote,
            lastUpdate: new Date(),
          });
        });
    } else {
      this.props.noteService.update(note)
        .then(() => {
          this.setState({
            newNote: emptyNote,
            lastUpdate: new Date(),
          });
        });
    }
  }

  onEditNoteClicked = note => {
    this.setState({ newNote: note });
  }

  render() {
    const { classes } = this.props;
    const {
      newNote,
      errors,
      lastUpdate,
    } = this.state;

    return (<>
      <SectionHeader>
        Notes
      </SectionHeader>
      <div className={classes.notesContainer} style={this.maxHeight}>
        <div className={classes.addNoteContainer}>
          <div className={classes.addNoteContent}>
            <div className={classes.noteContentColumn1}>
              <TextField
                label="Subject"
                value={newNote.subject}
                className={classes.fullWidth}
                onFieldChange={this.onFieldChange('subject')}
                error={errors && errors.subject}
              />
              <div className={classes.pad}>
                <RichTextField
                  value={newNote.body}
                  onFieldChange={this.onFieldChange('body')}
                  config={{
                    zIndex: 1301,
                  }}
                />
              </div>
            </div>
            <div className={classes.noteContentColumn2}>
              <Select
                label="Note Type"
                value={getNoteTypeKey(newNote.noteType)}
                className={classes.fullWidth}
                onFieldChange={this.onFieldChange('noteType')}
              >
                <MenuItem value="internal">Internal</MenuItem>
                <MenuItem value="general">General</MenuItem>
              </Select>
              <PrimaryButton onClick={this.onUpdate}>{newNote.id ? 'Save' : 'Add'}</PrimaryButton>
            </div>
          </div>
        </div>
        <NotesPanel
          lastUpdate={lastUpdate}
          onEditNote={this.onEditNoteClicked}
        />
      </div>
    </>);
  }
}

NotesWizard.propTypes = {
  maxHeight: PropTypes.number.isRequired,
};

NotesWizard.defaultProps = {
  maxHeight: 100,
};

export const NotesWizardWithNoContext = withStyles(styles)(NotesWizard);

//do connect here because I want the dispatch prop passed into this component
const NotesWizardWithContext = connect()(props => {
  const {
    relationship,
  } = useContext(SectionContext);

  const {
    dispatch,
    ...rest
  } = props;

  return (<NotesWizardWithNoContext
    {...rest}
    noteService={new NoteService(dispatch, relationship)}
  />);
});

export default NotesWizardWithContext;
