import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import SmallGrid from '../Components/Grid/SmallGrid';
import { buildGridCellModalLink } from 'Components/buildGridCellLink';
import { Clear as DeleteIcon } from '@material-ui/icons';
import moment from 'moment';

const styles = theme => ({
  venueGrid: {
    height: 'inherit',
  },
  iconButton: {
    color: theme.palette.grey[300],
  },
  deleteIcon: {
    '&:hover': {
      color: theme.common.softPink,
      cursor: 'pointer',
    },
  },
});

class VenueGrid extends Component {
  constructor(props) {
    super(props);
    if (!this.props.onEdit) {
      return;
    }
    this.roomCell = buildGridCellModalLink({
      onOpenModal: item => this.props.onEdit(item),
      text: item => item.name,
    });
  }

  /*TODO: API should support room setupStyle? */

  render() {
    const {
      classes,
      venues,
      isEditing,
      className,
    } = this.props;

    return (
      <div className={classes.venueGrid}>
        <SmallGrid
          className={className}
          data={venues ? venues.filter(venue => venue.recordStatus === 'Active') : []}
          editField="isEditing"
          style={{ overflow: 'hidden' }}
        >
          <Column field="name" title="Room" cell={this.roomCell} />
          <Column field="time" title="Time" cell={props => (
            <td>{moment(props.dataItem.startTime).format('LT')} - {moment(props.dataItem.endTime).format('LT')}</td>
          )}
          />
          <Column field="venueSetupStyle.name" title="Setup" />
          <Column field="room.type" title="Type" />
          <Column field="notes" title="Notes" />
          {isEditing && <Column
            field=""
            width="45px"
            cell={props => (
              <td style={{ display: 'flex', justifyContent: 'center' }}>
                <DeleteIcon className={classes.deleteIcon} />
              </td>
            )}
          />
          }
        </SmallGrid>
      </div>
    );
  }
}
export default withStyles(styles)(VenueGrid);
