import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import classNames from 'classnames';
import EventCard from 'bookings/EventCard';
import WizardContext from './WizardContext';
import QuickAddEventCard from 'bookings/QuickAddEventCard';
import CoverDatePicker from 'Components/CoverDatePicker';
import CoverTimePicker from 'Components/CoverTimePicker';
import TextField from 'Components/TextField';
import SectionHeader from 'Components/SectionHeader';
import { CurrencyField } from 'Components/currencyInput';
import SimpleDialog from 'Components/SimpleDialog';
import { Fade } from '@material-ui/core';
import DataLoader from 'Components/DataLoader';
import moment from 'moment';
import {
  updateStoreBooking,
  addStoreBookingEvent,
  setFocusedEventIndex,
  focusedEventUpdated,
  deleteStoreBookingEvent,
  cloneStoreBookingEvent,
  updateStoreFocusedEvent,
  toggleIncludeBookingEvent,
} from 'actions/booking';

const styles = {
  eventsPanel: {
    display: 'flex',
    overflowX: 'scroll',
    padding: '0 16px',
  },
  quarterWidth: {
    width: '25%',
  },
  cardWrapper: {
    margin: '16px 8px 0',
  },
  form: {
    padding: 16,
  },
  formGroupRow: {
    display: 'flex',
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
};

const dialog = React.createRef();

class WizardEvents extends Component {
  state = {};

  componentWillUnmount() {
    this.props.setNextIsDisabled(false);// we don't know if they're going backward or forwards
  }

  componentDidMount() {
    const { events, setNextIsDisabled } = this.props;

    if (events.length) {
      setNextIsDisabled(false);
    } else {
      setNextIsDisabled(true);
    }
  }

  handleFieldChange = (fieldName, parser) => value => {
    const { focusedEvent } = this.props;

    const updatedFocusedEvent = {
      ...focusedEvent,
      [fieldName]: parser ? parser(value) : value,
    };

    this.props.updateStoreFocusedEvent(updatedFocusedEvent);
  }

  onQuickAddEvent = () => {
    this.props.addStoreBookingEvent();
  }

  onSetIsIncludedInBookingTotal = eventIndex => {
    this.props.toggleIncludeBookingEvent(eventIndex);
  }

  handleDateChange = fieldName => value => {
    if (fieldName === 'startDate') {
      this.handleFieldChange('startDateTime')(moment(value).clone().format());
      this.handleFieldChange('endDateTime')(moment(value).clone().format());
    }
    if (fieldName === 'startTime') {
      this.handleFieldChange('startDateTime')(moment(value).clone().format());
    }
    if (fieldName === 'endTime') {
      // TODO: handle endTime past midnight (next day)
      this.handleFieldChange('endDateTime')(moment(value).clone().format());
    }
  }

  render() {
    const {
      classes,
      focusedEventIndex,
      focusedEvent,
      events,
      setFocusedEventIndex,
      deleteStoreBookingEvent,
      cloneStoreBookingEvent,
    } = this.props;

    const {
      isLoading,
    } = this.state;

    return (
      <div className={classes.wizardEvents}>
        <SectionHeader>Event</SectionHeader>
        <div className={classes.eventsPanel}>
          {events.filter(event => event.recordStatus === 'Active').map((event, index) => (
            <div className={classes.cardWrapper} key={`event-${index}`}>
              <EventCard
                event={event}
                isFocused={index === focusedEventIndex}
                onSelectEvent={() => setFocusedEventIndex(index)}
                onDelete={() => deleteStoreBookingEvent(index)}
                onSetIsIncludedInBookingTotal={() => this.onSetIsIncludedInBookingTotal(index)}
                onClone={() => cloneStoreBookingEvent(index)}
              />
            </div>
          ))}
          <div className={classes.cardWrapper}>
            <QuickAddEventCard className={classes.card} onClick={this.onQuickAddEvent} />
          </div>
        </div>
        <SectionHeader>Event Details</SectionHeader>
        <div className={classes.form}>
          {!focusedEvent && <div>Select an event above or create a new one!</div>}
          {focusedEvent &&
            <>
              <div className={classNames(classes.formGroupRow)}>
                <TextField
                  label="Name of Event"
                  fieldName="name"
                  value={focusedEvent.name}
                  className={classes.quarterWidth}
                  onFieldChange={this.handleFieldChange('name')}
                />
                <CoverDatePicker
                  name="datePicker"
                  label="Date"
                  value={focusedEvent.startDateTime}
                  className={classes.quarterWidth}
                  onFieldChange={this.handleDateChange('startDate')}
                />
                <CoverTimePicker
                  name="startDateTime"
                  label="Start Time"
                  value={focusedEvent.startDateTime}
                  onFieldChange={this.handleDateChange('startTime')}
                  className={classes.quarterWidth}
                />
                <CoverTimePicker
                  name="endDateTime"
                  label="End Time"
                  value={focusedEvent.endDateTime}
                  onFieldChange={this.handleDateChange('endTime')}
                  className={classes.quarterWidth}
                />
              </div>
              <div className={classNames(classes.formGroupRow, classes.bottomRow)}>
                <TextField
                  label="Minimum Count"
                  fieldName="minimumGuestCount"
                  value={focusedEvent.minimumGuestCount}
                  onFieldChange={this.handleFieldChange('minimumGuestCount', parseInt)}
                  type="number"
                  className={classes.quarterWidth}
                />
                <TextField
                  label="Estimated Count"
                  fieldName="estimatedGuestCount"
                  value={focusedEvent.estimatedGuestCount}
                  onFieldChange={this.handleFieldChange('estimatedGuestCount', parseInt)}
                  type="number"
                  className={classes.quarterWidth}
                />
                <CurrencyField
                  label="Minimum Spend"
                  fieldName="minimumSpend"
                  value={focusedEvent.minimumSpend}
                  onFieldChange={this.handleFieldChange('minimumSpend', parseFloat)}
                  className={classes.quarterWidth}
                />
                <CurrencyField
                  label="Estimated Spend"
                  fieldName="estimatedSpend"
                  value={focusedEvent.estimatedSpend}
                  onFieldChange={this.handleFieldChange('estimatedSpend', parseFloat)}
                  className={classes.quarterWidth}
                />
              </div>
            </>
          }
        </div>
        <SimpleDialog
          onlyOkayButton={true}
          innerRef={dialog}
        />
        {isLoading && <div className={classes.loader}>
          <Fade
            in={isLoading}
            style={{
              transitionDelay: isLoading ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <DataLoader />
          </Fade>
        </div>}
      </div>
    );
  }
}

//Container to move context into props
const WizardEventsContainer = props => {
  const {
    setNextIsDisabled,
    isProposal,
  } = useContext(WizardContext);

  return (
    <WizardEvents
      {...props}
      setNextIsDisabled={setNextIsDisabled}
      isProposal={isProposal}
    />
  );
};

const mapStateToProps = state => {
  const {
    booking: {
      wizard: {
        storeBooking,
        focusedEventIndex,
        focusedEvent,
      },
    },
  } = state;

  return {
    storeBooking,
    events: storeBooking.events,
    focusedEventIndex,
    focusedEvent,
  };
};

const mapDispatchToProps = {
  addStoreBookingEvent,
  updateStoreBooking,
  setFocusedEventIndex,
  focusedEventUpdated,
  deleteStoreBookingEvent,
  cloneStoreBookingEvent,
  updateStoreFocusedEvent,
  toggleIncludeBookingEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WizardEventsContainer));
