import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

const styles = theme => ({
  template: {
    width: 260,
    height: 300,
    margin: 20,
  },
  previewContainer: {
    height: 260,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    overflow: 'hidden',
  },
  previewContent: {
    transform: 'scale(.75)',
    transformOrigin: 'left top',
    width: '133%',
    height: '133%',
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
  },
  tools: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 60,
  },
  subject: {
    fontSize: 14,
    fontWeight: 500,
    width: 180,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  icon: {
    cursor: 'pointer',
  },
});

const EmailTemplatePreview = ({ classes, template, onEditTemplate, onDeleteTemplate }) => {
  return (
    <Card className={classes.template} key={template.id} >
      <div className={classes.previewContainer} onClick={() => onEditTemplate(template)}>
        <div
          className={classes.previewContent}
          dangerouslySetInnerHTML={{ __html: template.htmlContent }}>
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.subject}>
          {template.name}
        </div>
        <div className={classes.tools}>
          <EditIcon className={classes.icon} onClick={() => onEditTemplate(template)} />
          <DeleteIcon className={classes.icon} onClick={() => onDeleteTemplate(template)} />
        </div>
      </div>
    </Card>

  );
};

export default withStyles(styles)(EmailTemplatePreview);
