import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import { connect } from 'react-redux';
import ModifierEditor from 'portal/admin/modifierTemplateManagement/ModifierEditor';
import { SteppedLineTo } from 'react-lineto';
import {
  pushModifierTemplateToStack,
  removeModifierTemplatesFromStack,
  deleteMenuItemModifierTemplate,
  updateModifierTemplateInStack,
} from 'actions/menuItemModifier';

const styles = theme => ({
  fullRow: {
    width: '100%',
  },
  row: {
    display: 'flex',
    marginRight: theme.spacing.unit * 5,
    marginLeft: theme.spacing.unit * 5,
    marginTop: theme.spacing.unit * 1.5,
    marginBottom: theme.spacing.unit * 1.5,
  },
  subtitle: {
    backgroundColor: theme.palette.grey[50],
    fontSize: '17px',
    color: theme.common.black,
    width: '100%',
    paddingLeft: theme.spacing.unit * 5,
    paddingTop: theme.spacing.unit * 0.5,
    textAlign: 'left',
    height: theme.spacing.unit * 4,
  },
  horizontalScroll: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  addButton: {
    textTransform: 'initial',
    paddingLeft: theme.spacing.unit * 6.5,
    paddingRight: theme.spacing.unit * 6.5,
    borderColor: theme.palette.grey[100],
  },
});

const dimensions = {
  width: 1180,
  height: 810,
};

export class ModifierEditorModal extends Component {

  state = {
    modifierTemplatesStack: [],
    columns: [{
      field: 'selected',
      title: 'Selected',
      isVisible: true,
    }, {
      field: 'name',
      title: 'Name',
      isVisible: true,
    }, {
      field: 'options',
      title: 'Options',
      isVisible: true,
    },
    ],
  };

  componentDidMount() {
    window.addEventListener('resize', this.windowSizeOrScrollChanged);
    window.addEventListener('scroll', this.windowSizeOrScrollChanged, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowSizeOrScrollChanged);
    window.removeEventListener('scroll', this.windowSizeOrScrollChanged);
  }

  windowSizeOrScrollChanged = () => {
    this.setState({
      ...this.state,
      windowSize: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
      scroll: {
        y: window.scrollY,
        x: window.scrollX,
      },
    });

    return Promise.resolve(); // in case something needs to adjust to this new width (ie: columns)
  }

  componentDidUpdate(prevProps) {
    const { isOpened,
      modifier,
    } = this.props;

    if (isOpened === true && modifier !== prevProps.modifier) {

      this.setState({
        ...this.state,
        modifierTemplatesStack: [modifier],
      });
    }
  }

  save = () => {
    const { modifierTemplatesStack } = this.props;

    this.props.onSave(modifierTemplatesStack[0]);
  };

  render() {
    const {
      classes,
      onModalClosed,
      title,
      isOpened,
      modifierTemplatesStack,
    } = this.props;

    return (<Modal
      saveText='OK'
      isOpened={isOpened}
      onCancel={onModalClosed}
      title={title}
      dimensions={dimensions}
      onSave={this.save}
      addTitleBottomBorder={true}
    >
      <div className={classes.horizontalScroll}>
        {
          modifierTemplatesStack && modifierTemplatesStack.map((modifierTemplate, index) => {
            return (
              <>
                <ModifierEditor
                  minimumCancelButtonIndex={1}
                  modifierTemplate={modifierTemplate}
                  stack={modifierTemplatesStack}
                  index={index}
                  key={index}
                  pushModifierTemplateToStack={this.props.pushModifierTemplateToStack}
                  removeModifierTemplatesFromStack={this.props.removeModifierTemplatesFromStack}
                  deleteMenuItemModifierTemplate={this.props.deleteMenuItemModifierTemplate}
                  updateModifierTemplateInStack={this.props.updateModifierTemplateInStack}
                />
                <SteppedLineTo zIndex={9999} delay={20} borderColor={index === 1 ? '#4A90E2' : '#470a66'} borderWidth={3} fromAnchor="100% 50%" toAnchor="0 50%" from={`modifierEditor-${index - 1}`} to={`modifierEditor-${index}`} orientation="h" />
              </>
            );
          })
        }
      </div>
    </Modal>);
  }
}

const mapStateToProps = state => {
  const {
    menuItemModifier: {
      modifierTemplates: {
        data,
        isLoading,
        modifierTemplatesStack,
      },
    },
  } = state;

  return {
    modifierTemplates: data,
    modifierTemplatesStack,
    isLoading,
  };
};

ModifierEditorModal.propTypes = {
  onModalClosed: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
const mapDispatchToProps = {
  pushModifierTemplateToStack,
  removeModifierTemplatesFromStack,
  deleteMenuItemModifierTemplate,
  updateModifierTemplateInStack,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModifierEditorModal));
