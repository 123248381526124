import api, { reportsApi, paymentApi } from './api';

//if you want a POST you only need apiCall(url, {body:json})
//if you want a GET you only need apiCall(url)
export const callApi = (uri, params) =>
  buildRequestAndCall(`${api}${uri}`, params);

export const callReportsApi = (uri, params) =>
  buildRequestAndCall(`${reportsApi}${uri}`, params);

export const callPaymentApi = (uri, params) =>
  buildRequestAndCall(`${paymentApi}${uri}`, params);

const buildRequestAndCall = (url, { body, contentType, method = 'GET' } = {}) =>
  (_dispatch, getState) => {
    if (body) {
      if (!contentType || contentType === 'application/json') {
        body = JSON.stringify(body);
      }
      if (method === 'GET') {
        method = 'POST';
      }
    }
    const user = getState().oidc.user;

    if (!user) {
      console.warn('no access token in redux');

      return callApiWithAuth(url, {}, body, method);
    }
    const token = user.access_token;
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': contentType || 'application/json',
    };

    return callApiWithAuth(url, headers, body, method);
  };

export function callApiFileUpload(uri, { body, method = 'POST' } = {}) {
  return (_dispatch, getState) => {
    const url = `${api}${uri}`;
    const token = getState().oidc.user.access_token;
    const headers = { Authorization: `Bearer ${token}` };

    return callApiWithAuth(url, headers, body, method);
  };
}

function callApiWithAuth(url, headers, body, method) {
  const callApiWithHeader = headers => fetch(url, {
    method,
    headers,
    body,
  });

  //handle 401 and call userManager.logout()?
  return callApiWithHeader(headers);

}
