import React, { Component } from 'react';
import SmallGrid from 'Components/Grid/SmallGrid';
import _ from 'lodash';
import moment from 'moment';
import { buildGridCellLink } from 'Components/buildGridCellLink';
import { GridColumn } from '@progress/kendo-react-grid';

class SmallOrderGrid extends Component {

  linkToOrder = buildGridCellLink({
    url: order => `/orders/${order.id}`,
    text: order => order.id,
  });

  getItemString = orderItems => {
    return orderItems.map(item => item.name).join(', ');
  }

  render() {
    const { orders } = this.props;

    if (orders && orders.length < 1) return null;

    return (
      <SmallGrid data={orders}>
        <GridColumn key="id" field="id" title="Order #" cell={this.linkToOrder} width="100px"/>
        <GridColumn
          key="requestedDateTime"
          field="requestedDateTime"
          title="Date"
          width="100px"
          cell={props => (
            <td>{moment(props.dataItem.requestedDateTime).format('l')}</td>
          )}
        />
        <GridColumn key="type" field="type" title="Type" width="100px" />
        <GridColumn key="status" field="status" title="Status" width="100px" cell={props => (
          <td>{_.startCase(props.dataItem.status)}</td>
        )}
        />
        <GridColumn key="items" field="orderItems" title="Items" cell={props => (
          <td>{this.getItemString(props.dataItem.orderItems)}</td>
        )} />
        <GridColumn key="total" field="total" title="Total" width="100px" format="{0:C}" />
      </SmallGrid>
    );
  }
}

export default SmallOrderGrid;
