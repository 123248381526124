import {
  MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_BEGIN,
  RECEIVE_MENU_ITEM_MODIFIER_TEMPLATES,
  MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_END,
  DELETE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS,
  START_MODIFIER_TEMPLATE_STACK,
  PUSH_MODIFIER_TEMPLATE_TO_STACK,
  REMOVE_MODIFIER_TEMPLATES_FROM_STACK,
  SAVE_MODIFIER_TEMPLATE,
  UPDATE_MODIFIER_TEMPLATE_IN_STACK,
} from 'actions/constants';

import { ConvertArrayToDictionary } from '../../helpers/dictionary';
import _ from 'lodash';

const initialState = {
  modifierTemplates: {
    loading: false,
    data: [],
  },
};

export function menuItemModifier(state = initialState, action) {
  switch (action.type) {
    case MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_BEGIN: {
      return {
        ...state,
        modifierTemplates: {
          ...state.modifierTemplates,
          loading: true,
        },
      };
    }
    case MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_END: {
      return {
        ...state,
        modifierTemplates: {
          ...state.modifierTemplates,
          loading: false,
        },
      };
    }
    case RECEIVE_MENU_ITEM_MODIFIER_TEMPLATES: {
      const { response, append } = action;
      const { modifierTemplates } = state;

      const newData = append ? modifierTemplates.data.concat(response) : response;

      return {
        ...state,
        modifierTemplates: {
          data: newData.sort(idAscending),
          indexedData: ConvertArrayToDictionary(newData, 'id'),
          loading: false,
        },
      };
    }
    case DELETE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS: {
      const { modifierTemplates } = state;

      if (action.index === 0) {
        const newIndexedData = modifierTemplates.indexedData;

        delete newIndexedData[action.modifierTemplate.id];

        return {
          ...state,
          modifierTemplates: {
            ...state.modifierTemplates,
            data: modifierTemplates.data.filter(item => item.id !== action.modifierTemplate.id),
            indexedData: newIndexedData,
            modifierTemplatesStack: undefined,
          },
        };
      }
      else {
        let parentOptions = modifierTemplates.modifierTemplatesStack[action.index - 1].options || [];

        if (action.modifierTemplate.id !== undefined) {
          action.modifierTemplate.recordStatus = "Deleted";
          parentOptions = parentOptions.map(item => item.id === action.modifierTemplate.id ? action.modifierTemplate : item);
        } else {
          parentOptions = parentOptions.filter((item, index) => index !== action.modifierTemplate.index);
        }

        return {
          ...state,
          modifierTemplates: {
            ...state.modifierTemplates,
            modifierTemplatesStack: modifierTemplates.modifierTemplatesStack.filter((templtae, templateIndex) => templateIndex < action.index).map((template, templateIndex) => templateIndex === action.index - 1 ? { ...template, options: parentOptions } : template),
          },
        };
      }
    }
    case START_MODIFIER_TEMPLATE_STACK: {
      const { modifierTemplate } = action;

      return {
        ...state,
        modifierTemplates: {
          ...state.modifierTemplates,
          modifierTemplatesStack: [modifierTemplate],
        },
      };
    }
    case PUSH_MODIFIER_TEMPLATE_TO_STACK: {
      const { modifierTemplate, index } = action;
      const { modifierTemplates } = state;

      return {
        ...state,
        modifierTemplates: {
          ...state.modifierTemplates,
          modifierTemplatesStack: modifierTemplates.modifierTemplatesStack ? modifierTemplates.modifierTemplatesStack.filter((templtae, templateIndex) => templateIndex <= index).concat(modifierTemplate) : [modifierTemplate],
        },
      };
    }
    case UPDATE_MODIFIER_TEMPLATE_IN_STACK: {
      let modifierTemplatesStack = _.cloneDeep(state.modifierTemplates.modifierTemplatesStack);

      modifierTemplatesStack[action.index] = action.modifierTemplate;

      if (action.index > 0) {
        let currentLevel = action.index - 1;

        do {
          let parentOptions = modifierTemplatesStack[currentLevel].options || [];

          parentOptions = parentOptions.map(option => ({ ...option, selected: false }));

          if (modifierTemplatesStack[currentLevel + 1].index !== undefined) {
            parentOptions[modifierTemplatesStack[currentLevel + 1].index] = modifierTemplatesStack[currentLevel + 1];
          } else {
            parentOptions.push(modifierTemplatesStack[currentLevel + 1]);
            modifierTemplatesStack[currentLevel + 1].index = parentOptions.length - 1;
          }

          modifierTemplatesStack[currentLevel].options = parentOptions;

          currentLevel--;
        } while (currentLevel >= 0);
      }

      return {
        ...state,
        modifierTemplates: {
          ...state.modifierTemplates,
          modifierTemplatesStack,
        },
      };
    }
    case REMOVE_MODIFIER_TEMPLATES_FROM_STACK: {
      const { index } = action;
      const { modifierTemplates } = state;

      return {
        ...state,
        modifierTemplates: {
          ...state.modifierTemplates,
          modifierTemplatesStack: modifierTemplates.modifierTemplatesStack.filter((templtae, templateIndex) => templateIndex < index),
        },
      };
    }
    case SAVE_MODIFIER_TEMPLATE: {
      const { modifierTemplates } = state;

      if (action.index === 0) {
        return {
          ...state,
          modifierTemplates: {
            ...state.modifierTemplates,
            data: modifierTemplates.data.filter(item => item.id !== action.modifierTemplate.id).concat(action.modifierTemplate).sort(idAscending),
            indexedData: {
              ...modifierTemplates.indexedData,
              [action.modifierTemplate.id]: action.modifierTemplate,
            },
            modifierTemplatesStack: undefined,
          },
        };
      }
      else {
        const parentOptions = modifierTemplates.modifierTemplatesStack[action.index - 1].options || [];

        if (action.modifierTemplate.index !== undefined) {
          parentOptions[action.modifierTemplate.index] = action.modifierTemplate;
        } else {
          parentOptions.push(action.modifierTemplate);
        }

        return {
          ...state,
          modifierTemplates: {
            ...state.modifierTemplates,
            modifierTemplatesStack: modifierTemplates.modifierTemplatesStack.filter((templtae, templateIndex) => templateIndex < action.index).map((template, templateIndex) => templateIndex === action.index - 1 ? { ...template, options: parentOptions } : template),
          },
        };
      }
    }
    default:
      return state;
  }
}

const idAscending = (entity1, entity2) => {
  if (entity1.id > entity2.id) {
    return -1;
  }
  if (entity1.id < entity2.id) {
    return 1;
  }

  return 0;
};
