export function ConvertArrayToDictionary(array, indexKey) {
  const dictionary = {};

  if (array) {
    for (let i = 0; i < array.length; i++) {
      const key = array[i][indexKey];

      dictionary[key] = array[i];
    }
  }

  return dictionary;
}
