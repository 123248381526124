import { createMuiTheme } from '@material-ui/core/styles';
import shadows from './shadows';
import { darken, fade, lighten } from '@material-ui/core/styles/colorManipulator';

const extraSmall = 0;
const small = 600;
const breakpoints = {
  values: {
    xs: extraSmall,
    sm: small,
    md: 1024,
    lg: 1280,
    xl: 1920,
  },
};
//Default Theme
const Theme1 = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 56,
      height: 56,
      [`@media (min-width: ${extraSmall}px) and (orientation: landscape)`]: {
        minHeight: 48,
        height: 48,
      },
      [`@media (min-width: ${small}px)`]: {
        minHeight: 64,
        height: 64,
      },
    },
  },
  palette: {
    primary: {
      main: '#002257',
      dark: '#4A90E2', //note: used on label when selecting an input
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#4A90E2',
      dark: '#0070D2',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#FAFBFC',
      paper: '#FFFFFF',
    },
    // Current thought is to use these for contrast on background
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
    grey: { //the comment documents the rouded value out of 255. 0 is black 255 is white. So easier to fine replacements when working off mocks
      '900': '#212121', //33
      'A400': '#303030',//48
      '800': '#424242', //66
      '400': '#4A4A4A',	//74
      '700': '#616161',	//97
      'A700': '#616161',//97
      '300': '#637280',	//113
      '600': '#757575',	//117
      '200': '#9B9B9B',	//155
      '500': '#9e9e9e',	//158
      'A200': '#aaaaaa',//170
      'A100': '#d5d5d5',//213
      '100': '#D1DBE2',	//218
      '50': '#F2F2F2',	//242
    },
    action: {
      active: '#2189F8', // Accent
      inputHighlight: '#f2f8fd',
    },
    error: {
      light: '#F8E71C26', // Highlight
      main: '#FF0000',
      dark: '#D0021B',
    },

    // NON-MATERIAL TYPESCRIPT COLORS
    // TODO: May need to inherit and/or override the material ui types to support this when we migrate to typescript support
    // Color descriptions pulled from www.colorhexa.com
    success: '#34AA44',       //Dark moderate lime green
    personalEvent: '#9013FE', //Vivid violet
    proposalEvent: '#FFB75D',     //Light orange
    bookingEvent: '#50E3C2',  //Teal
    taskEvent: '#0064FF',     //Pure (or mostly pure) blue
    lead: '#5C236F', // purple
    order: '#0095a4', // softPink
    account: '#FCB95B', // orange
    contact: '#2FBFCE', // blue
  }, //end palette
  breakpoints,
  //material requires this shadows array to have 25 items, but there aren't shadows anywhere except the nav as of now
  shadows,
  typography: {
    allVariants: {
      fontFamily: '"Open Sans", "Helvetica Neue", sans-serif',
    },
    useNextVariants: true,
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
    red: '#E6492D',
    blue: '#1665D8', //TODO: replace with palette.secondary.dark?
    blue2: '#1F6FE5', //TODO replace with palette.secondary.main?
    bluegrey: '#95AEC5',
    green: '#34AA44',
    purple: '#6758F3',
    purple2: '#5B236f',
    yellow: '#F4A622',
    lavenderBlush: "#fcf2f3",
    panache: "#f8fdf4",
    emerald: "#4bca81",
    teal: '#50E3C2', // single booking page
    softPink: '#e35088',
    darkGreen: '#417505',
    uberGreen: '#7DC240', //uber eats border
    magenta: '#9647AA',
    navy: '#060f4e',
    contactBlue: '#2FBFCE', // contact page
    blueGrey: 'rgba(216,223,229,0.53)',
  },
});

const DarkTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#3e2723',
      dark: '#1b0000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#dd2c00',
      dark: '#a30000', //note: used on label when selecting an input
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#212121',
      paper: '#891800',
    },
    // Current thought is to use these for contrast on background
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
    },
    grey: {
      '50': '#F2F2F2',
      '100': '#D1DBE2',
      '200': '#9B9B9B',
      '300': '#637280',
      '400': '#4A4A4A',
      '500': '#3E3F42',
    },
    action: {
      active: '#2189F8', // Accent
      inputHighlight: '#f2f8fd',
    },
    error: {
      light: '#F8E71C26', // Highlight
      main: '#FF0000',
      dark: '#D0021B',
    },

    // NON-MATERIAL TYPESCRIPT COLORS
    // TODO: May need to inherit and/or override the material ui types to support this when we migrate to typescript support
    success: '#34AA44',       //Dark moderate lime green
    personalEvent: '#9013FE', //Vivid violet
    proposalEvent: '#FFB75D',     //Light orange
    bookingEvent: '#50E3C2', // Teal
    taskEvent: '#0064FF',     //Pure (or mostly pure) blue
    lead: '#5C236F', // purple
    order: '#0095a4', // softPink
  },
  breakpoints,
  //material requires this shadows array to have 25 items, but there aren't shadows anywhere except the nav as of now
  shadows,
  typography: {
    allVariants: {
      fontFamily: '"Open Sans", "Helvetica Neue", sans-serif',
    },
    useNextVariants: true,
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
    red: '#E6492D',
    blue: '#1665D8',
    blue2: '#1F6FE5',
    bluegrey: '#95AEC5',
    green: '#34AA44',
    purple: '#6758F3',
    purple2: '#5B236f',
    yellow: '#F4A622',
    lavenderBlush: "#fcf2f3",
    panache: "#f8fdf4",
    emerald: "#4bca81",
    teal: '#50E3C2',
    softPink: '#e35088',
    darkGreen: '#417505',
    uberGreen: '#7DC240',
    magenta: '#9647AA',
    navy: '#060f4e',
    contactBlue: '#2FBFCE', // contact page
    blueGrey: 'rgba(216,223,229,0.53)',
  },
});

const Theme2 = createMuiTheme({
  palette: {
    type: 'dark',
    primary: { main: '#D8BFD8' },
    secondary: { main: '#40E0D0' },
    background: { grayPaper: '#000' },
    grey: { //default greys for reference
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      'A100': '#d5d5d5',
      'A200': '#aaaaaa',
      'A400': '#303030',
      'A700': '#616161',
    },
  },
  //material requires this shadows array to have 25 items, but there aren't shadows anywhere except the nav as of now
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    useNextVariants: true,
  },
});

const getBorderColor = theme => {
  return theme.palette.type === 'light'
    ? lighten(fade(theme.palette.divider, 1), 0.88) //need the same color that Material design uses, and this is how it calculates it
    : darken(fade(theme.palette.divider, 1), 0.68);
};

export { Theme1, Theme2, DarkTheme, getBorderColor };
