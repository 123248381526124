import { callApi } from 'shared/CallApi';
import { defaultBookingMenuGridColumns } from 'constants/gridColumnDefaults';
import { updateStoreFocusedEvent, setNextTempId, updateStoreBooking } from 'actions/booking';
import {
  EVENT_MENU_CATEGORY_ADDED,
  EVENT_MENU_CATEGORY_DELETED,
  EVENT_MENU_CATEGORY_UPDATED,
  EVENT_MENU_ITEM_ADDED,
  EVENT_MENU_ITEM_DELETED,
  MENU_WAS_RESET,
  FETCH_BOOKING_MENU_COLUMNS_BEGIN,
  BOOKING_MENU_COLUMNS_RECEIVED,
  PACKAGES_RECEIVED,
  PACKAGE_APPLIED_TO_MENU,
} from './constants';
import Fuse from 'fuse.js';

export const menuReset = menuId => ({
  type: MENU_WAS_RESET,
  menuId,
});

export const fetchBookingMenuColumnsBegin = () => ({
  type: FETCH_BOOKING_MENU_COLUMNS_BEGIN,
});

export const bookingMenuColumnsReceived = columns => ({
  type: BOOKING_MENU_COLUMNS_RECEIVED,
  columns,
});

export const packagesReceived = packages => ({
  type: PACKAGES_RECEIVED,
  packages,
});

export const packageAppliedToMenu = () => ({
  type: PACKAGE_APPLIED_TO_MENU,
});

export const resetMenu = menuId => dispatch =>
  dispatch(callApi(`EventMenu/${menuId}/reset`, { method: 'PUT' }))
    .then(dispatch(menuReset(menuId)));

export const addLibraryItems = (menuSection, libraryItems) => dispatch => dispatch(
  callApi(`EventMenu/${menuSection.menuId}/${menuSection.id}/items`, { body: libraryItems }))
  .then(result => result.json())
  .catch(console.error);

export const editLibraryItem = (menuSection, libraryItem) => dispatch => dispatch(
  callApi(`EventMenu/${menuSection.menuId}/${menuSection.id}/items`,
    {
      body: libraryItem,
      method: 'PUT',
    }))
  .then(result => result.json())
  .catch(console.error);

//actually returns an array of sets. each set returns an array of modifiers
export const getSetsOfModifiers = (menuSection, menuSectionLibraryItem) => dispatch => dispatch(
  callApi(`EventMenu/${menuSection.menuId}/${menuSection.id}/items/${menuSectionLibraryItem.id}/modifiers`))
  .then(result => result.json())
  .catch(console.error);

//actually returns an array of sets. each set returns an array of modifiers
export const updateSetsOfModifiers = (menuSection, setsOfModifiers) => dispatch => dispatch(
  callApi(`EventMenu/${menuSection.menuId}/${menuSection.id}/items/modifiers`,
    {
      body: setsOfModifiers,
    }))
  .then(result => result.json())
  .catch(console.error);

export const removeLibraryItem = (menuSection, libraryItem) => dispatch => dispatch(
  callApi(`EventMenu/${menuSection.menuId}/${menuSection.id}/items`,
    {
      body: libraryItem,
      method: 'DELETE',
    }));

export const getBookingMenuColumns = () => dispatch => {
  dispatch(fetchBookingMenuColumnsBegin());
  dispatch(callApi('userSetting/booking_menu_columns'))
    .then(response => response.json())
    .then(columnString => {
      const columns = columnString ? columnString.split(',') : [];

      dispatch(bookingMenuColumnsReceived(columns));

      return columns;
    })
    .catch(dispatch(bookingMenuColumnsReceived(defaultBookingMenuGridColumns.all)));
};

export const saveBookingMenuColumns = columns => dispatch => {
  const setting = columns.length ? columns.join() : defaultBookingMenuGridColumns.default.join();

  dispatch(callApi('userSetting/booking_menu_columns', { method: 'PUT', body: setting }))
    .then(response => response.json())
    .then(dispatch(bookingMenuColumnsReceived(columns)));
};

export const getPackages = () =>
  dispatch => dispatch(callApi('package'))
    .then(result => result.json())
    .then(packages => {
      dispatch(packagesReceived(packages));

      return packages;
    });

export const createMenuFromPackage = (menuId, selectedPackage, menuSection) => dispatch =>
  dispatch(
    callApi(`EventMenu/${menuId}/package/${selectedPackage.id}`, {
      body: menuSection,
      method: 'POST',
    }))
    .then(dispatch(packageAppliedToMenu()))
    .catch(console.error);

// STORE ACTIONS

export const addMenuCategoryToEvent = () => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent, nextTempId } = booking[booking.currentStoreType];
  const menuCategory = {
    name: 'Unnamed Category',
    tempId: `t-${nextTempId}`,
    eventMenuCategoryItems: [],
    recordStatus: 'Active',
  };

  if (!focusedEvent.bookingEventMenuCategories) focusedEvent.bookingEventMenuCategories = [];

  const updatedEvent = {
    ...focusedEvent,
    bookingEventMenuCategories: [
      ...focusedEvent.bookingEventMenuCategories,
      menuCategory,
    ],
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
  dispatch(menuCategoryAdded(menuCategory));
  dispatch(setNextTempId(nextTempId + 1));

  return Promise.resolve();
};

export const updateEventMenuCategories = categories => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];

  const updatedEvent = {
    ...focusedEvent,
    bookingEventMenuCategories: categories,
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
};

export const updateEventMenuCategory = updatedCategory => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];

  const event = { ...focusedEvent };
  const categories = event.bookingEventMenuCategories.map(menuCategory => {
    if ((menuCategory.id && menuCategory.id === updatedCategory.id) || menuCategory.tempId === updatedCategory.tempId) return updatedCategory;

    return menuCategory;
  });

  const updatedEvent = {
    ...event,
    bookingEventMenuCategories: categories,
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
  dispatch(menuCategoryUpdated(updatedCategory));
};

export const deleteEventMenuCategory = eventMenuCategory => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];
  const updatedEvent = { ...focusedEvent };

  if (eventMenuCategory.id) {
    updatedEvent.bookingEventMenuCategories = updatedEvent.bookingEventMenuCategories.map(category => {
      if (category.id === eventMenuCategory.id) category.recordStatus = 'Deleted';

      return category;
    });
  } else if (eventMenuCategory.tempId) {
    const indexOfCategory = updatedEvent.bookingEventMenuCategories.findIndex(category => category.tempId === eventMenuCategory.tempId);

    updatedEvent.bookingEventMenuCategories.splice(indexOfCategory, 1);
  }

  dispatch(updateStoreFocusedEvent(updatedEvent));
  dispatch(menuCategoryDeleted(eventMenuCategory));

  return Promise.resolve();
};

export const addMenuItemToEventMenuCategory = (parentCategory, item) => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent, nextTempId } = booking[booking.currentStoreType];

  const bookingEventMenuCategoryItem = {
    tempId: `t-${nextTempId}`,
    name: item.name,
    recordStatus: 'Active',
    menuCategoryItemId: item.id,
    quantity: item.quantity, // tied to Estimated Guest Count
    description: item.description,
    allergens: item.allergens,
    calories: item.calories,
    unitPrice: item.price,
    // price: item.price,
    revenueTypeId: item.revenueTypeId || item.menuItem.revenueTypeId || 1, //TODO: Default RevenueType
    sortOrder: [...parentCategory.eventMenuCategoryItems].length + 1,
  };

  const updatedCategory = {
    ...parentCategory,
    eventMenuCategoryItems: [
      ...parentCategory.eventMenuCategoryItems,
      bookingEventMenuCategoryItem,
    ],
  };

  const updatedEvent = {
    ...focusedEvent,
    bookingEventMenuCategories: focusedEvent.bookingEventMenuCategories.map(category => (updatedCategory.id && category.id === updatedCategory.id) || (updatedCategory.tempId && category.tempId === updatedCategory.tempId) ? updatedCategory : category ),
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
  dispatch(eventMenuItemAdded(bookingEventMenuCategoryItem));
  dispatch(setNextTempId(nextTempId + 1));
};

export const editEventMenuCategoryItem = (parentCategory, updatedItem) => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];
  let updatedMenuCategoryItems;

  if (updatedItem.id) {
    updatedMenuCategoryItems = parentCategory.eventMenuCategoryItems.map(categoryItem => categoryItem.id === updatedItem.id ? updatedItem : categoryItem);
  } else if (updatedItem.tempId) {
    updatedMenuCategoryItems = parentCategory.eventMenuCategoryItems.map(categoryItem => categoryItem.tempId === updatedItem.tempId ? updatedItem : categoryItem);
  }

  const updatedCategory = {
    ...parentCategory,
    eventMenuCategoryItems: updatedMenuCategoryItems,
  };

  const updatedEvent = {
    ...focusedEvent,
    bookingEventMenuCategories: focusedEvent.bookingEventMenuCategories.map(category => (updatedCategory.id && category.id === updatedCategory.id) || (updatedCategory.tempId && category.tempId === updatedCategory.tempId) ? updatedCategory : category ),
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
};

export const filterStoreMenu = searchText => (dispatch, getState) => {
  const { storeReducer: { storeAllMenuItems } } = getState();
  const options = {
    isCaseSensitive: false,
    shouldSort: true,
    minMatchCharLength: 1,
    distance: 30,
    ignoreLocation: true,
    keys: [
      {
        name: 'name',
        weight: 0.7,
      },
      {
        name: 'description',
        weight: 0.3,
      },
    ],
  };
  // fuzzy-search tool: see https://fusejs.io/
  const fuse = new Fuse(storeAllMenuItems, options);

  if (searchText && searchText.length > 0) {
    const results = fuse.search(searchText);
    const topFive = results.map(result => result.item).slice(0, 5);

    return Promise.resolve(topFive);
  }

  return Promise.resolve([]);
};

export const deleteMenuItemFromCategory = (parentCategory, item) => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];
  let updatedCategory = { ...parentCategory };

  if (item.id) {
    updatedCategory.eventMenuCategoryItems = updatedCategory.eventMenuCategoryItems.map(categoryItem => {
      if (categoryItem.id === item.id) categoryItem.recordStatus = 'Deleted';

      return categoryItem;
    });
  } else if (item.tempId) {
    const indexOfItem = updatedCategory.eventMenuCategoryItems.findIndex(categoryItem => categoryItem.tempId === item.tempId);

    updatedCategory.eventMenuCategoryItems.splice(indexOfItem, 1);
  }

  const updatedCategories = focusedEvent.bookingEventMenuCategories.map(category => (parentCategory.id && category.id === parentCategory.id) || category.tempId === parentCategory.tempId ? updatedCategory : category);

  const updatedEvent = {
    ...focusedEvent,
    bookingEventMenuCategories: updatedCategories,
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
  dispatch(eventMenuItemDeleted(item));

  return Promise.resolve();
};

export const resetAllEventMenus = () => (dispatch, getState) => {
  const { booking } = getState();
  const { storeBooking } = booking[booking.currentStoreType];
  const savedEvents = storeBooking.events.filter(e => !!e.id);

  for (let i = 0; i < savedEvents.length; i++) {
    const { booking } = getState();
    const { nextTempId } = booking[booking.currentStoreType];

    const savedCategories = savedEvents[i].bookingEventMenuCategories.filter(c => !!c.id);

    for (let j = 0; j < savedCategories.length; j++) {
      savedCategories[j].recordStatus = 'Deleted';
      const savedItems = savedCategories[j].eventMenuCategoryItems.filter(item => !!item.id);

      for (let k = 0; k < savedItems.length; k++) {
        savedItems[k].recordStatus = 'Deleted';
      }
      savedCategories[j].eventMenuCategoryItems = savedItems;
    }

    savedCategories.push(
      {
        name: 'Unnamed Category',
        tempId: `t-${nextTempId}`,
        eventMenuCategoryItems: [],
        recordStatus: 'Active',
      },
    );

    savedEvents[i].bookingEventMenuCategories = savedCategories;
    dispatch(setNextTempId(nextTempId + 1));
  }
  const updatedStoreBooking = {
    ...storeBooking,
    events: savedEvents,
  };

  dispatch(updateStoreBooking(updatedStoreBooking));
};

///

export const menuCategoryAdded = eventMenuCategory => ({
  type: EVENT_MENU_CATEGORY_ADDED,
  eventMenuCategory,
});

export const menuCategoryUpdated = eventMenuCategory => ({
  type: EVENT_MENU_CATEGORY_UPDATED,
  eventMenuCategory,
});

export const menuCategoryDeleted = eventMenuCategory => ({
  type: EVENT_MENU_CATEGORY_DELETED,
  eventMenuCategory,
});

export const eventMenuItemAdded = bookingEventMenuCategoryItem => ({
  type: EVENT_MENU_ITEM_ADDED,
  bookingEventMenuCategoryItem,
});

export const eventMenuItemDeleted = bookingEventMenuCategoryItem => ({
  type: EVENT_MENU_ITEM_DELETED,
  bookingEventMenuCategoryItem,
});
