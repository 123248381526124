import {
  UPDATE_SEARCH,
  CLEAR_SEARCH,
} from 'actions/constants';

const initialState = {
  searchText: '',
};

export function searchReducer(state = initialState, action) {
  switch (action.type) {

    case UPDATE_SEARCH: {
      const { searchText } = action;

      const newState = {
        ...state,
        searchText,
      };

      return newState;
    }
    case CLEAR_SEARCH: {

      return {
        ...state,
        searchText: '',
      };
    }

    default:
      return state;
  }
}
