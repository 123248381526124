import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = () => ({
  containerStyle: {
    fontSize: 20,
  },
  dayOfMonth: {
    fontWeight: 'bold',
  },
});

class HeaderWeek extends Component {
  render() {
    const {
      date,
      classes,
      palette,
    } = this.props;
    const momentForDay = moment(date);
    const dayOfWeek = momentForDay.format('dddd');
    const dayOfMonth = momentForDay.format('D');

    return (
      <div className={classes.containerStyle}>
        <div style={{ color: palette.primary.main }}>{dayOfWeek}</div>
        <div className={classes.dayOfMonth} style={{ color: palette.grey.A200 }}>{dayOfMonth}</div>
      </div>
    );
  }
}
export default withStyles(styles)(HeaderWeek);
