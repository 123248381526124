import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ChitChatAll from './ChitChatAll';

const styles = theme => ({
  chitChatHeader: {
    position: 'static',
    alignItems: 'center',
    background: theme.palette.grey[50],
    fontSize: 18,
    padding: '12px 24px',
  },
});

class ChitChatMain extends Component {
  render() {
    const { classes } = this.props;

    return (<>
      <div className={classes.chitChatHeader}>
        Chit Chat
      </div>
      <ChitChatAll />
      </>
    );
  }
}

export default withStyles(styles)(ChitChatMain);
