import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import LeadHeader from './LeadHeader';
import LeadTabs from './LeadTabs';
import LeadSideTabs from './LeadSideTabs';
import ValidationBanner from 'Components/ValidationBanner';
import Email from 'Components/Email';
import SimpleDialog from 'Components/SimpleDialog';
import Convert from './Convert';
import ConvertSuccessModal from './ConvertSuccessModal';
import validate from 'validate.js';
import { withRouter } from 'react-router-dom';
import LeadContext from './LeadContext';
import {
  getSalesRepsIfNeeded,
} from 'actions/contact';
import {
  getLead,
  saveLead,
  deleteLead,
  cloneLead,
} from 'actions/lead';
import {
  fetchChitChat,
} from 'actions/chitchat';
import {
  getStatusHistory,
  addStatusHistory,
} from 'actions/statusHistory';
import { addToRecents, removeRecent } from 'actions/recents';
import _ from 'lodash';
import SectionContext from 'Components/Contexts/SectionContext';
import { ENTITY_TYPES } from 'constants/entityTypes';
import ChitChat from 'Components/ChitChat';
import { getStatuses } from 'actions/settings';
import Toast from 'Components/Toast';

const styles = theme => ({
  leadContent: {
    display: 'flex',
    padding: '0 24px',
    height: 'calc(100% - 120px - 82px - 20px)',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    borderRadius: 4,
    marginRight: 24,
    height: '100%',
  },
  side: {
    display: 'flex',
    borderRadius: 4,
    width: 340,
  },
  flexGrow: {
    display: 'flex',
    flexGrow: 1,
  },
});
const noErrors = {};

class Lead extends Component {

  constructor(props) {
    super(props);
    this.validationBanner = React.createRef();
    this.leadDialog = React.createRef();
    this.state = {
      isEditing: false,
      isFirstEdit: false,
      lead: {},
      leadRef: {},
      invalidFields: [],
      salesReps: [],
      viewState: 'leadDetails',
      statusHistory: [],
      isConvertModalOpen: false,
      isConvertSuccessModalOpen: false,
      successDto: null,
    };
  }

  async componentDidMount() {
    await this.checkIfFirstEditAsync();
    this.getLead();
    this.props.getStatuses();
  }

  async componentDidUpdate(prevProps, prevState) {
    await this.checkIfFirstEditAsync();
    if (prevProps.location !== this.props.location) {
      this.getLead();
    }
  }

  checkIfFirstEditAsync = () => {
    const { lead } = this.state;
    const isFirstEdit = !!lead && lead.firstName === 'New' && !lead.lastModifiedUtcDateTime;

    return new Promise((resolve, reject) => {
      if (this.state.isFirstEdit !== isFirstEdit) {
        this.setState({ isFirstEdit }, () => {
          if (isFirstEdit && !this.state.isEditing) {
            this.toggleEditMode();
          }
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  getLead = () => {
    const {
      getLead,
      match,
      addToRecents,
    } = this.props;
    const leadId = match.params.id;

    this.props.getStatusHistory('Lead', leadId).then(statusHistory => {
      this.setState({ statusHistory });
    });

    return new Promise((resolve, reject) => {
      getLead(leadId)
        .then(lead => {
          if (!!lead && !!lead.id) {
            this.setState({
              lead,
              isEditing: false,
              sectionContext: {
                relationship: {
                  entityId: lead.id,
                  entityType: ENTITY_TYPES.lead,
                },
              },
            }, () => {
              resolve(lead);
            });
            addToRecents(lead, match.url, ENTITY_TYPES.lead);
          } else {
            this.goToLeadsGrid(); // redirect if ID doesn't exist
          }
        });
    });
  }

  toggleEditMode = () => {
    if (this.state.isEditing) {
      this.cancelEditMode();
    } else {
      this.launchEditMode();
    }
  }

  launchEditMode = () => {
    // TURN ON: set initial form in case changes are not saved
    this.setState(
      {
        isEditing: true,
        editingLead: { ...this.state.lead },
        viewState: 'leadDetails',
        errors: {},
      });
  }

  cancelEditMode = () => {
    if (this.state.isFirstEdit) {
      this.deleteLead(); // User quick-adds a Lead, then decides not to Save it

      return;
    }
    if (this.isFormChanged()) {
      this.leadDialog.current.open('Are you sure you want to lose your changes?').then(() => {
        this.setState({ isEditing: false });
      });
    } else {
      this.setState({ isEditing: false });
    }
  }

  cloneLead = () => {
    const { cloneLead, saveLead, history, addToRecents } = this.props;

    this.leadDialog.current.open('Are you sure you want to clone this lead?').then(() => {
      cloneLead(this.state.lead).then(response => {
        const newUrl = `/leads/${response.id}`;

        addToRecents(response, newUrl, ENTITY_TYPES.lead);
        this.leadDialog.current.open('Successfully cloned! Would you like to go to the newly created lead?')
          .then(() => history.push(newUrl))
          .catch(() => saveLead(response)); // save new clone, so isNew is removed;
      }).catch(error => this.validationBanner.current.open(error.toString()));
    });
  }

  goToLeadsGrid = () => {
    this.props.history.push('/leads');
  }

  deleteLead = () => {
    const leadId = this.state.lead.id;

    this.leadDialog.current.open('Are you sure you want to delete this lead?').then(() => {
      this.props.deleteLead(leadId).then(() => {
        this.goToLeadsGrid();
        this.props.removeRecent(leadId, ENTITY_TYPES.lead);
      });
    });
  }

  stashLead = (fieldName, newValue) => {
    this.setState({
      editingLead: {
        ...this.state.editingLead,
        [fieldName]: newValue,
      },
    });
  }

  handleAddressChange = fieldName => value => {
    let editingLead = {
      ...this.state.editingLead,
      leadAddress: {
        ...this.state.editingLead.leadAddress,
        [fieldName]: value,
      },
    };

    this.setState({ editingLead });
  }

  saveLead = () => {
    const { editingLead, isFirstEdit } = this.state;

    const errors = this.isFormValid();//rerunall Validation

    if (errors) {
      this.setState({ errors });
      this.validationBanner.current.open('Check form for errors');

      return;
    }
    if (this.isFormChanged() || isFirstEdit) {
      this.setState({ isEditing: false });

      return this.props.saveLead(editingLead)
        .then(this.getLead)
        .catch(error => this.validationBanner.current.open(error.toString()));
    } else {
      this.cancelEditMode();
    }
  }

  isFormChanged = () => {
    return !_.isEqual(this.state.lead, this.state.editingLead);
  }

  //called onBlur of field
  validateField = fieldName => () => {
    let error = undefined;

    switch (fieldName) {
      case 'email':
        error = this.emailErrors();
        break;
      case 'name':
        error = this.nameErrors();
        break;
      default:
        return;
    }

    const hasError = error ? true : false; //treat error as truthy

    let errors = {
      ...this.state.errors,
      [fieldName]: hasError,
    };

    this.setState({ errors });
  }

  emailErrors = () => {
    return validate({ email: this.state.editingLead.email }, { email: { email: true } });
  }

  nameErrors = () => {
    return validate({ name: this.state.editingLead.name }, { name: { presence: { allowEmpty: false } } });
  }

  isFormValid = () => {
    const emailHasError = this.emailErrors() ? true : false;
    const nameHasError = this.nameErrors() ? true : false;

    if (!nameHasError && !emailHasError) {
      return null;
    }

    return {
      name: nameHasError,
      email: emailHasError,
    };
  }

  setView = viewState => {
    this.setState({ viewState });
  }

  onEmailDone = () => {
    this.setState({
      viewState: 'leadDetails',
      autoAttachFileId: null,
      successMessageOpen: true,
      successMessage: 'Email sent successfully',
    });
  }

  onEmailCancel = () => {
    this.setState({ viewState: 'leadDetails' });
  }

  handleSuccessMessageClose = () => {
    this.setState({
      successMessageOpen: false,
      successMessage: null,
    });
  }

  refreshChitChat = () => {
    const { lead, viewState } = this.state;
    const { fetchChitChat } = this.props;

    if (viewState === 'chitChat') {
      fetchChitChat(ENTITY_TYPES.lead, lead.id);
    }
  }

  updateLeadStatus = statusId => {
    const updatedLead = { ...this.state.lead, statusId };

    delete updatedLead.leadStatus;

    this.props.saveLead(updatedLead).then(apiLead => {
      this.setState({ lead: apiLead });
      this.props.addStatusHistory('Lead', apiLead.id, apiLead.statusId).then(statusHistory => {
        this.setState({ statusHistory });
        this.refreshChitChat();
      });
    });
  };

  promptSaveAndConvert = () => {
    const { isEditing, lead } = this.state;

    if (isEditing) {
      this.leadDialog.current.open('Save your changes?').then(() => {
        this.setState({ isConvertLoading: true });
        this.props.saveLead(lead)
          .then(() => {
            this.setState({ isConvertLoading: false });
            this.openConvertModal();
          })
          .catch(this.handleError);
      });
    } else {
      this.openConvertModal();
    }
  }

  openConvertModal = () => {
    this.setState({ isConvertModalOpen: true });
  }

  closeConvertModal = () => {
    this.setState({ isConvertModalOpen: false });
  }

  onConvertLeadStarted = () => {
    this.closeConvertModal();
    this.setState({ isConvertLoading: true });
  }

  onConvertLeadSuccess = successDto => {
    this.addConversionsToRecents(successDto);
    this.setState({
      isConvertSuccessModalOpen: true,
      successDto,
      isConvertLoading: false,
    });
  }

  addConversionsToRecents = successDto => {
    const convertedRecords = [];
    const accountId = successDto.account && successDto.account.id;
    const bookingId = successDto.booking && successDto.booking.id;
    const contactId = successDto.contact && successDto.contact.id;

    if (accountId) {
      convertedRecords.push({ name: successDto.account.name, id: accountId, url: `/accounts/${accountId}`, type: 'account' });
    }
    if (bookingId) {
      convertedRecords.push({ name: successDto.booking.name, id: bookingId, url: `/bookings/${bookingId}`, type: 'booking' });
    }
    if (contactId) {
      convertedRecords.push({ name: successDto.contact.name, id: contactId, url: `/contacts/${contactId}`, type: 'contact' });
    }

    convertedRecords.forEach(record => {
      this.props.addToRecents(record, record.url, record.type);
    });

    this.props.removeRecent(this.state.lead.id, 'lead');
  }

  goToUrl = url => {
    this.props.history.push(url);
  }

  handleError = () => {
    this.errorMessage.current.open('Something went wrong. Please try again.');
    this.setState({ isLoading: false });
  }

  render() {
    const { classes } = this.props;
    const {
      isEditing,
      lead,
      editingLead,
      errors,
      viewState,
      statusHistory,
      isConvertModalOpen,
      isConvertSuccessModalOpen,
      successDto,
      isConvertLoading,
      successMessage,
      successMessageOpen,
    } = this.state;

    if (!lead || !lead.id) {
      return null;
    }

    const sectionContext = {
      relationship: {
        entityId: lead.id,
        entityType: ENTITY_TYPES.lead,
      },
      onEmailDone: this.onEmailDone,
      onEmailCancel: this.onEmailCancel,
      chitChatRecordName: lead ? lead.name : '',
      defaultEmail: lead && lead.email ? lead.email : '',
    };

    const leadContextValue = {
      lead: isEditing ? editingLead : lead,
      isEditing,
      cancelEditMode: this.cancelEditMode,
      onLaunchEditMode: this.launchEditMode,
      stashLead: this.stashLead,
      saveLead: this.saveLead,
      validateField: this.validateField,
      errors: isEditing ? errors : noErrors,
      getLead: this.getLead,
      onAddressFieldChange: this.handleAddressChange,
      onChitChatUpdated: this.refreshChitChat,
      statusHistory,
      onUpdateStatus: this.updateLeadStatus,
      onLastStep: this.promptSaveAndConvert,
    };

    return (
      <LeadContext.Provider value={leadContextValue}>
        <SectionContext.Provider value={sectionContext}>
          <LeadHeader
            label="Lead"
            lead={lead}
            isEditing={isEditing}
            isLoading={isConvertLoading}
            onEdit={this.toggleEditMode}
            onDelete={this.deleteLead}
            onClone={this.cloneLead}
            onSetView={this.setView}
            onSaveLead={this.saveLead}
            viewState={viewState}
            onConvertClick={this.promptSaveAndConvert}
          />
          <div className={classes.leadContent}>
            <div className={classes.main}>
              {viewState === 'leadDetails' &&
                <LeadTabs
                  lead={lead}
                />
              }
              {viewState === 'chitChat' &&
                <Paper className={classes.flexGrow}>
                  <ChitChat
                    relationshipId={lead.id}
                    chitChatRelationship={ENTITY_TYPES.lead}
                    recordName={lead.name}
                  />
                </Paper>
              }
              {viewState === 'email' &&
                <Email />
              }
            </div>
            <div className={classes.side}>
              <LeadSideTabs />
            </div>
          </div>
          <ValidationBanner innerRef={this.validationBanner} />
          <SimpleDialog innerRef={this.leadDialog} />
          <SimpleDialog onlyOkayButton={true} innerRef={this.errorMessage} />
          {isConvertModalOpen &&
            <Convert
              lead={lead}
              onClose={this.closeConvertModal}
              onConvertLeadStarted={this.onConvertLeadStarted}
              onConvertLeadSuccess={this.onConvertLeadSuccess}
            />}
          {isConvertSuccessModalOpen && <ConvertSuccessModal onClose={this.goToUrl} successDto={successDto} />}
          <Toast message={successMessage} variant="success" open={successMessageOpen} onClose={this.handleSuccessMessageClose} />
        </SectionContext.Provider>
      </LeadContext.Provider>
    );
  }
}

const mapDispatchToProps = {
  getLead,
  saveLead,
  deleteLead,
  cloneLead,
  getSalesRepsIfNeeded,
  addToRecents,
  removeRecent,
  getStatuses,
  fetchChitChat,
  getStatusHistory,
  addStatusHistory,
};

export default withRouter(connect(undefined, mapDispatchToProps)(withStyles(styles)(Lead)));
