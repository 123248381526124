import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NumericTextBox } from '@progress/kendo-react-inputs';

const styles = theme => ({
  percentageContainer: {
    width: 115,
    '& .k-input': {
      color: theme.palette.primary.main,
    },
    '& .k-numeric-wrap': {
      borderStyle: 'none !important',
    },
    '& .k-select': {
      backgroundColor: 'transparent !important',
      backgroundImage: 'none !important',
      color: theme.palette.primary.main,
    },
    '& ::selection': {
      backgroundColor: theme.palette.action.inputHighlight + '!important',
      color: theme.palette.grey[900] + '!important',
    },
  },
});

class PercentageField extends Component {
  onPercentageChange = event => {
    this.props.onFieldChange(event.value);
  }

  render() {
    const {
      classes,
      ...rest
    } = this.props;

    return (
      <NumericTextBox
        format="##.0000 \%"
        min={0}
        max={100}
        step={1}
        className={classes.percentageContainer}
        onChange={this.onPercentageChange}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(PercentageField);
