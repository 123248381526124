import React from 'react';

const BookingContext = React.createContext(
  {
    onFinancialDetailsChanged: () => { },
    onBookingChanged: () => { },
    onChitChatUpdated: () => { },
    taxConfig: {},
  },
);

export default BookingContext;
