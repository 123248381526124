import React, { useContext } from 'react';
import PrimaryDetails from './PrimaryDetails';
import AddressManager from 'Components/AddressManager';
import AuditTrail from './AuditTrail';
import ContactContext from './ContactContext';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';

const styles = () => ({
  details: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: 'inherit',
  },
  addresses: {
    display: 'flex',
    flexGrow: 1,
    paddingRight: 16,
    marginBottom: -32,
  },
});

function ContactDetails({ classes, workingAddresses, selectedAddressId }) {
  const {
    contact,
    isEditing,
    onLaunchEditMode,
    onAddressChange,
    onNewAddress,
    onDeleteAddress,
    onHandlePrimaryAddress,
  } = useContext(ContactContext);

  return (
    <div className={classes.details} onDoubleClick={onLaunchEditMode}>
      <PrimaryDetails />
      <CoverExpansionPanel title="Address Information" defaultExpanded={true} >
        <div className={classes.addresses}>
          <AddressManager
            addresses={workingAddresses.filter(address => address.recordStatus !== 'Deleted')}
            parentId={contact.id}
            isEditing={isEditing}
            onAddressChange={onAddressChange}
            onHandlePrimaryAddress={onHandlePrimaryAddress}
            onNewAddress={onNewAddress}
            onDeleteAddress={onDeleteAddress}
            selectedAddressId={selectedAddressId}
          />
        </div>
      </CoverExpansionPanel>
      <AuditTrail contact={contact} />
    </div>
  );
}

const mapStateToProps = state => {
  const {
    address: {
      workingAddresses,
      selectedAddressId,
    },
  } = state;

  return {
    workingAddresses,
    selectedAddressId,
  };
};

export default connect(mapStateToProps, undefined)(withStyles(styles)(ContactDetails));
