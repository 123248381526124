import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductDetailsModal from './ProductDetailsModal';
import { connect } from 'react-redux';
import { updateStoreOrderItem } from 'actions/order';

class StoreOrderItemModifier extends Component {
  state = {
    menuItem: null,
    orderItem: null,
  };

  onSave = orderItem => {
    const { onModalClosed, updateStoreOrderItem } = this.props;

    updateStoreOrderItem(orderItem);
    onModalClosed();
  }

  componentDidMount () {
    const { selectedOrderItem, storeAllMenuItems } = this.props;

    const menuCategoryItem = storeAllMenuItems.find(item => {
      return item.id === selectedOrderItem.orderItem.menuCategoryItemId;
    });

    this.setState({
      menuItem: menuCategoryItem,
      orderItem: selectedOrderItem.orderItem,
    });

  }

  setModifiers = () => {
    const { orderItem } = this.state;
    const modifierState = [];

    if (orderItem && orderItem.orderItemModifiers) {
      orderItem.orderItemModifiers.forEach(element => {
        modifierState[element.menuItemModifierId] = true;
      });
    }

    return modifierState;
  }

  render() {
    const {
      onModalClosed,
      title,
      sets,
    } = this.props;
    const {
      menuItem,
      orderItem,
    } = this.state;

    if (!menuItem && !orderItem) {
      return null;
    }

    return (
      <ProductDetailsModal
        onModalClosed={onModalClosed}
        title={title}
        sets={sets}
        onSave={this.onSave}
        item={orderItem ? orderItem : menuItem}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeAllMenuItems,
    },
  } = state;

  return {
    storeAllMenuItems,
  };
};

const mapDispatchToProps = {
  updateStoreOrderItem,
};

StoreOrderItemModifier.propTypes = {
  sets: PropTypes.array.isRequired,
  onModalClosed: PropTypes.func.isRequired,
  title: PropTypes.string,
  selectedOrderItem: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreOrderItemModifier);
