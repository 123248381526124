import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Avatar } from '@material-ui/core/';
import classNames from 'classnames';
import {
  Business as Account,
  Category as Booking,
  Person as Contact,
} from '@material-ui/icons';

const styles = theme => ({
  sectionHeader: {
    fontSize: 17,
  },
  sectionBorder: {
    borderRadius: 4,
    height: 152,
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    justifyContent: 'space-between',
    padding: 8,
  },
  topLeft: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
  },
  smallerFont: {
    fontSize: 13,
  },
  name: {
    lineHeight: 1,
    fontSize: 18,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  overflow: {
    overflow: 'hidden',
  },
  bookingBorder: {
    border: `1px solid ${theme.common.teal}`,
  },
  accountBorder: {
    border: `1px solid ${theme.common.yellow}`,
  },
  contactBorder: {
    border: `1px solid ${theme.common.contactBlue}`,
  },
  booking: {
    background: theme.common.teal,
  },
  account: {
    background: theme.common.yellow,
  },
  contact: {
    background: theme.common.contactBlue,
  },
  disabled: {
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    '&:hover': {
      cursor: 'auto',
    },
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
    color: theme.palette.grey[200],
    fontSize: 16,
  },
  iconContainer: {
    display: 'flex',
    marginRight: 8,
  },
  avatar: {
    borderRadius: 5,
    width: 32,
    height: 32,
    fontSize: 17,
    color: theme.common.white,
  },
});

class ConvertedSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elevation: 1,
    };
    this.borderColors = {
      'Booking': 'bookingBorder',
      'Account': 'accountBorder',
      'Contact': 'contactBorder',
    };
    this.borderColor = this.borderColors[props.section];
    this.Icon = this.getIcon(props);
  }

  onMouseOver = () => {
    if (this.props.isActive) this.setState({ elevation: 3 });
  }

  onMouseOut = () => {
    if (this.props.isActive) this.setState({ elevation: 1 });
  }

  getIcon = props => {
    const { section, classes } = props;

    const icons = {
      'Booking': <Booking />,
      'Account': <Account />,
      'Contact': <Contact />,
    };

    const colorClasses = {
      'Booking': 'booking',
      'Account': 'account',
      'Contact': 'contact',
    };

    const icon = icons[section];
    const color = colorClasses[section];

    return (
      <div className={classes.iconContainer}>
        <Avatar className={classNames(classes.avatar, classes[color])}>
          {icon}
        </Avatar>
      </div>
    );
  }

  render() {
    const { classes, section, children, onSectionClick, isActive, name } = this.props;
    const { elevation } = this.state;

    return (
      <>
      <span className={classes.sectionHeader}>{section}</span>
        <Paper
          className={classNames(classes.sectionBorder, classes[this.borderColor], !isActive && classes.disabled)}
          elevation={elevation}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
        >
          {isActive
            ?
            <div onClick={onSectionClick} className={classes.container}>
              <div className={classes.topLeft}>
                {this.Icon}
                <div className={classes.overflow}>
                  <div className={classes.smallerFont}>{section}</div>
                  <p className={classes.name}>
                    {name}
                  </p>
                </div>
              </div>
              {children}
            </div>
            :
            <div className={classes.placeholder}>
              <span>No {section} Created</span>
            </div>}
        </Paper>
        </>
    );
  }
};

export default withStyles(styles)(ConvertedSection);
