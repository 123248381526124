import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { registerForIntl, provideIntlService } from '@progress/kendo-react-intl';
import classNames from 'classnames';

const styles = theme => ({
  container: {
    width: '25%',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderLeft: 0,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    padding: '1%',
    [theme.breakpoints.up('lg')]: {
      padding: 30,
    },
  },
  hideRightBorder: {
    borderRight: 0,
  },
  topText: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.palette.grey.A200,
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomText: {
    fontSize: 30,
  },
  changeText: {
    fontSize: 12,
    color: theme.palette.common.white,
    padding: '3px 7px',
    borderRadius: 4,
    marginLeft: 10,
  },
  upChange: {
    backgroundColor: theme.common.green,
  },
  downChange: {
    backgroundColor: theme.common.red,
  },
});

class CustomerNumber extends Component {
  constructor(props) {
    super(props);
    this.formatter = provideIntlService(this);
  }

  render() {
    const {
      classes,
      isCurrency,
      value,
      changeNumber,
      changeIsPercentage,
      label,
      valueDecimals,
      hideRightBorder,
    } = this.props;
    const changeClass = classNames(classes.changeText, changeNumber < 0 ? classes.downChange : classes.upChange);
    const numberFormat = (isCurrency ? 'c' : 'n') + (valueDecimals ? valueDecimals : 0);
    const containerClass = hideRightBorder ? classNames(classes.container, classes.hideRightBorder) : classes.container;

    return (
      <div className={containerClass}>
        <div className={classes.topText}>{label}</div>
        <div className={classes.bottomContainer}>
          <div className={classes.bottomText}>{this.formatter.formatNumber(value, numberFormat)}</div>
          {changeNumber !== undefined && <div className={changeClass}>{changeNumber > 0 ? '+' : ''}{this.formatter.formatNumber(changeNumber, changeIsPercentage && 'p1')}</div>}
        </div>
      </div>
    );
  }

}

registerForIntl(CustomerNumber);

export default withStyles(styles)(CustomerNumber);
