import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  AppBar,
  Menu,
  MenuItem,
  Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Business as AccountIcon,
  Forum as ChitChatIcon,
  Email as EmailIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as CloneIcon,
} from '@material-ui/icons';
import HeaderButton from 'Components/Buttons/HeaderButton';
import MiniButtonSet from 'Components/Buttons/MiniButtonSet';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';

const styles = theme => ({
  appBarOverrides: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    fontSize: 16,
    height: 'unset',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 14px',
    backgroundColor: theme.palette.account,
    color: theme.palette.common.white,
  },
  topLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  topRight: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    marginRight: 32,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 32,
    width: 32,
    marginRight: 16,
    borderRadius: 4,
  },
  accountIcon: {
    fontSize: '48px',
    color: theme.palette.common.white,
  },
  rightIcon: {
    fontSize: '20px',
  },
  smallerFont: {
    fontSize: 17,
  },
  verticalAlign: {
    verticalAlign: 'middle',
  },
  accountName: {
    fontSize: 22,
    lineHeight: 1,
  },
  bottomBar: {
    display: 'flex',
    padding: '12px 16px',
    justifyContent: 'space-between',
    backgroundColor: theme.common.blueGrey,
  },
  marginRight: {
    marginRight: 24,
  },
  glanceSection: {
    display: 'flex',
    width: '100%',
  },
  glanceLabel: {
    color: theme.palette.grey[200],
    fontSize: 10,
  },
  glanceData: {
    color: theme.palette.grey[300],
    fontSize: 12,
  },
  linkColor: {
    color: theme.palette.primary.dark,
  },
});

class AccountHeader extends Component {
  state = {
    optionsOpenedBy: null,
  }

  openToolsMenu = event => {
    this.setState({ optionsOpenedBy: event.currentTarget });
  }

  closeToolsMenu = () => {
    this.setState({ optionsOpenedBy: null });
  }

  onAddAccount = () => {
    this.closeToolsMenu();
    this.props.onAddAccount();
  }

  onSetView = (synthEvent, viewState) => {
    synthEvent.stopPropagation();
    this.props.onSetView(viewState);
  }

  render() {
    const {
      classes,
      label,
      account,
      onEdit,
      onDelete,
      isEditing,
      onClone,
      viewState,
    } = this.props;
    const {
      optionsOpenedBy,
    } = this.state;

    const salesRep = account.salesRep || {};

    return (
      <AppBar position="static" classes={{ root: classes.appBarOverrides }}>
        <div className={classes.topBar}>
          <div className={classes.topLeft}>
            <div className={classes.iconContainer}>
              <AccountIcon className={classes.accountIcon} />
            </div>
            <div className={classes.verticalAlign}>
              <div className={classes.smallerFont}>{label}</div>
              <p variant="h6" className={classes.accountName}>
                {account.name}
              </p>
            </div>
          </div>
          <div className={classes.topRight}>
            <MiniButtonSet>
              <HeaderButton
                onClick={synthEvent => this.onSetView(synthEvent, 'accountDetails')}
                isActive={viewState === 'accountDetails'}
                name="Account"
                icon={<AccountIcon className={classes.rightIcon} />}>
              </HeaderButton>
              <HeaderButton
                onClick={synthEvent => this.onSetView(synthEvent, 'chitChat')}
                isActive={viewState === 'chitChat'}
                name="Chit Chat"
                icon={<ChitChatIcon className={classes.rightIcon} />}>
              </HeaderButton>
              <HeaderButton
                onClick={synthEvent => this.onSetView(synthEvent, 'email')}
                isActive={viewState === 'email'}
                name="Email"
                icon={<EmailIcon className={classes.rightIcon} />}>
              </HeaderButton>
            </MiniButtonSet>
            <MiniButtonSet>
              <HeaderButton onClick={onEdit} isActive={isEditing} name="Edit" icon={<EditIcon className={classes.rightIcon} />}></HeaderButton>
              <HeaderButton onClick={onDelete} name="Delete" icon={<DeleteIcon className={classes.rightIcon} />}></HeaderButton>
              <HeaderButton onClick={onClone} name="Clone" icon={<CloneIcon className={classes.rightIcon} />}></HeaderButton>
              <ArrowDownButton onClick={this.openToolsMenu} />
              <Menu
                id="options-menu"
                anchorEl={optionsOpenedBy}
                open={Boolean(optionsOpenedBy)}
                onClose={() => this.closeToolsMenu()}
              >
                <MenuItem onClick={this.onAddAccount}>Add New Account</MenuItem>
              </Menu>
            </MiniButtonSet>
          </div>
        </div>

        <Paper elevation={1} square className={classes.bottomBar}>
          <div className={classes.glanceSection}>
            <div className={classes.marginRight}>
              <p className={classes.glanceLabel}>Type</p>
              <p className={classes.glanceData}>{account.type}</p>
            </div>
            <div className={classes.marginRight}>
              <p className={classes.glanceLabel}>Email</p>
              <a href={`mailto:${account.email}`}><p className={classNames(classes.glanceData, classes.linkColor)}>{account.email}</p></a>
            </div>
            <div className={classes.marginRight}>
              <p className={classes.glanceLabel}>Phone</p>
              <p className={classes.glanceData}>{account.phone}</p>
            </div>
            <div className={classes.marginRight}>
              <p className={classes.glanceLabel}>Sales Rep</p>
              <p className={classes.glanceData}>{salesRep.name}</p>
            </div>
          </div>

          <div className={classes.divider}></div>

          <div className={classes.glanceSection}>
            <div className={classes.marginRight}>
              <p className={classes.glanceLabel}>Primary Contact</p>
              <p className={classes.glanceData}>
                {account.primaryContact && <a href={`/contacts/${account.primaryContact.id}`}>{account.primaryContact.name}</a>}
              </p>
            </div>
            <div className={classes.marginRight}>
              {/* TODO: multiple phone # dropdown */}
              <p className={classes.glanceLabel}>Phone</p>
              <p className={classes.glanceData}>{account.primaryContact && account.primaryContact.phone}</p>
            </div>
            <div className={classes.marginRight}>
              <p className={classes.glanceLabel}>Email</p>
              <p className={classes.glanceData}>{account.primaryContact && account.primaryContact.email}</p>
            </div>
          </div>
        </Paper>
      </AppBar>
    );
  }
}

AccountHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(AccountHeader);
