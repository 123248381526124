import React, { Component } from 'react';
import Modal from 'Components/Modal';
import QuickContact from './QuickContact';
import { connect } from 'react-redux';
import {
  addContact,
  saveContact,
} from 'actions/contact';

export class QuickContactModal extends Component {
  state = {
    contact: {},
  };

  componentDidMount() {
    this.setState({ contact: { ...this.props.contact } });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contact !== this.props.contact) {
      this.setState({ contact: { ...this.props.contact } });
    }
  }

  handleContactFieldChange = fieldName => value => {
    const contact = {
      ...this.state.contact,
      [fieldName]: value,
    };

    this.setState({ contact });
  }

  onSave = () => {
    const { contact } = this.state;
    const { onSave, addContact, saveContact } = this.props;

    (contact.id
      ? saveContact(contact)
      : addContact(contact)
    ).then(onSave);
  }

  render() {
    const {
      isOpened,
      onCancel,
    } = this.props;

    const {
      contact,
    } = this.state;

    return (
      <Modal
        addTitleBottomBorder={true}
        isOpened={isOpened}
        title={contact.id ? 'Edit Contact' : 'New Contact'}
        onSave={this.onSave}
        onCancel={onCancel}
      >
        <QuickContact
          handleContactFieldChange={this.handleContactFieldChange}
          contact={contact}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => {

  return {

  };
};

const mapDispatchToProps = {
  addContact,
  saveContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickContactModal);
