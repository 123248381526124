import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  IconButton,
} from '@material-ui/core';
import ReportsIcon from 'shared/icons/prints';

const thumbnailWidth = 160;

const styles = theme => ({
  root: {
    display: 'flex',
    color: theme.palette.text.primary,
    margin: 8,
    height: 160,
    width: '100%',
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 24,
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 30,
  },
  bottomRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    color: theme.palette.grey[200],
    overflow: 'hidden',
  },
  thumbnail: {
    display: 'flex',
    width: thumbnailWidth,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  name: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  description: {
    whiteSpace: 'normal',
    marginTop: 8,
    overflow: 'hidden',
    position: 'relative',
    textOverflow: 'ellipsis',
  },
  buttonWrapper: {
    margin: '-12px -12px 0 0',
  },
  button: {
    color: theme.palette.grey[800],
  },
  initials: {
    fontSize: 42,
    position: 'absolute',
    fontWeight: '600',
    color: theme.palette.grey['50'],
  },
});

const ReportCard = ({
  classes,
  report,
  onOpen,
}) => {

  const onOpenReport = event => {
    event && event.stopPropagation();
    onOpen();
  };

  const getInitials = name => {
    if (name) {
      let names = name.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }

      return initials;
    }

    return '';
  };

  return (
    <Card className={classes.root} onClick={onOpenReport}>
      <div className={classes.thumbnail}>
        <div className={classes.initials}>{getInitials(report.name)}</div>
        <CardMedia
          style={{ width: thumbnailWidth, height: thumbnailWidth }}
          image={'/icons/doctype/unknown.svg'}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <p className={classes.name}>{report.name}</p>
          <div className={classes.buttonWrapper}>
            <IconButton className={classes.button}>
              <ReportsIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.bottomRow}>
          <div className={classes.lastUpdate}>
            20 August, 2020 | Chris Kopriva
          </div>
          <div className={classes.description}>
            See {report.name} for a given date range.
          </div>
        </div>
      </div>
    </Card>
  );
};

export default withStyles(styles)(ReportCard);
