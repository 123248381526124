
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { getBorderColor } from 'shared/themes';
import classNames from 'classnames';

const styles = theme => {
  const tableBorderColor = getBorderColor(theme);

  return {
    table: {
      '& tr': {
        borderTop: `1px solid ${tableBorderColor}`,
        borderBottom: `1px solid ${tableBorderColor}`,
      },
    },
  };
};

class Table extends Component {
  render() {
    const {
      classes,
      className,
      children,
      header,
      wrapWithTbody = true,
    } = this.props;

    return (
      <table className={classNames(classes.table, className)}>
        {header && <thead>{header}</thead>}
        {wrapWithTbody ?
          <tbody>
            {children}
          </tbody> :
          children
        }
      </table>
    );
  }
}

export default withStyles(styles)(Table);
