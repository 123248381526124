import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Input,
  InputAdornment,
  FormControl,
  IconButton,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {
  Search,
  ArrowDropDown,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { getLocations } from 'actions/location';
import { getContacts } from 'actions/contact';
import { updateSearch } from 'actions/searchbox';

const styles = theme => ({
  searchBar: {
    [theme.breakpoints.down('small')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    marginLeft: 12,
  },
});

export const CurrentLocation = {
  locations: 'locations',
  contacts: 'contacts',
  unknown: 'unknown',
};

class SearchBoxNoStyle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchPlaceHolder: 'Search',
      currentLocation: CurrentLocation.unknown,
    };
  }

  componentDidMount() {
    this.updateSearchPlaceHolder(this.props.location);
  }

  componentWillReceiveProps(nextProps) {
    this.updateSearchPlaceHolder(nextProps.location);
  }

  updateSearchPlaceHolder(location) {
    const settings = SearchBox.getSearchSettings(location);

    if (settings.currentLocation !== this.state.currentLocation) {
      this.setState(settings);
    }
  }

  onEnterPress = e => {
    if (e.key === 'Enter') {
      if (this.state.currentLocation === CurrentLocation.locations) {
        this.props.getLocations({ filter: this.state.searchText });
      }
      else if (this.state.currentLocation === CurrentLocation.contacts) {
      }
      //Wire in other searches here!
    }
  }

  searchChange = event => {
    this.props.updateSearch(event.target.value);
  }

  static getLocation(location) {
    if (!location) {
      return CurrentLocation.unknown;
    }
    if (location.pathname === '/admin/company/locations') {
      return CurrentLocation.locations;
    }

    if (location.pathname === '/contacts') {
      return CurrentLocation.contacts;
    }

    return CurrentLocation.unknown;
  }

  static getSearchSettings(location) {
    const currentLocation = SearchBox.getLocation(location);
    const settings = {
      currentLocation,
      searchPlaceHolder: 'Search',
    };

    if (currentLocation === CurrentLocation.locations) {
      settings.searchPlaceHolder = 'Search Locations';
    }
    else if (currentLocation === CurrentLocation.contacts) {
      settings.searchPlaceHolder = 'Search Contacts';
    }

    return settings;
  }

  render() {
    const { classes, searchText } = this.props;
    const { searchPlaceHolder } = this.state;

    return (
      <FormControl className={classes.searchBar}>
        <Input
          id="input-with-icon-adornment"
          onChange={this.searchChange}
          onKeyPress={this.onEnterPress}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="AdvancedSearch">
                < ArrowDropDown />
              </IconButton>
            </InputAdornment>
          }
          value={searchText}
          placeholder={searchPlaceHolder}
        />
      </FormControl>
    );
  }
}

const mapStateToProps = state => {
  const {
    searchReducer: {
      searchText,
    },
  } = state;

  return {
    searchText,
  };
};

const mapDispatchToProps = {
  getLocations,
  getContacts,
  updateSearch,
};

export const SearchBox = withStyles(styles)(SearchBoxNoStyle);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBox));
