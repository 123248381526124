import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from 'Components/Table';
import { provideIntlService } from '@progress/kendo-react-intl';
import classNames from 'classnames';
import SimpleModal from 'Components/SimpleModal';
import { connect } from 'react-redux';
import { getBorderColor } from 'shared/themes';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const styles = theme => {
  const tableBorderColor = getBorderColor(theme);

  return {
    container: {
      display: 'flex',
      width: '100%',
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 15,
    },
    commonTable: {
      lineHeight: '33px',
      '& td, & th': {
        paddingLeft: 6,
      },
      '& th': {
        color: theme.palette.grey[300],
      },
      '& td': {
        borderLeft: `1px solid ${tableBorderColor}`,
      },
      border: `1px solid ${tableBorderColor}`,
    },
    table: {
      color: theme.palette.primary.main,
      '& thead': {
        backgroundColor: theme.palette.grey[50],
      },
      '& td': {
        borderRight: `1px solid ${tableBorderColor}`,
      },
      border: `1px solid ${tableBorderColor}`,
    },
    totalsRow: {
      backgroundColor: theme.palette.grey[50],
    },
    leftColumn: {
      width: '70%',
      padding: 15,
    },
    rightColumn: {
      width: '30%',
      padding: 15,
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'space-between',
    },
    green: {
      backgroundColor: lighten(theme.common.green, 0.9),
    },
    pink: {
      backgroundColor: lighten(theme.common.red, 0.9),
    },
    pinkLighter: {
      backgroundColor: lighten(theme.common.red, 0.95),
    },
    greyText: {
      color: theme.palette.grey[300],
    },
    grey: {
      backgroundColor: theme.palette.grey[50],
    },
    greyLighter: {
      backgroundColor: lighten(theme.palette.grey[50], 0.5),
    },
  };
};

class FinancialDetailsModal extends Component {
  formatter = provideIntlService(this);

  DisplayRow = data => {
    const label = data ? data.revenueType : '';

    if (data) {
      return (<tr key={data.id}>
        <th scope="row">{label}</th>
        <td>{this.formatter.formatNumber(data.subTotal, 'c')}</td>
        <td>{this.formatter.formatNumber(data.serviceCharge, 'c')}</td>
        <td>{this.formatter.formatNumber(data.gratuity, 'c')}</td>
        <td>{this.formatter.formatNumber(data.taxTotal, 'c')}</td>
        <td>{this.formatter.formatNumber(data.total, 'c')}</td>
      </tr>
      );
    }

    return (<tr>
      <th scope="row">{label}</th>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>);
  };

  TotalRow = (data, label, className) => {
    return (<tr className={className}>
      <th scope="row">{label}</th>
      <td>{this.formatter.formatNumber(data.subTotal, 'c')}</td>
      <td>{this.formatter.formatNumber(data.serviceCharge, 'c')}</td>
      <td>{this.formatter.formatNumber(data.gratuity, 'c')}</td>
      <td>{this.formatter.formatNumber(data.taxTotal, 'c')}</td>
      <td>{this.formatter.formatNumber(data.total, 'c')}</td>
    </tr>
    );
  };

  formatFields = () => {
    const { storeBooking } = this.props;

    if (!this.formatter) {
      return {};
    }

    if (!storeBooking.subTotal) {
      return {};
    }

    return {
      discount: this.formatter.formatNumber(storeBooking.discount, 'c'),
      paid: this.formatter.formatNumber(storeBooking.paid, 'c'),
      balance: this.formatter.formatNumber(storeBooking.balance, 'c'),
      minimum: this.formatter.formatNumber(storeBooking.minimum, 'c'),
      remaining: storeBooking.remaining > 0 ? this.formatter.formatNumber(storeBooking.remaining, 'c') : 'None',
    };
  }

  render() {
    const { classes, onClose, isOpened, storeBooking } = this.props;
    const {
      discount,
      paid,
      balance,
      minimum,
      remaining,
    } = this.formatFields();

    return (<SimpleModal
      isOpened={isOpened}
      onClose={onClose}
      title="Financial Details"
    >
      <div className={classes.container}>
        <div className={classes.leftColumn}>
          <Table className={classNames(classes.commonTable, classes.table)} header={
            <tr>
              <th>{'\u00A0'}</th>
              <th scope="col">Subtotal</th>
              <th scope="col">Serv Chg</th>
              <th scope="col">Gratuity</th>
              <th scope="col">Taxes</th>
              <th scope="col">Total</th>
            </tr>
          }>
            {storeBooking.bookingRevenueTypeDetails && storeBooking.bookingRevenueTypeDetails.map(detail =>
              this.DisplayRow(detail)
            )}

            {this.TotalRow(storeBooking, 'Subtotal', classes.totalsRow)}
            <tr>
              <th scope="row">Discount</th>
              <td>{discount}</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>{discount}</td>
            </tr>
            {this.TotalRow(storeBooking, 'Total', classes.totalsRow)}
          </Table>
        </div>
        <div className={classes.rightColumn}>
          <div>
            <Table className={classNames(classes.commonTable, classes.table)} header={
              <tr>
                <th scope="col">Paid</th>
                <th scope="col">Balance</th>
              </tr>
            }>
              <tr className={classes.green}>
                <td>{paid}</td>
                <td>{balance}</td>
              </tr>
            </Table>
          </div>
          <div>
            <Table className={classes.commonTable}>
              <tr className={storeBooking.remaining <= 0 ? classes.grey : classes.pink}>
                <th scope="row">Minimum</th>
                <td className={classes.greyText}>{minimum}</td>
              </tr>
              <tr className={storeBooking.remaining <= 0 ? classes.greyLighter : classes.pinkLighter}>
                <th scope="row">Remaining</th>
                <td className={classes.greyText}>{remaining}</td>
              </tr>
            </Table>
          </div>
        </div>
      </div>
    </SimpleModal>);
  }
}
const mapStateToProps = state => {
  const {
    booking: {
      manager: {
        storeBooking,
      },
    },
  } = state;

  return {
    storeBooking,
  };
};

export default withStyles(styles)(connect(mapStateToProps, undefined)(FinancialDetailsModal));
