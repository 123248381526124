import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTitle,
} from '@progress/kendo-react-charts';

const styles = theme => ({
  graphContainer: {
    height: '100%',
  },
  totalContainer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'space-between',
  },
  totalDescription: {
    color: theme.palette.grey[200],
    fontSize: '12px',
  },
});

class LineGraphWidget extends React.Component {
  constructor(props) {
    super(props);

    this.chartContainerRef = React.createRef();
    this.chartRef = React.createRef();
  }

  render() {
    const {
      classes,
      data,
      compare,
      title,
      graphcolor,
      compareColor,
      height = '100%',
      width = '100%',
    } = this.props;

    return (
      <div ref={this.chartContainerRef} className={classes.graphContainer}>
        <Chart ref={this.chartRef} style={{ height, width }}>
          {title ? <ChartTitle text={title} /> : null}
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartValueAxis>
          <ChartSeries>
            <ChartSeriesItem type="area" data={data} line={{ style: 'normal' }} markers={{ visible: false }} color={graphcolor} opacity={0.1} />
            <ChartSeriesItem type="line" data={data} line={{ style: 'normal' }} markers={{ visible: false }} color={graphcolor} opacity={1} />
            {
              compare && <ChartSeriesItem type="line" data={compare} line={{ style: 'normal' }} markers={{ visible: false }} color={compareColor} opacity={0.3} />
            }
          </ChartSeries>
        </Chart>
      </div>
    );
  }
}

export default withStyles(styles)(LineGraphWidget);
