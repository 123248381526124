import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Table from 'Components/Table';
import { provideIntlService } from '@progress/kendo-react-intl';
import SimpleModal from 'Components/SimpleModal';
import TextField from 'Components/TextField';
import { getBorderColor } from 'shared/themes';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import AddIcon from '@material-ui/icons/Add';
import HighlightButton from 'Components/Buttons/HighlightButton';
import AddEditDepositModal from './AddEditDepositModal';
import AddEditPaymentModal from 'Components/AddEditPaymentModal';
import { CurrencyField } from 'Components/currencyInput';
import Deposit from './Deposit';
import {
  getDeposits,
  deleteDeposit,
  addPayment,
  updateDeposit,
} from 'actions/booking';
import moment from 'moment';

const styles = theme => {
  const tableBorderColor = getBorderColor(theme);

  return {
    top: {
      display: 'flex',
      flexDirection: 'row',
      padding: 15,
      whiteSpace: 'nowrap',
    },
    width60: {
      width: '60%',
    },
    width10: {
      width: '10%',
    },
    width30: {
      width: '30%',
    },
    infoTable: {
      lineHeight: '33px',
      color: theme.palette.primary.main,
      '& thead': {
        backgroundColor: theme.palette.grey[50],
      },
      '& th': {
        border: `1px solid ${tableBorderColor}`,
        color: theme.palette.grey[300],
        paddingLeft: 6,
        paddingRight: 30,
        width: 100,
      },
      '& td': {
        border: `1px solid ${tableBorderColor}`,
        paddingLeft: 6,
        paddingRight: 6,
      },
    },
    iconButton: {
      display: 'flex',
      flexFlow: 'row-reverse',
      paddingRight: 15,
    },
    depositsContainer: {
      display: 'flex',
      width: '100%',
      padding: 15,
      flexDirection: 'column',
      height: '200',
    },
    depositsTable: {
      '& td, & th': {
        paddingLeft: 6,
        paddingRight: 6,
      },
      '& td': {
        border: `1px solid ${tableBorderColor}`,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& th:last-child': {
        width: 120,
        textAlign: 'center',
      },
      '& th:nth-child(2), & th:nth-child(6), & td:nth-child(2), & td:nth-child(6)': {
        textAlign: 'right',
      },
      '& td:nth-child(3)': {
        maxWidth: 300,
      },
      '& td:last-child': {
        whiteSpace: 'nowrap',
        paddingLeft: 25,
      },
      '& thead': {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[300],
        whiteSpace: 'nowrap',
        lineHeight: '33px',
      },
      border: `1px solid ${tableBorderColor}`,
      color: theme.palette.primary.main,
    },
    bottom: {
      display: 'flex',
      flexFlow: 'row-reverse',
      whiteSpace: 'nowrap',
      padding: '0px 15px 30px 15px',
    },
    green: {
      backgroundColor: lighten(theme.common.green, 0.9),
    },
    pinkLighter: {
      backgroundColor: lighten(theme.common.red, 0.95),
    },
    greyLighter: {
      backgroundColor: lighten(theme.palette.grey[50], 0.5),
    },
  };
};

class DepositsModal extends Component {
  state = {
    isLoading: true,
    deposits: [],
    financialDetails: undefined,
    isEditModalOpen: false,
    deposit: undefined,
    isPaymentModalOpen: false,
  };

  componentDidMount() {
    this.formatter = provideIntlService(this);
    this.refreshDeposits();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.refreshDeposits();
    }
    if (prevProps.deposits !== this.props.deposits) {
      this.refreshDeposits();
    }
  }

  refreshDeposits = () => {
    const { id, getDeposits } = this.props;
    const { deposits: oldDeposits, isLoading } = this.state;

    getDeposits && getDeposits(id).then(deposits => {
      this.setState({ deposits });
      if (isLoading === true) {
        this.setState({ isLoading: false });

        return;
      }

      // open payment modal if a paid deposit is created/updated
      let newPaidDeposit;

      deposits.forEach(d => {
        const matchingDeposit = oldDeposits.find(od => od.id === d.id);

        if (!matchingDeposit && d.isPaid) {
          newPaidDeposit = d;
          this.setState({ paidDeposit: newPaidDeposit });
        }
      });
      if (newPaidDeposit || this.isUpdatedPaidDeposit()) {
        this.openPaymentModal();
      };
    });
  }

  isUpdatedPaidDeposit = () => {
    const { depositCurrent, deposits } = this.state;

    if (!depositCurrent || !deposits) {
      return false;
    }

    let updatedDeposit;

    if (depositCurrent && deposits) {
      // for editing a paid deposit
      updatedDeposit = deposits.find(d => d.id === depositCurrent.id);
      if (updatedDeposit && !depositCurrent.isPaid && updatedDeposit.isPaid) {
        this.setState({ paidDeposit: updatedDeposit });

        return true;
      }
    }

    return false;
  }

  onAddDepositButtonClicked = () => {
    const newDeposit = {
      id: undefined,
      isPaid: false,
    };

    this.setState({
      isEditModalOpen: true,
      deposit: newDeposit,
      depositCurrent: newDeposit,
    });
  }

  openPaymentModal = () => {
    const { paidDeposit } = this.state;
    const { user: { profile } } = this.props;
    const now = new Date();
    const newPayment = {
      dateApplied: now,
      receivedBy: profile.name,
      isDeposit: true,
      isDepositDisabled: true, // don't allow user to uncheck deposit checkbox
      amount: paidDeposit.dueAmount,
    };

    this.setState({ isPaymentModalOpen: true, payment: newPayment });
  }

  closePaymentModal = () => {
    this.setState({ isPaymentModalOpen: false, depositCurrent: undefined, paidDeposit: undefined });
  }

  onSavePaymentModal = payment => {
    const { paidDeposit } = this.state;
    const { id, addPayment, updateDeposit } = this.props;

    addPayment(id, payment)
      .then(addedPayment => {
        paidDeposit.paymentId = addedPayment.id;
        updateDeposit(id, paidDeposit)
          .then(() => this.setState({ paidDeposit: undefined }))
          .then(this.refreshDeposits);
      });
    this.closePaymentModal();
  }

  onCancelPaymentModal = () => {
    const { paidDeposit } = this.state;
    const { id, updateDeposit } = this.props;

    if (paidDeposit) { // paid deposit with payment cancelled
      paidDeposit.isPaid = false;
      updateDeposit(id, paidDeposit)
        .then(this.refreshDeposits);
    }
    this.closePaymentModal();
  }

  closeEditModal = () => {
    this.setState({ isEditModalOpen: false, deposit: undefined });
  }

  onEdit = deposit => {
    this.setState({ isEditModalOpen: true, deposit, depositCurrent: { ...deposit } });
  }

  onDelete = depositId => {
    this.props.deleteDeposit && this.props.deleteDeposit(this.props.id, depositId).then(this.refreshDeposits);
  }

  getUpcomingDeposit = () => {
    const { deposits } = this.state;

    if (deposits && deposits.length > 0) {
      const unpaidDeposits = deposits.filter(d => !d.isPaid);

      return unpaidDeposits && unpaidDeposits.length > 0 ? unpaidDeposits[0] : undefined;
    }

    return undefined;
  }

  getRemainingBalanceDue = () => {
    const { deposits, financialDetails } = this.state;

    if (!deposits || !financialDetails) {
      return 0;
    }

    let remainingBalanceDue = financialDetails.balance;

    deposits.forEach(d => {
      remainingBalanceDue = !d.isPaid ? remainingBalanceDue - d.dueAmount : remainingBalanceDue;
    });

    return remainingBalanceDue;
  }

  render() {
    const { classes, onClose, isOpened, id } = this.props;
    const { deposits, isEditModalOpen, deposit, financialDetails, isPaymentModalOpen, payment } = this.state;
    const upcomingDeposit = this.getUpcomingDeposit();
    const remainingBalanceDue = this.getRemainingBalanceDue();

    return (<SimpleModal
      isOpened={isOpened}
      onClose={onClose}
      title="Deposits"
      dimensions={{ width: 'unset', height: '600px', maxHeight: '775px' }}
    >
      <>
        <div className={classes.top}>
          <div className={classes.width60}>
            <TextField
              label="Due Date"
              fieldName="upcomingDueDate"
              disabled={true}
              value={upcomingDeposit && upcomingDeposit.dueDate ? moment(upcomingDeposit.dueDate).format('MM/DD/YYYY') : ''}
            />
            <CurrencyField
              label="Upcoming Deposit"
              fieldName="upcomingDueAmount"
              disabled={true}
              value={upcomingDeposit && upcomingDeposit.dueAmount}
            />
          </div>
          <div className={classes.width10}></div>
          <div className={classes.width30}>
            {financialDetails &&
            <Table
              className={classes.infoTable}
              header={
                <tr>
                  <th scope="col">Paid</th>
                  <th scope="col">Balance</th>
                </tr>
              }>
              <tr className={classes.green}>
                <td>{provideIntlService(this).formatNumber(financialDetails.paid, 'c')}</td>
                <td>{provideIntlService(this).formatNumber(financialDetails.balance, 'c')}</td>
              </tr>
            </Table>}
          </div>
        </div>
        <div className={classes.iconButton}>
          <label htmlFor="add-deposit">
            <HighlightButton
              component="span"
              aria-label={`Add Deposit`}
              onClick={this.onAddDepositButtonClicked}
            >
              <AddIcon />
            </HighlightButton>
          </label>
        </div>
        <div className={classes.depositsContainer}>
          <Table className={classes.depositsTable} header={
            <tr>
              <th scope="col">Due Date</th>
              <th scope="col" style={{ textAlign: 'right' }}>Due Amount</th>
              <th scope="col">Comment</th>
              <th scope="col" style={{ paddingLeft: 24 }}>Paid</th>
              <th scope="col">Paid Date</th>
              <th scope="col" style={{ textAlign: 'right' }}>Paid Amount</th>
              <th scope="col">Action</th>
            </tr>
          }>
            {deposits && deposits.map(deposit =>
              <Deposit id={id} deposit={deposit} key={deposit.id} onEdit={this.onEdit} onDelete={this.onDelete} formatter={this.formatter} />
            )}
            {deposits && !deposits.length &&
            <tr height="100px"><td colSpan="7" text-align="center">No Deposits</td></tr>}
          </Table>
        </div>
        <div className={classes.bottom}>
          <div className={classes.width30}>
            <Table className={classes.infoTable}>
              <tr className={remainingBalanceDue > 0 ? classes.pinkLighter : classes.greyLighter}>
                <th scope="row">Remaining Balance Due</th>
                <td>{provideIntlService(this).formatNumber(remainingBalanceDue, 'c')}</td>
              </tr>
            </Table>
          </div>
        </div>
        {isEditModalOpen && <AddEditDepositModal id={id} deposit={deposit} onRefresh={this.refreshDeposits} onCancel={this.closeEditModal} />}
        {isPaymentModalOpen && <AddEditPaymentModal onSave={this.onSavePaymentModal} onCancel={this.onCancelPaymentModal} payment={payment} />}
      </>
    </SimpleModal>);
  }
}

const mapStateToProps = state => {
  return {
    user: state.oidc.user,
  };
};

const mapDispatchToProps = {
  getDeposits,
  deleteDeposit,
  addPayment,
  updateDeposit,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DepositsModal));
