import React, { Component } from 'react';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Event as TaskIcon,
  Beenhere as ActionIcon,
  ChevronRight,
  ExpandMore,
  Warning,
} from '@material-ui/icons';
import { Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import SimpleDialog from 'Components/SimpleDialog';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';
import moment from 'moment';
import ICalendarEvent from 'models/ICalendarEvent';
import CalendarEventType from 'models/CalendarEventType';

interface ITheme extends  Theme {
  common: any,
}

const styles = createStyles((theme: ITheme) => ({
  calendarEvent: {
    display: 'flex',
    padding: '8px 8px 8px 0',
    marginBottom: 8,
    minHeight: 68,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  top: {
    display: 'flex',
  },
  left: {
    display: 'flex',
    width: 30,
    alignSelf: 'flex-start',
  },
  right: {
    display: 'flex',
    width: 30,
    alignSelf: 'flex-start',
  },
  expando: {
    width: 35,
    display: 'flex',
    flexDirection: 'column',
  },
  expandoIcon: {
    fontSize: 20,
    cursor: 'pointer',
  },
  titleArrow: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 8,
  },
  title: {
    color: theme.palette.action.active,
    whiteSpace: 'normal',
    paddingRight: 8,
  },
  activitySmall: {
    fontSize: 12,
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    padding: 6,
    marginTop: 2,
  },
  bottom: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: '0 10px',
    whiteSpace: 'normal',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: 18,
  },
  rightIcon: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  time: {
    fontSize: 12,
    color: theme.palette.grey[200],
  },
  date: {
    display: 'flex',
    marginBottom: 2,
  },
  red: {
    backgroundColor: theme.common.red,
  },
  blue: {
    backgroundColor: theme.palette.action.active,
  },
  redForeground: {
    color: theme.common.red,
  },
  overdue: {
    color: theme.common.yellow,
    padding: 6,
  },
}));

interface IProps extends WithStyles {
  calendarEvent: ICalendarEvent,
  onEdit: Function,
  onComplete: Function,
  onDelete: Function
}

interface IState {
  isExpanded: boolean,
  optionsMenu: any
}

class Activity extends Component<IProps, IState> {
  state = {
    isExpanded: false,
    optionsMenu: null as any,
  }

  confirmDeleteDialog = React.createRef() as any;

  openOptionsMenu = (event: any) => {
    this.setState({ optionsMenu: event.currentTarget });
  };

  closeOptionsMenu = () => {
    this.setState({ optionsMenu: null });
  };

  onDeleteActivity = () => {
    const { calendarEvent, onDelete } = this.props;

    this.closeOptionsMenu();
    this.confirmDeleteDialog.current.open()
      .then(() => {
        onDelete && onDelete(calendarEvent);
      })
      .catch(() => {/* noop */});
  }

  onEditActivity = () => {
    const { calendarEvent, onEdit } = this.props;

    this.closeOptionsMenu();
    onEdit && onEdit(calendarEvent);
  }

  onCompleteActivity = () => {
    const { calendarEvent, onComplete } = this.props;

    this.closeOptionsMenu();
    onComplete && onComplete(calendarEvent);
  }

  buildDateLine = () => {
    const { calendarEvent } = this.props;

    let timeAsString;

    if (calendarEvent.calendarEventType === CalendarEventType.Task) {
      timeAsString  = calendarEvent.startDateTime ? `${moment(calendarEvent.startDateTime).format('ll')} ${moment(calendarEvent.startDateTime).format('LT')}` : timeAsString;
      timeAsString = calendarEvent.endDateTime ? timeAsString + ` - ${moment(calendarEvent.endDateTime).format('LT')}` : timeAsString;
    } else {
      if (calendarEvent.completedDateTime) {
        timeAsString  = `${moment(calendarEvent.completedDateTime).format('ll')} ${moment(calendarEvent.completedDateTime).format('LT')}`;
      } else {
        timeAsString  = `${moment(calendarEvent.createdUtcDate).format('ll')} ${moment(calendarEvent.createdUtcDate).format('LT')}`;
      }
    }

    return timeAsString;
  }

  getColor = () => {
    const { calendarEvent } = this.props;

    return calendarEvent.calendarEventType === CalendarEventType.Task ? 'blue' : 'red';
  }

  getIcon = () => {
    const { classes, calendarEvent } = this.props;

    return calendarEvent.calendarEventType === CalendarEventType.Task
      ? <TaskIcon classes={{ root: classes.icon }} />
      : <ActionIcon classes={{ root: classes.icon }} />;
  }

  expandCollapse = () => {
    const { isExpanded } = this.state;

    this.setState({ isExpanded: !isExpanded });
  }

  isOverdue(dateStr: string, now: any) {
    const date = moment(dateStr);
    const diff = date.diff(now, 'minutes');

    return diff < 0 ? true : false;
  }

  render() {
    const { classes, calendarEvent } = this.props;
    const { isExpanded, optionsMenu } = this.state;
    const dateLine = this.buildDateLine();
    const now = moment();
    const isOverdue = calendarEvent.calendarEventType === CalendarEventType.Task && !calendarEvent.completedDateTime && this.isOverdue(calendarEvent.endDateTime, now);
    const color = this.getColor();
    const style = isOverdue ? classes.redForeground : undefined;

    return (
      <div className={classNames(classes.calendarEvent)}>
        <div className={classes.expando}>
          {isExpanded ?
            <ExpandMore classes={{ root: classes.expandoIcon }} onClick={this.expandCollapse} />
            :
            <ChevronRight classes={{ root: classes.expandoIcon }} onClick={this.expandCollapse} />
          }
          {isOverdue &&
          <div className={classes.overdue}>
            <Warning />
          </div>}
        </div>
        <div className={classNames(classes.flexColumn)}>

          <div className={classNames(classes.top)}>
            <div className={classes.left}>
              <div className={classNames(classes.iconBox, classes[color])}>
                {this.getIcon()}
              </div>
            </div>
            <div className={classes.titleArrow}>
              <p className={classNames(classes.title, style)}>{calendarEvent.name}</p>
            </div>
            <div className={classes.right}>
              <div className={classes.rightIcons}>
                <ArrowDownButton
                  onClick={this.openOptionsMenu}
                  aria-label="Activity options"
                  aria-owns="calendarEvent-menu"
                  aria-haspopup="true"
                >
                </ArrowDownButton>
                <Menu
                  id="calendarEvent-menu"
                  anchorEl={optionsMenu}
                  open={Boolean(optionsMenu)}
                  onClose={this.closeOptionsMenu}
                >
                  <MenuItem onClick={this.onEditActivity}>Edit</MenuItem>
                  {!calendarEvent.completedDateTime &&
                    <MenuItem onClick={this.onCompleteActivity}>Mark As Completed</MenuItem>
                  }
                  <MenuItem onClick={this.onDeleteActivity}>Delete</MenuItem>
                </Menu>
              </div>
            </div>
          </div>

          <div className={classes.bottom}>
            <div className={classNames(classes.left, classes.time)}>Time</div>
            <div className={classes.content}>
              <p className={classNames(classes.date, classes.activitySmall)}>
                {dateLine || ''}
              </p>
              {isExpanded &&
                <p
                  dangerouslySetInnerHTML={{ __html: calendarEvent.description }}
                >
                </p>
              }
            </div>
          </div>

        </div>
        <SimpleDialog
          message="Are you sure you want to delete this activity?"
          innerRef={this.confirmDeleteDialog}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Activity);
