import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import ModifierTemplateManagement from './ModifierTemplateManagement';
import { Route } from 'react-router-dom';

class ModifierTemplateManagementContainer extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/admin/modifier-templates" component={ModifierTemplateManagement} />
      </Switch>
    );
  }
}

export default withRouter(ModifierTemplateManagementContainer);
