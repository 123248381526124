import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SectionHeader from 'Components/SectionHeader';
import {
  Input,
  InputAdornment,
} from '@material-ui/core';
import Checkbox from 'Components/Checkbox';
import {
  Search,
} from '@material-ui/icons';
import SimpleDialog from 'Components/SimpleDialog';

const styles = theme => ({
  searchInput: {
    paddingLeft: 15,
  },
  searchIcon: {
    color: theme.palette.action.active,
  },
  locationsContainer: {
    fontSize: 18,
  },
  locationHeader: {
    color: theme.palette.grey[700],
  },
  labelClass: {
    color: theme.palette.grey[700],
    fontSize: 18,
  },
  locationList: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    height: 226,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& div': {
      borderTop: `1px solid ${theme.palette.grey[50]}`,
    },
  },

});
const dimensions = {
  width: 437,
  height: 466,
};

const initalState = {
  locations: [],
  searchText: '',
  totalLocations: 0,
  allLocationsChecked: false,
  checkedMap: {},
  locationsChecked: [],//iniitally checked locations
};

class ApplyToLocation extends Component {
  state = initalState;

  confirmApplyDialog = React.createRef();

  componentDidMount() {
    const { isOpened } = this.props;

    if (isOpened) {
      //this will probably never actually be called because the component starts with isOpened=false
      //but it's helpful for story book because the component starts as opened
      this.cleanLoad();
    }

  }

  componentDidUpdate = prevProps => {
    const { isOpened } = this.props;

    if (isOpened && !prevProps.isOpened) {
      //opening
      this.cleanLoad();
    }

    if (!isOpened && prevProps.isOpened) {
      //closing
      this.setState(initalState);
    }
  }

  cleanLoad = () => {
    const {
      getLocations,
    } = this.props;

    this.setState({
      locations: getLocations(),
    });
  }

  onEnterPress = e => {
    if (e.key !== 'Enter') {
      return;
    }

    const {
      allLocationsChecked,
      checkedMap: oldCheckedMap,
    } = this.state;

    const locations = this.props.getLocations();

    this.setState({
      locations,
    });

    if (allLocationsChecked) {
      return;
    }
    //so if you apply a filter and an item was checked before filter and is not included in the new filtered list
    //this will uncheck it
    const checkedMap = {};

    locations.forEach(location => {
      if (oldCheckedMap[location.id] === true || oldCheckedMap[location.id] === false) {
        checkedMap[location.id] = oldCheckedMap[location.id];
      }
    });

    this.setState({ checkedMap });

  }

  searchChange = event => {
    this.setState({ searchText: event.target.value });
  }

  onCancel = () => {
    this.props.onClose(false);
  }

  onSave = () => {
    const {
      locationParams: { filter },
      allLocationsChecked,
      checkedMap,
    } = this.state;

    const dto = {
      filter,
      allLocationsChecked,
      LocationIdsChecked: allLocationsChecked ? [] : Object.keys(checkedMap),
    };

    this.confirmApplyDialog.current.open().then(() => {
      this.props.onClose(dto);
    });

  }

  onLocationsScroll = e => {
    const element = e.target;
    const position = element.scrollHeight - element.scrollTop;

    if (position === element.clientHeight) {
      this.loadMore();
    }
  }

  loadMore = () => {
    const {
      totalLocations,
      locationParams: oldParams,
    } = this.state;
    const skip = oldParams.skip + oldParams.pageSize;

    if (skip >= totalLocations) {
      return; //you loaded the last item already
    }

    const locationParams = {
      ...oldParams,
      skip,
    };

    this.props.getLocations(locationParams).then(result => {
      const oldLocations = this.state.locations;
      const locations = [
        ...oldLocations,
        ...result.data,
      ];

      this.setState({ locations, locationParams });
    });
  }

  toggleAllChecked = event => {
    if (!event.target.checked) {
      this.setState({ locationsChecked: [] });
    }
    this.setState({ allLocationsChecked: event.target.checked, checkedMap: {} });
  }

  toggleLocation = clickedLocation => event => {

    if (!event.target.checked && this.state.allLocationsChecked) {
      const checkedMap = {};

      this.state.locations.forEach(location => {
        checkedMap[location.id] = location === clickedLocation ? false : true;
      });
      this.setState({ allLocationsChecked: false, checkedMap });

      return;
    }
    const checkedMap = {
      ...this.state.checkedMap,
      [clickedLocation.id]: event.target.checked,
    };

    this.setState({ checkedMap });
  }

  getCheckedStatus = location => {
    const {
      allLocationsChecked,
      checkedMap,
      locationsChecked,
    } = this.state;

    if (allLocationsChecked === true) {
      return true;
    }

    if (checkedMap[location.id]) {
      return true;
    }

    if (checkedMap[location.id] === false) {
      return false;
    }

    if (!locationsChecked) {
      return false;
    }
    if (locationsChecked.indexOf(location.id) !== -1) {
      return true;
    }

    return false;
  }

  render() {
    const {
      isOpened,
      classes,
      theme,
    } = this.props;

    const {
      locations,
      searchText,
      allLocationsChecked,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        dimensions={dimensions}
        onCancel={this.onCancel}
        onSave={this.onSave}
        title="Apply to Locations"
        addTitleBottomBorder={true}
      >
        <SectionHeader>
          Items
        </SectionHeader>

        <Input
          className={classes.searchInput}
          id="search-locations"
          onChange={this.searchChange}
          onKeyPress={this.onEnterPress}
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          value={searchText}
          placeholder="Search Locations"
        />
        <div className={classes.locationsContainer}>
          <div className={classes.locationHeader}>
            <Checkbox
              checkboxColor={theme.palette.grey[700]}
              labelClass={classes.labelClass}
              label="Location"
              checked={allLocationsChecked}
              onChange={this.toggleAllChecked}
            />
          </div>
          <div
            className={classes.locationList}
            onScroll={this.onLocationsScroll}
          >
            {locations.map(location => (
              <div key={location.id}>
                <Checkbox
                  labelClass={classes.labelClass}
                  label={location.name}
                  checked={this.getCheckedStatus(location)}
                  onChange={this.toggleLocation(location)}
                />
              </div>
            ))}
          </div>
        </div>
        <SimpleDialog
          message="Are you sure you apply the enterprise pick list to selected locations?"
          innerRef={this.confirmApplyDialog}
        />
      </Modal>);
  }
}

export default withStyles(styles, { withTheme: true })(ApplyToLocation);
