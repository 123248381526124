import React, { Component } from 'react';
import * as moment from 'moment';
import { withStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { connect } from 'react-redux';

const styles = theme => ({
  h1: {
    fontSize: '33px',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '33px',
    fontWeight: '200',
  },
  blue: {
    color: theme.palette.primary.dark,
  },
  h3: {
    fontSize: '20px',
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  tableText: {
    fonstSize: '12px',
    fontWeight: '100',
    textTransform: 'uppercase',
    color: theme.palette.grey['200'],
  },
  firstCell: {
    paddingLeft: '0',
  },
  cell: {
    border: 'none',
  },
  leftBorder: {
    border: 'none',
    borderLeft: `1px solid ${theme.palette.grey['50']}`,
  },
});

class GreetingWidget extends Component {
  calculateUser = () => {
    const { user: { profile } } = this.props;

    if (profile.given_name) {
      return profile.given_name;
    }

    return profile.email || 'none';

  }

  render() {
    const { taskCount = 0, classes } = this.props;
    const now = moment();

    return (
      <div>
        <h1 className={classes.h1}>Hello, {this.calculateUser()}!</h1>
        <h2 className={classes.h2}>
          <span className={classes.blue}>{now.format('M/D/YYYY')}</span> | <span className={classes.blue}>{now.format('h:mm a')}</span>
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={`${classes.tableText} ${classes.cell} ${classes.firstCell}`}>
                <h3 className={classes.h3}>{taskCount}</h3> Tasks
              </TableCell>
              <TableCell className={`${classes.tableText} ${classes.leftBorder}`}>
                <h3 className={classes.h3}>16</h3> New Leads
              </TableCell>
              <TableCell className={`${classes.tableText} ${classes.leftBorder}`}>
                <h3 className={classes.h3}>42</h3> Appointments
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.oidc.user,
});

export default connect(mapStateToProps)(withStyles(styles)(GreetingWidget));
