import { callApi } from 'shared/CallApi';

import {
  MENU_ITEMS_REQUEST_BEGIN,
  MENU_ITEMS_REQUEST_END,
  RECEIVE_MENU_ITEMS,
  CREATE_MENU_ITEM_SUCCESS,
  UPDATE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_SUCCESS,
  CLEAR_SELECTED_MENU_ITEM,
  MENU_ITEM_SELECTED,
  TAGS_REQUEST_BEGIN,
  TAGS_REQUEST_END,
  RECEIVE_TAGS,
  ALL_ITEMS_TOGGLED,
  MENU_ITEM_SALES_DATA_RECEIVED,
} from './constants';

export const getAllMenuItems = () => dispatch => {
  dispatch(fetchMenuItemsBegin());

  return getAllMenuItemsApi(dispatch).then(result => {
    dispatch(fetchMenuItemsSuccess(result, false));

    return result;
  }).finally(() => dispatch(fetchMenuItemsEnded()));
};

export const getMenuItems = (params = '', append = false) =>
  (dispatch, getState) => {
    dispatch(fetchMenuItemsBegin());

    return getMenuItemsApi(dispatch, params).then(result => {
      dispatch(fetchMenuItemsSuccess(result, append));

      return result;
    }).finally(() => dispatch(fetchMenuItemsEnded()));
  };

export const getMenuItemSales = () => (dispatch, getState) => {
  const { menuItem: { data } } = getState();

  const items = data && data.length ? data.filter(item => item.selected) : [];

  const queryString = items.map(item => `ids=${item.id}`).join('&');

  return getMenuItemsSales(dispatch, queryString).then(result => {
    dispatch(menuItemSalesReceived(result));

    return result;
  });
};

export const createMenuItem = menuItem =>
  dispatch => {
    return dispatch(createMenuItemApi(menuItem)).then(result => {
      dispatch(createMenuItemSuccess(result));
      dispatch(getAllTags());

      return result;
    });
  };

export const updateMenuItem = menuItem =>
  dispatch => {
    return dispatch(updateMenuItemApi(menuItem)).then(result => {
      dispatch(updateMenuItemSuccess(result));
      dispatch(getAllTags());

      return result;
    });
  };

export const saveMenuItems = menuItems =>
  dispatch => {
    return dispatch(saveMenuItemsApi(menuItems));
  };

export const deleteMenuItem = menuItemId => (dispatch, getState) => {
  const { masterMenuManagement: { masterMenus } } = getState();
  const odataFilter = `?$filter=menuItemId eq ${menuItemId} and recordStatus eq 'Active'`;

  // Important! Check if menuItemId is in use for any menuCategoryItems.
  return dispatch(getMenuCategoryItems(odataFilter)).then(menuCategoryItemsUsingThis => {
    if (menuCategoryItemsUsingThis.length > 0) {
      let menuCategoryNamesInUse = [];

      menuCategoryItemsUsingThis.forEach(menuCategoryItem => {
        const menuCategoryId = menuCategoryItem.menuCategoryId;

        return masterMenus.forEach(menu => {
          const menuCategory = menu.menuCategories.find(menuCategory => menuCategory.id === menuCategoryId);

          if (menuCategory) {
            menuCategoryNamesInUse.push(`${menu.name} - ${menuCategory.name}`);
          }
        });
      });
      const warningMessage = `This menu item is in use by the following Menu Categories:
      <br><br>${menuCategoryNamesInUse.join('<br>')}<br><br>Would you still like to archive it and remove it from all categories?`;

      throw Error(warningMessage);
    }

    return dispatch(deleteMenuItemApi(menuItemId)).then(result => {
      dispatch(deleteMenuItemSuccess(menuItemId));

      return result;
    });
  });
};

export const archiveMenuItem = menuItem => dispatch => {
  const odataFilter = `?$filter=menuItemId eq ${menuItem.id} and recordStatus eq 'Active'`;

  menuItem.recordStatus = 'Archived';

  return dispatch(updateMenuItem(menuItem)).then(() => {
    return dispatch(getMenuCategoryItems(odataFilter)).then(menuCategoryItems => {
      const archivedMenuCategoryItems = menuCategoryItems.map(menuCategoryItem => {
        menuCategoryItem.recordStatus = 'Archived';

        return menuCategoryItem;
      });

      for (let i = 0; i < archivedMenuCategoryItems.length; i++) {
        dispatch(updateMenuCategoryItem(archivedMenuCategoryItems[i]));
      }
    });
  }).then(dispatch(getAllMenuItems()));
};

export const getAllTags = () =>
  (dispatch, getState) => {
    dispatch(fetchTagsBegin());

    return dispatch(getTagsApi()).then(result => {
      dispatch(fetchTagsSuccess(result));

      return result;
    }).finally(() => dispatch(fetchTagsEnded()));
  };

const deleteMenuItemApi = menuItemId =>
  dispatch => {
    return dispatch(callApi(`MenuItem/${menuItemId}`, { method: 'DELETE' }))
      .then(response => response.json())
      .catch(console.error);
  };

const updateMenuItemApi = menuItem =>
  dispatch => {

    return dispatch(callApi(`MenuItem/${menuItem.id}`, { method: 'PUT', body: menuItem }))
      .then(response => response.json())
      .catch(console.error);
  };

const saveMenuItemsApi = menuItems =>
  dispatch => {

    return dispatch(callApi('MenuItem/batchSave', { method: 'POST', body: menuItems }))
      .then(response => response.json())
      .catch(console.error);
  };

const createMenuItemApi = menuItem =>
  dispatch => {

    return dispatch(callApi(`MenuItem`, { method: 'POST', body: menuItem }))
      .then(response => response.json())
      .catch(console.error);
  };

const getMenuItemsApi = (dispatch, params) => {
  return dispatch(
    callApi(`MenuItem?${params}`))
    .then(response => response.json())
    .catch(console.error);
};

const getMenuItemsSales = (dispatch, params) => {
  return dispatch(
    callApi(`MenuItem/Sales?${params}`))
    .then(response => response.json())
    .catch(console.error);
};

const getAllMenuItemsApi = dispatch =>
  dispatch(callApi(`MenuItem/All`))
    .then(response => response.json())
    .catch(console.error);

const getTagsApi = () =>
  dispatch => {
    return dispatch(callApi('itemtag'))
      .then(response => response.json())
      .catch(console.error);
  };

const getMenuCategoryItems = query => dispatch => {
  return dispatch(callApi(`MenuCategoryItem${query}`))
    .then(response => response.json());
};

const updateMenuCategoryItem = menuCategoryItem => dispatch => {
  return dispatch(callApi(`MenuCategoryItem/${menuCategoryItem.id}`, { method: 'PUT', body: menuCategoryItem }))
    .then(response => response.json());
};

export const fetchMenuItemsBegin = params => ({
  type: MENU_ITEMS_REQUEST_BEGIN,
});

export const fetchMenuItemsSuccess = (response, append) => ({
  type: RECEIVE_MENU_ITEMS,
  response,
  append,
});

export const fetchMenuItemsEnded = () => ({
  type: MENU_ITEMS_REQUEST_END,
});

export const allMenuItemsSelected = ({ selected }) => ({ type: ALL_ITEMS_TOGGLED, selected: selected });

export const menuItemSelected = ({ id, index, selected }) => ({
  type: MENU_ITEM_SELECTED,
  id,
  index,
  selected,
});

export const createMenuItemSuccess = menuItem => ({
  type: CREATE_MENU_ITEM_SUCCESS,
  menuItem,
});

export const updateMenuItemSuccess = menuItem => ({
  type: UPDATE_MENU_ITEM_SUCCESS,
  menuItem,
});

export const deleteMenuItemSuccess = menuItemId => ({
  type: DELETE_MENU_ITEM_SUCCESS,
  menuItemId,
});

export const clearSelectedMenuItem = () => ({
  type: CLEAR_SELECTED_MENU_ITEM,
});

export const fetchTagsBegin = params => ({
  type: TAGS_REQUEST_BEGIN,
});

export const fetchTagsSuccess = response => ({
  type: RECEIVE_TAGS,
  response,
});

export const fetchTagsEnded = () => ({
  type: TAGS_REQUEST_END,
});

export const menuItemSalesReceived = response => ({
  type: MENU_ITEM_SALES_DATA_RECEIVED,
  response,
});
