import { callApi } from 'shared/CallApi';
import api from 'shared/api';
import { uploadFile } from 'actions/file';

export default class FilesService {

  constructor(relationship, dispatch) {
    this.dispatch = dispatch;
    this.entityId = relationship.entityId;
    this.entityType = relationship.entityType;
    this.accessType = 'private';
    this.filesApiUrl = `file?$filter=EntityType eq '${this.entityType}' AND EntityId eq ${this.entityId}`;
    this.azureApiUrl = `file/${this.accessType}/${this.entityType}/${this.entityId}`;
  }

  fileUploadUrl = () => `${api}${this.azureApiUrl}`;

  get = () =>
    this.dispatch(callApi(this.filesApiUrl))
      .then(response => response.json());

  add = file =>
    this.dispatch(uploadFile(this.entityType, this.entityId, this.accessType, file));

  delete = fileId =>
    this.dispatch(callApi(`file/${fileId}`, { method: 'delete' }));

  share = (fileId, isShared) =>
    this.dispatch(callApi(`${this.filesApiUrl}/${fileId}/share`, { body: isShared, method: 'POST' }));

  addBookingImage = file => {
    return this.add(file).then(file => {
      return file.url;
    });
  }
}
