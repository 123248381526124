import React from 'react';
import { connect } from 'react-redux';
import VenuesSelection from '../VenuesSelection';

const WizardVenuesSelection = props => {
  const { focusedEvent } = props;

  //relies on focusedEvent having the venueConfigId set, but we may want to change it so that we look up the venueConfigId by EventId
  //as a performance enhavement so we don't have hit the venueConfig table

  return <VenuesSelection venueConfigId={focusedEvent.venueConfigId} storeType="wizard" />;
};

const mapStateToProps = state => {
  const {
    booking: {
      wizard: {
        focusedEvent,
      },
    },
  } = state;

  return {
    focusedEvent,
  };
};

export default connect(mapStateToProps, undefined)(WizardVenuesSelection);
