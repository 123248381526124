import React, { Component } from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CoverTextField from 'Components/TextField';
import MaterialTextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

// Note: Most of this is adapted from the Demo: https://material-ui.com/demos/autocomplete/#react-select
// https://react-select.com/home
// Note: There is a new AutoComplete component in Material Design https://material-ui.com/components/autocomplete/. Thought we would need to bump material design to 4.0.0+
const styles = theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
  },
  root: {
    flexGrow: 1,
    margin: '16px 16px 8px',
  },
  input: {
    display: 'flex',
    padding: 0,
    '& div[aria-hidden="true"]': { //Decrease padding around icons to make input size match (height: 32px)
      padding: '6px',
    },
    color: theme.palette.grey[700],
  },
  valueContainer: {
    color: theme.palette.grey[700],
    height: 28,
    paddingTop: 4,
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 100%',
    maxWidth: '95%',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.grey[200],
  },
  singleValue: {
    fontSize: 16,
    color: theme.palette.grey[700],
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    color: theme.palette.grey[200],
  },
  menuItem: {
    color: theme.palette.grey[300],
  },
  menu: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    borderRadius: '4px',
  },
  label: {
    color: theme.palette.grey[200],
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.selectProps.classes.menuItem}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.menu} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class ComboBoxAsync extends Component {
  state = {
    value: this.props.value,
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentWillUnmount() {
    this.setState({ value: {} });
  }

  handleSelection = value => {
    if (value !== this.state.value) {
      this.props.onSelect(value);
    }
  }

  onInputChange = (input, { action }) => {
    if (action !== 'input-blur' && action !== 'menu-close') { // allow parent to update
      this.setState({ inputValue: input });

      return input;
    }
  }

  onCreateOption = value => {
    const { onCreateOption } = this.props;

    onCreateOption ? onCreateOption(value) : console.error('No onCreateOption provided in props');
  }

  Control = props => {
    if (props.isFocused !== this.state.isFocused) {
      // need to get isFocused from react-select props for shrink-label
      this.setState({ isFocused: props.isFocused });
    }

    return (
      <MaterialTextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    );
  }

  render() {
    const {
      classes,
      suggestions,
      value,
      label,
      handleChange,
      disabled,
      autoLoadOptions,
      isCreatable,
      error,
      placeholder,
      getData,
      theme,
      ...rest
    } = this.props;
    const {
      inputValue,
      isFocused,
    } = this.state;

    components.Control = this.Control; // to give component access to Control state

    return (
      <div className={classes.wrapper}>
        {disabled ?
          <CoverTextField
            label={label}
            value={value || ''}
            disabled={true} // easy way to match disabled styling without re-working this whole component
          />
          :
          <div className={classes.root}>
            <AsyncCreatable
              {...rest}
              isClearable={true} //TODO pointer cursor for X
              classes={classes}
              multi={false}
              isDisabled={disabled}
              inputValue={inputValue}
              value={value}
              onChange={this.handleSelection}
              onInputChange={this.onInputChange}
              onCreateOption={this.onCreateOption}
              loadOptions={getData}
              defaultOptions={autoLoadOptions}
              components={components}
              minimumInput={1}
              placeholder={placeholder || " "}
              getOptionLabel={option => {
                return option.__isNew__ ? option.label : `${option.name || option.firstName + ' ' + option.lastName}`;
              }}
              backspaceRemovesValue={true}
              textFieldProps={{
                label: label,
                InputLabelProps: {
                  shrink: isFocused || !!value || !!inputValue,
                  classes: {
                    root: classes.label,
                  },
                },
                error,
              }}
              styles={{ menuPortal: base => ({ ...base, zIndex: theme.zIndex.modal + 1 }) }}
              menuPortalTarget={document.body}
            />
          </div>
        }
      </div>

    );
  }
}

export default withStyles(styles, { withTheme: true })(ComboBoxAsync);
