import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PickListEdit from 'Components/PickList/PickListEdit';
import Button from '@material-ui/core/Button';
import Modal from 'Components/Modal';
import {
  getAllTags,
  updateItemTags,
} from 'actions/item';
import { connect } from 'react-redux';

const styles = theme => ({
  textHalf: {
    width: '57%',
  },
  rightSide: {
    textAlign: 'right',
    width: '43%',
  },
  row: {
    display: 'flex',
    marginRight: theme.spacing.unit * 5,
    marginLeft: theme.spacing.unit * 5,
    marginTop: theme.spacing.unit * 1.5,
    marginBottom: theme.spacing.unit * 1.5,
  },
  subtitle: {
    backgroundColor: theme.palette.grey[50],
    fontSize: '17px',
    color: theme.common.black,
    width: '100%',
    paddingLeft: theme.spacing.unit * 5,
    paddingTop: theme.spacing.unit * 0.5,
    textAlign: 'left',
    height: theme.spacing.unit * 4,
  },
  overflowIfTooBig: {
    overflow: 'auto',
    marginTop: theme.spacing.unit * 2,
  },
  addButton: {
    textTransform: 'initial',
    paddingLeft: theme.spacing.unit * 6.5,
    paddingRight: theme.spacing.unit * 6.5,
    borderColor: theme.palette.grey[100],
  },
});

export class ItemTagModal extends Component {
  state = {
    newId: -1,
  };

  componentDidUpdate(prevProps) {
    const {
      getAllTags,
      checkedIds,
      isOpened,
    } = this.props;

    if (isOpened === true && prevProps.isOpened === false) {
      getAllTags().then(itemTags => {
        itemTags.forEach(itemTag => itemTag.checked = checkedIds.indexOf(itemTag.id) !== -1);
        this.setState({ itemTags });
      });
    }
    else if (isOpened === false && prevProps.isOpened === true) {
      //reset the state
      this.setState({
        itemTags: [],
        newId: -1,
      });
    }
  }

  updateItems = itemTags => {
    this.setState({ itemTags });
  }

  addCategory = () => {
    const {
      itemTags,
      newId,
    } = this.state;

    //Ids have to be unique, so each time a category is added, decrement the nextNewCategoryId by 1
    this.setState({
      itemTags: [
        ...itemTags,
        { name: '', id: newId, checked: true },
      ],
      newId: newId - 1,
    });
  }

  save = () => {
    this.props.updateItemTags(this.state.itemTags).then(updatedItems => {
      const tags = this.state.itemTags
        .filter(itemTag => itemTag.checked)
        .map(itemTag => updatedItems.find(item => item.name === itemTag.name));

      this.props.onSave(tags);
      this.props.onModalClosed();
    });
  }

  render() {
    const {
      classes,
      onModalClosed,
      title,
      isOpened,
    } = this.props;

    const { itemTags } = this.state;

    return (<Modal
      isOpened={isOpened}
      onCancel={onModalClosed}
      title={title}
      onSave={this.save}
      addTitleBottomBorder={true}
    >
      <div className={classes.overflowIfTooBig}>
        <Typography className={classes.subtitle} variant="body1">
          Item Tags
        </Typography>
        <div className={classes.row}>
          <div className={classes.textHalf}>
            {itemTags && <PickListEdit items={itemTags} updateItems={this.updateItems} useCheckBox={true} />}
          </div>
          <div className={classes.rightSide}>
            <Button className={classes.addButton} variant="outlined" color="secondary" aria-label="Add New Category" mini onClick={this.addCategory}>
              Add New Item Tag
            </Button>
          </div>
        </div>
      </div>
    </Modal>);
  }
}

ItemTagModal.propTypes = {
  getAllTags: PropTypes.func.isRequired,
  onModalClosed: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string,
};
const mapDispatchToProps = {
  getAllTags,
  updateItemTags,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(ItemTagModal));
