import React, { Component } from 'react';
import Modal from 'Components/Modal';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';

const dimensions = {
  width: 350,
  height: 213,
};

class AddEventSectionModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedSection: undefined,
    };
  }

  initializeSections = bookingEvent => {
    this.sections = [
      { id: 'Venues', name: 'Venue Details', exists: Boolean(bookingEvent.hasVenues || (bookingEvent.bookingEventRooms && bookingEvent.bookingEventRooms.length > 0)) },
      { id: 'Sites', name: 'Sites', exists: Boolean(bookingEvent.hasSites || (bookingEvent.bookingEventSites && bookingEvent.bookingEventSites.length > 0)) },
      { id: 'Shifts', name: 'Shifts', exists: Boolean(bookingEvent.hasShifts || (bookingEvent.bookingEventShifts && bookingEvent.bookingEventShifts.length > 0)) },
    ];
  }

  componentDidMount() {
    this.initializeSections(this.props.event);
  }

  componentDidUpdate(prevProps) {
    if (this.props.bookingId !== prevProps.bookingId || this.props.event !== prevProps.event) {
      this.initializeSections(this.props.event);
    }
  }

  onSectionSelected = section => {
    this.setState({ selectedSection: section });
  }

  onCancel = () => {
    this.props.onCancel();
    this.setState({ selectedSection: undefined });
  }

  onSave = () => {
    this.props.onSave(this.state.selectedSection);
    this.setState({ selectedSection: undefined });
  }

  render() {
    const { isOpen } = this.props;
    const { selectedSection } = this.state;
    const sections = this.sections;

    return (
      <>
        <Modal
          isOpened={isOpen}
          dimensions={dimensions}
          onCancel={this.onCancel}
          onSave={this.onSave}
          title="Add New Section"
          saveText="Add"
          addTitleBottomBorder={true}
          isSaveDisabled={!Boolean(selectedSection)}
        >
          <Select
            label="Section"
            name="templateId"
            value={selectedSection}
            onFieldChange={this.onSectionSelected}
          >
            {sections && sections.map(section =>
              <MenuItem
                key={section.id}
                value={section.id}
                disabled={section.exists}
              >
                {section.name}
              </MenuItem>)}
          </Select>
        </Modal>
      </>);
  }
}

export default AddEventSectionModal;
