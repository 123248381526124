import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';

//the height 100% and  minHeight: 'unset' allows this tab component to defer to the contaner's height
const styles = theme => ({
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    '&:first-child': {
      borderRadius: '4px 0 0 0',
    },
    height: '100%',
    minHeight: 'unset',
  },
  flexContainer: {
    height: '100%',
  },
  tab: {
    textTransform: 'initial',
    minWidth: 115,
    minHeight: 'unset',//defer to the tab's containters height
    fontSize: 13,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
  },
  hideIndicator: {
    display: 'none',
  },
  tabSelected: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    borderLeftColor: theme.palette.grey[50],
    borderRightColor: theme.palette.grey[50],
  },
});

class BlueTextTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: props.tabIndex,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tabIndex !== this.props.tabIndex) {
      this.setState({ tabIndex: this.props.tabIndex });
    }
  }

  handleChange = (_event, tabIndex) => {
    this.setState({ tabIndex });
    this.props.onTabChange(tabIndex);
  };

  render() {
    const {
      classes,
      tabs,
    } = this.props;
    const {
      tabIndex,
    } = this.state;
    const tabsClass = {
      root: classes.tabs,
      indicator: classes.hideIndicator,
      flexContainer: classes.flexContainer,
    };

    return (
      <Tabs value={tabIndex} onChange={this.handleChange} classes={tabsClass}>
        {tabs.map(tab =>
          <Tab label={tab} key={tab} classes={{ root: classes.tab, selected: classes.tabSelected }} disableRipple />
        )}
      </Tabs>
    );
  }
}

export default withStyles(styles)(BlueTextTab);
