import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getSalesRepsIfNeeded } from 'actions/contact';
import Modal from 'Components/Modal';
import SectionHeader from 'Components/SectionHeader';
import ConvertedSection from './ConvertedSection';
import classNames from 'classnames';
import moment from 'moment';

const styles = theme => ({
  main: {
    padding: '16px 32px',
  },
  header: {
    marginTop: 16,
  },
  glanceSection: {
    display: 'flex',
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingBottom: 6,
  },
  marginRight: {
    marginRight: 12,
  },
  glance: {
    minWidth: '20%',
    maxWidth: '65%',
  },
  glanceLabel: {
    color: theme.palette.grey[200],
    fontSize: 12,
  },
  glanceData: {
    color: theme.palette.grey[300],
    fontSize: 13,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  halfWidth: {
    width: '48%',
  },
  linkColor: {
    color: theme.palette.primary.dark,
  },
});

class ConvertSuccessModal extends Component {

  areAllEventsOnSameDay = booking => {
    const events = booking && booking.events && [...booking.events];

    if (events && events.length > 1) {
      const firstEvent = events[0];
      const lastEvent = events.slice(-1)[0];

      return moment(firstEvent.startTime).isSame(lastEvent.endTime, 'date');
    }

    return true;
  }

  handleClick = url => {
    this.props.onClose(url);
  };

  render() {
    const {
      classes,
      successDto: {
        account,
        booking,
        contact,
        isNewBooking,
      },
    } = this.props;
    const isConvertedContact = !!contact && !!contact.id;
    const successMessage = 'Congratulations, You\'ve ' + (isNewBooking ? 'Booked an Event!' : 'Converted a Lead!');
    const saveText = 'Go to ' + (isNewBooking ? 'Booking' : 'Account');
    const areAllEventsOnSameDay = this.areAllEventsOnSameDay(booking);
    const displayDate = isNewBooking && `${moment(booking.startDateTime).format('l')}${areAllEventsOnSameDay ? '' : ' - ' + moment(booking.endDateTime).format('l')}`;
    const displayTime = isNewBooking && `${moment(booking.startDateTime).format('LT')} - ${moment(booking.endDateTime).format('LT')}`;

    return (
      <Modal
        isOpened={true}
        onCancel={() => this.handleClick('/leads')}
        title={successMessage}
        onSave={() => this.handleClick(isNewBooking ? `/bookings/${booking.id}` : `/accounts/${account.id}`)}
        saveText={saveText}
        cancelText="Close"
        addTitleBottomBorder={true}
      >
        <SectionHeader className={classes.header}>Details</SectionHeader>
        <div className={classes.main}>
          <ConvertedSection
            section="Booking"
            onSectionClick={() => this.handleClick(`/bookings/${booking.id}`)}
            isActive={isNewBooking}
            name={booking && booking.name}
          >
            {isNewBooking && <>
              <div className={classNames(classes.glanceSection, classes.borderBottom)}>
                <div className={classNames(classes.glance, classes.marginRight)}>
                  <p className={classes.glanceLabel}>Contact</p>
                  <p onClick={this.goToContact} className={classNames(classes.glanceData, classes.linkColor)}>{booking.contact && booking.contact.name}</p>
                </div>
                <div className={classNames(classes.glance, classes.marginRight)}>
                  <p className={classes.glanceLabel}>Account</p>
                  <p onClick={this.goToAccount} className={classNames(classes.glanceData, classes.linkColor)}>{booking.account && booking.account.name}</p>
                </div>
                <div className={classNames(classes.glance, classes.marginRight)}>
                  <p className={classes.glanceLabel}>Date</p>
                  <p className={classes.glanceData}>{displayDate}</p>
                </div>
                <div className={classes.glance}>
                  <p className={classes.glanceLabel}>Time</p>
                  <p className={classes.glanceData}>{displayTime}</p>
                </div>
              </div>
              <div className={classes.glanceSection}>
                <div className={classNames(classes.glance, classes.marginRight)}>
                  <p className={classes.glanceLabel}>Event Manager</p>
                  <p className={classes.glanceData}>{booking.eventManager}</p>
                </div>
                <div className={classNames(classes.glance, classes.marginRight)}>
                  <p className={classes.glanceLabel}>Date Booked</p>
                  <p className={classes.glanceData}>{moment(booking.dateBooked).format('l')}</p>
                </div>
                <div className={classes.glance}>
                  <p className={classes.glanceLabel}># of Events</p>
                  <p className={classes.glanceData}>{booking.events && booking.events.length}</p>
                </div>
              </div>
            </>}
          </ConvertedSection>
          <div className={classes.spaceBetween}>
            <div className={classes.halfWidth}>
              <ConvertedSection
                section="Contact"
                onSectionClick={() => this.handleClick(`/contacts/${contact.id}`)}
                isActive={isConvertedContact}
                name={isConvertedContact && contact.name}
              >
                {isConvertedContact && <>
                  <div className={classes.glanceSection}>
                    <div className={classNames(classes.glance, classes.marginRight)}>
                      <p className={classes.glanceLabel}>Account Name</p>
                      <p className={classes.glanceData}>{account.name}</p>
                    </div>
                    <div className={classNames(classes.glance, classes.marginRight)}>
                      <p className={classes.glanceLabel}>Email</p>
                      <a href={`mailto:${contact.email}`}><p className={classNames(classes.glanceData, classes.linkColor)}>{contact.email}</p></a>
                    </div>
                  </div>
                  <div className={classes.glanceSection}>
                    <div className={classNames(classes.glance, classes.marginRight)}>
                      <p className={classes.glanceLabel}>Phone</p>
                      <p className={classes.glanceData}>{contact.phone}</p>
                    </div>
                    <div className={classes.glance}>
                      <p className={classes.glanceLabel}>Sales Rep</p>
                      <p className={classes.glanceData}>{contact && contact.salesRep ? contact.salesRep : ''}</p>
                    </div>
                  </div>
                </>}
              </ConvertedSection>
            </div>
            <div className={classes.halfWidth}>
              <ConvertedSection
                section="Account"
                onSectionClick={() => this.handleClick(`/accounts/${account.id}`)}
                isActive={true}
                name={account && account.name}
              >
                <div className={classes.glanceSection}>
                  <div className={classNames(classes.glance, classes.marginRight)}>
                    <p className={classes.glanceLabel}>Contact Name</p>
                    <p className={classes.glanceData}>{account.contact}</p>
                  </div>
                  <div className={classNames(classes.glance, classes.marginRight)}>
                    <p className={classes.glanceLabel}>Email</p>
                    <a href={`mailto:${account.email}`}><p className={classNames(classes.glanceData, classes.linkColor)}>{account.email}</p></a>
                  </div>
                </div>
                <div className={classes.glanceSection}>
                  <div className={classNames(classes.glance, classes.marginRight)}>
                    <p className={classes.glanceLabel}>Phone</p>
                    <p className={classes.glanceData}>{account.phone}</p>
                  </div>
                  <div className={classes.glance}>
                    <p className={classes.glanceLabel}>Sales Rep</p>
                    <p className={classes.glanceData}>{account && account.salesRep ? account.salesRep.name : ''}</p>
                  </div>
                </div>
              </ConvertedSection>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  getSalesRepsIfNeeded,
};

const mapStateToProps = state => {
  const { api: { salesReps: { salesRepsData } } } = state;

  return { salesReps: salesRepsData };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConvertSuccessModal));

