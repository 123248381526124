import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import ODataGrid from 'Components/Grid/ODataGrid';
import * as itemsService from 'actions/item';
import SelectedColumn from 'Components/Grid/SelectedColumn';
import dropdownFilterCell from 'Components/Grid/dropdownFilterCell';
import ViewEditItemModal from './ViewEditItemModal';
import Modal from 'Components/Modal';
import { connect } from 'react-redux';
import { getAllTags } from 'actions/item';
import { getMenuItems, menuItemSelected, clearSelectedMenuItem, saveMenuItems, allMenuItemsSelected } from 'actions/menu';
import GridToolBar from 'Components/Grid/GridToolBar';
import DropdownCell from 'Components/Grid/DropdownCell';
import { buildGridCellModalLink } from 'Components/buildGridCellLink';
import MenuItemFilterField from 'Components/Menu/MenuItemFilterField';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CloudUpload, CloudDownload } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import Papa from 'papaparse';

const styles = theme => ({
  tagsContainer: {
    '& tags': {
      fontSize: '16px',
      border: '0',
      margin: '0',
      marginTop: '6px',
      flex: 1,
      width: '100%',
    },
    '& div': {
      width: '100%',
    },
    '&:hover': {
      borderBottom: '2px solid rgb(100,100,100)',
      paddingBottom: 0,
    },
    '& .searchIcon': {
      marginTop: '12px',
      marginLeft: '5px',
      fill: '#bcbcbc',
      fontSize: '25px',
      width: 30,
    },
    '& .tagify__tag__removeBtn': {
      font: '17px Serif',
      width: 22,
    },
    borderBottom: '1px solid rgb(97,97,97)',
    display: 'flex',
    border: '0',
    flexDirection: 'row',
    marginTop: '6px',
    paddingBottom: 1,
    width: '80%',
  },
  uploadMenuItemsButtonSection: {
    width: '100%',
    textAlign: 'center',
  },
  uploadMenuItemsTemplate: {
    display: 'block',
    maxWidth: '127px',
    margin: 'auto',
    color: theme.palette.primary.main,
  },
  uploadFile: {
    margin: '10px 0',
    display: 'block',
  },
});

const camelize = string => {
  return string.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
};

class ItemGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      isEditable: false,
      filter: {},
      isFilterable: false,
      revenueTypesMap: {},
      isModalOpen: false,
      uploadModalOpened: false,
      menuItems: [],
      importErrors: '',
    };
  }

  config = {
    // delimiter: "", // auto-detect
    // newline: "", // auto-detect
    // quoteChar: '"',
    // escapeChar: '"',
    header: true,
    transformHeader: header => {
      return camelize(header);
    },
    // dynamicTyping: false,
    // preview: 0,
    // encoding: "",
    // worker: false,
    // comments: false,
    // step: undefined,
    error: e => this.setState({ importErrors: `Import Failed! ${e}` }),
    // download: false,
    // downloadRequestHeaders: undefined,
    skipEmptyLines: true,
    // chunk: undefined,
    // fastMode: undefined,
    // beforeFirstChunk: undefined,
    // withCredentials: undefined,
    // transform: undefined,
    // delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
    complete: (results, file) => {
      if (!results || !results.data || !results.data.length) {
        this.setState({ importErrors: 'No import data was found' });

        return;
      }

      const { revenueTypes } = this.props;
      const keyValueRevenueTypes = {};

      revenueTypes.forEach(revenueType => {
        keyValueRevenueTypes[revenueType.name] = revenueType.id;
      });

      const menuItems = results.data.map(item => {
        const menuItem = {};

        menuItem.revenueTypeId = keyValueRevenueTypes[item.revenueType];
        Object.keys(item).forEach(key => {
          const itemProp = item[key];

          if (key !== 'revenueType' && itemProp !== '') {
            menuItem[key] = itemProp;
          }
        });

        return menuItem;
      });

      this.setState({ menuItems });
    },
  };

  componentDidMount() {
    this.props.getColumns();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.revenueTypes !== this.props.revenueTypes) {
      this.mapRevenueTypes(this.props.revenueTypes);
    }
    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: this.props.columns });
    }
  }

  getTranslations() {
    if (!this.translations) {
      const localizationService = provideLocalizationService(this);

      this.translations = {
        name: localizationService.toLanguageString('itemManagement.name'),
        status: localizationService.toLanguageString('itemManagement.status'),
        prepArea: localizationService.toLanguageString('itemManagement.prepArea'),
        price: localizationService.toLanguageString('itemManagement.price'),
        revenueType: localizationService.toLanguageString('itemManagement.revenueType'),
        available: localizationService.toLanguageString('itemManagement.status'),
      };
    }

    return this.translations;
  }

  htmlDescription = ({ dataItem }) => {
    return (
      <td>
        <div
          dangerouslySetInnerHTML={{ __html: dataItem.description }}
          style={{
            maxHeight: 30,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        />
      </td>
    );
  }

  nameCell = buildGridCellModalLink({
    onOpenModal: item => this.onItemSelectionChange(item.id)(),
    text: item => item.name,
  });

  getColumn(column, translations, items, revenueTypes, revenueTypesMap) {
    if (column.isVisible) {
      switch (column.field) {
        case 'selected':
          return SelectedColumn(items, !this.state.isEditable);
        case 'name':
          return this.state.isEditable ?
            <GridColumn key={column.field} field={column.field} title={translations.name} /> :
            <GridColumn key={column.field} field={column.field} title={translations.name} cell={this.nameCell} />;
        case 'prepArea':
          return <GridColumn key='preparationArea' field='preparationArea' title={translations.prepArea} />;
        case 'price':
          return <GridColumn key={column.field} field={column.field} title={translations.price} format="{0:C}" filter="numeric" />;
        case 'revenueTypeId':
          return <GridColumn
            key={column.field}
            field="revenueTypeId"
            title={translations.revenueType}
            filterCell={this.revenueTypeFilterCell}
            cell={props => this.state.isEditable ?
              <DropdownCell options={revenueTypes} onChange={this.onDropdownCellChange(props)} value={props.dataItem.revenueTypeId} name="revenueTypeId" /> :
              <td>{revenueTypesMap[props.dataItem.revenueTypeId]}</td>} />;
        case 'status':
          return <GridColumn key={column.field} field={column.field} title={translations.status} />;
        case 'description':
          return <GridColumn key={column.field} field={column.field} title={column.title} cell={this.htmlDescription} />;
        default:
          return <GridColumn key={column.field} field={column.field} title={column.title} />;
      }
    }
  }

  reorderColumns = event => {
    const { columns: reOrderColumns } = event;
    const reorderedColumns = reOrderColumns.sort((a, b) => a.orderIndex - b.orderIndex).map(column => column.field);

    this.props.reorderColumns(reorderedColumns);
  }

  onItemSelectionChange = itemId => () => {
    this.props.menuItemSelected({ id: itemId });
    this.setState({ itemId, isModalOpen: true });
  }

  onItemChangeManual = props => () => {
    if (this.state.isEditable) {
      props.onChange({ dataItem: props.dataItem, field: props.field, value: !props.dataItem.onlineOrderable });
    }
  }

  onGridItemsSaved = items => {
    this.props.saveItems(items).finally(() => {
      this.setState({ isEditable: false });
    });
  }

  onGridEditClick = () => {
    this.setState({ isGridToggled: !this.state.isGridToggled });
  }

  onGridEditClose = () => {
    this.setState({ isGridToggled: false });
  }

  saveGridColumns = columns => {
    this.props.saveColumns(columns);
  }

  onDropdownCellChange = props => newValue => {
    props.onChange({ dataItem: props.dataItem, field: newValue.name, value: newValue.value });
  }

  textLabels = {
    toolBarLabel: 'Tags',
    modalLabel: 'Filter By Tag',
    addButtonLabel: 'Add Library Item',
    editButtonLabel: 'Edit Library Items',
    gridButtonLabel: 'Grids! What does this button do?',
    filterButtonLabel: 'Filter Library Items',
  }

  onUploadFileClick = () => {
    this.setState({ importErrors: '' });
  }

  saveMenuItems = () => {
    const { saveMenuItems, getMenuItems } = this.props;
    const { menuItems } = this.state;

    return saveMenuItems(menuItems)
      .then(error => {
        if (error) {
          this.setState({ importErrors: `Import Menu Items Failed. ${error.title ? error.title : ''}` });
        } else {
          this.setState({ uploadModalOpened: false, menuItems: [] });
          getMenuItems();
        }
      });
  }

  uploadModalOpened = uploadModalOpened => {
    this.setState({ uploadModalOpened });
  }

  handleFileSubmit = files => {
    Papa.parse(files[0], this.config);
  }

  getToolbar = () => (
    <GridToolBar
      filter={this.GetGridFilter()}
      additionalButtons={this.getUploadButton()}
      onEditClick={this.onEditClick}
      onAddClick={this.onAddClick}
      textLabels={this.textLabels}
      columns={this.state.columns}
      isFilterToggled={this.state.isFilterable}
      isEditToggled={this.state.isEditable}
      isGridToggled={this.state.isGridToggled}
      onFilterClick={this.onFilterClick}
      onGridEditClick={this.onGridEditClick}
      onColumnsSubmit={this.saveGridColumns}
      onCloseMenu={this.onGridEditClose}
      gridContext="Menu Items"
    />
  )

  getUploadButton = () => {
    const { classes } = this.props;
    const { uploadModalOpened, menuItems, importErrors } = this.state;

    return (<>
      <HighlightButton
        onClick={() => this.uploadModalOpened(true)}
        aria-label="Import Menu Items"
        pressed={this.state.uploadModalOpened}
      >
        <CloudUpload />
      </HighlightButton>
      <Modal
        isOpened={uploadModalOpened}
        onCancel={() => this.uploadModalOpened(false)}
        onSave={this.saveMenuItems}
        saveText="Import"
        isSaveDisabled={!menuItems || !menuItems.length}
        title="Import Menu Items"
        errorMessage={importErrors}
        addTitleBottomBorder={false}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '750px' }}
      >
        <div className={classes.uploadMenuItemsButtonSection}>
          <Button
            href="/templates/ImportMenuItems.csv"
            className={classes.uploadMenuItemsTemplate}
          >
            <CloudDownload />
            &nbsp;Template
          </Button>
          <label
            htmlFor="upload-menu-items"
            className={classes.uploadFile}
            onClick={this.onUploadFileClick}
          >
            <input style={{ display: 'none' }}
              id="upload-menu-items"
              type="file"
              onChange={e => this.handleFileSubmit(e.target.files)}
              accept=".csv"
            />
            <Button color="secondary" variant="contained" component="span">
              Upload File
            </Button>
          </label>
        </div>
      </Modal >
    </>);
  }

  GetGridFilter = () => {
    const { classes } = this.props;

    return (
      <MenuItemFilterField
        className={classes.tagsContainer}
        onChange={this.setGridFilter} />
    );
  }

  setGridFilter = filters => {
    const { getMenuItems } = this.props;

    let oDataFilters = [];

    if (filters && filters.length > 0 && filters.some(filter => filter.type === 'tag')) {
      oDataFilters.push(`(${filters.filter(filter => filter.type === 'tag').map(tag => `(itemTags/any(itemTag: itemTag/itemTagId eq ${tag.id}))`).join('and')})`);
    }
    if (filters && filters.length > 0 && filters.some(filter => !filter.type)) {
      oDataFilters.push(`(${filters.filter(filter => !filter.type).map(tag => `(contains(name, '${tag.value}')) or (contains(description, '${tag.value}'))`).join('and')})`);
    }

    if (oDataFilters.length > 0) {
      const filterString = oDataFilters.join('and');

      getMenuItems(`$filter=${filterString}`);
    } else {
      getMenuItems();
    }
  }

  onAddClick = () => {
    this.props.clearSelectedMenuItem();
    this.setState({ itemId: undefined, isModalOpen: true });
  }

  onEditClick = () => {
    this.setState({ isEditable: !this.state.isEditable });
  }

  onFilterClick = () => {
    this.setState({ isFilterable: !this.state.isFilterable });
  }

  closeModal = () => {
    this.setState({ itemId: undefined, isModalOpen: false });
  }

  onReordered = items => {
    return this.props.reorder(this.props.selectedCategory.id, items);
  }

  mapRevenueTypes = revenueTypes => {
    const revenueTypeDropDownOptions = [];
    let revenueTypesMap = {};

    !!revenueTypes && revenueTypes.forEach(t => {
      revenueTypeDropDownOptions.push({
        text: t.name,
        id: t.id,
      });
      revenueTypesMap[t.id] = t.name;
    });

    this.revenueTypeFilterCell = dropdownFilterCell(revenueTypeDropDownOptions, 'Revenue Types');
    this.setState({ revenueTypesMap });
  }

  getDefaultCategoryId = () => {
    const { selectedCategory, items } = this.props;

    let categoryId;

    if (selectedCategory && selectedCategory.id !== 0) {
      categoryId = selectedCategory.id;
    } else if (items && items.length > 0) {
      categoryId = items[0].categoryId;
    }

    return categoryId;
  }

  getDefaultTaxTypeId = () => {
    const { items } = this.props;
    const { revenueTypesMap } = this.state;

    let revenueTypeId;

    if (items && items.length > 0) {
      revenueTypeId = items[0].revenueTypeId;
    } else if (revenueTypesMap && revenueTypesMap.length > 0) {
      revenueTypeId = revenueTypesMap[0];
    }

    return revenueTypeId;
  }

  render() {
    const {
      columns,
      isEditable,
      itemId,
      filter,
      isFilterable,
      revenueTypesMap,
      isModalOpen,
    } = this.state;

    const translations = this.getTranslations();
    const { total, getMenuItems, items = [], menuItemSelected, loading, canReorder = true, revenueTypes, allMenuItemsSelected } = this.props;

    if (items && items.status && items.status === 500) {
      return null;
    }

    let selectedIndex = items.findIndex(item => item.id === itemId);

    selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;
    const prevItem = (itemId && items[selectedIndex === 0 ? items.length - 1 : selectedIndex - 1]) || {};
    const nextItem = (itemId && items[(selectedIndex + 1) % items.length]) || {};

    // Kendo will not properly update column hide/show binding/rendering even if the `columns` object updates. So we cant render the grid until columns is populated.
    return columns.length ? (
      <>
        <ODataGrid
          onColumnReorder={this.reorderColumns}
          getData={getMenuItems}
          items={items}
          onReordered={canReorder ? this.onReordered : null}
          selectionChanged={menuItemSelected}
          allItemsSelected={allMenuItemsSelected}
          toolBar={this.getToolbar()}
          total={total}
          isLoading={loading}
          isSortable={!isEditable}
          filterable={!isEditable && isFilterable}
          isEditable={isEditable}
          filter={filter}
          onSave={this.onGridItemsSaved}
          onCancel={this.onEditClick}
        >
          {columns.map(field => this.getColumn(field, translations, items, revenueTypes, revenueTypesMap))}
        </ODataGrid>
        <ViewEditItemModal
          itemId={itemId}
          closeFunc={this.closeModal}
          onNextClick={this.onItemSelectionChange(nextItem.id)}
          onPreviousClick={this.onItemSelectionChange(prevItem.id)}
          isModalOpen={isModalOpen}
          defaultCategoryId={this.getDefaultCategoryId()}
          defaultTaxTypeId={this.getDefaultTaxTypeId()}
        />
      </>
    ) : null;
  }
}

registerForLocalization(ItemGrid);

const mapStateToProps = state => {
  const {
    menuItem: { data, loading },
    api: {
      revenueTypes,
    },
    settings: {
      menuItems: {
        columns,
      },
    },
  } = state;

  return {
    items: data,
    revenueTypes,
    loading,
    columns,
  };
};

const mapDispatchToProps = {
  getMenuItems,
  saveMenuItems,
  allMenuItemsSelected,
  menuItemSelected,
  clearSelectedMenuItem,
  getColumns: itemsService.getMenuItemColumns,
  saveColumns: itemsService.saveMenuItemColumns,
  reorderColumns: itemsService.reorderMenuItemColumns,
  saveItems: itemsService.saveMenuItemColumns,
  reorder: itemsService.reorder,
  getAllTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ItemGrid));
