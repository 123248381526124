import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionHeader from 'Components/SectionHeader';
import { withStyles } from '@material-ui/core/styles';
import SubTotal from '../SubTotal';
import CustomerDetails from '../CustomerDetails';
import PaymentMethodDetails from '../PaymentMethodDetails';
import { addOrderPayment } from 'actions/orderPayment';
import { applyCoupon, removeCoupon } from 'actions/order';
import NewOrderContext from 'orders/wizard/NewOrderContext';

const styles = () => ({
  paymentMethodConainer: {
    display: 'flex',
    height: 400,
  },
  checkoutDetailsContainer: {
    display: 'flex',
    width: '100%',
    margin: '10px 0',
  },
  orderDetails: {
    width: '50%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  financialDetails: {
    width: '50%',
  },
  customerDetails: {
    width: '50%',
  },
  paymentDetails: {
    width: '50%',
  },
  paymentProcessor: {
    display: 'flex',
    flexDirection: 'column',
  },
  padLeft: {
    paddingLeft: 16,
  },
  paymentFields: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 300,
  },
  editDetails: {
    width: '100%',
    margin: '20px',
    textAlign: 'center',
  },
  button: {
    width: '126px',
    height: '32px',
    borderRadius: 4,
  },
  orderNumber: {
    position: 'absolute',
    top: '33px',
    left: '12px',
    fontSize: '15px',
  },
});

class Place extends Component {
  static contextType = NewOrderContext;

  componentDidMount() {
    document.addEventListener('*', this.handleKeyPress);
  }

  handleKeyPress = event => {
    console.log(event);
  }

  getOrderId = () => {
    const orderId = this.props.storeOrder.id;

    return orderId ? orderId : "";
  }

  paymentSuccess = () => {
    const { saveAndClose } = this.context;

    saveAndClose && saveAndClose();
  }

  render() {
    const { classes, storeOrder, user, addOrderPayment, applyCoupon, removeCoupon } = this.props;

    return (
      <>
        <SectionHeader>
          Checkout Details
        </SectionHeader>
        <div className={classes.orderNumber}>Order: {this.getOrderId()}</div>
        <div className={classes.checkoutDetailsContainer}>
          <div className={classes.orderDetails}>
            <div className={classes.financialDetails}>
              <SubTotal
                order={storeOrder}
                className={classes.padLeft}
              />
            </div>
            <div className={classes.customerDetails}>
              <CustomerDetails order={storeOrder} />
            </div>
          </div>
          <div className={classes.paymentDetails}>
            <PaymentMethodDetails
              order={storeOrder}
              user={user}
              addOrderPayment={addOrderPayment}
              paymentSuccess={this.paymentSuccess}
              closeOnSuccess={true}
              applyCoupon={applyCoupon}
              removeCoupon={removeCoupon}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  storeOrder: state.storeReducer.storeOrder,
  user: state.oidc.user,
});

const mapDispatchToProps = {
  addOrderPayment,
  applyCoupon,
  removeCoupon,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Place));
