import {
  RECEIVE_CONTACTS,
  SET_CURRENT_CONTACT,
  RESET_CURRENT_CONTACT,
  CLONE_CONTACT,
  CONTACTS_REQUEST_BEGIN,
  CONTACTS_SELECTED,
  RECEIVE_CONTACT_NAME_AND_IDS,
  UPDATE_CURRENT_CONTACT,
} from 'actions/constants';

const initialState = {
  isLoading: true,
  contacts: [],
  selectedContacts: [],
  nameAndIds: [],
  currentContact: null,
};

export function contact(state = initialState, action) {
  switch (action.type) {
    case CONTACTS_REQUEST_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CONTACTS_SELECTED: {
      const { contacts } = action;

      return {
        ...state,
        selectedContacts: contacts,
      };
    }
    case RECEIVE_CONTACTS: {
      const { data, append } = action;

      return {
        ...state,
        isLoading: false,
        contacts: append ? state.contacts.concat(data) : data,
        selectedContacts: append ? state.selectedContacts : [],
      };
    }
    case RECEIVE_CONTACT_NAME_AND_IDS: {
      const { nameAndIds } = action;

      return {
        ...state,
        nameAndIds,
      };
    }
    case SET_CURRENT_CONTACT: {
      const { contact } = action;

      return {
        ...state,
        currentContact: contact,
      };
    }
    case UPDATE_CURRENT_CONTACT: {
      const { contact } = action;

      return {
        ...state,
        currentContact: contact,
      };
    }
    case RESET_CURRENT_CONTACT: {
      return {
        ...state,
        currentContact: null,
      };
    }
    case CLONE_CONTACT: {
      return {
        ...state,
      };
    }

    default:
      return state;
  };
}
