import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from 'Components/TextField';
import StringComboBox from 'Components/StringComboBox';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  getPickLists,
} from 'actions/pickList';

const styles = theme => ({
  masonry: {
    display: 'flex',
    flex: 1,
    padding: '8px 0',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
  },
  fieldWrapper: {
    display: 'flex',
    flex: 1,
  },
  halfWidth: {
    display: 'flex',
    flex: 1,
    width: '50%',
  },
  fullWidth: {
    display: 'flex',
    width: '100%',
    flex: 1,
  },
  flexRow: {
    flexDirection: 'row',
  },
  flex: {
    display: 'flex',
  },
  fieldIsEdited: {
    background: theme.palette.error.light,
  },
});

class ContactQuickAddDetails extends Component {
  state = {
    salutations: [],
  };

  componentDidMount() {
    this.props.getPickLists();
  }

  componentDidUpdate(prevProps, prevState) {
    const { pickListDictionary } = this.props;

    if (pickListDictionary && pickListDictionary !== prevProps.pickListDictionary) {
      const salutations = pickListDictionary['Salutations'] && pickListDictionary['Salutations'].pickListOptions;

      this.setState({ salutations });
    }
  }

  handleSalutationChange = salutationModel => {
    if (!salutationModel) {
      this.props.onFieldChange('salutation')(null);//clicked clear button

      return;
    }
    this.props.onFieldChange('salutation')(salutationModel.label);
  }

  render() {
    const { classes, contact, isEditing, onFieldChange, isFieldChanged, isFieldInvalid, validateField } = this.props;
    const { salutations } = this.state;

    return (
      <div className={classes.masonry}>
        <div className={classNames(classes.column, classes.halfWidth)}>
          <div className={classNames(classes.flex)}>
            <div className={classNames(classes.halfWidth, isFieldChanged('firstName') && classes.fieldIsEdited)}>
              <TextField
                label="First Name"
                onFieldChange={onFieldChange('firstName')}
                name="firstName"
                value={contact.firstName || ''}
                disabled={!isEditing}
                error={isFieldInvalid('firstName')}
                onBlur={() => validateField('firstName')}
              />
            </div>
            <div className={classNames(classes.halfWidth, isFieldChanged('lastName') && classes.fieldIsEdited)}>
              <TextField
                label="Last Name"
                onFieldChange={onFieldChange('lastName')}
                name="lastName"
                value={contact.lastName || ''}
                disabled={!isEditing}
                error={isFieldInvalid('lastName')}
                onBlur={() => validateField('lastName')}
              />
            </div>
          </div>

          <div className={classNames(classes.fullWidth)}>
            <div className={classNames(classes.halfWidth, isFieldChanged('title') && classes.fieldIsEdited)}>
              <TextField
                label="Title"
                onFieldChange={onFieldChange('title')}
                name="title"
                value={contact.title || ''}
                disabled={!isEditing}
              />
            </div>
            <div className={classNames(classes.halfWidth, isFieldChanged('salutationId') && classes.fieldIsEdited)}>
              <StringComboBox
                label="Salutation"
                value={contact.salutation}
                dataSource={salutations}
                isClearable={true}
                maxLength={50}
                onChange={onFieldChange('salutation')}
                onQuickAdd={onFieldChange('salutation')}
                valueType="string"
              />
            </div>
          </div>
        </div>

        <div className={classNames(classes.column, classes.halfWidth)}>
          <div className={classNames(classes.fullWidth, isFieldChanged('email') && classes.fieldIsEdited)}>
            <TextField
              label="Email"
              onFieldChange={onFieldChange('email')}
              name="email"
              value={contact.email || ''}
              disabled={!isEditing}
              type="email"
              error={isFieldInvalid('email')}
              onBlur={() => validateField('email')}
            />
          </div>

          <div className={classNames(classes.fullWidth, isFieldChanged('phone') && classes.fieldIsEdited)}>
            <TextField
              label="Cell"
              onFieldChange={onFieldChange('phone')}
              name="phone"
              value={contact.phone || ''}
              disabled={!isEditing}
            />
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    pickList: {
      pickListDictionary,
    },
  } = state;

  return {
    pickListDictionary,
  };
};

const mapDispatchToProps = {
  getPickLists,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContactQuickAddDetails));
